<template>
    <div
        v-if="currentCard !== null"
        class="p-3">
        <div>
            <h3 class="sidebar-item-title">
                <span
                    class="card-name-title"
                    contenteditable="true"
                    @blur="handleCardNameBlur"
                    @keypress.enter="onEnterKeyPress"
                    v-text="currentCard.name" /> - Layer(s)
                <layer-quick-insert-menu class="sidebar-item-action-button">
                    <template #activator="{ on }">
                        <button
                            v-on="on">
                            <icon
                                class="create-layer-icon btn"
                                color="#00a2ea"
                                name="plus"
                                width="18"
                                height="18" />
                        </button>
                    </template>
                </layer-quick-insert-menu>
            </h3>
        </div>
        <div class="d-flex layer-preview-list">
            <draggable
                v-model="localLayerList"
                class="width-100 height-100">
                <card-layers-manager-item
                    v-for="(layer, index) in localLayerList"
                    :key="layer.id + '_layer_preview_item'"
                    :layer-number="index + 1"
                    :layer="layer"
                    :is-focused="isFocusedLayer(layer)" />
            </draggable>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    import CardLayersManagerItem from './CardLayersManagerItem';
    import Icon from '@/components/globals/Icon';
    import LayerQuickInsertMenu from '@/components/creative-studio/layered-designer/shared/LayerQuickInsertMenu';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        components: {
            CardLayersManagerItem,
            Icon,
            draggable,
            LayerQuickInsertMenu
        },
        computed: {
            ...mapGetters([
                'currentCardLayers',
                'currentCard',
                'focusedLayer'
            ]),
            localLayerList: {
                get() {
                    // Show the reversed version
                    return [...this.currentCardLayers].reverse();
                },
                set(newOrderedLayers) {
                    // Use the original version
                    const updatedLayers = newOrderedLayers.reverse().map((layer, index) => {
                        layer.index = index + 1;
                        return layer;
                    });
                    this.setCurrentCardLayers(updatedLayers);
                }
            }
        },
        methods: {
            ...mapActions([
                'setCurrentCardLayers',
                'setCurrentCardName'
            ]),
            isFocusedLayer(layer) {
                return layer.id === this.focusedLayer?.id;
            },
            handleCardNameBlur(event) {
                const updatedText = event.target.innerText;
                if (updatedText === this.currentCard.name) {
                    return;
                }
                this.setCurrentCardName(updatedText);
            },
            onEnterKeyPress(e) {
                e.preventDefault();
                e.target.blur();
            }
        }

    };
</script>

<style lang="scss" scoped>
.layer-preview-list {
    margin-bottom: 30px;
}
.create-layer-icon {
    cursor: pointer;
}

.width-100{
    width: 100%;
}

.height-100 {
    height: 100%;
}

.p-3 {
    padding: 3px;
}

.card-name-title {
    padding-right: 5px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;

    &:focus {
        overflow-x: auto;
        scrollbar-width: thin;
    }
}

</style>