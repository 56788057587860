<template>
    <div>
        <slot />
        <moveable
            ref="moveable"
            :element-guidelines="guidelines || []"
            :snappable="true"
            :is-display-snap-digit="true"
            :snap-gap="true"
            :snap-directions="snapDirections"
            :element-snap-directions="snapDirections"
            :snap-digit="0"
            :snap-grid-width="10"
            :target="target"
            :drag-area="false"
            :horizontal-guidelines="horizontalGuidelines"
            :vertical-guidelines="verticalGuidelines"
            :draggable="true"
            :scalable="scalable"
            :resizable="resizable"
            :keep-ratio="keepRatio"
            :render-directions="renderDirections"
            :clip-area="true"
            :clippable="clippable"
            :clip-relative="hasRelativeCrop"
            :clip-target-bounds="true"
            :rotatable="true"
            :rotation-position="'bottom'"
            :origin="false"
            :throttle-drag="1"
            :start-drag-rotate="0"
            :throttle-drag-rotate="0"
            :ables="[customRotateAble]"
            rotation-target=".moveable-custom-rotation"
            :custom="true"
            :props="{custom:true}"
            @dragStart="onDragStart"
            @drag="onDrag"
            @dragEnd="onDragEnd"
            @resize="onResize"
            @resizeEnd="onResizeEnd"
            @rotate="onRotate"
            @rotateEnd="onRotateEnd"
            @scale="onScale"
            @scaleEnd="onScaleEnd"
            @clip="onClip"
            @clipEnd="onClipEnd" />
        <icon
            ref="rotate-icon"
            class="rotate-icon rotate-icon-original moveable-custom-rotation"
            name="rotate"
            color="black"
            width="20"
            height="20" />
        <!-- <vue-selecto
            v-if="!isReadOnly"
            drag-container="[data-card-canvas]"
            :selectable-targets="['[data-selectable-layer]']"
            :select-by-click="true"
            :select-from-inside="false"
            :hit-rate="100"
            :ratio="0"
            :toggle-continue-select="['shift']"
            @selectEnd="onSelectEnd" /> -->
    </div>
</template>
<script>
    import Moveable from 'vue-moveable';
    import Icon from '@/components/globals/Icon';

    import { mapActions, mapGetters } from 'vuex';

    import {
        MOVEABLE_DEFAULT_TRANSLATE_ARRAY,
        MOVEABLE_TRANSLATE_TOP_INDEX,
        MOVEABLE_TRANSLATE_LEFT_INDEX,
        MOVEABLE_CLIP_STYLES_INDEXES,
        MOVEABLE_USES_RELATIVE_CROP,
        LAYERED_DESIGNER_MAIN_CANVAS_ID,
        KEYBOARD,
        SNAP_GUIDELINES_DIRECTIONS
    } from '@/components/creative-studio/common/constants';

    import { customRotateAble } from '@/components/creative-studio/layered-designer/utils/custom-ables/custom-rotate-able';
    import { manageCardPreviewDebounced } from '../LayeredDesigner.vue';
    import undoRedoHistory from '@/components/creative-studio/layered-designer/utils/undo-redo-history';
    import { debounce } from 'lodash';
    export default {
        components: {
            Moveable,
            Icon
        },
        props: {
            focusedLayerId: {
                required: true,
                type: Number,
            },
            resizable: {
                required: false,
                type: Boolean,
                default: false
            },
            scalable: {
                required: false,
                type: Boolean,
                default: false
            },
            clippable: {
                required: false,
                type: Boolean,
                default: false
            },
            keepRatio: {
                required: false,
                type: Boolean,
                default: true,
            },
            focusedLayerAsset: {
                required: true,
                type: Object
            },
            horizontalGuidelines: {
                required: false,
                type: Array,
                default() {
                    return [];
                }
            },
            verticalGuidelines: {
                required: false,
                type: Array,
                default() {
                    return [];
                }
            },
            focusedLayerAlterations: {
                required: true,
                type: Object
            },
            focusedLayerGradient: {
                required: true,
                type: Object
            },
            focusedLayerScale: {
                required: false,
                type: Number,
                default: 1
            },
            currentCardStyle: {
                required: true,
                type: Object
            },
            focusedLayerPosition: {
                required: true,
                type: Object
            },
            isReadOnly: {
                required: false,
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isMousePressed: false,
                target: null,
                targetGhostClone: null,
                localUpdate: false,
                hasRelativeCrop: MOVEABLE_USES_RELATIVE_CROP,
                customRotateAble: {},
                isSyncing: false,
                guidelines: null,
                snapDirections: SNAP_GUIDELINES_DIRECTIONS,
            };
        },
        computed: {
            ...mapGetters([
                'focusedLayer',
                'currentCard',
                'currentCardLayers'
            ]),
            renderDirections() {
                if(this.keepRatio) {
                    return ['nw','ne','se', 'sw'];

                }
                return ['nw','ne','se', 'sw', 'w', 'e'];
            }
        },
        watch: {
            currentCardLayers() {
               this.updateDOMGuidelines();
            },
            focusedLayerId(newId) {
                const target = this.$el.querySelector(`[data-layer-id="${newId}"]`);

                if(newId === -1) {
                    this.target = null;
                    this.setFocusedLayer(null);
                    return;
                }

                if(target) {
                    this.setFocusedLayerById(newId);
                    this.target = target;
                    this.syncDraggableCanvas();
                }


            },
            target(newTarget) {
                if (this.targetGhostClone !== null) {
                    this.targetGhostClone.remove();
                    this.targetGhostClone = null;
                }

                if (newTarget === null || !this.clippable) {
                    return;
                }

                const targetClone = newTarget?.cloneNode(true);
                targetClone.dataset.layerFocused = false;
                targetClone.style.clipPath = null;
                targetClone.style.opacity = 0.5;
                targetClone.style.zIndex = newTarget?.parentElement?.style?.zIndex - 1;
                targetClone.id = targetClone.id + '_crop_clone';
                delete targetClone.dataset.selectableLayer;

                targetClone.children.forEach((children) => children.setAttribute('draggable', false));

                this.targetGhostClone = targetClone;

                document.getElementById(LAYERED_DESIGNER_MAIN_CANVAS_ID)?.append(this.targetGhostClone);
            }
        },
        created() {
            window.addEventListener('mouseup', this.handleWindowMouseUp);
            document.addEventListener('keydown', this.handleKeyPress, { passive: false });
        },
        mounted() {

            const rotationIcon = this.$refs['rotate-icon'].$el;
            this.customRotateAble = customRotateAble(rotationIcon);
        },
        beforeDestroy() {
            window.removeEventListener('mouseup', this.handleWindowMouseUp);
            document.removeEventListener('keydown', this.handleKeyPress, { passive: false });

        },
        methods: {
            ...mapActions([
                'updateTextLayer',
                'setFocusedLayer',
                'setFocusedLayerById',
                'setFocusedLayer',
                'updateFocusedLayerPosition',
                'updateFocusedLayerDimensions',
                'updateFocusedLayerRotateAngle',
                'updateFocusedLayerScale',
                'updateFocusedLayerCropOffset'
            ]),

            updateDOMGuidelines() {
                this.$nextTick(() => {
                    const elementsArray = new Array();
                    const nodes = this.$el.querySelectorAll('[data-selectable-layer]');
                    for (const node of nodes) {
                        elementsArray.push({
                        element: node,
                        className: 'guidelines-styled',
                        refresh: true
                        });
                    }
                   this.guidelines = elementsArray;
                });
            },
            handleKeyPress(event) {
                const currentDimensionId = this.currentCard?.dimensions?.id;
                if (currentDimensionId === 2 || currentDimensionId === 3) {
                    this.$flash('Changes can only be performed on the 1:1 card size', 'red');
                    return;
                }
                const isPressingArrowKeys = event.key === KEYBOARD.KEY_LEFT ||
                event.key === KEYBOARD.KEY_DOWN ||
                event.key === KEYBOARD.KEY_RIGHT ||
                event.key === KEYBOARD.KEY_UP;

                if(isPressingArrowKeys) {
                    this.handleMove(event);
                }

                if(event.key === KEYBOARD.Z_KEY && event.ctrlKey) {
                    this.handleUndo();
                }

                if(event.key === KEYBOARD.Y_KEY && event.ctrlKey) {
                    this.handleRedo();
                }

                if (event.key === 'Z' && event.metaKey && event.shiftKey) {
                  this.handleUndo();

                }

                if (event.key === 'Y' && event.metaKey && event.shiftKey) {
                    this.handleRedo();
                }

                if (event.key === 'z' && event.metaKey) {
                    this.handleUndo();
                }

                if (event.key === 'y' && event.metaKey) {
                    this.handleRedo();
                }

            },

            // Handle arroe keys move
            handleMove(event) {
                const tagNamesToIgnore = ['INPUT', 'TEXTAREA'];
                const eventKeysToHandle = [KEYBOARD.KEY_UP, KEYBOARD.KEY_DOWN, KEYBOARD.KEY_LEFT, KEYBOARD.KEY_RIGHT];
                // Ignore if content is editable or is an input-textarea
                const hasToIgnoreElement = tagNamesToIgnore.includes(event.target.tagName) || event.target.isContentEditable;
                const hasToHandleEventKey = eventKeysToHandle.includes(event.key);
                const isValidEvent = !hasToIgnoreElement && hasToHandleEventKey;

                if(!isValidEvent) {
                    return;
                }
                event.preventDefault();


                const moveable = this.$refs.moveable.target;

                let top = this.focusedLayer.position.top;
                let left = this.focusedLayer.position.left;
                const angle = this.focusedLayer.rotateAngle;
                const scale = this.focusedLayer.scale;


                const pixelsToPositionate = event.shiftKey ? 10 : 2;

                if(event.key === KEYBOARD.KEY_LEFT) {
                    left = left - pixelsToPositionate;
                }
                if(event.key === KEYBOARD.KEY_DOWN) {
                    top = top + pixelsToPositionate;
                }
                if(event.key === KEYBOARD.KEY_RIGHT) {
                    left = left + pixelsToPositionate;
                }
                if(event.key === KEYBOARD.KEY_UP) {
                    top = top - pixelsToPositionate;
                }

                // TODO: Determine if scaleY(1) scaleX(1) affects with this
                moveable.style.transform = `translate(${left}px, ${top}px) rotate(${angle}deg) scaleY(1) scaleX(1) scale(${scale})`;
                moveable.dataset.currentTop = top;
                moveable.dataset.currentLeft = left;

                this.syncTargetGhostClone();

                this.updateFocusedLayerPosition({ top, left });
            },
            handleUndo:debounce(() => {
                undoRedoHistory.undo();
            }),
            handleRedo:debounce(() => {
                undoRedoHistory.redo();
            }),
            // Regular methods
            syncDraggableCanvas() {
                // Prevent duplicated syncs in the same loop
                if (this.isSyncing) {
                    return;
                }
                this.isSyncing = true;
                this.$nextTick(() => {
                    this.updateTarget();
                    if (this.target !== null) {
                        this.$refs.moveable.updateRect();
                        this.syncTargetGhostClone();
                    }
                    this.isSyncing = false;
                });
            },
            updateTarget() {
                const newTarget = document.querySelector('[data-layer-focused="true"] [data-selectable-layer]');
                if (newTarget && newTarget !== this.target) {
                    this.target = newTarget;
                }
            },
            syncTargetGhostClone: debounce(function() {

                if ( this.target !== null && this.targetGhostClone !== null ) {
                    this.targetGhostClone.style.transform = this.target.style.transform;
                    this.targetGhostClone.style.height = this.target.style.height;
                    this.targetGhostClone.style.width = this.target.style.width;
                    // Set the img children styles
                    this.targetGhostClone.querySelector('img').style.backgroundImage = this.target.querySelector('img')?.style?.backgroundImage;
                }
            }, 200),
            // Event handlers
            handleWindowMouseUp() {
                this.isMousePressed = false;
            },
            onDragStart() {
                // generate card review is cpu intensive and we don't
                // want to affect the user experience while dragging a layer
                manageCardPreviewDebounced.cancel();
                this.$emit('drag-start');

            },
            onDrag({ transform, target }) {
                target.style.transform = transform;

                this.syncTargetGhostClone();
            },
            onDragEnd(event) {
                const newTranslate = event?.lastEvent?.translate || MOVEABLE_DEFAULT_TRANSLATE_ARRAY;
                const top = newTranslate[MOVEABLE_TRANSLATE_TOP_INDEX];
                const left = newTranslate[MOVEABLE_TRANSLATE_LEFT_INDEX];

                if (top === 0 && left === 0) {
                    return; // Event triggered with a click and not an actual drag
                }

                const target = event.target;
                target.dataset.currentTop = top;
                target.dataset.currentLeft = left;

                this.updateFocusedLayerPosition({ top, left });
                this.updateDOMGuidelines();

            },
            onResize({ width, height, target, drag }) {
                // generate card review is cpu intensive and we don't
                // want to affect the user experience while dragging a layer
                manageCardPreviewDebounced.cancel();

                target.style.width = `${width}px`;
                target.style.height = `${height}px`;
                target.style.transform = drag.transform;

                this.syncTargetGhostClone();
            },
            onResizeEnd(event) {
                const height = event?.lastEvent?.height;
                const width = event?.lastEvent?.width;
                if (!height || !width ) {
                    return;
                }

                const newTranslate = event?.lastEvent?.drag?.beforeTranslate || MOVEABLE_DEFAULT_TRANSLATE_ARRAY;
                const top = newTranslate[MOVEABLE_TRANSLATE_TOP_INDEX];
                const left = newTranslate[MOVEABLE_TRANSLATE_LEFT_INDEX];

                const target = event.target;
                target.dataset.currentTop = top;
                target.dataset.currentLeft = left;
                target.dataset.currentHeight = height;
                target.dataset.currentWidth = width;

                this.updateFocusedLayerDimensions({ height, width });
                this.updateFocusedLayerPosition({ top, left });
                this.updateDOMGuidelines();
            },
            onRotate({ target, transform }) {
                // generate card review is cpu intensive and we don't
                // want to affect the user experience while dragging a layer
                manageCardPreviewDebounced.cancel();

                target.style.transform = transform;
                this.syncTargetGhostClone();
            },
            onRotateEnd(event) {
                const rotateAngle = event?.lastEvent?.rotate;
                if (rotateAngle === undefined) {
                    return;
                }
                const newTranslate = event?.lastEvent?.drag?.beforeTranslate || MOVEABLE_DEFAULT_TRANSLATE_ARRAY;
                const top = newTranslate[MOVEABLE_TRANSLATE_TOP_INDEX];
                const left = newTranslate[MOVEABLE_TRANSLATE_LEFT_INDEX];

                const target = event.target;
                target.dataset.currentTop = top;
                target.dataset.currentLeft = left;
                target.dataset.currentRotateAngle = rotateAngle;

                this.updateFocusedLayerRotateAngle(rotateAngle);
                this.updateFocusedLayerPosition({ top, left });
                this.updateDOMGuidelines();

            },
            onScale({ target, scale, transform }) {
                 manageCardPreviewDebounced.cancel();
                 const [layerScale] = scale;

                 if(layerScale < 0) {
                    return;
                 }


                target.style.transform = transform;

                this.updateFocusedLayerScale(layerScale);

                this.syncTargetGhostClone();
            },
            onScaleEnd({ target, lastEvent }) {
                 const [layerScale] = lastEvent.scale;
                target.dataset.currentScale = layerScale;
            },
            onClip({ target, clipStyle }) {
                // generate card review is cpu intensive and we don't
                // want to affect the user experience while dragging a layer
                manageCardPreviewDebounced.cancel();

                target.style.clipPath = clipStyle;
            },
            onClipEnd(event) {
                const clipStyles = event?.lastEvent?.clipStyles;
                if (clipStyles === undefined) {
                    return;
                }
                const top = parseInt(clipStyles[MOVEABLE_CLIP_STYLES_INDEXES.TOP]);
                const left = parseInt(clipStyles[MOVEABLE_CLIP_STYLES_INDEXES.LEFT]);
                const right = parseInt(clipStyles[MOVEABLE_CLIP_STYLES_INDEXES.RIGHT]);
                const bottom = parseInt(clipStyles[MOVEABLE_CLIP_STYLES_INDEXES.BOTTOM]);

                this.updateFocusedLayerCropOffset({ top, left, right, bottom });
                this.updateDOMGuidelines();
            },
            handleAssetDrag(e) {
                const moveable = this.$refs.moveable;
                e.preventDefault();
                this.target = e.target.closest('[data-layer-id]');
                this.$nextTick(() => {
                    setTimeout(() => {
                        moveable.dragStart(e);

                    });
                });

            }
        }
    };
</script>

<style lang="scss">

.moveable-rotation .moveable-control.moveable-rotation-control {
    border-color: #979797 !important;
    transform: translate(0.5px, 15px) scale(1) !important;
}

.moveable-rotation .moveable-line.moveable-rotation-line, .moveable-rotation .moveable-control.moveable-rotation-control {
    display: none !important;
}

.moveable-line {
    background: linear-gradient(to right, transparent 50%, #979797 50%)  !important;
    background-size: 10px 2px, 100% 2px !important;
    // TODO: This is causing  vertical guideline not showing
    //height: 2px !important;
}


.guidelines-styled {
    border: solid 2px $blue-bg !important;
    height: 100% !important;
}

.moveable-direction.moveable-e,
.moveable-direction.moveable-w,
.moveable-direction.moveable-s,
.moveable-direction.moveable-n,
.moveable-direction.moveable-nw,
.moveable-direction.moveable-se,
.moveable-reverse .moveable-direction.moveable-ne,
.moveable-reverse .moveable-direction.moveable-sw,
.moveable-direction.moveable-ne,
.moveable-direction.moveable-sw {
    background: $white!important;
    border-color: #979797 !important;
    border-radius: 0 !important;
}

.moveable-control.moveable-clip-control {
    background: $blue-bg !important;
}

.moveable-control.moveable-clip-control[data-clip-index="0"],
.moveable-control.moveable-clip-control[data-clip-index="2"],
.moveable-control.moveable-clip-control[data-clip-index="4"],
.moveable-control.moveable-clip-control[data-clip-index="6"] {
  //  display: none !important;
  z-index: 100;
}

.rotate-icon-original {
    display: none !important;
}

.moveable-custom-rotation-wrapper {
    position: absolute;
    width: 1px;
    transform-origin: 50% 100%;
    height: calc(40px * var(--zoom));
    top: auto;
    left: 0;
    bottom: 100%;
    will-change: transform;
}

.moveable-custom-rotation {
    cursor: move;
    background-color: white;
    border-radius: 50%;
    margin-top: -7px;
    margin-left: -7px;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    transform-origin: 50% 100%;
}

.moveable-clip-line {
    pointer-events: none;
}

.moveable-vertical {
    background-color: #979797 !important;
}

.moveable-bold,
  .moveable-target{
    background-color: $blue-bg !important;
  }
</style>