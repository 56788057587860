/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'inventory': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" _fill="#444" d="M16 5.981v9.29L8.63 20v-9.29L16 5.981zm-16 0l2.65 1.7.002 3.122 2.142 1.413-.002-3.16L7.37 10.71V20L0 15.27V5.982zm12.608-2.963l2.987 1.931-7.582 4.767-2.988-1.932 7.583-4.766zM7.938 0l2.507 1.62L2.86 6.385.356 4.765 7.937 0z"/>'
  }
})
