/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-down': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<defs><path pid="0" id="svgicon_chevron-down_a" d="M4-1.667L6.27-4 16 6 6.27 16 4 13.667 11.46 6z"/></defs><use _fill="#8F9EA6" transform="rotate(90 10 6)" xlink:href="#svgicon_chevron-down_a" fill-rule="evenodd"/>'
  }
})
