<template>
    <div class="as-main">
        <best-practice-icon />
        <div
            ref="wholeArea"
            class="whole-area">
            <div class="drop-visual" />
            <div
                ref="dropField"
                class="drop__field">
                <div
                    ref="dropArea"
                    class="drop__land">
                    <div
                        ref="dropIcon"
                        class="drop__icon-land">
                        <icon
                            name="cloud"
                            color="white"
                            width="45"
                            height="45" />
                    </div>
                    <div class="drop__title-land">
                        Drop here
                    </div>
                    <div
                        ref="dropInfo"
                        class="drop__info-land">
                        Supported BuyerBridge file formats:<br>
                        Images: {{ imageExtensions.join(', ') }}
                        <span class="drop__slash">|</span> Video: {{ videoExtensions.join(', ') }}
                        <span class="drop__slash">|</span> Min dimensions: 500x500px
                        <span class="drop__slash">|</span> Max size: 500MB
                        <span class="drop__slash">|</span> Video duration less than 60 seconds
                    </div>
                </div>
            </div>
            <div class="drop">
                <div class="drop-left">
                    <div class="drop-left-inner">
                        <div class="drop__title">
                            Let's get started!
                        </div>
                        <div class="drop__desc">
                            It's time to build and deploy a custom ad for the selected account.
                            All you have to do is upload the ad creative, choose which channel(s) to deploy the ad on,
                            personalize the ad copy, which you can keep the same or change per channel; and we'll take care of the rest!
                        </div>
                        <form>
                            <div class="drop__box">
                                <input
                                    id="upload"
                                    type="file"
                                    multiple
                                    @change="handleFiles($event)">
                                <div v-if="!errorMessage && !uploadingIsGoing">
                                    <div class="drop__icon">
                                        <icon
                                            name="cloud"
                                            color="#8F9EA6"
                                            width="60"
                                            height="60" />
                                    </div>
                                    <div class="drop__icondesc">
                                        Drop file to upload
                                    </div>
                                    <div class="drop__or">
                                        or
                                    </div>
                                    <label for="upload">
                                        <span>SELECT FILE FROM YOUR COMPUTER</span>
                                    </label>
                                </div>
                                <div v-if="errorMessage && !uploadingIsGoing">
                                    <div class="drop__oops">
                                        Oops!
                                    </div>
                                    <div class="drop__error">
                                        {{ errorMessage }}
                                    </div>
                                    <label for="upload">
                                        <span>CHOOSE ANOTHER FILE</span>
                                    </label>
                                </div>
                                <div v-if="uploadingIsGoing">
                                    <div class="drop__circle">
                                        <div class="drop__bar">
                                            <progress-ring
                                                radius="100"
                                                :progress="progress"
                                                stroke="10" />
                                        </div>
                                        <div class="drop__percentage">
                                            {{ progress }}%
                                        </div>
                                    </div>
                                    <div class="drop__uploading">
                                        Uploading {{ currentFile }} of {{ totalFiles }}
                                    </div>
                                    Filename: {{ fileName }}
                                </div>
                            </div>
                        </form>
                        <div class="drop__info">
                            <div class="mb-3">
                                Supported BuyerBridge file formats:
                            </div>
                            <div><b>Images:</b> {{ imageExtensions.join(', ') }}</div>
                            <div><b>Video:</b> {{ videoExtensions.join(', ') }}</div>
                            <div><b>Max size:</b> 500MB</div>
                            <div><b>Min dimensions:</b> 500x500px</div>
                            <div><b>Video duration:</b> Less than 60 seconds</div>
                        </div>
                    </div>
                </div>
                <div class="drop-right">
                    <div class="drop-placeholder" />
                    <div class="drop-block">
                        <div class="drop-block-right">
                            <div>
                                <img
                                    src="/img/ads-lib.jpg"
                                    alt="">
                            </div>
                        </div>
                        <div class="drop-block-left">
                            <div>
                                Jump start your ad creation by customizing an ad from our ad library.
                            </div>
                            <router-link
                                class="drop-block-button"
                                :to="{name: 'ads-library'}"
                                @click.native="$emit('close')">
                                BROWSE AD LIBRARY
                            </router-link>
                        </div>
                    </div>
                    <div class="drop-block">
                        <div class="drop-block-right">
                            <div>
                                <img
                                    src="/img/assets-lib.jpg"
                                    alt="">
                            </div>
                        </div>
                        <div class="drop-block-left">
                            <div>
                                Not quite sure where to begin?
                                Browse our extensive image and video asset library for the perfect creative to get you started!
                            </div>
                            <router-link
                                class="drop-block-button"
                                :to="{name: 'assets-tab'}"
                                @click.native="$emit('close')">
                                BROWSE ASSET LIBRARY
                            </router-link>
                        </div>
                    </div>
                    <div class="drop-creative">
                        <icon
                            class="mr-2"
                            name="creative"
                            width="20"
                            height="20" />
                        <span>Feeling creative? Create a design from scratch! </span>
                        <a
                            href="#"
                            @click.prevent="openLayeredDesigner">Try our Layered Designer</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import ProgressRing from '@/components/ad-studio/uploading/ProgressRing';
import BestPracticeIcon from '@/components/ad-studio/BestPracticeIcon';
import { mapState } from 'vuex';
import {
    IMAGE_ASSET_TYPE,
    VIDEO_ASSET_TYPE
} from '@/components/ad-studio/store/constants.js';
import EventBus from '@/event-bus';
import { LAYERED_DESIGNER_MODE } from '@/creative-studio/domain/layered-designer/models/Layer';

export default {
    name: 'AdStudioUploading',
    components: {
        Icon,
        ProgressRing,
        BestPracticeIcon
    },
    data() {
        return {
            errorMessage: '',
            uploadingIsGoing: false,
            fileName: '',
            progress: 0,
            imageExtensions: ['jpg', 'jpeg', 'png'],
            videoExtensions: ['mov', 'mp4'],
            assetType: null,
            extension: null,
            currentFile: 0,
            totalFiles: 0,
            duration: null,
            VIDEO_ASSET_TYPE
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            user: state => state.user.user,
            agency: state => state.agency.currentAgency
        })
    },
    mounted() {
        this.handleDropping();
    },
    methods: {
        handleDropping() {
            const wholeArea = this.$refs.wholeArea;
            const dropField = this.$refs.dropField;
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                wholeArea.addEventListener(eventName, this.preventDefaults, false);
                dropField.addEventListener(eventName, this.preventDefaults, false);
            });
            ['dragenter', 'dragover'].forEach(eventName => {
                wholeArea.addEventListener(eventName, this.highlight, false);
            });
            ['dragleave', 'drop'].forEach(eventName => {
                dropField.addEventListener(eventName, this.unhighlight, false);
            });
        },
        preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        highlight() {
            this.$refs.wholeArea.classList.add('highlight');
        },
        unhighlight(e) {
            const icon = this.$refs.dropIcon;
            const info = this.$refs.dropInfo;

            // if not dropping or leaving
            if (e.type == 'dragleave' && e.x == 0 && e.y == 0) {
                this.$refs.wholeArea.classList.remove('highlight');
            }
            else if (e.type == 'dragleave' && e.target.parentElement == this.$refs.dropArea) return;
            else if (e.type == 'dragleave' && e.target.parentElement == this.$refs.dropField) return;
            else if (e.type == 'dragleave' && e.target.parentElement == icon) return;
            else if (e.type == 'dragleave' && e.target.parentElement == info) return;
            else if (e.type == 'dragleave' && e.target.parentElement.tagName == 'svg') return;
            else {
                this.$refs.wholeArea.classList.remove('highlight');
            }

            // if dropping
            if (e.type == 'drop') {
                this.handleFiles(e);
            }
        },
        async handleFiles(e) {
            this.resetFileData();
            const fileList = e.target.files || e.dataTransfer.files;



            for (const file of fileList) {
                this.setExtension(file.name);

                if (file.size > 524288000) {
                    this.errorMessage = 'It looks like your file esceeds our max upload size';
                    return;
                }

                if (!this.allowedFormat(file.name)) {
                    this.errorMessage = 'It looks like this format of file is not allowed';
                    return;
                }

                if (this.assetType === IMAGE_ASSET_TYPE) {
                    const imageDimensions = await this.imageData(file);
                    if (imageDimensions.width < 500 || imageDimensions.height < 500) {
                        this.errorMessage = 'Media must be at least 500x500';
                        return;
                    }
                }

                if (this.assetType === VIDEO_ASSET_TYPE) {
                    const videoDimensions = await this.videoMetaData(file, 'dimensions');
                    const duration = await this.videoMetaData(file, 'duration');
                    if (videoDimensions.width < 500 || videoDimensions.height < 500) {
                        this.errorMessage = 'Media must be at least 500x500';
                        return;
                    }
                    if (duration > 62) {
                        this.errorMessage = 'The video duration is over 60 seconds';
                        return;
                    }
                    this.duration = duration;
                }
            }

            if (!this.errorMessage) {
                this.totalFiles = fileList.length;
                for (const [index, file] of Object.entries(fileList)) {
                    this.fileName = file.name;
                    await this.uploadFile(file, +index);
                }
            }

        },
        resetFileData() {
            this.errorMessage = '';
            this.uploadingIsGoing = false;
            this.fileName = '';
            this.progress = 0;
            this.assetType = null;
            this.extension = null;
            this.currentFile = 0;
            this.totalFiles = 0;
            this.duration = null;
        },
        async videoMetaData(file, option) {
            const getVideoData = () => new Promise(resolve => {
                let video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = () => {
                    window.URL.revokeObjectURL(video.src);
                    if (option === 'dimensions') {
                        const width = video.videoWidth;
                        const height = video.videoHeight;
                        resolve({ width, height });
                    }
                    if (option === 'duration') {
                        resolve(video.duration);
                    }
                };
                video.src = URL.createObjectURL(file);
            });
            return await getVideoData();
        },
        async imageData(file) {
            const getImageData = () => new Promise(resolve => {
                let img = new Image();
                img.onload = function() {
                    const height = img.height;
                    const width = img.width;
                    resolve({ width, height });
                };
                img.src = URL.createObjectURL(file);
            });
            return await getImageData();
        },
        allowedFormat() {
            return this.imageExtensions.includes(this.extension.toLowerCase()) || this.videoExtensions.includes(this.extension.toLowerCase());
        },
        setExtension(filename) {
            const parts = filename.split('.');
            this.extension = parts[parts.length - 1];
            this.assetType = this.imageExtensions.includes(this.extension.toLowerCase()) ? IMAGE_ASSET_TYPE : VIDEO_ASSET_TYPE;
        },
        async uploadFile(file, index) {
            this.progress = 0;
            this.uploadingIsGoing = true;
            this.currentFile = index + 1;
            let formData = new FormData();
            formData.append('file', file);
            formData.append('display_name', this.fileName);
            formData.append('agency_id', this.agency.id);
            this.assetType === VIDEO_ASSET_TYPE ? formData.append('duration', this.duration) : null;
            await this.$http.axios.post('/assets/upload', formData, {
                onUploadProgress: event => {
                    let percent = Math.round((event.loaded / event.total) * 100);
                    // fixing short delay in the end while we are waiting for server response
                    if (percent == 100) {
                        percent = 99;
                    }
                    this.progress = percent;
                }
            }).then(result => {
                this.progress = 100;
                this.uploadingIsGoing = false;
                this.$emit('file-uploaded', {
                    fileName: result.data.display_name,
                    asset: result.data,
                    index,
                    totalFiles: this.totalFiles
                });
            }).catch(error => {
                this.uploadingIsGoing = false;
                this.errorMessage = error;
            });
        },
        openLayeredDesigner() {
            this.$emit('close');
            EventBus.$emit('open-layered-designer', LAYERED_DESIGNER_MODE.NEW_DESIGN);
        }
    }
};
</script>

<style lang="scss" scoped>
.as-main{
    display: flex;
}
.whole-area{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    &.highlight{
        .drop__field{
           display: block;
        }
    }
    @media only screen and (max-height: 768px) {
       align-items: flex-start;
       overflow: auto;
       padding-top: 70px;
    }
}
.drop{
    width: 100%;
    display: flex;
    justify-content: center;
    @media only screen and (max-height: 768px) {
       min-height: 700px;
    }
    &__title{
        font-size: 30px;
        margin-bottom: 30px;
        color: #03A2EA;
        text-align: left;
    }
    &__desc{
      max-width: 700px;
      text-align: left;
      margin: auto auto 30px;
      line-height: normal;
    }
    &__box{
        border: 3px dashed $gray-light;
        border-radius: 10px;
        padding:40px;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 30px;
        #upload{
           display: none;
        }
        label{
            display: inline-block;
            padding: 5px 25px;
            background: #03A2EA;
            font-weight: bold;
            color: white;
            border-radius: 5px;
            white-space: nowrap;
            cursor: pointer;
        }
    }
    &__oops{
        color: #FF3C00;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    &__error{
        margin-bottom: 45px;
        color: #FF3C00;
        font-size: 12px;
    }
    &__uploading{
        font-weight: bold;
        margin-bottom: 15px;
    }
    &__circle{
        position: relative;
    }
    &__percentage{
        font-size: 36px;
        font-weight: bold;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__icondesc{
        font-size: 16px;
    }
    &__or{
        margin-bottom: 30px;
        margin-top: 30px;
        font-size: 16px;
    }
    &__slash{
        margin-left: 15px;
        margin-right: 15px;
        display: inline-block;
        margin-top: 5px;
    }
    &__field{
        display: none;
        position: fixed;
        z-index: 9999;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: rgba(0,0,0,0.75);
        backdrop-filter: blur(6px);
    }
    &__land{
        position: absolute;
        top:10%;
        left:10%;
        bottom: 10%;
        right: 10%;
        border: 3px dashed #A8A8A8;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &__title-land{
        color: white;
        font-size: 16px;
        margin-bottom: 45px;
    }
    &__info-land{
        color: white;
    }
}
.drop-left {
    width: 40%;
    display: flex;
    justify-content: center;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 45px;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background-color: $gray-light;
    }
}
.drop-left-inner {
    max-width: 400px;
}
.drop-right {
    width: 40%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.drop-block {
    display: flex;
    text-align: left;
    max-width: 500px;
}
.drop-block-left {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.drop-block-right img {
    max-width: 100px;
    height: auto;
    vertical-align: top;
}
.drop-block-button {
    display: inline-block;
    width: 100%;
    padding: 15px 0;
    font-size: 14px;
    text-align: center;
    background: #03A2EA;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
}
</style>
