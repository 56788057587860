/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audio-pause': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M4.821 15H1.607C.72 15 0 14.28 0 13.393V1.607C0 .72.72 0 1.607 0h3.214C5.71 0 6.43.72 6.43 1.607v11.786c0 .887-.72 1.607-1.608 1.607zM15 13.393V1.607C15 .72 14.28 0 13.393 0h-3.214C9.29 0 8.57.72 8.57 1.607v11.786c0 .887.72 1.607 1.608 1.607h3.214C14.28 15 15 14.28 15 13.393z" _fill="#B3E5FC" fill-rule="nonzero"/>'
  }
})
