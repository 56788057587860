/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'redo': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M3.507 12.49c.529-3.86 4.148-6.554 8.03-6.011a7.116 7.116 0 013.518 1.507l-2.806 2.393 7.706 1.849-.366-8.041-2.603 2.191a9.553 9.553 0 00-5.103-2.332C6.638 3.323 1.78 6.961 1.047 12.148c-.732 5.186 1.932 4.201 2.46.341z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
