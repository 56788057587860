
/**
 * Applies designated properties from a source object to a destination, if set.
 * This is primarily intended for HTTP calls where the presence of null, undefined
 * properties and/or empty arrays can have unintended side-effects
 *
 * @param {object} source      The source object to pull properties from
 * @param {object} destination The target object to update
 * @param {object} properties  A map of optional properties to pull over
 *                             and their property name in the destination.
 */
const applyOptionalProperties = (source, dest, properties) => {
    Object.entries(properties).forEach(([sourceProperty, destProperty]) => {
        const value = source[sourceProperty];
        if (
            (Array.isArray(value) && value?.length) ||
            (
                typeof value !== 'undefined' &&
                value !== null
            )
        ) {
            dest[destProperty] = value;
        }
    });
}

export default applyOptionalProperties;