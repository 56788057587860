import {
    SET_GLOBAL_MENU,
    SET_INVENTORY_ADS_VIEW,
    SET_USER_WELCOMED,
    SET_DISMISSED_ALERTS,
    SET_USER_DATA,
    TOGGLE_OPPORTUNITY_SLIDEOUT,
    TOGGLE_CHANNEL_SETTINGS_SLIDEOUT,
    TOGGLE_PAGE_CHANGE_SLIDEOUT,
    TOGGLE_ORGANIZATION_SETTINGS_SLIDEOUT,
    SET_CHANNEL_SETTINGS_TAB,
    SET_ACTIVATED_CHANNELS,
    SET_CHANNEL_CONNECTION_DEBUG_PANEL
} from '@/store/mutation-types';
import {
    SETTINGS_STORAGE_KEY,
} from '@/helpers/storageKeys';


const getLocalData = () => {
    const currentSettingsJson = localStorage.getItem(SETTINGS_STORAGE_KEY);
    return currentSettingsJson ? JSON.parse(currentSettingsJson) : null;
};

const getDefaultData = () => {
    return {
        welcomed: false,
        dismissedAlerts: [],
        userData: {}
    };
};

// Merge local data with defaults
const initialData = { ...getDefaultData(), ...getLocalData() } ;

export default {
    state: {
        welcomed: initialData.welcomed,
        userData: initialData.userData,
        dismissedAlerts: initialData.dismissedAlerts,
        selectedPlatforms: initialData.selectedPlatforms,
        activatedChannels: [],
        opportunitySlideoutOpen: false,
        channelSettingsSlideoutOpen: false,
        pageChangeSlideoutOpen: false,
        organizationSettingsSlideoutOpen: false,
        channelConnectionDebugPanelOpen: false,
        channelForConnection: null,
        dealerForCheckingConnection: null,
        globalMenuIsOpen: true,
        inventoryAdsIsOpen: true,
        activeChannelSettingsTab: 'facebook'
    },
    getters: {
        settingsStorage(state) {
            return {
                welcomed: state.welcomed,
                dismissedAlerts: state.dismissedAlerts,
                userData: state.userData,
                inventoryAdsIsOpen: state.inventoryAdsIsOpen,
                selectedPlatforms: state.selectedPlatforms
            };
        },
    },
    actions: {
        dismissAlert({ state, commit }, key) {

            const dismissedAlerts = [ ...state.dismissedAlerts ];

            dismissedAlerts.push(key);

            commit(SET_DISMISSED_ALERTS, dismissedAlerts);
        },
        updateUserData({ commit, state }, newData) {

            // Combine updated data with the original
            const combinedData = {
                ...state.userData,
                ...newData
            };

            commit(SET_USER_DATA, combinedData);
        },
        setChannelSettingsTab({ commit }, tab) {
            commit(SET_CHANNEL_SETTINGS_TAB, tab);
        },
    },
    mutations: {
        [SET_GLOBAL_MENU](state, value) {
            state.globalMenuIsOpen = value;
        },
        [SET_INVENTORY_ADS_VIEW](state, value) {
            state.inventoryAdsIsOpen = value;
        },
        [SET_USER_WELCOMED](state, value) {
            state.welcomed = value;
        },
        [SET_USER_DATA](state, userData) {
            state.userData = userData;
        },
        [SET_DISMISSED_ALERTS](state, value) {
            state.dismissedAlerts = value;
        },
        [TOGGLE_OPPORTUNITY_SLIDEOUT](state, value) {
            state.opportunitySlideoutOpen = value;
        },
        [TOGGLE_CHANNEL_SETTINGS_SLIDEOUT](state, value) {
            state.channelSettingsSlideoutOpen = value;
        },
        [TOGGLE_PAGE_CHANGE_SLIDEOUT](state, value) {
            state.pageChangeSlideoutOpen = value;
        },
        [SET_CHANNEL_SETTINGS_TAB](state, tab) {
            state.activeChannelSettingsTab = tab;
        },
        [SET_CHANNEL_CONNECTION_DEBUG_PANEL](state, payload) {
            state.channelConnectionDebugPanelOpen = payload.status;
            state.channelForConnection = payload.channel;
            state.dealerForCheckingConnection = payload.dealer_id;
        },
        [TOGGLE_ORGANIZATION_SETTINGS_SLIDEOUT](state, value) {
            state.organizationSettingsSlideoutOpen = value;
        },
        [SET_ACTIVATED_CHANNELS](state, channels) {
            state.activatedChannels = channels;
        },
    }
};
