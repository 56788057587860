<template>
    <div
        class="d-flex layer-preview-item"
        :class="{ 'focused' : isFocused}"
        @click="setFocusedLayer(layer)">
        <icon
            class="btn drag-icon"
            name="grab"
            width="20"
            height="20" />
        <span
            class="layer-preview-title">
            <icon
                v-if="layerIconName !== 'photo'"
                class="btn"
                :name="layerIconName"
                width="15"
                height="15" />
            <PhotoIcon v-else />
            <div v-if="!isRenaming">
                {{ layerTitle | truncate(20, "...") }} {{ hasChanges ? '' : '- ' + layerNumber }}
            </div>
            <div v-else>
                <input
                    ref="input-layer-name"
                    v-model="newLayerName"
                    class="layer-name-input"
                    type="text"
                    name="layer-name"
                    @blur="handleSaveNewLayerName"
                    @keyup="hasChanges = true"
                    @keyup.enter="handleSaveNewLayerName">
            </div>

        </span>
        <button
            class="ml-auto layer-config-button"
            @click="menuOpen = !menuOpen">
            <icon
                class="btn"
                name="dots"
                width="19"
                height="19" />
        </button>
        <card-item-manager-menu
            v-custom-click-outside="{
                handler: handleMenuClose
            }"
            :open="menuOpen"
            @center="handleCenterLayer"
            @rename="handleRenameLayer"
            @delete="handleDeleteLayer" />
    </div>
</template>

<script>
    import Icon from '@/components/globals/Icon';
    import { mapActions } from 'vuex';
    import { AssetType } from '@/creative-studio/domain/assets/models/types';
    import CardItemManagerMenu from './CardItemManagerMenu.vue';
    import { customClickOutsideDirective } from '@/components/creative-studio/common/directives/custom-click-outside.js';
    import PhotoIcon from '@/components/creative-studio/svgs/PhotoIcon.vue';

export default {
        components: {
            Icon,
            PhotoIcon,
            CardItemManagerMenu
        },
        directives: {
            CustomClickOutside : customClickOutsideDirective
        },
        props: {
            layer: {
                type: Object,
                required: true
            },
            layerNumber: {
                type: Number,
                required: true
            },
            isFocused: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                isRenaming: false,
                newLayerName: '',
                menuOpen: false,
                hasChanges: false

            };
        },
        computed: {
            layerTitle() {
                if(!this.layer.name) {
                    switch(this.layer.asset.type) {
                    case AssetType.Text:
                        return 'Text Layer';
                    case AssetType.Shape:
                        return 'Shape Layer';
                    case AssetType.Image:
                        return 'Image Layer';
                    default:
                        return 'Layer';
                }
                }
                return this.layer.name;

            },
            layerIconName() {
                let name;
                switch(this.layer.asset.type) {
                    case AssetType.Text:
                        name= 'text';
                    break;
                    case AssetType.Shape:
                    name= 'shape';
                    break;

                    case AssetType.Image:
                    default:
                        name= 'photo';
                }
                return name;
            }
        },
        methods: {
            ...mapActions([
                'setFocusedLayer',
                'deleteLayer',
                'centerLayer',
                'renameLayer'
            ]),
            handleDeleteLayer() {
                this.deleteLayer(this.layer);
            },
            handleCenterLayer() {
                this.centerLayer(this.layer);
            },
            handleRenameLayer() {
                this.isRenaming = true;
                this.$nextTick(() => {
                    this.$refs['input-layer-name'].focus();
                    this.$refs['input-layer-name'].value = this.layer.name || this.layerTitle;
                });
            },
            handleSaveNewLayerName() {
                this.isRenaming = false;
                this.renameLayer(this.newLayerName);
            },
            handleMenuClose() {
                this.menuOpen = false;
            }
        }
    };
</script>

<style lang="scss">
.layer-config-button {
    max-width: 25px;
}

.layer-preview-item {
    align-items: center;
    cursor: pointer;
}

.layer-preview-title {
    border-radius: 5px;
    padding: 5px 15px;
    width: 230px;
    display: flex;
    gap: 8px;
}

.layer-preview-item.focused .layer-preview-title {
    background-color:  rgba(0, 162, 234, 0.15);
}

.layer-name-input {
    outline: none;
}

.drag-icon {
    cursor: move;
}

</style>