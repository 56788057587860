import { ImageAsset } from '@/creative-studio/domain/assets/models/ImageAsset';
import { AssetType } from '@/creative-studio/domain/assets/models/types';

export class ImageAssetMapper {
    static toDomain(assetResource, { loadFromRemote = false } = {}) {

        if(loadFromRemote) {
            const { id } = assetResource;

            const name = assetResource.filename;
            const thumbnail = assetResource.url;
            const type = AssetType.Image;

            const file = {
                id,
                name,
                path: thumbnail,
                size: assetResource.file_size_kb,
                type,
                width: assetResource.width,
                height: assetResource.height,
            };

            // create a default width and height for the image based on the aspect ratio

            const imageAsset = new ImageAsset(id, type, name, thumbnail);

            imageAsset.file = file;

            return imageAsset;
        }
        const { id, type, name, thumbnail, file } = assetResource;

        const imageAsset = new ImageAsset(id, type, name, thumbnail);

        if (file) {
            imageAsset.file = file;
        }

        return imageAsset;
    }

    static toRequest(file) {
        const imageResource = {
            type: AssetType.Image,
            name: file.name,
            ownership: 'user',
            transparent: true,
            logo_type_id: 1234,
            fileId: file.id,
            thumbnail: file.path,
            // Additional properties that should be returned from BE after creation
            // The properties belongs to the asset details
            file,
            created_at: {
                date: '2021-11-12 00:45:44.000000',
                timezone: 'UTC',
                timezone_type: 3
              },
            user: {
                name: 'Some Name',
                id: 1234
              },
              expires_at: {
                date: '2021-11-12 00:45:44.000000',
                timezone: 'UTC',
                timezone_type: 3
              },
              categories: [
                {
                  name: 'F-150',
                  id: 1234,
                  path: [
                    'Verticals',
                    'Automotive',
                    'Brands',
                    'Ford'
                  ]
                }
              ],
        };

        return imageResource;
    }
}


