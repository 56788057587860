import { GOOGLE_FONTS_API_KEY } from '@/components/creative-studio/common/constants';
import { CREATIVE_STUDIO_FONTS } from '@/components/creative-studio/common/fonts';
import { FontManager } from '@buyerbridge/font-manager';

export class CreativeStudioFontManager {

    fontManager;
    fontList;

    static initFontManager = async(defaultFont = 'Open Sans') => {
        const newInstance = new CreativeStudioFontManager();

        const options = {
            families: CREATIVE_STUDIO_FONTS
        };

        newInstance.fontManager = new FontManager(
            GOOGLE_FONTS_API_KEY,
            defaultFont,
            options
        );
        const fontList = await newInstance.fontManager.init();
        newInstance.fontList = fontList;
        return newInstance;
    };

    setOnChangeCallback(callback) {
        this.fontManager.setOnChange(callback);
    }

    addFont(fontFamily, { hasToRunOnChangeCallback } = { hasToRunOnChangeCallback: true }, url) {
        this.fontManager.addFont(fontFamily, true, url);
        this.fontManager.setActiveFont(fontFamily, hasToRunOnChangeCallback);
    }

    getFontList() {
        return this.fontList;
    }

    preloadFonts() {
        CREATIVE_STUDIO_FONTS.forEach(font => {
            this.addFont(font.family, { hasToRunOnChangeCallback: false }, font.url);
        });
    }
}


