var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"card",class:{
        'styled-card' : true,
        'panel-collapsed' : !_vm.isOpen,
        'centered': _vm.centered,
        'simple': _vm.simple,
        'full-height': _vm.fullHeight
    },style:(_vm.wrapperStyles)},[(!_vm.hideHeader)?_c('div',{staticClass:"styled-card-header"},[_c('div',{staticClass:"styled-card-heading"},[_vm._t("heading")],2),(_vm.tabs.length)?[_c('ul',{staticClass:"tab-selectors",class:_vm.tabAlign},_vm._l((_vm.tabs),function(tab,i){return _c('li',{key:i,staticClass:"selector",class:{active: tab.name == _vm.activeTab.name}},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.currentTab = tab}}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(tab.name))])])])}),0)]:_vm._e(),_c('div',{staticClass:"styled-card-actions"},[_vm._t("action-buttons")],2),(!_vm.hideToggle)?_c('button',{staticClass:"styled-card-toggle",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('icon',{staticClass:"toggle-icon",attrs:{"name":"collapse"}})],1):_vm._e()],2):_vm._e(),_c('div',{staticClass:"styled-card-body"},[(_vm.showMore)?[_c('div',{staticClass:"styled-card-body-inner show-more",class:_vm.isOpen ? 'open' : null},[_vm._t("default")],2),_c('button',{staticClass:"show-more-button",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('icon',{staticClass:"toggle-icon",attrs:{"name":"eye","size":"18"}}),(_vm.isOpen)?_c('span',[_vm._v("Show Less")]):_c('span',[_vm._v("Show More")])],1)]:[_c('transition-expand',[(_vm.isOpen)?_c('div',{staticClass:"styled-card-body-inner"},[(_vm.tabs.length)?[_vm._t(_vm.activeTab.slot)]:[_vm._t("default")]],2):_vm._e()])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }