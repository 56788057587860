/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sound-off': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" _fill="#8F9EA6" d="M3.963 5.65H1.321C.595 5.65 0 6.256 0 6.996v5.002c0 .74.595 1.346 1.321 1.346h2.642V5.65zm7.487-3.499a1.076 1.076 0 00-1.101 0L5.064 5.314v8.366l5.285 3.163c.176.112.374.157.55.157a.98.98 0 00.55-.157c.353-.202.551-.56.551-.964V3.139c0-.404-.22-.786-.55-.988z"/>'
  }
})
