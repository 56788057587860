/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-right': {
    width: 12,
    height: 20,
    viewBox: '0 0 12 20',
    data: '<path pid="0" d="M0 2.333L2.27 0 12 10 2.27 20 0 17.667 7.46 10z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
