import formatInsightsData from '@/apis/facebook/formatInsightsData';
import moment from 'moment';
import { differenceInDays } from 'date-fns';

const attributionWindows = [
    '1d_view',
    '1d_click',
    '7d_view',
    '7d_click',
    '28d_view',
    '28d_click'
];

const metricsTotalsFormatted = (state, getters, rootState, rootGetters) => {
    if (state.metricsTotals == null) {
        return null;
    }

    return formatInsightsData(state.metricsTotals, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows
    });
};

const metricsTotalsPreviousMonthFormatted = (state, getters, rootState, rootGetters) => {
    if (state.metricsTotalsPreviousMonth == null) {
        return null;
    }

    return formatInsightsData(state.metricsTotalsPreviousMonth, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows
    });
};

const metricsTotalsPrevious90DaysFormatted = (state, getters, rootState, rootGetters) => {
    if (state.metricsTotalsPrevious90Days == null) {
        return null;
    }

    return formatInsightsData(state.metricsTotalsPrevious90Days, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows
    });
};


const metricsIntervalsFormatted = (state, getters, rootState, rootGetters) => {
    if (!state.metricsIntervals.length) {
        return [];
    }

    const formatter = (data) => formatInsightsData(data, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows
    });

    return state.metricsIntervals.map(formatter);

};

const metricsCampaignsFormatted = (state, getters, rootState, rootGetters) => {
    if (!state.metricsCampaigns.length) {
        return [];
    }

    const formatter = (data) => formatInsightsData(data, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows
    });

    return state.metricsCampaigns.map(formatter);
};

const metricsInventoryFormatted = (state) => {
    if (!state.metricsInventory.length) {
        return [];
    }

    const formatter = (vehicle) => {
        const keys = Object.keys(vehicle);
        keys.forEach(key => {
            if(!(vehicle[key] instanceof Object)) {
                vehicle[key] = {
                    format: 'raw',
                    formatted: vehicle[key],
                    value: vehicle[key]
                };
            }
        });
        return vehicle;

    };

    return state.metricsInventory.map(formatter);
};

const metricsTotalsComparisonFormatted = (state, getters, rootState, rootGetters) => {
    if (state.metricsTotalsComparison == null) {
        return null;
    }

    return formatInsightsData(state.metricsTotalsComparison, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows
    });
};


/**
 * Determines if the current dealer is eligible to retrieve insights
 *
 * @returns Boolean
 */
const eligibleForMetrics = (state, getters, rootState, rootGetters) => {

    return (
        rootGetters.dealerHasFeatures(['facebook_ad_account']) &&
        !!rootGetters.dealerFacebookAdAccount
    );
};

/**
 * Determines if metrics are available
 *
 * @returns Boolean
 */
const hasMetrics = (state) => {
    return state.metricsTotals !== null;
};

/**
 * The total loaded offline events
 */
 const offlinePurchaseEvents = (state) => {
    return state.offlineEvents.filter(e => e.event === 'Purchase');
};

/**
 * The total loaded offline events
 */
 const currentOfflinePurchaseEvents = (state) => {
    return state.offlineEventsCurrent.filter(e => e.event === 'Purchase');
};

/**
 * The total loaded offline events
 */
 const totalOfflinePurchaseEvents = (state, getters) => {
    return getters.offlinePurchaseEvents
                    .reduce((sum,event)=> {
                        return sum + event.count;
                    }, 0);
};

/**
 * The date of the last offline purchase event from the
 * given array of current offline events
 */
const lastOfflinePurchaseEventDate = (state, getters) => {

    // Find the days in the array with more than one event
    const activePurchaseEvents = getters.currentOfflinePurchaseEvents
                                    .filter(event => event.count > 0);

    if (!activePurchaseEvents.length) {
        return null;
    }

    const lastEvent = activePurchaseEvents[activePurchaseEvents.length - 1];

    return moment.unix(lastEvent.time).toDate();
};

const canViewOfflinePurchaseAttribution = (state, getters, rootState) => {
    if (getters.lastOfflinePurchaseEventDate == null) {
        return false;
    }

    const diff = differenceInDays(getters.lastOfflinePurchaseEventDate, rootState.metrics.dateRange.endDate);

    return diff <= 0;
};

/**
 * Formats local storage data
 */
const storage = (state) => {
    return {
        campaigns: state.campaigns,
        campaignsSelected: state.campaignsSelected
    };
};

/**
 * Determines if any tracked metrics are loading
 */
 const metricsLoading = (state) => {
    return (
        state.campaignsLoading ||
        state.offlineEventsLoading ||
        state.offlineEventsCurrentLoading ||
        state.metricsTotalsLoading ||
        state.metricsIntervalsLoading ||
        state.metricsCampaignsLoading ||
        state.metricsTotalsComparisonLoading ||
        state.metricsTotalsPreviousMonthLoading ||
        state.metricsTotalsPrevious90DaysLoading
    );
};

export default {
    storage,
    eligibleForMetrics,
    hasMetrics,
    offlinePurchaseEvents,
    currentOfflinePurchaseEvents,
    totalOfflinePurchaseEvents,
    lastOfflinePurchaseEventDate,
    canViewOfflinePurchaseAttribution,
    metricsTotalsFormatted,
    metricsTotalsPreviousMonthFormatted,
    metricsTotalsPrevious90DaysFormatted,
    metricsIntervalsFormatted,
    metricsCampaignsFormatted,
    metricsInventoryFormatted,
    metricsTotalsComparisonFormatted,
    metricsLoading
};
