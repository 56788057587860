<template>
    <div>
        <styled-menu :custom-content-class="'styled-menu-content layered-designer-quick-insert-menu'">
            <template #activator="props">
                <slot
                    name="activator"
                    v-bind="props" />
            </template>

            <button @click="handleQuickInsertText">
                <div class="quick-insert-inner-option">
                    <icon
                        name="text"
                        width="15"
                        height="15" />
                    <span class="quick-insert-text">Text</span>
                </div>
            </button>
            <button @click="handleQuickInsertImage">
                <div class="quick-insert-inner-option">
                    <PhotoIcon class="svg-icon" />
                    <span class="quick-insert-text">Image</span>
                </div>
            </button>
            <button @click="handleQuickInsertShape">
                <div class="quick-insert-inner-option">
                    <icon
                        name="shape"
                        width="15"
                        height="15" />
                    <span class="quick-insert-text">Shape</span>
                </div>
            </button>
            <div
                v-if="!isAssetLibraryActive"
                class="upload-option">
                <asset-uploader
                    view-type="button"
                    @asset-added="handleUploadedAsset" />
            </div>
        </styled-menu>
    </div>
</template>

<script>
    import Icon from '@/components/globals/Icon';
    import StyledMenu from '@/components/globals/StyledMenu';
    import { AssetLibraryMode } from '@/components/creative-studio/common/types';
    import { AssetServiceFactory } from '@/creative-studio/domain/assets/services/AssetServiceFactory';
    import { AssetType } from '@/creative-studio/domain/assets/models/types';
    import EventBus from '@/event-bus';
    import AssetUploader from '@/components/creative-studio/assets/AssetUploader';
    import { mapActions, mapGetters } from 'vuex';
    import { AssetMapper } from '@/creative-studio/domain/assets/services/api/mappers/AssetMapper';
    import PhotoIcon from '@/components/creative-studio/svgs/PhotoIcon.vue';

    export default {
        components: {
            Icon,
            StyledMenu,
            AssetUploader,
            PhotoIcon
        },
        data() {
            return {
                iconPath: 'M4.168 6.997a2.499 2.499 0 1 0 0-4.997 2.499 2.499 0 0 0 0 4.997Zm9.817.408a.833.833 0 0 0-1.396-.056l-4.256 6.013-2.606-2.76a.833.833 0 0 0-1.304.117l-4.3 6.606a.883.883 0 0 0 .714 1.333H19.16a.883.883 0 0 0 .723-1.321l-5.898-9.932Z'
            };
        },
        computed: {
           ...mapGetters([
                'isAssetLibraryActive',
            ]),
        },
        methods: {
            ...mapActions([
                'insertSelectedAssetsAsLayers',
                'setAssetLibraryAssetType',
                'setAssetLibraryMode',
                'setAssetLibraryActiveStatus',
            ]),
            handleQuickInsertImage() {
                this.triggerOpenLibrary(AssetType.Image);
            },
            handleQuickInsertShape() {
                this.triggerOpenLibrary(AssetType.Shape);
            },
            triggerOpenLibrary(assetType) {
                this.setAssetLibraryMode(AssetLibraryMode.Insert);
                this.setAssetLibraryAssetType(assetType);
                this.setAssetLibraryActiveStatus(true);
            },
            handleQuickInsertText() {
                const assetService = AssetServiceFactory.createApiAssetService();
                const textAsset = assetService.createTextAsset();
                this.insertSelectedAssetsAsLayers([textAsset]);
            },
            handleFileUpload() {
                this.$refs['layer-workspace-upload'].$el.querySelector('label').click();
            },
            handleUploadedAsset(assets) {
                assets.map((asset) => {
                    const assetModel = AssetMapper.toDomain(asset, { loadFromRemote: true });
                    EventBus.$emit('new-asset-upload', assetModel);
                });
            }
        }
    };
</script>

<style lang="scss">
.layered-designer-quick-insert-menu {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        box-shadow: none !important;

    & > .styled-menu::before {
        border-color: transparent transparent $white transparent;
    }
    .styled-menu {
        background-color: $white;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        button {
            color: $gray-text;
            font-size: 14px;
            padding: 2px 10px 2px 10px;
        }

    }
    .quick-insert-inner-option {
        display: flex;
        align-items: center;
        padding: 5px 25px 5px 15px;
        width: 100%;
        border-radius: 5px;
        .svg-icon {
            margin: 0px 10px;
        }
        .quick-insert-text {
            font-style: normal;
        }
    }
    .quick-insert-inner-option:hover {
        background-color: rgba(0, 162, 234, 0.24);
    }
}

.quickinsert-card-icon {
    cursor: pointer;
}

.upload-option {
    background-color: $blue-bg;
    text-align: center;
    cursor: pointer;
    color: $white !important;
    font-size: 14px;
    padding: 5px 0px;
}

.upload-icon {
    margin-right: 5px;
}
</style>