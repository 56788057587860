<template>
    <svg style="height: 0px;">
        <defs>
            <component
                :is="gradientTag"
                :id="gradientId"
                :[gradientTransformName]="gradientTransform">
                <stop
                    v-for="(stop, index) in gradientStops"
                    :key="gradientId + 'stop_key_' + index"
                    :offset="stop.offset"
                    :stop-color="stop.color" />
            </component>
        </defs>
    </svg>
</template>

<script>
    import { GRADIENT_TYPES } from '@/creative-studio/domain/layered-designer/models/Layer';

    export default {
        props: {
            gradient: {
                type: Object,
                required: true
            },
            layerId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                diffBetweenRadAndDeg: 90
            };
        },
        computed: {
            gradientTag() {
                return this.gradient.type === GRADIENT_TYPES.LINEAR ? 'linearGradient' : 'radialGradient';
            },
            gradientId() {
                return `${this.layerId}_layer_gradient`;
            },
            gradientTransformName() {
                return this.gradient.type === GRADIENT_TYPES.LINEAR ? 'gradientTransform' : '';
            },
            gradientTransform() {
                return `rotate(${this.gradient.degree - this.diffBetweenRadAndDeg} 0.5 0.5)`;
            },
            gradientStops() {
                return [...this.gradient.points]
                .sort((pointA, pointB) => {
                    return pointA.left - pointB.left;
                })
                .map((point) => {
                    return {
                        color: `rgba(${point.red}, ${point.green}, ${point.blue}, ${point.alpha})`,
                        offset: point.left + '%'
                    };
                });
            }
        }
    };
</script>