/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indicator-dot': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<rect pid="0" width="14" height="14" rx="7" _fill="#8F9EA6" fill-rule="evenodd" fill-opacity=".15"/>'
  }
})
