/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 12,
    height: 20,
    viewBox: '0 0 12 20',
    data: '<defs><path pid="0" id="svgicon_arrow-left_a" d="M4 2.333L6.27 0 16 10 6.27 20 4 17.667 11.46 10z"/></defs><use _fill="#8F9EA6" transform="translate(-4)" xlink:href="#svgicon_arrow-left_a" fill-rule="evenodd"/>'
  }
})
