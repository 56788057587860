import Fuse from 'fuse.js'
import preMapped from '@/data/offline-events-pre-mapped-fields.json';

/**
 * Given an array of field settings and headers to map from
 * this function will loop through each variable header and
 * search each field's dictionary to identify matches from
 * which it will create a mapping object to be us ed by other
 * consumers (mainly the UploadMapper component)
 *
 * @param {array} fields
 * @param {array} headers
 * @param preset
 */
const autoMap = (fields, headers, preset) => {
    const mapping = {};
    const mappedHeaders = [];
console.log();
    // pre mapping
    // data: offline-events-pre-mapped.json
    if(preset !== 'Default') {
        Object.entries(preMapped[preset]).forEach(([key, value]) => {
            headers.forEach(header => {
                if (key === header) {
                    mapping[value] = header;
                }
            });
        });
        return mapping;
    }

    const fuse = new Fuse(fields, {
        keys: ['dictionary'], // Focus on the dictionary to drive results
        threshold: 0.3, // Only return results with high matching scores
        includeScore: true // For debugging
    })

    // Loop through each header to determine how they can be mapped to our fields
    headers.forEach(header => {

        // Search the Fuse index of fields for this header
        const results = fuse.search(header);
        
        // Now loop through all the matches to map them
        results.forEach(result => {

            const { item: field } = result;
            
            // If there's no mapping for the field and the 
            // header hasn't been mapped, map it up!
            if (
                !mapping[field.field] && 
                !mappedHeaders.includes(header)
            ) {
                mapping[field.field] = header;

                // Track that we've already mapped this
                mappedHeaders.push(header);
            }
        });
    
    });

    return mapping;
}

export default autoMap;