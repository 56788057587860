<template>
    <div
        v-show="open"
        class="shadow-picker-window">
        <div
            class="shadow-picker-container">
            <div
                class="shadow-picker-item">
                X
                <div class="blur-axis-input-wrapper">
                    <div
                        class="font-size-input-action minus"
                        @click="handleXOffsetAction('minus')">
                        -
                    </div>
                    <input
                        v-model="xOffset"
                        type="number"
                        min="1"
                        class="blur-axis-input"
                        name="">
                    <div
                        class="font-size-input-action plus"
                        @click="handleXOffsetAction('plus')">
                        +
                    </div>
                </div>
            </div>
            <div class="shadow-picker-item">
                Y
                <div class="blur-axis-input-wrapper">
                    <div
                        class="blur-axis-input-action minus"
                        @click="handleYOffsetAction('minus')">
                        -
                    </div>
                    <input
                        v-model="yOffset"
                        type="number"
                        min="1"
                        class="blur-axis-input"
                        name="">
                    <div
                        class="blur-axis-input-action plus"
                        @click="handleYOffsetAction('plus')">
                        +
                    </div>
                </div>
            </div>
            <div
                class="shadow-picker-item blur-item full-width"
                @click="updateBlurHandler">
                <span class="blur-label">Blur</span>
                <v-slider
                    v-model="blur"
                    class="blur-range-slider"
                    inverse-label
                    :label="blur + '%'"
                    thumb-color="white"
                    dense
                    min="0"
                    max="100" />
            </div>
            <color-picker
                v-model="color"
                @click="updateColorHandler" />
        </div>
    </div>
</template>

<script>
import { Sketch } from 'vue-color';
    import { mapActions ,mapState, mapGetters } from 'vuex';

    export default {
        components: {
            ColorPicker: Sketch,
        },
        props: {
            value: {
                required: true,
                type: Object
            },
            open: {
                required: true,
                type: Boolean
            }
        },
        data() {
            return {
                colorPickerOpen: false,
                xOffset: 0,
                yOffset: 0,
                blur: 0,
                color: '#000000',
            };
        },
        computed: {
            ...mapState({ mappedBlur:state=>state.layeredDesigner.mappedBlur,
                          mappedColor: state=> state.layeredDesigner.mappedColor, }),
            ...mapGetters([
                'focusedLayer',
            ]),
            colorString() {
                if (!this.color?.rgba) {
                    return this.color;
                }
                return `rgba(${this.color?.rgba?.r}, ${this.color?.rgba?.g}, ${this.color?.rgba?.b}, ${this.color?.rgba?.a})`;
            }
        },

        watch: {
            focusedLayer: {
                handler(value) {
                  this.blur = value.shadow.blur;
                    this.color = value.shadow.color;
                    this.xOffset = value.shadow.xOffset;
                    this.yOffset = value.shadow.yOffset;
                },
                deep: true
            },
            blur() {
                this.handleChange();
            },
            color() {
                this.handleChange();
            },
            xOffset() {
                this.handleChange();
            },
            yOffset() {
                this.handleChange();
            },
            value(newValue) {
                const { xOffset, yOffset, blur, color } = newValue;
                if (xOffset !== undefined) {
                    this.xOffset = xOffset;
                }

                if (yOffset !== undefined) {
                    this.yOffset = yOffset;
                }

                if (blur !== undefined) {
                    this.blur = blur;
                }

                if (color !== undefined) {
                    this.color = color;
                }
            }
        },async created() {
            await this.updateBlur();
            if(this.mappedBlur) this.blur = this.mappedBlur;
            await this.updateColor();
            if(this.mappedColor) this.color = this.mappedColor;
        },
        methods: {
            ...mapActions([
               'updateBlur',
               'updateColor',
        ]),
            updateColorHandler() {
                this.updateColor(this.colorString);
            },
            updateBlurHandler() {
                this.updateBlur();
            },
            handleChange() {
                const shadowData= {
                    xOffset: this.xOffset,
                    yOffset: this.yOffset,
                    blur: this.blur,
                    color: this.colorString
                };

                this.$emit('input', shadowData);
            },
            handleXOffsetAction(action) {
            if(action === 'plus') {
                this.xOffset++;
            } else {
                this.xOffset--;
            }
        },
        handleYOffsetAction(action) {
            if(action === 'plus') {
                this.yOffset++;
            } else {
                this.yOffset--;
            }
        },
        }
    };
</script>

<style lang="scss">

.shadow-picker-window {
    position: absolute;
    left: calc(50% - 100px);
    top: 40px;
    z-index: 100;
    width: 200px;
    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.shadow-picker-container {
    display: flex;
    flex-wrap: wrap;
}

.shadow-picker-item {
    width: 50% ;
    padding: 10px;
    text-align: center;
    .v-input--slider {
      margin-top: 0px !important;
}
.v-input__slot {
    margin-bottom: -8px !important;
}
}

.shadow-picker-item.full-width {
    width: 100%;
}

.shadow-picker-item.blur-item {
    text-align: left;
    margin-left: 15px;
}

.blur-label {
    font-size: 12px;
    color: $light-grey-title;
    margin-bottom: -20px;
}

.blur-range-slider  {
    .v-label {
        color: $black;
        font-size: 12px;
    }
    .v-slider__thumb {
        border: none;
        background: $white !important;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
    }
}

.blur-axis-input-wrapper {
    border: solid 1px $gray;
    padding: 2px 1px;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.3);
    width: 60px;
    margin-left: 10px;
    position: relative;
}

.shadow-picker-item .font-size-input-action.minus,
.shadow-picker-item .blur-axis-input-action.minus {
    position: absolute;
    left: 8px;
    top: 1px;
}
.shadow-picker-item .font-size-input-action.plus,
.shadow-picker-item .blur-axis-input-action.plus {
    position: absolute;
    right: 5px;
}


.blur-axis-input-wrapper input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blur-axis-input-action {
    color: $gray;
    transform: scale(1.5);
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.blur-axis-input {
    width: 20px;
    outline: none;
    margin-left: 1px;
    margin-right: 1px;

    text-align: center;
    font-size: 14px;

}

</style>
