import { File } from '@/creative-studio/domain/assets/models/File';

export class FileMapper {

    static toDomain(fileResource) {
        const { id, path, name, type, size, height, width } = fileResource;
        const file = new File(id);

        file.path = path;
        file.name = name;
        file.type = type;
        file.size = size;
        file.height = height;
        file.width = width;

        return file;
    }

    // TODO: Remove the async/await behavior when the final API is integrated
    static async toRequest(rawFileResource) {
        const formData = new FormData();
        formData.append('file', rawFileResource);
        // Mock data
        const fileTemporaryURL = URL.createObjectURL(rawFileResource);
        const { width, height } = await FileMapper.getImageHeightAndWidthFromDataUrl(fileTemporaryURL);
        const fileResource = {
            path: fileTemporaryURL,
            name: 'test_file.png',
            type: 'image/png',
            size: 417000,
            height,
            width
        };
        return fileResource;
    }

    static getImageHeightAndWidthFromDataUrl(dataUrlImage) {
        return new Promise(resolve => {
            const img = new Image();
            img.onload = () => {
                resolve({
                    height: img.height,
                    width: img.width
                });
            };
            img.src = dataUrlImage;
          });
    }
}