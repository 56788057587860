/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'switch': {
    width: 13,
    height: 12,
    viewBox: '0 0 13 12',
    data: '<path pid="0" d="M11.795 5.926c.552 0 1 .403 1 .899 0 2.008-1.818 3.644-4.052 3.644H3.91a.837.837 0 01-.005 1.267c-.195.176-.45.264-.706.264-.257 0-.512-.088-.708-.264l-1.7-1.53A.861.861 0 01.5 9.57c0-.24.106-.468.293-.637l1.7-1.529a1.079 1.079 0 011.414 0c.388.35.389.915.005 1.266h4.83c1.132 0 2.053-.827 2.053-1.845 0-.497.448-.9 1-.9zM9.387.264a1.079 1.079 0 011.414 0l1.7 1.529a.857.857 0 01.293.636.86.86 0 01-.292.637l-1.7 1.53a1.058 1.058 0 01-.708.263c-.255 0-.51-.088-.706-.263a.838.838 0 01-.004-1.267H4.55c-1.131 0-2.052.828-2.052 1.846 0 .497-.448.9-1 .9-.551 0-.999-.403-.999-.9 0-2.01 1.817-3.645 4.051-3.645h4.831A.837.837 0 019.387.264z" _fill="#00A2EA" fill-rule="evenodd"/>'
  }
})
