/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subscribe': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.875 0C18.601 0 20 1.4 20 3.125v13.75C20 18.601 18.6 20 16.875 20H3.125A3.125 3.125 0 010 16.875V3.125C0 1.399 1.4 0 3.125 0h13.75zm1.25 15.625A.625.625 0 0017.5 15H8.37a.625.625 0 100 1.25h9.13c.345 0 .625-.28.625-.625zm-.625-6.25a.625.625 0 110 1.25H8.37a.625.625 0 110-1.25h9.13zm.625-5a.625.625 0 00-.625-.625H8.37a.625.625 0 100 1.25h9.13c.345 0 .625-.28.625-.625zM5.613 3.181a.625.625 0 00-1.009-.737L3.283 4.253l-.278-.38a.625.625 0 10-1.01.737l.783 1.071a.625.625 0 001.01 0l1.825-2.5zm-.136 5.064c.28.204.34.595.136.874l-1.826 2.5a.625.625 0 01-1.01 0l-.782-1.072a.625.625 0 011.01-.737l.278.38 1.321-1.809a.625.625 0 01.873-.136zm.136 6.811a.625.625 0 10-1.009-.737l-1.321 1.809-.278-.38a.625.625 0 10-1.01.737l.783 1.071a.625.625 0 001.01 0l1.825-2.5z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
