import { cloneDeep, merge } from 'lodash';
import {
    OBJECT_TYPE_AD,
    OBJECT_TYPE_ADSET,
    OBJECT_TYPE_CAMPAIGN
} from '@/helpers/globals';
import getSelectionsByPlatform from '../helpers/getSelectionsByPlatform';
import { getDealerAdAccounts } from '@/components/onboarding/channel-setup/channels';

/**
 * The structure will come in like the example below which
 * makes it easier to normalize all the different objects together
 * but it doesn't work for the actual preview system.
 *
 * From this:
 * ```js
 * {
 *   "1234": { // campaigns
 *     "1234": { // ad sets
 *       "1234": null // ads
 *     }
 *   }
 * }
 * ```
 *
 * To this:
 * ```js
 * [{
 *   "external_id": "1234",
 *   "ad_sets": [{
 *     "external_id": "1234",
 *     "ads": [{
 *       "external_id": "1234"
 *     }]
 *   }]
 * }]
 * ```
 *
 */
const transformObjectTree = (structure) => {
    // Begin a map at the top of the structure which represent the
    // campaign IDs and their children (ad sets & ads)
    const campaigns = Object.entries(structure);
    return campaigns.map(([campaignId, adSetsIds]) => {
        const campaign = {
            external_id: campaignId
        };

        // Break out the campaign's properties whose keys
        // represent the the ad set IDs (the values are ads).
        // If these are set map them in and continue to ads
        const adSets = Object.entries(adSetsIds);
        if (adSets?.length) {
            campaign.ad_sets = adSets.map(([adSetId, adIdsObj]) => {

                const adSet = {
                    external_id: adSetId
                };

                // The ads don't have properties so we can
                // just use their keys.  If these are set
                // map them in!
                const adIds = Object.keys(adIdsObj);
                if (adIds?.length) {
                    adSet.ads = adIds.map(adId => ({ external_id: adId }));
                }

                return adSet;
            });
        }

        return campaign;
    });
};


/**
 * Runs transformations for the individual platforms to move the individual
 * object structure into the format that the preview system expects.
 *
 * From this:
 * ```js
 * {
 *   ad: [{ external_id: "1234" }], // Abbreviated from full object
 *   adset: [{ external_id: "1234" }], // Ditto
 *   campaign: [{ external_id: "1234" }], // Ditto
 * }
 * ```
 *
 * To this:
 * ```js
 * {
 *   "facebook": {
 *     "ad_account_id": "1234"
 *     campaigns: [{
 *       "external_id": "1234",
 *       "ad_sets": [{
 *         "external_id": "1234",
 *         "ads": [{
 *           "external_id": "1234"
 *         }]
 *       }]
 *     }]
 *   }
 * }
 * ```
 *
 * @param {String} platform The platform (e.g. `facebook`)
 * @param {Object} types The object types (e.g. `{ campaign, adset, ad }`)
 * @param {String} adAccount
 * @returns {Object}
 */
const getPlatformPreviewStructure = (platform, types, adAccount) => {

    const structure = {};

    // Handle ads
    const ads = types[OBJECT_TYPE_AD];
    if (ads) {
        for (const ad of ads) {
            merge(structure, {
                [ad.campaign_id]: {
                    [ad.ad_set_id]: {
                        [ad.external_id]: null
                    }
                }
            });
        }
    }

    // Handle ads sets
    const adSets = types[OBJECT_TYPE_ADSET];
    if (adSets) {
        for (const adSet of adSets) {
            merge(structure, {
                [adSet.campaign_id]: {
                    [adSet.external_id]: {}
                }
            });
        }
    }


    // Handle campaigns
    const campaigns = types[OBJECT_TYPE_CAMPAIGN];
    if (campaigns) {
        for (const campaign of campaigns) {
            merge(structure, {
                [campaign.external_id]: {}
            });
        }
    }

    return {
        ad_account_id: adAccount,
        campaigns: transformObjectTree(structure)
    };
};

/**
 * A Vuex action with a similar purpose to `getPreviewStructureFromState`
 * but designed to work outside of the current playbook management
 * state.  This is primarily used for the ads tab to preview deployed
 * playbooks.
 *
 * The data coming in needs the following minimal architecture
 * for each type (which can be sent independently)
 * ```js
 * {
 *   ad: [{
 *     external_id: "1234",
 *     campaign_id: "1234",
 *     ad_set_id: "1234"
 *   }],
 *   adset: [{
 *     external_id: "1234",
 *     campaign_id: "1234",
 *   }],
 *   campaign: [{
 *     external_id: "1234"
 *   }],
 * }
 * ```
 *
 * @param {Object} params
 * @param {Object} params.vuexParams Default Vuex params
 * @param {Object} params.data The data structure noted in the description
 * @returns {Object}
 */
export const getPreviewStructure = ({ rootState }, data) => {
    const adAccounts = getDealerAdAccounts(rootState.dealer.currentDealer);
    const previewStructure = {};

    for (const [platform, types] of Object.entries(data)) {
        const adAccount = adAccounts[platform];
        previewStructure[platform] = getPlatformPreviewStructure(platform, types, adAccount);
    }

    console.log('Generated structure:', JSON.stringify(previewStructure, null, 2));

    return previewStructure;
};

/**
 * A Vuex action tied to the store for easier state access
 * that restructures the entire state into the following format:
 *
 * ```js
 * {
 *   "facebook": {
 *     "ad_account_id": "1234"
 *     campaigns: [{
 *       "external_id": "1234",
 *       "ad_sets": [{
 *         "external_id": "1234",
 *         "ads": [{
 *           "external_id": "1234"
 *         }]
 *       }]
 *     }]
 *   }
 * }
 * ```
 *
 * @param {Object} params Default Vuex params
 * @returns {Object}
 */
export const getPreviewStructureFromState = ({ state, rootState }) => {

    const stateClone = cloneDeep(state);
    const platforms = getSelectionsByPlatform(stateClone);
    const adAccounts = getDealerAdAccounts(rootState.dealer.currentDealer);

    for (const [platform, types] of Object.entries(platforms)) {
        const adAccount = adAccounts[platform];
        platforms[platform] = getPlatformPreviewStructure(platform, types, adAccount);
    }

    console.log('Generated structure:', JSON.stringify(platforms, null, 2));

    return platforms;
};


export default {
    getPreviewStructure,
    getPreviewStructureFromState
};
