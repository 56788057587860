/* eslint-disable max-len */
import HttpClient from './http';
import { get } from 'lodash';
import formatInsightsData from './apis/facebook/formatInsightsData';

class InsightsApiRepository {

    constructor() {
        this.http = new HttpClient();
    }


    async getAgencyAdAccountInsights(config) {

        // Default fields
        const fields = [
            'account_id',
            'campaign_id',
            'campaign_name',
            'account_name',
            'clicks',
            'spend',
            'reach',
            'cpc',
            'cpm',
            'cpp',
            'ctr',
            'frequency',
            'impressions',
            'unique_clicks',
            'unique_ctr',
            'unique_inline_link_click_ctr',
            'unique_inline_link_clicks',
            'unique_link_clicks_ctr',
            'inline_link_click_ctr',
            'inline_link_clicks',
            'cost_per_action_type',
            'cost_per_unique_inline_link_click',
            'cost_per_unique_click',
            'cost_per_unique_outbound_click',
            'cost_per_thruplay',
            'cost_per_outbound_click',
            'cost_per_unique_action_type',
            'video_play_actions',
            'website_ctr',
            'unique_outbound_clicks_ctr',
            'unique_outbound_clicks',
            'unique_actions',
            'outbound_clicks_ctr',
            'outbound_clicks',
            'actions'
        ];

        // Set defaults
        config = {
            ...{
                dealerId: null,
                campaigns: [],
                startDate: null,
                endDate: null,
                filtering: null,
                insightsFiltering: [],
                campaignFiltering: [],
                attributionWindows: [],
                limit: 100,
                fields
            },
            ...config
        };

        const data = {
            fields: `name,status,insights.action_attribution_windows(${JSON.stringify(config.attributionWindows)}).time_range({"since":"${config.startDate}","until":"${config.endDate}"}){${config.fields.join(',')}}`,
            limit: config.limit
        };

        if (config.filtering) {
            data.filtering = config.filtering;
        }

        // Use root axios call to avoid get payload limitations
        const response = await this.http.post(`/agencies/${config.agencyId}/graph-api/business-manager/owned_ad_accounts`, data);

        const accounts = response.data.data.map(account => {

            // Campaigns
            account.campaigns = get(account, 'campaigns.data', null) || [];

            // With this type of query there's only ever 1 insights node
            const insights = get(account, 'insights.data[0]', null) || {};

            account.insights = formatInsightsData(insights, config);

            // Reformat metrics
            account.dealer_budget = account.dealer_budget ? parseFloat(account.dealer_budget) : null;
            account.id = account.id.replace('act_', '');
            account.daily_budget = this.getTotalDailyBudget(account.campaigns);

            return account;
        });

        return accounts;
    }

    async getDealerInventoryInsights(config) {

        // Set defaults
        config = {
            ...{
                dealerId: null,
                startDate: null,
                endDate: null,
                attributionWindows: []
            },
            ...config
        };

        // List out Facebook fields to retrieve for dev usability
        const fields = [
            'actions',
            'clicks',
            'spend',
            'reach',
            'cpc',
            'cpm',
            'cpp',
            'ctr',
            'frequency',
            'impressions',
            'outbound_clicks'
        ];
        let data = {
            fields: fields.join(','),
            breakdowns: 'product_id',
            time_range: {
                since: config.startDate, // start date as yyyy-mm-dd
                until: config.endDate // end date as yyyy-mm-dd
            },
            action_attribution_windows: config.attributionWindows,
            limit: 500
        };

        const response = await this.http.get(`/dealers/${config.dealerId}/graph-api/ad-account/insights`, data);
        const insights = response.data.map(insights => {
            return formatInsightsData(insights, config);
        });

        return insights;

    }

    getTotalDailyBudget(campaigns) {
        let totalActiveDailyBudget = null;
        campaigns.forEach(campaign => {

            // Only active campaigns
            if (campaign.effective_status !== 'ACTIVE' || !campaign.daily_budget) {
                return;
            }

            const dailyBudget = parseInt(campaign.daily_budget);

            // If it's zero don't add it up - this way we can leave totalActiveDailyBudget
            // as null if no campaigns are running
            if (dailyBudget === 0) {
                return;
            }

            totalActiveDailyBudget += dailyBudget / 100; // Facebook sends whole numbers
        });
        return totalActiveDailyBudget;
    }

    async getDealerInsights(config) {

        // Set defaults
        config = {
            ...{
                dealerId: null,
                campaigns: [],
                startDate: null,
                endDate: null,
                level: 'account',
                increment: null,
                attributionWindows: []
            },
            ...config
        };

        // List out Facebook fields to retrieve for dev usability
        const fields = [
            'account_id',
            'campaign_id',
            'campaign_name',
            'account_name',
            'clicks',
            'spend',
            'reach',
            'cpc',
            'cpm',
            'cpp',
            'ctr',
            'frequency',
            'impressions',
            'unique_clicks',
            'unique_ctr',
            'unique_inline_link_click_ctr',
            'unique_inline_link_clicks',
            'unique_link_clicks_ctr',
            'inline_link_click_ctr',
            'inline_link_clicks',
            'cost_per_action_type',
            'cost_per_unique_inline_link_click',
            'cost_per_unique_click',
            'cost_per_unique_outbound_click',
            'cost_per_thruplay',
            'cost_per_outbound_click',
            'cost_per_unique_action_type',
            'video_play_actions',
            'website_ctr',
            //'video_thruplay_watched_actions',
            //'video_30_sec_watched_actions',
            //'video_10_sec_watched_actions',
            'unique_outbound_clicks_ctr',
            'unique_outbound_clicks',
            'unique_actions',
            'outbound_clicks_ctr',
            'outbound_clicks',
            'actions',
            'action_values'
        ];
        let data = {
            level: config.level, // switch level
            time_range: {
                since: config.startDate, // start date as yyyy-mm-dd
                until: config.endDate // end date as yyyy-mm-dd
            },
            fields: fields.join(','),
            action_attribution_windows: config.attributionWindows
        };

        if (config.campaigns.length) {
            data.filtering = [{
                field: 'campaign.id',
                operator: 'IN',
                value: config.campaigns // selected campaign ids
            }];
        }

        // if a time increment has been requested append it
        if (config.increment) {
            data.time_increment = config.increment;
        }

        const response = await this.http.get(`/dealers/${config.dealerId}/graph-api/ad-account/insights`, data);
        const insights = response.data.map(insights => {
            return formatInsightsData(insights, config);
        });

        return insights;
    }
}

export default InsightsApiRepository;
