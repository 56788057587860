import getNumberFormats from '@/helpers/numberFormats';
import numeral from 'numeral';

const NumbersPlugin = {
    install(Vue, options) {
        // @todo make this dynamic in the future
        const numberFormats = getNumberFormats();
        Vue.prototype.$numberFormats = numberFormats;
        const numberFormatsAbbr = getNumberFormats(true);
        Vue.prototype.$numberFormatsAbbr = numberFormatsAbbr;
        Vue.prototype.$formatNumber = (number, format = numberFormats.decimal) => {
            return numeral(number).format(format);
        }
    }
};

export default NumbersPlugin;
