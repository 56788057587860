var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:{
        'action-button': true,
        'disabled': _vm.disabled,
        'light': _vm.light,
        'normal-text': _vm.normalText,
        'position-top': _vm.position === 'top',
        'position-right': _vm.position === 'right',
        'position-bottom': _vm.position === 'bottom',
        'position-left': _vm.position === 'left',
    },on:{"click":_vm.onClick}},[_c('span',[_c('button',{staticClass:"action-button-button",attrs:{"type":"button"}},[(_vm.loading)?_c('styled-loader',{attrs:{"size":_vm.size,"light":_vm.light}}):_c('icon',{staticClass:"action-button-icon",attrs:{"height":_vm.height || _vm.size,"width":_vm.width || _vm.size,"name":_vm.icon,"color":_vm.light ? 'white' : _vm.derivedColor}})],1),(!!_vm.$slots['default'])?_c('span',{staticClass:"action-button-text"},[_vm._t("default")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }