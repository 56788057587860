
import moment from 'moment';
import store from './store';
import { get } from 'lodash';

window.getUser = function() {
    return store.state.user.user;
};

window.getUserAgencyId = function() {
    return window.getUser().agency_id;
};

window.toKebabCase = function(string) {
    return string.replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase();
};

window.matchesUserRole = function(element) {
    return window.getUser().roles.data.some(function(role) {
        return element === role.name;
    });
};

window.matchesUserProduct = function(productId) {
    let dealers = (window.getUser().dealers? window.getUser().dealers : { data: [] }).data;

    return dealers.some(function(dealer) {
        let products = (dealer.products? dealer.products : { data: [] }).data;

        return products.some(function(product) {
            return productId === product.id;
        });
    });
};

window.isAdProduct = function(product) {
    const validAdProducts = [
        1, // Inventory Ads VDP 500
        2, // Inventory Ads VDP 1000
        3, // Inventory Ads VDP 2500
        4, // Inventory Ads Non-Guaranteed
        5, // Full Facebook
        7, // Plumbing Facebook
        8, // Plumbing Plus Facebook
        9, // Plumbing Turnkey Facebook
        11, // Plumbing Lite Facebook
        12, // Plumbing Pro Facebook
        13, // Ultimate
        14, // Service
        15, // New Car
        18, // Custom
        21, // Facebook Advertising
        22, // Facebook Advertising+
        27, // Tiktok Advertising+
        28, // Snapchat Advertising+
        29, // Pinterest Advertising+
        30, // Facebook AdvertisingLITE
        31, // Tiktok AdvertisingLITE
        32, // Snapchat AdvertisingLITE
        33, // Pinterest AdvertisingLITE
        34, // Tiktok Advertising
        35, // Snaphchat Advertising
        36, // Pinterest Advertising
    ];

    let productId = null;
    if (Number.isInteger(product)) {
        productId = product;
    } else {
        productId = product.id;
    }

    // If the given product ID is in the array it's an ad product
    if (validAdProducts.includes(productId)) {
        return true;
    }

    // Otherwise it's not
    return false;
};

window.dealerHasAdProduct = function(dealer) {

    // Retrieve the dealer's products
    const products = get(dealer, 'products.data', null) || [];

    // If one of the products in the array is an ad product this is true
    if (products.some(window.isAdProduct)) {
        return true;
    }

    // Otherwise they don't have any ad products
    return false;
};

window.dealerHasProducts = function(dealer, productIds) {

    // Retrieve the dealer's products
    const products = get(dealer, 'products.data', null) || [];

    // Return true if any of the products match the product IDs
    return products.some(product => productIds.includes(product.id));
};

window.userHasRole = function(roles) {
    return roles.some(window.matchesUserRole);
};

window.userIsInternal = function() {
    return window.userHasRole(['admin','super_admin','performance_manager','agency_dashboard']);
};

window.userHasProduct = function(productIds) {
    return productIds.some(window.matchesUserProduct);
};

window.userHasAgency = function() {
    return window.getUser().agency_id != null;
};

window.convertFromUtcToLocalTime = function(val) {
    let offset = new Date().getTimezoneOffset();
    return moment(val).subtract(offset, 'minutes').format('YYYY-MM-DD hh:mm:ss a');
};

window.convertFromLocalTimeToUtc = function(val) {
    let offset = new Date().getTimezoneOffset();
    return moment(val).add(offset, 'minutes').format('YYYY-MM-DD hh:mm:ss a');
};

/**
 * Extend Date() class to retrieve api format.
 */
window.Date.prototype.toApiFormat = function()
{
    let day = this.getDate();
    let monthIndex = this.getMonth();
    let year = this.getFullYear();
    return year + '-' + (monthIndex + 1) + '-' + day;
};
