import {
    PLATFORM_FACEBOOK,
} from '@/helpers/globals';
import {
    DEPLOYMENT_PLATFORMS,
    CAMPAIGN_OBJECTIVES
} from '@/components/ad-deployment/store/constants.js';

const minimums = DEPLOYMENT_PLATFORMS.reduce((acc, platform) => {
    acc[platform.name] = platform.budget;
    return acc;
}, {});

/**
 * Navigates the active state to get the current budget
 * based on a target platform
 *
 * @param {string} platform
 * @param {object} budgetState
 * @returns
 */
const getPlatformBudget = (platform, budgetState) => {

    const {
        schedule = '',
        platform_specific = {},
    } = budgetState;

    const platformData = platform_specific[platform] ?? {};

    const { level = '' } = platformData;

    // Navigate to the target budget field
    const budget = platformData[level]?.[`${schedule}_budget`];

    return budget ? parseFloat(budget) : '';
};

/**
 * Navigates the minimums config to get the lifetime budget for
 * a given platform.  All platforms (currently) handle lifetime
 * the same by getting the total based on the running days and
 * a variable minimum (each platform's is different)
 *
 * @param {string} platform The target platform
 * @param {float} budget The current budget
 * @param {float} days The total running days
 */
const validateLifetimeBudget = (platform, budget, days) => {

    const { lifetimePerDay } = minimums[platform] ?? {};
    const minBudget = days * lifetimePerDay;

    return budget < minBudget
        ? `The budget must be $${minBudget} ($${lifetimePerDay} / day) or greater`
        : true;
};

/**
 * Navigates the minimums config to get the daily budget for given
 * platform.  TikTok and Snap just have a fixed value. Facebook is
 * the exception which uses the number of ads.
 *
 * @param {string} platform The target platform
 * @param {float} budget The current budget
 * @param {array} ads The deployment's ads (for Facebook)
 * @returns
 */
const validateDailyBudget = (platform, budget, ads = []) => {

    const {
        daily: dailyMin,
        dailyPerAd: dailyPerAdMin
    } = minimums[platform] ?? {};

    let minBudget;

    if (platform === PLATFORM_FACEBOOK) {

        // @todo adjust this for eligible ads once it exists
        minBudget = ads.length * dailyPerAdMin;

        return budget < minBudget
            ? `The budget must be $${minBudget} ($${dailyPerAdMin} / ad) or greater`
            : true;

    }

    minBudget = dailyMin;

    return budget < minBudget
            ? `The budget must be $${minBudget} or greater`
            : true;
};


/**
 * Validates the bid cap for a given platform. The bid cap
 * must be greater than the minimum and less than the budget.
 *
 * @param {string} platform The platform to validate
 * @param {object} adDeploymentState The entire adDeployment state
 * @returns {string|boolean}
 */
const validateBidCap = ({ platform, adDeploymentState }) => {

    const level = adDeploymentState?.budget?.platform_specific?.[platform]?.level;
    const bidCapValue = adDeploymentState?.budget?.platform_specific?.[platform]?.[level]?.bid_cap;
    const bidCapMin = getBidCapMin(platform, adDeploymentState.optimization, adDeploymentState.budget.schedule);
    const budget = getPlatformBudget(platform, adDeploymentState.budget);

    if (bidCapValue < bidCapMin) {
        return `The bid cap must be $${bidCapMin} or greater`;
    }

    if (bidCapValue >= budget) {
        return 'The bid cap must be less than the budget';
    }

    return true;
};


/**
 * Navigates the campaign objectives config to get the bid cap
 * object for a given platform and optimization
 *
 * @param {string} platform
 * @param {object} optimization
 * @param {string} schedule
 * @returns {object|null}
 */
const getBidCapMin = (platform, optimization, schedule) => {

    const selectedCampaignObjective = optimization?.platform_specific?.[platform]?.objective;

    return CAMPAIGN_OBJECTIVES[platform].find(objectiveObject => objectiveObject.platform_value === selectedCampaignObjective)?.budget?.bid_cap?.minimum?.[schedule];
};


/**
 * Uses the active state to navigate budget minimums for a
 * given platform. If there's an error it will return a
 * string.  Otherwise, if the budget is valid it will return `true`
 *
 * @param {object} params
 * @param {object} params.platform The target platform
 * @param {object} params.days The number of days between the start/end date
 * @param {object} params.adDeploymentState The entire adDeployment state
 * @returns {boolean|string}
 */
const validatePlatformBudget = ({
    platform,
    days,
    adDeploymentState
}) => {

    // Navigate to the target budget field
    const budget = getPlatformBudget(platform, adDeploymentState.budget);

    // All Platforms require budget
    if (!budget) {
        return 'Budget is required';
    }

    const {
        schedule = '',
        start_date_time: startDate = '',
        end_date_time: endDate = ''
    } = adDeploymentState.budget;

    if (schedule === 'lifetime') {

        if (!startDate || !endDate) {
            return 'Start/end date required to validate';
        }


        return validateLifetimeBudget(platform, budget, days);
    }

    return validateDailyBudget(platform, budget, adDeploymentState.ads);
};

export {
    validatePlatformBudget,
    validateBidCap
};
