/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bold': {
    width: 14,
    height: 19,
    viewBox: '0 0 14 19',
    data: '<path pid="0" d="M0 0h5.798c2.69 0 4.633.381 5.83 1.144 1.199.762 1.798 1.966 1.798 3.612 0 1.11-.294 2.032-.882 2.769-.588.736-1.434 1.2-2.538 1.39v.13c1.371.251 2.38.747 3.025 1.488.646.74.969 1.744.969 3.009 0 1.707-.612 3.043-1.837 4.009C10.94 18.517 9.235 19 7.053 19H0V0zm3.193 7.85h3.072c1.336 0 2.311-.206 2.926-.618.614-.411.922-1.11.922-2.099 0-.892-.332-1.537-.996-1.936-.663-.399-1.716-.598-3.159-.598H3.193v5.25zm0 2.52v6.018h3.393c1.336 0 2.344-.25 3.026-.747.68-.499 1.022-1.285 1.022-2.36 0-.987-.348-1.719-1.042-2.196-.695-.476-1.75-.714-3.166-.714H3.193z" _fill="#8F9EA6"/>'
  }
})
