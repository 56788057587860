/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'light-bulb-icon': {
    width: 41,
    height: 40,
    viewBox: '0 0 41 40',
    data: '<path pid="0" d="M16.794 32.5v-1.583c0-1.43-1.296-2.749-2.298-3.76C12.983 25.63 12 23.742 12 21.416c0-4.372 3.583-7.917 8-7.917 4.419 0 8 3.545 8 7.917 0 2.326-.983 4.213-2.494 5.74-1.004 1.011-2.308 2.33-2.308 3.76V32.5H23V37l-1.5 1.5h-3L17 37v-4.5h-.206zM20 6.5v-6m-7 8l-3-5m-2 10l-5-3m3 11H0m8 7l-5 3m30-3l5 3m-3-10h6m-8-8l5-3m-10-2l3-5" _stroke="#909FA7" stroke-width="2" _fill="none" fill-rule="evenodd" stroke-linejoin="round"/>'
  }
})
