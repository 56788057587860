<template>
    <div>
        <icon-with-tooltip
            v-for="({ name, icon, iconSize }, id) in orderedSelectedChannels"
            :key="id"
            position="top"
            class="single-icon"
            :field-props="fieldProps"
            :channel="name"
            :class="`${name}-icon`"
            :has-tooltip="hasTooltip"
            :icon="icon"
            :color="iconColor(name)"
            :size="iconSize" />
    </div>
</template>

<script>
import IconWithTooltip from '@/components/ad-studio/editing/preview-step/IconWithTooltip';
import { channelPerField, supportedPlatforms } from '@/components/ad-studio/store/constants';

export default {
    name: 'IconsList',
    components: {
        IconWithTooltip,
    },
    props: {
        selectedChannels: {
            type: Array,
            required: true
        },
        validationResult: {
            type: Object,
            default: () => {}
        },
        fieldProps: {
            type: Object,
            required: true,
            default: () => {}
        },
    },
    data() {
        return {
            defaultIconColor: '#909FA8',
            invalidIconColor: '#FF3D00',
            supportedPlatforms,
        };
    },
    computed: {
        orderedSelectedChannels() {
            return this.supportedPlatforms.filter(channel => this.selectedChannels.includes(channel.name) && channelPerField(this.fieldProps.key).includes(channel.name));
        },
        hasTooltip() {
            return this.fieldProps.hasTooltip || false;
        }
    },
    methods: {
        iconColor(channel) {
            if(!this.validationResult) {
                return this.defaultIconColor;
            }

            return this.validationResult[channel] ? this.defaultIconColor : this.invalidIconColor;
        }
    }
};
</script>
