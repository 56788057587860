<template>
    <transition name="width">
        <div
            v-if="opened && selectedItem"
            class="asset-detail-sidebar">
            <div v-if="!loading">
                <div v-if="asset">
                    <div class="image-wrapper">
                        <selected-asset-image :image-url="asset.thumbnail" />
                    </div>
                    <selected-asset-detail :asset="asset" />
                    <div class="sidebar-footer">
                        <p
                            class="cancel-link"
                            @click="$emit('cancel-preview')">
                            Cancel
                        </p>
                    </div>
                </div>
                <div
                    v-else
                    class="text-center">
                    Error loading asset preview
                </div>
            </div>
            <div
                v-else
                style="display: flex; justify-content: center">
                <loader :label="false" />
            </div>
        </div>
    </transition>
</template>
<script>
import SelectedAssetImage from './SelectedAssetImage';
import SelectedAssetDetail from './SelectedAssetDetail';
import { AssetServiceFactory } from '@/creative-studio/domain/assets/services/AssetServiceFactory';
import { AssetType } from '@/creative-studio/domain/assets/models/types';

import Loader from '@/components/globals/Loader';

export default {
  components: {
    SelectedAssetImage,
    SelectedAssetDetail,
    Loader,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => {}
    },
    assetType: {
        required: false,
        type: String,
        default: AssetType.Image
    }
  },
  data() {
    return {
      asset: null,
      loading: false,
    };
  },
  watch: {
    selectedItem: {
      handler() {
        this.loadAssetDetails();
      },
    },
  },
  methods: {
    async loadAssetDetails() {
    try {
        this.loading = true;
        const assetService = AssetServiceFactory.createApiAssetService();
        this.asset = await assetService.showAsset(this.selectedItem?.id);
        this.loading = false;
    } catch {
        this.loading = false;
    }

    },
  },
};
</script>
<style lang="scss">
.asset-detail-sidebar {
  height: calc(100% - 100px - (#{$asset-library-modal-margin} * 2));
  width: 280px;
  position: fixed;
  overflow-y: scroll;
  max-height: 100%;
  right: $asset-library-modal-margin;
  top: $asset-library-modal-margin;
  padding-top: 30px;
  background-color: #fff;
  border-left: solid 1px $border-list;
}

.width-enter-active,
.width-leave-active {
  transition: width 0.5s;
}

.width-enter,
.width-leave-to {
  width: 0;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 20px 70px;
}

.image-wrapper {
    display: flex;
    justify-content: center;
}
.button {
  background-color: $blue-bg;
  color: #fff;
  padding: 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.cancel-link {
  color: $blue-bg;
  text-decoration: underline;
  cursor: pointer;
}
</style>