/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'undo': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.403 12.49c-.53-3.86-4.148-6.554-8.031-6.011a7.116 7.116 0 00-3.518 1.507l2.806 2.393-7.705 1.849.366-8.041 2.602 2.191a9.553 9.553 0 015.103-2.332c5.245-.723 10.104 2.915 10.836 8.102.732 5.186-1.931 4.201-2.46.341z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
