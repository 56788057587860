<template>
    <div class="google-links">
        <div v-if="currentDealer.internal_tag_manager_container_id || analyticsViewLink != null">
            <p class="section-title">
                Google Links
            </p>
            <template v-if="currentDealer.internal_tag_manager_container_id">
                <div class="mb-1">
                    <a
                        :href="`https://tagmanager.google.com/?hl=en#/container/accounts/${currentDealer.gtm_account_id}/containers/${currentDealer.internal_tag_manager_container_id}`"
                        target="_blank">
                        Tag Manager Container ({{ currentDealer.tag_manager_container_id }})
                    </a>
                </div>
            </template>
            <template v-if="analyticsViewLink != null">
                <div class="mb-1">
                    <a
                        :href="analyticsViewLink"
                        target="_blank">
                        Google Analytics View ({{ currentDealer.ua_code }})
                    </a>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ApiQuery from '@/api-query';
    export default {
        data() {
            return {
                analyticsViewLink: null
            };
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer,
            })
        },
        async created() {
            if (this.currentDealer.ua_code != null) {
                try {
                    const query = new ApiQuery('dealers/'+this.currentDealer.id+'/ga-property');
                    const response = await query.get();
                    if (response.data.defaultProfileId !== undefined) {
                        this.analyticsViewLink = 'https://analytics.google.com/analytics/web/#/report-home/a'+response.data.accountId+'w'+response.data.internalWebPropertyId+'p'+response.data.defaultProfileId;
                    }
                } catch(error) {
                    console.error(error);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
.google-links {
    margin-bottom: 30px;
}
.section-title {
    margin-top: 30px;
    font-size: 16px;
    background: #f3f5f6;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
</style>
