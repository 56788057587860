/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'browser': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.605 2C19.375 2 20 2.614 20 3.371V16.63c0 .757-.625 1.371-1.395 1.371H1.395C.625 18 0 17.386 0 16.629V3.37C0 2.614.625 2 1.395 2zm-.233 3.2H1.628a.23.23 0 00-.233.229V16.4a.23.23 0 00.233.229h16.744a.23.23 0 00.233-.229V5.429a.23.23 0 00-.233-.229zm-9.07 4.571a.23.23 0 01.233.229v4.571a.23.23 0 01-.233.229H3.488a.23.23 0 01-.232-.229V10a.23.23 0 01.232-.229h5.814zm7.21 0a.23.23 0 01.232.229v4.571a.23.23 0 01-.232.229h-5.814a.23.23 0 01-.233-.229V10a.23.23 0 01.233-.229h5.814zm0-2.742a.23.23 0 01.232.228v.914a.23.23 0 01-.232.229H3.488a.23.23 0 01-.232-.229v-.914a.23.23 0 01.232-.228h13.024zm.465-4.115a.692.692 0 00-.698.686c0 .379.312.686.698.686a.692.692 0 00.697-.686.692.692 0 00-.697-.686zm-2.791 0a.692.692 0 00-.698.686c0 .379.313.686.698.686a.692.692 0 00.698-.686.692.692 0 00-.698-.686zm-2.79 0a.692.692 0 00-.698.686c0 .379.312.686.697.686a.692.692 0 00.698-.686.692.692 0 00-.698-.686z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
