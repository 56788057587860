import { getChannelByPlatformKey } from '@/components/onboarding/channel-setup/channels';
import steps from '../steps';
import { differenceInSeconds } from 'date-fns';
import { DEPLOYMENT_PLATFORMS, DYNAMIC_DEPLOYMENT_TYPE, STATIC_DEPLOYMENT_TYPE } from './constants';

export default {
    availablePlatforms(state) {
        return DEPLOYMENT_PLATFORMS.filter(platform => !platform.comingSoon).map(platformConfig => {

            const ads = state.ads?.reduce((eligibleAds, ad) => {

                const isEligible = ad.configuration
                    ?.selected_platforms
                    ?.includes(platformConfig.name);

                // If the ad is mapped for the platform add it in!
                if (isEligible) {
                    eligibleAds.push(ad);
                }

                return eligibleAds;
            }, []);

            return {
                ads,
                ...getChannelByPlatformKey(platformConfig.name),
                ...platformConfig
            };
        });
    },
    selectedPlatformDetails(state) {
        return state.platforms.map(platform => (
            getChannelByPlatformKey(platform)
        ));
    },
    currentSteps(state, getters) {
        return steps
            .filter(step => {
                const { configuration: { campaignType, deploymentType } } = step;

                const stateDeploymentType = !state.deployment_type.length
                    ? STATIC_DEPLOYMENT_TYPE
                    : state.deployment_type;

                if (state.deployment_type === DYNAMIC_DEPLOYMENT_TYPE) {
                    return deploymentType.includes(stateDeploymentType);
                }

                return campaignType.includes(state.campaign_selection.selection) && deploymentType.includes(stateDeploymentType);
            })
            .map(step => {
                step.valid = step.isValid.call(null, { state, getters });

                return step;
            });
    },
    allStepsValid(state, getters) {
        return getters.currentSteps.every(step => step.valid);
    },
    getState(state) {
        return state;
    },
    getPlatforms(state) {
        return state?.platforms;
    },
    budgetSchedule(state) {
        return state?.budget;
    },
    totalRunningDays(state, getters) {
        const {
            start_date_time = '',
            end_date_time = ''
        } = getters.budgetSchedule ?? {};

        const seconds = differenceInSeconds(
            new Date(end_date_time),
            new Date(start_date_time),
        );

        return Math.ceil(seconds / 60 / 60 / 24);
    },
    totalEstimatedMonthlyBudget(state) {
        let totalEstimated = 0;

        state.platforms.forEach(platform => {
            const platformBudget = state.budget.platform_specific[platform]?.['campaign']?.['daily_budget'] ?? state.budget.platform_specific[platform]?.['adSet']?.['daily_budget'];
            const estimatedMonthly = +platformBudget * 30;
            totalEstimated += estimatedMonthly;
        });

        return totalEstimated;
    },
    /**
     * Returns active platforms information based on selected platforms on the first step of any deployment.
     * It will also return what's defined on the DEPLOYMENT_PLATFORMS constant.
     * @param state
     * @returns {array}
     */
    activePlatforms(state) {
        return DEPLOYMENT_PLATFORMS.filter(platform => state.platforms.includes(platform.name));
    },

    isStaticDeployment(state) {
        if (!state.deployment_type?.length) {
            return true;
        }

        return state.deployment_type === STATIC_DEPLOYMENT_TYPE;
    }
};
