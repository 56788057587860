/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'anchor-detail': {
    width: 76,
    height: 76,
    viewBox: '0 0 76 76',
    data: '<path pid="0" d="M52.87 0a.63.63 0 010 1.26l-14.25-.001V16.38a.62.62 0 01-1.24 0V1.259H23.13a.63.63 0 010-1.259h29.74zM23.13 76a.63.63 0 010-1.26l14.25.001V59.62a.62.62 0 111.24 0v15.121h14.25a.63.63 0 010 1.259H23.13zM0 23.123a.623.623 0 011.246 0L1.245 37.38H16.2a.62.62 0 110 1.24H1.245v14.257a.623.623 0 01-1.245 0V23.123zm76 29.754a.623.623 0 01-1.246 0l.001-14.257H59.8a.62.62 0 110-1.24h14.955V23.123a.623.623 0 011.245 0v29.754zM46.625 30c1.036 0 1.875.84 1.875 1.875v16.25c0 1.036-.84 1.875-1.875 1.875h-16.25a1.875 1.875 0 01-1.875-1.875v-16.25c0-1.036.84-1.875 1.875-1.875h16.25zm0 1.172h-16.25a.703.703 0 00-.703.703v16.25c0 .388.315.703.703.703h16.25a.703.703 0 00.703-.703v-16.25a.703.703 0 00-.703-.703z" _fill="#9CAAB1"/>'
  }
})
