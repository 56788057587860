<template>
    <div>
        <div
            v-for="annotation in currentDealer.facebook_page_annotations.data"
            :key="annotation.id">
            <page-link
                :refresh="refresh"
                :dealer="currentDealer"
                :annotation="annotation" />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import PageLink from '@/components/dealers/DealerID/QuickLinks/PageLink';
    export default {
        components: {
            PageLink
        },
        data() {
            return {
                refresh: 0
            };
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer,
            })
        }
    };
</script>