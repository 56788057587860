import { merge } from 'lodash';
import { getStateTypeMap } from './storeHelpers';

/**
 * Restructures the Vuex state into a singular object organized
 * by platform with the objects below.
 *
 * For example:
 * ```js
 * {
 *   facebook: {
 *     ads: [{ 'external_id': 1234', ...additional }] // abbreviated
 *   }
 * }
 * ```
 *
 *
 * @param {Object} state The Vuex playbook management state
 * @returns {Object}
 */
const getSelectionsByPlatform = (state) => {

    const stateMap = getStateTypeMap(state);

    const platforms = {};

    for (const [type, typeStates] of Object.entries(stateMap)) {

        const { objects, selected } = typeStates;

        // This will begin looping through each platform since that's how
        // we organize all the state in the store
        for (const [platform, platformObjects] of Object.entries(objects)) {
            const platformSelections = selected[platform];

            // Filter down the objects list to only those that are selected
            const selectedObjects = platformObjects.filter(object => (
                platformSelections.includes(object.external_id)
            ));


            if (!selectedObjects.length) {
                continue;
            }

            // If there's selected objects add them to the structure and
            // make a new structure each round and exploit lodash to shore up code
            const data = {
                [platform]: {
                    [type]: selectedObjects
                }
            };

            merge(platforms, data);
        }
    }

    return platforms;
};

export default getSelectionsByPlatform;