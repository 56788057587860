/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share': {
    width: 14,
    height: 18,
    viewBox: '0 0 14 18',
    data: '<path pid="0" d="M13.816 2.049a.443.443 0 00-.363-.538L5.992.257a.501.501 0 00-.566.382l-.317 1.509a.443.443 0 00.363.538l3.766.616C6.472 5.243 4.503 6.916 3.332 8.32c-1.17 1.404-2.174 4.34-3.01 8.81-.05.256-.086.413.087.49h1.774c.224 0 .224-.07.255-.196.654-3.675 1.43-6.21 2.33-7.606 1.031-1.602 2.968-3.113 5.808-4.532L9.75 9.028a.443.443 0 00.363.537l1.505.253a.501.501 0 00.566-.381l1.633-7.388z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
