<template>
    <styled-slideout
        v-model="infoPanelOpened"
        class="open-support-ticket-dialog">
        <div
            class="ma-5">
            <h3 class="primary--text font-weight-regular mb-3">
                Allowing Supported Platforms to Control Media Styles
            </h3>
            <p class="mb-3">
                When "Allow supported platforms to control media styles" is checked,
                BuyerBridge will send those platforms your media with minimal (or no)
                treatments in its native aspect ratio.
            </p>
            <p class="mb-5">
                While many platforms require media to be sent in specific dimensions to run ads on them,
                the ones that do allow for open-ended dimensions typically have their own methods of handling
                the various sizes to best fit their placements and, as a result, generally recommend letting
                their platform handle media display. Since BuyerBridge covers multiple channels we support
                both options to provide you with a fluid experience across the board.
            </p>
            <h2 class="mb-3">
                Media Style Placement Examples
            </h2>
            <p class="mb-3">
                With Meta offering the most comprehensive treatment for different dimensions, we have
                provided the tool below to give you an idea of how they translate some common aspect ratios.
            </p>

            <div class="meta-previews">
                <meta-media-types-dropdown
                    :selected-meta-preview="selectedMetaPreview"
                    :show-info="false"
                    :hide-previews="[INSTAGRAM_DESKTOP_FEED]"
                    @show-preview="showPreview" />
                <div
                    v-if="selectedMetaPreview === FACEBOOK_MOBILE_FEED"
                    class="meta-images">
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/mobile-feed/meta-mf-9-16.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/mobile-feed/meta-mf-2-3.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/mobile-feed/meta-mf-4-5.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/mobile-feed/meta-mf-1-1.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/mobile-feed/meta-mf-16-9.png">
                    </div>
                </div>
                <div
                    v-if="selectedMetaPreview === FACEBOOK_DESKTOP_FEED"
                    class="meta-images">
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/desktop-feed/meta-df-9-16.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/desktop-feed/meta-df-2-3.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/desktop-feed/meta-df-4-5.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/desktop-feed/meta-df-1-1.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/desktop-feed/meta-df-16-9.png">
                    </div>
                </div>
                <div
                    v-if="selectedMetaPreview === FACEBOOK_STORY"
                    class="meta-images">
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/story/meta-s-9-16.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/story/meta-s-2-3.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/story/meta-s-4-5.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/story/meta-s-1-1.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/story/meta-s-16-9.png">
                    </div>
                </div>
                <div
                    v-if="selectedMetaPreview === FACEBOOK_REEL"
                    class="meta-images">
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/reel/meta-r-9-16.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/reel/meta-r-2-3.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/reel/meta-r-4-5.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/reel/meta-r-1-1.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/meta/reel/meta-r-16-9.png">
                    </div>
                </div>
                <div
                    v-if="selectedMetaPreview === INSTAGRAM_MOBILE_FEED"
                    class="meta-images">
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/mobile-feed/instagram-mf-9-16.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/mobile-feed/instagram-mf-2-3.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/mobile-feed/instagram-mf-4-5.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/mobile-feed/instagram-mf-1-1.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/mobile-feed/instagram-mf-16-9.png">
                    </div>
                </div>
                <div
                    v-if="selectedMetaPreview === INSTAGRAM_STORY"
                    class="meta-images">
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/story/instagram-s-9-16.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/story/instagram-s-2-3.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/story/instagram-s-4-5.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/story/instagram-s-1-1.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/story/instagram-s-16-9.png">
                    </div>
                </div>
                <div
                    v-if="selectedMetaPreview === INSTAGRAM_REEL"
                    class="meta-images">
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/reel/instagram-r-9-16.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/reel/instagram-r-2-3.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/reel/instagram-r-4-5.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/reel/instagram-r-1-1.png">
                    </div>
                    <div class="img-wrapper">
                        <img src="/img/preview-examples/instagram/reel/instagram-r-16-9.png">
                    </div>
                </div>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import MetaMediaTypesDropdown from '@/components/ad-studio/editing/preview-step/MetaMediaTypesDropdown';
import {
FACEBOOK_MOBILE_FEED,
FACEBOOK_DESKTOP_FEED,
FACEBOOK_STORY,
FACEBOOK_REEL,
INSTAGRAM_MOBILE_FEED,
INSTAGRAM_DESKTOP_FEED,
INSTAGRAM_STORY,
INSTAGRAM_REEL
} from '@/helpers/globals';

export default {
    components: {
        StyledSlideout,
        MetaMediaTypesDropdown
    },
    data() {
        return {
            FACEBOOK_MOBILE_FEED,
            FACEBOOK_DESKTOP_FEED,
            FACEBOOK_STORY,
            FACEBOOK_REEL,
            INSTAGRAM_MOBILE_FEED,
            INSTAGRAM_DESKTOP_FEED,
            INSTAGRAM_STORY,
            INSTAGRAM_REEL,
            selectedMetaPreview: FACEBOOK_MOBILE_FEED,
        };
    },
    computed: {
        infoPanelOpened: {
            get() {
                return this.$store.state.adStudio.infoPanelOpened;
            },
            set(isOpen) {
                if (!isOpen) {
                    this.close();
                }
            }
        },
    },
    methods: {
        close() {
            this.$store.commit('adStudio/setInfoPanelStatus', false);
        },
        showPreview(type) {
            this.selectedMetaPreview = type;
        },
    }
};
</script>

<style lang="scss" scoped>
.meta-images {
    display: grid;
    grid-gap: 6%;
    grid-template-columns: 27% 27% 27%;
}
.img-wrapper img {
    max-width: 100%;
    height: auto;
}
</style>