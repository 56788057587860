<template>
    <div
        class="layered-designer-wrapper d-flex">
        <div
            v-if="notAllowedActive"
            class="not-allowed-placeholder"
            @click="notAllowedMessage" />
        <layer-quick-selector-panel
            :focused-layer="focusedLayer"
            @on-toolbar-visible="handleQuickSelectorVisibility" />
        <card-canvas
            :style="canvasContainerStyle"
            :class="['card-canvas-container','scrollable-canvas', {'card-canvas-wrapped-by-toolbar': isQuickSelectorOpened }]"
            @client-height-exceed="handleOverflowVisivility" />
    </div>
</template>

<script>
    import CardCanvas from './CardCanvas';
    import { mapGetters } from 'vuex';
    import LayerQuickSelectorPanel from './layer-quick-selector-panel/LayerQuickSelectorPanel.vue';

    export default {
        components: {
            CardCanvas,
            LayerQuickSelectorPanel
        },
        data() {
            return {
                canvasContainerStyle: {
                    overflow: 'auto'
                },
                isQuickSelectorOpened: true
            };
        },
        computed: {
            ...mapGetters([
                'isLayeredDesignerActive',
                'currentCard',
                'focusedLayer'
            ]),
            notAllowedActive() {
                const currentDimensionId = this.currentCard?.dimensions?.id;
                if (currentDimensionId === 2 || currentDimensionId === 3) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        watch: {
            isLayeredDesignerActive() {
                this.handleScrollToCenter();
            },
            currentCard() {
                this.$nextTick(() =>   this.handleScrollToCenter({ resetFirst: true }));

            }
        },
        mounted() {
        window.addEventListener('resize', this.handleScrollToCenter);
        },
        methods: {
            notAllowedMessage() {
                this.$flash('Changes can only be performed on the 1:1 card size', 'red');
            },
            handleScrollToCenter({ resetFirst =  false } = {}) {
                const scrollEl = this.$el.querySelector('.scrollable-canvas');
                if(resetFirst) {
                    scrollEl.scrollTop = 0;
                }

                scrollEl.scrollLeft = (scrollEl.scrollWidth - scrollEl.clientWidth ) / 2;
                scrollEl.scrollTop = (scrollEl.scrollHeight - scrollEl.clientHeight) / 2;
            },
            handleOverflowVisivility(isVisible) {
                this.canvasContainerStyle.overflow = isVisible ? 'auto' : 'auto';
            },
            handleQuickSelectorVisibility(value) {
               this.isQuickSelectorOpened = value;
               this.$nextTick(() => this.handleScrollToCenter());
            }
        },
    };
</script>

<style lang="scss" scoped>
.layered-designer-wrapper{
    min-height: 100%;
    min-width: 100%;
    max-height: calc(100vh - (180px +  (#{$layered-designer-modal-margin} * 2)) );
    margin-top: 85px;
    margin-bottom: 30px;
    position: relative;
}

@media only screen and (max-height: 990px) {
    .layered-designer-wrapper {
        max-height: calc(100vh - (145px +  (#{$layered-designer-modal-margin} * 2)) );
  }
}

.card-canvas-container {
    overflow: auto;

}

.card-canvas-wrapped-by-toolbar {
    margin-left: $quick-selector-open-width;
    margin-bottom: -5px;
}

.not-allowed-placeholder {
    position: absolute;
    top: 0;
    left: 350px;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 99;
}
</style>