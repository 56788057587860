/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fb_reels_thumbsup_icon': {
    width: 54,
    height: 54,
    viewBox: '0 0 54 54',
    data: '<defs><filter id="svgicon_fb_reels_thumbsup_icon_b" width="163.2%" height="163.2%" x="-31.6%" y="-31.6%" filterUnits="objectBoundingBox"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.300453453 0"/></filter><circle pid="0" id="svgicon_fb_reels_thumbsup_icon_a" cx="19" cy="19" r="19"/></defs><g _fill="none" fill-rule="evenodd" transform="translate(8 8)"><use xlink:href="#svgicon_fb_reels_thumbsup_icon_a" _fill="#000" filter="url(#svgicon_fb_reels_thumbsup_icon_b)"/><use xlink:href="#svgicon_fb_reels_thumbsup_icon_a" _fill="#D8D8D8" fill-opacity=".198"/><path pid="1" _fill="#FFF" fill-rule="nonzero" d="M27.473 20.055c.07.422.105.843.035 1.265-.07.457-.176.844-.387 1.16.07.422.035.844-.07 1.266-.14.422-.317.809-.563 1.09 0 .984-.246 1.758-.808 2.32-.563.563-1.371.844-2.39.844h-1.442c-.703 0-1.477-.105-2.32-.316-.493-.106-1.196-.317-2.04-.633l-1.054-.352c-.352-.07-.598-.14-.809-.14v.316c0 .316-.14.598-.352.809A1.09 1.09 0 0114.5 28h-3.375c-.316 0-.598-.105-.809-.316a1.114 1.114 0 01-.316-.809v-9c0-.281.105-.563.316-.773.211-.211.493-.352.809-.352H14.5c.387 0 .703.176.914.492.106 0 .211-.035.281-.07.211-.106.457-.352.809-.738.21-.211.527-.598.95-1.16.491-.704.878-1.16 1.124-1.372.14-.14.246-.351.352-.668.07-.175.14-.457.21-.879.282-1.546.844-2.355 1.653-2.355.984 0 1.723.281 2.215.844.457.562.703 1.37.703 2.426 0 .457-.07.914-.211 1.335-.105.282-.246.598-.457 1.02l-.07.105h2.46c.704 0 1.301.282 1.794.774.492.527.773 1.125.773 1.828 0 .703-.176 1.266-.527 1.723z"/></g>'
  }
})
