<template>
    <div :class="{ 'change-page-wrapper': true, 'animate-in': animateIn }">
        <change-page-dialog
            v-if="newPage || currentPage"
            v-model="changePageDialogActive"
            :dealer="dealer"
            :page="newPage || currentPage"
            @complete="onChangePage"
            @close="changePageDialogActive = false" />
        <resend-page-access-dialog
            v-if="newPage || currentPage"
            v-model="resendPageAccessDialogActive"
            :dealer="dealer"
            :page="newPage || currentPage"
            @close="resendPageAccessDialogActive = false" />

        <template v-if="loading">
            <p
                v-if="settingsMode"
                class="section-title">
                Page
            </p>
            <loader :label="loadingMessage" />
        </template>

        <template v-if="!loading && currentPage != null">
            <p
                v-if="settingsMode"
                class="section-title">
                Page&nbsp;
                <span v-if="currentPageData.agencyStatus">
                    ({{ currentPageData.agencyStatus.label }})
                </span>
                <span v-if="currentPageData.internalStatus">
                    ({{ currentPageData.internalStatus.label }})
                </span>
                <facebook-page-questions
                    :overall-status="currentPageData.overallStatus"
                    :page-name="currentPage.name"
                    :page-id="currentPage.id"
                    :new-page="newPage"
                    :new-page-settings="true"
                    :icon-size="'17'" />
            </p>
            <v-layout
                class="wrap page-data-wrapper"
                :class="{'justify-space-around': !settingsMode, 'settings-mode-wrap': settingsMode}">
                <div
                    class="flex shrink layout align-center"
                    :class="{'pt-2': settingsMode}">
                    <img v-bind="currentPageData.image">
                </div>

                <v-flex
                    class="px-2"
                    :class="{'md2 xs5': !settingsMode, 'pt-2': settingsMode}">
                    <data-field>
                        <template
                            v-if="!settingsMode"
                            #label>
                            Facebook Page
                        </template>
                        <template v-if="currentPageData.overallStatus == 'revoked'">
                            Unknown Name <span v-if="settingsMode"> ({{ currentPage.id }})</span>
                        </template>
                        <template v-else>
                            <a
                                :href="currentPage.facebook_page_url"
                                target="_blank"
                                class="page-name">
                                {{ currentPage.name }}
                                <span v-if="settingsMode"> ({{ currentPage.id }})</span>
                            </a>
                            <ul
                                v-if="settingsMode"
                                class="links">
                                <li>
                                    <a
                                        :href="`https://business.facebook.com/${currentPage.id}/settings?tab=messenger_platform&ref=page_edit&business_id=${dealer.agency.facebook_business_id}`"
                                        target="_blank">
                                        Advanced Messaging
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="`https://business.facebook.com/latest/instant_forms/forms?asset_id=${currentPage.id}&business_id=${dealer.agency.facebook_business_id}`"
                                        target="_blank">
                                        Forms
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="`https://business.facebook.com/ads/library/?active_status=all&ad_type=all&country=US&impression_search_field=has_impressions_lifetime&view_all_page_id=${currentPage.id}&business_id=${dealer.agency.facebook_business_id}`"
                                        target="_blank">
                                        Ad Library
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="`https://business.facebook.com/latest/inbox/all?asset_id=${currentPage.id}&business_id=${dealer.agency.facebook_business_id}&mailbox_id=${currentPage.id}`"
                                        target="_blank">
                                        Inbox
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </data-field>
                </v-flex>

                <v-flex
                    v-if="!settingsMode"
                    class="xs2 px-2 hide-sm">
                    <data-field no-wrap>
                        <template #label>
                            Page Id
                        </template>
                        <a
                            :href="currentPage.facebook_page_url"
                            target="_blank">
                            {{ currentPage.id }}
                        </a>
                    </data-field>
                </v-flex>

                <v-flex
                    v-if="!settingsMode"
                    class="layout xs5 md3 px-2">
                    <data-field
                        v-if="currentPageData.agencyStatus"
                        class="ml-3"
                        no-indent>
                        <template #label>
                            Agency BM
                        </template>
                        <styled-tooltip>
                            <status-field :color="currentPageData.agencyStatus.color">
                                <a>{{ currentPageData.agencyStatus.label }}</a>
                            </status-field>
                            <template #content>
                                {{ currentPageData.agencyStatus.message }}
                            </template>
                        </styled-tooltip>
                    </data-field>

                    <data-field
                        v-if="currentPageData.internalStatus"
                        class="ml-4"
                        no-indent>
                        <template #label>
                            BuyerBridge BM
                        </template>
                        <styled-tooltip>
                            <status-field :color="currentPageData.internalStatus.color">
                                <a>{{ currentPageData.internalStatus.label }}</a>
                            </status-field>
                            <template #content>
                                {{ currentPageData.internalStatus.message }}
                            </template>
                        </styled-tooltip>
                    </data-field>
                </v-flex>

                <v-flex
                    class="layout align-center"
                    :class="{'xs12 md3 justify-space-around': !settingsMode, 'md6 justify-end': settingsMode}">
                    <template v-if="settingsMode">
                        <action-button
                            class="mx-2"
                            icon="circle"
                            :color="currentPageData.agencyStatus.color"
                            @click.prevent>
                            Access
                            <span v-if="currentPageData.agencyStatus">
                                {{ accessLabel(currentPageData.agencyStatus.label) }}
                            </span>
                            <span v-if="currentPageData.internalStatus">
                                {{ accessLabel(currentPageData.internalStatus.label) }}
                            </span>
                        </action-button>
                    </template>
                    <template
                        v-if="!newPage && !userIsClient">
                        <action-button
                            v-if="currentPageData.overallStatus != 'active'"
                            class="mx-2"
                            icon="download"
                            @click="resendPageAccessDialogActive = true">
                            Resend Request
                        </action-button>

                        <action-button
                            class="mx-2"
                            icon="refresh"
                            @click="updateFacebookPages('currentPage')">
                            Recheck Status
                        </action-button>

                        <action-button
                            class="mx-2"
                            icon="edit"
                            @click="changePageDialogActive = true">
                            Change Page
                        </action-button>
                    </template>
                    <template v-if="settingsMode">
                        <action-button
                            v-if="settingsMode"
                            class="mx-2"
                            :color="userAssigned ? 'blue' : 'red'"
                            :icon="userAssigned ? 'indicator-success' : 'indicator-error' ">
                            {{ userAssigned ? 'Permission' : 'No Permission' }}
                        </action-button>

                        <action-button
                            v-if="settingsMode"
                            class="ml-2"
                            :href="`https://business.facebook.com/settings/pages/${currentPage.id}?business_id=${dealer.agency.facebook_business_id}`"
                            target="_blank"
                            icon="cog">
                            BM Settings
                        </action-button>
                    </template>
                </v-flex>

                <div
                    v-if="!settingsMode"
                    class="flex hide-sm layout shrink align-center">
                    <facebook-page-questions
                        :overall-status="currentPageData.overallStatus"
                        :page-name="currentPage.name"
                        :page-id="currentPage.id"
                        :new-page="newPage"
                        :new-page-settings="true" />
                </div>
            </v-layout>
        </template>


        <template v-if="!loading && newPage != null">
            <p
                v-if="settingsMode"
                class="section-title">
                Page&nbsp;
                <span v-if="newPageData.agencyStatus">
                    ({{ newPageData.agencyStatus.label }})
                </span>
                <span v-if="newPageData.internalStatus">
                    ({{ newPageData.internalStatus.label }})
                </span>
                <facebook-page-questions
                    :overall-status="newPageData.overallStatus"
                    :page-name="newPage.name"
                    :page-id="newPage.id"
                    :icon-size="'17'" />
            </p>
            <v-layout
                class="wrap page-data-wrapper"
                :class="{'mt-5 justify-space-around': !settingsMode, 'settings-mode-wrap': settingsMode}">
                <div
                    class="flex shrink layout align-center"
                    :class="{'pt-2': settingsMode}">
                    <img v-bind="newPageData.image">
                </div>

                <v-flex
                    class="px-2"
                    :class="{'md2 xs5': !settingsMode, 'pt-2': settingsMode}">
                    <data-field>
                        <template
                            v-if="!settingsMode"
                            #label>
                            Facebook Page
                        </template>
                        <template v-if="newPageData.overallStatus == 'revoked'">
                            Unknown Name <span v-if="settingsMode"> ({{ newPage.id }})</span>
                        </template>
                        <template v-else>
                            <a
                                :href="newPage.facebook_page_url"
                                target="_blank"
                                class="page-name">
                                {{ newPage.name }}
                                <span v-if="settingsMode"> ({{ newPage.id }})</span>
                            </a>
                            <ul
                                v-if="settingsMode"
                                class="links">
                                <li>
                                    <a
                                        :href="`https://business.facebook.com/${newPage.id}/settings?tab=messenger_platform&ref=page_edit&business_id=${dealer.agency.facebook_business_id}`"
                                        target="_blank">
                                        Advanced Messaging
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="`https://business.facebook.com/latest/instant_forms/forms?asset_id=${newPage.id}&business_id=${dealer.agency.facebook_business_id}`"
                                        target="_blank">
                                        Forms
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="`https://business.facebook.com/ads/library/?active_status=all&ad_type=all&country=US&impression_search_field=has_impressions_lifetime&view_all_page_id=${newPage.id}&business_id=${dealer.agency.facebook_business_id}`"
                                        target="_blank">
                                        Ad Library
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="`https://business.facebook.com/latest/inbox/all?asset_id=${newPage.id}&business_id=${dealer.agency.facebook_business_id}&mailbox_id=${newPage.id}`"
                                        target="_blank">
                                        Inbox
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </data-field>
                </v-flex>

                <v-flex
                    v-if="!settingsMode"
                    class="xs2 px-2 hide-sm">
                    <data-field no-wrap>
                        <template #label>
                            Page Id
                        </template>
                        <a
                            :href="`http://www.facebook.com/${newPage.id}`"
                            target="_blank">
                            {{ newPage.id }}
                        </a>
                    </data-field>
                </v-flex>

                <v-flex
                    v-if="!settingsMode"
                    class="layout xs5 md3 px-2">
                    <data-field
                        v-if="newPageData.agencyStatus"
                        class="ml-3"
                        no-indent>
                        <template #label>
                            Agency BM
                        </template>
                        <styled-tooltip>
                            <status-field :color="newPageData.agencyStatus.color">
                                <a>{{ newPageData.agencyStatus.label }}</a>
                            </status-field>
                            <template #content>
                                {{ newPageData.agencyStatus.message }}
                            </template>
                        </styled-tooltip>
                    </data-field>
                    <data-field
                        v-if="newPageData.internalStatus"
                        class="ml-4"
                        no-indent>
                        <template #label>
                            BuyerBridge BM
                        </template>
                        <styled-tooltip>
                            <status-field :color="newPageData.internalStatus.color">
                                <a>{{ newPageData.internalStatus.label }}</a>
                            </status-field>
                            <template #content>
                                {{ newPageData.internalStatus.message }}
                            </template>
                        </styled-tooltip>
                    </data-field>
                </v-flex>

                <v-flex
                    v-if="!userIsClient"
                    class="layout justify-space-around align-center new-page-actions"
                    :class="{'xs12 md3': !settingsMode, 'md6': settingsMode}">
                    <action-button
                        v-if="settingsMode"
                        class="mx-2"
                        icon="circle"
                        :color="newPageData.agencyStatus.color"
                        @click.prevent>
                        Access
                        <span v-if="newPageData.agencyStatus">
                            {{ accessLabel(newPageData.agencyStatus.label) }}
                        </span>
                        <span v-if="newPageData.internalStatus">
                            {{ accessLabel(currentPageData.internalStatus.label) }}
                        </span>
                    </action-button>

                    <action-button
                        v-if="newPageData.overallStatus !== 'active'"
                        class="mx-2"
                        icon="download"
                        @click="resendPageAccessDialogActive = true">
                        Resend Request
                    </action-button>

                    <action-button
                        class="mx-2"
                        icon="refresh"
                        @click="updateFacebookPages('newPage')">
                        Recheck Status
                    </action-button>

                    <action-button
                        class="mx-2"
                        icon="edit"
                        @click="changePageDialogActive = true">
                        Change Page
                    </action-button>

                    <action-button
                        v-if="settingsMode"
                        class="mx-2"
                        :color="userAssigned ? 'blue' : 'red'"
                        :icon="userAssigned ? 'indicator-success' : 'indicator-error' ">
                        {{ userAssigned ? 'Permission' : 'No Permission' }}
                    </action-button>

                    <action-button
                        v-if="settingsMode"
                        class="ml-2"
                        :href="`https://business.facebook.com/settings/pages/${newPage.id}?business_id=${dealer.agency.facebook_business_id}`"
                        target="_blank"
                        icon="cog">
                        BM Settings
                    </action-button>
                </v-flex>

                <div
                    v-if="!settingsMode"
                    class="flex hide-sm layout shrink align-center">
                    <facebook-page-questions
                        :overall-status="newPageData.overallStatus"
                        :page-name="newPage.name"
                        :page-id="newPage.id" />
                </div>
            </v-layout>
        </template>
    </div>
</template>

<script>
import FacebookPageQuestions from '@/components/globals/FacebookPageQuestions';
import ChangePageDialog from '@/components/globals/ChangePageDialog';
import StyledTooltip from '@/components/globals/StyledTooltip';
import ActionButton from '@/components/globals/ActionButton';
import StatusField from '@/components/globals/StatusField';
import DataField from '@/components/globals/DataField';
import Loader from '@/components/globals/Loader';
import { TOGGLE_PAGE_CHANGE_SLIDEOUT } from '@/store/mutation-types';
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { sleep } from '../../helpers';
import { executeWithExponentialBackoff } from '@/helpers/functionWithExpotenialBackoff';

export default {
    name: 'ManageDealerPage',
    components: {
        FacebookPageQuestions,
        ChangePageDialog,
        ResendPageAccessDialog: () => import('./ResendPageAccessDialog'),
        ActionButton,
        DataField,
        Loader,
        StatusField,
        StyledTooltip,
    },
    props: {
        settingsMode: {
            type: Boolean,
            default: false
        },
        userAssigned: {
            type: Boolean,
            default: false
        },
        dealer: {
            type: Object,
            required: true
        },
        completed: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            loadingMessage: 'Retrieving page...',
            currentPage: null,
            newPage: null,
            changePageDialogActive: false,
            resendPageAccessDialogActive: false,
            valid: false,
            loading: false,
            pageUrl: null,
            animateIn: false
        };
    },
    computed: {
        ...mapGetters([
            'userIsClient'
        ]),
        currentPageData() {
            const fbImage = get(this.currentPage, 'picture.data', null);

            return {
                agencyStatus: this.getStatusData('agency', this.currentPage),
                internalStatus: this.getStatusData('internal', this.currentPage),
                overallStatus: this.getOverallStatus(this.currentPage),
                image: this.getFbImgProps(fbImage)
            };
        },
        newPageData() {
            const fbImage = get(this.newPage, 'picture.data', null);

            return {
                agencyStatus: this.getStatusData('agency', this.newPage),
                internalStatus: this.getStatusData('internal', this.newPage),
                overallStatus: this.getOverallStatus(this.newPage),
                image: this.getFbImgProps(fbImage)
            };
        },
        routeHash() {
            return this.$route.hash;
        }
    },
    watch: {
        routeHash() {
            if (this.routeHash === '#change-page') {
                this.changePageDialogActive = true;
                this.$router.push({ name: 'dealer' });
            }
        },
        loading(newValue, oldValue) {
            // If we've stopped loading then animate in
            if (newValue === false && oldValue === true) {
                // Leave time for the elements to enter the DOM so we can animate them
                setTimeout(() => {
                    this.animateIn = true;
                }, 200);
            } else {
                this.animateIn = false;
            }
        }
    },
    async created() {
        // Store initial data so we can reset
        this.initialData = this.$options.data.apply(this);

        await this.updateFacebookPages();
        this.checkIfPagesArePendingAndRefetch();
        if (this.$store.state.settings.pageChangeSlideoutOpen === true) {
            this.changePageDialogActive = true;
            this.$store.commit(TOGGLE_PAGE_CHANGE_SLIDEOUT, false);
            this.$router.push({ name: 'dealer' });
        }
    },
    methods: {
        getFbImgProps(fbImage) {

            if (!fbImage) {
                return {
                    height: 50,
                    width: 50,
                    src: '/img/facebook-placeholder.png'
                };
            }

            return {
                height: fbImage.height,
                width: fbImage.width,
                src: fbImage.url
            };
        },
        async onChangePage() {
            try {
                this.loading = true;
                this.loadingMessage = 'Waiting for Facebook to update...';
                // Retry the request 3 times at 1 second intervals
                const pages = await this.getFacebookPagesRetry(3, 2000);

                this.currentPage = pages.currentPage;
                this.newPage = pages.newPage;

                // Emit the update event with the new page if set or the current page
                this.$emit('update', pages);
            } catch (error) {
                this.$flash('Error loading page data, please try again in a few minutes, contact support if it persits.', 'red');
            } finally {
                this.loading = false;
                // Reset to the original loading message
                this.loadingMessage = this.initialData.loadingMessage;

                this.checkIfPagesArePendingAndRefetch();
            }
        },
        checkIfPagesArePendingAndRefetch() {
            if ([this.currentPageData.overallStatus, this.newPageData.overallStatus].includes('pending')) {
                executeWithExponentialBackoff(this.updateFacebookPages, 5, 10000);
            }
        },
        getStatusData(type, page) {
            // If there's no page there's nothing to do
            if (!page) {
                return null;
            }
            // Determine which status indicator to use based on the type of request
            let status = (type === 'agency') ? page.agency_bm_page_access : page.buyerbridge_bm_page_access;

            // If there's no status the logic below won't apply
            // This happens when one of the BMs hasn't been sent the invite
            if (!status) {
                return null;
            }
            if (this.completed === true) {
                status = 'pending';
            }
            // Determine which business manager this is for
            let organization = 'BuyerBridge';
            let boilerplate = ' This is required for posting to Marketplace.';
            // If this is the agency's page than we can use their name and assume that the access
            // is just for ad campaigns since the default setup uses BuyerBridge's BM for marketplace
            if (type === 'agency') {
                organization = this.dealer.agency.name;
                boilerplate = ' This is required for advertising campaigns.';
            }
            // If the type is agency and there's not marketplace page status that means
            // that the agency is using their own catalog for Marketplace
            if (type === 'agency' && page.buyerbridge_bm_page_access === null) {
                boilerplate = ' This is required for advertising campaigns & posting to Marketplace.';
            }

            // Map each state to what's needed for output
            const states = {
                active: {
                    label: 'Active',
                    color: 'green',
                    message: `${organization}'s business manager has been granted access to ${page.name}.${boilerplate}`
                },
                revoked: {
                    label: 'Revoked',
                    color: 'red',
                    message: `${organization}'s access to this page has been revoked.${boilerplate}`
                },
                pending: {
                    label: 'Pending',
                    color: 'yellow',
                    message: `${page.name} needs to grant access from ${organization}'s business manager.${boilerplate}`
                }
            };
            return states[status];
        },
        getOverallStatus(page) {
            if (page) {
                // If either page is revoked consider the overall status revoked
                if (page.agency_bm_page_access === 'revoked' || page.buyerbridge_bm_page_access === 'revoked') {
                    return 'revoked';
                }
                // Or if either page is pending consider the overall status as pending
                if (page.agency_bm_page_access === 'pending' || page.buyerbridge_bm_page_access === 'pending') {
                    return 'pending';
                }
                // If both pages are null or active consider the overall status active
                // We do this because either page can be null if the dealer doesn't need
                // access to the related business manager
                if (
                    (page.agency_bm_page_access === null || page.agency_bm_page_access === 'active')
                    && (page.buyerbridge_bm_page_access === null || page.buyerbridge_bm_page_access === 'active')
                ) {
                    return 'active';
                }
            }
        },
        /**
         * Retries the pages endpoint based on a count of times and sleep interval between tries
         * we do this because the get pages endpoint proxies to Facebook live and the page change
         * won't immediately register.
         */
        async getFacebookPagesRetry(times, sleepInterval) {
            for (let i = 0; i < times; i++) {
                try {
                    const pages = await this.getFacebookPages();
                    // This is important!  We use the name to determine
                    // when the new page is fully registered.  Throwing this
                    // error will cause the system to repeat until it's registered
                    if (!pages.newPage.name) {
                        throw 'New page not fully registered';
                    }
                    // If all goes well return the pages
                    return pages;
                } catch (error) {
                    // Sleep to give Facebook time to update
                    await sleep(sleepInterval);
                    const isLastAttempt = (i + 1 === times);
                    if (isLastAttempt) {
                        throw error;
                    }
                }
            }
        },
        /**
         * Wrapper to handle Facebook page data once received
         */
        async updateFacebookPages(pageType = '', showLoader = true) {
            if (showLoader) this.loading = true;

            try {
                // Get the latest page request data from the page request workflow
                const pages = await this.getFacebookPages();

                this.currentPage = pages.currentPage;
                this.newPage = pages.newPage;

                // Run a live check on the page request workflow if page access is pending or revoked
                if (pageType && this[pageType].agency_bm_page_access !== 'active') {
                    await this.detectPageAccess(pageType);
                }

                // Emit the update event with the new page if set or the current page
                this.$emit('update', pages);
            } catch (error) {
                this.$flash('Error retrieving Meta page status, please try again in a few minutes, contact support if it persits.', 'red');
                this.$emit('error', error);
            } finally {
                this.loading = false;
            }
        },
        /**
         * Interact with the API for the dealer's Facebook pages
         */
        async getFacebookPages() {
            const response = await this.$apiRepository.getFacebookPage(this.dealer.id);

            // Setup return data for external use
            return {
                currentPage: get(response, 'data[0]', null),
                newPage: get(response, 'data[1]', null)
            };
        },
        /**
         * Refresh Facebook page access request workflow on dealer's Facebook page request
         */
        async detectPageAccess(pageType) {
            const response = await this.$apiRepository.detectPageAccess(this.dealer.id);

            if (response.data.success) {
                this[pageType].agency_bm_page_access = 'active';
            } else {
                if (this[pageType].agency_bm_page_access === 'active') {
                    this[pageType].agency_bm_page_access = 'pending';
                }
            }
        },
        accessLabel(label) {
            return label.toLowerCase() === 'active' ? 'Granted' : label;
        }
    },
};
</script>

<style lang="scss" scoped>
/* .change-page-wrapper {
    padding: 30px;
} */

.page-data-wrapper {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .animate-in & {
        opacity: 1;
    }
}

@media only screen and (max-width: 960px) {
    .new-page-actions {
        margin-top: 30px;
    }
}

.links {
    list-style: none;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}

.settings-mode-wrap {
    align-items: flex-start;

    .page-name {
        max-width: 300px;
        display: inline-block;
    }

    img {
        max-width: 24px;
        height: auto;
    }
}

.section-title {
    margin-top: 20px;
    font-size: 16px;
    background: #f3f5f6;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    display: flex;
    align-items: center;
}
</style>
<style>
.section-title span a {
    margin: 0;
}
</style>

