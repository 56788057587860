<template>
    <styled-dialog
        :value="true"
        :width="900">
        <div class="header">
            <div
                class="close"
                @click="hideModal(true)">
                <icon
                    name="close"
                    size="14" />
            </div>
            <h2 class="mb-2 mt-4">
                Prepare for Launch in T-Minus 10...9...8...
            </h2>
            <p class="mb-4">
                Congratulations! You're 1-step closer to digital media dominance.
                To get started with the launch process please confirm the account you want to
                deploy the 3 ad(s) to and whether you want to create new campaigns or using campaigns
                that are already running on your target channels.
            </p>
        </div>
        <div class="body">
            <h2 class="text-xs-center">
                Which account do you want to deploy these ad(s) to?
            </h2>
            <div class="dealer-selector-container mb-3">
                <div class="mt-3">
                    <v-autocomplete
                        :items="dealersPlusCurrent"
                        :value="dealerId"
                        :loading="loading ? 'primary' : false"
                        class="styled-field"
                        item-text="name"
                        item-value="id"
                        append-icon="arrow_drop_down"
                        light
                        color="grey lighten-2"
                        label="SELECT ACCOUNT"
                        placeholder="Select..."
                        return-object
                        required
                        @input="onInput"
                        @keyup="search">
                        <template #item="{ item }">
                            <v-list-tile-content>
                                <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                            </v-list-tile-content>
                        </template>
                    </v-autocomplete>
                </div>
            </div>
        </div>
        <static-deployment-options
            v-if="isStaticDeployment"
            @start-static-ad-deployment="startStaticAdDeployment" />
        <div
            v-else
            class="continue-button">
            <styled-button @click="startDynamicAdDeployment">
                CONTINUE
            </styled-button>
        </div>
    </styled-dialog>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import Icon from '@/components/globals/Icon';
import StaticDeploymentOptions from '@/components/ad-deployment/components/DeploymentModal/StaticDeploymentOptions';
import {
    DYNAMIC_DEPLOYMENT_TYPE,
    FORMAT_CAROUSEL_MULTI_ITEMS,
    FORMAT_CAROUSEL_SLIDESHOWS,
    SINGLE_CAROUSEL_AD,
    SINGLE_IMAGE__PRICE_IN_CREATIVE_AD,
    SINGLE_IMAGE_AD,
    SLIDESHOW_CAROUSEL_AD,
    STATIC_DEPLOYMENT_TYPE
} from '@/components/ad-deployment/store/constants';
import { mapState } from 'vuex';
import { debounce } from 'lodash';
import StyledButton from '@/components/globals/StyledButton.vue';
import { FACEBOOK_BB_PLATFORM_ID, SNAPCHAT_BB_PLATFORM_ID, TIKTOK_BB_PLATFORM_ID, PINTEREST_BB_PLATFORM_ID } from '@/helpers/globals';

export default {
    name: 'DeploymentModal',
    components: {
        StyledButton,
        StyledDialog,
        Icon,
        StaticDeploymentOptions,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            dealers: [],
        };
    },
    computed: {
        ...mapState({
            stateDealers: (state) => state.dealer.dealers,
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentUser: (state) => state.user.user,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        dealerId: {
            get() {
                return this.$store.state.adDeployment?.dealer_id || this.currentDealerId;
            },
            set(dealerId) {
                this.$store.commit('adDeployment/setDealerId', dealerId);
            }
        },
        dealersPlusCurrent() {
            const dealers = [...this.dealers];

            if (this.currentDealer &&
                !dealers.some(dealer => dealer.id === this.currentDealer.id)) {
                dealers.push(this.currentDealer);
            }

            return dealers;
        },
        isStaticDeployment() {
            return this.configuration.deployment_type === STATIC_DEPLOYMENT_TYPE;
        },
    },
    methods: {
        hideModal(showAdStudio = false) {
            this.$emit('close', showAdStudio);
        },
        onInput(dealer) {
            this.configuration = {
                ...this.configuration,
                dealerId: dealer.id,
                configuration: {
                    ...this.configuration?.configuration,
                    dealer_name: dealer.name
                }
            };
        },
        search: debounce(async function(e) {
            this.loading = true;
            this.dealers = [];

            const response = await this.$apiRepository.getDealerPickerSearchDealers(e.target.value, this.currentDealer.agency_id);

            this.dealers = response.data.data;
            this.loading = false;
        }, 300),
        startAdDeployment() {
            this.$store.dispatch('adDeployment/start', this.configuration);
            this.hideModal();
        },
        startDynamicAdDeployment() {
            this.configuration = {
                ...this.configuration,
                userId: this.currentUser.id,
                dealerId: this.dealerId,
                configuration: {
                    ...this.configuration.configuration,
                    ads: [
                        this.buildAdForDynamicDeployment(SINGLE_IMAGE_AD),
                        this.buildAdForDynamicDeployment(SINGLE_IMAGE__PRICE_IN_CREATIVE_AD, FORMAT_CAROUSEL_MULTI_ITEMS),
                        this.buildAdForDynamicDeployment(SLIDESHOW_CAROUSEL_AD, FORMAT_CAROUSEL_SLIDESHOWS),
                        this.buildAdForDynamicDeployment(SINGLE_CAROUSEL_AD, FORMAT_CAROUSEL_MULTI_ITEMS)
                    ],
                    deployment_type: DYNAMIC_DEPLOYMENT_TYPE,
                },
            };

            this.startAdDeployment();
        },
        startStaticAdDeployment(deploymentLocation) {
            this.configuration.configuration = {
                ...this.configuration.configuration,
                deployment_type: STATIC_DEPLOYMENT_TYPE,
                campaign_selection: {
                    selection: deploymentLocation,
                },
            };

            this.startAdDeployment();
        },
        buildAdForDynamicDeployment(displayName, format = null) {
            return {
                'user_id': this.currentUser.id,
                'dealer_id': this.dealerId,
                'agency_id': this.currentAgency.id,
                'created_by_user_id': this.currentUser.id,
                'display_name': displayName,
                format,
                'configuration': {
                    'selected_platforms': [],
                    'customized_per_platform': true,
                    'supported_platforms_control_media_styles': true,
                    'all_platforms': {
                        'primary_text': null,
                        'headline': null,
                        'description': null,
                        'cta_link': this.currentDealer.site_base_url,
                        'cta_text': 'Shop Now',
                        'url_params': null,
                        'platform_id': null,
                    },
                    'platform_specific': {
                        'facebook': {
                            'primary_text': null,
                            'headline': null,
                            'description': null,
                            'cta_link': this.currentDealer.site_base_url,
                            'cta_text': 'Shop Now',
                            'url_params': null,
                            'platform_id': FACEBOOK_BB_PLATFORM_ID,
                            'form_valid': true,
                            'platform_controls_media_styles': true,
                            'creative_enhancement': true,
                        },
                        'snapchat': {
                            'primary_text': null,
                            'headline': null,
                            'description': null,
                            'cta_link': this.currentDealer.site_base_url,
                            'cta_text': 'Shop Now',
                            'url_params': null,
                            'platform_id': SNAPCHAT_BB_PLATFORM_ID,
                            'form_valid': false,
                            'platform_controls_media_styles': false,
                            'creative_enhancement': false,
                        },
                        'tiktok': {
                            'primary_text': null,
                            'headline': null,
                            'description': null,
                            'cta_link': this.currentDealer.site_base_url,
                            'cta_text': 'Shop Now',
                            'url_params': null,
                            'platform_id': TIKTOK_BB_PLATFORM_ID,
                            'form_valid': false,
                            'platform_controls_media_styles': false,
                        },
                        'pinterest': {
                            'primary_text': null,
                            'headline': null,
                            'description': null,
                            'cta_link': this.currentDealer.site_base_url,
                            'cta_text': 'Shop Now',
                            'url_params': null,
                            'platform_id': PINTEREST_BB_PLATFORM_ID,
                            'form_valid': false,
                            'platform_controls_media_styles': true,
                            'creative_enhancement': false,
                        }
                    }
                },
                'public': 1,
                'deleted_at': null
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.header {
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px;
        cursor: pointer;
    }
}

.body {
    .dealer-selector-container {
        display: flex;
        justify-content: center;

        .styled-field {
            width: 520px;
        }
    }

    .continue-button {
        display: flex;
        justify-content: center;
        margin-top: 4.2rem;
    }
}
</style>
