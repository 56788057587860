<template>
    <div
        ref="container"
        class="single-advt">
        <div class="top__holder">
            <div class="top__block">
                <div class="top__icon">
                    <icon
                        size="20"
                        name="ad" />
                </div>
                <div class="top__text">
                    <div class="top__desc">
                        {{ name }}
                    </div>
                </div>
            </div>
            <div class="top__block">
                <div class="top__active">
                    <span
                        class="circle"
                        :class="status" />
                </div>
            </div>
        </div>
        <loader v-if="loading" />
        <error-boundary>
            <template #error>
                <ad-placeholder>
                    <p>
                        This ad cannot be previewed because its configuration or type is not currently supported.
                    </p>
                    <p>
                        Please <a href="mailto:support@buyerbridge.io">contact us</a> if you would like BuyerBridge to
                        support this type of ad.
                    </p>
                </ad-placeholder>
            </template>
            <component
                :is="currentPreview.component"
                v-if="!loading && currentPreview"
                :ad-data="adData"
                :muted="muted"
                @set-video-type="videoType = true" />
        </error-boundary>
        <action-button
            v-if="videoType"
            class="sound-button"
            :class="{'ml-5': managementPreview}"
            :icon="muted === true ? 'sound-off' : 'sound-on'"
            size="27"
            @click="toggleMute" />
        <div
            class="single-advt__tooltip"
            @mouseover="showTooltip = true"
            @mouseleave="showTooltip = false">
            <div class="tooltip__text">
                Actual ad may differ
            </div>
            <div class="tooltip__i">
                <div
                    v-if="showTooltip"
                    class="tooltip__popup">
                    The Ad Previews displayed are mockups, designed to mirror the actual ad previews
                    for each channel as much as possible, and may slightly vary from what the real Ad looks like within
                    its respective platform.
                </div>
                <div class="tooltip__el" />
            </div>
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import Loader from '@/components/globals/Loader';
import Icon from '@/components/globals/Icon';
import ErrorBoundary from '@/components/globals/ErrorBoundary';
import AdPlaceholder from '@/components/globals/AdPreview/AdPlaceholder';
import {
    PREVIEW_TYPES,
    LAYOUT_CAROUSEL,
    LAYOUT_SINGLE,
} from '@/components/ad-studio/store/constants';
import {
    FACEBOOK_BB_PLATFORM_ID,
    PLATFORM_ID_PINTEREST, PLATFORM_ID_TIKTOK,
    SNAPCHAT_BB_PLATFORM_ID,
    TIKTOK_BB_PLATFORM_ID
} from '@/helpers/globals';

export default {
    components: {
        ActionButton,
        Loader,
        ErrorBoundary,
        AdPlaceholder,
        Icon
    },
    props: {
        adData: {
            type: [Object, Array],
            default: null
        },
        platformId: {
            type: Number,
            default: null
        },
        selectedMetaPreview: {
            type: String,
            default: null,
        },
        managementPreview: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            showTooltip: false,
            currentPlacement: null,
            muted: false,
            videoType: false,
        };
    },
    computed: {
        name() {
            return this.adData?.name;
        },
        status() {
            return this.adData?.status;
        },
        currentLayout() {
            // @todo clean this up when the ads are normalized
            // going both from platform and to bb
            if ((this.platformId || this.adData.platform_id) === TIKTOK_BB_PLATFORM_ID) {
                return LAYOUT_SINGLE;
            }

            if (this.adData?.creatives?.length > 1 || this.adData?.products?.length > 1) {
                return LAYOUT_CAROUSEL;
            }

            const creativeType = this.adData?.creatives?.[0]?.media_type
                || this.adData?.media_items?.[0]?.media_type;

            // Dynamic ads share the same layout but we can't
            // rely on the number of creatives
            // @todo separate collections & carousels
            if (
                (
                    creativeType === 'COLLECTION'
                    || creativeType === 'CAROUSEL'
                ) && this.adData?.render_type === 'DYNAMIC'
            ) {
                return LAYOUT_CAROUSEL;
            }

            return LAYOUT_SINGLE;
        },
        adAssetType() {
            if (this.adData?.media_items?.[0]) {
                return this.adData.media_items[0].media_type;
            }

            return this.adData.creatives[0].assets[0].asset_type.name;
        },
        selectablePreviewTypes() {
            if (this.platformId === FACEBOOK_BB_PLATFORM_ID && this.selectedMetaPreview) {
                return PREVIEW_TYPES.find(preview => preview.name === this.selectedMetaPreview);
            }

            if ([PLATFORM_ID_PINTEREST, PLATFORM_ID_TIKTOK].includes(this.adData?.platform_id)) {
                return PREVIEW_TYPES.find(preview => preview.platform_ids.includes(this.adData?.platform_id));
            }

            // todo remove this in the future when the bb ads with products
            // are normalized better across all platforms
            if (this.adData?.platform_id === SNAPCHAT_BB_PLATFORM_ID) {
                return PREVIEW_TYPES.filter(preview => {
                    return preview.platform_ids.includes(this.platformId || parseInt(this.adData?.platform_id))
                        && (preview.layout === this.currentLayout && preview.asset_types.includes(this.adAssetType.toUpperCase())
                            || preview.layout.toUpperCase() === this.adData?.platform_specific_fields?.ad_layout);
                });
            }

            return PREVIEW_TYPES.filter(preview => {
                return preview.platform_ids.includes(this.platformId || parseInt(this.adData?.platform_id))
                    && preview.layout === this.currentLayout
                    && preview.asset_types.includes(this.adAssetType.toUpperCase());
            });
        },
        currentPreview() {
            // If there's no placement set just use the first
            // preview type
            if (!this.currentPlacement) {
                return this.selectablePreviewTypes[0] || this.selectablePreviewTypes;
            }

            // Otherwise find the appropriate preview type based
            // on the user's selection
            return this.selectablePreviewTypes.find(preview => (
                preview.name === this.currentPlacement
            ));
        },
    },
    methods: {
        toggleMute() {
            this.muted = !this.muted;
        },
        changePreviewType(previewType) {
            this.currentPlacement = previewType.name;
        }
    }
};
</script>

<style lang="scss" scoped>
.single-advt {
    position: relative;
    margin-top: 50px;
}

.top__holder {
    position: absolute;
    display: flex;
    bottom: calc(100% + 12px);
    justify-content: space-between;
    align-items: flex-end;
    right: 0;
    left: 0;
    min-height: 35px;
}

.top__block {
    display: flex;
}

.top__text {
    display: flex;
    align-items: flex-start;
}

.top__desc {
    font-size: 13px;
    margin-left: 10px;
}

.top__active {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    margin-right: 3px;
    margin-top: 3px;
}

.top__list {
    cursor: pointer;
}

.single-advt__tooltip {
    position: absolute;
    bottom: -35px;
    right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    .tooltip__i {
        margin-left: 8px;
        width: 25px;
        height: 25px;
        background: #8F9EA6;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tooltip__el {
        position: relative;
        width: 1px;
        height: 1px;

        &:after {
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            top: -8px;
            left: -1.5px;
            background: white;
        }

        &:before {
            content: '';
            position: absolute;
            width: 4px;
            height: 10.5px;
            top: -1.5px;
            left: -1.5px;
            background: white;
        }
    }

    .tooltip__popup {
        width: 250px;
        text-align: left;
        position: absolute;
        box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
        padding: 22px;
        bottom: calc(100% + 20px);
        left: -10px;
        font-size: 13px;
        background: white;
        box-sizing: border-box;
        z-index: 999;
        border-radius: 5px;

        &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            transform: translateX(-50%) rotate(45deg) skew(-5deg, -5deg);
            bottom: -8px;
            left: 22px;
            background: white;
            box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
        }

        &:before {
            content: '';
            position: absolute;
            background: white;
            width: 50px;
            height: 20px;
            bottom: 0;
            left: 35px;
            transform: translateX(-50%);
            z-index: 3;
        }
    }
}
</style>
<style lang="scss">
.ad-studio-previews {
    .single-advt {
        margin-top: 0;
    }
}

.sound-button {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
}
</style>
