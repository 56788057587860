import Echo from 'laravel-echo';

const EchoPlugin = {
    install(Vue, options) {

        // Export pusher to the window (Echo requires this)
        window.Pusher = require('pusher-js');

        Vue.prototype.$echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            cluster: process.env.VUE_APP_PUSHER_CLUSTER
        });
    }
};

export default EchoPlugin;


