import { LAYERED_DESIGNER_MAIN_CANVAS_ID, LAYERED_DESIGNER_VUEX_PREFIX } from '@/components/creative-studio/common/constants';
import {
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS,
    SET_LAYERED_DESIGNER_CARD_LAYERS,
    SET_LAYERED_DESIGNER_CURRENT_CARD,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_SHADOW,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET
} from '@/store/mutation-types';
import { generateDataURLFromElement } from '@/components/creative-studio/layered-designer/utils/static-assets-generation';

const ACCEPTED_VUEX_MUTATION_TYPES = [
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS,
    SET_LAYERED_DESIGNER_CARD_LAYERS,
    SET_LAYERED_DESIGNER_CURRENT_CARD,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_SHADOW,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET,
];

export const checkIsMutationAccepted = (mutation) => ACCEPTED_VUEX_MUTATION_TYPES.includes(mutation.type);

export const manageCardPreview = async(mutation, state, setThumbnailAction) => {
    const isMutationFromLayeredDesigner = mutation.type.startsWith(LAYERED_DESIGNER_VUEX_PREFIX);
    const hasToGeneratePreview = isMutationFromLayeredDesigner
        && checkIsMutationAccepted(mutation)
        && state.layeredDesigner?.currentCard !== null;

    if (!hasToGeneratePreview) {
        return;
    }

    return generateCardPreview({ state, setThumbnailAction });
};

export const generateCardPreview = async({ state, setThumbnailAction }) => {
    const elementId = LAYERED_DESIGNER_MAIN_CANVAS_ID;
    const options = {
        fontEmbedCSS: state.layeredDesigner.fontEmbedCSS,
        ...state.layeredDesigner.currentCard.dimensions
    };
    const dataUrl = await generateDataURLFromElement(elementId, options);
    setThumbnailAction(dataUrl);
};
