<template>
    <div class="asset-library-header">
        <div
            class="header-content-details-view content">
            <div class="row-content">
                <div class="title-container">
                    <h2>Asset Library</h2>
                </div>
                <div class="link ml-auto">
                    Learn more about this tool
                </div>
            </div>
            <div class="search-bar">
                <icon
                    class="prepend-icon"
                    name="search"
                    width="22"
                    height="22" />
                <input
                    v-model="searchAssetText"
                    type="text"
                    class="styled-input"
                    placeholder="Search assets"
                    @keyup="$emit('search-asset', searchAssetText)">
            </div>
        </div>
    </div>
</template>
<script>
    import Icon from '@/components/globals/Icon';
export default {
    components: {
        Icon,
    },
    props: {
        isWrapped: {
        type: Boolean,
        },
        assetType: {
            required: false,
            type: String,
            default: ''
        }
  },
  data() {
    return {
      searchAssetText: '',
    };
  },
  computed: {
    title() {
        if(this.assetType) {
            return this.$options.filters.capitalize(this.assetType);
        }
        return this.$options.filters.capitalize('all assets');
    }
  },
};
</script>
<style lang="scss" >
.asset-library-header {
    background-color: #ECF2F5;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    padding: 20px 0;
    .content {
        width: 100%;
        padding-left: 360px;
    }
    .header-content-details-view {
        padding-right: 300px;
        transition: padding-right 0.5s;
    }
    .header-content-no-details {
        transition: padding-right 0.5s;
    }
    .row-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .link {
        font-size: 12px;
        color: $blue-bg;
        cursor: pointer;
        margin-right: 80px;
    }
    .title-container {
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
    }
    .search-bar {
        width: 1000px !important;
        margin-top: 20px;
        input::placeholder {
            color: #a7aaad;
        }
        input {
            width: 100%;
            background-color: #fff !important;
        }
    }

    .search-icon {
        position: absolute;
        margin-top: 8px;
    }
    .search-bar .styled-input {
        position: relative;
        background-color: transparent;
        border-color: #a7aaad;
        max-width: 13.5vw;
        outline: none;
        border-radius: 4px;
        padding: 10px 10px;
        border: solid 1px $gray;
        padding-left: 40px;
    }
    .search-bar .prepend-icon {
        position: absolute;
        margin-left: 10px;
        margin-top: 10px;
        z-index: 10;

    }

    .uploader-container {
        padding-left: 15px;
        padding-top: 25px;
        padding-right: 15px;
        background-color: #fff;
    }
}
</style>
