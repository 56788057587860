/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report-detailed': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<defs><path pid="0" d="M2 13v6H0v-4.013L2 13zm3.5-3l1 1.055V19h-2v-7.945L5.5 10zm5.5 3v6H9v-6l1 1 1-1zm4.5-5v11h-2v-8.552L15.5 8zM20 6v13h-2V6.588L18.682 6l.659.588L20 6zm0-5l-.76 4.246-1.01-1.019L10 11.762 5.556 7.18 0 13V9.454l5.556-5.752L10 8.3l6.73-5.83-1.174-1.19L20 1z" id="svgicon_report-detailed_a"/></defs><use _fill="#8F9EA6" xlink:href="#svgicon_report-detailed_a" transform="translate(0 -1)" fill-rule="evenodd"/>'
  }
})
