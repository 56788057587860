/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'main-link': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.998 0a.858.858 0 00-.45.152L.282 7.424c-.238.235-.406.74-.16 1.053.245.312.761.354 1.059.091L10 1.652l8.818 6.916c.298.263.825.23 1.06-.09.205-.282.155-.82-.16-1.054L10.449.152A.748.748 0 009.998 0zM4.145 7.1L2.68 8.25v7.023a.735.735 0 00.732.727h4.634c.194 0 .38-.077.518-.213a.725.725 0 00.214-.514v-2.91c0-.683.531-1.211 1.22-1.211.688 0 1.219.528 1.219 1.212v2.909c0 .192.077.377.214.514a.735.735 0 00.518.213h4.634c.194 0 .38-.077.517-.213a.725.725 0 00.214-.514V8.25l-1.463-1.152v7.447h-3.17v-2.182c0-1.464-1.21-2.666-2.684-2.666-1.473 0-2.683 1.202-2.683 2.666v2.182h-3.17V7.098z" _fill="#00A2EA" fill-rule="evenodd"/>'
  }
})
