/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop': {
    width: 16,
    height: 16,
    viewBox: '0 0 489.8 489.8',
    data: '<path pid="0" d="M336.2 489.4h55.2V98H0v55.2h336.2zM90.6.4h55.2v68H90.6z"/><path pid="1" d="M306.7 352.2H138V183.1H82.8v224.3h223.9zm114.7-7.8h68.4v55.2h-68.4z"/>'
  }
})
