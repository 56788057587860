<template>
    <div
        class="channel-selector"
        :class="{disabled: disabled}">
        <icon
            :name="platformConfig.icon"
            size="25px" />
        <div>
            <styled-checkbox
                :label="platformConfig.displayName"
                :value="platformConfig.platform"
                :disabled="disabled"
                :checked="checked"
                @change="selectPlatform" />
            <div v-if="platformConfig.comingSoon">
                <div class="coming-soon">
                    COMING SOON
                </div>
            </div>
            <div v-if="!mediaSupported">
                <div class="miss-deps reddish">
                    Media unsupported
                </div>
                <div>
                    <small>{{ platformConfig.displayName }} only supports video</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import { mapState, mapGetters } from 'vuex';
import EventBus from '@/event-bus';

export default {
    components: {
        Icon,
        StyledCheckbox,
    },
    props: {
        platformConfig: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            ads: state => state.adStudio.ads,
            adIndex: state => state.adStudio.adIndex,
        }),
        ...mapGetters([
            'dealerFeaturesIncomplete',
        ]),
        ...mapGetters({
            originalAsset: 'adStudio/selectedAdOriginalAsset',
        }),
        mediaSupported() {
            return this.platformConfig.supportedMedia.includes(this.originalAsset.asset_type.name);
        },
        disabled() {
            return !this.mediaSupported || this.platformConfig.comingSoon;
        },
        checked() {
            return this.ads[this.adIndex].configuration.selected_platforms.includes(this.platformConfig.platform);
        }
    },
    methods: {
        selectPlatform(event) {
            if (event.target.checked) {
                this.addPlatform();
            } else {
               this.removePlatform();
            }
        },
        addPlatform() {
            this.$store.commit('adStudio/addPlatform', {
                adIndex: this.adIndex,
                platform: this.platformConfig.platform
            });
        },
        removePlatform() {
            this.$store.commit('adStudio/removePlatform', {
                adIndex: this.adIndex,
                platform: this.platformConfig.platform
            });
        },
        closeAdStudio() {
            EventBus.$emit('close-ad-studio');
        }
    }
};
</script>

<style lang="scss">
.channel-selector{
    text-align: center;
    margin-bottom: 60px;
    &.disabled{
        .styled-checkbox, svg{
            opacity: 0.5;
        }
    }
    .styled-checkbox{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .styled-checkbox-checkmark{
        margin-top: 5px;
    }
    .miss-deps{
        margin-top: 5px;
        display: block;
        text-transform: uppercase;
        color: #EB8400;
        font-size: 10px;
        font-weight: 600;
        &.reddish{
            color: #EF5542;
        }
    }
    .coming-soon{
        opacity: 0.5;
        margin-top: 5px;
        font-size: 10px;
        font-weight: 600;
    }
}
</style>
