<template>
    <div
        v-if="open && focusedLayer"
        class="gradient-color-picker">
        <color-picker
            :class="`${focusedLayer.index}-gradient-picker`"
            :reference="`${focusedLayer.index}-gradient-picker`"
            :gradient="focusedLayer.colorGradient"
            :is-gradient="true"
            :on-start-change="onChange"
            :on-change="onChange"
            :on-end-change="onChange" />
    </div>
</template>

<script>
    import ColorPicker from '@/components/globals/GradientPicker/components/ColorPicker/index.vue';
    import { GRADIENT_TYPES, DEFAULT_GRADIENT_POINTS } from '@/creative-studio/domain/layered-designer/models/Layer';
    import { mapActions, mapGetters } from 'vuex';
    export default {
        components: {
            ColorPicker
        },
        props: {
            open: {
                required: true,
                type: Boolean
            },
            value: {
                required: true,
                type: Object
            },
        },
        data() {
            return {
               gradient: {
                    type: GRADIENT_TYPES.LINEAR,
                    degree: 1,
                    points: DEFAULT_GRADIENT_POINTS
                },
            };
        },

        computed: {
            ...mapGetters([
                'focusedLayer'
            ])
        },
        watch: {
            value(newValue) {
                const { type, degree, points } = newValue;
                if (type) {
                    this.gradient.type = type;
                }

                if (degree !== undefined) {
                    this.gradient.degree = degree;
                }

                if (points !== undefined) {
                    this.gradient.points = [...points];
                }
            },
        },
        methods: {
            ...mapActions([
                'updateShapeLayer'
            ]),
            onChange(changeData) {
                if(changeData.type === 'solid') {
                    if(this.firstInArrayExists(changeData.points)) {
                        const updateFields = {
                         fillColor: this.formatInRgba(changeData.points[0])
                        };
                        this.updateShapeLayer(updateFields);
                    }
                }
                this.gradient = { ...changeData };
                this.$emit('input' , changeData);
            },
            firstInArrayExists(array) {
                if(array[0]) {
                    return true;
                }
                return false;
            },
            formatInRgba(color) {
                const { red, green, blue, alpha } = color;
                return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
            }
        }
    };
</script>

<style lang="scss">

.gradient-color-picker {
    position: absolute;
    left: calc(50% - 150px);
    top: 40px;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: $white;
    padding-top: 17px;
}

</style>

