import ApiRepository from '@/api-repository';
import {
    SET_DEALER,
    UPDATE_DEALER,
    SET_DEALER_ID,
    SET_DEALER_LOADING,
    SET_DEALERS,
    SET_DEALERS_LOADING,
    SET_DEALER_FEATURE_STATUS,
    SET_DEALER_FEATURE_STATUS_LOADING,
    SET_DEALER_FEATURE_STATUS_UPDATED_AT,
    SET_DEALER_TIMEZONES,
    RECHECK_FACEBOOK_ASSETS
} from '../mutation-types';
import {
    GENERIC_SITE_PROVIDER,
    WEBSITE_INVENTORY_SOURCES,
    LDU_STATES,
    EVENT_FLOW_REQUIRED_PRODUCTS,
    VERTICAL_AUTOMOTIVE,
    TIKTOK_BB_PLATFORM_ID,
    SNAPCHAT_BB_PLATFORM_ID,
    PINTEREST_BB_PLATFORM_ID
} from '@/helpers/globals';
import isAdProduct from '@/helpers/isAdProduct';
import getRootDomain from '@/helpers/getRootDomain';
import { get, uniqBy } from 'lodash';
import moment from 'moment';
import { DEALER_STORAGE_KEY } from '@/helpers/storageKeys';

const apiRepository = new ApiRepository();

/**
 * Adds a dealer to a list of dealers if it's not in the list already
 *
 * @param {Array} dealers
 * @param {Object} dealer
 */
const addDealer = (dealers, dealer) => {

    if (!dealer) {
        return dealers;
    }

    if (!dealers.some(item => item.id === dealer.id)) {
        dealers.push(dealer);
    }

    return dealers;
};


const currentDealerJson = localStorage.getItem(DEALER_STORAGE_KEY);
const dealerJson = currentDealerJson ? JSON.parse(currentDealerJson) : null;

export default {
    state: {
        currentDealer: dealerJson,
        currentDealerId: get(dealerJson, 'id') || null,
        dealers: [],
        dealerLoading: false,
        dealersLoading: false,
        dealerChanged: false,
        featureStatus: [],
        featureStatusUpdatedAt: null,
        timezones: [],
        recheckFacebookAssets: 0
    },
    getters: {
        dealerChangedStatus(state) {
            return state.dealerChanged;
        },
        dealerInFacebookDpoJurisdiction(state) {
            const dealerState = get(state.currentDealer, 'state', null);
            return LDU_STATES.includes(dealerState);
        },
        dealerVehicleCount(state) {
            return get(state.currentDealer, 'vehicles_count', null) || 0;
        },
        dealerAddressString(state) {
            if (!state.currentDealer) {
                return '';
            }

            return `${state.currentDealer.address}, ${state.currentDealer.city}, ${state.currentDealer.state} ${state.currentDealer.zip}`;
        },
        dealerHasMarketplaceEnabledCatalog(state, getters) {
            return getters.dealerFacebookCatalogAnnotations.some(
                (catalog) => catalog.marketplace_enabled
            );
        },
        dealerHasGenericSiteProvider(state) {
            const siteProvider = get(state, 'currentDealer.site_provider_id');

            if (!siteProvider) {
                return false;
            }

            return siteProvider === GENERIC_SITE_PROVIDER;
        },
        dealerHostname(state) {
            const baseUrl = get(state, 'currentDealer.site_base_url');

            if (!baseUrl) {
                return null;
            }
            const url = new URL(baseUrl);
            return url.hostname;
        },
        dealerRootDomain(state, getters) {
            return getRootDomain(getters.dealerHostname);
        },
        dealerBusinessManager(state) {
            return get(state, 'currentDealer.agency.facebook_business_id', null);
        },
        dealerShouldCreateAGaProperty(state) {
            const agency_has_ga_account_id = get(state, 'currentDealer.agency.ga_account_id', null);
            const dealer_has_ga_account_id = get(state, 'currentDealer.ga_account_id', null);
            const dealer_has_ua_code = get(state, 'currentDealer.ua_code', null);

            // Check if the dealer already has a us code or ga account id
            if (dealer_has_ga_account_id || dealer_has_ua_code) {
                return false;
            }

            // Check if the agency has a ga account id
            if (!agency_has_ga_account_id) {
                return false;
            }

            return true;
        },
        dealerShouldCreateAGtmContainer(state) {
            const agency_has_gtm_account_id = get(state, 'currentDealer.agency.gtm_account_id', null);
            const dealer_has_gtm_account_id = get(state, 'currentDealer.gtm_account_id', null);
            const dealer_has_gtm_container_id = get(state, 'currentDealer.tag_manager_container_id', null);

            // Check if the agency does not have a gtm account id
            if (!agency_has_gtm_account_id) {
                return false;
            }

            // Check if the dealer already has a gtm account id or a gtm container id
            if (dealer_has_gtm_account_id || dealer_has_gtm_container_id) {
                return false;
            }

            return true;
        },
        dealerUsingEventFlow(state, getters) {
            const usesEventFlow = get(state, 'currentDealer.uses_event_flow', false);
            const isNewDealer = !usesEventFlow && !getters.dealerIsUsingGtm;

            return usesEventFlow || isNewDealer;
        },
        dealerIsUsingGtm(state) {
            const agencyGtmAccountId = get(state, 'currentDealer.agency.gtm_account_id', null);
            const dealerGtmContainerId = get(state, 'currentDealer.tag_manager_container_id', null);

            return !!agencyGtmAccountId || !!dealerGtmContainerId;
        },
        dealerFacebookAdAccountAnnotation(state) {
            // Get the ad account annotations and return the first one or null if none exist
            const adAccoundAnnotations =
                get(
                    state,
                    'currentDealer.facebook_ad_account_annotations.data',
                    null
                ) || [];
            return adAccoundAnnotations.length ? adAccoundAnnotations[0] : null;
        },
        dealerFacebookAdAccount(state, getters) {
            if (!getters.dealerFacebookAdAccountAnnotation) {
                return null;
            }
            return getters.dealerFacebookAdAccountAnnotation.facebook_ad_account_id.replace(
                'act_',
                ''
            );
        },
        dealerTiktokAdAccount(state) {
            const adAccounts = get(state, 'currentDealer.ad_accounts.data', null);
            return adAccounts.find(a => a.platform_id == TIKTOK_BB_PLATFORM_ID)?.external_ad_account_id;
        },
        dealerSnapchatOrganizationId(state) {
            const businessManagers = get(state, 'currentDealer.agency.business_managers.data', null);
            return businessManagers.find( bm => bm.platform_id == SNAPCHAT_BB_PLATFORM_ID)?.external_business_manager_id;
        },
        dealerSnapchatAdAccountId(state) {
            const adAccounts = get(state, 'currentDealer.ad_accounts.data', null);
            return adAccounts.find(a => a.platform_id == SNAPCHAT_BB_PLATFORM_ID)?.external_ad_account_id;
        },
        dealerPinterestOrganizationId(state) {
            const businessManagers = get(state, 'currentDealer.agency.business_managers.data', null);
            return businessManagers.find( bm => bm.platform_id == PINTEREST_BB_PLATFORM_ID)?.external_business_manager_id;
        },
        dealerPinterestAdAccountId(state) {
            const adAccounts = get(state, 'currentDealer.ad_accounts.data', null);
            return adAccounts.find(a => a.platform_id == PINTEREST_BB_PLATFORM_ID)?.external_ad_account_id;
        },
        dealerPinterestDynamicProductUrl(state, getters) {
            return `https://ads.pinterest.com/advertiser/${getters.dealerPinterestAdAccountId}/reporting/productgroups`;
        },
        dealerTiktokAdAccountUrl(state, getters) {
            return `https://ads.tiktok.com/i18n/dashboard?aadvid=${getters.dealerTiktokAdAccount}`;
        },
        dealerSnapchatAdAccountUrl(state, getters) {
            return `https://business.snapchat.com/${getters.dealerSnapchatOrganizationId}/settings/ad-accounts/${getters.dealerSnapchatAdAccountId}?ref_aid=${getters.dealerSnapchatAdAccountId}`;
        },
        dealerPinterestAdAccountUrl(state, getters) {
            return `https://www.pinterest.com/business/business-access/${getters.dealerPinterestOrganizationId}/ad-accounts/${getters.dealerPinterestAdAccountId}/details/`;
        },
        dealerFacebookAdAccountUrl(state, getters) {
            return `https://business.facebook.com/adsmanager/manage/campaigns?act=${getters.dealerFacebookAdAccount}&business_id=${getters.dealerBusinessManager}`;
        },
        dealerAdSpendOverride(state) {
            return (
                get(state.currentDealer, 'configuration.facebook_ad_spend_override') ||
                null
            );
        },
        dealerFacebookAdAccountUrlFiltered: (state, getters) => (params) => {
            const { dateRange = null, campaigns = [] } = params;

            const query = new URLSearchParams();

            if (dateRange) {
                const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
                // For whatever reason Facebook adds an extra day
                const endDate = moment(dateRange.endDate)
                    .add(1, 'day')
                    .format('YYYY-MM-DD');

                query.append('date', `${startDate}_${endDate}`);
            }

            if (campaigns.length) {
                query.append(
                    'filter_set',
                    `CAMPAIGN_GROUP_SELECTED-STRING_SETIN[${campaigns.join(',')}]`
                );
                query.append('selected_campaign_ids', campaigns.join(','));
            }

            return getters.dealerFacebookAdAccountUrl + '&' + query.toString();
        },
        dealerFacebookAdsFilter: (state, getters) => (params) => {
            const {
                dateRange = null,
                campaigns = [],
                adSets = [],
                ads = [],
            } = params;

            const query = new URLSearchParams();

            if (dateRange) {
                const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
                // For whatever reason Facebook adds an extra day
                const endDate = moment(dateRange.endDate)
                    .add(1, 'day')
                    .format('YYYY-MM-DD');

                query.append('date', `${startDate}_${endDate}`);
            }

            let filter = null;

            if (!campaigns.includes(undefined)) {
                query.append('selected_campaign_ids', campaigns.join(','));
                filter = 'campaigns';
            }

            if (!adSets.includes(undefined)) {
                query.append('selected_adset_ids', adSets.join(','));
                filter = 'adsets';
            }

            if (!ads.includes(undefined)) {
                query.append('selected_ad_ids', ads.join(','));
                filter = 'ads';
            }

            const baseUrl = `https://business.facebook.com/adsmanager/manage/${filter}?act=${getters.dealerFacebookAdAccount}&business_id=${getters.dealerBusinessManager}`;

            return baseUrl + '&' + query.toString();
        },
        dealerFacebookPageAnnotation(state) {
            // Get the page annotations and return the first one or null if none exist
            const pageAnnotations =
                get(state, 'currentDealer.facebook_page_annotations.data', null) || [];
            return pageAnnotations.length ? pageAnnotations[0] : null;
        },
        dealerFacebookPage(state, getters) {
            if (!getters.dealerFacebookPageAnnotation) {
                return null;
            }
            return getters.dealerFacebookPageAnnotation.facebook_page_id;
        },
        dealerFacebookPageUrl(state, getters) {
            return `https://business.facebook.com/${getters.dealerFacebookPage}?business_id=${getters.dealerBusinessManager}`;
        },
        dealerFacebookPixelAnnotation(state) {
            const pixelAnnotations =
                get(state, 'currentDealer.facebook_pixel_annotations.data', null) || [];
            return pixelAnnotations.length ? pixelAnnotations[0] : null;
        },
        dealerFacebookPixel(state, getters) {
            if (!getters.dealerFacebookPixelAnnotation) {
                return null;
            }
            return getters.dealerFacebookPixelAnnotation.facebook_pixel_id;
        },
        dealerFacebookPixelUrl(state, getters) {
            return `https://business.facebook.com/events_manager/pixel/events?business_id=${getters.dealerBusinessManager}&selected_data_sources=PIXEL&pixel_id=${getters.dealerFacebookPixel}`;
        },
        dealerFacebookOfflineEventSetAnnotation(state) {
            const offlineEventSetAnnotations =
                get(
                    state,
                    'currentDealer.facebook_offline_event_set_annotations.data',
                    null
                ) || [];
            return offlineEventSetAnnotations.length
                ? offlineEventSetAnnotations[0]
                : null;
        },
        dealerFacebookOfflineEventSet(state, getters) {
            if (!getters.dealerFacebookOfflineEventSetAnnotation) {
                return null;
            }
            return getters.dealerFacebookOfflineEventSetAnnotation
                .facebook_offline_event_set_id;
        },
        dealerFacebookOfflineEventSetUrl(state, getters) {
            return `https://business.facebook.com/events_manager/dataset/events?business_id=${getters.dealerBusinessManager}&selected_data_sources=DATA_SET&dataset_id=${getters.dealerOfflineEventSet}`;
        },
        dealerFacebookCatalogAnnotations(state) {
            return (
                get(
                    state,
                    'currentDealer.facebook_product_catalog_annotations.data',
                    null
                ) || []
            );
        },
        dealerFacebookProductCatalogAnnotation(state, getters) {
            if (!getters.dealerFacebookCatalogAnnotations.length) {
                return null;
            }

            return (
                getters.dealerFacebookCatalogAnnotations.find(
                    (catalog) => catalog.catalog_type_id === 2
                ) || null
            );
        },
        dealerFacebookProductCatalog(state, getters) {
            if (!getters.dealerFacebookProductCatalogAnnotation) {
                return null;
            }
            return getters.dealerFacebookProductCatalogAnnotation
                .facebook_product_catalog_id;
        },
        dealerFacebookProductCatalogUrl(state, getters) {
            return `https://business.facebook.com/products/catalogs/${getters.dealerFacebookProductCatalog}/products?business_id=${getters.dealerBusinessManager}`;
        },
        dealerFacebookAutomotiveCatalogAnnotation(state, getters) {
            if (!getters.dealerFacebookCatalogAnnotations.length) {
                return null;
            }

            return (
                getters.dealerFacebookCatalogAnnotations.find(
                    (catalog) => catalog.catalog_type_id === 1
                ) || null
            );
        },
        dealerFacebookAutomotiveCatalog(state, getters) {
            if (!getters.dealerFacebookAutomotiveCatalogAnnotation) {
                return null;
            }
            return getters.dealerFacebookAutomotiveCatalogAnnotation
                .facebook_product_catalog_id;
        },
        dealerFacebookAutomotiveCatalogUrl(state, getters) {
            return `https://business.facebook.com/products/catalogs/${getters.dealerFacebookAutomotiveCatalog}/products?business_id=${getters.dealerBusinessManager}`;
        },
        dealerConfiguration(state) {
            return get(state, 'currentDealer.configuration', null) || {};
        },
        dealerProducts(state) {
            return get(state.currentDealer, 'products.data', null) || [];
        },
        dealerPlatforms(state) {
            return get(state.currentDealer, 'platforms.data', null) || [];
        },
        dealerProductIds(state, getters) {
            return getters.dealerProducts.map((dealerProduct) => dealerProduct.id);
        },
        dealerHasAdProduct(state, getters) {
            if (!state.currentDealer) {
                return false;
            }

            // If one of the products in the array is an ad product this is true
            if (getters.dealerProducts.some(isAdProduct)) {
                return true;
            }

            // Otherwise they don't have any ad products
            return false;
        },
        dealerHasProduct: (state, getters) => (productId) => {
            if (!state.currentDealer) {
                return false;
            }

            return getters.dealerProductIds.includes(productId);
        },
        dealerHasProducts: (state, getters) => (productIds) => {
            if (!state.currentDealer) {
                return false;
            }

            // Return true if any of the products match the product IDs
            return getters.dealerProducts.some((product) =>
                productIds.includes(product.id)
            );
        },
        dealerFeatureObjects(state, getters) {
            // Collect all features
            let features = [];
            getters.dealerProducts.forEach((product) => {
                const productFeatures = product.features?.data || [];
                features = [...features, ...productFeatures];
            });

            // Return unique features by ID
            return uniqBy(features, 'id');
        },
        dealerFeatures(state, getters) {
            return getters.dealerFeatureObjects.map((feature) => feature.name);
        },
        dealerFeaturesByProducts: (state, getters) => (productIds) => {
            // Collect all features
            let features = [];
            getters.dealerProducts
                .filter((product) => productIds.includes(product.id))
                .forEach((product) => {
                    const productFeatures = product.features?.data || [];
                    features = [...features, ...productFeatures];
                });

            // Return unique features by ID and distill down to feature names
            return uniqBy(features, 'id').map((feature) => feature.name);
        },
        dealerHasFeature: (state, getters) => (feature) => {
            return getters.dealerFeatures.includes(feature);
        },
        dealerHasFeatures: (state, getters) => (features) => {
            return getters.dealerFeatures.some((feature) =>
                features.includes(feature)
            );
        },
        dealerHasVertical: (state) => (vertical) => {
            return state.currentDealer?.vertical_id === vertical;
        },
        dealerInAutoVertical(state, getters) {
            return getters.dealerHasVertical(VERTICAL_AUTOMOTIVE);
        },
        dealerFeaturesComplete(state) {
            return state.featureStatus
                .filter((feature) => feature.status === 'complete')
                .map((feature) => feature.feature);
        },
        dealerFeaturesIncomplete(state) {
            return state.featureStatus
                .filter((feature) => feature.status !== 'complete')
                .map((feature) => feature);
        },
        dealerFeaturesSetup(state) {
            return state.featureStatus
                .filter((feature) => feature.status !== 'missing_initial_setup')
                .map((feature) => feature.feature);
        },
        dealerInventorySources(state) {
            return (
                get(state.currentDealer, 'remote_dealer_identifiers.data', null) || []
            );
        },
        dealerInventoryConfigured(state, getters) {
            return !!getters.dealerInventoryType;
        },
        dealerInventoryType(state, getters) {
            if (getters.dealerWebsiteRdi) {
                return 'website_inventory';
            }

            if (getters.dealerFeedRdi) {
                return 'feed_inventory';
            }

            return null;
        },
        dealerProductOnboarding(state) {
            return (
                get(state.currentDealer, 'dealer_product_onboarding.data', null) || []
            );
        },
        dealerFeedRdi(state, getters) {
            return (
                getters.dealerInventorySources.find((source) => {
                    return !WEBSITE_INVENTORY_SOURCES.includes(
                        source.vehicle_data_source_id
                    );
                }) || null
            );
        },
        dealerWebsiteRdi(state, getters) {
            return (
                getters.dealerInventorySources.find((source) => {
                    return (
                        WEBSITE_INVENTORY_SOURCES.includes(source.vehicle_data_source_id) &&
                        source.id_string !== '-- temporary --' &&
                        !source.delegate_to_secondary_rdi
                    );
                }) || null
            );
        },
        dealerIsLoaded(state) {
            return (
                !state.dealerLoading &&
                state.currentDealerId !== null &&
                state.currentDealerId === get(state.currentDealer, 'id')
            );
        },
        newDealerIsLoading(state) {
            return (
                state.dealerLoading &&
                state.currentDealerId !== state.currentDealer?.id
            );
        },
        dealerPrimaryLogo(state) {
            return state.currentDealer.account_branding?.logos?.find(logo => logo.type === 'primary');
        }
    },
    actions: {
        async recheckFacebookAssets({ commit }) {
            commit(RECHECK_FACEBOOK_ASSETS);
        },
        waitForDealerLoaded({ getters }) {
            return new Promise((resolve) => {
                (function checkDealerLoaded() {
                    if (getters.dealerIsLoaded) {
                        return resolve();
                    }
                    setTimeout(checkDealerLoaded, 30);
                })();
            });
        },
        async processDealerInventory({ state, getters }, params = {}) {
            if (!getters.dealerInventorySources.length) {
                throw 'No configured inventory sources';
            }

            const data = {
                ...{
                    upload_exports: true,
                    upload_to_facebook: true,
                    notification_email: [],
                },
                ...params,
            };

            const operations = getters.dealerInventorySources.map((rdi) => {
                return apiRepository.processDealerInventory(
                    state.currentDealerId,
                    rdi.id,
                    data
                );
            });

            await Promise.all(operations);
        },
        async updateDealers({ commit }) {
            try {
                commit(SET_DEALERS_LOADING, true);

                const response = await apiRepository.getDealersMinimal();
                const dealers = response.data.data;
                const frozenDealers = dealers.map(Object.freeze);

                commit(SET_DEALERS, frozenDealers);

                return dealers;
            } catch (error) {
                console.error('Error retrieving dealers', error);
            } finally {
                commit(SET_DEALERS_LOADING, false);
            }
        },
        async updateCurrentDealer({ commit, state, rootGetters }) {
            try {
                const response = await apiRepository.getFullDealer(
                    state.currentDealerId,
                    rootGetters.userIsClient
                );

                const dealer = response.data.data;

                commit(UPDATE_DEALER, dealer);
            } catch (error) {
                console.error('Error updating dealer', error);
            }
        },
        async updateDealerById({ commit, state, dispatch, rootGetters }, dealerId) {
            state.dealerChanged = true;
            try {
                // Set the dealer ID before anyting else
                // This allows components that needs to kick off additional
                // dealer-specific requests to start before anything else happens
                commit(SET_DEALER_ID, dealerId);

                commit(SET_DEALER_LOADING, true);

                // Reset the feature status to alleviate overlap
                commit(SET_DEALER_FEATURE_STATUS_UPDATED_AT, null);
                commit(SET_DEALER_FEATURE_STATUS, []);

                const response = await apiRepository.getFullDealer(
                    state.currentDealerId,
                    rootGetters.userIsClient
                );

                const dealer = response.data.data;

                //Update the agency for the selected dealer
                dispatch('updateAgencyById', dealer.agency_id);

                commit(SET_DEALER, dealer);

                return dealer;
            } catch (error) {
                state.dealerChanged = false;
                console.error('Error updating dealer', error);
            } finally {
                state.dealerChanged = false;
                commit(SET_DEALER_LOADING, false);
            }
        },
        async updateDealerFeatureStatus({ state, getters, commit }, params) {
            try {
                const { reset = false, features = [], cached = false } = params || {};

                // If the request has been made to use cached data
                // and there is a previous check determine if the
                // cache is viable to use and return to stop any external
                // loaders since the consumer can rely on the cached data
                if (state.featureStatusUpdatedAt && cached) {

                    const ageSeconds =
                        (new Date().getTime() - state.featureStatusUpdatedAt.getTime()) /
                        1000;

                    // Check to ensure all features are up-to-date this can
                    // alleviate a situation where features are missing when upgrading, etc
                    const allFeaturesUpdated = getters.dealerFeatures.every(feature => {
                        return state.featureStatus.some(status => status.feature == feature);
                    });

                    // Exit on anything younger than 5 minutes
                    // and ensure that all features are up-to-date
                    if ((ageSeconds < 60 * 5) && allFeaturesUpdated) {
                        return;
                    }
                }

                commit(SET_DEALER_FEATURE_STATUS_LOADING, true);

                if (reset) {
                    commit(SET_DEALER_FEATURE_STATUS, []);
                }

                let featuresToUpdate = [];
                if (features.length) {
                    featuresToUpdate = features;
                } else {
                    featuresToUpdate = [...getters.dealerFeatures];
                }

                const dealerId = state.currentDealerId;

                // Get the feature status in parallel to speed things up
                const statusUpdatePromises = featuresToUpdate.map(async(feature) => {
                    const response = await apiRepository.getFeatureStatus(
                        dealerId,
                        feature,
                    );

                    return response.data;
                });

                const statusUpdates = await Promise.all(statusUpdatePromises);

                const globalStatus = [...state.featureStatus];

                // Loop through all the status updates to determine if we should apply them to
                // an existing index or add it to the list
                statusUpdates.forEach((featureStatus) => {
                    // Determine if the feature is already being tracked
                    const featureIndex = globalStatus.findIndex((globalFeatureStatus) => {
                        return globalFeatureStatus.feature === featureStatus.feature;
                    });

                    // If it was already in the list update it
                    if (featureIndex !== -1) {
                        globalStatus[featureIndex] = featureStatus;
                    }
                    // Otherwise add it
                    else {
                        globalStatus.push(featureStatus);
                    }
                });

                commit(SET_DEALER_FEATURE_STATUS_UPDATED_AT, new Date());
                commit(SET_DEALER_FEATURE_STATUS, globalStatus);
            } catch (error) {
                console.error(error);
            } finally {
                commit(SET_DEALER_FEATURE_STATUS_LOADING, false);
            }
        },
        async getDealerTimezones({ state, commit }) {

            // There's no reason to re-retrieve timezones since they don't change
            if (state.timezones.length) {
                return;
            }

            const response = await apiRepository.getTimezones();

            const timezones = response.data.data;

            commit(SET_DEALER_TIMEZONES, timezones);
        },
        async upgradeToEventFlow({ state, getters }, { dealerId, productIds }) {
            if (productIds.length <= 0) {
                return;
            }

            // Check if they are already using event flow 2.0
            if (state.currentDealer.uses_event_flow) {
                return;
            }

            // Check if the dealer has GTM and is not using event flow
            if (!((!getters.dealerUsingEventFlow) && state?.currentDealer?.tag_manager_container_id)) {
                return;
            }

            // Check if any of the onboarding product ids need to be using event flow 2.0
            if (!(EVENT_FLOW_REQUIRED_PRODUCTS.some(productId => productIds.includes(productId)))) {
                return;
            }

            return await apiRepository.upgradeToEventFlow(dealerId);
        },
        async disableSingleCallTrackingNumber({ state }, trackingNumber) {
            return apiRepository.disableSingleCallTrackingNumber(state.currentDealer.id, trackingNumber);
        }
    },
    mutations: {
        [SET_DEALER_ID](state, dealerId) {
            // Only update if the dealer has changed
            if (state.currentDealerId === dealerId) {
                return;
            }
            state.currentDealerId = dealerId;
        },
        [SET_DEALER](state, dealer) {
            /* if (get(dealer, 'id') === get(state.currentDealer, 'id')) {
                                return;
                        } */

            // Manipulate local storage for continuous use
            if (dealer === null) {
                localStorage.removeItem(DEALER_STORAGE_KEY);
            } else {
                localStorage.setItem(DEALER_STORAGE_KEY, JSON.stringify(dealer));
            }

            state.currentDealer = dealer;
            state.currentDealerId = dealer ? dealer.id : null;

            // Add the dealer to the list
            state.dealers = addDealer(state.dealers, dealer);
        },
        [UPDATE_DEALER](state, dealer) {
            localStorage.setItem(DEALER_STORAGE_KEY, JSON.stringify(dealer));
            state.currentDealer = dealer;
        },
        [SET_DEALER_LOADING](state, isLoading) {
            state.dealerLoading = isLoading;
        },
        [SET_DEALERS](state, dealers) {
            // Add the current dealer to the list if it exists
            state.dealers = addDealer(dealers, state.currentDealer);
        },
        [SET_DEALERS_LOADING](state, isLoading) {
            state.dealersLoading = isLoading;
        },
        [SET_DEALER_FEATURE_STATUS](state, featureStatus) {
            state.featureStatus = featureStatus;
        },
        [SET_DEALER_FEATURE_STATUS_LOADING](state, isLoading) {
            state.featureStatusLoading = isLoading;
        },
        [SET_DEALER_FEATURE_STATUS_UPDATED_AT](state, updatedAt) {
            state.featureStatusUpdatedAt = updatedAt;
        },
        [SET_DEALER_TIMEZONES](state, timezones) {
            state.timezones = timezones;
        },
        [RECHECK_FACEBOOK_ASSETS](state) {
            state.recheckFacebookAssets++;
        }
    },
};
