<template>
    <div class="ad-account-link-group">
        <p class="section-title">
            Ad Account
        </p>
        <div
            v-for="annotation in currentDealer.facebook_ad_account_annotations.data"
            :key="annotation.id">
            <ad-account-link
                :refresh="refresh"
                :dealer="currentDealer"
                :annotation="annotation" />
        </div>
        <div
            v-if="currentAgency.id === AGENCY_DU">
            <h4 class="opportunities-title">
                <icon
                    name="opportunity"
                    size="15" />
                Opportunities
            </h4>
            <ul class="opportunities">
                <li
                    v-for="(link, index) in opportunityLinks"
                    :key="index">
                    <a
                        :href="link.url"
                        target="_blank">{{ link.title }}</a>
                    <span> ({{ link.subTitle }})</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import AdAccountLink from '@/components/dealers/DealerID/QuickLinks/AdAccountLink';
    import Icon from '@/components/globals/Icon';
    import { AGENCY_DU } from '@/helpers/globals';

    export default {
        components: {
            Icon,
            AdAccountLink
        },
        data() {
            return {
                AGENCY_DU,
                refresh: 0
            };
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer,
                currentAgency: (state) => state.agency.currentAgency,
            }),
            ...mapGetters([
                'dealerBusinessManager',
                'dealerFacebookAdAccount'
            ]),
            opportunityLinks() {
                return [{
                    title: 'Video Ad Performance',
                    subTitle: 'TikTok/Snapchat Opp',
                    url: `https://business.facebook.com/adsmanager/manage/ads?act=${this.dealerFacebookAdAccount}&business_id=${this.dealerBusinessManager}&global_scope_id=793117967429501&nav_entry_point=am_local_scope_selector&columns=name%2Creach%2Cimpressions%2Cactions%3Alink_click%2Cactions%3Alanding_page_view%2Cspend%2Ccpm%2Ccpc%2Ccost_per_action_type%3Alanding_page_view%2Ccampaign_name%2Cdelivery&attribution_windows=default&date=2020-07-10_2022-06-01%2Cmaximum&insights_date=2020-07-10_2022-06-01%2Cmaximum&filter_set=SEARCH_BY_ADGROUP_NAME-STRING%1ECONTAIN%1E%22video%22%1DADGROUP_DELIVERY_STATUS-STRING_SET%1EIN%1E[%22active%22%2C%22pending%22]%1Dad.impressions-NUMBER%1EGREATER_THAN%1E0%1DDELIVERY_AGE-STRING_SET%1EANY%1E[%2225-34%22%2C%2235-44%22%2C%2245-54%22%2C%2255-64%22]&is_reload_from_account_change&sort=actions%3Alanding_page_view~0`
                },{
                    title: 'Female Audience Performance',
                    subTitle: 'Pinterest Opp',
                    url: `https://business.facebook.com/adsmanager/manage/ads?act=${this.dealerFacebookAdAccount}&business_id=${this.dealerBusinessManager}&global_scope_id=793117967429501&nav_entry_point=am_local_scope_selector&columns=name%2Creach%2Cimpressions%2Cactions%3Alink_click%2Cactions%3Alanding_page_view%2Cspend%2Ccpm%2Ccpc%2Ccost_per_action_type%3Alanding_page_view%2Ccampaign_name%2Cdelivery&attribution_windows=default&date=2020-07-10_2022-06-01%2Cmaximum&insights_date=2020-07-10_2022-06-01%2Cmaximum&filter_set=ADGROUP_DELIVERY_STATUS-STRING_SET%1EIN%1E[%22active%22%2C%22pending%22]%1Dad.impressions-NUMBER%1EGREATER_THAN%1E0%1DDELIVERY_AGE-STRING_SET%1EANY%1E[%2225-34%22%2C%2235-44%22%2C%2245-54%22%2C%2255-64%22]%1DOBJECTIVE-STRING_SET%1ENOT_IN%1E[%22PRODUCT_CATALOG_SALES%22]%1DDELIVERY_GENDER-STRING_SET%1EANY%1E[%22female%22]%1DSEARCH_BY_CAMPAIGN_GROUP_NAME-STRING%1ENOT_CONTAIN%1E%22lead%22&is_reload_from_account_change&sort=actions%3Alanding_page_view~0`
                }];
            }
        }
    };
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 16px;
    background: #f3f5f6;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}

.opportunities-title {
    margin-top: 20px;
    margin-bottom: 10px;
    color: $gray-darkest;
}

.opportunities {
    list-style-type: none;
}
</style>
