/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-play': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 0C7.164 0 0 7.164 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0zm-6 24V8l16.008 8L10 24z" _fill="#030104"/>'
  }
})
