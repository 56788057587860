<template>
    <div>
        <styled-tooltip
            nudge-right="135"
            position="right-far">
            <template #content>
                <div class="ad-studio-tooltip">
                    <span
                        v-if="originalAssetTypeName == IMAGE_ASSET_TYPE"
                        class="ad-studio-blue">Single Image Ad:</span>
                    <span
                        v-if="originalAssetTypeName == VIDEO_ASSET_TYPE"
                        class="ad-studio-blue">Video Ad:</span>
                    <span class="ml-2">{{ adName }}</span>
                </div>
            </template>
            <div
                v-if="currentAd"
                class="tri">
                <div />
                <div />
                <div />
            </div>
            <div
                v-if="!adValid && showValidationErrors"
                class="lt__exclamation">
                <icon
                    name="exclamation"
                    color="white"
                    size="15" />
            </div>
            <div class="lt__images">
                <div class="lt__image">
                    <img
                        v-if="originalAssetTypeName == IMAGE_ASSET_TYPE || originalAssetTypeName == DESIGN_ASSET_TYPE"
                        :src="originalAssetUrl"
                        alt="">
                    <video
                        v-else-if="originalAssetTypeName === VIDEO_ASSET_TYPE"
                        muted
                        paused>
                        <source
                            :src="originalAssetUrl"
                            type="video/mp4">
                    </video>
                </div>
            </div>
            <div class="lt__text">
                <span v-if="originalAssetTypeName == IMAGE_ASSET_TYPE">Single Image Ad</span>
                <span v-if="originalAssetTypeName == VIDEO_ASSET_TYPE">Video Ad</span>
                <span v-if="originalAssetTypeName == DESIGN_ASSET_TYPE">Design Ad</span>
            </div>
        </styled-tooltip>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';
import {
    IMAGE_ASSET_TYPE,
    VIDEO_ASSET_TYPE,
    DESIGN_ASSET_TYPE
} from '@/components/ad-studio/store/constants.js';
export default {
    components: {
        Icon,
        StyledTooltip
    },
    props: {
        ad: {
            type: Object,
            required: true
        },
        currentAd: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            IMAGE_ASSET_TYPE,
            VIDEO_ASSET_TYPE,
            DESIGN_ASSET_TYPE
        };
    },
    computed: {
        ...mapState({
            showValidationErrors: state => state.adStudio.showValidationErrors,
        }),
        adName() {
            return this.ad?.display_name;
        },
        adValid() {
            const selectedPlatforms = this.ad?.configuration?.selected_platforms;

            // The user has not selected a platform yet
            if (selectedPlatforms?.length == 0) {
                return false;
            }

            return selectedPlatforms?.every((platform) => {
                return this.ad?.configuration?.platform_specific?.[platform]?.form_valid;
            });
        },
        originalAssetTypeName() {
            return this.ad?.configuration?.all_platforms?.creatives?.[0]?.assets?.[0]?.asset_type?.name;
        },
        originalAssetUrl() {
            return this.ad?.configuration?.all_platforms?.creatives?.[0]?.assets?.[0]?.url;
        }
    }
};
</script>

<style lang="scss" scoped>
.lt{
    &__text{
        padding: 5px 15px;
        margin-bottom: 15px;
    }
    &__tooltip{
        position: absolute;
        background: white;
        z-index: 9999;
        left:50%;
    }
    &__exclamation{
        position: absolute;
        background: #EF5643;
        border-radius: 50%;
        right:28px;
        top:25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        z-index: 100;
    }
    &__images{
        margin-bottom: 15px;
        position: relative;
        width: 100%;
        div{
            display: inline-block;
        }
    }
    &__image{
        img, video{
            width: 50px;
            height: auto;
            vertical-align: top;
        }
    }
    &__block{
        position: relative;
        padding-top: 70px;
        box-sizing: border-box;
        text-align: center;
        border-right: 1px solid #CBCBCB;
        height: 100%;
        .tri{
            position: absolute;
            z-index: 3;
            right:-1px;
            top: 50%;
            margin-top: -25px;
            div{
                &:nth-of-type(1){
                    width: 25px;
                    height: 25px;
                    background: white;
                    transform: rotate(45deg) translate(12px, -6px);
                }
                &:nth-of-type(2){
                    position: absolute;
                    top:0;
                    right:0;
                    width: 25px;
                    height: 1px;
                    transform: rotate(-45deg) translate(-4px, 8px);
                    background: #CBCBCB;
                }
                &:nth-of-type(3){
                    position: absolute;
                    bottom:0;
                    right:0;
                    width: 25px;
                    height: 1px;
                    transform: rotate(45deg) translate(4px, -1px);
                    background: #CBCBCB;
                }
            }
        }
    }
    &__current{
        padding-top: 30px;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid #D3D5D6;
        &.selected{
            background-color: #dfdfdf;
        }
        &:hover{
            .lt__image{
                outline: 5px solid #03A2EA;
            }
        }
    }
}
</style>