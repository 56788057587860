export default {
    nav: {
        create: {
            ad: 'Create New Ad(s)',
            design: 'Create New Design',
            inventoryDeployment: 'Create Inventory Ad(s)',
            beta: 'BETA',
        }
    },
    adDeployment: {
        channelSelection: {
            manualDeployment: {
                mainMessage: 'Please note that while Facebook-only deployments are automated, multi-channel deployments require additional turnaround time. Most orders can be fulfilled within 3 business days, but delays may occur due to factors such as those listed here.',
                first: 'Platform delays outside of our control (pending whitelisting requests, etc)',
                second: 'Ad Account technical or billing issues',
                third: 'Requests requiring customization to copy or creative',
                fourth: 'Requests requiring custom targeting (Oracle provisioning, zip code lists, etc)',
                fifth: 'Requests for additional languages - Periods of abnormally high multi-channel deployment requests'
            }
        },
        adDestination: {
            description: 'Select the campaigns and their underlying sets for every channel that you\'re launching to. You can toggle the live status of each below as well!',
            title: 'Where do you want to deploy these ads?',
            tooltip: '`Use this toggle to enable or disable all ({adCount}) ads on {platformName} during deployment. After deployment, you can change the status under Campaign Management.`'
        },
        presetSelection: {
            title: 'Would you like to launch with presets or would you prefer to customize it?',
            description: 'We recommend that most launches use a awareness or traffic preset depending on the desired outcome; however, if you want to fine tune your audiences and objectives across each channel choose "Custom" below.',
            reach: {
                title: 'Awareness',
                description: 'Optimize your campaigns to reach the most potential customer possible with your market.',
            },
            traffic: {
                title: 'Traffic',
                description: 'Optimize your campaigns to find users who are most likely to visit your website and engage in its content.',
            },
            custom: {
                title: 'Custom',
                description: 'Customize exactly how you would like to configure your optimization and targeting across each channel.',
            }
        },
        deploymentModal: {
            panel: {
                newCampaign: {
                    title: 'New Campaign',
                    text: 'We all like a fresh start! If you have a specific objective, audience & budget you\'re targeting, creating a new campaign is your best bet.',
                },
                existingCampaign: {
                    title: 'Existing Campaign',
                    text: 'If you have campaigns already running across your target channels, that you want to deploy these ad(s) into, this is your ticket to success.'
                }
            }
        },
        productGroupSelection: {
            title: 'Product Groups',
            description: 'Choose which product/vehicles you want to advertise across the selected channels.  We recommend maximizing your inventory reach by advertising "All Inventory" but if you prefer to advertising specific groups of inventory select "Customize" below.',
            tooltips: {
                default: 'Advertise all your eligible inventory across the selected channels. This is our recommended approach when launching inventory ads unless you want to advertise specific groups of your inventory.',
                custom: 'Choose which "Product Groups" will be used in your inventory ads across each selected channel. Choosing this option you will give you the ability to select existing product groups for each channel or create new ones.',
            },
            misc: {
                default: 'All Inventory',
                custom: 'Customize',
                pinterest: 'Pinterest doesn’t support used inventory so we will send them new vehicles only.',
            }
        },
        dynamicAudienceConfiguration: {
            title: 'Dynamic Audience Configuration',
            description: 'Choose how dynamic audiences should be created and managed across the target platforms. You can choose to allow each platform to automatically handle who sees your inventory based on their own AI and targeting systems. If you want more specific options, such as prospecting or retargeting only, select the Customize option.',
            tooltips: {
                default: 'Allow each platform to automatically handle who sees your inventory ads based on their own AI and targeting systems.  While each platform handles dynamic audience generation uniquely, they generally combine data from EventFlow with their user\'s own behavior to decide the best users to put your products in front of.',
                custom: 'Choose more specific commonly used dynamic audience configuration options available on each platform like prospecting or retargeting only.',
                facebook: {
                    automatedByChannel: 'Take advantage of Meta\'s world class AI systems, which combines data from EventFlow and their user\'s own behavior, to decide which product their users will most likely be interested in.',
                    retargetingOnly: 'Use this option if you want Meta to build your audience based only on users who have visited a VDP on {dealerName}\'s website and increase the exposure if the specific vehicles viewed along with similar models.',
                    prospectingOnly: 'Prospecting is similar to automation in that it uses AI and EventFlow signals to find new customers but specifically excludes users who have visited {dealerName}\'s website.',
                },
                pinterest: {
                    automatedByChannel: 'Leverage Pinterest\'s dynamic audience generation algorithm, which combines data from EventFlow and their user\'s own behavior, to decide which users will be most likely interested in your products.',
                    retargetingOnly: 'Use this option if you want Pinterest to build your audience based only on users who have visited a VDP on {dealerName}\'s website and increase the exposure if the specific vehicles viewed along with similar models.',
                    customAudience: 'Select a specific custom audience from {dealerName}\'s Pinterest ad account',
                },
                tiktok: {
                    automatedByChannel: 'Leverage TikTok\'s dynamic audience generation algorithm, which combines data from EventFlow and their user\'s own behavior, to decide which users will be most likely interested in your products.',
                },
                snapchat: {
                    automatedByChannel: 'Leverage Snapchat\'s dynamic audience generation algorithm, which combines data from EventFlow and their user\'s own behavior, to decide which users will be most likely interested in your products.',
                    retargetingOnly: 'Use this option if you want Snapchat to build your audience based only on users who have visited a VDP on {dealerName}\'s website and increase the exposure if the specific vehicles viewed along with similar models.',
                    prospectingOnly: 'Prospecting is similar to automation in that it combines Snapchat with EventFlow signals to find new customers.',
                }
            },
        },
        dynamicAdDestination: {
            title: 'Ad Destination',
            description: 'Determine where users should be directed after clicking the ad\'s primary call-to-action. All channels default to the VDPs on {dealerName}\'s website.  If you would like to use some of Meta\'s alternative options such as Lead Forms (a.k.a. "Lead Ads") or their own "On-Facebook VDPs" (a.k.a. "On-Facebook AIA") select "Customize".',
            tooltips: {
                default: 'Users will be directed to VDPs on {dealerName}\'s website after clicking the ad\'s primary call-to-action.',
                custom: 'Select from some of Meta\'s alternative options such as Lead Forms (a.k.a. "Lead Ads") or their own "On-Facebook VDPs" (a.k.a. "On-Facebook AIA").',
                websiteVDPs: '{platform} users will be directed to VDPs on {dealerName}\'s website after clicking the ad\'s primary call-to-action.',
                leadForm: 'Users will be presented with a basic lead form inside Meta\'s interface after clicking the ad\'s primary call-to-action. Leads will be routed to configured lead destinations under {dealerName}\'s settings.',
                onFacebookVDPs: 'Users will be directed to a VDP within Meta\'s own interface that offers a customized native experience with each vehicles own images, pricing and attributes. Primary calls-to-action on the page direct users to Messenger where {dealerName}\'s sales reps can interact directly with customers. As of January 2024, only mobile users will be directed to On-FB VDPs.',
            },
        },
        adCopy: {
            title: 'Ad Copy',
            description: 'Customize the message you want users to see alongside your selected products. In addition to this copy users will also see the vehicle\'s imagery and other attributes like price, make & model -- depending on the channel & placement.',
        },
        review: {
            sendNotifications: {
                title: 'Notifications',
                description: 'Inventory ads can take a while. Would you like us to send a notification when the play has been deployed?',
                checkboxLabel: 'Send notification on completion to:',
            }
        },
        geoTargeting: {
            simple: 'Simple geo targeting allows you to customize the location targeting for all the channels you are deploying it at once. If you wish to configure each channel separately, use Customize',
            custom: 'Use this option to set specific location targeting options by channel. If you wish to edit all channels at once, use the simplified option.',
            customMilePinterest: 'Pinterest catalog ads only support DMA targeting but Pinterest has the ability to provision a user customized audience, in miles, using a third-party data network, on request. When selection this option, the ability to Customize location targeting will be disabled'
        }
    },
    campaignManagement: {
        fulfillmentType: {
            tooltips: {
                automated: 'Our automated deployments are tied directly to each channel and, as a result, deploy in real-time.  Deployments typically finish within 3 minutes but can take longer depending on the channel and number of ads.',
                assisted: 'Assisted deployments require additional team support and are typically unique to new channels and/or features that haven\'t been fully automated yet. Most deployments can be fulfilled within 3 business days, but delays may occur due to various factors.\n' +
                    '\n' +
                    'Our team should be reaching out in the near future to update you on the status of your deployment.'
            },
            automated: 'Automated',
            assisted: 'Assisted',
            mixed: 'Mixed',
        }
    },
    metrics: {
        reach: 'The number of people who saw your ads at least once. Reach is different from impressions, which may include multiple views of your ads by the same people.',
        impressions: 'Impressions are a common metric used by the online marketing industry. Impressions measure how often your ads were on screen for your target audience. An impression is counted as the number of times an instance of an ad is on screen for the first time. (Example: If an ad is on screen and someone scrolls down, and then scrolls back up to the same ad, that counts as 1 impression.)',
        clicks: 'The number of clicks on your ads. The metric counts multiple types of clicks on your ad, including certain types of interactions with the ad container, links to other destinations, and links to expanded ad experiences.',
        unique_clicks: 'The number of unique people who clicked on an ad. The metric counts multiple types of clicks on your ad, including certain types of interactions with the ad container, links to other destinations, and links to expanded ad experiences.',
        unique_outbound_clicks: 'The number of people who clicked an ad leading them off Facebook-owned properties.',
        landing_page_views: 'The number of times a person clicked on an ad link and then successfully loaded the destination webpage or Instant Experience.  This metric is in development as stated by Facebook.',
        unique_landing_page_views: 'The number of unique people who clicked on an ad link and then successfully loaded the destination webpage or Instant Experience. This metric is in development as stated by Facebook.',
        total_leads: 'The sum of all Facebook form leads and website leads.',
        spend: 'The estimated total amount of money you\'ve spent on your campaign, ad set or ad during its schedule.',
        cpm: 'CPM is a common metric used by the online advertising industry to gauge the cost-effectiveness of an ad campaign. It\'s often used to compare performance among different ad publishers and campaigns. CPM measures the total amount spent on an ad campaign, divided by impressions, multiplied by 1,000. (Example: If you spent $50 and got 10,000 impressions, your CPM was $5.)',
        cpc: 'CPC shows how much, on average, each link click costs you. CPC is a metric used in the online advertising industry for benchmarking ad efficiency and performance. The metric is calculated as the total amount spent divided by link clicks.',
        cpoc: 'Cost per outbound click.  This metric is calculated as the total amount spend divided by outbound clicks.',
        cplpv: 'Cost per landing page view.  This metric is calculated as the total amount spend divided by landing page views.',
        cpl: 'Cost per lead. This metric is calculated as total amount spent divided by leads.',
        cps: 'Cost per sale. This metric is calculated as total amount spent divided by number of sales',
        cpsu: 'CPSU shows you how much, on average, each Swipe Up costs you. CPSU is a metric used within the Snapchat platform that mimics CPC in Facebook. It is used in the online advertising industry for benchmarking ad efficiency and performance. The metric is calculated as the total amount spend divided by the number of Swipe Ups.',
        cppv: 'Cost per Page View. This metric is calculated by dividing the amount spend by the number of Page Views.',
        cpcv: 'Cost per Content View. This metric is calculated by dividing the amount spent by the number of Content Views.',
        ctr: 'Click through rate.  This metric is calculated as the total impressions divided by clicks.',
        landing_page_view_rate: 'Landing page view rate.  This metric is calculated as the total impressions divided by landing page views.',
        conversion_rate: 'Conversion rate.  This metric is calculated as the total leads divided by unique landing page views.',
        web_leads: 'The total number of combined phone clicks, email clicks, form submissions with contact information and widget conversions on your website that were referred by this channel\'s ads.  Note that widget conversions represent people who used a chat/trade/finance widget for more than our defined conversion threshold for that widget.',
        on_facebook_leads: 'Any leads that occurred on a Meta-owned property.  These include lead form submissions, messenger inquiries, click-to-call ads and any other conversion that occurs inside Meta.',
        off_facebook_leads: 'Also known as "Web Leads" these are the total number of combined phone clicks, email clicks, form submissions with contact information, clicks on critical conversion buttons that lead to external tools like financing systems and widget conversions on your website that were referred by Meta ads.  Note that widget conversions represent people who used a chat/trade/finance widget for more than our defined conversion threshold for that widget.',
        phone_calls: 'The total number of phone calls received from people who interacted with your ad on Facebook and clicked through to your website.',
        sms: 'The total number of text messages received from people who interacted with your ad on Facebook and clicked through to your website.',
        lead_ads: 'The total number of form submissions within a Facebook lead ad.',
        lead_rate: 'Lead Conversion Rate. The total number of leads divided by the total number of clicks.',
        on_facebook_phone_clicks: 'The total number of times users clicked a phone number on a Facebook product page.  This does not include clicks on "Click-to-Call" ads.',
        on_facebook_get_directions: 'The total number of times users clicked the get directions button on a Facebook product page.',
        unique_on_site_content_views: 'The number of unique people who clicked on an ad directed to an on-Facebook product display page and successfully loaded it.',
        lead_to_reach_rate: 'Total leads divided by the total reach.',
        lead_to_landing_page_view_rate: 'Total leads divided by landing page views.',
        lead_to_unique_click_rate: 'Total leads divided by unique clicks.',
        lead_to_unique_on_site_view_content_rate: 'Total leads divided by unique on-Facebook content views.',
        offline_purchase: 'The number of vehicle sales that Facebook has attributed to Offline Events that have been uploaded during the given date range and the selected view/click attribution windows.',
        cost_per_offline_purchase: 'Cost per sale. This metric is calculated as the total account spend divided by sales.',
        swipe_ups: 'The number of times your ad was swiped up on or the CTA was tapped to view the attachment below.',
        swipe_up_rate: 'Swipe Up Rate: Percentage of paid impressions swiped up on',
        sales: 'The number of vehicle sales that have been attributed to Offline Events that have been uploaded during the given date range.',
    }
};
