/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'creative': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M.776 2.574l1.552-1.552 1.272.34.143.038a.485.485 0 01.285.441.472.472 0 01-.061.23.48.48 0 01-.192.193L1.997 4.041a.477.477 0 01-.407.224.483.483 0 01-.48-.45L.775 2.574zm-.168-.628L1.7.854.73.594A.28.28 0 00.594.556H.591a.288.288 0 00-.282.272C.308.834.305.838.305.845c0 .058.02.107.048.152l.255.949zM2.36 4.602a.878.878 0 00.133 1.224v.005l4.015 4.02 3.26-2.943L5.62 2.762l-.171-.171a.878.878 0 00-1.122.045l-.074.073-1.739 1.738-.155.155zm17.082 12.079a.48.48 0 00-.795.036l-2.19 2.19-.129.13a.48.48 0 00.093.643 2.193 2.193 0 003.084-2.89c-.018-.039-.041-.074-.062-.11zm-3.018-2.131a.881.881 0 00-.072-1.058l-.097-.096-3.06-3.06-2.944 3.262 2.938 2.941.012.011a.883.883 0 001.353-.13l1.608-1.607a.884.884 0 00.262-.263zm-.074 3.664l1.608-1.607a.892.892 0 00.406-.744.88.88 0 00-.313-.674.884.884 0 00-1.41.078l-1.6 1.6a.887.887 0 00-.408.747c0 .27.123.51.314.672a.883.883 0 001.403-.072zm-12.297.944c-.023.022-.048.04-.073.06a2.198 2.198 0 11-3.233-2.969c.045-.046.092-.086.14-.125.02-.025.036-.05.059-.073l9.743-8.795a.95.95 0 011.344.001l.815.814a.95.95 0 010 1.345l-8.795 9.742zM14.743 5.36a2.47 2.47 0 003.496 0A5.534 5.534 0 0019.744.315l-.03-.132a.32.32 0 00-.062-.092.312.312 0 00-.44 0 .289.289 0 00-.056.085c-.008.016-.012.032-.019.047a1.798 1.798 0 01-1.974 1.012l-.008-.003a2.473 2.473 0 00-2.413 4.128zm.55 1.382L13.42 4.87a.95.95 0 00-1.344 0L11.95 5a.948.948 0 000 1.343l1.87 1.872c.371.37.974.37 1.344 0l.129-.128a.952.952 0 000-1.344z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
