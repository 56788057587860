import { AssetType } from '@/creative-studio/domain/assets/models/types';
import { ImageAssetMapper } from './ImageAssetMapper';
import { ShapeAssetMapper } from './ShapeAssetMapper';
import { TextAssetMapper } from './TextAssetMapper';

export class AssetMapper {
    static toDomain(assetResource, ...args) {

        if (typeof assetResource.asset_type === 'object') {
            assetResource.asset_type = assetResource.asset_type.name;
        }

        const mapper = AssetMapper.getMapper(assetResource);

        return mapper.toDomain(assetResource, ...args);
    }

    static getMapper({ asset_type, type }) {

        // Support remote and local asset types
        switch(asset_type ?? type) {
            case AssetType.Shape:
                return ShapeAssetMapper;
            case AssetType.Text:
                return TextAssetMapper;
            case AssetType.TextTemplate:
                return TextAssetMapper;
            case AssetType.Image:
                return ImageAssetMapper;
            default:
                return ImageAssetMapper;
        }
    }
}