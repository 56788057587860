<template>
    <div :class="wrapperClass">
        <h3 
            v-if="label" 
            style="color: #8f9ea6;" 
            class="loader-text mb-4">
            {{ label }}
        </h3>
        <v-progress-circular v-bind="mergedProgressProps" />
    </div>
</template>

<script>
export default {
    props: {
        label: {
            validator(value) {
                return (typeof value === 'string' || typeof value === 'boolean');
            },
            default: 'Loading'
        },
        progressProps: {
            type: Object,
            default() {
                return {};
            }
        },
        wrapperClass: {
            type: String,
            default: 'text-xs-center pa-5'
        }
    },
    data() {
        return {};
    },
    computed: {
        mergedProgressProps() {
            const defaults = {
                size: 50,
                color: 'primary',
                indeterminate: true
            };

            return {
                ...defaults,
                ...this.progressProps
            };
        }
    }
};
</script>

<style lang="scss" scoped>

.loader-text {
    color: $gray-text;
}
</style>


