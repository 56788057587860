<script>
import { NODES_RELEVANT_STYLES } from '@/components/creative-studio/common/constants';
export default {
    props: {
            content: {
                type: String,
                required: true
            },
            editableNodes: {
                type: Array,
                required: false,
                default: () => []
            }
    },
    methods: {

        isValidNode(node) {
                return typeof node === 'object' && node !== null && 'getAttribute' in node;
        },
        getVNodeAttributes(node) {
               const nodesArray = Array.from(node.attributes);
               const attributes = {};
               nodesArray.forEach(element => {
                    Object.assign(attributes, {
                        [element.nodeName] : element.nodeValue
                    });
               });
               return attributes;
        },
        getVNodeStyle(node) {
                if(!NODES_RELEVANT_STYLES.includes(node.nodeName)) {
                    return;
                }
                return this.styles;
        },
        getVNodes(h, nodes) {
                const children = Array.from(nodes);
                const vnodes =  children.map(child => {
                if (child.nodeType == Node.ELEMENT_NODE) {
                    return h(
                        child.nodeName, {
                            attrs: this.getVNodeAttributes(child)
                        },
                        this.getVNodes(h, child.childNodes)
                    );
                }
                if(child.nodeType === Node.TEXT_NODE) {
                    const textToDisplay = this.editableNodes.length > 0 &&
                    this.editableNodes.find(item => item.id === child.parentElement.getAttribute('bb-text-field-id'))?.textContent;
                // Create node text only using prototype Vue.prototype._v = createTextVNode;
                    return this._v(textToDisplay || child.textContent);
                }
            });
            return vnodes;
        }
    },
    render(h) {
            const tempElem = document.createElement('div');
            tempElem.innerHTML = this.content;
            const vNodes = this.getVNodes(h, tempElem.childNodes);
            return h('div',  {
                class: 'canvas-text-asset',
                on: {
                    dblclick: () => this.$emit('double-click'),
                    mousedown: () => this.$emit('on-mousedown'),
                 },
            },
            vNodes);
        }
};
</script>