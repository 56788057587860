import HttpClient from '@/http';
import { isAfter } from 'date-fns';

const getDealerOfflineEvents = async({ dealerId, startDate, endDate }) => {
    // We do not support query that has endDate in the future.
    if(isAfter(endDate, new Date())) {
        endDate = new Date();
    }
    // "END" time should be the latest second of the endDate (example - 23:59:59)
    // so we need to add 86399 miliseconds to the endDate!
    const unixStartDate = Math.floor(new Date(startDate).getTime() / 1000);
    const unixEndDate = Math.floor(new Date(endDate).getTime() / 1000) + 86399;
    const data = {
        start: unixStartDate,
        end: unixEndDate,
    };

    const response = await (new HttpClient()).get(`dealers/${dealerId}/graph-api/offline-event-set/stats`, data);

    return response.data;
};

export default getDealerOfflineEvents;
