import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';

const _mutationTypes = removeNamespace('metrics/aggregate/', mutationTypes);

export default {
    [_mutationTypes.SET_AGG_PLATFORMS_SELECTED](state, platforms) {
        state.platformsSelected = platforms;
    },

};