import { Layer } from '@/creative-studio/domain/layered-designer/models/Layer';
import { AssetMapper } from '@/creative-studio/domain/assets/services/api/mappers/AssetMapper';

export class LayerMapper {
    static toDomain(layerResource) {
        const {  name, asset, position, dimensions, originalBaseDimensions, rotateAngle, scale, anchorPosition,anchoredPositions, index, cropOffset, alterations, shadow, colorMode, colorGradient } = layerResource;

        const id = Math.floor(Math.random() * 10000);

        const layer = new Layer(id);

        layer.name = name;

        layer.asset = AssetMapper.toDomain(asset);

        layer.anchorPosition = anchorPosition || null;
        layer.anchoredPositions = anchoredPositions || [];

        layer.index = index;

        if (position?.top !== undefined && position?.left !== undefined) {
            layer.position = position;
        }

        if (dimensions?.height !== undefined && dimensions?.width !== undefined) {
            layer.dimensions = dimensions;
        }

        if (originalBaseDimensions?.cardDimensions !== undefined && originalBaseDimensions?.layerOriginalDimensions !== undefined) {
            layer.originalBaseDimensions = originalBaseDimensions;
        }

        if (rotateAngle !== undefined) {
            layer.rotateAngle = rotateAngle;
        }

        if (scale != null) {
            layer.scale = scale;
        }

        if (cropOffset?.top !== undefined && cropOffset?.left !== undefined && cropOffset?.right !== undefined && cropOffset?.bottom !== undefined) {
            layer.cropOffset = cropOffset;
        }

        if (alterations?.isHorizontalFlipped !== undefined && alterations?.isVerticalFlipped !== undefined && alterations?.hasToKeepSize !== undefined) {
            layer.alterations = alterations;
        }

        if (shadow?.xOffset !== undefined && shadow?.yOffset !== undefined && shadow?.blur !== undefined && shadow?.color !== undefined) {
            layer.shadow = shadow;
        }

        if (colorMode !== undefined) {
            layer.colorMode = colorMode;
        }

        if (colorGradient?.type !== undefined && colorGradient?.degree !== undefined && colorGradient?.points !== undefined) {
            layer.colorGradient = colorGradient;
        }

        return layer;
    }
}