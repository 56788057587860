import { validatePlatformBudget, validateBidCap } from '../helpers/validatePlatformBudget';
import {
    NEW_CAMPAIGN_DEPLOYMENT,
    EXISTING_CAMPAIGN_AD_DEPLOYMENT,
    STATIC_DEPLOYMENT_TYPE,
    DYNAMIC_DEPLOYMENT_TYPE,
} from '@/components/ad-deployment/store/constants';
import fieldValidator from '@/validators/granular/lengthValidator';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';
import { headlineKey, primaryTextKey } from '@/components/ad-studio/store/constants';

export default [
    {
        name: 'presets_selection',
        component: () => import('./Presets'),
        isValid: () => true,
        isActive: () => ({ state }) => {
            return state.campaign_selection?.selection === NEW_CAMPAIGN_DEPLOYMENT && Boolean(
                state.campaign_selection?.all_platforms?.campaign_name?.length
                && state.campaign_selection?.all_platforms?.ad_category?.length
            );
        },
        configuration: {
            campaignType: [NEW_CAMPAIGN_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'channel_selection',
        component: () => import('./ChannelSelection'),
        isValid: ({ state }) => (
            Boolean(state.platforms.length) && Boolean(
                (state.campaign_selection?.selection === NEW_CAMPAIGN_DEPLOYMENT && state.campaign_selection.all_platforms.campaign_name) ||
                state.campaign_selection?.selection === EXISTING_CAMPAIGN_AD_DEPLOYMENT)
        ),
        isActive: () => true,
        configuration: {
            campaignType: [NEW_CAMPAIGN_DEPLOYMENT, EXISTING_CAMPAIGN_AD_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE, DYNAMIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'ad_configuration',
        component: () => import('./AdConfiguration'),
        isValid: () => true,
        isActive: () => true,
        configuration: {
            campaignType: [NEW_CAMPAIGN_DEPLOYMENT, EXISTING_CAMPAIGN_AD_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'product_group_selection',
        component: () => import('./DynamicDeployment/ProductGroupSelection'),
        isValid: ({ state }) => {
            return state.platforms.every(platform => (
                Boolean(state.product_group_selection?.platform_specific?.[platform]?.name)
            ));
        },
        isActive: () => true,
        configuration: {
            campaignType: [],
            deploymentType: [DYNAMIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'dynamic_audience_configuration',
        component: () => import('./DynamicDeployment/DynamicAudienceConfiguration'),
        isValid: () => true,
        isActive: () => true,
        configuration: {
            campaignType: [],
            deploymentType: [DYNAMIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'ad_destination',
        component: () => import('./DynamicDeployment/AdDestination'),
        isValid: () => true,
        isActive: () => true,
        configuration: {
            campaignType: [],
            deploymentType: [DYNAMIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'ad_copy',
        component: () => import('./DynamicDeployment/AdCopy'),
        isValid: ({ state }) => {
            const fields = {
                [PLATFORM_FACEBOOK]: primaryTextKey,
                [PLATFORM_TIKTOK]: headlineKey,
                [PLATFORM_SNAPCHAT]: headlineKey,
            };

            return state.platforms.every(platform => {
                if (platform === PLATFORM_PINTEREST) {
                    return true;
                }

                return fieldValidator(fields[platform], state.ad_copy?.platform_specific?.[platform], platform);
            });
        },
        isActive: () => true,
        configuration: {
            campaignType: [],
            deploymentType: [DYNAMIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'ad_destination',
        component: () => import('./AdDestination'),
        isValid: ({ state }) => {
            return state.platforms.every(platform => (
                state.campaign_selection?.platform_specific?.[platform]?.campaign?.name?.length
                && state.campaign_selection?.platform_specific?.[platform]?.adSet?.name?.length
            ));
        },
        isActive: ({ state }) => {
            state.campaign_selection?.selection === NEW_CAMPAIGN_DEPLOYMENT;
        },
        configuration: {
            campaignType: [EXISTING_CAMPAIGN_AD_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'campaign_configuration',
        component: () => import('./CampaignConfiguration'),
        isValid: ({ state }) => {
            // @todo Add additional fields to validation
            return state.platforms.every(platform => (
                Boolean(state.optimization?.platform_specific?.[platform]?.objective?.length)
            ));
        },
        isActive: ({ state }) => (
            state.campaign_selection?.selection === NEW_CAMPAIGN_DEPLOYMENT
        ),
        configuration: {
            campaignType: [NEW_CAMPAIGN_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE]
        },
    },
    {
        name: 'audiences',
        component: () => import('./Audiences'),
        isValid: () => true,
        isActive: ({ state }) => (
            state.campaign_selection?.selection === NEW_CAMPAIGN_DEPLOYMENT
        ),
        configuration: {
            campaignType: [NEW_CAMPAIGN_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'geo_targeting',
        component: () => import('./GeoTargeting'),
        isValid: ({ state }) => {
            return state.is_simple_geo || state.platforms.every(channel => {
                return Boolean(state.geo_targeting?.platform_specific?.[channel]?.locations?.length);
            });
        },
        isActive: ({ state }) => (
            state.campaign_selection?.selection === NEW_CAMPAIGN_DEPLOYMENT
        ),
        configuration: {
            campaignType: [NEW_CAMPAIGN_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE, DYNAMIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'budgeting',
        component: () => import('./Budgeting'),
        isValid: ({ state, getters }) => {
            const schedule = state.budget.schedule;
            const { start_date_time, end_date_time } = state.budget;

            if (
                end_date_time &&
                new Date(start_date_time) >= new Date(end_date_time)
            ) {
                return false;
            }

            if (
                schedule === 'lifetime' &&
                (
                    !start_date_time?.length ||
                    !end_date_time?.length
                )
            ) {
                return false;
            }

            return state.platforms.every(platform => {
                const budgetResult = validatePlatformBudget({
                    platform,
                    days: getters.totalRunningDays,
                    adDeploymentState: state
                });

                const bidCapResult = validateBidCap({
                    platform,
                    adDeploymentState: state
                });

                return [budgetResult, bidCapResult].every(result => result === true);
            });
        },
        isActive: ({ state }) => (
            state.campaign_selection?.selection === NEW_CAMPAIGN_DEPLOYMENT
        ),
        configuration: {
            campaignType: [NEW_CAMPAIGN_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE, DYNAMIC_DEPLOYMENT_TYPE]
        }
    },
    {
        name: 'review',
        component: () => import('./Review'),
        isValid: () => true,
        isActive: () => true,
        configuration: {
            campaignType: [NEW_CAMPAIGN_DEPLOYMENT, EXISTING_CAMPAIGN_AD_DEPLOYMENT],
            deploymentType: [STATIC_DEPLOYMENT_TYPE, DYNAMIC_DEPLOYMENT_TYPE]
        }
    }
];
