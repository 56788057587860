/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-align-right': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M81.232 15.138H18.769a3.407 3.407 0 00-3.407 3.407v3.143a3.407 3.407 0 003.407 3.407h62.463a3.407 3.407 0 003.407-3.407v-3.143a3.407 3.407 0 00-3.407-3.407zM81.232 35.06H35.597a3.407 3.407 0 00-3.407 3.407v3.143a3.407 3.407 0 003.407 3.407h45.634a3.407 3.407 0 003.407-3.407v-3.143a3.407 3.407 0 00-3.406-3.407zM81.217 74.905H25.583a3.407 3.407 0 00-3.407 3.407v3.143a3.407 3.407 0 003.407 3.407h55.634a3.407 3.407 0 003.407-3.407h.015v-3.143h-.015a3.408 3.408 0 00-3.407-3.407zM84.639 58.39a3.407 3.407 0 00-3.407-3.407h-38.82a3.407 3.407 0 00-3.407 3.407v3.143a3.407 3.407 0 003.407 3.407h38.82a3.407 3.407 0 003.407-3.407V58.39z"/>'
  }
})
