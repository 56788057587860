<template>
    <div class="holder">
        <div class="holder__title">
            <div class="holder__title__left">
                <span>{{ fieldProps.ui_name }}</span>
                <icon-with-tooltip
                    v-if="fieldProps.disableTooltip"
                    class="ml-2"
                    :tooltip="utmTooltipText"
                    has-tooltip
                    icon="question-circle" />
            </div>
            <icon-list
                v-if="!customizedPerPlatform"
                :key="iconListKey"
                :field-props="fieldProps"
                :validation-result="validationResult"
                :selected-channels="selectedAdPlatforms" />
        </div>
        <v-textarea
            v-if="fieldProps.component === 'textArea'"
            ref="fieldAdStudio"
            v-model="getFieldByKey"
            :rules="fieldProps.rules"
            :required="fieldProps.required"
            :maxlength="findMaxLengthField(fieldKey)"
            class="styled-field"
            @input="handlesValidation($event, fieldKey)" />
        <v-text-field
            v-else-if="fieldProps.component === 'textField'"
            ref="fieldAdStudio"
            v-model="getFieldByKey"
            :rules="fieldProps.rules"
            :maxlength="findMaxLengthField(fieldKey)"
            class="styled-field"
            data-lpignore="true"
            @input="handlesValidation($event, fieldKey)" />
        <v-select
            v-else-if="fieldProps.component === 'select'"
            ref="fieldAdStudio"
            v-model="getFieldByKey"
            :items="itemsForCta"
            :rules="fieldProps.rules"
            class="styled-field" />
        <div
            v-else-if="fieldProps.component === 'utmContent'"
            class="utmFieldText">
            {{ utmFieldText }}
        </div>
        <div
            v-if="!fieldProps.disableTooltip"
            class="holder__info mt-1">
            <span v-if="fieldProps.hasMaxLength"><span v-text="fieldLength" />/{{ findMaxLengthField(fieldKey) }}</span>
            <icon-with-tooltip
                class="single-icon holder__info"
                icon="info"
                has-tooltip
                :tooltip="getTextForTooltip(fieldKey)" />
        </div>
    </div>
</template>

<script>
// Same as debounceTimer() comment
const validatesFieldAndChangePreview = function(event, field) {
    if (field) {
        this.handlesValidation(field, event);
    }
};

import { OVERRIDE_UTM_PARAMS } from '@/helpers/globals';
import IconWithTooltip from '@/components/ad-studio/editing/preview-step/IconWithTooltip';
import IconList from '@/components/ad-studio/editing/preview-step/IconList';
import { mapGetters, mapState } from 'vuex';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';
import { FIELDS_NAMING_CONFIGURATION } from '@/components/ad-studio/store/constants';
import { capitalizeAndSwapFB } from '@/helpers/capitalizeAndSwapFB';
import { debounce } from 'lodash';

export default {
    name: 'AdStudioField',
    components: {
        IconWithTooltip,
        IconList
    },
    props: {
        fieldProps: {
            type: Object,
            required: true
        },
        adIndex: {
            type: Number,
            required: true,
            default: 0
        }
    },
    data() {
        return {
            validationResult: null,
            iconListKey: 0
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency
        }),
        ...mapGetters('adStudio', [
            'selectedAdPlatforms'
        ]),
        currentAd() {
            return this.$store.state.adStudio.ads[this.adIndex];
        },
        customizedPerPlatform() {
            return this.currentAd.configuration.customized_per_platform;
        },
        fieldKey() {
            return this.fieldProps.key;
        },
        activePlatform() {
            return this.$store.state.adStudio.ads[this.adIndex]?.active_platform;
        },
        utmFieldText() {
            const platform = this.activePlatform || '{{platform_name}}';

            if (this.currentAgency.configuration?.[OVERRIDE_UTM_PARAMS]) {
                return `utm_source=${this.currentAgency.utm_company_name}&utm_medium=${platform}&utm_campaign={{campaign_name}}`;
            }

            return `utm_source=${platform}&utm_medium=cpc&utm_campaign=${this.currentAgency.utm_company_name}`;
        },
        getFieldByKey: {
            get() {
                if (!this.customizedPerPlatform) {
                    return this.currentAd.configuration?.all_platforms[this.fieldProps.key];
                } else {
                    return this.currentAd.configuration?.platform_specific[this.activePlatform][this.fieldProps.key];
                }
            },
            set(value) {
                this.$refs.fieldAdStudio.resetValidation();

                this.$store.commit('adStudio/updateAdField', {
                    adIndex: this.adIndex,
                    key: this.fieldProps.key,
                    value,
                    platform: this.activePlatform // this is only used when customizedPerPlatform is true
                });
            }
        },
        fieldLength() {
            return this.getFieldByKey?.length || 0;
        },
        // TODO: Needs to adds debounce to field? test it out later
        debounceTimer() {
            return this.isVideo ? 1000 : 0;
        },
        eventEmitted() {
            return debounce(validatesFieldAndChangePreview, this.debounceTimer);
        },
        isVideo() {
            return this.originalFileType === 'VIDEO';
        },
        itemsForCta() {
            if (this.customizedPerPlatform) {
                return this.fieldProps.specificItems?.[this.activePlatform];
            }

            return this.fieldProps.items;
        },
        utmTooltipText() {
            const defaultPrefix = 'URL parameters use a standard UTM format by default. ';

            if (this.currentAgency.configuration?.[OVERRIDE_UTM_PARAMS]) {
                return defaultPrefix + 'The utm_source will be dynamic based on the organization name and can be changed by contacting support and the utm_medium will be based on the platform name.';
            }

            return defaultPrefix + 'The utm_source will be dynamic based on the platform and utm_campaign can be changed by contacting support.';
        }
    },
    watch: {
        getFieldByKey() {
            this.handlesValidation(this.getFieldByKey, this.fieldKey);
        }
    },
    methods: {
        findMaxLengthField(fieldKey) {
            if (!this.customizedPerPlatform) {
                return Math.max(
                    ...this.selectedAdPlatforms
                        .map(platform => lengthValidatorConstants[fieldKey]?.[platform]?.maxLength || null)
                ) || null;
            }

            return lengthValidatorConstants[fieldKey]?.[this.activePlatform]?.maxLength || null;
        },
        handlesValidation(value, fieldKey) {
            if (lengthValidatorConstants[fieldKey]) {
                this.validationResult = {};
                this.selectedAdPlatforms.forEach(platform => {
                    const platformObj = {
                        [platform]: true
                    };
                    if (lengthValidatorConstants[fieldKey][platform]?.maxLength < value.length) {
                        platformObj[platform] = false;
                    }
                    Object.assign(this.validationResult, platformObj);
                });
                this.iconListKey += 1;
            }
        },
        getTextForTooltip(fieldKey) {
            const fieldNames = [];
            let currentPlatforms = this.selectedAdPlatforms;
            if (this.activePlatform) currentPlatforms = [this.activePlatform];
            currentPlatforms.forEach(platform => {
                const field = FIELDS_NAMING_CONFIGURATION[fieldKey].platforms[platform];
                let fieldName = `${capitalizeAndSwapFB(platform)} "${field}"`;
                if (field) {
                    if (fieldNames.find(name => name.includes(field))) {
                        fieldName = `${capitalizeAndSwapFB(platform)}`;
                        fieldNames.unshift(fieldName);
                    } else {
                        fieldNames.push(fieldName);
                    }
                }
            });
            let joinedNames = fieldNames;
            let s = '';
            if (fieldNames.length > 1) {
                const lastName = fieldNames.pop();
                s = 's';
                joinedNames = fieldNames.join(', ') + ' and ' + lastName;
            }
            return `This will be used to populate the ${joinedNames} field${s}.`;
        }
    }
};
</script>

<style scoped lang="scss">
.holder {
    margin-bottom: 25px;

    &__title {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
        }
    }

    &__info {
        display: flex;
        justify-content: flex-end;
    }

    textarea {
        background: white;
        width: 100%;
        min-height: 80px;
        outline: none;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #C7CED3;
    }

    input {
        background: white;
        width: 100%;
        outline: none;
        border-radius: 5px;
        min-height: 30px;
        padding-left: 10px;
        border: 1px solid #C7CED3;
    }
}

.utmFieldText {
    font-size: 12px;
    color: #9B9B9B;
    width: 100%;
    line-break: anywhere;
    font-style: italic;
}
</style>
