<template>
    <styled-slideout
        v-model="shouldShowSafezone">
        <div
            v-for="(channel, id) in channels"
            :key="id"
            class="pa-5">
            <h3>{{ capitalizeWord(channel) }} Image safe zone</h3>
            <div class="content-container ml-5">
                <img
                    class="image-container"
                    :src="`/img/safezones/${channel}.jpg`"
                    :alt="`${channel} image safe zone`">
                <a href="https://google.com">Learn more about {{ capitalizeWord(channel) }} best practices</a>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import { capitalizeWord } from '@/helpers/capitalizeWord';
import EventBus from '@/event-bus';

export default {
    name: 'SafezoneSlideout',
    components: {
        StyledSlideout
    },
    data() {
        return {
            shouldShowSafezone: false,
            channels: ['meta', 'snapchat', 'tiktok'],
            capitalizeWord
        };
    },
    created() {
        EventBus.$on('show-safezone-slideout', () => this.shouldShowSafezone = true);
    }
};
</script>

<style scoped>
.image-container {
    width: 350px;
    height: 650px;
}
.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
