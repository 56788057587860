/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blocked': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.155 15.155c-1.423 1.433-3.142 2.148-5.155 2.148-1.548 0-2.92-.422-4.118-1.266L16.037 5.882c.844 1.197 1.266 2.57 1.266 4.118 0 2.013-.715 3.732-2.148 5.155M10 2.697c1.553 0 2.929.422 4.129 1.266L3.963 14.129c-.844-1.2-1.266-2.576-1.266-4.13 0-2.012.709-3.734 2.127-5.165l.01-.01C6.264 3.406 7.987 2.697 10 2.697m7.095.26l-.062-.063C15.091.964 12.747 0 10 0 7.244 0 4.886.975 2.925 2.925.975 4.885 0 7.244 0 10c0 2.747.965 5.091 2.894 7.033l.062.062C4.906 19.032 7.253 20 10 20c2.756 0 5.108-.979 7.054-2.936l.01-.01C19.021 15.108 20 12.756 20 10c0-2.747-.968-5.095-2.905-7.044" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
