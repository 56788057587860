// /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const email = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

export const phone = {
    US: /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/,
    GB: /^0(((1[0-9]{2}[ -]?[0-9]{3}[ -]?[0-9]{4})|(1[0-9]{3}[ -]?[0-9]{6})|(1[0-9]{4}[ -]?[0-9]{4,5}))|((1[0-9]1)|(11[0-9]))[ -]?[0-9]{3}[ -]?[0-9]{4}|(2[0-9][ -]?[0-9]{4}[ -]?[0-9]{4})|((20[ -]?[0-9]{4})|(23[ -]?[8,9][0-9]{3})|(24[ -]?7[0-9]{3})|(28[ -]?(25|28|37|71|82|90|92|95)[0-9]{2})|(29[ -]?2[0-9]))[ -]?[0-9]{4}|(7[4-9][0-9]{2}[ -]?[0-9]{6})|((3[0,3,4,7][0-9])[ -]?[0-9]{3}[ -]?[0-9]{4})|((5[5,6][ -]?[0-9]{4}[ -]?[0-9]{4})|(500[ -]?[0-9]{3}[ -]?[0-9]{4}))|(8[0247][0-9]{1}[ -]?[0-9]{3}[ -]?[0-9]{4})|(9[0-9]{2}[ -]?[0-9]{3}[ -]?[0-9]{4}))$/,
    CA: /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/,
    IE: /^((\(\d{2,3}\) ?)|(\d{3}))?\d{3} \d{3,4}$/
};

// State
export const administrativeArea = {
    US: /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$|^(?:AB|BC|MB|N[BLTSU]|ON|PE|QC|SK|YT)*$/
};

export const postal = {
    US: /^[0-9]{5}(?:-[0-9]{4})?$/,
    CA: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z](?: [0-9][ABCEGHJ-NPRSTV-Z][0-9])?$/,
    GB: /^[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s[0-9][A-Z][A-Z]$/,
    IE: /^[AC-FHKNPRTV-Y]\d[0-9W][ -]?[0-9AC-FHKNPRTV-Y]{4}$/
};

// City
export const locality = {
    US: /^[A-Za-zÀ-ÖØ-öø-ț ]*$/,
    CA: /^[A-Za-zÀ-ÖØ-öø-ț ]*$/,
    GB: /^[A-Za-zÀ-ÖØ-öø-ț ]*$/,
    IE: /^[A-Za-zÀ-ÖØ-öø-ț ]*$/
};

export const url = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

export const httpsUrl = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

export const facebookPage = /^https:\/\/(business|www)\.facebook\.com\/[\/A-Za-z0-9\-\_\.]+$/;

export const facebookListing = /^https:\/\/(?:www\.)?facebook\.com\/marketplace\/item\/(\d+)\/?/

export const domain = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

export const currency = {
    US: /^[1-9]\d*(\.\d+)?$/,
    CA: /^[1-9]\d*(\.\d+)?$/,
    GB: /^[1-9]\d*(\.\d+)?$/,
    IE: /^[1-9]\d*(\.\d+)?$/
};

export const twelveHourTime = /^(0?[1-9]|1[012])(:[0-5]\d)$/;

export const twentyFourHourTime = /^(?:([01]?\d|2[0-3]):([0-5]?\d))$/;

export const areaCode = {
    US: /^\d{3}$/,
    CA: /^\d{3}$/,
    GB: /^\d{3,6}$/,
    IE: /^\d{3,6}$/
};

export default {
    email,
    phone,
    administrativeArea,
    postal,
    locality,
    url,
    httpsUrl,
    domain,
    facebookPage,
    facebookListing,
    currency,
    twelveHourTime,
    twentyFourHourTime,
    areaCode,
};
