import Vue from 'vue';
import { Plugin } from 'vue-fragment';

import Solid from './Solid';
import Gradient from './Gradient';

Vue.use(Plugin);

export default {
    name: 'ColorPicker',

    props: {
        isGradient: {
            type: Boolean,
            default: false,
        },
        color: {
            type: Object,
            default: () => ({
                red: 255,
                green: 0,
                blue: 0,
                alpha: 1,
                hue: 0,
                saturation: 100,
                value: 100,
            })
        },
        reference: {
            type: String,
            default: ''
        },

        gradient: {
            type: Object,
            default: () => ({
                type: 'solid',
                degree: 0,
                points: [
                    {
                        left: 0,
                        red: 0,
                        green: 0,
                        blue: 0,
                        alpha: 1,
                    },
                    {
                        left: 100,
                        red: 255,
                        green: 0,
                        blue: 0,
                        alpha: 1,
                    },
                ],
            })
        },

        onStartChange: {
            type: Function,
            default: () => {}
        },
        onChange: {
            type: Function,
            default: () => {}
        },
        onEndChange: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        Solid,
        Gradient
    },
    data() {
        return {
            gradientType: 'solid',
            gradientDegree: 0,
            gradientPoints: [
                {
                    left: 0,
                    red: 0,
                    green: 0,
                    blue: 0,
                    alpha: 1,
                },
                {
                    left: 100,
                    red: 255,
                    green: 0,
                    blue: 0,
                    alpha: 1,
                },
            ],
        };
    },
    // mounted() {
    //     console.log(this.gradient);
    //     this.gradientType = this.gradient.type;
    //     this.gradientDegree = this.gradient.degree;
    //     this.gradientPoints = this.gradient.points;
    // },
    // watch: {
    //     gradient: {
    //         handler(newValue) {
    //             this.gradientType = newValue.type;
    //             this.gradientDegree = newValue.degree;
    //             this.gradientPoints = newValue.points;
    //         },
    //         deep: true,
    //     },
    // },

};
