<template>
    <svg
        viewBox="0 0 96 96"
        width="96"
        xmlns="http://www.w3.org/2000/svg">

        <g
            transform="matrix(1.528218, 0, 0, 1.481634, -285.759918, -159.76033)">
            <rect
                style="fill: none; stroke: rgb(156, 170, 177);"
                x="211.017"
                y="132.195"
                width="14.125"
                height="14.125"
                :class="[{'active' : positions.length === 4}]"
                rx="3"
                ry="3" />
            <g
                v-for="anchor in anchors"
                :key="anchor.position"
                style="cursor: pointer !important;"
                :class="['anchor', {'active' : isPositionActive(anchor.position)}]"
                width="150">

                <path
                    style="width: 100px !important;"
                    width="150"
                    height="150"
                    :d="anchor.dOne"
                    :transform="anchor.transformOne" />
                <path
                    style="width: 100px !important;"
                    width="150"
                    height="150"
                    :d="anchor.dTwo"
                    :transform="anchor.transformTwo" />
            </g>

        </g>
        <!-- Clickable Areas -->
        <rect
            x="30.609"
            y="4.077"
            width="38.071"
            height="31.303"
            style="cursor: pointer; fill: transparent; stroke-opacity: 0; fill-opacity: 0;"
            @click="handleElementClicked(localPositions.TOP)" />
        <rect
            x="64.336"
            y="32.576"
            width="38.071"
            height="31.303"
            style="cursor: pointer;stroke: rgb(0, 0, 0); stroke-opacity: 0;fill: transparent; fill-opacity: 0;"
            @click="handleElementClicked(localPositions.RIGHT)" />
        <rect
            x="31.02"
            y="63.802"
            width="38.071"
            height="31.303"
            style="cursor: pointer;stroke: rgb(0, 0, 0); stroke-opacity: 0;fill: transparent; fill-opacity: 0;"
            @click="handleElementClicked(localPositions.BOTTOM)" />
        <rect
            x="-2.98"
            y="35.802"
            width="38.071"
            height="31.303"
            style="cursor: pointer;stroke: rgb(0, 0, 0); stroke-opacity: 0;fill: transparent; fill-opacity: 0;"
            @click="handleElementClicked(localPositions.LEFT)" />
        <rect
            x="306.261"
            y="98.139"
            width="39.763"
            height="28.765"
            style="fill: none; fill-rule: nonzero;" />
    </svg>
</template>
<script>
    import { ANCHOR_POSITIONS } from '@/creative-studio/domain/layered-designer/models/Layer';

export default {
    props: {
        positions: {
            type: Array,
            required: true
        },
        width: {
            type: String,
            required: false,
            default: '80px'
        },
        height: {
            type: String,
            required: false,
            default: '80px'
        }
    },
    data() {
        return {
            anchors: [
                {
                    position: ANCHOR_POSITIONS.TOP,
                    dOne: 'M 218.51 114.933 C 218.51 118.947 218.51 122.96 218.51 126.973',
                    dTwo: 'M 208.615 114.913 C 214.969 114.913 221.323 114.913 227.678 114.913',
                    transformOne: '',
                    transformTwo: '',

                },
                {
                    position: ANCHOR_POSITIONS.BOTTOM,
                    dOne: 'M 218.469 152.025 C 218.469 168.409 218.469 162.917 218.469 164.065',
                    dTwo: 'M 209.302 164.097 C 215.656 164.097 222.01 164.097 228.365 164.097',
                    transformOne: 'matrix(-1, 0, 0, -1, 436.937988, 316.102188)',
                    transformTwo: 'matrix(-1, 0, 0, -1, 437.667007, 328.194)'

                },
                {
                    position: ANCHOR_POSITIONS.LEFT,
                    dOne: 'M 198.514 133.728 C 198.514 150.112 198.514 144.62 198.514 145.768',
                    dTwo: 'M 182.937 140.119 C 189.291 140.119 195.645 140.119 202 140.119',
                    transformOne: 'matrix(0, -1, 1, 0, 58.759918, 338.268097)',
                    transformTwo: 'matrix(0, -1, 1, 0, 52.349495, 332.587502)'
                },
                {
                    position: ANCHOR_POSITIONS.RIGHT,
                    dOne: 'M 237.675 133.688 C 237.675 150.072 237.675 144.58 237.675 145.728',
                    dTwo: 'M 234.19 139.35 C 240.544 139.35 246.898 139.35 253.253 139.35',
                    transformOne: 'matrix(0, 1, -1, 0, 377.389099, -97.960907)',
                    transformTwo: 'matrix(0, 1, -1, 0, 383.07151, -104.371498)'
                },
            ],
            localPositions: ANCHOR_POSITIONS
        };
    },
    methods: {
        handleElementClicked(position) {
            if(position === 'center') {
                return;
            }
            this.$emit('position-clicked', position);
        },
        isPositionActive(position) {
            return this.positions.includes(position);
        }
    }
};
</script>
<style lang="scss">
svg #anchor {
    cursor: pointer !important;
}

.anchor  {
    cursor: pointer !important;
    padding: 100px;
    stroke: rgb(156, 170, 177)
}

.anchor:hover {
    cursor: pointer !important;

}

.active {
    stroke: $blue-bg !important;
}

</style>