/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people': {
    width: 22,
    height: 16,
    viewBox: '0 0 22 16',
    data: '<path pid="0" _fill="#444" d="M6.339 10.281l.358.022c.94.06 1.69.847 1.726 1.779v.148l-.169 2.796a.883.883 0 01-.8.826 32.306 32.306 0 01-3.24.148c-1.116 0-2.19-.064-3.242-.148a.867.867 0 01-.788-.717l-.012-.11-.168-2.795a1.819 1.819 0 011.555-1.912l.15-.015.357-.022c.169 0 .316.043.463.127a2.969 2.969 0 001.663.509c.631 0 1.2-.19 1.684-.509.126-.105.294-.127.463-.127zm13.576 0l.358.022c.94.06 1.69.847 1.726 1.779v.148l-.169 2.796a.883.883 0 01-.8.826A32.306 32.306 0 0117.79 16c-1.116 0-2.189-.064-3.241-.148a.867.867 0 01-.788-.717l-.012-.11-.168-2.795a1.819 1.819 0 011.555-1.912l.15-.015.357-.022c.169 0 .316.043.463.127a2.969 2.969 0 001.663.509c.631 0 1.2-.19 1.684-.509.126-.105.294-.127.463-.127zM9.317 6.083a3.038 3.038 0 003.368 0 .726.726 0 01.362-.103l.1-.003.359.021c.105.022.231.043.336.064a3.169 3.169 0 00-.105.763c0 .686.222 1.303.588 1.806l.128.163-.21.021c-1.231.061-2.172 1.095-2.192 2.3l.002.157.169 2.796c.02.339.168.657.4.89-.548.02-1.095.042-1.642.042-.547 0-1.094-.021-1.642-.042.199-.2.335-.446.384-.74l.016-.15.169-2.796a2.315 2.315 0 00-2.01-2.44l-.158-.017-.21-.02c.441-.53.715-1.23.715-1.97a2.9 2.9 0 00-.105-.763c.084-.028.159-.047.237-.056l.12-.008.358-.02a.688.688 0 01.463.105zm-5.125-1.8a2.534 2.534 0 012.526 2.542 2.534 2.534 0 01-2.526 2.541 2.534 2.534 0 01-2.526-2.541 2.534 2.534 0 012.526-2.542zm13.576 0a2.534 2.534 0 012.526 2.542 2.534 2.534 0 01-2.526 2.541 2.534 2.534 0 01-2.526-2.541 2.534 2.534 0 012.526-2.542zM10.98 0a2.534 2.534 0 012.526 2.542 2.534 2.534 0 01-2.526 2.541 2.534 2.534 0 01-2.526-2.541A2.534 2.534 0 0110.98 0z"/>'
  }
})
