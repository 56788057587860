<template>
    <div class="assets-results-container">
        <div
            v-if="assets.length > 0"
            :class="[{ 'insert-mode': insertMode }, 'assets-flex-wrapper']">
            <div
                v-for="(asset, index) in assets"
                :key="`${asset.id}-${index}`"
                class="asset-item">
                <assets-result-item
                    :hide-checked-asset="hideCheckedAsset"
                    :asset="asset"
                    :is-current-asset="isCurrentAsset(asset)"
                    :is-selected="isSelectedAsset(asset)"
                    @click="handleClickedAsset(asset)"
                    @unselect-asset="handleUnselectedAsset(asset)" />
            </div>
        </div>
        <div v-if="loading">
            <loader />
        </div>
        <div
            v-if="!loading"
            v-intersect="pageBottomReached" />
    </div>
</template>

<script>
import AssetsResultItem from './AssetsResultItem';
import { AssetType } from '@/creative-studio/domain/assets/models/types';
import Loader from '@/components/globals/Loader.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AssetsResultItem,
    Loader
  },
  props: {
    assets: {
      type: Array,
      required: true,
    },
    insertMode: {
      type: Boolean,
      default: false,
    },
    selectedAssets: {
      type: Array,
      required: true,
    },
    hideCheckedAsset: {
        type: Boolean,
        default: false,
    },
    gridMode: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    }
  },
  data() {
    return {
      assetClicked: null,
      imageAssetType : AssetType.Image
    };
  },
    computed: {
        ...mapGetters([
            'assetLibraryAssetType',
            'isAssetLibraryActive'
        ]),
    },
  methods: {
    handleClickedAsset(assetSelected) {
      this.$emit('asset-clicked', assetSelected);
      this.assetClicked = assetSelected;
    },
    isSelectedAsset(currentAsset) {
      return (
          this.findAssetIndex(currentAsset) !== -1
      );
    },
    isCurrentAsset(currentAsset) {
        return currentAsset.id === this.assetClicked?.id && this.isSelectedAsset(currentAsset);
    },
    handleUnselectedAsset(currentAsset) {
        const selectedAssetIndex = this.findAssetIndex(currentAsset);
        this.$emit('unselect-asset', selectedAssetIndex);
    },
    findAssetIndex(currentAsset) {
       return this.selectedAssets.findIndex(
          (selectedAsset) => selectedAsset.id === currentAsset.id
        );
    },
    pageBottomReached() {
        this.$emit('page-bottom-reached');
    }
  },
};
</script>


<style lang="scss" scoped>
.insert-mode .photo-asset {
    cursor: pointer;
}
.assets-flex-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 20px;
    justify-content: space-between;
    padding: 15px;
}

.flex-center {
    justify-content: center;
}

.asset-grid {
    flex-grow: 1;
}

.asset-grid .image-asset-container {
    max-height: 120px !important;
}
.asset-grid .image-asset {
    max-width: 120px !important;
}
.asset-grid .asset-container {
    max-width: 100% !important;
}

.asset-grid:last-child {
  flex-grow: 10;
}
.asset-item {
    outline: 1px solid $border-list;
    height: 150px;
    width: 150px;
    background-color: $white;
}
</style>