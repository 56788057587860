<template>
    <svg
        class="canvas-shape-asset inner-canvas-layer"
        preserveAspectRatio="none"
        :viewBox="viewBox"
        :style="shapeAssetStyles"
        @mousedown="setFocusedLayer(layer)"
        v-html="layer.asset.content" />
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        props: {
            layer: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                viewBox: ''
            };
        },
        computed: {
            shapeAssetStyles() {
                const { fillColor, strokeColor, strokeWidth } = this.layer.asset;

                const layerGradientDefinition = `url(#${this.layer.id}_layer_gradient)`;

                return {
                    fill: this.layer.colorGradient.type !== 'solid' ? layerGradientDefinition : fillColor,
                    stroke: strokeColor,
                    strokeWidth,
                };
            }
        },
        created() {
            //TODO: Use the real size of the svg asset, for now defaults to the layer default sizes
            this.viewBox = '0 0 200 200';
        },
        methods: {
            ...mapActions([
                'setFocusedLayer',
            ])
        }
    };
</script>

<style lang="scss" scoped>
.canvas-shape-asset {
    min-height: 20px;
    min-width: 20px;
    height: 100%;
    width: 100%;
    overflow: visible;
}

</style>