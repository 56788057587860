/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand all': {
    width: 12,
    height: 6,
    viewBox: '0 0 12 6',
    data: '<path pid="0" d="M.343 0h11.314L6 5.657z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
