/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'best-practices': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M4.707 13.467c.093.13.158.277.193.433a2 2 0 001.973 1.577c.204 0 .406-.03.6-.09a1.13 1.13 0 01.35-.054L5.157 20l-.9-2.087L2 18.19zm10.953 0l2.716 4.723-2.256-.277-.9 2.087-2.667-4.667c.113.001.226.02.333.054.197.06.402.09.607.09a2 2 0 001.974-1.577c.034-.156.1-.303.193-.433zM10.183 0c.312 0 .61.13.82.36a2.084 2.084 0 002.154.577 1.113 1.113 0 011.42.82 2.083 2.083 0 001.576 1.577 1.113 1.113 0 01.82 1.42 2.089 2.089 0 00.577 2.153 1.113 1.113 0 010 1.64 2.093 2.093 0 00-.577 2.156 1.113 1.113 0 01-.82 1.417 2.087 2.087 0 00-1.576 1.58 1.114 1.114 0 01-1.42.817 2.083 2.083 0 00-2.154.58 1.12 1.12 0 01-1.64 0 2.084 2.084 0 00-2.153-.58 1.113 1.113 0 01-1.42-.817 2.088 2.088 0 00-1.576-1.58 1.113 1.113 0 01-.82-1.417 2.092 2.092 0 00-.577-2.156 1.113 1.113 0 010-1.64 2.09 2.09 0 00.577-2.154 1.113 1.113 0 01.82-1.42A2.083 2.083 0 005.79 1.758a1.113 1.113 0 011.42-.82A2.083 2.083 0 009.364.36c.21-.23.508-.36.82-.36zm-.004 2.27a5.457 5.457 0 10.004 10.913 5.465 5.465 0 005.454-5.456 5.457 5.457 0 00-5.458-5.457zm.004.913a4.55 4.55 0 014.547 4.544 4.547 4.547 0 11-4.547-4.544zm1.444 2.904L9.67 8.42l-1-1a.455.455 0 00-.643.643L9.39 9.427a.47.47 0 00.333.133h.02a.457.457 0 00.334-.163l2.273-2.73a.463.463 0 10-.723-.58z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
