import getRootDomain from './getRootDomain';

export const setCookie = (cookieName, cookieValue, expirationDays = 365, setRootDomain = true) => {
    let date = new Date();
    date = new Date(date.getTime() + (1000 * 60 * 60 * 24 * expirationDays));
    const expires = 'expires=' + date.toUTCString();
    let domainField = '';
    if (setRootDomain) {
        const rootDomain = getRootDomain(window.location.hostname);
        domainField = ` domain=${rootDomain};`;
    }
    document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/;${domainField}`;
};

export const getCookie = (cookieName) => {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const deleteCookie = (cookieName, setRootDomain = true) => {
    let domainField = '';
    if (setRootDomain) {
        const rootDomain = getRootDomain(window.location.hostname);
        domainField = ` domain=${rootDomain};`;
    }
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${domainField}`;
};


export default {
    getCookie,
    setCookie,
    deleteCookie
};
