/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linked': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M4.72 20a4.829 4.829 0 01-3.34-1.31c-1.84-1.742-1.84-4.579 0-6.322l1.7-1.608a1.046 1.046 0 011.423 0 .917.917 0 010 1.348l-1.698 1.608a2.473 2.473 0 000 3.627 2.77 2.77 0 001.915.752 2.77 2.77 0 001.916-.752l3.715-3.517a2.473 2.473 0 000-3.628.917.917 0 010-1.347 1.046 1.046 0 011.423 0c1.841 1.743 1.841 4.579 0 6.322L8.058 18.69A4.828 4.828 0 014.72 20zm3.218-8.571c-.258 0-.515-.093-.711-.28-1.841-1.743-1.841-4.579 0-6.322l3.715-3.517A4.828 4.828 0 0114.281 0c1.261 0 2.446.465 3.338 1.31 1.841 1.742 1.841 4.578 0 6.321L15.921 9.24a1.046 1.046 0 01-1.423 0 .917.917 0 010-1.347l1.698-1.608a2.472 2.472 0 000-3.627 2.77 2.77 0 00-1.915-.752 2.77 2.77 0 00-1.916.752L8.65 6.174a2.473 2.473 0 000 3.628.917.917 0 010 1.347 1.03 1.03 0 01-.712.28z" _fill="#00A2EA" fill-rule="evenodd"/>'
  }
})
