<template>
    <div
        v-if="hideLink"
        class="best-practices">
        <action-button
            icon="best-practices"
            :href="bestPracticesLink"
            target="_blank" />
    </div>
    <div
        v-else
        class="pt-3 best-practices reduced-width">
        <icon name="best-practices" />
        <a
            class="ml-2"
            :href="bestPracticesLink"
            target="_blank">
            <button>
                Check out our "Best Practice" for creative media
            </button>
        </a>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';

export default {
    name: 'BestPracticeIcon',
    components: {
        ActionButton,
        Icon
    },
    props: {
        hideLink: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            bestPracticesLink: 'https://support.buyerbridge.com/knowledge/best-practices-omnichannel-creative'
        };
    }
};
</script>

<style scoped>
.best-practices {
    display: flex;
    justify-content: flex-end;
    z-index: 2090;
}
.reduced-width {
    width: 95%;
}
</style>
