import regexRules from './regexRules';

export const requiredRules = [v => !!v || 'This field is required'];

export const requiredComboboxRules = [v => !!v.length || 'This field is required'];

export const booleanRules = [
    v => !!(typeof v === 'boolean') || 'You must select an option'
];

export const snapchatHeadline = [
    v =>
        !v.length ? true :
            ((v && v.length <= 34) ||
                'Headline for Snapchat must be less than 34 characters.'),
];

export const nameRules = [
    v =>
        !v.length ? true :
            (/^[a-zA-Z0-9 ]*$/.test(v) ||
                'Name can not contain non-text characters.'),
    v =>
        !v.length ? true :
            ((v && v.length <= 100) ||
                'Name must be less than 100 characters.'),
    v =>
        !v.length ? true :
            ((v && v.length >= 2) ||
                'Name must be greater than 2 characters.')
];
export const autocompleteRules = [
    v =>
        !v.length ? true :
            ((v && v.length > 2) ||
                'Enter more than 2 characters to search')
];
export const emailRules = [
    v =>
        !v.length ? true :
            ((v && v.length <= 100) ||
                'Email must be less than 100 characters.'),
    v =>
        !v.length ? true :
            ((v && v.length >= 6) ||
                'Email must be greater than 6 characters.'),
    // from emailregex.com
    v =>
        (!v.length ? true :
            regexRules.email.test(
                v
            ) || 'Email must be valid.')
];
export const multipleEmailRules = [
    v => {
        // Ignore empty strings
        if (!v.length) {
            return true;
        }

        const isValid =  v.split(',').every(email => {
            return regexRules.email.test(email.trim());
        });

        return isValid ? true : 'All emails addresses must be valid.';
    }
];
export const emailComboboxRules = [
    v => {
        if (!v.length) {
            return true;
        }

        const isValid = v.every(item => {
            if (typeof item === 'object') {
                return regexRules.email.test(item.value.email);
            }

            return regexRules.email.test(item);
        });

        return isValid ? true : 'All email addresses must be valid';
    }
];
export const phoneRules = (country) => {

    const message = {
        US: 'Supported formats: 555-555-5555, (555) 555-5555',
        GB: 'Supported formats: 020 1234 1234, 020-1234-1234, 02012341234, 01234 123456, 012345 1234',
        CA: 'Supported formats: 555-555-5555, (555) 555-5555',
        IE: 'Supported formats: (055) 555 555',
    };

    return [
        v =>
            !v.length ? true :
                (regexRules.phone[country].test(v) ||
                    message[country])
    ];
};
export const dealershipNameRules = [
    v =>
        !v.length ? true :
            ((v && v.length <= 200) ||
                'Name must be less than 200 characters.'),
    v =>
        !v.length ? true :
            ((v && v.length >= 6) ||
                'Name must be greater than 6 characters.')
];
export const dealershipAddressRules = [
    v =>
        !v.length ? true :
            ((v && v.length <= 50) ||
                'Address must be less than 50 characters.'),
    v =>
        !v.length ? true :
            ((v && v.length >= 6) ||
                'Address must be greater than 6 characters.')
];
export const localityRules = (country) => {
    const rules = [
        v =>
            !v.length ? true :
                ((v && v.length >= 2) ||
                    'City must be greater than 2 characters.')
    ];

    // Don't require locality in GB
    if (country !== 'GB') {
        rules.push(v => !!v || 'This field is required');
    }

    return rules;
};
export const administrativeAreaRules = (country) => [
    v =>
        !v.length ? true :
            ((v && v.length <= 2) || 'State must be valid.'),
    v =>
        !v.length ? true :
            (regexRules.administrativeArea[country].test(
                v
                ) ||
                'State field must be a valid abbreviation for a US state or Canadian province.')
];
export const postalRules = (country) => {
    const message = {
        US: 'Zip code must be in the following format: 55555',
        GB: 'Postal code must be in the following format(s): W1H 7EJ, W9 1JA',
        CA: 'Postal code must be in the following format: A1A 1A1',
        IE: 'Postal code must be in the following format: D01 F2X9',
    };
    return [
        v =>
            !v.length ? true :
                (regexRules.postal[country].test(v) || message[country])
    ];
};
export const areaCodeRules = (country) => [
    v =>
        !v.length ? true :
            (regexRules.areaCode[country].test(v) ||
                'Area code can not contain non numbers.'),
    v =>
        !v.length ? true :
            ((v && v.length == 3) || 'Area code must be 3 characters.')
];
export const timeRules = (country) => {

    if (['US', 'UK', 'PH', 'CA', 'AU', 'NZ', 'IN', 'EG', 'SA', 'CO', 'PK', 'MY'].includes(country)) {
        return [
            v =>
                !v.length ? true :
                    (regexRules.twelveHourTime.test(
                        v
                    ) || 'Must be 12 hour format as hh:mm')
        ]
    }


    return [
        v =>
            !v.length ? true :
                (regexRules.twentyFourHourTime.test(
                    v
                ) || 'Must be 24 hour format as hh:mm')
    ]
};

export const urlRules = [
    v =>
        !v || !v.length ? true :
            (regexRules.url.test(
                v
            ) || 'URL must in the following format: https://www.example.com')
];
export const httpsRules = [
    v =>
        !v || !v.length ? true :
            (regexRules.httpsUrl.test(
                v
            ) || 'The URL must be secure and begin with "https://"')
];
export const domainRules = [
    v =>
        !v.length ? true :
            (regexRules.domain.test(
                v
            ) || 'Domain must in the following format: example.com or www.example.com')
];
export const facebookUrlRules = [
    v =>
        !v.length ? true :
            (regexRules.facebookPage.test(v) || 'URL must in the following format: https://www.facebook.com/page-name-here/')
];

export const facebookListingUrlRules = [
    v =>
        !v.length ? true :
            (regexRules.facebookListing.test(v) || 'URL must in the following format: https://www.facebook.com/marketplace/item/123456789/')
];

export const currencyRules = (country) => [
    v => !v.length ? true :
        (regexRules.currency[country].test(v) || 'Number must be valid and can contain two decimals (55 or 55.55)')
];
export const percentageRules = [
    v => !v.length ? true :
        (/^\d{1,3}$/.test(v) || 'Percentages must be 1-3 integers (e.g. 6, 20 or 100).')
];
export const adBudgetRules = [
    v => !v.length ? true :
        (v >= 5 || 'Ad budgets must be $5/day or greater')
];
export const campaignMinimumBudget = (minimumBudget) => [
    v => !v.length ? true :
        (((v / 30) >= minimumBudget) || `Must be $${minimumBudget * 30} or greater`)
];
export const campaignMinimumDailyBudget = (minimumBudget) => [
    v => !(v || isFinite(v)) ? true :
        ((v >= minimumBudget) || `Must be $${minimumBudget} or greater`)
];
export const radioRules = [v => !!v || 'You must select an option to continue'];

export const hecRadiusRules = [v => v >= 15 || 'Must be 15 or greater', v => v <= 50 || 'Must be 50 or less'];

export const passwordRules = [
    v => !v.length ? true :
        ((v.length >= 8) || 'Password must be 8 or more characters')
];
export const vinRules = [
    v => !v.length ? true :
        (v.length == 17 || 'VINs must be exactly 17 characters')
];

export default {
    requiredRules,
    requiredComboboxRules,
    snapchatHeadline,
    nameRules,
    emailRules,
    emailComboboxRules,
    phoneRules,
    dealershipNameRules,
    dealershipAddressRules,
    localityRules,
    administrativeAreaRules,
    postalRules,
    areaCodeRules,
    urlRules,
    httpsRules,
    domainRules,
    facebookUrlRules,
    facebookListingUrlRules,
    currencyRules,
    adBudgetRules,
    radioRules,
    campaignMinimumBudget,
    campaignMinimumDailyBudget,
    hecRadiusRules,
    booleanRules,
    autocompleteRules,
    passwordRules,
    vinRules,
    timeRules
};
