/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'align': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M5.314 15.79l-1.3-1.3a.71.71 0 111.006-1.006l2.513 2.513a.731.731 0 010 1.006L5.02 19.516a.71.71 0 01-1.005-1.006l1.299-1.299H.568a.71.71 0 110-1.422h4.746zM14.986.477a.712.712 0 111.007 1.007l-1.302 1.302 4.755-.001a.72.72 0 01.711.712.712.712 0 01-.712.712l-4.755.001 1.302 1.302a.712.712 0 01-1.007 1.007l-2.517-2.517a.734.734 0 010-1.007L14.986.478z"/><path pid="1" d="M11 1v18a1 1 0 01-2 0V1a1 1 0 112 0z" fill-rule="nonzero"/></g>'
  }
})
