/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-permission': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M9.622 12.007A5.968 5.968 0 008.5 15.5c0 1.793.786 3.401 2.032 4.5H0v-2.667c0-3.417 6.185-5.2 9.622-5.326zm10.38 5.892L20 20h-1.532A6.016 6.016 0 0020 17.9zM14.5 11a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm.551 2H13.95v1.962H12v1.101h1.949V18h1.102v-1.937H17v-1.101h-1.949V13zM10 0c2.762 0 5 2.237 5 5s-2.238 5-5 5c-2.763 0-5-2.237-5-5s2.237-5 5-5z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
