<template>
    <button
        :class="{
            'outline': outline,
            'gray': gray,
            'green': green,
            'red': red,
            'small': small,
            'full-width': fullWidth,
            'light': light,
            'text': text
        }"
        :disabled="disabled"
        @click="onClick">
        <div
            v-if="loading"
            class="button-loader">
            <styled-loader
                v-model="loading"
                :size="currentLoaderSize"
                :light="!(outline || text)" />
        </div>
        <div>
            <slot />
        </div>
    </button>
</template>

<script>
import StyledLoader from './StyledLoader';

export default {
    components: {
        StyledLoader
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        outline: {
            type: Boolean,
            default: false
        },
        gray: {
            type: Boolean,
            default: false
        },
        green: {
            type: Boolean,
            default: false
        },
        red: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        light: {
            type: Boolean,
            default: false
        },
        loaderSize: {
            type: Number,
            default: null
        },
        href: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: '_self'
        },
        to: {
            type: Object,
            default: null
        },
        text: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currentLoaderSize() {
            if (this.text) {
                return 12;
            }

            if (this.loaderSize) {
                return this.loaderSize;
            }

            return this.small ? 15 : 25;
        }
    },
    methods: {
        onClick(e) {
            if (this.disabled || this.loading) {
                return;
            }

            // Allow for routing
            if (this.to) {
                this.$router.push(this.to);
            }

            // Mimic href behavior for dev UX
            if (this.href && this.target === '_blank') {
                window.open(this.href);
            } else if (this.href) {
                document.href = this.href;
            }

            // Always emit the click event
            this.$emit('click', e);
        }
    }
};
</script>

<style lang="scss" scoped>

button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-bg;
    border: 1px solid $blue-bg;
    color: $white;
    border-radius: 5px;
    padding: 0 1.5rem;
    height: 50px;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, width 0.3s ease-in-out;
    &:hover {
        background-color: darken($blue-bg, 15%);
    }
    &:focus,
    &:active {
        outline: none;
    }
    &.green {
        background: linear-gradient(to right, #80D423, #ABEA01);
        border: none;
        color: $white;
        font-weight: 600;
        &:hover {
            opacity: 0.8;
        }
    }
    &.light {
        background-color: $white;
        color: $blue-bg;
        &.outline {
            background-color: transparent;
            border-color: $white;
            color: $white;
            &:hover {
                background-color: rgba($white, 0.2);
            }
        }
    }
    &.outline {
        background-color: transparent;
        color: $blue-bg;
        &:hover {
            background-color: rgba($blue-bg, 0.2);
        }
    }
    &.gray {
        border-color: $gray;
        background-color: rgba($gray, 0.05);
        color: $gray-dark;
        &:hover {
            background-color: rgba($gray, 0.2);
        }
        &:disabled {
            background-color: rgba($gray, 0.05);
        }
    }
    &.full-width {
        width: 100%;
    }
    &.small {
        height: 30px;
    }
    &:disabled {
        background-color: $gray;
        border-color: $gray;
        opacity: 0.5;
        &:hover {
            cursor: default;
        }
    }
    &:disabled.outline {
        background-color: transparent;
        color: $gray;
    }
    &.text {
        display: inline-flex;
        align-items: center;
        background: none;
        color: $blue-bg;
        border-radius: 0;
        padding: 0;
        height: auto;
        border: none;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .button-loader {
        margin-right: 8px;
        margin-top: -1px;
        display: inline-flex;
    }
}

</style>
