/**
 * Retrieves a simple YYYY-MM-DD format
 * from a date string
 *
 * @param {Date} date
 * @returns {String}
 */
export const getSimpleDate = (date) => {
    return new Date(date).toISOString().split('T')[0];
}

export default {
    getSimpleDate
}