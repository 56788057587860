<template>
    <div class="catalog-link-group">
        <p class="section-title">
            Catalog(s)
        </p>
        <asset-alerts :monitor-id="5" />
        <div
            v-for="annotation in currentDealer.facebook_product_catalog_annotations.data"
            :key="annotation.id">
            <catalog-link
                :refresh="refresh"
                :dealer="currentDealer"
                :annotation="annotation" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import CatalogLink from '@/components/dealers/DealerID/QuickLinks/CatalogLink';
import AssetAlerts from './AssetAlerts';
export default {
    components: {
        CatalogLink,
        AssetAlerts
    },
    data() {
        return {
            refresh: 0
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        })
    }
};
</script>

<style lang="scss" scoped>
.section-title {
    margin-top: 30px;
    font-size: 16px;
    background: #f3f5f6;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
</style>