/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'snapchat-camera': {
    width: 78,
    height: 72,
    viewBox: '0 0 78 72',
    data: '<path pid="0" _fill="#DFDFDF" fill-rule="evenodd" d="M46.648 0a4 4 0 013.05 1.412L55.712 8.5H65c6.627 0 12 5.373 12 12v14.428a5.985 5.985 0 00-4.828.817l-11 7.333-.214.15a6 6 0 00-2.452 4.582L58.5 48h-3.87l-.008-.045a6 6 0 00-2.32-3.695A17.43 17.43 0 0056 33.5C56 23.835 48.165 16 38.5 16S21 23.835 21 33.5c0 9.022 6.827 16.449 15.596 17.398l-3.985 3.09a7 7 0 00.009 11.07l1.865 1.442H12c-6.627 0-12-5.373-12-12v-34c0-6.627 5.373-12 12-12h8.772l6.538-7.19A4 4 0 0130.27 0h16.378zM38.5 21C45.404 21 51 26.596 51 33.5S45.404 46 38.5 46 26 40.404 26 33.5 31.596 21 38.5 21zm12.73 46v2.926a2 2 0 01-3.224 1.583l-12.44-9.616a3 3 0 01-.004-4.744L48.004 47.5a2 2 0 013.225 1.58V52H63L63 48.07a2 2 0 01.89-1.664l11-7.333A2 2 0 0178 40.737V59.5a7.5 7.5 0 01-7.5 7.5H51.23z"/>'
  }
})
