/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 26,
    height: 37,
    viewBox: '0 0 92.126 131.102',
    data: '<path pid="0" d="M64.93 94.243L48.388 77.73l-2.853 1.258C29.193 86.195 9.963 76.684 5.509 59.193c-.933-3.661-1.03-9.562-.224-13.48.314-1.527 1.386-4.45 2.38-6.495 8.768-18.022 32.802-22.005 47.015-7.792 5.768 5.768 8.412 12.267 8.412 20.676 0 5.598-.692 8.731-2.9 13.132l-.94 1.873 16.24 16.196c8.932 8.907 16.456 16.739 16.72 17.403.674 1.692-.343 3.424-4.136 7.04-5.208 4.968-3.845 5.763-23.144-13.502zM39.657 70.687c7.737-2.297 13.76-10.433 13.76-18.586 0-5.98-3.536-12.609-8.572-16.063-9.508-6.523-23.094-2.915-28.388 7.539-1.404 2.773-1.532 3.484-1.532 8.524 0 5.004.131 5.749 1.459 8.268 4.68 8.884 14.076 13.05 23.273 10.318z" _fill="#a7aaad" fill-opacity=".945"/>'
  }
})
