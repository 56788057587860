/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ad': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.086 0c1.11 0 2.01.9 2.01 2.01v16.076c0 1.11-.9 2.01-2.01 2.01H2.01c-1.11 0-2.01-.9-2.01-2.01V2.01C0 .9.9 0 2.01 0h16.076zm0 2.01H2.01v11.253h16.076V2.01z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
