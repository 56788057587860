/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'curve-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M114.58 5.055c-7.89 17.304-21.247 13.233-21.374 13.233v-5.726l-7.699 9.225 7.762 7.76V23.76s18.511 4.326 21.311-18.704z" transform="matrix(1.6768 0 0 1.6331 -137.75 1.744)" stroke-linejoin="round" _stroke="#fff" stroke-linecap="round" stroke-width="6.043" _fill="none"/><path pid="1" d="M114.58 5.055c-7.89 17.304-21.247 13.233-21.374 13.233v-5.726l-7.699 9.225 7.762 7.76V23.76s18.511 4.326 21.311-18.704z" transform="matrix(1.6768 0 0 1.6331 -137.75 1.744)" stroke-linejoin="round" fill-rule="evenodd" _stroke="#8f9ea6" stroke-linecap="round" stroke-width="3.022" _fill="#8f9ea6"/>'
  }
})
