import { BILLING_EVENTS, OPTIMIZATION_GOALS } from '../store/constants';

const getPlatformOptimizationDefaults = (platform, objective) => {

    const defaultGoal = OPTIMIZATION_GOALS[platform][objective][0];
    const defaultEvent = BILLING_EVENTS[platform][defaultGoal.platform_value][0];

    return {
        objective,
        optimization_goal: defaultGoal,
        billing_event: defaultEvent,
    };
};

export default getPlatformOptimizationDefaults;