<template>
    <div class="layered-designer-container">
        <div class="layered-designer-gradient" />
        <layered-designer-side-controls />
        <layered-designer-header class="left-sidebar-spacing-padding" />
        <layered-designer-workspace class="left-sidebar-spacing-padding" />
        <layered-designer-footer class="left-sidebar-spacing-padding" />
        <styled-dialog
            :value="isLoading">
            <loader label="Generating Static Assets" />
        </styled-dialog>
        <div class="hide">
            <div id="font-picker" />
            <div class="apply-font" />
        </div>
    </div>
</template>

<script>
    import LayeredDesignerSideControls from './layered-designer-side-controls/LayeredDesignerSideControls';
    import LayeredDesignerHeader from './layered-designer-header/LayeredDesignerHeader';
    import LayeredDesignerWorkspace from './layered-designer-workspace/LayeredDesignerWorkspace';
    import LayeredDesignerFooter from './layered-designer-footer/LayeredDesignerFooter';
    import Loader from '@/components/globals/Loader';
    import StyledDialog from '@/components/globals/StyledDialog';
    import { checkIsMutationAccepted, manageCardPreview } from '@/components/creative-studio/layered-designer/utils/card-preview-generation';
    import { CreativeStudioFontManager } from '@/components/creative-studio/layered-designer/utils/font-manager';
    import { downloadFontsCss } from '@/components/creative-studio/layered-designer/utils/static-assets-generation';
    import { CREATIVE_STUDIO_FONTS } from '@/components/creative-studio/common/fonts';
    import { KEYBOARD, MUTATIONS_TO_TRIGGER_HISTORY, } from '../common/constants';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import undoRedoHistory from '@/components/creative-studio/layered-designer/utils/undo-redo-history';
    import { debounce } from 'lodash';
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        components: {
            LayeredDesignerSideControls,
            LayeredDesignerWorkspace,
            LayeredDesignerHeader,
            LayeredDesignerFooter,
            Loader,
            StyledDialog
        },
        data() {
            return {
                vuexModuleSubscription: null,
                initialFontLoadFinished: false
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.user.user,
            }),
            ...mapGetters([
                'isLoading',
                'downloadedFonts',
                'focusedLayer'
            ])
        },
        async mounted() {

            // Initialize layered designer state history
            undoRedoHistory.init(this.$store.state.layeredDesigner);

            const subscribeFn = (mutation, state) => {
                if (checkIsMutationAccepted(mutation)) {
                    return manageCardPreviewDebounced(mutation, state, this.setCurrentCardThumbnail);
                }
            };

            let date = new Date();
            let options = { year: 'numeric', month: 'short', day: 'numeric' };
            let formattedDate = date.toLocaleDateString('en-US', options);

            this.setDefaultCurrentCard(`${this.user.name}'s Design - ${formattedDate}`);


            const subscribeHistory = (mutation, state) => {
                undoRedoHistory.setStore(this.$store);
                let mutationType = mutation.type;
                let currentMutation = mutationType.substr(mutationType.lastIndexOf('/') + 1);
                if(MUTATIONS_TO_TRIGGER_HISTORY.includes(currentMutation)) {
                    this.handleAddToHistory(cloneDeep(state.layeredDesigner), currentMutation);
                }
            };

            this.$store.subscribe(subscribeHistory);

            if (process.env.VUE_APP_CREATIVE_STUDIO_ENABLE_CARD_PREVIEW_GENERATION) {
                this.vuexModuleSubscription = this.$store.subscribe(subscribeFn);
            } else {
                console.log('Card preview generation is disabled.');
            }

            const fontManager = await CreativeStudioFontManager.initFontManager();

            this.setLayeredDesignerFontManager(fontManager);


            fontManager.preloadFonts();

            document.fonts.addEventListener('loadingdone', this.handleFontLoading);
            document.addEventListener('keyup', this.deleteLayerOnKeyUp);

        },
        beforeDestroy() {
            if (this.vuexModuleSubscription !== null) {
                this.vuexModuleSubscription();
            }
            document.fonts.removeEventListener('loadingdone', this.handleFontLoading);
            document.removeEventListener('keyup', this.deleteLayerOnKeyUp);
        },
        methods: {
            ...mapActions([
                'setCurrentCardThumbnail',
                'setDefaultCurrentCard',
                'setLayeredDesignerEmbededFonts',
                'setLayeredDesignerFontManager',
                'addDownloadedFont',
                'deleteLayer'
            ]),
            deleteLayerOnKeyUp(event) {

                const tagNamesToIgnore = ['INPUT', 'TEXTAREA'];
                const eventKeysToHandle = [KEYBOARD.DELETE, KEYBOARD.BACKSPACE];
                // Ignore if content is editable or is an input-textarea
                const hasToIgnoreElement = tagNamesToIgnore.includes(event.target.tagName) || event.target.isContentEditable;
                const hasToHandleEventKey = eventKeysToHandle.includes(event.key);
                const isValidEvent = !hasToIgnoreElement && hasToHandleEventKey;

                if (!isValidEvent) {
                    return;
                }

                if (!this.focusedLayer) {
                    return;
                }

                this.deleteLayer(this.focusedLayer);

            },
            async updateCssFonts(event) {
                const fontFamily = event?.family;
                if (this.downloadedFonts.includes(fontFamily)) {
                    return;
                }
                const fontsCss = await downloadFontsCss();
                this.setLayeredDesignerEmbededFonts(fontsCss);
                if (fontFamily) {
                    this.addDownloadedFont(fontFamily);
                }
            },
            handleFontLoading() {
                const fontsLoaded = Array.from(document.querySelectorAll('style[id*=\'font-\'][data-is-preview=\'false\']'))
                    .filter(element =>{
                        // Filter only the fonts where all the latin scripts had been downloaded
                        return element.innerHTML !== '' && element.innerHTML.includes('latin');
                    });
                    // Make sure all the creative studio fonts are loaded
                if (fontsLoaded.length >= CREATIVE_STUDIO_FONTS.length && !this.initialFontLoadFinished) {
                    CREATIVE_STUDIO_FONTS.forEach(({ family }) => {
                        this.addDownloadedFont(family);
                    });
                    this.initialFontLoadFinished= true;
                    this.updateCssFonts();
                }
            },
            handleAddToHistory: debounce((state, mutation) => {
                undoRedoHistory.addState(state, mutation);
            }, 200)
        }
    };

    export const manageCardPreviewDebounced = debounce((mutation, state, setCurrentCardThumbnail) => {
        manageCardPreview(mutation, state, setCurrentCardThumbnail);
    }, 1000);
</script>

<style lang="scss" scoped>
.left-sidebar-spacing-padding {
    padding-left: 350px;
}

.layered-designer-container {
    max-height: 100%;
}

.hide {
    display: none;
}
.layered-designer-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    z-index: 1;
    background: linear-gradient(to right,#1F6886 0%, #1F6886 15%, #3A4044 35%, #3A4044 100%);
}
</style>