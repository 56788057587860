/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vertical': {
    width: 30,
    height: 19,
    viewBox: '0 0 30 19',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M6 0h19v7a1 1 0 01-1 1H7a1 1 0 01-1-1V0zm1 11h13.294a1 1 0 01.615.21l3.705 2.884a1 1 0 01.386.79V19H6v-7a1 1 0 011-1z" _fill="#8F9EA6"/><path pid="1" _fill="#FFF" d="M18.183 1l.817.773-3.5 3.31-3.5-3.31.817-.773L15.5 3.538zM18.183 13l.817.773-3.5 3.31-3.5-3.31.817-.773 2.683 2.538z"/></g>'
  }
})
