<template>
    <v-app
        id="inspire"
        name="inspire"
        :style="{
            '--color-primary' : brand.theme.primary_color
        }"
        :class="{
            'app-wrapper': true,
            'sidebar-menu-open': menuOpen,
            'sidebar-menu-overlay': sidebarMenuOverlay,
            'interface-type-minimal': interfaceType === INTERFACE_MINIMAL,
            'interface-type-blank': interfaceType === INTERFACE_BLANK

        }">
        <branded-loader-dialog
            :active="isInitializing"
            :message="`Initializing the ${$brand.name} app`" />
        <beta-disclaimer-dialog
            v-model="showBetaDisclaimerDialog"
            @close="onBetaDisclaimerApproval" />
        <create-support-ticket-dialog v-if="!isInitializing && user" />
        <channel-connection-debug-panel v-if="!isInitializing && user" />

        <navbar
            v-if="!isInitializing && user && !userHasRoles(['pre_onboarding', 'onboarding']) && interfaceType === INTERFACE_FULL"
            v-model="menuOpen"
            @toggle="onUserToggle" />


        <transition name="fade">
            <div
                v-if="showSidebarOverlay"
                ref="sidebarOverlay"
                class="sidebar-overlay" />
        </transition>
        <nav
            v-if="user && interfaceType === INTERFACE_FULL"
            class="primary-toolbar">
            <div class="primary-toolbar-inner">
                <div class="logo">
                    <img
                        ref="agencyLogo"
                        alt="Agency logo"
                        :style="{
                            maxWidth: '200px',
                            maxHeight: '60px'
                        }"
                        :src="brand.asset_urls.navbar_logo">
                </div>
                <div class="pickers">
                    <agency-picker-global
                        v-if="userIsAdmin && !!currentAgency"
                        class="top-bar-variant" />
                    <dealer-picker-global class="top-bar-variant" />
                </div>
                <div
                    v-if="user"
                    class="top-bar-actions">
                    <div
                        v-if="userHasAdDeploymentAccess"
                        class="pr-3">
                        <v-menu
                            v-if="!isInitializing"
                            v-model="createAdMenuOpen"
                            transition="slide-y-transition"
                            bottom
                            left
                            nudge-right="30"
                            nudge-bottom="-10"
                            offset-y
                            allow-overflow
                            :nudge-width="60"
                            fixed
                            :close-on-content-click="false">
                            <template #activator="{ on }">
                                <action-button
                                    class="has-arrow close-arrow"
                                    icon="plus"
                                    color="#7ED322"
                                    v-on="on">
                                    CREATE NEW
                                </action-button>
                            </template>
                            <create-new-dropdown :relative-position="true" />
                        </v-menu>
                    </div>
                    <div
                        v-if="!userIsClient"
                        class="pr-4">
                        <action-button
                            icon="channels"
                            @click="channelSettingsSlideoutOpen = !channelSettingsSlideoutOpen">
                            Channel Settings
                        </action-button>
                        <channel-settings-slideout
                            v-model="channelSettingsSlideoutOpen"
                            @showAlerts="opportunitySlideoutOpen = true" />
                    </div>
                    <div
                        v-if="!userIsClient"
                        class="pr-4 mr-2">
                        <action-button
                            icon="alert"
                            @click="opportunitySlideoutOpen = true">
                            Alerts
                        </action-button>
                        <opportunity-slideout
                            v-if="!isInitializing"
                            v-model="opportunitySlideoutOpen" />
                    </div>
                    <div
                        v-if="!userIsClient"
                        class="pr-4 mr-2">
                        <v-menu
                            v-if="!isInitializing && (canUserOverridesSupportButtonConfiguration || !organizationHasSupportDisabled)"
                            v-model="supportMenuOpen"
                            transition="slide-y-transition"
                            bottom
                            left
                            nudge-right="16"
                            nudge-bottom="-13"
                            offset-y
                            allow-overflow
                            :nudge-width="60"
                            fixed
                            :close-on-content-click="false">
                            <template #activator="{ on }">
                                <action-button
                                    class="has-arrow"
                                    icon="life-buoy"
                                    v-on="on">
                                    Support
                                </action-button>
                            </template>
                            <div class="triangle triangle-header">
                                -
                            </div>
                            <support-options-dropdown
                                @slideout-opened="supportMenuOpen = false" />
                        </v-menu>
                    </div>
                    <div
                        class="nav-username pr-1">
                        {{ user ? user.name : null }}
                    </div>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        left
                        nudge-right="16"
                        nudge-bottom="-10"
                        offset-y
                        allow-overflow
                        :nudge-width="60"
                        fixed>
                        <v-btn
                            slot="activator"
                            icon
                            large>
                            <v-avatar
                                size="45px">
                                <img
                                    :src="profilePhoto"
                                    alt="Avatar">
                            </v-avatar>
                        </v-btn>
                        <div class="triangle triangle-header">
                            -
                        </div>
                        <v-list class="dropdown-list">
                            <v-list-tile
                                v-for="(item, i) in userItems"
                                :key="i"
                                router
                                :to="item.to"
                                @click="item.title ==='Log Out'? logout() : null">
                                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                            </v-list-tile>
                        </v-list>
                        <div />
                    </v-menu>
                </div>
            </div>
        </nav>
        <v-toolbar
            v-if="!user && $router.currentRoute.path !== '/add-payment-method' && interfaceType === INTERFACE_FULL"
            app
            fixed
            clipped-left
            height="110"
            class="white-text"
            color="du-black">
            <v-layout
                justify-center
                align-center>
                <img
                    ref="agencyLogo"
                    alt="Agency logo"
                    :style="{
                        maxWidth: '280px',
                        maxHeight: '75px'
                    }"
                    :src="brand.asset_urls.navbar_logo">
            </v-layout>
        </v-toolbar>
        <main :class="'primary-container'">
            <!-- Prevent flashing if the route has features tied to it -->
            <div
                v-if="routeHasFeatures && newDealerIsLoading"
                class="text-xs-center ma-5">
                <loader label="Initializing new account..." />
            </div>
            <upgrade-product-callout
                v-else-if="(
                    !isInitializing &&
                    routeHasFeatures &&
                    !newDealerIsLoading &&
                    dealerNeedsUpgrade
                )" />
            <!--
                Keep the router mounted while changing between upgrade
                callouts but hide it if the route has features that need
                to be checked once the dealer is loaded.
            -->
            <div
                class="container fluid"
                :class="{
                    'hide-container': !showCurrentRoute
                }">
                <keep-alive
                    v-if="isLoggedIn"
                    :max="5"
                    :exclude="keepAliveExclusions">
                    <router-view
                        v-if="!isInitializing"
                        :key="$route.path" />
                </keep-alive>
                <router-view
                    v-else-if="!isInitializing"
                    :key="$route.path" />
            </div>
        </main>
        <v-footer
            v-show="$router.currentRoute.path !== '/add-payment-method'"
            class="primary-footer"
            app
            fixed
            height="30">
            <v-layout
                row
                align-center>
                <v-flex
                    class="text-xs-left px-2 subheading"
                    style="color: #909ea6">
                    <div class="footer-info">
                        <div>
                            &copy; {{ new Date().getFullYear() }} {{ $brand.name }}
                        </div>
                        <div class="footer-links">
                            <a
                                class="footer-link"
                                title="Privacy Policy"
                                :href="privacyPolicy"
                                target="_blank">Privacy Policy</a>
                            |
                            <a
                                class="footer-link"
                                title="Our Terms"
                                :href="terms"
                                target="_blank">Our Terms</a>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
        </v-footer>
        <v-snackbar
            v-model="show"
            :timeout="timeout"
            :top="true"
            :right="true"
            :color="color">
            <strong>{{ body }}</strong>
        </v-snackbar>

        <!-- ad studio -->
        <transition name="fade">
            <ad-studio
                v-if="adStudio"
                :selected-assets="selectedAssets"
                :open-from-editing="adStudioInEditMode"
                :is-opened-from-layered-designer="openAdStudioFromLayeredDesigner"
                @close="closeAdStudio" />
        </transition>
        <ad-studio-info-panel />
        <!-- ad studio END -->

        <!-- ad deployment DeploymentModal -->
        <transition
            name="fade">
            <deployment-modal
                v-if="showDeploymentModal"
                :configuration="deploymentConfiguration"
                @close="closeAdDeploymentModal" />
        </transition>

        <!-- layered designer -->
        <layered-designer-container
            :is-open="showLayeredDesigner" />
        <div
            class="layered-designer-bg"
            :class="{ active: showLayeredDesigner }" />
        <!-- layered designer END -->
    </v-app>
</template>

<script>
import InitMetricsMixin from '../mixins/init-metrics';
import InitMixin from '../mixins/init';
import ActionButton from './globals/ActionButton';
import Loader from './globals/Loader';
import UpgradeProductCallout from './globals/UpgradeProductCallout';
import AgencyPickerGlobal from './globals/AgencyPickerGlobal';
import DealerPickerGlobal from './globals/DealerPickerGlobal';
import OpportunitySlideout from './globals/OpportunitySlideout';
import BrandedLoaderDialog from './globals/BrandedLoaderDialog';
import BetaDisclaimerDialog from './globals/BetaDisclaimerDialog';
import CreateSupportTicketDialog from './globals/CreateSupportTicketDialog';
import SupportOptionsDropdown from '@/components/globals/SupportOptionsDropdown';
import ChannelSettingsSlideout from '@/components/globals/ChannelSettingsSlideout';
import CreateNewDropdown from '@/components/globals/CreateNewDropdown';
import ChannelConnectionDebugPanel from '@/components/globals/ChannelConnectionDebugPanel';
import Navbar from './nav/Nav';
import debounce from 'debounce';
import { mapState, mapGetters, mapActions } from 'vuex';
import {
    INTERFACE_MINIMAL,
    INTERFACE_FULL,
    INTERFACE_BLANK,
} from '@/helpers/globals';
import {
    SET_GLOBAL_MENU,
    TOGGLE_OPPORTUNITY_SLIDEOUT,
    TOGGLE_CHANNEL_SETTINGS_SLIDEOUT
} from '@/store/mutation-types';
import EventBus from '../event-bus';
import orgConfiguration from '@/helpers/organizations/configuration';
import AdStudio from '@/components/ad-studio/AdStudio';
import LayeredDesignerContainer from '@/components/creative-studio/layered-designer/LayeredDesignerContainer';
import AdStudioInfoPanel from '@/components/ad-studio/AdStudioInfoPanel';
const BETA_ROUTES_STORAGE = 'approvedBetaRoutes';
import DeploymentModal from '@/components/ad-deployment/components/DeploymentModal/index.vue';

export default {
    name: 'Layout',
    components: {
        Loader,
        AdStudioInfoPanel,
        ActionButton,
        AgencyPickerGlobal,
        DealerPickerGlobal,
        BetaDisclaimerDialog,
        BrandedLoaderDialog,
        Navbar,
        OpportunitySlideout,
        CreateSupportTicketDialog,
        SupportOptionsDropdown,
        ChannelSettingsSlideout,
        UpgradeProductCallout,
        CreateNewDropdown,
        AdStudio,
        LayeredDesignerContainer,
        ChannelConnectionDebugPanel,
        DeploymentModal,
    },
    mixins: [InitMixin, InitMetricsMixin],
    data: () => ({
        INTERFACE_FULL,
        INTERFACE_MINIMAL,
        INTERFACE_BLANK,
        menuOpen: true,
        supportMenuOpen: false,
        approvedBetaRoutes: [],
        sidebarMenuOverlay: false,
        userMenuOverride: false,
        body: '',
        color: 'success',
        show: false,
        timeout: 5000,
        notification_model: false,
        logoUrl: null,
        laptopBreakPoint: 1700,
        mobileBreakPoint: 768,
        showOpportunitySlideout: false,
        showBetaDisclaimerDialog: false,
        keepAliveExclusions: [
            'AdDeployment',
            'Onboarding',
            'PlaybookManagement',
            'ChannelSelection',
            'ProductSelection'
        ],
        quickLinksShow: false,
        showChannelSettings: false,
        showUpgrade: false,
        buyerbridgePrivacyPolicy: 'https://buyerbridge.com/privacy-policy/platform',
        buyerbridgeTerms: 'https://buyerbridge.com/our-terms/',
        orgConfiguration,
        createAdMenuOpen: false,
        adStudio: false,
        showLayeredDesigner: false,
        adStudioInEditMode: false,
        openAdStudioFromLayeredDesigner: false,
        selectedAssets: [],
        showDeploymentModal: false,
        deploymentConfiguration: {},
    }),
    computed: {
        ...mapState({
            brand: (state) => state.brand,
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
            isLoggedIn: (state) => state.user.isLoggedIn,
        }),
        ...mapGetters([
            'isInitializing',
            'userHasRoles',
            'userIsAdmin',
            'userIsClient',
            'newDealerIsLoading',
            'dealerHasFeatures',
            'userHasAdDeploymentAccess',
            'isLayeredDesignerActive'
        ]),
        opportunitySlideoutOpen: {
            set(value) {
                this.$store.commit(TOGGLE_OPPORTUNITY_SLIDEOUT, value);
            },
            get() {
                return this.$store.state.settings.opportunitySlideoutOpen;
            }
        },
        channelSettingsSlideoutOpen: {
            set(value) {
                this.$store.commit(TOGGLE_CHANNEL_SETTINGS_SLIDEOUT, value);
            },
            get() {
                return this.$store.state.settings.channelSettingsSlideoutOpen;
            }
        },
        showSidebarOverlay() {
            return this.menuOpen && this.sidebarMenuOverlay;
        },
        userItems() {
            const items = [
                {
                    title: 'Settings',
                    to: {
                        name: 'user-settings',
                    },
                },
            ];
            // Only support dealer info when the dealer is set and the UI isn't in init
            if (this.currentDealer && !this.isInitializing && !this.userIsClient) {
                items.push({
                    title: 'Account Info',
                    to: {
                        name: 'dealer',
                        params: {
                            dealer_id: this.currentDealer.id,
                        },
                    },
                });
            }
            items.push({ title: 'Log Out', to: null });

            return items;
        },
        interfaceType() {
            if (this.$route.query.interface_type) {
                return this.$route.query.interface_type;
            }

            return this.$route.meta.interfaceType;
        },
        routeHasFeatures() {
            return Boolean(this.$route?.meta?.features?.length);
        },
        dealerNeedsUpgrade() {
            const routeFeatures = this.$route?.meta?.features || [];

            if (!routeFeatures.length) {
                return false;
            }

            return !this.dealerHasFeatures(routeFeatures);
        },
        showCurrentRoute() {
            if (!this.routeHasFeatures) {
                return true;
            }

            return !this.newDealerIsLoading && !this.dealerNeedsUpgrade;
        },
        privacyPolicy() {
            return this.currentAgency?.configuration?.[orgConfiguration.PRIVACY_POLICY] || this.buyerbridgePrivacyPolicy;
        },
        terms() {
            return this.currentAgency?.configuration?.[orgConfiguration.TERMS] || this.buyerbridgeTerms;
        },
        organizationHasSupportDisabled() {
            return this.currentAgency?.configuration?.[orgConfiguration.SUPPORT_BUTTON_DISABLED];
        },
        canUserOverridesSupportButtonConfiguration() {
            return this.$userHasRole(['super_admin', 'admin', 'agency_reseller', 'agency_dashboard', 'performance_manager']);
        },
        profilePhoto() {
            return this.user.profile_photo == null ?
                'https://res.cloudinary.com/du/image/upload/v1541772891/email_assets/Group_3.png' :
                this.user.profile_photo;
        }
    },
    watch: {
        isLayeredDesignerActive(isActive) {
            this.showLayeredDesigner = Boolean(isActive);
        },
        brand: {
            handler() {
                this.updateFavicon();
            },
            deep: true,
        },
        menuOpen(isOpen) {
            this.$store.commit(SET_GLOBAL_MENU, isOpen);
            EventBus.$emit('menu-toggle', {
                state: isOpen,
                animationEnd: false,
            });
            setTimeout(() => {
                EventBus.$emit('menu-toggle', {
                    state: isOpen,
                    animationEnd: true,
                });
            }, 300);
        },
        interfaceType(type) {
            if (type === this.INTERFACE_MINIMAL) {
                this.menuOpen = false;
            } else if(type === this.INTERFACE_BLANK) {
                this.menuOpen = false;

            }
        },
        user() {
            this.handleWindowSize();

            if (!this.user) {
                this.menuOpen = false;
                this.sidebarMenuOverlay = false;
            }
        },
        showSidebarOverlay() {
            if (this.$refs.sidebarOverlay) {
                // Move the sidebar overlay to the document body
                document.body.appendChild(this.$refs.sidebarOverlay);
            }
        },
        $route(newRoute, oldRoute) {
            // Only run logic when the route changes
            if (newRoute?.name === oldRoute?.name) {
                return;
            }

            // Determine if we should show the beta disclaimer for this route
            // If the route requires a beta disclaimer that hasn't been approved
            // by the user show the disclaimer
            if (
                newRoute?.meta?.betaDisclaimer &&
                !this.approvedBetaRoutes.includes(newRoute?.name)
            ) {
                this.showBetaDisclaimerDialog = true;
            }
        },
    },
    created() {
        if (!this.user || this.interfaceType === this.INTERFACE_MINIMAL) {
            this.menuOpen = false;
        }

        const approvedBetaRoutesJson = localStorage.getItem(
            BETA_ROUTES_STORAGE
        );
        this.approvedBetaRoutes = JSON.parse(approvedBetaRoutesJson) || [];

        EventBus.$on('flash', (data) => {
            this.flash(data);
        });

        EventBus.$on('open-ad-studio', ({ editMode = false } ={}, { isOpenedFromLayeredDesigner = false } = {}, { selectedAssets = [] } = {}) => {
            this.openAdStudio(editMode, isOpenedFromLayeredDesigner, selectedAssets);
        });

        EventBus.$on('open-deployment-modal', (configuration) => {
            this.deploymentConfiguration = configuration;
            this.showDeploymentModal = true;
        });

        EventBus.$on('open-layered-designer', (layeredDesignerMode) => {
            this.openLayeredDesigner(layeredDesignerMode);
        });

        EventBus.$on('new-layered-design-saved', () => {
            this.handleSavedLayeredDesigner();
        });

        this.handleWindowSize();

        const onResize = debounce(() => {
            this.handleWindowSize();
        }, 300);

        window.addEventListener('resize', onResize);

        this.updateFavicon();
    },
    methods: {
        ...mapActions([
            'setLayeredDesignerActiveStatus',
            'resetLayeredDesigner'
        ]),
        closeAdDeploymentModal(showAdStudio) {
            if (this.adStudio && !showAdStudio) {
                this.adStudio = showAdStudio;
            }

            this.showDeploymentModal = false;
        },
        updateFavicon() {
            const link =
                document.querySelector('link[rel*=\'icon\']') ||
                document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = this.$brand.asset_urls.favicon;
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        onBetaDisclaimerApproval() {
            // Add the route to the state
            this.approvedBetaRoutes.push(this.$route.name);
            // Update local storage
            localStorage.setItem(
                BETA_ROUTES_STORAGE,
                JSON.stringify(this.approvedBetaRoutes)
            );
        },
        onUserToggle() {
            // Once a user interacts with the menu consider it overridden
            this.userMenuOverride = true;
        },
        handleWindowSize() {
            // If there's no user there's nothing to do
            if (!this.user || this.interfaceType === this.INTERFACE_MINIMAL) {
                return;
            }

            // Show the drawer in overlay mode if the window is mobile
            this.sidebarMenuOverlay =
                window.innerWidth <= this.mobileBreakPoint;

            // If the user has overridden the menu there's nothing to do
            // past this point
            if (this.userMenuOverride) {
                return;
            }
            // Otherwise determine whether the menu is open based on the whether the
            // window size is greater than the breakpoint
            this.menuOpen = window.innerWidth > this.laptopBreakPoint;
        },
        logout() {
            this.menuOpen = false;
            this.$router.push(
                {
                    name: 'login',
                },
                () => {
                    // Logout after navigation occurs to avoid errors
                    this.$store.dispatch('logout');
                }
            );
        },
        flash(data) {
            this.body = data.message;
            this.color = data.color;
            this.show = true;
        },
        openAdStudio(editMode = false, isOpenedFromLayeredDesigner = false, selectedAssets = []) {
            this.createAdMenuOpen = false;
            this.adStudioInEditMode = editMode;
            this.openAdStudioFromLayeredDesigner = isOpenedFromLayeredDesigner;
            this.selectedAssets = selectedAssets;
            this.adStudio = true;
        },
        openLayeredDesigner(layeredDesignerMode) {
            this.createAdMenuOpen = false;
            this.setLayeredDesignerActiveStatus({ isActive: true, mode: layeredDesignerMode });
        },
        handleSavedLayeredDesigner() {
            this.resetLayeredDesigner();
            this.setLayeredDesignerActiveStatus({ isActive: false, mode: null });
            this.flash({
                message: 'The design was successfully saved',
                color: 'green',
            });
            this.$router.push({
                name: 'designs-tab',
            });
        },
        closeAdStudio() {
            this.adStudio = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.primary-toolbar {
    position: fixed;
    width: 100%;
    background-color: $gray-darkest;
    color: $white;
}

.primary-toolbar-inner {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 24px;
    height: 70px;
}
.logo {
    padding-right: 30px;
}
.pickers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .action-button {
        margin: 0 10px;
    }
}
.top-bar-actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

.primary-container {
    padding-top: 70px;
    padding-bottom: 30px;
    height: 100%;
}

.container {
    height: 100%;
}

.primary-container,
.primary-toolbar,
.primary-footer {
    transition: padding-left 0.2s linear;
    padding-left: 50px;
}

.sidebar-menu-open:not(.sidebar-menu-overlay) {
    .primary-container,
    .primary-toolbar,
    .primary-footer {
        padding-left: 350px;
    }
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 155;
    background: rgba(0, 0, 0, 0.5);
}

.primary-toolbar,
.primary-footer {
    z-index: 150 !important;
}

.content-wrapper {
    padding-top: 20px;
}

.interface-type-minimal {
    .primary-container {
        padding: 0 0 36px;

    }
    .primary-footer {
        padding-left: 0;
    }
}
.interface-type-blank {
    .primary-container {
        padding: 0 !important;
        .container {
            padding: 0;
            margin: 0;
        }
    }
    .primary-footer {
        display: none;
    }
}

.v-menu__content, .v-card {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2) !important;
    border-radius: 0 !important;
}

.hide-container {
    display: none;
}
.has-arrow {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-image: url('/img/triangle.png');
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(90deg);
        top: 15px;
        left: 100%;
    }
}
.has-arrow.close-arrow {
    &:after {
        left: 90%;
    }
}
.triangle-header:before {
    border-color: transparent transparent white transparent;
}
</style>

<style>
.dropdown-list {
    padding: 0 !important;
    box-shadow: 0 0 6px 4px rgba(0,0,0,0.1);
    margin: 0 10px 10px 10px;
    border-radius: 5px;
}
.dropdown-list a {
    padding: 25px;
}
.dropdown-list .v-list__tile__title {
    color: #00A4EB;
    font-size: 14px;
}
.circle-button .action-button-button {
    height: 40px;
    width: 40px;
}
.footer-info{
    display:flex;
    justify-content: space-between;
}
.footer-links{
    margin-right: 15px;
}
.footer-link{
    font-size: 13px;
    color: #909ea6;
    text-decoration: none;
}
.footer-link:hover{
    text-decoration: underline;
}
.v-avatar img{
    object-fit: cover;
}
.layered-designer-bg {
    display: none;
}
.layered-designer-bg.active {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 200;
    background: rgba(0, 0, 0, 0.6);
}
</style>
