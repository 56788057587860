<template>
    <div class="assert-library-insert-control-footer d-flex align-items-center">
        <h3 class=" text-left asset-preview-title">
            SELECTED <br>
            ASSETS
        </h3>
        <div class="asset-preview-list">
            <div
                v-for="asset in selectedAssets"
                :key="asset.id"
                class="asset-preview-wrapper">
                <button
                    class="remove-asset-button"
                    @click="$emit('remove-selected-asset', asset)">
                    <icon
                        color="white"
                        name="close"
                        width="18"
                        height="18" />
                </button>
                <img
                    class="asset-preview-item"
                    :src="asset.thumbnail"
                    :alt="asset.name"
                    @error="asset.thumbnail='https://via.placeholder.com/200'">
            </div>
        </div>
        <div class="asset-control-insert-button-wrapper">
            <div
                class="asset-insert-cancel-button"
                @click="$emit('close-asset-library')">
                Cancel
            </div>
            <styled-button
                small
                class="asset-control-insert-button"
                @click="$emit('insert-selected-assets')">
                INSERT AS IMAGE(S)
            </styled-button>
        </div>
    </div>
</template>

<script>
    import StyledButton from '@/components/globals/StyledButton';
    import Icon from '@/components/globals/Icon';

    export default {
        components: {
            StyledButton,
            Icon
        },
        props: {
            selectedAssets: {
                type: Array,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
.asset-preview-title, .asset-preview-wrapper{
   max-width: 100px;
}

.asset-preview-title {
    padding: 5px;
    text-align: center;
    font-size: 12px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
}

.asset-preview-item {
    height: 70px;
    width: 70px;
    object-fit: cover;
    outline: 1px solid $border-list;
    margin: 0 5px;
}

.asset-insert-cancel-button {
    color: $blue-bg;
    cursor: pointer;
    text-decoration: underline;
}

.asset-control-insert-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 250px;
    min-width: 250px;
    margin-left: auto;
    margin-right: 20px;
}


.assert-library-insert-control-footer {
    position: fixed;
    bottom: $asset-library-modal-margin;
    left: $asset-library-modal-margin;
    width: calc(100% - (#{$asset-library-modal-margin} * 2));
    max-width: 100%;
    height: 100px;
    background-color: #E4EAED;
    align-items: center;
    border-radius: 0 0 5px 5px;
}

.asset-preview-wrapper {
    position: relative;
    margin: 0 5px;
    padding: 5px;
}

.remove-asset-button {
    position: absolute;
    right: 1px;
    top: 0;
    svg {
        background-color: #8F9EA6;
        border-radius: 50%;
        padding: 4px;
    }
}

.asset-preview-list {
    display: flex;
    max-width: 100%;
    margin: 0 20px;
    overflow: auto;
}
</style>