import { ShapeAsset } from '@/creative-studio/domain/assets/models/ShapeAsset';

export class ShapeAssetMapper {
    static toDomain(assetResource) {

        const {
            id,
            filename,
            url: thumbnail,
            asset_type: type,
            display_name: name,
            configuration,
        } = assetResource;

        const {
            content = '',
            size = 0,
            height = 200,
            width = 200,
            fill_color: fillColor = '#9B9B9B',
            stroke_color: strokeColor = '#9B9B9B',
            stroke_width: strokeWidth = '0px',
        } = configuration ?? {};

        const shapeAsset = new ShapeAsset({
            id,
            type,
            name,
            thumbnail,
            fillColor,
            strokeColor,
            strokeWidth
        });

        // Mock the file based on available data
        shapeAsset.file = {
            path: thumbnail,
            name: filename,
            type: 'image/svg',
            size,
            height,
            width
        };

        shapeAsset.content = content;
        shapeAsset.fillColor = fillColor;
        shapeAsset.strokeColor = strokeColor;
        shapeAsset.strokeWidth = strokeWidth;

        return shapeAsset;
    }
}