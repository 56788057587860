import { GRADIENT_TYPES } from '@/creative-studio/domain/layered-designer/models/Layer';

export const gradientToString = (gradient) => {
    let gradientString= `${gradient.type}-gradient(`;
    if (gradient.type === GRADIENT_TYPES.LINEAR) {
        gradientString+= `${gradient.degree}deg,`;
    }

    const colorPoints = [...gradient.points].sort((pointA, pointB) => {
        return pointA.left - pointB.left;
    });

    colorPoints.forEach(point => {
        const colorString= `rgba(${point.red}, ${point.green}, ${point.blue}, ${point.alpha}) ${point.left}%, `;
        gradientString+= colorString;
    });

    // Remove the last trailing comma
    gradientString = gradientString.slice(0, -2) + ')';

    return gradientString;
};