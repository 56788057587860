import { TextAsset } from '@/creative-studio/domain/assets/models/TextAsset';
import { AssetType } from '../../../models/types';

export class TextAssetMapper {

    static toDomain(assetResource) {

        // Since text templates and text assets share the same model we need to normalize
        // remote text template data down to align with local text data
        let normalizedAssetResource;
        if (assetResource.asset_type === AssetType.TextTemplate) {
            normalizedAssetResource = this.translateExternalTextTemplateResource(assetResource);
        } else {
            normalizedAssetResource = assetResource;
        }

        const {
            id,
            type,
            name,
            thumbnail,
            text,
            fontFamily,
            fontSize,
            content,
            height,
            width,
            viewBox,
            editableFields,
            contentType,
            textAlign,
            fillColor,
            weight,
            fontStyle,
        } = normalizedAssetResource;

        const textAsset = new TextAsset(id, type, name, thumbnail, contentType, textAlign);

        textAsset.text = text;
        textAsset.fontFamily = fontFamily ?? 'Open Sans';
        textAsset.fontSize = fontSize;
        textAsset.fillColor = fillColor ?? '#000';
        textAsset.content = content;
        textAsset.viewBox = viewBox;
        textAsset.width = width;
        textAsset.height = height;
        textAsset.contentType = contentType;
        textAsset.textAlign = textAlign;
        textAsset.editableFields = editableFields ?? [];
        textAsset.weight = weight;
        textAsset.fontStyle = fontStyle;

        return textAsset;
    }

    static translateExternalTextTemplateResource(assetResource) {
        return {
            id: assetResource.id,
            name: assetResource.display_name,
            type: AssetType.Text,
            thumbnail: assetResource.url,
            text: assetResource.configuration?.text,
            fontFamily: assetResource.configuration?.font_family,
            fontSize: assetResource.configuration?.font_family,
            content: assetResource.configuration?.content,
            contentType: assetResource.configuration?.content_type ?? 'html',
            height: assetResource.configuration?.height,
            width: assetResource.configuration?.width,
            viewBox: assetResource.configuration?.view_box,
            editableFields: assetResource.configuration?.editable_fields,
            textAlign: assetResource.configuration?.font_family,
            fillColor: assetResource.configuration?.font_family,
            weight: assetResource.configuration?.font_family,
            fontStyle: assetResource.configuration?.font_family,
        };
    }
}