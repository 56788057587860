import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';
import { getDefaultDateRangeComparison } from './helpers';
// import moment from 'moment';
// import { INTERVAL_DAY, INTERVAL_WEEK } from '@/helpers/globals';

const _mutationTypes = removeNamespace('metrics/', mutationTypes);

export default {
    [_mutationTypes.SET_DATE_RANGE](state, dateRange) {
        state.dateRange = dateRange;

        // Keep the comparison in sync. Normally this would be a
        // getter but we are planning on letting the user control these.

        // @todo Allow user to control comparison
        state.dateRangeComparison = getDefaultDateRangeComparison(dateRange);

        // Force the interval to week if set to days over 90 days to ease up data sizes
        // DISABLED until other channels support weekly interval
        // const daysBetweenDateRange = moment(state.dateRange.endDate)
        //                                 .diff(state.dateRange.startDate, 'days');

        // if (daysBetweenDateRange > 90 && state.interval == INTERVAL_DAY) {
        //     state.interval = INTERVAL_WEEK;
        // }
    },
    [_mutationTypes.SET_INTERVAL](state, interval) {
        state.interval = interval;
    },
    [_mutationTypes.SET_SPEND_OVERRIDE_DISABLE](state, isDisabled) {
        state.spendOverrideDisable = isDisabled;
    }
};