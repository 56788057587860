/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid': {
    width: 30,
    height: 19,
    viewBox: '0 0 30 19',
    data: '<path pid="0" d="M8 10.5v8H0v-8h8zm11 0v8h-8v-8h8zm11 0v8h-8v-8h8zM8 0v8H0V0h8zm11 0v8h-8V0h8zm11 0v8h-8V0h8z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
