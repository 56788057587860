/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'channels': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<g transform="translate(.788 -.485)" _fill="#8F9EA6" fill-rule="evenodd"><circle pid="0" cx="10" cy="2.485" r="2"/><circle pid="1" cx="10" cy="18.485" r="2"/><circle pid="2" cx="18" cy="6.485" r="2"/><circle pid="3" cx="18" cy="14.485" r="2"/><circle pid="4" cx="2" cy="6.485" r="2"/><path pid="5" fill-rule="nonzero" d="M.824 6.193l.468-.884 17.884 9.468-.468.884z"/><path pid="6" fill-rule="nonzero" d="M10.5 1.485v18h-1v-18z"/><path pid="7" fill-rule="nonzero" d="M1.221 15.54l-.455-.89 18.013-9.22.455.89z"/><circle pid="8" cx="2" cy="14.485" r="2"/><circle pid="9" cx="10" cy="10.485" r="3"/></g>'
  }
})
