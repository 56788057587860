/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'resize': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 20V9.242h1V10h7.843l6.523-6.078h-1.838a.71.71 0 110-1.422h3.554c.388.01.7.323.71.71v3.555a.71.71 0 01-1.42 0V4.927L10 10.864V19h1.828v1H0zm15.828-1v1h-2v-1h2zm3.288-.007v.085l.712.483A.999.999 0 0119 20h-1.172l-.005-1 1.293-.007zM20 15.585v2h-1v-2h1zm0-4v2h-1v-2h1zm0-4v2h-1v-2h1zM1 5.243v2l-1-.001v-2h1zm19-1.658v2h-1v-2h1zM0 1.242h1v2H0v-2zM19 0a1 1 0 011 1v.585h-1V1h-1.657V0zM3.343 0v1h-2V0h2zm4 0v1h-2V0h2zm4 0v1h-2V0h2zm4 0v1h-2V0h2z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
