/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-align-center': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" d="M21 19a1 1 0 010 2H3a1 1 0 010-2h18zm-3-4a1 1 0 010 2H6a1 1 0 010-2h12zm3-4a1 1 0 010 2H3a1 1 0 010-2h18zm-3-4a1 1 0 010 2H6a1 1 0 110-2h12zm3-4a1 1 0 010 2H3a1 1 0 110-2h18z"/>'
  }
})
