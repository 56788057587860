import { SINGLE_IMAGE_AD } from '@/components/ad-deployment/store/constants';

const translateToRequestFormat = (data, channel) => {
    return {
        ad_deployment_id: data?.ad_deployment_id,
        play: {
            campaigns: [
                translateCampaign(data, channel)
            ]
        }
    };
};

const translateCampaign = (data, channel) => {
    return {
        channel,
        existing_campaign_id: data?.campaign_selection?.platform_specific[channel]?.campaign?.id,
        name: data?.campaign_selection?.all_platforms.campaign_name,
        objective: data?.optimization?.platform_specific[channel]?.objective,
        special_ads_category: data?.campaign_selection?.all_platforms.ad_category,
        status: data?.statuses?.platform_specific?.[channel]?.campaign?.status,
        ad_destination: data?.ad_destination?.platform_specific[channel]?.radio_group_option,
        budget: {
            schedule: data?.budget?.schedule, // daily or lifetime
            currency: data?.budget?.currency,
            start_date_time: data?.budget?.start_date_time ? new Date(data?.budget?.start_date_time).toISOString() : null,
            end_date_time: data?.budget?.end_date_time ? new Date(data?.budget?.end_date_time).toISOString() : null,
            level: data?.budget?.platform_specific[channel]?.level, // adSet or campaign
            daily_budget: data?.budget?.platform_specific[channel]?.campaign?.daily_budget,
            daily_spend_cap: data?.budget?.platform_specific[channel]?.campaign?.daily_spend_cap,
            lifetime_budget: data?.budget?.platform_specific[channel]?.campaign?.lifetime_budget,
            lifetime_spend_cap: data?.budget?.platform_specific[channel]?.campaign?.lifetime_spend_cap,
            bid_cap: data?.budget?.platform_specific[channel]?.campaign?.bid_cap,
        },
        ad_sets: [
            translateAdSet(data, channel)
        ]
    };
};

const translateAdSet = (data, channel) => {
    const adDestination = data?.ad_destination?.platform_specific[channel]?.radio_group_option;

    return {
        channel,
        existing_ad_set_id: data?.campaign_selection?.platform_specific[channel]?.adSet?.id,
        name: data?.campaign_selection?.all_platforms?.campaign_name,
        optimization_goal: data?.optimization?.platform_specific[channel]?.optimization_goal?.platform_value,
        billing_event: data?.optimization?.platform_specific[channel]?.billing_event?.platform_value,
        status: data?.statuses?.platform_specific?.[channel]?.adSet?.status,
        targeting: translateTargeting(data, channel),
        dynamic_audience: data?.dynamic_audience_configuration?.platform_specific[channel]?.radio_group_option,
        ad_destination: data?.ad_destination?.platform_specific[channel]?.radio_group_option,
        product_set_id: data?.product_group_selection?.platform_specific[channel]?.id,
        budget: {
            schedule: data?.budget?.schedule, // daily or lifetime
            currency: data?.budget?.currency,
            start_date_time: data?.budget?.start_date_time ? new Date(data?.budget?.start_date_time).toISOString() : null,
            end_date_time: data?.budget?.end_date_time ? new Date(data?.budget?.end_date_time).toISOString() : null,
            level: data?.budget?.platform_specific[channel]?.level, // adSet or campaign
            daily_budget: data?.budget?.platform_specific[channel]?.adSet?.daily_budget,
            daily_spend_cap: data?.budget?.platform_specific[channel]?.adSet?.daily_spend_cap,
            lifetime_budget: data?.budget?.platform_specific[channel]?.adSet?.lifetime_budget,
            lifetime_spend_cap: data?.budget?.platform_specific[channel]?.adSet?.lifetime_spend_cap,
            bid_cap: data?.budget?.platform_specific[channel]?.adSet?.bid_cap,
        },
        ads: data?.ads.filter(ad => {
            if (adDestination === 'onFacebookVDPs') {
                return ad?.configuration?.selected_platforms.includes(channel) && ad?.display_name !== SINGLE_IMAGE_AD;
            }

            return ad?.configuration?.selected_platforms.includes(channel);
        }).map(ad => {
            return translateAd(data, ad, channel);
        }),
    };
};

const translateTargeting = (data, channel) => {
    return {
        custom_audience_ids: data?.audience?.platform_specific[channel]?.existing_audiences.map(audience => audience.id),
        designated_marketing_area_ids: data?.audience?.platform_specific[channel]?.existing_audiences.map(audience => audience.id),
        gender: data?.audience?.platform_specific[channel]?.gender,
        geo_locations: data?.geo_targeting?.platform_specific[channel]?.locations,
        age_min: data?.audience?.platform_specific[channel]?.ages?.min?.value,
        age_max: data?.audience?.platform_specific[channel]?.ages?.max?.value
    };
};

const translateAd = (data, ad, channel) => {
    // create a temp variable to avoid mutating the original ad object
    const adData = { ...ad };
    // get the platform specific ad data
    const channelData = adData?.configuration?.platform_specific[channel];
    // clear out the ad data configuration as that's where the platform specific data is stored
    adData.configuration = null;
    // set the status of the ad
    adData.status = data?.statuses?.platform_specific?.[channel]?.ad?.status;
    // set the ad destination
    adData.ad_destination =  data?.ad_destination?.platform_specific[channel]?.radio_group_option;
    // merge the platform data into the ad data at the top level
    return {
        ...adData,
        ...channelData
    };
};

export default translateToRequestFormat;
