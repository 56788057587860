// import ApiRepository from '../../api-repository';
import {
    SET_AVAILABLE_TEMPLATES,
    SET_TEMPLATE,
    UPDATE_TEMPLATE,
    SET_ACTIVE_PAGE,
    DUPLICATE_PAGE,
    MOVE_PAGE,
    REORDER_PAGES,
    ADD_PAGES,
    UPDATE_PAGE,
    UPDATE_PAGE_META,
    UPDATE_WIDGET,
    DELETE_PAGE,
} from '../mutation-types';

export default {
    state: {
        template: {},
        activePageIndex: 0,
        availableTemplates: []
    },
    getters: {
        activePage: (state) => state.template.pages[state.activePageIndex],
    },
    actions: {
        setAvailableTemplates({ commit }, templates) {
            commit(SET_AVAILABLE_TEMPLATES, templates);
        },
        setTemplate({ commit }, template) {
            commit(SET_TEMPLATE, template);
        },
        updateTemplate({ commit }, payload) {
            commit(UPDATE_TEMPLATE, payload);
        },
        setActivePageIndex({ commit }, index) {
            commit(SET_ACTIVE_PAGE, index);
        },
        duplicatePage({ commit }, pageIndex) {
            commit(DUPLICATE_PAGE, pageIndex);
        },
        movePage({ commit, state }, { pageIndex, moveTo }) {
            switch(moveTo) {
                case 'up':
                    commit(MOVE_PAGE, { fromIndex: pageIndex, toIndex:pageIndex - 1 });
                    break;
                case 'down':
                    commit(MOVE_PAGE, { fromIndex: pageIndex, toIndex: pageIndex + 1 });
                    break;
                case 'top':
                    commit(MOVE_PAGE, { fromIndex: pageIndex, toIndex: 0 });
                    break;
                case 'bottom':
                    commit(MOVE_PAGE, { fromIndex: pageIndex, toIndex: state.template.pages.length - 1 });
                    break;
            }
        },
        reorderPages({ commit }, pageList) {
            commit(REORDER_PAGES, pageList);
        },
        addPages({ commit }, pages) {
            commit(ADD_PAGES, pages);
        },
        updatePage({ commit }, payload) {
            commit(UPDATE_PAGE, payload);
        },
        updatePageMeta({ commit }, payload) {
            commit(UPDATE_PAGE_META, payload);
        },
        updateWidget({ commit }, payload) {
            commit(UPDATE_WIDGET, payload);
        },
        deletePage({ commit }, pageIndex) {
            if(confirm('Are you sure you want to delete this page?')) {
                commit(DELETE_PAGE, pageIndex);
            }
        }
    },
    mutations: {
        [SET_AVAILABLE_TEMPLATES](state, templates) {
            state.availableTemplates = templates;
        },
        [SET_TEMPLATE](state, template) {
            state.template = template;
        },
        [UPDATE_TEMPLATE](state, { ...updates }) {
            for(let key in updates) {
                state.template[key] = updates[key];
            }
        },
        [SET_ACTIVE_PAGE](state, index) {
            state.activePageIndex = index;
        },
        [DUPLICATE_PAGE](state, index) {
            const page = state.template.pages[index];
            let clone = { ...page };
            clone.title = page.name + ' copy';
            state.template.pages.splice(index+1, 0, clone);
        },
        [MOVE_PAGE](state, { fromIndex, toIndex }) {
            const page = state.template.pages.splice(fromIndex, 1)[0];
            state.template.pages.splice(toIndex, 0, page);
        },
        [REORDER_PAGES](state, pageList) {
            state.template.pages = pageList;
        },
        [ADD_PAGES](state, pages) {
            const current = state.template.pages;
            state.template.pages.push.apply(current, pages);
        },
        [UPDATE_WIDGET](state, { slotIndex, ...updates }) {
            let page = state.template.pages[state.activePageIndex];
            for(let key in updates) {
                page.slots[slotIndex][key] = updates[key];
            }
            state.template.pages.splice(state.activePageIndex, 1, page);
        },
        [UPDATE_PAGE](state, { ...updates }) {
            for(let key in updates) {
                state.template.pages[state.activePageIndex][key] = updates[key];
            }

        },
        [UPDATE_PAGE_META](state, { ...updates }) {
            for(let key in updates) {
                state.template.pages[state.activePageIndex].meta[key] = updates[key];
            }
        },
        [DELETE_PAGE](state, index) {
            state.template.pages.splice(index, 1);
        }
    }
};