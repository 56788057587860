import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import dealer from './modules/dealer';
import agency from './modules/agency';
import monitoring from './modules/monitoring';
import brand from './modules/brand';
import report from './modules/report';
import metrics from './modules/metrics';
import onboarding from '../components/onboarding/workflow/store';
import playbookManagement from '../components/playbook-management/store';
import support from './modules/support';
import settings from './modules/settings';
import layeredDesigner from './modules/layered-designer';
import assetLibrary from './modules/asset-library';
import creativeLibrary from './modules/creative-library';
import adStudio from '../components/ad-studio/store/index.js';
import adDeployment from '../components/ad-deployment/store/index.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        dealer,
        agency,
        monitoring,
        brand,
        report,
        metrics,
        onboarding,
        support,
        settings,
        layeredDesigner,
        assetLibrary,
        creativeLibrary,
        playbookManagement,
        adStudio,
        adDeployment
    },
});
