/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pinterest': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 0C4.477 0 0 4.477 0 10a10 10 0 005.986 9.159c-.028-.698-.005-1.537.174-2.296l1.286-5.45s-.319-.638-.319-1.581c0-1.482.859-2.59 1.929-2.59.909 0 1.348.684 1.348 1.502 0 .915-.583 2.282-.883 3.55-.25 1.06.532 1.925 1.579 1.925 1.894 0 3.17-2.433 3.17-5.317 0-2.191-1.476-3.832-4.16-3.832-3.034 0-4.924 2.262-4.924 4.79 0 .87.257 1.485.66 1.96.185.22.21.307.143.558-.048.184-.158.627-.203.802-.067.254-.272.344-.501.25-1.397-.57-2.048-2.1-2.048-3.82 0-2.84 2.396-6.247 7.147-6.247 3.818 0 6.33 2.763 6.33 5.729 0 3.923-2.18 6.853-5.395 6.853-1.08 0-2.095-.583-2.443-1.246 0 0-.58 2.304-.703 2.749-.213.77-.627 1.541-1.007 2.142.9.265 1.85.41 2.834.41 5.522 0 10-4.477 10-10S15.521 0 10 0" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
