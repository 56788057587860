<script>
export default {
    data() {
        return {
            error: null,
            info: null
        };
    },
    errorCaptured(error, vm, info) {
        this.error = error;
        this.info = info;
        console.error(`Error found in ${info} of component:\n\n${error.stack}`);
    },
    render(h) {
        const errorSlot = this.$scopedSlots.error({
            error: this.error,
            info: this.info
        });

        return this.error ?
            h('div', errorSlot) :
            h('div', this.$slots.default);
    }
};
</script>