<template>
    <label
        :style="{
            '--checkmark-scale': checkmarkScale,
            '--checkmark-color': derivedColor
        }"
        :class="{
            'disallow': disabled,
            'square': square,
            'inline': inline,
            'dash': dash
        }"
        class="styled-checkbox">
        {{ label }}
        <input
            type="checkbox"
            :value="value"
            :checked="checked"
            :disabled="disabled"
            @click="handleClick">
        <span class="styled-checkbox-checkmark" />
    </label>
</template>

<script>
import colors from '@/helpers/colors';

export default {
    name: 'StyledCheckbox',
    props: {
        value: {
            type: [String, Boolean, Number],
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        checked: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        square: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        dash: {
            type: Boolean,
            default: false
        },
        checkmarkScale: {
            type: [String,Number],
            default: 1
        },
        color: {
            type: String,
            default: 'blue'
        },
    },
    computed: {
        derivedColor() {
            if (colors[this.color]) {
                return colors[this.color];
            }

            return this.color;
        }
    },
    methods: {
        handleClick(event) {
            this.$emit('change', event);
            // Added 8/12/22 to try and make this a bit more usable
            // for additional scenarios
            this.$emit('update:checked', event.target.checked);
        }
    }
};
</script>

<style lang="scss" scoped>

.styled-checkbox {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    &.disallow {
        cursor: not-allowed;
        opacity: 0.4
    }
    &.square {
        .styled-checkbox-checkmark {
            border-radius: 2px;
        }
    }
    &.inline {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 7px;
        .styled-checkbox-checkmark {
            margin-right: 8px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.dash {
        .styled-checkbox-checkmark {
            &::after {
                content: '';
                top: 50%;
                left: 50%;
                width: 10px;
                height: 2px;
                border: none;
                background-color: $white;
                transform: rotate(0) translate(-50%, -50%);
            }
         }
    }
    &:hover input ~ .styled-checkbox-checkmark {
        background-color: $alabaster-dark;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
            & ~ .styled-checkbox-checkmark {
                background-color: var(--checkmark-color);
                border-color: var(--checkmark-color);
                &::after {
                    display: block;
                }
            }
        }
        &:disabled {
            cursor: not-allowed;
        }
    }
    .styled-checkbox-checkmark {
        position: relative;
        display: block;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid;
        border-color: $gray;
        background-color: $white;
        transform-origin: center center;
        transform: scale(var(--checkmark-scale));
        transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        &::after {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 6px;
            height: 11px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

    }
}
</style>