<template>
    <v-autocomplete
        ref="select"
        :items="dealersPlusCurrent"
        :value="dealerId"
        item-text="name"
        item-value="id"
        :menu-props="menuProps"
        :loading="loading ? 'primary' : false"
        :class="[
            styled ? 'styled-field' : 'dealer-select styled-select'
        ]"
        :disabled="disabled"
        append-icon="arrow_drop_down"
        light
        color="grey lighten-2"
        label="Account"
        placeholder="Select..."
        return-object
        required
        @input="onInput"
        @keyup="search"
        @click="onFocus">
        <template #item="{ item }">
            <v-list-tile-content>
                <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                <v-list-tile-sub-title
                    v-if="userIsAdmin && item.agency"
                    class="grey--text text--lighten-2">
                    {{ item.agency.name }}
                </v-list-tile-sub-title>
            </v-list-tile-content>
        </template>
        <template #no-data>
            <v-list-tile>
                <v-list-tile-title>
                    {{ (loading) ? 'Loading...' : 'No dealers found' }}
                </v-list-tile-title>
            </v-list-tile>
        </template>
    </v-autocomplete>
</template>

<script>
import { get, debounce } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { GROUPS_ROUTES } from '@/helpers/globals';

export default {
    props: {
        styled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            dealers: [],
            dealerId: null
        };
    },
    computed: {
        ...mapGetters([
            'userIsAdmin'
        ]),
        ...mapState({
            stateDealers: (state) => state.dealer.dealers,
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId
        }),
        menuProps() {
            if (this.userIsAdmin) {
                return { contentClass: 'dealer-menu', maxHeight: 290 };
            }
            return {};
        },
        disabled() {
            if (GROUPS_ROUTES.includes(this.$route.name)) { return true }
            return false;
        },
        dealersPlusCurrent() {
            // Ensure the current dealer is always in the list
            const dealers = [ ...this.dealers ];
            if (this.currentDealer &&
                !dealers.some(dealer => dealer.id === this.currentDealer.id)) {
                dealers.push(this.currentDealer);
            }
            return dealers;
        }
    },
    watch: {
        currentDealerId() {
            this.dealerId = this.currentDealerId;
        },
        stateDealers() {
            this.dealers = this.stateDealers;
        }
    },
    created() {
        this.dealers = this.stateDealers;
        this.dealerId = this.currentDealerId;
    },
    methods: {
        onInput(dealer) {
            this.updateDealer(dealer);
        },
        updateDealer(dealer) {
            // If we're on a dealer route we can change the dealer
            // by changing the URL which will automatically register the
            // target dealer
            const routeDealerId = get(this.$route, 'params.dealer_id', null);
            if (routeDealerId !== null &&
                parseInt(routeDealerId) !== dealer.id) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        dealer_id: dealer.id
                    }
                });
            }
            // Otherwise just update the state directly
            else {
                this.$store.dispatch('updateDealerById', dealer.id);
            }
        },
        onFocus() {
            setTimeout(() => this.$refs.select.$refs.input.select(), 200);
        },
        search: debounce(async function(e) {
            this.loading = true;
            this.dealers = [];
            const response = await this.$apiRepository.getDealerPickerSearchDealers(e.target.value, this.agencyId);
            this.dealers = response.data.data;
            this.loading = false;

        }, 300)
    }
};
</script>

<style lang="scss">
.dealer-menu {
    .v-list > div {
        padding: 10px 0;
    }
}
.v-autocomplete {
    .v-label {
        text-transform: uppercase;
    }
}
.builder-variant {
    .v-input__control {
        background-color: $gray-darkest !important;
        color: $gray-text;
    }
    .v-label {
        margin-top: 14px;
        margin-left: 8px;
        padding: 4px;
        font-size: 13px !important;
        text-transform: none;
        background-color: $gray-darkest;
        color: $gray-text;
    }
    .v-label--active {
        color: #CED4D7 !important;
        background-color: $gray-darkest !important;
        }
    .v-input__slot {
        margin-top: 3px;
        background-color: transparent !important;
        color: $gray-text !important;
        border: 1px solid $gray-text !important;
        border-radius: 0px !important;
        font-size: 13px !important;
        height: 28px;
    }
    input {
        color: $gray-text !important;
    }
}
.top-bar-variant {
    &.v-input--is-disabled {
        opacity: 0.5;
    }
    .v-label {
        text-transform: none;
        font-size: 14px;
        background-color: transparent !important;
        color: $white;
    }
    .v-input__slot {
        background-color: transparent !important;
        color: $white !important;
        border: 1px solid #CED4D7 !important;
    }
    input {
        color: $white !important;
    }
}
</style>

