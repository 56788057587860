/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flip-horizontal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M9.625 4.386v10.489a1 1 0 01-1 1H1.838a1 1 0 01-.84-1.543L7.786 3.843a1 1 0 011.84.543z" opacity=".49"/><path pid="1" d="M11.375 4.386v10.489a1 1 0 001 1h6.787a1 1 0 00.84-1.543L13.215 3.843a1 1 0 00-1.84.543z"/></g>'
  }
})
