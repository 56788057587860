import { HttpFactory } from '@/creative-studio/domain/common/services/http/HttpFactory';

import { UPLOAD_FILE_PATH } from '@/creative-studio/domain/assets/services/routes';
import { FileMapper } from './mappers/FileMapper';

export class FileApiService {

    constructor() {
        this.httpClient = HttpFactory.createClient();
    }

    async uploadFile(rawFileResource) {
        const requestParams = await FileMapper.toRequest(rawFileResource);
        const { data: fileResource } = await this.httpClient.post(UPLOAD_FILE_PATH, requestParams);
        return FileMapper.toDomain(fileResource);
    }
}