<script>
export default {
    name: 'StyledList',
    props: {
        alignMiddle: {
            type: Boolean,
            default: false
        }
    },
    render(h) {
        const items = this.$slots.default
            .filter(vnode => vnode.children)
            .map((vnode, index) => {
                vnode.data = vnode.data || {};
                const number = h('div', { class: ['styled-list-counter'] }, index + 1);
                const numberWrap = h('div', [ number ]);
                const content = h('div', { class: ['styled-list-content'] }, vnode.children);
                return h('div',
                    {
                        class: [
                            'styled-list-item',
                            vnode.data.staticClass || ''
                        ],
                        style: {
                            alignItems: this.alignMiddle ? 'center' : 'normal'
                        }
                    },
                    [ numberWrap, content ]
                );
            });
        return h('div', { class: { 'styled-list': true } }, items);
    }
};
</script>

<style lang="scss">

.styled-list {
    counter-reset: section;
    .styled-list-item {
        display: flex;
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
        & > div {
            .styled-list-counter {
                font-weight: bold;
                display: block;
                background-color: $gray;
                border-radius: 50%;
                color: $white;
                width: 30px;
                height: 30px;
                font-size: 20px;
                text-align: center;
                margin-right: 20px;
            }
            &.styled-list-content {
                flex: 1;
            }
        }

    }
}
</style>
