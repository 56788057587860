<template>
    <styled-tooltip
        v-model="tooltipOpen"
        position="bottom"
        :max-width="450"
        class="d-inline-block"
        open-on-click>
        <v-progress-circular
            v-if="loading"
            color="primary"
            width="2"
            size="15"
            indeterminate />

        <span
            v-else
            class="email-edit">
            <a
                v-if="currentEmail && !loading"
                @click="tooltipOpen = true">
                <span>{{ currentEmail }}</span>
            </a>
            <button @click="tooltipOpen = true">
                <icon
                    class="email-icon"
                    name="edit"
                    width="15"
                    height="15" />
            </button>
        </span>


        <template #content>
            <div :style="{ minWidth: '270px' }">
                <v-alert
                    :value="error"
                    type="error">
                    Error applying update.  Please try again or contact support if the problem persists.
                </v-alert>
                <v-alert
                    :value="!loading && complete"
                    type="success">
                    Update successfully applied!
                </v-alert>
                <v-form
                    id="edit-email-form"
                    v-model="valid"
                    @submit.prevent="handleSubmit">
                    <v-text-field
                        v-model.trim="newEmail"
                        label="User Email Address"
                        :rules="[...requiredRules, ...emailRules]"
                        hint="Enter new email address for your account"
                        class="py-3 my-0"
                        required />

                    <div class="text-xs-center">
                        <v-btn
                            type="submit"
                            form="edit-email-form"
                            :loading="loading"
                            :disabled="!valid"
                            depressed
                            outline
                            color="primary"
                            class="text-none font-weight-bold">
                            Save
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </template>
    </styled-tooltip>
</template>

<script>
import { requiredRules, emailRules } from '../../helpers/validationRules';
import StyledTooltip from './StyledTooltip';
import Icon from './Icon';

export default {
    components: {
        Icon,
        StyledTooltip
    },
    props: {
        user: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            requiredRules,
            emailRules,
            error: null,
            complete: false,
            valid: false,
            loading: false,
            tooltipOpen: false,
            currentEmail: '',
            newEmail: ''
        };
    },
    watch: {
        user() {
            this.setData();
        }
    },
    created() {
        this.setData();
    },
    methods: {
        setData() {
            this.currentEmail = this.user.email;
            this.newEmail = this.user.email;
        },
        async handleSubmit() {
            this.updateEmail(this.newEmail);
        },
        async updateEmail(email) {
            this.error = null;
            this.complete = false;
            this.loading = true;

            try {

                await this.$http.patch(`/users/${this.user.id}`, { email });

                this.$emit('update', { email });
                this.currentEmail = email;
                this.loading = false;
                this.complete = true;
                setTimeout(() => {
                    this.tooltipOpen = false;
                    this.complete = false;
                }, 2000);

            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error updating user email:', error);
                this.error = true;
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.email-edit {
    .email-icon {
        margin-left: 5px;
        &:hover {
            cursor: pointer;
        }
    }
    button {
        &:active,
        &:focus {
            outline: none;
        }
    }
}
</style>
