import { cloneDeep } from 'lodash';

const maxCacheEntries = 50;

export default {
    store: [],
    get(key) {

        const entryIndex = this.store.findIndex(entry => entry.key == key);

        if (entryIndex == -1) {
            return null;
        }

        const entry = this.store[entryIndex];

        // If the entry has expired drop it and exit
        if (entry.expiration < Date.now()) {
            this.store.splice(entryIndex, 1);
            return null;
        }

        // Otherwise return a clone of the cache
        // this ensures that modifications don't affect
        // the original cached entry
        return cloneDeep(entry.data);
    },

    set(key, data) {
        // Set expiration 1 hour from now
        const expiration = (new Date()).getTime() + 1000 * 60 * 60;
        this.store.push({ 
            key, 
            expiration,
            data: cloneDeep(data) // Clone to ensure the cache remains untouched
        });

        // If the cache is full remove the oldest item
        if (this.store.length > maxCacheEntries) {
            this.store.shift();
        }
    }
};