/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shadow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#000" x="4" y="4" width="16" height="16" rx="2"/><rect pid="1" _fill="#8F9EA6" width="16" height="16" rx="2"/></g>'
  }
})
