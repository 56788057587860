/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sound-on': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" _fill="#8F9EA6" d="M16.188 3.878c.176 0 .352.068.484.18C18.165 5.448 19 7.489 19 9.553s-.857 4.06-2.35 5.45a.607.607 0 01-.44.18.7.7 0 01-.483-.224.602.602 0 01-.176-.494c.022-.179.088-.358.22-.47a6.126 6.126 0 001.933-4.464c0-1.682-.703-3.32-1.933-4.464-.132-.134-.22-.291-.22-.47 0-.18.066-.36.176-.494.132-.135.286-.225.461-.225zM13.75 5.493a.635.635 0 01.484.157c1.098.965 1.69 2.468 1.69 3.926a5.276 5.276 0 01-1.713 3.858.69.69 0 01-.439.18.7.7 0 01-.483-.225.775.775 0 01-.154-.494c0-.18.088-.359.22-.47a3.868 3.868 0 001.274-2.872c0-1.099-.461-2.153-1.274-2.87-.132-.135-.22-.292-.22-.472a.666.666 0 01.154-.493c.132-.135.286-.225.461-.225zM0 6.996v5.002c0 .74.593 1.346 1.318 1.346h2.636V5.65H1.318C.593 5.65 0 6.256 0 6.996zm11.422-4.845a1.072 1.072 0 00-1.098 0L5.052 5.314v8.366l5.272 3.163c.175.112.373.157.549.157a.976.976 0 00.549-.157c.351-.202.55-.56.55-.964V3.139c0-.404-.199-.786-.55-.988z"/>'
  }
})
