/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'car': {
    width: 20,
    height: 14,
    viewBox: '0 0 20 14',
    data: '<defs><path pid="0" d="M15.488 10C14.658 10 14 9.337 14 8.484A1.48 1.48 0 0115.488 7C16.318 7 17 7.663 17 8.484 17 9.337 16.317 10 15.488 10M4.5 10C3.677 10 3 9.337 3 8.484 3 7.664 3.677 7 4.5 7S6 7.663 6 8.484C6 9.337 5.323 10 4.5 10m15.006-4.777h-2.083c-.158-.027-.264-.08-.316-.188l-1.187-3.85C15.71.485 15.103 0 14.602 0H5.375c-.5 0-1.08.485-1.318 1.185L2.87 5.035c-.053.107-.132.161-.29.188H.498c-.29 0-1.371 1.642 1.002 1.642.007-.002.007-.002 0 0-.066.026-.694.311-.765 1.427-.105 1.643 0 3.285 0 4.927 0 .431.343.781.765.781h2.003c.422 0 .765-.35.765-.78v-1.24a47.842 47.842 0 0111.441 0v1.24c0 .43.37.78.791.78h1.978c.421 0 .764-.35.764-.78 0-1.643.105-3.285.026-4.928-.07-1.117-.7-1.401-.784-1.427h-.006c-.006-.002-.003-.002.006 0 2.39-.003 1.311-1.642 1.022-1.642" id="svgicon_car_a"/></defs><use _fill="#8F9EA6" xlink:href="#svgicon_car_a" fill-rule="evenodd"/>'
  }
})
