import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';

const _mutationTypes = removeNamespace('metrics/aggregate/', mutationTypes);

const setPlatformsSelected = ({ commit }, platforms) => {
    commit(_mutationTypes.SET_AGG_PLATFORMS_SELECTED, platforms);
};

export default {
    setPlatformsSelected
};