import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';
import getDealerInsightsRequest from '@/apis/facebook/getDealerInsights';
import getDealerCampaigns from '@/apis/facebook/getDealerCampaigns';
import getDealerOfflineEvents from '@/apis/facebook/getDealerOfflineEvents';
import getDealerInventoryInsights from '@/apis/facebook/getDealerInventoryInsights';
import getDealerVehiclesById from '@/apis/inventory/getDealerVehiclesById';
import formatInventoryData from '@/apis/inventory/formatInventoryData';
import moment from 'moment';
import { INTERVALS } from '@/helpers/globals';

const _mutationTypes = removeNamespace('metrics/facebook/ads/', mutationTypes);

const attributionWindows = [
    '1d_view',
    '1d_click',
    '7d_view',
    '7d_click',
    '28d_view',
    '28d_click'
];

/**
 * Converts the selected interval into a value that works
 * with Facbeook's time_increment field
 *
 * @todo update this function to support the time_ranges
 * since this currently isn't 100% (e.g. 90d isn't actually a quarter)
 */
const intervalToIncrement = (interval) => {

    const selected = INTERVALS.find(item => item.value == interval);

    return selected.increment;
};

const updateCampaigns = async({ rootState, commit, getters }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_CAMPAIGNS, []);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_CAMPAIGNS_LOADING, true);


        const data = await getDealerCampaigns(rootState.dealer.currentDealerId);

        const frozenData = data.map(Object.freeze);

        commit(_mutationTypes.SET_FB_DEALER_CAMPAIGNS, frozenData);

    } finally {
        commit(_mutationTypes.SET_FB_DEALER_CAMPAIGNS_LOADING, false);
    }
};


const getInsights = async({ state, rootState, rootGetters }, config = {}) => {

    const defaults = {
        ...rootState.metrics.dateRange,
        dealerId: rootState.dealer.currentDealerId,
        campaigns: state.campaignsSelected.map(campaign => campaign.id),
        spendOverride: rootState.metrics.spendOverrideDisable ? null : rootGetters.dealerAdSpendOverride,
        level: 'account',
        increment: null,
        attributionWindows
    };

    return await getDealerInsightsRequest({ ...defaults, ...config });
};

const updateMetricsTotals = async({ dispatch, commit, getters }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS, null);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_LOADING, true);

        const data = await dispatch('getInsights');

        const accountData = data.length ? data[0] : null;

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS, frozenAccountData);
    } finally {
        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_LOADING, false);
    }
};



const updateMetricsTotalsPreviousMonth = async({ dispatch, commit, getters, rootGetters }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH, null);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH_LOADING, true);

        const data = await dispatch('getInsights', {
            ...rootGetters['metrics/dateRangePreviousMonth']

        });

        const accountData = data.length ? data[0] : null;

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH, frozenAccountData);

    } finally {
        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH_LOADING, false);
    }
};

const updateMetricsTotalsPrevious90Days = async({ dispatch, commit, getters, rootGetters }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS, null);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS_LOADING, true);

        const data = await dispatch('getInsights', {
            ...rootGetters['metrics/dateRangePrevious90Days']

        });

        const accountData = data.length ? data[0] : null;

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS, frozenAccountData);

    } finally {
        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS_LOADING, false);
    }
};

const updateMetricsIntervals = async({ dispatch, commit, getters, rootState }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INTERVALS, []);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INTERVALS_LOADING, true);

        const data = await dispatch('getInsights', {
            increment: intervalToIncrement(rootState.metrics.interval)
        });

        const frozenData = Object.freeze(data);

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INTERVALS, frozenData);
    } finally {
        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INTERVALS_LOADING, false);
    }
};


const updateMetricsCampaigns = async({ dispatch, commit, getters }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS, []);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS_LOADING, true);

        const data = await dispatch('getInsights', {
            level: 'campaign'
        });

        const frozenData = Object.freeze(data);

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS, frozenData);
    } finally {
        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS_LOADING, false);
    }
};

const updateMetricsInventory = async({ state, commit, getters, rootState, rootGetters }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INVENTORY, []);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INVENTORY_LOADING, true);

        //TODO: Get dealer inventory insights and combine with dealer inventory data
        const dealerId = rootState.dealer.currentDealerId;
        const config = {
            dealerId,
            startDate: moment(rootState.metrics.dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(rootState.metrics.dateRange.endDate).format('YYYY-MM-DD'),
            attributionWindows: ['1d_view', '1d_click', '7d_click'],
            campaigns: rootState.metrics.facebook.ads.campaignsSelected
        };

        const insights = await getDealerInventoryInsights(config);

        const vehicleIds = insights.map(vehicle => {
            return vehicle.product_id.split(',')?.[0];
        });

        const dealerVehiclesResponse = await getDealerVehiclesById({ vehicleIds, dealerId });
        const inventory = dealerVehiclesResponse.data.hits.hits;

        const data = formatInventoryData(insights, inventory, {
            spendOverride: rootGetters['metrics/spendOverride'],
            attribution: state.attribution,
            attributionWindows,
            dateRange: rootState.metrics.dateRange,
        });

        const frozenData = Object.freeze(data);

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INVENTORY, frozenData);
    } finally {
        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INVENTORY_LOADING, false);
    }
};


const updateMetricsTotalsComparison = async({ dispatch, commit, getters, rootState }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON, null);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON_LOADING, true);

        const data = await dispatch('getInsights', {
            ...rootState.metrics.dateRangeComparison
        });

        const accountData = data.length ? data[0] : null;

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON, frozenAccountData);
    } finally {
        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON_LOADING, false);
    }
};

const updateAllMetrics = async({ dispatch, commit }) => {

    commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_ERROR, null);
    // commit(_mutationTypes.SET_TT_DEALER_METRICS_PREVIOUS_PERIOD_ERROR, null);

    const responses = await Promise.allSettled([
        dispatch('updateMetricsTotals'),
        dispatch('updateMetricsIntervals'),
        dispatch('updateMetricsCampaigns'),
        dispatch('updateMetricsInventory'),
        dispatch('updateMetricsTotalsComparison'),
        dispatch('updateMetricsTotalsPreviousMonth'),
        dispatch('updateMetricsTotalsPrevious90Days')
    ]);


    // Get the metric total responses from the promise all settled array
    const metricsResponses = responses.slice(0, 4);

    if (metricsResponses.some(response => response.status === 'rejected')) {
        commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_ERROR, 'Error retrieving Facebook Insights');
        console.error('Error retrieving Facebook Insights');
    }


    // Get the previous metric total responses from the promise all settled array
    // const metricsPreviousResponses = responses.slice(4, 7);

    // if (metricsPreviousResponses.some(response => response.status === 'rejected')) {
    //     commit(_mutationTypes.SET_FB_DEALER_PREVIOUS_INSIGHTS_ERROR, 'Error retrieving previous Facebook Insights');
    //     console.error('Error retrieving previous Facebook Insights');
    // }
};

const updateOfflineEvents = async({ rootState, rootGetters, getters, commit }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS, []);

        if (!rootGetters.dealerFacebookOfflineEventSetAnnotation || !getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_LOADING, true);

        const data = await getDealerOfflineEvents({
            dealerId: rootState.dealer.currentDealerId,
            startDate: moment(rootState.metrics.dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(rootState.metrics.dateRange.endDate).format('YYYY-MM-DD'),
        });

        const frozenData = Object.freeze(data);

        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS, frozenData);

    } finally {
        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_LOADING, false);
    }
};

/**
 * Retrieves current offline events which we use to determine the last
 * time that a user uploaded an offline event
 */
const updateOfflineEventsCurrent = async({ rootState, rootGetters, getters, commit }) => {

    try {

        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_CURRENT, []);

        if (!rootGetters.dealerFacebookOfflineEventSetAnnotation || !getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_CURRENT_LOADING, true);

        const data = await getDealerOfflineEvents({
            dealerId: rootState.dealer.currentDealerId,
            startDate: moment().subtract(60, 'days').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
        });

        const frozenData = Object.freeze(data);

        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_CURRENT, frozenData);

    } finally {
        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_CURRENT_LOADING, false);
    }
};

const updateAllOfflineEvents = async({ dispatch, commit }) => {
    try {
        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_ERROR, null);

        await Promise.all([
            dispatch('updateOfflineEvents'),
            dispatch('updateOfflineEventsCurrent'),
        ]);

    } catch (error) {
        console.error('Error loading events', error.response || error);
        commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_ERROR, 'Error loading events');
    }
};

const startDataLoaders = ({ commit }) => {
    commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_LOADING, true);
    commit(_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_CURRENT_LOADING, true);
    commit(_mutationTypes.SET_FB_DEALER_CAMPAIGNS_LOADING, true);
};

const startMetricsLoaders = ({ commit }) => {
    commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_LOADING, true);
    commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_INTERVALS_LOADING, true);
    commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS_LOADING, true);
    commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON_LOADING, true);
    commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS_LOADING, true);
    commit(_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH_LOADING, true);
};

export default {
    startDataLoaders,
    startMetricsLoaders,
    getInsights,
    updateCampaigns,
    updateAllMetrics,
    updateMetricsTotals,
    updateMetricsIntervals,
    updateMetricsCampaigns,
    updateMetricsInventory,
    updateMetricsTotalsComparison,
    updateMetricsTotalsPreviousMonth,
    updateMetricsTotalsPrevious90Days,
    updateOfflineEvents,
    updateOfflineEventsCurrent,
    updateAllOfflineEvents
};
