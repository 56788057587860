<template>
    <span :class="`status-field ${colorClass}`">
        <i v-if="colorClass" />
        <template v-if="label.length">
            {{ label }}
        </template>
        <slot v-else />
    </span>
</template>

<script>
    export default {
        props: {
            /* description: {
                type: String,
                default: null
            }, */
            color: {
                required: true,
                validator(value) {
                    return ['red', 'orange', 'green', 'yellow', 'gray', 'blue'].includes(value);
                }
            },
            label: {
                type: String,
                default: ''
            }
        },
        computed: {
            colorClass() {
                return (this.color) ? 'status-field--' + this.color : '';
            },
            hasDefaultSlot() {
                return !!this.$slots.default;
            },
        }
    };
</script>

<style lang="scss">


.status-field {
    white-space: nowrap;
    i {
        display: inline-block;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        margin-bottom: -3px;
        margin-right: 5px;
    }

    &--red {
        i {
            background-color: $carnation;
        }
        a {
            color: $carnation;
        }
    }
    &--green {
        i {
            background-color: $whild-willow;
        }
        a {
            color: $whild-willow;
        }
    }
    &--orange {
        i {
            background-color: $coral;
        }
        a {
            color: $coral;
        }
    }
    &--yellow {
        i {
            background-color: $yellow;
        }
        a {
            color: $yellow;
        }
    }
    &--gray {
        i {
            background-color: $gray;
        }
        a {
            color: $gray;
        }
    }
    &--blue {
        i {
            background-color: $blue;
        }
        a {
            color: $blue;
        }
    }
}

</style>
