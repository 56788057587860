/**
 * This function sets a value in a nested object following dot notation, for example, if you have an object like this:
 * {
 *    foo: {
 *      bar: {
 *          baz: 'hello'
 *      }
 *    }
 * }
 *
 * and you want to set the value of baz, you can do this:
 * setNestedValue(obj, 'foo.bar.baz', 'goodbye');
 *
 * @param obj
 * @param propertyPath
 * @param value
 * @returns {any}
 */
    export const setNestedValue = (obj, propertyPath, value) => {
    const copy = JSON.parse(JSON.stringify(obj));
    const parts = propertyPath.split('.');
    let currentValue = copy;

    for (let i = 0; i < parts.length - 1; i++) {
        const part = parts[i];

        if (typeof currentValue[part] !== 'object' || currentValue[part] === null) {
            currentValue[part] = {};
        }
        currentValue = currentValue[part];
    }

    const lastPart = parts[parts.length - 1];
    currentValue[lastPart] = value;

    return copy;
};
