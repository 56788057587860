/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm1.042 15H8.75V9.27h2.292V15zM8.75 6.974c0-.394.11-.683.327-.866.219-.183.491-.275.819-.275.312 0 .58.094.807.28.226.187.339.474.339.861s-.113.676-.34.866c-.225.19-.494.285-.806.285-.328 0-.6-.095-.819-.285-.218-.19-.327-.479-.327-.866z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
