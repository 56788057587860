<template>
    <v-menu
        v-model="open"
        transition="slide-y-transition"
        bottom
        left
        nudge-right="18"
        nudge-bottom="0"
        offset-y
        allow-overflow
        :nudge-width="60"
        fixed
        :content-class="customContentClass"
        :close-on-content-click="closeOnContentClick">
        <template #activator="props">
            <slot
                name="activator"
                v-bind="props" />
        </template>
        <div class="styled-menu">
            <slot />
        </div>
    </v-menu>
</template>

<script>
export default {
    props: {
        closeOnContentClick: {
            type: Boolean,
            default: true
        },
        customContentClass: {
            required: false,
            type: String,
            default: 'styled-menu-content'
        }
    },
    data() {
        return {
            open: false
        };
    }
};
</script>

<style lang="scss">
.styled-menu-content {
    box-shadow: none;
    border-radius: 0;
}
.styled-menu {
    background-color: $gray-dark;
    margin-top: 20px;
    &:before {
        content: '';
        position: absolute;
        right: 23px;
        top: 11px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $gray-dark transparent;
        z-index: 9999;
    }
    a, button {
        display: block;
        width: 100%;
        padding: 15px 35px 15px 35px;
        color: $white;
        text-decoration: none;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        transition: background-color 0.2s ease-in-out;
        border-bottom: 1px solid rgba($black, 0.1);
        &:disabled {
            opacity: 0.7;
        }
        &:last-child {
            border-bottom: none;
        }
        span {
            display: block;
            color: $gray;
            font-style: italic;
            font-size: 14px;
        }
        &:hover {
            background-color: rgba($white, 0.1)
        }
    }
}
</style>
