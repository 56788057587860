/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gradient': {
    width: 16,
    height: 16,
    viewBox: '0 0 200 200',
    data: '<g _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M0 0h5.882v5.882H0z"/><path pid="1" opacity=".298" d="M14.118 0H20v5.882h-5.882z"/><path pid="2" opacity=".701" d="M7.059 0h5.882v5.882H7.059z"/><path pid="3" d="M0 14.118h5.882V20H0zm0-7.059h5.882v5.882H0z"/><path pid="4" opacity=".701" d="M7.059 14.118h5.882V20H7.059zm0-7.059h5.882v5.882H7.059z"/><path pid="5" opacity=".298" d="M14.118 14.118H20V20h-5.882zm0-7.059H20v5.882h-5.882z"/></g>'
  }
})
