<template>
    <nav class="layered-designer-header">
        <div class="layered-designer-header-menu">
            <!-- <div>
                <div class="v-divider v-divider--vertical" />
                <button
                    class="action-button-wrapper">
                    <span class="action-button-label">Settings</span>
                    <icon
                        class="action-button-icon"
                        color="#E4EAED"
                        name="cog"
                        width="20"
                        height="20" />
                </button>
            </div> -->
            <!-- <div class="v-divider v-divider--vertical" />

            <div>
                <button
                    class="action-button-wrapper">
                    <span class="action-button-label">Need Help?</span>
                    <icon
                        class="action-button-icon"
                        color="#E4EAED"
                        name="life-buoy"
                        width="20"
                        height="20" />
                </button>
            </div> -->

            <div>
                <button
                    class="action-button-wrapper"
                    @click="onExit">
                    <span class="exit-label">Exit</span>
                    <icon
                        class="exit-icon"
                        color="#3A4044"
                        name="close"
                        width="20"
                        height="20" />
                </button>
            </div>
        </div>
        <layered-designer-card-controls-sub-header />
    </nav>
</template>

<script>
    import Icon from '@/components/globals/Icon';

    import LayeredDesignerCardControlsSubHeader from './LayeredDesignerCardControlsSubHeader';

    import { mapActions } from 'vuex';

    export default {
        components: {
            Icon,
            LayeredDesignerCardControlsSubHeader
        },
        methods: {
            ...mapActions([
                'setLayeredDesignerActiveStatus',
                'resetLayeredDesigner'
            ]),
            onExit() {
                // Check unsaved data and exit
                this.setLayeredDesignerActiveStatus({ status: false, mode: null });
                this.resetLayeredDesigner();
            }
        }
    };
</script>

<style lang="scss">
.layered-designer-header {
    width: calc(100% - (#{$layered-designer-modal-margin} * 2));
    position: fixed;
    top: $layered-designer-modal-margin;
    z-index: 1000;
    border-top-right-radius: 5px;
    .action-button-wrapper {
        padding: 0 25px;
    }

    .exit-icon {
        margin-left: 10px;
        padding: 5px;
        border-radius: 50%;
        color: $white;
        background-color: $gray;
    }

    .action-button-icon {
        margin-left: 10px;
    }

    .exit-label {
        display: inline-block;
        margin-top: 3px;
        font-size: 16px;
        color: $gray-text;
        margin-right: 5px;
    }

    .action-button-label {
        font-size: 14px;
        color: $white;
    }
}

.layered-designer-header-menu {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    padding-bottom: 18.5px;
    align-items: center;
    border-top-right-radius: 10px;
    height: 70px;
}



.layered-designer-header .v-divider {
    color: #B1BCC1;
}

.layered-designer-header-card-controls {
    background-color: #EDF2F5;
    height: 35px;
    display: flex;
    padding: 10px 0px;
    align-items: center;
}

.delete-card-button {
    padding: 0 20px;
}
</style>