/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 12,
    height: 22,
    viewBox: '0 0 12 22',
    data: '<path pid="0" d="M11.214 12.282l.622-3.952h-3.89V5.766c0-1.081.543-2.135 2.285-2.135H12V.267S10.395 0 8.86 0C5.656 0 3.562 1.892 3.562 5.318V8.33H0v3.952h3.562v9.552a14.494 14.494 0 004.383 0v-9.552h3.269" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
