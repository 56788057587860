<template>
    <div class="quick-selector-toolbar-wrapper">
        <div class="search-bar">
            <icon
                class="prepend-icon"
                name="search"
                width="22"
                height="22" />
            <input
                v-model="searchAssetText"
                class="styled-input"
                placeholder="Search assets"
                @keyup="$emit('search-asset', searchAssetText)">
        </div>
        <div
            class="expand-icon"
            @click="$emit('on-expand-library')">
            <icon
                name="full-screen"
                width="22"
                height="22" />
        </div>
    </div>
</template>

<script>
    import Icon from '@/components/globals/Icon';

export default {
    components: {
        Icon
    },
    data() {
        return {
            searchAssetText: ''
        };
    }
};
</script>

<style lang="scss">
.search-bar {
    input::placeholder {
        color: #a7aaad;
    }
}
.search-icon {
    position: absolute;
    margin-top: 8px;
}
.search-bar .styled-input {
    position: relative;
    background-color: transparent;
    border-color: #a7aaad;
    width: 210px;
    outline: none;
    border-radius: 4px;
    padding: 10px 10px;
    border: solid 1px $gray;
    padding-left: 40px;
}
.search-bar .prepend-icon {
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;

}
.expand-icon {
    cursor: pointer;
    margin-left: 30px;
    margin-right: 15px;
}

.quick-selector-toolbar-wrapper {
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-top: 20px;
}
</style>