<template>
    <div class="status-icon">
        <icon
            :height="size"
            :width="size"
            v-bind="iconProps"
            :style="{
                backgroundColor
            }" />
    </div>
</template>

<script>
import Icon from './Icon';
import colors from '../../helpers/colors';

export default {
    components: {
        Icon
    },
    props: {
        value: {
            type: String,
            default: 'success'
        },
        size: {
            type: String,
            default: '15'
        },
        color: {
            type: String,
            default: null
        },
        backgroundColor: {
            type: String,
            default: 'transparent'
        }
    },
    computed: {
        iconProps() {

            if (this.value === 'error') {
                return {
                    name: 'indicator-error',
                    color: this.color || colors.red
                };
            }

            if (this.value === 'warning') {
                return {
                    name: 'indicator-warning',
                    color: this.color || colors.orange
                };
            }

            return {
                name: 'indicator-success',
                color: this.color || colors.green
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.status-icon {
    display: inline-block;
    vertical-align: middle;
}
</style>
