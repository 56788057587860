/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money-sack': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14 0a1 1 0 01.81.42 1 1 0 01.14.9l-.7 2.1C16.93 5.06 20 11.18 20 14v.3a5.71 5.71 0 01-5.7 5.7H5.7A5.71 5.71 0 010 14.3V14c0-2.82 3.07-8.94 5.75-10.58l-.7-2.1a1 1 0 01.14-.9A1 1 0 016 0zm-4 7a1 1 0 00-1 1h-.5a2.5 2.5 0 000 5H9v1H7a1 1 0 000 2h2a1 1 0 002 0h.5a2.5 2.5 0 100-5H11v-1h2a1 1 0 000-2h-2a1 1 0 00-1-1zm1.5 6a.5.5 0 110 1H11v-1zM9 10v1h-.5a.5.5 0 110-1H9z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
