import { primaryTextKey, headlineKey, descriptionKey, FACEBOOK, PINTEREST, SNAPCHAT, TIKTOK } from '@/components/ad-studio/store/constants';

export const lengthValidatorConstants = {
    [primaryTextKey]: {
        [FACEBOOK]: {
            minLength: 0,
            maxLength: 2200,
        },
        [PINTEREST]: {
            minLength: 0,
            maxLength: 500,
        },
    },
    [headlineKey]: {
        [FACEBOOK]: {
            minLength: 0,
            maxLength: 40,
        },
        [PINTEREST]: {
            minLength: 0,
            maxLength: 40,
        },
        [SNAPCHAT]: {
            minLength: 0,
            maxLength: 34,
        },
        [TIKTOK]: {
            minLength: 1,
            maxLength: 100,
        },
    },
    [descriptionKey]: {
        [FACEBOOK]: {
            minLength: 0,
            maxLength: 40,
        }
    }
};

export default (field, content, channel) => {
    return content.length >= lengthValidatorConstants[field][channel]?.minLength &&
        content.length <= lengthValidatorConstants[field][channel]?.maxLength;
};
