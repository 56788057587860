export default function(featureId) {
    const features = [
        {
            id: 1,
            name: 'VDP Guarantee',
            description: 'Monthly guaranteed VDP views'
        },
        {
            id: 2,
            name: 'VDP Non-Guaranteed',
            description: 'Monthly non-guaranteed VDP views'
        },
        {
            id: 3,
            name: 'Inventory Processing',
            description: 'Recurring inventory processing and normalization from your designated provider'
        },
        {
            id: 4,
            name: 'Page Management',
            description: 'Managed access to your Facebook page to ensure all associated Facebook assets remain connected to it'
        },
        {
            id: 5,
            name: 'Marketplace Listings',
            description: 'Automated syndication of your processed inventory to Facebook Marketplace'
        },
        {
            id: 6,
            name: 'Automotive Inventory Ads',
            description: 'Transform your pre-owned inventory feed into Facebook & Instagram Ads served to local shoppers'
        },
        {
            id: 7,
            name: 'Lead Distribution',
            description: 'Distribution of collected leads back to your CRM or direct to email based on your desired preferences'
        },
        {
            id: 8,
            name: 'Website Lead Tracking',
            description: 'Tailored on-site tracking to determine meaningful visitor interactions and optimize campaigns based on them'
        },
        {
            id: 9,
            name: 'Custom Retargeting Pixel',
            description: 'Custom implementation of the Facebook pixel to ensure maximum retargeting and tracking coverage'
        },
        {
            id: 10,
            name: 'New/Used Campaigns',
            description: 'Utilizing the base AIA setup, new and used campaigns run in unison for optimial inventory coverage'
        },
        {
            id: 11,
            name: 'Dynamic Body Style Campaigns',
            description: 'Utilizing the base AIA setup, campaigns for all represented body styles in your inventory will be dyanamically deployed for optimial inventory coverage'
        },
        {
            id: 12,
            name: 'Craigslist Listings',
            description: 'Automated syndication of your processed inventory to Craigslist'
        },
        {
            id: 13,
            name: 'Call Tracking',
            description: 'Track calls from across all BuyerBridge products to benchmark success and streamline lead attribution'
        }
    ];

    return features.find(feature => feature.id === featureId);
}