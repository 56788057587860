<template>
    <img
        class="canvas-image-asset inner-canvas-layer"
        :class="[{'canvas-image-cover' : objectFit}]"
        :src="layer.asset.thumbnail"
        :alt="layer.asset.name"
        :style="imageStyles"
        @mousedown="setFocusedLayer(layer)"
        @dblclick="setFocusedLayerIsClipping">
</template>

<script>
    import { mapActions,mapGetters } from 'vuex';
    import { gradientToString } from '@/components/creative-studio/layered-designer/utils/gradient-to-string';

    export default {
        props: {
            layer: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters([
            'focusedLayer'
            ]),
            imageStyles() {
                const styles= {};

                if (this.layer.usesColorGradient()) {
                    const gradientString = gradientToString(this.layer.colorGradient);
                    styles.backgroundImage= gradientString;
                }
                return styles;
            },
            objectFit() {
                return this.layer?.alterations?.isBeingFit;
            }
        },
        methods: {
            ...mapActions([
                'setFocusedLayer',
                'setFocusedLayerIsClipping'
            ]),

        }
    };
</script>

<style lang="scss" scoped>
.canvas-image-asset {
    min-height: 20px;
    min-width: 20px;
}


.canvas-image-cover {
    object-fit: cover;
}

</style>