export const CREATIVE_STUDIO_FONTS = [
    // Custom Fonts
    {
        family: 'Acura Bespoke', // Acura
        url: '/fonts/layered-designer/Acura-Bespoke-Regular.woff2'
    },
    {
        family: 'Apex New', // Alfa Romeo
        url: '/fonts/layered-designer/Apex-New-Medium.woff2'
    },
    {
        family: 'Audi Type', // Audi
        url: '/fonts/layered-designer/Audi-Type-Normal.woff2'
    },
    {
        family: 'BMW Type', // BMW
        url: '/fonts/layered-designer/BMW-Type-Regular.woff2'
    },
    {
        family: 'Arial', // Buick
        url: '/fonts/layered-designer/Arial.woff2'
    },
    {
        family: 'Futura', // Buick
        url: '/fonts/layered-designer/Futura-Medium.woff2'
    },
    {
        family: 'Trebuchet', // Buick
        url: '/fonts/layered-designer/Trebuchet.woff2'
    },
    {
        family: 'Cadillac Sans', // Cadillac
        url: '/fonts/layered-designer/Cadillac-Sans-Medium.woff2'
    },
    {
        family: 'Louis Global', // Chevrolet
        url: '/fonts/layered-designer/Louis-Global-Regular.woff2'
    },
    {
        family: 'Trade Ghotic', // Chrysler + Dodge + Jeep + Ram
        url: '/fonts/layered-designer/Trade-Gothic.woff2'
    },
    {
        family: 'Ford Antenna', // Ford
        url: '/fonts/layered-designer/Ford-Antenna-Regular.woff2'
    },
    {
        family: 'Genesis Sans', // Genesis
        url: '/fonts/layered-designer/Genesis-Sans-Regular.woff2'
    },
    {
        family: 'Avenir Next', // Honda + GMC
        url: '/fonts/layered-designer/Avenir-Next-Regular.woff2'
    },
    {
        family: 'Hyundai Sans', // Hyundai
        url: '/fonts/layered-designer/Hyundai-Sans-Regular.woff2'
    },
    {
        family: 'Infiniti Brand', // Infiniti
        url: '/fonts/layered-designer/Infiniti-Brand-Regular.woff2'
    },
    {
        family: 'Kia Signature', // KIA
        url: '/fonts/layered-designer/Kia-Signature-Regular.woff2'
    },
    {
        family: 'Lincoln Miller', // Lincoln
        url: '/fonts/layered-designer/Lincoln-Miller-Roman.woff2'
    },
    {
        family: 'Lincoln Proxima', // Lincoln
        url: '/fonts/layered-designer/Lincoln-Proxima-Regular.woff2'
    },
    {
        family: 'Everett', // Maserati
        url: '/fonts/layered-designer/Everett-Regular.woff2'
    },
    {
        family: 'Mazda Type', // Mazda
        url: '/fonts/layered-designer/Mazda-Type-Regular.woff2'
    },
    {
        family: 'Knockout', // Mitsubishi
        url: '/fonts/layered-designer/Knockout-Regular.woff2'
    },
    {
        family: 'Nissan Brand', // Nissan
        url: '/fonts/layered-designer/Nissan-Brand-Regular.woff2'
    },
    {
        family: 'Eurostile', // Subaru
        url: '/fonts/layered-designer/Eurostile-Normal.woff2'
    },
    {
        family: 'Toyota Type', // Toyota
        url: '/fonts/layered-designer/Toyota-Type-Regular.woff2'
    },
    {
        family: 'Volvo Antikva', // Volvo
        url: '/fonts/layered-designer/Volvo-Antikva-Regular.woff2'
    },
    {
        family: 'Volvo Novum', // Volvo
        url: '/fonts/layered-designer/Volvo-Novum-Regular.woff2'
    },
    {
        family: 'VW Text', // VW
        url: '/fonts/layered-designer/VW-Text-Regular.woff2'
    },
    // Google Fonts
    { family: 'Alegreya' },
    { family: 'Alegreya Sans' },
    { family: 'Archivo Narrow' },
    { family: 'BioRhyme' },
    { family: 'Cardo' },
    { family: 'Chivo' },
    { family: 'Cormorant' },
    { family: 'Eczar' },
    { family: 'Fira Sans' },
    { family: 'IBM Plex Sans' },
    { family: 'Inconsolata' },
    { family: 'Inknut Antiqua' },
    { family: 'Inter' },
    { family: 'Karla' },
    { family: 'Lato' },
    { family: 'Libre Baskerville' },
    { family: 'Libre Franklin' },
    { family: 'Lora' },
    { family: 'Manrope' },
    { family: 'Merriweather' },
    { family: 'Montserrat' },
    { family: 'Neuton' },
    { family: 'Open Sans' },
    { family: 'Playfair Display' },
    { family: 'Poppins' },
    { family: 'Proza Libre' },
    { family: 'PT Sans' },
    { family: 'PT Serif' },
    { family: 'Raleway' },
    { family: 'Roboto' },
    { family: 'Roboto Slab' },
    { family: 'Rubik' },
    { family: 'Source Sans Pro' },
    { family: 'Source Serif Pro' },
    { family: 'Space Grotesk' },
    { family: 'Space Mono' },
    { family: 'Spectral' },
    { family: 'Syne' },
    { family: 'Work Sans' },
  ];