<template>
    <div>
        <action-button
            icon="admin"
            @click="showAssignPermissionsModal = true">
            Assign Permissions
        </action-button>
        <assign-permissions-modal v-model="showAssignPermissionsModal" />
    </div>
</template>

<script>
    import AssignPermissionsModal from '@/components/dealers/DealerID/QuickLinks/AssignPermissionsModal';
    import ActionButton from '@/components/globals/ActionButton';
    export default {
        components: {
            AssignPermissionsModal,
            ActionButton
        },
        data() {
            return {
                showAssignPermissionsModal: false
            };
        }
    };
</script>