<template>
    <div
        v-show="open"
        class="content-align-window">
        <div class="align-content-picker-container">
            <div class="align-content-picker-title">
                Alignment to Page
            </div>

            <div class="icons-row">
                <button title="page-align-left-button">
                    <icon
                        name="page-align-left"
                        width="20"
                        height="20" />
                </button>
                <button title="page-align-center-button">
                    <icon
                        name="page-align-center"
                        width="20"
                        height="20" />
                </button>
                <button title="page-align-right-button">
                    <icon
                        name="page-align-right"
                        width="20"
                        height="20" />
                </button>
            </div>

            <div class="icons-row">
                <button title="page-align-top-button">
                    <icon
                        name="page-align-top"
                        width="20"
                        height="20" />
                </button>
                <button title="page-align-center-button">
                    <icon
                        name="page-align-center"
                        width="20"
                        height="20" />
                </button>
                <button title="page-align-bottom-button">
                    <icon
                        name="page-align-bottom"
                        width="20"
                        height="20" />
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import Icon from '@/components/globals/Icon';

export default {
    components: {
        Icon
    },
    props: {

        open: {
            required: true,
            type: Boolean
        }
    },
};

</script>

<style lang="scss">

.content-align-window {
    position: absolute;
    left: calc(50% - 80px);
    width: 150px;
    top: 40px;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: $white;
}

.align-content-picker-container {
    padding: 4px;
}

.align-content-picker-title {
    text-align: center;
    color: $gray;
    font-size: 12px;

}


.icons-row {
    display: flex;
    justify-content: space-between;

    margin: 15px 20px
}

</style>