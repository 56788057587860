<template>
    <span
        :class="{
            'action-button': true,
            'disabled': disabled,
            'light': light,
            'normal-text': normalText,
            'position-top': position === 'top',
            'position-right': position === 'right',
            'position-bottom': position === 'bottom',
            'position-left': position === 'left',
        }"
        @click="onClick">
        <span>
            <button
                class="action-button-button"
                type="button">
                <styled-loader
                    v-if="loading"
                    :size="size"
                    :light="light" />
                <icon
                    v-else
                    class="action-button-icon"
                    :height="height || size"
                    :width="width || size"
                    :name="icon"
                    :color="light ? 'white' : derivedColor" />
            </button>
            <span
                v-if="!!$slots['default']"
                class="action-button-text">
                <slot />
            </span>
        </span>
    </span>
</template>
<script>
import StyledLoader from './StyledLoader';
import Icon from './Icon';
import colors from '../../helpers/colors';

export default {
    components: {
        Icon,
        StyledLoader
    },
    props: {
        icon: {
            type: String,
            required: true
        },
        href: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: '_self'
        },
        to: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: '23'
        },
        height: {
            type: String,
            default: null
        },
        width: {
            type: String,
            default: null
        },
        light: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: colors.gray
        },
        position: {
            type: String,
            default: 'top'
        },
        normalText: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        derivedColor() {
            if (colors[this.color]) {
                return colors[this.color];
            }

            return this.color;
        }
    },
    methods: {
        onClick(e) {
            if (this.disabled) {
                return;
            }

            // Allow for routing
            if (this.to) {
                this.$router.push(this.to);
            }

            // Mimic href behavior for dev UX
            if (this.href && this.target === '_blank') {
                window.open(this.href);
            } else if (this.href) {
                document.href = this.href;
            }

            // Always emit the click event
            this.$emit('click', e);
        }
    }
};
</script>

<style lang="scss">
.action-button-button {
    .loader {
        display: block;
    }
}
</style>

<style lang="scss" scoped>
.action-button {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    transition: opacity 0.2s ease-in-out;
    &.disabled {
        opacity: 0.6;
        &:hover {
            cursor: default;
            .action-button-button {
                background-color: transparent;
                svg {
                    transform: scale(1);
                }
            }
        }
    }
    &:hover {
        cursor: pointer;
        .action-button-button {
            background-color: rgba($gray, 0.2);
            svg {
                transform: scale(0.8);
            }
        }
    }
    &.position-left {
        span {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
        }
        .action-button-text {
            margin-right: 5px;
        }
    }
    &.position-right {
        span {
            display: flex;
            align-items: center;
        }
        .action-button-text {
            margin-left: 5px;
        }
    }
    &.light {
        .action-button-text {
            color: $white;
        }
    }
    & > div {
        display: block;
    }
    .action-button-button {
        border-radius: 50%;
        padding: 3px;
        background-color: transparent;
        transition: background-color 0.3s ease-in-out;
        svg {
            transition: transform 0.3s ease-in-out;
            display: block;
        }
        &:active,
        &:focus {
            outline: none;
        }
    }
    &:not(.normal-text) {
        .action-button-text {
            font-size: 9px;
            text-transform: uppercase;
        }
    }
    .action-button-text {
        display: block;
        color: $gray;
    }
}
</style>
