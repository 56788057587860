/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'see-more': {
    width: 20,
    height: 14,
    viewBox: '0 0 20 14',
    data: '<path pid="0" d="M10 .45c1.279 0 2.6.321 3.926.957 1.038.497 2.082 1.187 3.103 2.05 1.725 1.459 2.788 2.904 2.832 2.964a.72.72 0 010 .85c-.044.06-1.108 1.506-2.832 2.964-1.021.863-2.065 1.553-3.103 2.05-1.326.636-2.647.958-3.926.958-1.279 0-2.6-.322-3.926-.957-1.038-.498-2.082-1.188-3.103-2.051C1.246 8.777.183 7.332.139 7.27a.72.72 0 010-.85c.044-.06 1.107-1.505 2.832-2.963 1.021-.864 2.065-1.554 3.103-2.051C7.4.77 8.721.449 10 .449zm0 1.44c-3.908 0-7.305 3.684-8.36 4.956 1.055 1.272 4.452 4.957 8.36 4.957 3.908 0 7.305-3.685 8.36-4.957C17.306 5.574 13.909 1.89 10 1.89zm0 1.316a3.644 3.644 0 013.64 3.64 3.644 3.644 0 01-3.64 3.64 3.644 3.644 0 01-3.64-3.64A3.644 3.644 0 0110 3.206z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
