<template>
    <div
        v-if="value"
        class="loader">
        <div

            :class="[
                'loader-icon',
                light ? 'light' : ''
            ]"
            :style="{
                height: `${size}px`,
                width: `${size}px`
            }">
            <div
                v-for="div in [1,2,3,4]"
                :key="div"
                :style="{
                    borderWidth: `${borderWidth}px`,
                    height: `${size}px`,
                    width: `${size}px`
                }" />
        </div>
        <div
            v-if="label"
            class="loader-label">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: true
        },
        light: {
            type: Boolean,
            default: false
        },
        size: {
            type: [Number,String],
            default: 80
        },
        label: {
            type: String,
            default: null
        }
    },
    computed: {
        borderWidth() {
            if (this.size > 50) {
                return 10;
            }

            if (this.size > 20) {
                return 3;
            }

            return 2;
        }
    }
};
</script>

<style lang="scss" scoped>

.loader {
    display: inline-block;
    text-align: center;
}

.loader-icon {
    display: inline-block;
    position: relative;
    &.light {
        div {
            border-top-color: $white;
        }
    }
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        border-style: solid;
        border-color: transparent;
        border-top-color: $blue-bg;
        border-radius: 50%;
        animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

.loader-label {
    margin-top: 10px;
    text-align: center;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
