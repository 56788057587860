<template>
    <div>
        <asset-alerts :monitor-id="1" />
        <template v-if="loading">
            <p class="section-title">
                Page
            </p>
            <loader label="Retrieving page..." />
        </template>
        <manage-dealer-page
            v-if="!loading"
            :settings-mode="true"
            :dealer="dealer"
            :user-assigned="userAssigned" />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Loader from '@/components/globals/Loader';
    import ManageDealerPage from '@/components/globals/ManageDealerPage';
    import AssetAlerts from './AssetAlerts';

    export default {
        components: {
            Loader,
            ManageDealerPage,
            AssetAlerts
        },
        props: {
            dealer: {
                type: Object,
                required: true
            },
            annotation: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                loading: false,
                userAssigned: false,
            };
        },
        computed: {
            ...mapState({
                recheckFacebookAssets: (state) => state.dealer.recheckFacebookAssets,
            })
        },
        watch: {
            recheckFacebookAssets() {
                this.checkAccess(this.annotation.id);
            }
        },
        created() {
            this.checkAccess(this.annotation.id);
        },
        methods: {
            async checkAccess(annotationId) {
                try {
                    this.loading = true;
                    const response = await this.$http.post(`/facebook_page_annotations/${annotationId}/check-user-access`);

                    if (['user_already_has_access', 'user_added_to_ad_account'].includes(response.data.status)) {
                        this.userAssigned = true;
                    } else {
                        this.userAssigned = false;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
            }
        }
    };
</script>
<style scoped>
.section-title {
    margin-top: 20px;
    font-size: 16px;
    background: #f3f5f6;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    display: flex;
    align-items: center;
}
</style>