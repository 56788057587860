<template>
    <div class="ad-deployment-options">
        <div class="campaign-selector">
            <h2 class="text-xs-center">
                Are you creating a new campaign or launching into an existing campaign?
            </h2>
            <div class="panel-container mt-3 text-xs-center">
                <panel
                    :is-new-campaign-component="true"
                    @close-deployment-modal="setDeploymentLocationAndDeploy" />
                <panel
                    @close-deployment-modal="setDeploymentLocationAndDeploy" />
            </div>
        </div>
        <div class="blank" />
    </div>
</template>

<script>

import Panel from '@/components/ad-deployment/components/DeploymentModal/Panel.vue';

export default {
    name: 'StaticDeploymentOptions',
    components: { Panel },
    methods: {
        setDeploymentLocationAndDeploy(deploymentLocation) {
            this.$emit('start-static-ad-deployment', deploymentLocation);
        },
    }
};
</script>

<style scoped lang="scss">
.ad-deployment-options {
    .campaign-selector {
        .panel-container {
            display: flex;
            justify-content: space-evenly;
        }
    }

    .blank {
        height: 70px;
    }
}
</style>
