/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'adset': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M8.182 10.91a.91.91 0 01.909.908v7.273a.91.91 0 01-.91.909H.91A.91.91 0 010 19.09v-7.272a.91.91 0 01.91-.909h7.272zm10.909 0a.91.91 0 01.909.908v7.273a.91.91 0 01-.91.909h-7.272a.91.91 0 01-.909-.91v-7.272a.91.91 0 01.91-.909h7.272zm0 .908h-7.273v5.091h7.273v-5.09zm-10.91 0H.91v5.091h7.273v-5.09zM8.182 0a.91.91 0 01.91.91v7.272a.91.91 0 01-.91.909H.91A.91.91 0 010 8.18V.91A.91.91 0 01.91 0h7.272zm10.91 0A.91.91 0 0120 .91v7.272a.91.91 0 01-.91.909h-7.272a.91.91 0 01-.909-.91V.91A.91.91 0 0111.82 0h7.272zm0 .91h-7.273V6h7.273V.91zM8.18.91H.91V6h7.273V.91z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
