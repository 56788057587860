<template>
    <div
        :key="internalKey"
        :style="wrapperStyle"
        class="layer-wrapper">
        <div
            :id="layer.id"
            ref="layer-element"
            :class="layerClasses"
            :style="layerStyle"
            data-selectable-layer
            :data-layer-id="layer.id"
            :data-current-top="localTop"
            :data-current-left="localLeft"
            :data-current-height="localHeight"
            :data-current-width="localWidth"
            :data-current-rotate-angle="localRotateAngle"
            :data-current-scale="localScale"
            :draggable="true"
            @dragstart="handleAssetDragStart">
            <canvas-text-asset
                v-if="layer.asset.isTextAsset() && layer.asset.contentType === 'html'"
                :layer="layer" />
            <canvas-text-asset-editable
                v-else-if="layer.asset.isTextAsset() && layer.asset.contentType !== 'html'"
                :layer="layer" />
            <canvas-image-asset
                v-else-if="layer.asset.isImageAsset()"
                :layer="layer" />
            <canvas-shape-asset
                v-else-if="layer.asset.isShapeAsset()"
                :layer="layer" />
        </div>
        <svg-gradient-definitions
            v-if="layer.asset.isShapeAsset() || layer.asset.isTextAsset()"
            :layer-id="layer.id"
            :gradient="layer.colorGradient" />
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import CanvasTextAsset from './CanvasTextAsset';
    import CanvasImageAsset from './CanvasImageAsset';
    import CanvasShapeAsset from './CanvasShapeAsset';
    import SvgGradientDefinitions from './svg-wrappers/SvgGradientDefinitions';
    import { getImgSizeInfo } from '../../utils/contained-image-size';
    import { FIT_TYPES } from '@/creative-studio/domain/layered-designer/models/Layer';
    import CanvasTextAssetEditable from './text-asset-editable/CanvasTextAssetEditable';

    import EventBus from '@/event-bus';

    import {
        MOVEABLE_USES_RELATIVE_CROP
    } from '@/components/creative-studio/common/constants';

    export default {
        components: {
            CanvasTextAsset,
            CanvasImageAsset,
            CanvasShapeAsset,
            SvgGradientDefinitions,
            CanvasTextAssetEditable
        },
        props: {
            layer: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                localTop: 0,
                localLeft: 0,
                localHeight: 0,
                localWidth: 0,
                localRotateAngle: 0,
                localScale: 1,
                randomKey: 0
            };
        },
        computed: {
            layerClasses() {
                return `${this.layer.asset.type}-asset asset-layer`;
            },
            layerStyle() {
                const verticalTransform = this.layer.alterations.isVerticalFlipped ? -1 : 1;
                const horizontalTransform = this.layer.alterations.isHorizontalFlipped ? -1 : 1;
                const transform = `translate(${this.localLeft}px, ${this.localTop}px)`
                    + ` rotate(${this.localRotateAngle}deg)`
                    + ` scaleY(${verticalTransform})`
                    + ` scaleX(${horizontalTransform})`
                    + `${this.layer?.asset.isTextAsset() ? `scale(${this.layer.scale}, ${this.layer.scale})` : ''}`;

                const style = {
                    transform
                };

                if (!this.layer?.asset?.isTextAsset()) {
                     const width = this.localWidth + 'px';
                    const height = this.localHeight + 'px';
                    style.height = height;
                    style.width = width;
                }

                if (this.layer?.asset?.isTextAsset()) {
                    style.fontSize = '50px';
                }

                if (this.layer?.isClippable()) {
                    const clipUnit = MOVEABLE_USES_RELATIVE_CROP ? '%' : 'px';
                    const clipPath = `inset(${this.layer.cropOffset.top}${clipUnit} ${this.layer.cropOffset.right}${clipUnit} ${this.layer.cropOffset.bottom}${clipUnit} ${this.layer.cropOffset.left}${clipUnit})`;
                    style.clipPath = clipPath;
                }

                if (this.layer?.asset?.isTextAsset() && this.layer.shadow?.color ) {
                    const shadowString = `${this.layer.shadow?.xOffset}px ${this.layer.shadow?.yOffset}px ${this.layer.shadow?.blur}px ${this.layer.shadow?.color}`;
                    style.textShadow = shadowString;
                }

                return style;
            },
            wrapperStyle() {
                const wrapperStyle = {
                    zIndex: this.layer.index,
                };

                if (!this.layer?.asset?.isTextAsset()) {
                    const shadowString = `${this.layer.shadow?.xOffset}px ${this.layer.shadow?.yOffset}px ${this.layer.shadow?.blur}px ${this.layer.shadow?.color}`;
                    wrapperStyle.filter= `drop-shadow(${shadowString})`;
                }

                return wrapperStyle;
            },
            internalKey() {
                return this.randomKey + '_' + this.layer.id;
            }
        },
        watch: {
            'layer.position'(newPosition) {
                const { top: newTop, left: newLeft } = newPosition;

                const currentTop = parseInt(this.$refs['layer-element'].dataset.currentTop);
                const currentLeft = parseInt(this.$refs['layer-element'].dataset.currentLeft);

                // Check if the position changed but the element position did not
                if (currentTop !== newTop || currentLeft !== newLeft) {
                    this.localTop = newTop;
                    this.localLeft = newLeft;
                    this.customForceUpdate();
                }
            },
            'layer.dimensions'(newDimensions) {
                const { height: newHeight, width: newWidth } = newDimensions;

                this.localHeight = newHeight;
                this.localWidth = newWidth;
            },
            'layer.rotateAngle'(newRotateAngle) {
                this.localRotateAngle = newRotateAngle;
            },
            'layer.scale'(newScale) {
                this.localScale = newScale;
            }
        },
        mounted() {
            EventBus.$on('image-asset-fit', this.handleImageAssetFit);
        },
        created() {
            this.initLocalValues();
        },
        beforeDestroy() {
            EventBus.$off('image-asset-fit');
        },
        updated() {
            this.initLocalValues();
        },
        methods: {
            ...mapActions([
                'updateFocusedLayerDimensions',
                'setFocusedLayerById'
            ]),
            initLocalValues() {
                this.localTop = this.layer.position.top;
                this.localLeft = this.layer.position.left;

                this.localHeight = this.layer.dimensions.height;
                this.localWidth = this.layer.dimensions.width;

                this.localRotateAngle = this.layer.rotateAngle;
                this.localScale = this.layer.scale;
            },
            customForceUpdate() {
                // Update the internal key to force the component to re-render
                this.randomKey++;
            },
            handleImageAssetFit(option) {
                const moveable = document.querySelector('[data-layer-focused="true"] [data-selectable-layer]');
                const focusedLayerImage = moveable.getElementsByClassName('canvas-image-asset')[0];

                if(option === FIT_TYPES.CONTAIN) {
                if(focusedLayerImage) {
                        const objectFitDimessions = getImgSizeInfo(focusedLayerImage);
                        const { height, width } = objectFitDimessions;
                        this.updateFocusedLayerDimensions({ height, width });
                    }
                }
                if(option === FIT_TYPES.COVER ||
                option === FIT_TYPES.FILL ||
                option === FIT_TYPES.SCALE_DOWN ||
                option === FIT_TYPES.NONE
                ) {
                    const offsetHeight = moveable.offsetHeight;
                    const offsetWidth = moveable.offsetWidth;
                    const maxDimesionForResize = Math.max(offsetHeight, offsetWidth);
                    this.updateFocusedLayerDimensions({ height: maxDimesionForResize, width: maxDimesionForResize });
                }
            },
            handleAssetDragStart(e) {
                this.$emit('asset-drag-start', e);
            },
        }
    };
</script>

<style lang="scss">

.layer-wrapper {
    position: absolute;
    height: 0px;
    width: 100%;
}
</style>