import { generateSolidStyle, generateGradientStyle } from '@/components/globals/GradientPicker/helpers';

export default {
    name: 'area-preview',

    props: {
        isGradient: Boolean,
        red: Number,
        green: Number,
        blue: Number,
        alpha: Number,
        points: Array,
        gradientDegree: Number,
        gradientType: String,
        type: String,

    },

    computed: {
        style() {
            if (this.type !== 'solid') {
                const style = generateGradientStyle(this.points, this.gradientType, this.gradientDegree);

                return { background: style };
            }

            const style = generateSolidStyle(this.red, this.green, this.blue, this.alpha);

            return { backgroundColor: style };
        }
    }
};
