/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ig_reels_menu_icon': {
    width: 17,
    height: 5,
    viewBox: '0 0 17 5',
    data: '<g fill-rule="evenodd" transform="translate(.5 .5)"><circle pid="0" cx="2" cy="2" r="2"/><circle pid="1" cx="7.778" cy="2" r="2"/><circle pid="2" cx="13.556" cy="2" r="2"/></g>'
  }
})
