import moment from 'moment';
import { getDefaultDateRangeComparison } from '@/store/modules/metrics/helpers';

/**
 * Handles structure and state tracked in storage
 */
const storage = (state) => {
    return {
        interval: state.interval,
        dateRange: state.dateRange,
        spendOverrideDisable: state.spendOverrideDisable
    };
};

/**
 * Determines if the spend override is active
 */
const spendOverride = (state, getters, rootState, rootGetters) => {

    // Always ensure the end user sees the override if it's set
    if (rootGetters.userIsClient && rootGetters.dealerAdSpendOverride !== null) {
        return rootGetters.dealerAdSpendOverride;
    }

    if (rootGetters.dealerAdSpendOverride == null) {
        return null;
    }

    if (state.spendOverrideDisable) {
        return null;
    }

    return rootGetters.dealerAdSpendOverride;
};

/**
 * Determines if spend should be hidden
 */
const hideSpend = (state, getters) => {
    return (
        getters.spendOverride !== null &&
        getters.spendOverride?.type === 'no_spend'
    );
};

/**
 * Calculates the days between the selected date range
 */
const daysBetweenDateRange = (state) => {
    return moment(state.dateRange.endDate)
            .diff(state.dateRange.startDate, 'days');
};

/**
 * Calculates a 90 day period before the selected date range
 */
const dateRangePrevious90Days = (state) => {

    const startDate = moment(state.dateRange.endDate)
            .subtract(90, 'days')
            .toDate();

    const endDate = state.dateRange.endDate;

    return { startDate, endDate };
};

/**
 * Calculates the previous month before the selected date range
 */
const dateRangePreviousMonth = (state) => {

    const startDate = moment(state.dateRange.startDate)
        .subtract(1, 'month')
        .startOf('month')
        .toDate();

    const endDate = moment(state.dateRange.startDate)
        .subtract(1, 'month')
        .endOf('month')
        .toDate();

    return { startDate, endDate };
};

const dateRangeComparison = (state) => {
    return getDefaultDateRangeComparison(state.dateRange);
};

export default {
    storage,
    spendOverride,
    hideSpend,
    daysBetweenDateRange,
    dateRangePrevious90Days,
    dateRangePreviousMonth,
    dateRangeComparison,
};
