/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M5.839 9.036L.333 3.53 3.448.414 8.954 5.92 14.504.37l3.115 3.116-5.549 5.549 5.506 5.506-3.116 3.115-5.506-5.506-5.477 5.478L.36 14.513z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
