import formatInsightsData from '@/apis/facebook/formatInsightsData';
import moment from 'moment';

const getVehicleCondition = (vehicle) => {
    if (vehicle.certified) {
        return 'CPO';
    }
    return vehicle.used ? 'Used' : 'New';
};

function formatInventoryData(insights, inventory, config) {
    return insights.reduce((allInsights, insights) => {
        const vehicleId = insights.product_id.split(',')?.[0];
        const vehicle = inventory.find(vehicle => vehicle._source.vehicle_id == vehicleId);

        if (!vehicle) {
            return allInsights;
        }

        const insightsFormatted = formatInsightsData(insights, {
            spendOverride: config.spendOverride,
            attribution: config.attribution,
            attributionWindows: config.attributionWindows,
            offlineEventTypes: [] // disable
        });

        const source = vehicle._source;

        const customFields = {
            image: vehicle.fields.image[0],
            condition: getVehicleCondition(source),
            status: source.deleted_at ? 'Sold' : 'For Sale',
            days_seen: moment(config.dateRange.endDate).diff(source.created_at, 'days'),
            content_views: insightsFormatted.content_views.value + insightsFormatted.on_site_content_views.value
        };

        allInsights.push({
            ...source,
            ...insightsFormatted,
            ...customFields
        });

        return allInsights;
    }, []);
}

export default formatInventoryData;