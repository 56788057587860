<template>
    <div class="asset-alerts">
        <div
            v-for="(alert, a) in formattedAlerts"
            :key="a"
            class="alert">
            <p
                class="status"
                :class="alert.status">
                {{ alert.status }}
            </p>
            <div class="message">
                <p
                    class="time-ago"
                    :class="alert.status">
                    {{ alert.time_ago }}
                </p>
                <remote-content
                    :value="alert.message"
                    :dealer="currentDealer"
                    :ticket-data="{
                        type: 'Other',
                        subject: alert.type
                    }" />
            </div>
            <ul class="actions">
                <li
                    v-for="(action,i) in alert.actions"
                    :key="i"
                    class="action">
                    <a
                        v-if="action.type == 'link'"
                        :href="action.url">{{ action.title }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import RemoteContent from '@/components/globals/RemoteContent';
import moment from 'moment';

export default {
    name: 'AssetAlerts',
    components: {
        RemoteContent
    },
    props: {
        monitorId: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            dealerAlerts: (state) => state.monitoring.dealerAlerts
        }),
        formattedAlerts() {
                return this.dealerAlerts
                    .filter(alert => alert.level == 'critical' && alert.alert_type.monitor_id == this.monitorId)
                    .map( alert => {
                        return {
                            time_ago: moment.utc(alert.created_at.date).fromNow(),
                            status: alert.level,
                            type_id: alert.alert_type_id,
                            message: alert.message,
                            actions: alert.actions,
                            type: alert.alert_type?.display_name || ''
                        };
                    });
            }
    }
};
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}
.alert {
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #F3F5F6;
    border: 1px solid lighten($gray, 30%);
    padding: 5px;
}
.status {
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 5px;
    color: $white;
    padding: 2px 10px;
    margin: 0 15px;
    &.critical {
        background: $carnation;
    }

}
.message {
    width: 400px;
    margin: 0 15px;
    .time-ago {
        color: $blue-primary;
        &.critical {
            color: $carnation;
        }
    }
    font-size: 12px;
}
.actions {
    list-style: none;
    font-size: 14px;
    .action {
        margin: 5px 0;
    }
}

</style>