const isAdProduct = (product) => {
    const validAdProducts = [
        1, // Inventory Ads VDP 500
        2, // Inventory Ads VDP 1000
        3, // Inventory Ads VDP 2500
        4, // Inventory Ads Non-Guaranteed
        5, // Full Facebook
        7, // Plumbing Facebook
        8, // Plumbing Plus Facebook
        9, // Plumbing Turnkey Facebook
        11, // Plumbing Lite Facebook
        12, // Plumbing Pro Facebook
        13,  // Ultimate
        14, // Service
        15, // New Car
        18, // Custom
        21, // Facebook Advertising
        22, // Facebook Advertising+
        27, // Tiktok Advertising+
        28, // Snapchat Advertising+
        29, // Pinterest Advertising+
        30, // Facebook AdvertisingLITE
        31, // Tiktok AdvertisingLITE
        32, // Snapchat AdvertisingLITE
        33, // Pinterest AdvertisingLITE
        34, // Tiktok Advertising
        35, // Snapchat Advertising
        36, // Pinterest Advertising
    ];

    let productId = null;
    if (Number.isInteger(product)) {
        productId = product;
    } else {
        productId = product.id;
    }

    // If the given product ID is in the array it's an ad product
    if (validAdProducts.includes(productId)) {
        return true;
    }

    // Otherwise it's not
    return false;
};

export default isAdProduct;
