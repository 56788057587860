import HTTP from '@/http';
import { orderBy } from 'lodash';

const getDealerCampaigns = async(dealerId) => {

    const fields = ['name','effective_status','daily_budget'];
    const url = `/dealers/${dealerId}/graph-api/ad-account/campaigns?fields=${fields.join(',')}`;
    const response = await (new HTTP()).get(url);

    // Add in a friendly label
    const campaigns = response.data.map(campaign => {
        campaign.label = `${campaign.name} (${campaign.effective_status})`;
        return campaign;
    });

    return orderBy(campaigns, ['effective_status', 'campaign_name']);
};

export default getDealerCampaigns;