/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'target-people-icon': {
    width: 46,
    height: 47,
    viewBox: '0 0 46 47',
    data: '<g _stroke="#909FA7" stroke-width="2" _fill="none" fill-rule="evenodd"><path pid="0" d="M41 23.5c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18zM0 23.5h9M37 23.5h9M23 .5v9M23 37.5v9"/><path pid="1" d="M28 31.5v-4c0-1.14-2.75-2.993-4.998-2.993S18 26.36 18 27.5v4" stroke-linecap="round"/><path pid="2" d="M23 24.5c-1.804 0-3-1.535-3-3.429v-1.144c0-1.894 1.196-3.429 3-3.429 1.803 0 3 1.535 3 3.429v1.144c0 1.894-1.196 3.429-3 3.429z"/><path pid="3" d="M35 30.5v-2c0-1.14-2.75-2.993-4.998-2.993M28.727 17.787a2.848 2.848 0 011.273-.29c1.804 0 3 1.528 3 3.412v1.138c0 1.884-1.196 3.453-3 3.453M10.999 30.5v-2c0-1.14 2.75-2.993 4.998-2.993M17.317 17.81A2.848 2.848 0 0016 17.498c-1.804 0-3 1.527-3 3.412v1.137c0 1.884 1.196 3.453 3 3.453" stroke-linecap="round"/></g>'
  }
})
