
const ASSET_RESOURCE_PATH = '/assets';

const FIND_ASSETS_PATH = '/assets/search';

const UPLOAD_FILE_PATH = '/files';

const CREATE_ASSETS_PATH = '/assets';

const FIND_CATEGORIES_PATH = '/categories';

export {
    ASSET_RESOURCE_PATH,
    FIND_ASSETS_PATH,
    UPLOAD_FILE_PATH,
    CREATE_ASSETS_PATH,
    FIND_CATEGORIES_PATH
};