import { BaseAsset } from './BaseAsset';

export class ShapeAsset extends BaseAsset {
    content;
    fillColor;
    strokeColor;
    strokeWidth;
    file;
    constructor({ id, type, name, thumbnail }) {
        super(id, type, name, thumbnail);
    }
}