export const addAlpha = (color, opacity) => {
    // coerce values so it is between 0 and 1
    const _opacity = Math.round(
        Math.min(
            Math.max(opacity || 1, 0),
            1
        ) * 255
    );
    return color + _opacity.toString(16).toUpperCase();
}

export const getBrightness = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
}

export const hexIsLight = (color) => {
    const brightness = getBrightness(color);
    return brightness > 155;
}

export default {
    green: '#a2cf75',
    greenLight: '#7ED321',
    greenDark: '#41e1bf',
    red: '#f6685f',
    yellow: '#f8c41c',
    orange: '#ff914f',
    blue: '#51c1f7',
    lightBlue: '#E3EEFB', // @todo drop this
    blueLight: '#E3EEFB',
    blueDark: '#00A2EA',
    gray: '#8f9ea6',
    grayDark: '#4e555b',
    grayLight: '#c7Ced2',
    purple: '#9E31FE',
    purpleLight: '#BD10E0',
    purpleChartLine: '#E350B4'
};
