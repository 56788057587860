/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'image': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M4.46 6.06c1.31 0 2.374-1.02 2.374-2.28 0-1.26-1.063-2.28-2.374-2.28-1.311 0-2.374 1.02-2.374 2.28 0 1.26 1.063 2.28 2.374 2.28zm9.325.372a.797.797 0 00-.648-.371.803.803 0 00-.678.32l-4.043 5.487-2.475-2.519a.81.81 0 00-1.239.107L.618 15.484a.778.778 0 00-.019.792c.139.248.403.41.696.424h17.407a.843.843 0 00.695-.418.778.778 0 00-.008-.787l-5.604-9.063z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
