/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indicator-add': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M9 0a9 9 0 110 18A9 9 0 019 0zm1.103 4H7.897v3.924H4v2.203h3.897V14h2.206v-3.873H14V7.924h-3.897V4z" _fill="#00BCFF" fill-rule="evenodd"/>'
  }
})
