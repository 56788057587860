<template>
    <div class="layered-designer-footer">
        <div class="layered-designer-footer-menu">
            <div v-if="!isEditing">
                <styled-button
                    small
                    class="layered-designer-save-button"
                    @click="handleSaveAndContinueClick">
                    Save & Exit
                </styled-button>
            </div>
            <div v-if="!isEditing">
                <styled-button
                    small
                    class="layered-designer-save-button"
                    @click="handleSaveAndDeploy">
                    Save & Deploy
                </styled-button>
            </div>
            <div v-if="isEditing">
                <styled-button
                    small
                    class="layered-designer-save-button"
                    @click="handleEditDesign">
                    Save & Exit
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { generateAndDownloadCardPNG, downloadJsonAsFile, generateDataURLFromElementAsBlob } from '@/components/creative-studio/layered-designer/utils/static-assets-generation';
    import { LAYERED_DESIGNER_MAIN_CANVAS_ID } from '@/components/creative-studio/common/constants';
    import { LayeredDesignerServiceFactory } from '@/creative-studio/domain/layered-designer/services/LayeredDesignerServiceFactory';
    import {  LAYERED_DESIGNER_MODE } from '@/creative-studio/domain/layered-designer/models/Layer';
    import { setParentAssetAsAd, setSelectedPlatformsForAd, setChildAssetsAsAd, setEditedAssetsForAd } from '@/components/creative-studio/layered-designer/utils/asset-to-ad-helper';
    import {
    getAllAssetsForAPlatform,
    getAllAssetsForAd
    } from '@/components/ad-studio/store/constants';
    import StyledButton from '@/components/globals/StyledButton';
    import { mapGetters, mapActions, mapState } from 'vuex';
    import EventBus from '@/event-bus';

    export default {
        components: {
            StyledButton
        },
        data() {
            return {
                parentId: null,
                uploadedAssets: [],
                LAYERED_DESIGNER_MODE
            };
        },
        computed: {
            ...mapGetters([
                'currentCard',
                'layeredDesignerCards',
                'fontEmbedCSS',
                'layeredDesignerMode'
            ]),
            ...mapState({
                dealer: state => state.dealer.currentDealer,
                user: state => state.user.user,
                agency: state => state.agency.currentAgency,
                ads: (state) => state.adStudio.ads,
                adIndex: (state) => state.adStudio.adIndex,
        }),
            isCurrentCardSelected() {
                return this.currentCard !== null;
            },
            isEditing() {
                return this.layeredDesignerMode === LAYERED_DESIGNER_MODE.EDIT_DESIGN;
            },
            currentParentAssetId() {
                return this.ads[this.adIndex]?.configuration?.all_platforms?.creatives[0]?.assets[0].id;
            },
            currentAdAssets() {
                return  this.customizedPerPlatform ? getAllAssetsForAPlatform(this.ads[this.adIndex], this.activePlatform) : getAllAssetsForAd(this.ads[this.adIndex]);
            },
            customizedPerPlatform: {
                get() {
                    return this.ads[this.adIndex]?.configuration.customized_per_platform;
                },
                set(value) {
                    // Check to see if the value trying to be set to true
                    // It should never be going from true to false
                    if (value) {
                        this.$store.commit('adStudio/customizePerPlatform', { adIndex: this.adIndex });
                        // select the first channel as the one being customized
                        this.setActivePlatform(this.selectedAdPlatforms[0]);
                    }
                }
            },
            activePlatform: {
                get() {
                    return this.ads[this.adIndex]?.active_platform;
                },
                set(platform) {
                    this.$store.commit('adStudio/changeActivePlatform', {
                        adIndex: this.adIndex,
                        platform
                    });
                }
        },
        },
        methods: {
            ...mapActions([
               'getExportableLayeredDesignerData',
               'updateCurrentCardDimensions',
               'setLayeredDesignerLoadingStatus',
               'setCurrentCard',
               'setLayeredDesignerActiveStatus',
               'resetLayeredDesigner',
               'setFocusedLayer',
               'setLayeredDesignerWorkspaceOverflow'
            ]),
            exit() {
                this.setLayeredDesignerActiveStatus({ status: false, mode: null });
                this.resetLayeredDesigner();
            },
            async handleSaveAndContinueClick() {
                try {
                    this.setFocusedLayer(null);
                    this.setLayeredDesignerWorkspaceOverflow('hidden');
                    this.setLayeredDesignerLoadingStatus(true);
                    if(this.layeredDesignerMode === LAYERED_DESIGNER_MODE.NEW_DESIGN) {
                        this.uploadedAssets = [];
                        await this.saveNewDesign();
                    } else {
                        await this.saveLayeredDesigner();
                        await this.exportJsonData();
                    }

                } catch(error) {
                    console.log('Error generating static assets', error);
                } finally {
                    this.setLayeredDesignerLoadingStatus(false);
                    EventBus.$emit('new-layered-design-saved');
                    this.resetLayeredDesigner();
                }
            },
            async handleSaveAndDeploy() {
                try {
                    this.setFocusedLayer(null);
                    this.setLayeredDesignerWorkspaceOverflow('hidden');
                    this.setLayeredDesignerLoadingStatus(true);
                    this.uploadedAssets = [];
                    await this.saveNewDesign();
                    await this.createAdFromAsset();
                } catch(error) {
                    console.log('Error generating static assets', error);
                } finally {
                    this.setLayeredDesignerLoadingStatus(false);
                    this.setLayeredDesignerActiveStatus({ status: false, mode: null });
                    this.resetLayeredDesigner();
                    EventBus.$emit('open-ad-studio', { editMode: true }, { isOpenedFromLayeredDesigner: true });
                }
            },
            async handleEditDesign() {
                try {
                    this.setFocusedLayer(null);
                    this.setLayeredDesignerWorkspaceOverflow('hidden');
                    this.setLayeredDesignerLoadingStatus(true);
                    await this.editDesign();
                } catch(error) {
                    console.log('Error editing static assets', error);
                } finally {
                    this.setLayeredDesignerLoadingStatus(false);
                    this.setLayeredDesignerActiveStatus({ status: false, mode: null });
                    this.resetLayeredDesigner();
                }
            },
            async editDesign() {
                this.uploadedAssets = [];

                const layeredDesignerApiService = LayeredDesignerServiceFactory.createApiLayeredDesignerService();
                const cardSizes = await layeredDesignerApiService.getCardSizes();
                const elementId = LAYERED_DESIGNER_MAIN_CANVAS_ID;
                const fontEmbedCSS = this.fontEmbedCSS;

                this.parentId = this.currentParentAssetId;

                for ( const card of this.layeredDesignerCards ) {

                    this.setCurrentCard(card);

                    for ( const  { id, height, width, aspectRatio, linkedStatus } of cardSizes  ) {

                        const container = document.getElementById(elementId);
                            // set width and height based on input
                        container.style.width = `${width}px`;
                        container.style.height = `${height}px`;
                        this.updateCurrentCardDimensions({ id, height, width });
                        const blobAsset = await generateDataURLFromElementAsBlob(elementId, 'Main asset', { height: card.dimensions.height, width: card.dimensions.width, fontEmbedCSS });
                        await this.uploadAsset(blobAsset, card, false, { height, width, aspectRatio, linkedStatus }, { is_original_asset: false });

                    }
                }
                setEditedAssetsForAd({
                        id:  this.currentParentAssetId,
                        editedAssets: this.uploadedAssets
                    });

                setChildAssetsAsAd(this.adIndex,  this.uploadedAssets);
            },
            async saveLayeredDesigner()  {
                if (!this.isCurrentCardSelected) {
                    return;
                }
                const elementId = LAYERED_DESIGNER_MAIN_CANVAS_ID;

                const layeredDesignerApiService = LayeredDesignerServiceFactory.createApiLayeredDesignerService();
                const cardSizes = await layeredDesignerApiService.getCardSizes();
                const originalCard = this.currentCard;
                const fontEmbedCSS = this.fontEmbedCSS;

                for ( const card of this.layeredDesignerCards ) {
                    this.setCurrentCard(card);
                    const originalSize = this.currentCard.dimensions;

                    for ( const { id, height, width } of cardSizes  ) {

                        this.updateCurrentCardDimensions({ id, height, width });
                        const options = { height, width, fontEmbedCSS };
                        const sizeString = `${width}x${height}`;
                        const cardAssetName = `${this.currentCard.name} - ${sizeString}`;
                        const success = await generateAndDownloadCardPNG(elementId, cardAssetName, options);
                        // Add delay between the multiple downloads to prevent errors
                        // in some browsers (safari) where only some files get downloaded
                        await this.sleep(500);
                        // TODO: Handle proper error and success messages
                        if (success) {
                            console.log('Image generated successfully for the size' + sizeString);
                        } else {
                            console.error('Error generating image for the size' + sizeString);
                        }
                    }
                    // Set the back the original size
                    const { id, height, width } = originalSize;
                    this.updateCurrentCardDimensions({ id, height, width });
                }

                // Set the original card
                this.setCurrentCard(originalCard);
            },
            async exportJsonData() {
                const data = await this.getExportableLayeredDesignerData();

                downloadJsonAsFile(data);
                console.log('Json file generated successfully');
            },
            async sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async saveNewDesign()  {
                this.parentId = null;
                const elementId = LAYERED_DESIGNER_MAIN_CANVAS_ID;

                const layeredDesignerApiService = LayeredDesignerServiceFactory.createApiLayeredDesignerService();
                const cardSizes = await layeredDesignerApiService.getCardSizes();
                const fontEmbedCSS = this.fontEmbedCSS;

                for ( const card of this.layeredDesignerCards ) {
                    this.parentId = null;
                    this.setCurrentCard(card);
                    // upload parent asset
                    const blobAsset = await generateDataURLFromElementAsBlob(elementId, 'Main asset', { height: card.dimensions.height, width: card.dimensions.width, fontEmbedCSS });
                    const isMainCard = card.dimensions.linkedStatus === 'main';
                    await this.uploadAsset(blobAsset, card, { isParentCard: isMainCard }, {
                        height: card.dimensions.height,
                        width: card.dimensions.width,
                        aspectRatio: card.dimensions.aspectRatio,
                        linkedStatus: card.dimensions.linkedStatus
                    });

                    for ( const  { id, height, width, aspectRatio, linkedStatus } of cardSizes  ) {

                            const options = { height, width, fontEmbedCSS };
                            const cardAssetName = `${this.currentCard.name}`;
                            const container = document.getElementById(elementId);
                            // set width and height based on input
                            container.style.width = `${width}px`;
                            container.style.height = `${height}px`;
                            this.updateCurrentCardDimensions({ id, height, width });

                            const blobAsset = await generateDataURLFromElementAsBlob(elementId, cardAssetName, options);
                            await this.uploadAsset(blobAsset, card, { isParentCard: false }, { height, width, aspectRatio, linkedStatus });
                        }

                        const container = document.getElementById(elementId);
                        container.style.width = '1080px';
                        container.style.height = '1080px';
                }
            },
            async uploadAsset(binaryFile, card, { isParentCard = false } = {}, { height, width, aspectRatio }, { is_original_asset = true } = {}) {

                const cardName = `${card.name || 'Design'}`;
                const file = new File([binaryFile], cardName + '.png', { type: 'image/png' });

                let formData = new FormData();
                formData.append('file', file);
                formData.append('agency_id', this.agency.id);
                formData.append('width', Number(width));
                formData.append('height', Number(height));
                formData.append('aspect_ratio', aspectRatio);
                formData.append('display_name', cardName);
                // form append configuration as object
                formData.append('configuration', JSON.stringify({ design: card, is_original_asset }));

                if(isParentCard) {

                    formData.append('is_design', true);
                    const originalMainAsset = await this.$http.axios.post('/assets/upload', formData);
                    this.parentId = originalMainAsset.data.id;
                    this.uploadedAssets.push(originalMainAsset.data);

                } else {
                    if(this.parentId) {
                        formData.append('parent_id', this.parentId);
                    }
                    // re upload assets including original copy with the parent id
                    const childAsset = await this.$http.axios.post('/assets/upload', formData);

                    this.uploadedAssets.push(childAsset.data);

                }
            },
            async createAdFromAsset() {

                const originalAssets = this.uploadedAssets.filter((asset) => !asset.parent_id);

                originalAssets.forEach((originalAsset, index) => {
                    const childAssets = this.uploadedAssets.filter((asset) => Number(asset.parent_id) === Number(originalAsset.id));
                    setParentAssetAsAd( this.agency.id, originalAsset);
                    setSelectedPlatformsForAd(index);
                    setChildAssetsAsAd(index, childAssets);
                });

            },
        },

    };
</script>

<style lang="scss">
.layered-designer-footer {
    width: 100%;
    position: fixed;
    width: calc(100% - (#{$layered-designer-modal-margin} * 2));
    bottom: $layered-designer-modal-margin;
    border-bottom-right-radius: 5px;
    z-index: 100;
}

.layered-designer-footer-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    min-height: 30px;
    background-color: #EDF2F5;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #B4B9BB;
    padding: 10px 30px 10px 0;
}

.layered-designer-footer-option {
    color: $blue-bg;
    text-decoration: underline;
    cursor: pointer;
}

.layered-designer-save-button {
    font-size: 14px;
    font-weight: 700;
}
</style>