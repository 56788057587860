/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tool': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11.272 0c.21 0 .379.168.421.379l.19 1.474c.02.231.189.4.42.484a7.49 7.49 0 011.472.61c.21.106.463.085.652-.063l1.157-.905c.168-.126.4-.105.567.042l1.788 1.81c.168.148.168.4.042.569l-.904 1.158a.571.571 0 00-.042.653c.252.463.441.968.61 1.473.063.21.252.38.483.421l1.472.19c.21.042.379.21.4.42v2.548c0 .21-.168.4-.379.421l-1.472.19a.56.56 0 00-.483.42c-.042.106-.063.19-.106.274l-2.376-2.21c.021-.105.021-.232.021-.358a5.217 5.217 0 00-5.216-5.221A5.222 5.222 0 004.753 10a5.226 5.226 0 005.236 5.242c.127 0 .232 0 .358-.02l2.23 2.336c-.085.042-.169.063-.274.105a.589.589 0 00-.42.484l-.19 1.474a.43.43 0 01-.42.379H8.727a.43.43 0 01-.421-.379l-.19-1.474a.589.589 0 00-.42-.484 7.173 7.173 0 01-1.493-.61c-.21-.106-.442-.106-.63.042L4.394 18c-.168.126-.4.105-.567-.042l-1.788-1.81c-.168-.148-.168-.4-.042-.57l.904-1.157a.536.536 0 00.042-.632 9.28 9.28 0 01-.61-1.494.622.622 0 00-.483-.421l-1.472-.19a.415.415 0 01-.379-.42V8.715c0-.21.168-.38.379-.421l1.472-.19a.56.56 0 00.483-.42c.169-.506.358-1.011.61-1.474.105-.211.105-.464-.063-.632L1.977 4.42c-.126-.168-.126-.421.042-.568l1.788-1.81A.423.423 0 014.374 2l1.178.905c.168.148.42.169.631.042a7.674 7.674 0 011.493-.61.589.589 0 00.42-.484l.19-1.474A.43.43 0 018.706 0zm-1.85 6.695a3.376 3.376 0 013.407 1.516c.546.863.652 1.936.336 2.884l4.48 4.273a1.66 1.66 0 01.042 2.4c-.652.674-1.746.653-2.398-.042L11.041 13.2a3.35 3.35 0 01-3.449-.8c-.8-.8-1.094-1.916-.925-2.947a.406.406 0 01.673-.21l.799.8a1.307 1.307 0 001.872 0 1.31 1.31 0 000-1.875l-.8-.8c-.21-.231-.105-.631.21-.673z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
