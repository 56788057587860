/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-circle': {
    width: 600,
    height: 600,
    viewBox: '0 0 600 600',
    data: '<defs><clipPath id="svgicon_user-circle_a"><circle pid="0" cx="300" cy="300" r="250"/></clipPath></defs><circle pid="1" cx="300" cy="300" r="280" _fill="#8F9EA6"/><circle pid="2" cx="300" cy="230" r="100"/><circle pid="3" cx="300" cy="550" r="190" clip-path="url(#svgicon_user-circle_a)"/>'
  }
})
