<template>
    <div class="description-container">
        <ul class="list">
            <li class="list-item">
                Name: <span class="link">{{ asset.name | truncate(20, '...') }}</span>
            </li>
            <li class="list-item">
                File name: {{ asset.name | truncate(20, '...') }}
            </li>

            <li class="list-item delete-item">
                <span class="text-danger"> Delete Permanently </span>
            </li>
        </ul>
        <ul class="list list-metadata">
            <li class="list-item">
                <!-- Uploaded on: {{ asset.createdAt.date | fullDate }} -->
            </li>
            <li class="list-item">
                <!-- Uploaded by: <span class="link">{{ asset.user.name }} </span> -->
            </li>
            <li class="list-item">
                File type: {{ assetFileType }}
            </li>
            <li class="list-item">
                File size: {{ asset.file.size | formatBytes }}
            </li>
            <li class="list-item">
                Dimensions: {{ asset.file.width || 'unknown' }} by {{ asset.file.height || 'unknown' }} pixels
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    assetFileType() {
        const url = this.asset.thumbnail;
        const extension = url.split('.').pop();
      return extension;
    },
  }
};
</script>

<style lang="scss" scoped>
.description-container {
  padding: 26px 0px 0px 20px;
  font-size: 13px;
}

.link {
  color: $blue-primary;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
.list {
  list-style-type: none;
}
.list-item {
  margin-left: -15px;
  margin-top: 5px;
}
.list-metadata {
  margin-top: 20px;
  font-size: 11px;
}
.text-danger {
  color: #d0021b;
  text-decoration: underline;
  cursor: pointer;
}
.delete-item {
    margin-top: 10px;
    margin-bottom: 18px;
}
</style>
