/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building': {
    width: 18,
    height: 20,
    viewBox: '0 0 18 20',
    data: '<path pid="0" _fill="#444" fill-rule="evenodd" d="M16.364 18.333V0H1.636v18.333H0V20h5.727v-3.333h6.546V20H18v-1.667h-1.636zm-9.818-4.166H4.909V12.5h1.637v1.667zm0-3.334H4.909V9.167h1.637v1.666zm0-3.333H4.909V5.833h1.637V7.5zm0-3.333H4.909V2.5h1.637v1.667zm3.272 10H8.182V12.5h1.636v1.667zm0-3.334H8.182V9.167h1.636v1.666zm0-3.333H8.182V5.833h1.636V7.5zm0-3.333H8.182V2.5h1.636v1.667zm3.273 10h-1.636V12.5h1.636v1.667zm0-3.334h-1.636V9.167h1.636v1.666zm0-3.333h-1.636V5.833h1.636V7.5zm0-3.333h-1.636V2.5h1.636v1.667z"/>'
  }
})
