/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indicator-question': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M9 0a9 9 0 110 18A9 9 0 019 0zm.165 11.553c-.71 0-.71 0-1.077.342-.365.341-.365.341-.365.97 0 .63 0 .63.382.968.383.338.383.338 1.06.338.656 0 .656 0 1.036-.342s.38-.342.38-.964c0-.622 0-.622-.367-.967-.365-.345-.365-.345-1.049-.345zm.39-7.69c-1.698 0-1.698 0-3.27.815L6 4.833l.95 1.86c1.381-.732 1.381-.732 2.413-.732.417 0 .417 0 .677.167.26.168.26.168.26.455 0 .362 0 .362-.243.643-.243.28-.243.28-.796.628-.698.438-.698.438-.971.9-.228.384-.228.384-.266.913l-.007.218v.588h2.098v-.349c0-.294 0-.294.181-.51.182-.215.182-.215.9-.68.847-.54.847-.54 1.233-1.144.386-.605.386-.605.386-1.433 0-1.155 0-1.155-.868-1.825-.868-.67-.868-.67-2.392-.67z" _fill="#00BCFF" fill-rule="evenodd"/>'
  }
})
