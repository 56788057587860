<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20">
        <path
            :d="iconPath"
            fill="#8F9EA6" />
    </svg>
</template>
<script>
export default {
    name: 'PhotoIcon',
    data() {
        return {
            iconPath: 'M4.168 6.997a2.499 2.499 0 1 0 0-4.997 2.499 2.499 0 0 0 0 4.997Zm9.817.408a.833.833 0 0 0-1.396-.056l-4.256 6.013-2.606-2.76a.833.833 0 0 0-1.304.117l-4.3 6.606a.883.883 0 0 0 .714 1.333H19.16a.883.883 0 0 0 .723-1.321l-5.898-9.932Z'
        };
    }
};
</script>