/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17 4h-2.46l-1.11-1.66A3 3 0 0010.93 1H9.07a3 3 0 00-2.5 1.34L5.46 4H3a3 3 0 00-3 3v8a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zm-7 11a4 4 0 110-8 4 4 0 010 8zm2-4a2 2 0 11-4 0 2 2 0 014 0z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
