/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quick-links': {
    width: 24,
    height: 20,
    viewBox: '0 0 24 20',
    data: '<path pid="0" d="M10.4.5C15.705.5 20 4.75 20 10s-4.32 9.5-9.6 9.5a9.55 9.55 0 01-6.745-2.775l-.078-.098a.839.839 0 01-.1-.802c.026-.1.102-.2.178-.275l1.414-1.4a.845.845 0 011.188 0 .824.824 0 010 1.175l-.783.775c1.187.925 2.602 1.525 4.118 1.675v-1.1c0-.45.379-.825.833-.825.455 0 .834.375.834.825v1.1a7.876 7.876 0 004.118-1.725l-.783-.775a.824.824 0 010-1.175.845.845 0 011.187 0l.783.775a7.714 7.714 0 001.718-4.05h-1.111a.837.837 0 01-.834-.825c0-.45.379-.825.834-.825h1.111A7.71 7.71 0 0016.564 5.1l-.783.775a.825.825 0 01-.606.25.825.825 0 01-.607-.25.824.824 0 010-1.175l.809-.75a8.052 8.052 0 00-4.118-1.7v1.1c0 .45-.379.825-.834.825a.837.837 0 01-.833-.825v-1.1a7.78 7.78 0 00-4.118 1.675l.783.775a.824.824 0 010 1.175.825.825 0 01-.606.25.825.825 0 01-.607-.25l-1.415-1.4a.824.824 0 010-1.175l.241-.23A9.56 9.56 0 0110.4.5zM2.442 12.35c.455 0 .834.375.834.825 0 .45-.38.825-.834.825h-3.688a.837.837 0 01-.834-.825c0-.45.379-.825.834-.825zM10.4 5.275c.48 0 .859.375.859.825v3.075h2.501c.455 0 .834.375.834.825 0 .45-.38.825-.834.825H10.4A.837.837 0 019.566 10V6.1c0-.45.38-.825.834-.825zm-7.958 3.9c.455 0 .834.375.834.825 0 .45-.38.825-.834.825h-5.608A.837.837 0 01-4 10c0-.45.379-.825.834-.825zm0-3.2c.455 0 .834.375.834.825 0 .45-.38.825-.834.825h-3.688A.837.837 0 01-2.08 6.8c0-.45.379-.825.834-.825z" _fill="none" fill-rule="evenodd"/>'
  }
})
