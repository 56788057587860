export const customClickOutsideDirective = {
    bind(element, binding) {
        element.clickOutsideEvent = function(event) {
            //  Check that click was outside the el and his children
            if (!(element === event.target || element.contains(event.target))) {
                // Call the function with the parameters;
                binding.value.handler(binding.value.identifier, event);
            }
        };
        // Use mousedown event instead of click to work inside the modals
        document.body.addEventListener('mousedown', element.clickOutsideEvent);
    },
    unbind(element) {
        document.body.removeEventListener('mousedown', element.clickOutsideEvent);
    }
};
