/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flip-vertical': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M15.78 9.167H6a1 1 0 01-1-1V1.838a1 1 0 011.543-.84l9.78 6.33a1 1 0 01-.543 1.839z"/><path pid="1" d="M15.78 10.833H6a1 1 0 00-1 1v6.329a1 1 0 001.543.84l9.78-6.33a1 1 0 00-.543-1.839z" fill-opacity=".494"/></g>'
  }
})
