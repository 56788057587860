<template>
    <styled-slideout
        class="alert-slideout"
        :value="value"
        :tabs="tabs"
        label="Alerts"
        :width="700"
        :active-tab="activeTab"
        @setActiveTab="activeTab = $event"
        @input="$emit('input', $event)">
        <template #action-buttons>
            <action-button
                icon="details"
                @click="activeTabObject.showDetails">
                Show Details
            </action-button>
        </template>
        <template #alerts>
            <div class="alerts current">
                <p class="tab-title">
                    {{ activeTab }}
                </p>
                <p class="current-account">
                    Current Account: {{ currentDealer.name }}
                </p>
                <p
                    v-if="dealerAlerts.length === 0"
                    class="no-alerts">
                    This account does not currently have any alerts.
                </p>
                <template v-else>
                    <p class="status critical">
                        Critical
                    </p>
                    <div class="critical-dealer-alerts">
                        <div
                            v-for="alert in formattedDealerAlerts.critical"
                            :key="alert.id"
                            class="alert-item">
                            <div class="details">
                                <p class="time-ago">
                                    {{ alert.time_ago }}
                                </p>
                                <p class="message">
                                    <remote-content
                                        :value="alert.message"
                                        :dealer="currentDealer"
                                        :ticket-data="{
                                            type: 'Other',
                                            subject: alert.type
                                        }" />
                                </p>
                            </div>
                            <ul class="actions">
                                <li
                                    v-for="(action,i) in alert.actions"
                                    :key="i"
                                    class="action-item">
                                    <remote-content-link
                                        :href="action.url"
                                        :dealer="currentDealer"
                                        :ticket-data="{
                                            type: 'Other',
                                            subject: alert.type
                                        }">
                                        {{ action.title }}
                                    </remote-content-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </div>
            <div class="alerts all">
                <p class="current-account">
                    All Other Accounts
                </p>
                <div
                    v-if="agencyAlertsLoading"
                    class="pa-4 text-xs-center">
                    <loader />
                </div>
                <p class="status critical">
                    Critical
                </p>
                <div class="critical-dealer-alerts">
                    <div
                        v-for="alert in formattedAllOtherAlerts.critical.slice(0,8)"
                        :key="alert.id"
                        class="alert-item">
                        <div class="details">
                            <p class="time-ago">
                                {{ alert.time_ago }}
                            </p>
                            <p class="dealer">
                                Dealer: <router-link
                                    :to="{ name: 'global-dashboard', params:{dealer_id: alert.dealer.id}}"
                                    @click.native="closeSlideout()">
                                    {{ alert.dealer.name }}
                                </router-link>
                            </p>
                            <p class="message">
                                <remote-content
                                    :value="alert.message"
                                    :dealer="alert.dealer"
                                    :ticket-data="{
                                        type: 'Other',
                                        subject: alert.type
                                    }" />
                            </p>
                        </div>
                        <ul class="actions">
                            <li
                                v-for="(action,i) in alert.actions"
                                :key="i"
                                class="action-item">
                                <remote-content-link
                                    :href="action.url"
                                    :dealer="alert.dealer"
                                    :ticket-data="{
                                        type: 'Other',
                                        subject: alert.type
                                    }">
                                    {{ action.title }}
                                </remote-content-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <template #onboardings>
            <div class="onboardings current">
                <p class="tab-title">
                    {{ activeTab }}
                </p>
                <p class="current-account">
                    Current Account: {{ currentDealer.name }}
                </p>
                <p
                    v-if="activeDealerOnboardings.length === 0"
                    class="no-onboardings">
                    This account does not currently have any active onboardings.
                </p>
                <div
                    v-for="onboarding in activeDealerOnboardings"
                    v-else
                    :key="onboarding.id"
                    class="onboarding-item">
                    <div class="description">
                        <p class="label">
                            {{ onboarding.label }}
                        </p>
                        <p class="updated">
                            Last updated
                            {{ $moment.utc(onboarding.updated_at.date).local().format('MM/DD/YY @ h:mma') }}
                            on step {{ onboarding.configuration.currentStep + 1 }}
                        </p>
                    </div>
                    <router-link
                        class="action"
                        :to="{ name: 'dealer-onboarding', params: { dealer_id: onboarding.dealer_id, onboarding_id: onboarding.id}}">
                        Continue
                    </router-link>
                </div>
            </div>
            <div class="onboardings all">
                <p class="current-account">
                    All Other Accounts
                </p>
                <div
                    v-for="onboarding in allOtherOnboardings.slice(0,10)"
                    :key="onboarding.id"
                    class="onboarding-item">
                    <div class="description">
                        <p class="label">
                            {{ onboarding.label }} for <router-link
                                :to="{ name: 'global-dashboard', params:{dealer_id: onboarding.dealer_id}}"
                                @click.native="closeSlideout()">
                                {{ onboarding.dealer.name }}
                            </router-link>
                        </p>
                        <p class="updated">
                            Last updated
                            {{ $moment.utc(onboarding.updated_at.date).local().format('MM/DD/YY @ h:mma') }}
                            on step {{ onboarding.configuration.currentStep + 1 }}
                        </p>
                    </div>
                    <router-link
                        class="action"
                        :to="{ name: 'dealer-onboarding', params: { dealer_id: onboarding.dealer_id, onboarding_id: onboarding.id}}">
                        Continue
                    </router-link>
                </div>
            </div>
        </template>
    </styled-slideout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import Loader from '@/components/globals/Loader';
import ActionButton from '@/components/globals/ActionButton';
import RemoteContent from '@/components/globals/RemoteContent';
import RemoteContentLink from '@/components/globals/RemoteContentLink';
import moment from 'moment';

export default {
    name: 'OpportunitySlideout',
    components: {
        StyledSlideout,
        ActionButton,
        RemoteContent,
        RemoteContentLink,
        Loader,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            tabs: [
                { label: 'Alerts', icon: 'indicator-warning', slot: 'alerts', showDetails: this.showAllAlerts },
            ],
            activeTab: 'alerts',
        };
    },
    computed: {
        ...mapGetters([
            'userIsClient',
            'activeOnboardings',
            'activeDealerOnboardings',
            'activeOnboardingsCount'
        ]),
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
            dealerAlerts: (state) => state.monitoring.dealerAlerts,
            agencyAlerts: (state) => state.monitoring.agencyAlerts,
            agencyAlertsLoading: (state) => state.monitoring.agencyAlertsLoading
        }),
        activeTabObject() {
            return this.tabs.find(tab => tab.slot == this.activeTab);
        },
        allOtherOnboardings() {
            return this.activeOnboardings.filter(allOb => {
                return !this.activeDealerOnboardings.some(dealerOb => dealerOb.id == allOb.id);
            });
        },
        allOtherAlerts() {
            return this.agencyAlerts.filter(alert => {
                return !this.dealerAlerts.some(dealerAlert => dealerAlert.id == alert.id);
            });
        },
        formattedDealerAlerts() {
            const formatted = this.dealerAlerts.map(alert => {
                return {
                    time_ago: moment.utc(alert.created_at.date).fromNow(),
                    status: alert.level,
                    message: alert.message,
                    actions: alert.actions,
                    type: alert.alert_type?.display_name || ''
                };
            });
            return {
                critical :formatted.filter(alert => alert.status === 'critical'),
                warning :formatted.filter(alert => alert.status === 'warning'),
            };
        },
        formattedAllOtherAlerts() {
            const formatted =  this.allOtherAlerts.map(alert => {
                return {
                    time_ago: moment.utc(alert.created_at.date).fromNow(),
                    status: alert.level,
                    message: alert.message,
                    actions: alert.actions,
                    dealer: alert.dealer,
                    type: alert.alert_type?.display_name || ''
                };
            });
            return {
                critical :formatted.filter(alert => alert.status === 'critical'),
                warning :formatted.filter(alert => alert.status === 'warning'),
            };
        }
    },
    created() {
        if (!this.userIsClient) {
            this.getAgencyAlerts({ agencyId: this.currentAgency.id });
            let tabs = [
                { label: 'Active Onboardings', icon: 'handshake', slot: 'onboardings', showDetails: this.showAllOnboardings },
            ];
            this.tabs.push(...tabs);
        }
    },
    methods: {
        ...mapActions([
            'getAgencyAlerts'
        ]),
        closeSlideout() {
            this.$emit('input', false);
        },
        showAllAlerts() {
            this.$emit('input', false);
            this.$router.push({
                name: 'communication-central',
                params: {
                    agency_id: this.currentAgency.id
                }
            });
        },
        showAllOnboardings() {
            this.$emit('input', false);
            this.$router.push({
                name: 'account-onboarding-status',
                params: {
                    agency_id: this.currentAgency.id
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>

.onboardings,
.alerts {
    padding: 20px 30px 35px;
    &.all {
        background: $gray-lightshade;
    }
    .status {
        &.critical {
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            padding: 2px 10px;
            text-transform: uppercase;
            color: $white;
            background-color: $carnation;
            border-radius: 5px;
        }
    }

}
.tab-title {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 2px 10px;
    text-transform: uppercase;
    color: $white;
    background-color: #00a2ea;
    border-radius: 5px;
}
.current-account {
    color: $blue-primary;
    font-size: 20px;
    font-weight: 600;
}
.no-onboardings,
.no-alerts {
    color: $gray-dark;
    font-style: italic;
}
.critical-dealer-alerts {
    border-top: 1px solid $gray;
    .alert-item {
        display: flex;
        flex-direction: row;
        padding: 15px;
        color: $gray-dark;
        font-size: 12px;
        .details {
            flex: 1;
            .time-ago {
                color: $carnation;
                margin: 2px 0;
            }
            .dealer {
                margin: 2px 0;
            }
        }
        .actions {
            padding-top: 10px;
            list-style: none;
            text-align: right;


            .action-item {
                white-space: nowrap;
                margin-bottom: 10px;
            }
        }
    }
}
.onboarding-item {
    padding: 10px 0;
    border-bottom: 1px solid $gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .description {
        font-size: 12px;
        .label {
            color: $gray-dark;
            margin: 0;
        }
        .updated {
            font-style: italic;
            color: $gray;
        }
    }
    .action {
        display: block;
        font-size: 12px;
    }
}

.see-more {
    display: block;
    margin-left: 30px;
}
</style>