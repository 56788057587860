import {
    SET_ASSET_LIBRARY_ACTIVE_STATUS,
    SET_ASSET_LIBRARY_ASSET_TYPE,
    SET_ASSET_LIBRARY_MODE
} from '@/store/mutation-types';

import { AssetLibraryMode } from '@/components/creative-studio/common/types';
import { AssetType } from '@/creative-studio/domain/assets/models/types';

export default {
    state: {
        isAssetLibraryActive: false,
        assetLibraryAssetType: AssetType.All,
        assetLibraryMode: AssetLibraryMode.Default
    },
    getters: {
        isAssetLibraryActive: (state) => state.isAssetLibraryActive,
        assetLibraryAssetType: (state) => state.assetLibraryAssetType,
        assetLibraryMode: (state) => state.assetLibraryMode,
    },
    actions: {
        setAssetLibraryActiveStatus({ commit }, status) {
            commit(SET_ASSET_LIBRARY_ACTIVE_STATUS, status);
        },
        setAssetLibraryAssetType({ commit }, type) {
            commit(SET_ASSET_LIBRARY_ASSET_TYPE, type);
        },
        setAssetLibraryMode({ commit }, status) {
            commit(SET_ASSET_LIBRARY_MODE, status);
        }
    },
    mutations: {
        [SET_ASSET_LIBRARY_ACTIVE_STATUS](state, status) {
            state.isAssetLibraryActive = status;
        },
        [SET_ASSET_LIBRARY_ASSET_TYPE](state, type) {
            state.assetLibraryAssetType = type;
        },
        [SET_ASSET_LIBRARY_MODE](state, mode) {
            state.assetLibraryMode = mode;
        }
    }
};
