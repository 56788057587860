/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calculator': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" d="M13.889 0a1.99 1.99 0 011.984 1.984v15.873a1.99 1.99 0 01-1.984 1.984H1.984A1.99 1.99 0 010 17.857V1.984A1.99 1.99 0 011.984 0zM4.96 15.873H2.976v1.984H4.96v-1.984zm3.969 0H6.944v1.984H8.93v-1.984zm3.968 0h-1.984v1.984h1.984v-1.984zM4.96 11.905H2.976v1.984H4.96v-1.984zm3.969 0H6.944v1.984H8.93v-1.984zm3.968 0h-1.984v1.984h1.984v-1.984zM4.96 7.937H2.976V9.92H4.96V7.937zm3.969 0H6.944V9.92H8.93V7.937zm3.968 0h-1.984V9.92h1.984V7.937zm.992-5.953H1.984v3.968H13.89V1.984zm-.992.992V4.96h-1.984V2.976h1.984z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
