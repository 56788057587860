/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'snapchat': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.714 15.246c-3.507-1.698-4.066-4.318-4.091-4.513-.03-.235-.064-.42.196-.66.25-.232 1.362-.92 1.67-1.135.51-.356.734-.712.569-1.15-.116-.302-.398-.416-.695-.416-.093 0-.187.01-.278.031-.56.122-1.104.403-1.419.478a.493.493 0 01-.115.016c-.168 0-.232-.075-.215-.277.039-.612.122-1.806.025-2.923-.132-1.535-.627-2.296-1.214-2.969C13.862 1.402 12.543 0 9.997 0 7.453 0 6.135 1.402 5.853 1.724c-.589.673-1.084 1.434-1.215 2.97-.097 1.115-.01 2.31.026 2.922.012.192-.047.277-.215.277a.495.495 0 01-.116-.016c-.314-.075-.858-.356-1.418-.478a1.29 1.29 0 00-.278-.03c-.298 0-.579.115-.694.416-.166.437.057.793.569 1.15.308.215 1.42.902 1.67 1.134.26.24.226.425.196.66-.025.197-.585 2.818-4.09 4.513-.206.1-.556.31.06.65.968.535 1.612.478 2.112.8.425.273.174.864.483 1.076.38.263 1.501-.018 2.95.46 1.216.4 1.954 1.532 4.108 1.532 2.155 0 2.915-1.136 4.108-1.532 1.447-.478 2.57-.197 2.951-.46.308-.212.058-.803.483-1.076.5-.322 1.143-.265 2.111-.8.615-.336.266-.547.06-.646" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
