/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'life-buoy': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<path pid="0" d="M14.742 7.788a.804.804 0 010-1.134l4.214-4.215a.805.805 0 011.134 0l.47.47a.804.804 0 010 1.135l-4.214 4.214a.805.805 0 01-1.134 0l-.47-.47zm0 7.424a.804.804 0 000 1.134l4.214 4.215a.805.805 0 001.134 0l.47-.47a.804.804 0 000-1.135l-4.214-4.214a.805.805 0 00-1.134 0l-.47.47zM8.258 7.788a.804.804 0 000-1.134L4.044 2.439a.805.805 0 00-1.134 0l-.47.47a.804.804 0 000 1.135l4.214 4.214a.805.805 0 001.134 0l.47-.47zm-.47 6.954a.805.805 0 00-1.134 0l-4.215 4.214a.804.804 0 000 1.134l.47.47a.805.805 0 001.135 0l4.214-4.214a.804.804 0 000-1.134l-.47-.47zm-1.512-.379a1.32 1.32 0 01.276-.206 5.59 5.59 0 01-.668-2.657c0-.962.242-1.866.668-2.658a1.32 1.32 0 01-.276-.206L2.274 4.635A11.448 11.448 0 000 11.5c0 2.573.846 4.95 2.274 6.865l4.002-4.001zm8.088-8.087l4.001-4.002A11.448 11.448 0 0011.5 0C8.926 0 6.551.846 4.635 2.274l4.002 4.002c.082.083.148.177.206.276a5.59 5.59 0 012.657-.668 5.59 5.59 0 012.657.668c.058-.099.124-.194.207-.276zm6.362-1.641l-4.002 4.002a1.32 1.32 0 01-.276.206 5.59 5.59 0 01.668 2.657c0 .962-.242 1.866-.668 2.658.099.057.194.123.276.206l4.002 4.001A11.448 11.448 0 0023 11.5c0-2.573-.846-4.95-2.274-6.865zm-6.362 12.09a1.342 1.342 0 01-.207-.277 5.59 5.59 0 01-2.657.668 5.59 5.59 0 01-2.657-.668 1.335 1.335 0 01-.207.276l-4.001 4.002A11.448 11.448 0 0011.5 23c2.574 0 4.949-.846 6.865-2.274l-4.001-4.002z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
