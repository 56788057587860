<template>
    <div
        class="text-center"
        @click.stop="handleOutOfCardClicked">
        <div
            class="card-canvas-wrapper"
            :style="canvasStyle">
            <div
                class="card-canvas-spaced-container">
                <card-canvas-control-bar
                    :card-name="cardTitle"
                    :name-editable="isCurrentCardSelected"
                    :card-linked-icon="cardLinkedIcon"
                    :card-aspect-ratio="cardAspectRatio" />

                <draggable-canvas
                    :id="cardCanvasId"
                    ref="draggable-canvas"
                    :focused-layer-id="focusedLayerId"
                    data-card-canvas
                    :resizable="isResizeActive"
                    :horizontal-guidelines="horizontalGuidelines"
                    :vertical-guidelines="verticalGuidelines"
                    :clippable="isClipActive"
                    :scalable="isScalable"
                    :keep-ratio="isKeepRatioActive"
                    :focused-layer-asset="focusedLayerAsset"
                    :focused-layer-alterations="focusedLayerAlterations"
                    :focused-layer-gradient="focusedLayerGradient"
                    :focused-layer-position="focusedLayerPosition"
                    :focused-layer-dimensions="focusedLayerDimensions"
                    :focused-layer-scale="focusedLayerScale"
                    :style="currentCardStyle"
                    :current-card-style="currentCardStyle"
                    class="draggable-canvas-main text-center mx-auto align-self-center"
                    @drag-start="handleDragStart">
                    <div
                        :style="{overflow: layeredDesignerOverflow}"
                        class="card-canvas"
                        @click.stop="handleCardClicked">
                        <canvas-layer
                            v-for="layer in currentCardLayers"
                            :key="layer.id + '_layer'"
                            :data-layer-focused="isCurrentFocusedLayer(layer)"
                            :layer="layer"
                            :class="['asset-layer-' + layer.id]"
                            @hook:updated="handleLayerUpdate(layer)"
                            @asset-drag-start="handleAssetDragStart" />
                    </div>
                </draggable-canvas>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import DraggableCanvas from './DraggableCanvas';
    import CanvasLayer from './canvas-layers/CanvasLayer';
    import CardCanvasControlBar from './CardCanvasControlBar';
    import { DEFAULT_CARD_DIMENSIONS } from '@/creative-studio/domain/layered-designer/models/Card';
    import { LAYERED_DESIGNER_MAIN_CANVAS_ID } from '@/components/creative-studio/common/constants';
    import { LinkedStatuses } from '@/creative-studio/domain/layered-designer/models/types';
    import EventBus from '@/event-bus';
    import { customClickOutsideDirective } from '@/components/creative-studio/common/directives/custom-click-outside.js';

    export default {
        components: {
            CardCanvasControlBar,
            CanvasLayer,
            DraggableCanvas
        },
        directives: {
            CustomClickOutside : customClickOutsideDirective
        },
        data() {
            return {
                canvasStyle: {
                    transform: null,
                    transformOrigin: '50% 50%'
                },
                autoScrollPosition: {
                    x: null,
                    y: null
                }
            };
        },
        computed: {
            ...mapGetters([
                'currentCard',
                'currentCardLayers',
                'focusedLayer',
                'canvasZoom',
                'recommendedScale',
                'layeredDesignerOverflow'
            ]),
            cardTitle() {
                if (!this.isCurrentCardSelected) {
                    return 'No card selected';
                }

                return this.currentCard.name;
            },
            isCurrentCardSelected() {
                return this.currentCard !== null;
            },
            isFocusedLayerSelected() {
                return this.focusedLayer !== null;
            },
            focusedLayerId() {
                return this.isFocusedLayerSelected ? parseInt(this.focusedLayer.id) : -1;
            },
            horizontalGuidelines() {
                return [0, this.currentCard?.dimensions.height/2, this.currentCard?.dimensions.height] || [];
            },
            verticalGuidelines() {
                return [0, this.currentCard?.dimensions.width/2, this.currentCard?.dimensions.width] || [];
            },
                isScalable() {
                return this.focusedLayer?.isScalable();
            },
            focusedLayerAlterations() {
                return this.focusedLayer?.alterations || {};
            },
            focusedLayerGradient() {
                return this.focusedLayer?.colorGradient || {};
            },
            focusedLayerPosition() {
                return this.focusedLayer?.position || {};
            },
            focusedLayerDimensions() {
                return this.focusedLayer ? this.focusedLayer?.dimensions : {};
            },
            focusedLayerScale() {
                return this.focusedLayer?.scale || 1;
            },
            isResizeActive() {
                return this.focusedLayer?.isResizeable();
            },
            isClipActive() {
                return this.focusedLayer?.isClippable() && this.focusedLayer?.alterations.isClipping;
            },
            isKeepRatioActive() {
                return this.focusedLayer?.hasToKeepAspectRatio();
            },
            focusedLayerAsset() {
                return this.focusedLayer?.asset || {};
            },
            currentCardStyle() {
                if (!this.isCurrentCardSelected) {
                    return {
                        width: DEFAULT_CARD_DIMENSIONS.width + 'px',
                        height: DEFAULT_CARD_DIMENSIONS.height + 'px'
                    };
                }

                return {
                    width: this.currentCard.dimensions.width + 'px',
                    height: this.currentCard.dimensions.height + 'px',
                };
            },
            cardCanvasId() {
                return LAYERED_DESIGNER_MAIN_CANVAS_ID;
            },
            cardLinkedIcon() {
                let icon = '';

                switch(this?.currentCard?.dimensions?.linkedStatus) {
                    case LinkedStatuses.Main:
                    icon = 'main-link';
                    break;
                    case LinkedStatuses.Linked:
                    icon = 'linked';
                    break;
                    case LinkedStatuses.Unlinked:
                    icon = 'not-linked';
                    break;
                    default:
                    icon = 'main-link';
                }
                return icon;
            },
            cardAspectRatio() {
                return this.currentCard?.dimensions?.aspectRatio ?? '1:1';
            }
        },
        watch: {
            currentCard() {
                this.scaleAndFitContent();
            },
            canvasZoom(newZoom) {

                this.$nextTick(() => {
                    this.handleScrollToCenter();
                });

                if (newZoom >= 1) {
                        // Update the transform origin to make sure all of the zoomed canvas is centered and visible
                          this.canvasStyle.transformOrigin = '0 0';
                } else {
                        // Update the transform origin to make the zoomed out canvas stay on the center of the page
                        this.canvasStyle.transformOrigin = '50% 50%';
                }
                this.canvasStyle.transform = `scale(${newZoom})`;
            },
            'currentCardLayers.length'() {
                this.$nextTick(() => {
                   const newFocusedLayer = this.currentCardLayers[this.currentCardLayers.length-1];
                   this.setFocusedLayer(newFocusedLayer);
                });
            },
        },
        mounted() {
            EventBus.$on('on-current-card-dimensions-change', () => {
                this.$nextTick(() => {
                    this.scaleAndFitContent();
                    this.handleScrollToCenter({ resetFirst: true });
                });
            });
            this.handleScrollToCenter();
        },
        beforeDestroy() {
            EventBus.$off('on-current-card-dimensions-change', this.scaleAndFitContent);

        },
        methods: {
            ...mapActions([
                'setRecommendedScale',
                'setCanvasZoom',
                'setFocusedLayer'
            ]),
            isCurrentFocusedLayer(layer) {
                return layer.id === this.focusedLayer?.id;
            },
            handleLayerUpdate(layer) {
                if (this.isCurrentFocusedLayer(layer)) {
                    this.$refs['draggable-canvas'].syncDraggableCanvas();
                }
            },
            handleDragStart() {
                this.$refs['draggable-canvas'].syncDraggableCanvas();
            },
            scaleAndFitContent() {
                const scaleX = (window.screen.width / 2) / this.currentCard?.dimensions.width;
                const scaleY =  (window.screen.height / 2) /  this.currentCard?.dimensions.height;
                const scaleToFit = Math.min(scaleX, scaleY);
                 this.canvasStyle.transform = `scale(${scaleToFit})`;
                 this.setCanvasZoom(scaleToFit);
            },
            handleScrollToCenter({ resetFirst =  false } = {}) {
                const scrollEl = document.querySelector('.scrollable-canvas');

                if(resetFirst) {
                    scrollEl.scrollTop = 0;
                }

                this.$nextTick(() => {
                    const xScroll = (scrollEl.scrollWidth - scrollEl.clientWidth ) / 2;
                    const yScroll = (scrollEl.scrollHeight - scrollEl.clientHeight) / 2;
                    scrollEl.scrollTop = Math.floor(yScroll);
                    scrollEl.scrollLeft = Math.floor(xScroll);
                });

            },
            handleAssetDragStart(e) {
                this.$refs['draggable-canvas'].handleAssetDrag(e);
            },
            handleOutOfCardClicked(e) {
                const clickedOutOfCard = e.target.parentElement.classList.contains('layered-designer-wrapper');
                if(clickedOutOfCard) {
                    this.setFocusedLayer(null);
                }
            },
            handleCardClicked(e) {
             const clickOnCard =  e.target.parentElement.classList.contains('draggable-canvas-main');
             if(clickOnCard) {
                this.setFocusedLayer(null);
             }

            },

        }
    };
</script>

<style lang="scss">

.card-canvas-wrapper {
    margin: 0 auto;
    width: fit-content;
    height: fit-content;
    position: relative;
    transform: scale(0.50);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.draggable-canvas-main {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: $white;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.14);
    max-width: 100%;
}
.card-canvas {
    height: 100%;
    width: 100%;
    position: relative;
}


.card-canvas-spaced-container {
    margin-top: 150px;
    margin-bottom: 150px;
    margin-left: 60px;
     margin-right: 60px;
}
.asset-layer {
    width: fit-content;
    position: absolute;
    min-width: 20px;
    min-height: 20px;
}
.asset-layer .inner-canvas-layer {
    width: 100%;
    height: 100%;
}

</style>