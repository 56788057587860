/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid-four': {
    width: 30,
    height: 19,
    viewBox: '0 0 30 19',
    data: '<path pid="0" d="M6 0h8v8H6zm11 0h8v8h-8zM6 11h8v8H6zm11 0h8v8h-8z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
