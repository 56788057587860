/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video': {
    width: 42,
    height: 36,
    viewBox: '0 0 42 36',
    data: '<g _stroke="#8F9EA6" stroke-width="1.25" _fill="none" fill-rule="evenodd"><path pid="0" d="M29.188 28.896l10.75 5.556A.726.726 0 0041 33.814V17.688a.726.726 0 00-1.061-.637l-10.751 5.556a.72.72 0 00-.39.637v5.014a.72.72 0 00.39.638zM20.258 8.751c0 4.281-3.504 7.751-7.826 7.751-4.321 0-7.825-3.47-7.825-7.75C4.607 4.47 8.111 1 12.432 1c4.322 0 7.826 3.47 7.826 7.751z"/><path pid="1" d="M19.939 11.47c0 2.649 2.167 4.796 4.841 4.796 2.673 0 4.84-2.147 4.84-4.795 0-2.649-2.167-4.796-4.84-4.796-2.674 0-4.841 2.147-4.841 4.796zM27.591 16.502a1.2 1.2 0 011.207 1.196h0v16.106A1.2 1.2 0 0127.59 35h0H5.814a1.201 1.201 0 01-1.207-1.196h0v-9.63h5.995c.44 0 .796-.352.796-.788v-2.312a.792.792 0 00-.796-.788H4.607v-2.588a1.2 1.2 0 011.084-1.19l.123-.006z"/><path pid="2" d="M4.607 20.286H1.796a.792.792 0 00-.796.788v2.312c0 .436.356.789.796.789h8.806c.44 0 .796-.353.796-.789v-2.312a.792.792 0 00-.796-.788H4.607h0z"/></g>'
  }
})
