/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minus': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<path pid="0" d="M9 .5a9 9 0 110 18 9 9 0 010-18zm5 8H4v2h10v-2z" _fill="#00A2EA" fill-rule="evenodd"/>'
  }
})
