const SyncBodyClassMixin = (className) => {
    return {
        mounted() {
            this._toggleActiveClass(true);
        },
        activated() {
            this._toggleActiveClass(true);
        },
        deactivated() {
            this._toggleActiveClass(false);
        },
        destroyed() {
            this._toggleActiveClass(false);
        },
        methods: {
            _toggleActiveClass(active) {
                if (active) {
                    document.body.classList.add(className);
                } else {
                    document.body.classList.remove(className);
                }
            }
        }
    };
};

export default SyncBodyClassMixin;