import cache from '@/apis/cache';
import HTTP from '@/http';
import moment from 'moment';

async function getDealerInsights(config) {

    const cacheKey = `facebook_${JSON.stringify(config)}`;

    const cacheEntry = cache.get(cacheKey);

    if (cacheEntry) {
        return cacheEntry;
    }

    // Set defaults
    config = {
        ...{
            dealerId: null,
            campaigns: [],
            startDate: null,
            endDate: null,
            level: 'account',
            increment: null,
            attributionWindows: []
        },
        ...config
    };

    // List out Facebook fields to retrieve for dev usability
    const fields = [
        'account_id',
        'campaign_id',
        'campaign_name',
        'account_name',
        'clicks',
        'spend',
        'reach',
        'cpc',
        'cpm',
        'cpp',
        'ctr',
        'frequency',
        'impressions',
        'unique_clicks',
        'unique_ctr',
        'unique_inline_link_click_ctr',
        'unique_inline_link_clicks',
        'unique_link_clicks_ctr',
        'inline_link_click_ctr',
        'inline_link_clicks',
        'cost_per_action_type',
        'cost_per_unique_inline_link_click',
        'cost_per_unique_click',
        'cost_per_unique_outbound_click',
        'cost_per_thruplay',
        'cost_per_outbound_click',
        'cost_per_unique_action_type',
        'video_play_actions',
        'website_ctr',
        //'video_thruplay_watched_actions',
        //'video_30_sec_watched_actions',
        //'video_10_sec_watched_actions',
        'unique_outbound_clicks_ctr',
        'unique_outbound_clicks',
        'unique_actions',
        'outbound_clicks_ctr',
        'outbound_clicks',
        'actions',
        'action_values',
        'conversion_values',
        'catalog_segment_value',
        'converted_product_quantity',
        'converted_product_value',
        'cost_per_conversion',
        'instant_experience_outbound_clicks',
        'mobile_app_purchase_roas',
        'purchase_roas',
        'website_purchase_roas'
    ];
    const params = {
        level: config.level, // switch level
        time_range: {
            since: moment(config.startDate).format('YYYY-MM-DD'), // start date as yyyy-mm-dd
            until: moment(config.endDate).format('YYYY-MM-DD') // end date as yyyy-mm-dd
        },
        fields: fields.join(','),
        action_attribution_windows: config.attributionWindows
    };

    if (config.campaigns.length) {
        params.filtering = [{
            field: 'campaign.id',
            operator: 'IN',
            value: config.campaigns // selected campaign ids
        }];
    }

    // if a time increment has been requested append it
    if (config.increment) {
        params.time_increment = config.increment;
    }

    const response = await (new HTTP()).get(`/dealers/${config.dealerId}/graph-api/ad-account/insights`, params);

    const data = response.data || [];

    cache.set(cacheKey, data);

    return data;
}

export default getDealerInsights;