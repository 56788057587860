<template>
    <styled-interface hide-toolbar>
        <styled-card
            class="mt-5"
            :width="800"
            centered
            hide-header>
            <div class="ma-5 text-xs-center">
                <h1>Upgrade to Access</h1>
                <p>
                    The products associated with {{ currentDealer.name }} do not allow
                    you to access this page.  To upgrade, please click the link below and
                    follow the prompts to continue!
                </p>
                <styled-button
                    class="mt-4"
                    :to="{
                        name: 'channel-setup',
                        params: {
                            dealer_id: currentDealer.id
                        }
                    }"
                    green>
                    UPGRADE NOW
                </styled-button>
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import StyledCard from '@/components/globals/StyledCard';
import StyledButton from '@/components/globals/StyledButton';
import StyledInterface from '@/components/globals/StyledInterface';
import { mapState } from 'vuex';

export default {
    components: {
        StyledInterface,
        StyledButton,
        StyledCard
    },
    title: 'Upgrade Your Products',
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        })
    }
};
</script>
