/**
*  This file is an http request wrapper around axios to
*  make sure all requests sent from the front end use
*  the auth token provided by the api on login.
*/

import axios from 'axios';
import store from './store';
import router from './routes';
import sleep from './helpers/sleep';
class HttpClient {

    constructor() {
        // Create a new instance so we don't affect other axios uses
        this.axios = axios.create();

        // For whatever reason it won't allow baseUrl during creation
        this.axios.defaults.baseURL = process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI.replace(new RegExp(/\/$/, 'g'), '') + '/api/v1';

        this.axios.interceptors.request.use(
            config => {
                config.headers = {
                    'Authorization': `Bearer ${store.state.user.token}`,
                };
                return config;
            },
            error => {
                return Promise.reject(error);
            }
            );

            this.axios.interceptors.response.use(
                response => {
                    return response;
                },
                error => {
                    if (error.response != undefined && error.response.status === 401) {
                        // @todo handle refresh tokens here

                        router.push({
                            name: 'login',
                            query: {
                                user_message: 'session_timeout'
                            }
                        }, () => {
                            // Logout after navigation occurs to avoid errors
                            store.dispatch('logout');
                        });
                    } else {
                        return Promise.reject(error);
                    }
                }
                );

                // const CancelToken = this.axios.CancelToken;
                // this.cancelSource = CancelToken.source();
            }

            cancel() {
                // @todo As far as I can tell the cancel system has never really
                // worked consistently.  Tried moving it here then realized that
                // it causes some post requests to cancel at random
                //this.cancelSource.cancel();
            }



            request(method, url, params, data, hasFile=false) {

                //Build the request with the auth token
                const config = {
                    method,
                    url,
                    headers: {
                        Accept: ['application/json', 'multipart/form-data'],
                    },
                    params,
                    data,
                    //cancelToken: this.cancelSource.token
                };

                if ((config.method === 'POST') || (config.method === 'PUT')) {
                    if (hasFile) {
                        if (config.method === 'PUT') {
                            throw 'Can\'t use PUT with a file.';
                        } else {
                            config.headers['Content-Type'] = 'multipart/form-data';
                        }
                    } else {
                        config.headers['Content-Type'] = 'application/json';
                    }
                }

                //Make the request
                return this.axios.request(config);
            }

            get(url, params) {
                return this.request('GET', url, params);
            }


            async getRetry(url, params, n, sleepTimer = 0) {
                for (let i = 1; i <= n; i++) {
                    try {
                        return await this.request('GET', url, params);
                    } catch (error) {
                        if (i === n) {
                            throw error;
                        }
                        await sleep(sleepTimer);
                    }
                }
            }

            post(url, data, hasFile=false) {
                return this.request('POST', url, null, data, hasFile);
            }

            async postRetry(url, data, n, sleepTimer = 0) {
                for (let i = 1; i <= n; i++) {
                    try {
                        return await this.request('POST', url, null, data, false);
                    } catch (error) {
                        if (i === n) {
                            throw error;
                        }
                        await sleep(sleepTimer);
                    }
                }
            }

            put(url, data) {
                return this.request('PUT', url, null, data);
            }

            async putRetry(url, data, n, sleepTimer = 0) {
                for (let i = 1; i <= n; i++) {
                    try {
                        return await this.request('PUT', url, null, data);
                    } catch (error) {
                        if (i === n) {
                            throw error;
                        }
                        await sleep(sleepTimer);
                    }
                }
            }

            patch(url, data) {
                return this.request('PATCH', url, null, data);
            }

            async patchRetry(url, data, n, sleepTimer = 0) {
                for (let i = 1; i <= n; i++) {
                    try {
                        return await this.request('PATCH', url, null, data);
                    } catch (error) {
                        if (i === n) {
                            throw error;
                        }
                        await sleep(sleepTimer);
                    }
                }
            }

            delete(url, params) {
                return this.request('DELETE', url, params);
            }

            async deleteRetry(url, params, n, sleepTimer = 0) {
                for (let i = 1; i <= n; i++) {
                    try {
                        return await this.request('DELETE', url, params);
                    } catch (error) {
                        if (i === n) {
                            throw error;
                        }
                        await sleep(sleepTimer);
                    }
                }
            }

            getFromApi(path, params) {
                params = params || {};

                params.page_size = 1000;

                return this.get(path, params);
            }
        }

        export default HttpClient;