export const MOVEABLE_DEFAULT_TRANSLATE_ARRAY = [0, 0];
export const MOVEABLE_TRANSLATE_TOP_INDEX = 1;
export const MOVEABLE_TRANSLATE_LEFT_INDEX = 0;

export const MOVEABLE_CLIP_STYLES_INDEXES = Object.freeze({
    TOP: 0,
    RIGHT: 1,
    BOTTOM: 2,
    LEFT: 3
});

export const MOVEABLE_USES_RELATIVE_CROP = true;


export const LAYERED_DESIGNER_MAIN_CANVAS_ID= 'card-main-canvas';
export const LAYERED_DESIGNER_VUEX_PREFIX = 'creative-studio/layered-designer';

export const ASSETS_SORTABLE_FIELDS = [
    {
        field: 'created_at',
        label: 'Newest',
        order: 'desc',
        default: true
    },
    {
        field: 'created_at',
        label: 'Older',
        order: 'asc',
        default: false
    }
];

export const CARD_REARRANGE_DIRECTIONS = Object.freeze({
    UP: 'up',
    DOWN: 'down'
});

export const CARD_NAVIGATE_DIRECTIONS = Object.freeze({
    UP: 'up',
    DOWN: 'down'
});

export const SNAP_GUIDELINES_DIRECTIONS = Object.freeze({
    top: true,
    right: true,
    bottom: true,
    left: true,
    center: true,
    middle: true
});


export const TEXT_ALIGNMENT = Object.freeze({
    center: 'center',
    left: 'left',
    right: 'right'
});

export const KEYBOARD = Object.freeze({
    DELETE: 'Delete',
    BACKSPACE: 'Backspace',
    KEY_UP: 'ArrowUp',
    KEY_LEFT: 'ArrowLeft',
    KEY_RIGHT: 'ArrowRight',
    KEY_DOWN: 'ArrowDown',
    Z_KEY : 'z',
    Y_KEY: 'y'
});


export const GOOGLE_FONTS_API_KEY = process.env.VUE_APP_GOOGLE_MAP_API_KEY ;


export const NODES_RELEVANT_STYLES = [
    'svg'
];


export const MAX_ANCHORS_ON_IMAGE_ASSETS = 3;


export const MUTATIONS_TO_TRIGGER_HISTORY = [
        'SET_LAYERED_DESIGNER_CARD_LAYERS',
        'SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION',
        'SET_LAYERED_DESIGNER_FOCUSED_LAYER_ROTATE_ANGLE',
        'SET_LAYERED_DESIGNER_FOCUSED_LAYER_DIMENSIONS',
        'SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET',
        'SET_LAYERED_DESIGNER_CURRENT_CARD',
        'SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHOR_POSITION',
        'SET_LAYERED_DESIGNER_FOCUSED_LAYER_ORIGINAL_DIMENSIONS',
        'SET_LAYERED_DESIGNER_CURRENT_CARD_NAME',
         'SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS',
         'SET_LAYERED_DESIGNER_CURRENT_CARD_THUMBNAIL',
         'SET_LAYERED_DESIGNER_CURRENT_CARD_DIMENSIONS',
         'SET_LAYERED_DESIGNER_CURRENT_CARD_NAME',
         'SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR',
         'SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE',
         'SET_LAYERED_DESIGNER_FOCUSED_LAYER_IS_CLIPPING',
         'SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET',
         'SET_LAYERED_DESIGNER_FOCUSED_LAYER_NAME'
];