/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hambuger': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<defs><path pid="0" d="M18.698 15.5a1 1 0 110 2H1.178a1 1 0 010-2zm0-7.5a1 1 0 110 2H1.178a1 1 0 110-2zm0-8a1 1 0 110 2H1.178a1 1 0 110-2z" id="svgicon_hambuger_a"/></defs><use _fill="#8F9EA6" xlink:href="#svgicon_hambuger_a" fill-rule="evenodd"/>'
  }
})
