/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'page-align-top': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M1 0h18a1 1 0 010 2H1a1 1 0 110-2zm6 18V4a1 1 0 011-1h4a1 1 0 011 1v14a1 1 0 01-1 1H8a1 1 0 01-1-1z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
