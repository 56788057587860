/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-share': {
    width: 33,
    height: 29,
    viewBox: '0 0 33 29',
    data: '<path pid="0" d="M32.37 11.103c.4.453.63 1.02.63 1.586 0 .623-.23 1.133-.63 1.586L22.286 24.698a2.308 2.308 0 01-1.661.68c-.63 0-1.146-.17-1.604-.623-.459-.453-.688-.963-.688-1.643v-4.985c-3.323.057-5.672.227-7.104.567-1.833.453-3.036 1.303-3.552 2.492-.573 1.246-.458 3.06.287 5.495.114.567.057 1.02-.23 1.473-.286.453-.687.68-1.203.793-.515.113-.974.057-1.432-.283-1.375-.963-2.464-2.096-3.266-3.456C.573 23.282 0 21.13 0 18.75c0-3.059.86-5.438 2.635-7.25 1.49-1.53 3.782-2.663 6.76-3.343 2.293-.566 5.272-.85 8.938-.906V2.266c0-.623.23-1.133.688-1.586.458-.453.974-.68 1.604-.68.63 0 1.203.227 1.661.68L32.37 11.103zm-1.318 1.926c.057-.113.115-.227.115-.34 0-.113-.058-.227-.115-.34L20.97 1.926a.562.562 0 00-.516-.057c-.23.114-.286.227-.286.397v6.798c-3.782 0-6.703.17-8.766.51-2.98.453-5.27 1.359-6.818 2.662-1.833 1.53-2.75 3.682-2.75 6.514 0 1.926.459 3.682 1.433 5.212.687 1.246 1.661 2.323 2.921 3.229-.916-3.286-.916-5.722.115-7.42.917-1.474 2.636-2.437 5.214-2.947 1.833-.34 4.698-.51 8.65-.51v6.798c0 .227.058.34.287.397.172.113.344.056.516-.057L31.052 13.03z" _fill="#606770" fill-rule="nonzero"/>'
  }
})
