<template>
    <div class="dropdown-title">
        <div
            class="dropdown-inside"
            :class="{'with-tooltip': tooltipOpened}">
            <div @click="showTooltip">
                <span class="dropdown-icon meta-icon">
                    <icon
                        name="meta"
                        color="#03A2EA"
                        size="25" />
                </span>
                <span>{{ selectedMetaPreview }}</span>
                <span class="dropdown-arrow">
                    <icon
                        name="arrow-left"
                        color="#03A2EA"
                        size="15" />
                </span>
            </div>
            <div
                v-if="tooltipOpened"
                class="dd-tooltip"
                @click.stop="">
                <div class="dd-tooltip-left">
                    <div class="tooltip-title">
                        Facebook
                    </div>
                    <div class="dd-tooltip-list">
                        <div><span @click="showPreview(FACEBOOK_DESKTOP_FEED, true)">Desktop Feed</span></div>
                        <div><span @click="showPreview(FACEBOOK_MOBILE_FEED)">Mobile Feed</span></div>
                        <div><span @click="showPreview(FACEBOOK_STORY)">Stories</span></div>
                        <div>
                            <span
                                v-if="showReels"
                                @click="showPreview(FACEBOOK_REEL)">Reels</span>
                        </div>
                    </div>
                    <div class="dd-tooltip-title">
                        Instagram
                    </div>
                    <div class="dd-tooltip-list">
                        <div><span @click="showPreview(INSTAGRAM_MOBILE_FEED)">Feed</span></div>
                        <div><span @click="showPreview(INSTAGRAM_STORY)">Stories</span></div>
                        <div>
                            <span
                                v-if="showReels"
                                @click="showPreview(INSTAGRAM_REEL)">Reels</span>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showInfo"
                    class="dd-tooltip-right">
                    <div>Need more Meta placements?</div>
                    <div class="dd-tooltip-desc">
                        We've selected the most popular Meta ad placement
                        to preview & edit. If you would like to view & edit
                        all placements you can edit them directly within Ads
                        Manager post-deployment.
                    </div>
                </div>
                <div
                    v-else
                    class="info-placeholder" />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import {
    FACEBOOK_DESKTOP_FEED,
    FACEBOOK_MOBILE_FEED,
    FACEBOOK_STORY,
    FACEBOOK_REEL,
    INSTAGRAM_MOBILE_FEED,
    INSTAGRAM_STORY,
    INSTAGRAM_REEL
} from '@/helpers/globals';

export default {
    components: {
        Icon
    },
    props: {
        selectedMetaPreview: {
            type: String,
            required: true
        },
        showReels: {
            type: Boolean,
            required: false,
            default: true
        },
        showInfo: {
            type: Boolean,
            required: false,
            default: true
        },
        hidePreviews: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            FACEBOOK_DESKTOP_FEED,
            FACEBOOK_MOBILE_FEED,
            FACEBOOK_STORY,
            FACEBOOK_REEL,
            INSTAGRAM_MOBILE_FEED,
            INSTAGRAM_STORY,
            INSTAGRAM_REEL,
            tooltipOpened: false,
        };
    },
    mounted() {
        document.addEventListener('click', this.hideTooltip);
    },
    methods: {
        isAvailable(type) {
            if (this.hidePreviews.includes(type)) {
                return false;
            }
            return true;
        },
        showPreview(type, desktopPreview) {
            this.tooltipOpened = false;
            this.$emit('show-preview', type, desktopPreview);
        },
        showTooltip() {
            setTimeout(() => {
                this.tooltipOpened = true;
            }, 0);
        },
        hideTooltip() {
            this.tooltipOpened = false;
        },
    }
};
</script>

<style lang="scss" scoped>
.dropdown-title {
    color: #03A2EA;
    padding: 5px;
    padding-bottom: 15px;
}
.dropdown-inside {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.dropdown-inside.with-tooltip {
    &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
        top: calc(100% + 10px);
        right: 0;
        transform: rotate(45deg);
        z-index: 99;
    }
    &:before {
        content: '';
        position: absolute;
        width: 60px;
        height: 25px;
        background: white;
        top: calc(100% + 20px);
        right: -20px;
        z-index: 100;
    }
}
.dropdown-icon {
    margin-right: 10px;
    display: inline-block;
    &.meta-icon{
        transform: translateY(4px);
    }
}
.dropdown-arrow {
    margin-left: 10px;
    display: inline-block;
    transform: rotate(90deg) translateX(1px);
}

.dd-tooltip {
    position: absolute;
    background: white;
    padding: 30px;
    z-index: 99;
    top: calc(100% + 20px);
    left: 10%;
    display: flex;
    cursor: default;
    box-shadow: 1px 1px 15px 5px rgba(0,0,0,0.2);
}
.dd-tooltip-right{
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid #CBCBCB;
    width: 240px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.dd-tooltip-desc {
    margin-top: 15px;
    color: $gray-primary;
    font-size: 12px;
}
.dd-tooltip-list{
    padding-left: 30px;
    margin-bottom: 15px;
    padding-top: 5px;
    &:last-of-type{
        margin-bottom: 0;
    }
    span{
        display: inline-block;
        white-space: nowrap;
        margin: 5px 0;
        cursor: pointer;
    }
}
.info-placeholder {
    width: 100px;
}
</style>
