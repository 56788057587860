/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drag': {
    width: 10,
    height: 18,
    viewBox: '0 0 10 18',
    data: '<path pid="0" d="M4.59 0l4.58 4.59L7.76 6 5.5 3.74v10.52L7.76 12l1.42 1.41L4.59 18 .01 13.41 1.42 12l2.08 2.08V3.92L1.42 6 0 4.59 4.59 0z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
