<template>
    <sidebar-menu
        class="layered-designer-sidebar"
        :menu="menuItems"
        theme="white-theme">
        <template
            #header>
            <div class="layered-designer-sidebar-header">
                <div class="layered-designer-sidebar-title">
                    Layered Designer <span class="layered-designer-beta-label">BETA</span>
                </div>
                <div class="v-divider v-divider--vertical" />

                <div>
                    <layer-quick-insert-menu class="quick-insert-wrapper">
                        <template #activator="{ on }">
                            <button
                                class="text-center centered quickinsert-button"
                                v-on="on">
                                <div class="d-inline-block">
                                    <icon
                                        class="quickinsert-card-icon d-block mx-auto"
                                        color="#00a2ea"
                                        name="plus"
                                        width="20"
                                        height="20" />
                                    <span class="quickinsert-label">INSERT</span>
                                </div>
                                <icon
                                    class="quickinsert-arrow-icon"
                                    name="chevron-down"
                                    width="15"
                                    height="15" />
                            </button>
                        </template>
                    </layer-quick-insert-menu>
                </div>
            </div>
        </template>
    </sidebar-menu>
</template>

<script>
    import { SidebarMenu } from 'vue-sidebar-menu';
    import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
    import LayerQuickInsertMenu from '@/components/creative-studio/layered-designer/shared/LayerQuickInsertMenu';
    import Icon from '@/components/globals/Icon';

    import CardLayersManager from './CardLayersManager';
    import CardAttributesManager from './CardAttributesManager';

    export default {
        components: {
            SidebarMenu,
            LayerQuickInsertMenu,
            Icon
        },
        data() {
            return {
                menuItems: [
                    {
                        component: CardAttributesManager
                    },
                    // {
                    //     component: CardsManager
                    // },
                    {
                        component: CardLayersManager
                    }
                ]
            };
        }
    };
</script>

<style lang="scss">

.layered-designer-sidebar.v-sidebar-menu {
    z-index: 10000;
    top: $layered-designer-modal-margin;
    bottom: $layered-designer-modal-margin;
    left: $layered-designer-modal-margin;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.layered-designer-sidebar {
    background-color: transparent !important;
}
.v-sidebar-menu.layered-designer-sidebar .vsm--scroll-wrapper {
    background: #E4EAED;
    border-right: 1px solid #B4B9BB;
}

.layered-designer-sidebar-title {
    font-size: 22px;
}
.layered-designer-sidebar-header {
    color: $white;
    padding: 20px 0 20px 20px;
    font-family: "Open Sans";
    border-top-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}


.layered-designer-sidebar .vsm--list > div {
    border-bottom: 1px solid #B1BCC1;
    padding: 20px;
}

.image-format-select {
    padding: 10px;
}

.sidebar-item-title {
    font-size: 14px;
    color: $blue-bg;
    padding-bottom: 20px;
    display: flex;
}

.sidebar-item-action-button {
    margin-left: auto;
}


.quickinsert-button {
    display: flex;
    align-items: center;
}

.quickinsert-arrow-icon {
    margin-bottom: 15px;
    margin-left: 10px;
}

.quickinsert-label {
    color: $white;
    font-size: 11px;
    margin-top: 4px;
    display: inline-block;
}

.quick-insert-wrapper {
    margin-right: -15px;
}


.layered-designer-sidebar-header .v-divider {
    color: #8f9ea6;
    max-height: 40px;
    margin-top: -5px;
}

.layered-designer-beta-label {
    font-size: 11px;
    font-weight: 600;
    padding: 5px 10px;
    background: #538AA2;
    border-radius: 5px;
    color: $white;
    display: inline-block;
    margin-left: 5px;
    transform: translateY(-2px);
}
</style>