const validTypes = {
    streetNumber: ['street_number'],
    postalCode: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    country: ['country']
  };

export const formatGoogleAddress = (components) => {
    const address = {
      streetNumber: '',
      postalCode: '',
      street: '',
      region: '',
      city: '',
      country: ''
    };
    components.forEach(component => {
        for (const type in validTypes) {
            if (validTypes[type].includes(component.types[0])) {
                if (component.short_name) {
                    address[`${type}Short`] = component.short_name;
                }
                address[type] = component.long_name;
            }
        }
    });
    return address;
};

/**
 *
 * @param {Object} address
 * @param {String} type
 *
 * @returns {Object}
 *
 * This function takes an address object and a type and returns a formatted address object
 * with the address type and the address type short name
 */
export const formatToBuyerBridgeLocation = (location, type) => {
    return {
        id: location?.id,
        name: location?.name,
        type: type,
        radius: location?.radius,
        geometry: location?.geometry,
        coordinates: {
            lat: location?.geometry?.lat,
            lng: location?.geometry?.lng || location?.geometry?.lon, // Google response has lon instead of lng
        },
        address: {
            country: location?.countryShort || location?.country,
            region: location?.state || location?.regionShort, // Same thing as state in the US
            postal_code: location?.name || location?.postal_code || location?.postalCodeShort,

            // These are not sent by the backend
            street: location?.street || location?.streetShort,
            street_number: location?.street_number || location?.streetNumberShort,
            city: location?.city || location?.cityShort,
        }
    }
}
