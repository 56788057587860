/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pdf': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17 0a3 3 0 013 3v14a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3h14zM9.524 7.61c-.189 0-.189 0-.427.004-.239.005-.239.005-.491.014-.252.009-.252.009-.49.027-.16.012-.16.012-.296.028l-.132.017v6.309c.117.018.117.018.351.031l.49.028c.257.013.257.013.495.022.239.009.239.009.383.009.909 0 .909 0 1.525-.279.617-.279.617-.279.995-.738.378-.459.378-.459.54-1.053.162-.594.162-.594.162-1.233 0-.702 0-.702-.171-1.287s-.171-.585-.54-1.008c-.369-.423-.369-.423-.963-.657-.594-.234-.594-.234-1.431-.234zm-5.247 0c-.45 0-.45 0-.905.04-.302.027-.302.027-.571.068l-.261.045V14h1.233v-2.169c.045.009.045.009.148.018.104.009.104.009.212.014l.106.003.101.001h.144c.468 0 .468 0 .904-.117.437-.117.437-.117.779-.378s.342-.261.549-.67c.207-.41.207-.41.207-1.004 0-.657 0-.657-.239-1.066-.238-.41-.238-.41-.616-.64-.378-.229-.378-.229-.85-.306-.473-.076-.473-.076-.941-.076zm12.969.09h-3.735V14h1.233v-2.547h2.304v-1.098h-2.304V8.798h2.502V7.7zM9.623 8.708c.513 0 .513 0 .846.189.333.189.333.189.527.486.193.297.193.297.265.67.072.374.072.374.072.743 0 .423 0 .423-.081.824-.081.4-.081.4-.284.706-.202.306-.202.306-.549.49-.346.185-.346.185-.868.185-.045 0-.045 0-.135-.005l-.189-.008-.184-.01c-.057-.002-.057-.002-.092-.008l-.03-.005V8.744c.099-.018.099-.018.302-.027.202-.009.202-.009.4-.009zm-5.247-.036c.234 0 .234 0 .459.045s.225.045.4.162c.176.117.176.117.284.31.108.194.108.194.108.491 0 .351 0 .351-.112.567-.113.216-.113.216-.302.338-.189.121-.189.121-.428.166-.238.045-.238.045-.481.045h-.113c-.076 0-.076 0-.157-.005-.081-.004-.081-.004-.153-.013-.048-.006-.048-.006-.08-.012l-.028-.006V8.717c.099-.018.099-.018.261-.032.162-.013.162-.013.342-.013z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
