import { IMAGE_ASSET_TYPE, VIDEO_ASSET_TYPE } from '@/components/ad-studio/store/constants';
import {
    FACEBOOK_FEATURES,
    FEATURE_FACEBOOK_AUTOMOTIVE_CATALOG,
    FEATURE_PINTEREST_CATALOG,
    FEATURE_SNAPCHAT_CATALOG,
    FEATURE_TIKTOK_CATALOG,
    PINTEREST_FEATURES,
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK,
    SNAPCHAT_FEATURES,
    TIKTOK_FEATURES
} from '@/helpers/globals';

export const DEPLOYMENT_PLATFORMS = [
    {
        name: PLATFORM_FACEBOOK,
        displayName: 'Meta',
        icon: 'meta',
        ui_name: 'Meta',
        platform: PLATFORM_FACEBOOK,
        features: (deploymentType) => {
            if (deploymentType === STATIC_DEPLOYMENT_TYPE) {
                return FACEBOOK_FEATURES;
            }

            return [
                ...FACEBOOK_FEATURES,
                FEATURE_FACEBOOK_AUTOMOTIVE_CATALOG
            ];
        },
        supportedMedia: [IMAGE_ASSET_TYPE, VIDEO_ASSET_TYPE],
        channelControlsMediaStyles: true,
        maxNumberOfAdsInAdSet: 50,
        comingSoon: false,
        hasAutomatedInventoryDeploy: true,
        budget: {
            levels: ['adSet', 'campaign'],
            dailyPerAd: 2,
            lifetimePerDay: 2,
            bid_cap: {
                enabled: false
            },
        }
    },
    {
        name: PLATFORM_PINTEREST,
        displayName: 'Pinterest',
        icon: PLATFORM_PINTEREST,
        platform: PLATFORM_PINTEREST,
        features: (deploymentType) => {
            if (deploymentType === STATIC_DEPLOYMENT_TYPE) {
                return PINTEREST_FEATURES;
            }

            return [
                ...PINTEREST_FEATURES,
                FEATURE_PINTEREST_CATALOG
            ];
        },
        supportedMedia: [IMAGE_ASSET_TYPE, VIDEO_ASSET_TYPE],
        channelControlsMediaStyles: true,
        maxNumberOfAdsInAdSet: 100,
        comingSoon: false,
        hasAutomatedInventoryDeploy: false,
        budget: {
            levels: ['campaign'], // Pinterest budget level depends on campaign objective
            daily: 1,
            lifetimePerDay: 1,
            bid_cap: {
                enabled: false
            }
        }
    },
    {
        name: PLATFORM_TIKTOK,
        displayName: 'TikTok',
        icon: PLATFORM_TIKTOK,
        platform: PLATFORM_TIKTOK,
        features: (deploymentType) => {
            if (deploymentType === STATIC_DEPLOYMENT_TYPE) {
                return TIKTOK_FEATURES;
            }

            return [
                ...TIKTOK_FEATURES,
                FEATURE_TIKTOK_CATALOG
            ];
        },
        supportedMedia: [VIDEO_ASSET_TYPE],
        channelControlsMediaStyles: false,
        maxNumberOfAdsInAdSet: 20,
        comingSoon: false,
        hasAutomatedInventoryDeploy: false,
        budget: {
            levels: ['adSet'],
            daily: 20,
            lifetimePerDay: 20,
            bid_cap: {
                enabled: false
            }
        }
    },
    {
        name: PLATFORM_SNAPCHAT,
        displayName: 'Snapchat',
        icon: PLATFORM_SNAPCHAT,
        platform: PLATFORM_SNAPCHAT,
        features: (deploymentType) => {
            if (deploymentType === STATIC_DEPLOYMENT_TYPE) {
                return SNAPCHAT_FEATURES;
            }

            return [
                ...SNAPCHAT_FEATURES,
                FEATURE_SNAPCHAT_CATALOG
            ];
        },
        supportedMedia: [IMAGE_ASSET_TYPE, VIDEO_ASSET_TYPE],
        channelControlsMediaStyles: false,
        maxNumberOfAdsInAdSet: 100,
        comingSoon: false,
        hasAutomatedInventoryDeploy: false,
        budget: {
            levels: ['adSet'],
            daily: 5,
            lifetimePerDay: 5,
            bid_cap: {
                enabled: false
            },
        }
    },
];

export const GEO = 'geo';
export const DMA = 'dma';
export const POSTAL_CODE = 'postal_code';
export const CUSTOM = 'custom';

export const OBJECTIVE_OUTCOME_AWARENESS = 'OUTCOME_AWARENESS';
export const OBJECTIVE_BRAND_AWARENESS = 'BRAND_AWARENESS';
export const OBJECTIVE_AWARENESS = 'AWARENESS';
export const OBJECTIVE_REACH = 'REACH';
export const OBJECTIVE_CONSIDERATION = 'CONSIDERATION';
export const OBJECTIVE_VIDEO_VIEWS = 'VIDEO_VIEWS';
export const OBJECTIVE_TRAFFIC = 'TRAFFIC';
export const OBJECTIVE_VIDEO_VIEW = 'VIDEO_VIEW';
export const OBJECTIVE_WEB_VIEW = 'WEB_VIEW';
export const OBJECTIVE_WEB_CONVERSION = 'WEB_CONVERSION';
export const OBJECTIVE_OUTCOME_TRAFFIC = 'OUTCOME_TRAFFIC';
export const OBJECTIVE_OUTCOME_ENGAGEMENT = 'OUTCOME_ENGAGEMENT';
export const OBJECTIVE_CONVERSION = 'CONVERSION'; // Not yet supported in BB ad building

export const ADVANTAGE_PLUS_OBJECTIVES = [
    OBJECTIVE_OUTCOME_TRAFFIC,
    OBJECTIVE_CONVERSION
];

export const BB_ADVANTAGE_PLUS_OBJECTIVES = [
    'traffic',
    'conversions'
];

export const CAMPAIGN_OBJECTIVES = {
    [PLATFORM_FACEBOOK]: [
        {
            ui_name: 'Awareness',
            platform_value: OBJECTIVE_OUTCOME_AWARENESS
        },
        {
            ui_name: 'Traffic',
            platform_value: OBJECTIVE_OUTCOME_TRAFFIC
        },
        {
            ui_name: 'Engagement',
            platform_value: OBJECTIVE_OUTCOME_ENGAGEMENT
        },
    ],
    [PLATFORM_SNAPCHAT]: [
        {
            ui_name: 'Awareness',
            platform_value: OBJECTIVE_BRAND_AWARENESS
        },
        {
            ui_name: 'Video View',
            platform_value: OBJECTIVE_VIDEO_VIEW
        },
        {
            ui_name: 'Website Traffic',
            platform_value: OBJECTIVE_WEB_VIEW
        },
    ],
    [PLATFORM_TIKTOK]: [
        {
            ui_name: 'Reach',
            platform_value: OBJECTIVE_REACH
        },
        {
            ui_name: 'Traffic',
            platform_value: OBJECTIVE_TRAFFIC
        },
        {
            ui_name: 'Video Views',
            platform_value: OBJECTIVE_VIDEO_VIEWS
        }
    ],
    [PLATFORM_PINTEREST]: [
        {
            ui_name: 'Awareness',
            platform_value: OBJECTIVE_AWARENESS,
            budget: {
                levels: ['campaign'],
            }
        },
        {
            ui_name: 'Consideration',
            platform_value: OBJECTIVE_CONSIDERATION,
            budget: {
                levels: ['campaign'],
            }
        },
        {
            ui_name: 'Video View',
            platform_value: OBJECTIVE_VIDEO_VIEW,
            budget: {
                levels: ['adSet'],
                bid_cap: {
                    enabled: true,
                    minimum: {
                        daily: 0.02,
                        lifetime: 0.02,
                    }
                }
            }
        },
        // This will need its own project as the pixel events
        // matter for the billing event
        // {
        //     ui_name: 'Conversions',
        //     platform_value: OBJECTIVE_WEB_CONVERSION,
        //     budget: {
        //         levels: ['campaign'],
        //     }
        // }
    ]
};

export const OPTIMIZATION_AD_RECALL_LIFT = 'AD_RECALL_LIFT';
export const OPTIMIZATION_REACH = 'REACH';
export const OPTIMIZATION_IMPRESSIONS = 'IMPRESSIONS';
export const OPTIMIZATION_LINK_CLICKS = 'LINK_CLICKS';
export const OPTIMIZATION_LANDING_PAGE_VIEWS = 'LANDING_PAGE_VIEWS';
export const OPTIMIZATION_THRUPLAY = 'THRUPLAY';
export const OPTIMIZATION_CLICK = 'CLICK';
export const OPTIMIZATION_CLICK_THROUGH = 'CLICK_THROUGH';
export const OPTIMIZATION_VIDEO_VIEW = 'VIDEO_VIEW';
export const OPTIMIZATION_ENGAGED_VIEW = 'ENGAGED_VIEW';
export const OPTIMIZATION_VIDEO_VIEWS = 'VIDEO_VIEWS';
export const OPTIMIZATION_SWIPES = 'SWIPES';
export const OPTIMIZATION_VIDEO_VIEWS_15_SEC = 'VIDEO_VIEWS_15_SEC';
export const OPTIMIZATION_PIXEL_PAGE_VIEW = 'PIXEL_PAGE_VIEW';
export const OPTIMIZATION_SIGN_UP = 'SIGN_UP';
export const OPTIMIZATION_ADD_TO_CART = 'ADD_TO_CART';
export const OPTIMIZATION_LEAD = 'LEAD';
export const OPTIMIZATION_CHECKOUT = 'CHECKOUT';
export const OPTMIZATION_POST_ENGAGEMENT = 'POST_ENGAGEMENT';

export const THRUPLAY_DISABLED_OBJECTIVES = [
    {
        ui_name: 'Ad Recall Lift',
        platform_value: OPTIMIZATION_AD_RECALL_LIFT
    },
    {
        ui_name: 'Reach',
        platform_value: OPTIMIZATION_REACH
    },
    {
        ui_name: 'Impressions',
        platform_value: OPTIMIZATION_IMPRESSIONS
    },
];

export const OPTIMIZATION_GOALS = {
    [PLATFORM_FACEBOOK]: {
        [OBJECTIVE_OUTCOME_AWARENESS]: [
            {
                ui_name: 'Ad Recall Lift',
                platform_value: OPTIMIZATION_AD_RECALL_LIFT
            },
            {
                ui_name: 'Thruplay',
                platform_value: OPTIMIZATION_THRUPLAY
            },
            {
                ui_name: 'Reach',
                platform_value: OPTIMIZATION_REACH
            },
            {
                ui_name: 'Impressions',
                platform_value: OPTIMIZATION_IMPRESSIONS
            },
        ],
        [OBJECTIVE_OUTCOME_ENGAGEMENT]: [
            {
                ui_name: 'Link Clicks',
                platform_value: OPTIMIZATION_LINK_CLICKS
            },
            {
                ui_name: 'Impressions',
                platform_value: OPTIMIZATION_IMPRESSIONS
            },
            {
                ui_name: 'Reach',
                platform_value: OPTIMIZATION_REACH
            },
            {
                ui_name: 'Landing Page Views',
                platform_value: OPTIMIZATION_LANDING_PAGE_VIEWS
            },
        ],
        [OBJECTIVE_OUTCOME_TRAFFIC]: [
            {
                ui_name: 'Link Clicks',
                platform_value: OPTIMIZATION_LINK_CLICKS
            },
            {
                ui_name: 'Landing Page Views',
                platform_value: OPTIMIZATION_LANDING_PAGE_VIEWS
            },
            {
                ui_name: 'Impressions',
                platform_value: OPTIMIZATION_IMPRESSIONS
            },
            {
                ui_name: 'Daily Unique Reach',
                platform_value: OPTIMIZATION_REACH
            }
        ]
    },
    [PLATFORM_SNAPCHAT]: {
        [OBJECTIVE_BRAND_AWARENESS]: [
            {
                ui_name: 'Impressions',
                platform_value: OPTIMIZATION_IMPRESSIONS
            },
            {
                ui_name: 'Swipes',
                platform_value: OPTIMIZATION_SWIPES
            }
        ],
        [OBJECTIVE_VIDEO_VIEW]: [
            {
                ui_name: 'Video Views 15 Seconds',
                platform_value: OPTIMIZATION_VIDEO_VIEWS_15_SEC
            },
            {
                ui_name: 'Video Views',
                platform_value: OPTIMIZATION_VIDEO_VIEWS
            }
        ],
        [OBJECTIVE_WEB_VIEW]: [
            {
                ui_name: 'Pixel Page View',
                platform_value: OPTIMIZATION_PIXEL_PAGE_VIEW
            },
            {
                ui_name: 'Swipes',
                platform_value: OPTIMIZATION_SWIPES
            }
        ]
    },
    [PLATFORM_TIKTOK]: {
        [OBJECTIVE_REACH]: [
            {
                ui_name: 'Reach',
                platform_value: OPTIMIZATION_REACH
            }
        ],
        [OBJECTIVE_TRAFFIC]: [
            {
                ui_name: 'Click',
                platform_value: OPTIMIZATION_CLICK
            },
        ],
        [OBJECTIVE_VIDEO_VIEWS]: [
            {
                ui_name: 'Video View',
                platform_value: OPTIMIZATION_ENGAGED_VIEW
            },
        ]
    },
    [PLATFORM_PINTEREST]: {
        [OBJECTIVE_AWARENESS]: [
            {
                ui_name: 'Impressions',
                platform_value: OPTIMIZATION_IMPRESSIONS
            }
        ],
        [OBJECTIVE_CONSIDERATION]: [
            {
                ui_name: 'Click Through',
                platform_value: OPTIMIZATION_CLICK_THROUGH
            }
        ],
        [OBJECTIVE_VIDEO_VIEW]: [
            {
                ui_name: 'Video View',
                platform_value: OPTIMIZATION_VIDEO_VIEW
            }
        ],
        [OBJECTIVE_WEB_CONVERSION]: [
            {
                ui_name: 'Sign Up',
                platform_value: OPTIMIZATION_SIGN_UP
            },
            {
                ui_name: 'Add to Cart',
                platform_value: OPTIMIZATION_ADD_TO_CART
            },
            {
                ui_name: 'Lead',
                platform_value: OPTIMIZATION_LEAD
            },
            {
                ui_name: 'Checkout',
                platform_value: OPTIMIZATION_CHECKOUT
            }
        ],
    }
};

export const BILLING_EVENTS = {
    [PLATFORM_FACEBOOK]: {
        [OPTIMIZATION_THRUPLAY]: [
            {
                ui_name: 'Thruplay',
                platform_value: 'THRUPLAY'
            },
            {
                ui_name: 'Impressions',
                platform_value: 'IMPRESSIONS'
            },
        ],
        [OPTIMIZATION_IMPRESSIONS]: [
            {
                ui_name: 'Impressions',
                platform_value: 'IMPRESSIONS'
            },
        ],
        [OPTMIZATION_POST_ENGAGEMENT]: [
            {
                ui_name: 'Impressions',
                platform_value: 'IMPRESSIONS'
            },
        ],
        [OPTIMIZATION_LANDING_PAGE_VIEWS]: [
            {
                ui_name: 'Impressions',
                platform_value: 'IMPRESSIONS'
            },
        ],
        [OPTIMIZATION_LINK_CLICKS]: [
            {
                ui_name: 'Link Clicks',
                platform_value: 'LINK_CLICKS'
            },
            {
                ui_name: 'Impressions',
                platform_value: 'IMPRESSIONS'
            },
        ],
        [OPTIMIZATION_REACH]: [
            {
                ui_name: 'Impressions',
                platform_value: 'IMPRESSIONS'
            },
        ],
        [OPTIMIZATION_AD_RECALL_LIFT]: [
            {
                ui_name: 'Impressions',
                platform_value: 'IMPRESSIONS'
            },
        ]
    },
    [PLATFORM_SNAPCHAT]: {
        [OPTIMIZATION_IMPRESSIONS]: [
            {
                ui_name: 'Impressions',
                platform_value: 'IMPRESSIONS'
            },
        ],
        [OPTIMIZATION_SWIPES]: [
            {
                ui_name: 'Swipes',
                platform_value: 'SWIPES'
            },
        ],
        [OPTIMIZATION_VIDEO_VIEWS_15_SEC]: [
            {
                ui_name: 'Video Views 15 Seconds',
                platform_value: 'VIDEO_VIEWS_15_SEC'
            },
            {
                ui_name: 'Impression',
                platform_value: 'IMPRESSION'
            },
        ],
        [OPTIMIZATION_VIDEO_VIEWS]: [
            {
                ui_name: 'Video Views',
                platform_value: 'VIDEO_VIEWS'
            },
            {
                ui_name: 'Impression',
                platform_value: 'IMPRESSION'
            },
        ],
        [OPTIMIZATION_PIXEL_PAGE_VIEW]: [
            {
                ui_name: 'Pixel Page View',
                platform_value: 'PIXEL_PAGE_VIEW'
            },
        ],
    },
    [PLATFORM_TIKTOK]: {
        [OPTIMIZATION_REACH]: [
            {
                ui_name: 'Cost Per Thousand',
                platform_value: 'CPM'
            },
        ],
        [OPTIMIZATION_CLICK]: [
            {
                ui_name: 'Cost Per Click',
                platform_value: 'CPC'
            },
        ],
        [OPTIMIZATION_ENGAGED_VIEW]: [
            {
                ui_name: 'Cost Per View',
                platform_value: 'CPV'
            },
        ],
    },
    [PLATFORM_PINTEREST]: {
        [OPTIMIZATION_IMPRESSIONS]: [
            {
                ui_name: 'Impression',
                platform_value: 'IMPRESSION'
            }
        ],
        [OPTIMIZATION_CLICK_THROUGH]: [
            {
                ui_name: 'Click Through',
                platform_value: 'CLICKTHROUGH'
            }
        ],
        [OPTIMIZATION_VIDEO_VIEW]: [
            {
                ui_name: 'Video View',
                platform_value: 'VIDEO_V_50_MRC'
            }
        ],
        [OPTIMIZATION_SIGN_UP]: [
            {
                ui_name: 'Impression',
                platform_value: 'IMPRESSION'
            }
        ],
        [OPTIMIZATION_ADD_TO_CART]: [
            {
                ui_name: 'Impression',
                platform_value: 'IMPRESSION'
            }
        ],
        [OPTIMIZATION_LEAD]: [
            {
                ui_name: 'Impression',
                platform_value: 'IMPRESSION'
            }
        ],
        [OPTIMIZATION_CHECKOUT]: [
            {
                ui_name: 'Impression',
                platform_value: 'IMPRESSION'
            }
        ]
    }
};

export const TIKTOK_AGES_GROUPS = [
    {
        min: 18,
        max: 24,
        name: '18-24',
        value: 'AGE_18_24'
    },
    {
        min: 25,
        max: 34,
        name: '25-34',
        value: 'AGE_25_34'
    },
    {
        min: 35,
        max: 44,
        name: '35-44',
        value: 'AGE_35_44'
    },
    {
        min: 45,
        max: 54,
        name: '45-54',
        value: 'AGE_45_54'
    },
    {
        min: 55,
        max: 100,
        name: '55-100',
        value: 'AGE_55_100'
    }
];

export const PINTEREST_AGES_GROUPS = [
    {
        min: 18,
        max: 24,
        name: '18-24',
        value: '18_24'
    },
    {
        min: 25,
        max: 34,
        name: '25-34',
        value: '25_34'
    },
    {
        min: 35,
        max: 44,
        name: '35-44',
        value: '35_44'
    },
    {
        min: 45,
        max: 49,
        name: '45-49',
        value: '45_49'
    },
    {
        min: 50,
        max: 54,
        name: '50-54',
        value: '50_54'
    },
    {
        min: 55,
        max: 64,
        name: '55-64',
        value: '55_64'
    },
    {
        min: 65,
        max: 100,
        name: '65+',
        value: '65+'
    }
];

export const DYNAMIC_DEPLOYMENT_TYPE = 'dynamic';
export const STATIC_DEPLOYMENT_TYPE = 'static';
export const NEW_CAMPAIGN_DEPLOYMENT = 'all_platforms';
export const EXISTING_CAMPAIGN_AD_DEPLOYMENT = 'platform_specific';

export const SINGLE_IMAGE_AD = 'Image - Single';
export const SINGLE_IMAGE__PRICE_IN_CREATIVE_AD = 'Image - Single - Carousel - Price in Creative';
export const SLIDESHOW_CAROUSEL_AD = 'Image - Slideshow - Carousel';
export const SINGLE_CAROUSEL_AD = 'Image - Single - Carousel';

export const FORMAT_CAROUSEL_MULTI_ITEMS = 'carousel_images_multi_items';
export const FORMAT_CAROUSEL_SLIDESHOWS = 'carousel_slideshows';
