/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tiktok': {
    width: 17,
    height: 20,
    viewBox: '0 0 17 20',
    data: '<g _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M11.217 0c-.002.28.021.56.07.837H8.873v13.217a2.772 2.772 0 01-2.766 2.65 2.772 2.772 0 01-2.236-1.15 2.81 2.81 0 001.278.32 2.772 2.772 0 002.766-2.67V0zM13.3 3.833a4.51 4.51 0 002.498.754v.734A4.58 4.58 0 0113.3 3.833z" fill-opacity=".3"/><path pid="1" d="M15.798 5.321c.315.067.636.101.958.102v3.29a7.851 7.851 0 01-4.587-1.469v6.695a6.062 6.062 0 01-10.502 4.126 6.012 6.012 0 003.475 1.1 6.075 6.075 0 006.075-6.063V6.388a7.839 7.839 0 004.58 1.488zm-9.691 2.55a5.8 5.8 0 01.817.063v3.36a2.915 2.915 0 00-.817-.128 2.766 2.766 0 00-2.242 4.389 2.772 2.772 0 011.277-5.225c.276-.008.551.024.818.095V7.87zM12.169.83c0 1.104.402 2.172 1.13 3.002A4.574 4.574 0 0111.288.83z" fill-opacity=".598"/><path pid="2" d="M11.217 13.102V6.388a7.838 7.838 0 004.587 1.47V5.301a4.58 4.58 0 01-2.504-1.47A4.574 4.574 0 0111.287.83H8.873v13.224a2.772 2.772 0 01-5.015 1.514 2.772 2.772 0 011.278-5.226c.277.003.553.046.817.128V7.915a6.056 6.056 0 00-4.292 10.22 6.011 6.011 0 003.481 1.03 6.075 6.075 0 006.075-6.063"/></g>'
  }
})
