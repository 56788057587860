/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report': {
    width: 21,
    height: 14,
    viewBox: '0 0 21 14',
    data: '<path pid="0" d="M18.5 0a2 2 0 11-.187 3.991l-3.226 3.792a2 2 0 11-3.426.429L9.673 6.62c-.33.24-.735.38-1.173.38a1.99 1.99 0 01-1.032-.287l-3.355 4.104a2 2 0 11-.775-.633l3.426-4.191a2 2 0 113.548-.144l1.961 1.572a1.991 1.991 0 012.026-.255l3.022-3.55A2 2 0 0118.5 0zm-16 11a1 1 0 100 2 1 1 0 000-2zm11-3a1 1 0 100 2 1 1 0 000-2zm-5-4a1 1 0 100 2 1 1 0 000-2zm10-3a1 1 0 100 2 1 1 0 000-2z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
