const getNumberFormats = (abbreviateNumbers = false) => {
    const abbreviationFlag = abbreviateNumbers ? 'a' : '';
    return {
        percent: '0.00%',
        currency: `$0,0.00${abbreviationFlag}`,
        currencyRound: `$0,0${abbreviationFlag}`,
        number: `0,0${abbreviationFlag}`,
        decimal: `0,0.00${abbreviationFlag}`,
        time: '00:00:00',
    };
};

export default getNumberFormats;
