<template>
    <div class="popup-wrapper">
        <div class="popup">
            <div
                class="popup__close"
                @click="closePopup">
                <icon
                    name="close"
                    size="20px" />
            </div>
            <div class="popup__files">
                <file-previews-list
                    :original-asset="originalAsset"
                    :selected-platforms="platforms"
                    :popup-active="true" />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import FilePreviewsList from '@/components/ad-studio/editing/common/FilePreviewsList';
import { mapGetters } from 'vuex';
import {
    IMAGE_ASSET_TYPE,
    VIDEO_ASSET_TYPE
} from '@/components/ad-studio/store/constants.js';
import EventBus from '@/event-bus';

export default {
    components: {
        Icon,
        FilePreviewsList
    },
    props: {
        activePlatform: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            IMAGE_ASSET_TYPE,
            VIDEO_ASSET_TYPE
        };
    },
    computed: {
        ...mapGetters({
            originalAsset: 'adStudio/selectedAdOriginalAsset',
            selectedAdPlatforms: 'adStudio/selectedAdPlatforms',
            selectedAdCustomized: 'adStudio/selectedAdCustomized',
        }),
        originalAssetUrl() {
            return this.originalAsset.url;
        },
        originalAssetFileType() {
            return this.originalAsset.asset_type.name;
        },
        platforms() {
            if (this.selectedAdCustomized) {
                return [this.activePlatform];
            } else {
                return this.selectedAdPlatforms;
            }
        },
    },
    methods: {
        closePopup() {
            this.$emit('close-popup');
            EventBus.$emit('close-popup');
        }
    }
};
</script>

<style lang="scss" scoped>
.popup-wrapper{
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(5px);
}
.popup{
    position: absolute;
    top:30px;
    bottom:30px;
    left: 10%;
    right:10%;
    background: white;
    border-radius: 10px;
    display: flex;
    &__original{
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &__files{
        width: 100%;
        padding: 30px;
        overflow: auto;
    }
    &__note{
        font-size: 20px;
        color: #03A2EA;
        margin-bottom: 15px;
    }
    &__image{
        width: 150px;
        img, video{
            max-width: 100%;
            height: auto;
        }
    }
    &__close{
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px;
        cursor: pointer;
    }
}
.asset-width{
    width: 150px;
}
</style>
