/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microsoft_light': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<g _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M0 0h12.35v12.35H0z"/><path pid="1" opacity=".499" d="M0 13.65h12.35V26H0z"/><path pid="2" opacity=".442" d="M13.65 0H26v12.35H13.65z"/><path pid="3" opacity=".716" d="M13.65 13.65H26V26H13.65z"/></g>'
  }
})
