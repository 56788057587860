import ApiRepository from '@/api-repository';
import {
    SET_CREATIVE,
    ADD_NEW_CREATIVE
} from '../mutation-types';

const apiRepository = new ApiRepository();

export default {
    state: {
        creatives: [],
        creativesForDeployment: [],
        selectedCreatives: new Set()
    },
    getters: {},
    actions: {
        async getAdsFromApi({ commit }) {
            try {
                const response = await apiRepository.getAds();
                console.log(response.data);
                commit(SET_CREATIVE, response.data.data);
            } catch (error) {
                console.error('Error loading creatives', error);
            }
        },
        addCreativesForDeployment({ commit }, payload) {
            commit(ADD_NEW_CREATIVE, payload);
        }
    },
    mutations: {
        [SET_CREATIVE](state, payload) {
            state.creatives = payload;
        },
        [ADD_NEW_CREATIVE](state, payload) {
            // first of all we need to check if already selected?
            if (state.selectedCreatives.has(payload)) {
                state.selectedCreatives.delete(payload);
            } else {
                state.selectedCreatives.add(payload);
            }

            // main logic after checking
            if (state.selectedCreatives.has(payload)) {
                const creative = state.creatives.filter(creative => creative.id == payload);
                state.creativesForDeployment.push(creative[0]);
            } else {
                state.selectedCreatives.delete(payload);
                state.creativesForDeployment = state.creativesForDeployment.filter(creative => creative.id != payload);
            }
        },
    }
};
