<template>
    <styled-slideout
        :value="value"
        @input="toggle($event)">
        <div class="pa-5">
            <div class="ma-2 layout justify-space-between row">
                <h2>Business Manager Access</h2>
                <div>
                    <v-progress-circular
                        v-if="loadingBmAccessStatus || loadingBmInvite"
                        size="30"
                        color="primary"
                        indeterminate />
                </div>
            </div>


            <styled-callout
                v-if="bmAccessStatus == 'user_already_in_bm'"
                class="layout align-center mb-3">
                <div class="flex shrink mr-3">
                    <status-icon size="25" />
                </div>
                <div class="flex">
                    <h2>You Have Business Manager Access!</h2>
                    <p>
                        You have been successfully added to {{ currentDealer.agency.name }}'s
                    </p>
                </div>
            </styled-callout>


            <div v-if="bmAccessStatus == 'user_pending_bm_invite'">
                <styled-callout
                    class="mb-3">
                    <div class="layout align-center">
                        <div class="flex shrink mr-3">
                            <status-icon
                                value="warning"
                                size="25" />
                        </div>
                        <div class="flex">
                            <h2>You need to accept the invitation!</h2>
                            <p>
                                Please accept the invitation sent to {{ user.email }}
                                or use the link below to finalize access to
                                {{ currentDealer.agency.name }}'s business manager.
                            </p>
                            <p class="text-xs-center">
                                <a
                                    :href="bmInvite"
                                    target="_blank">{{ bmInvite }}</a>
                                <action-button
                                    v-clipboard:copy="bmInvite"
                                    v-clipboard:success="onCopy"
                                    icon="copy" />
                            </p>
                        </div>
                    </div>
                    <div class="layout align-center">
                        <div class="flex grow text-xs-center ml-5 mr-3">
                            <template v-if="bmAccessDetectionActive">
                                <p>
                                    Detecting access<animated-dots />
                                </p>
                                <styled-pulse-loader :size="50" />
                            </template>
                            <template v-else>
                                <p>Could not detect access</p>
                                <p>
                                    <styled-button
                                        outline
                                        @click="initiateBmAccessDetection()">
                                        Retry
                                    </styled-button>
                                </p>
                            </template>
                        </div>
                    </div>
                </styled-callout>
            </div>


            <div v-if="bmAccessStatus == 'user_not_in_bm'">
                <styled-callout
                    class="mb-3">
                    <div class="layout align-center">
                        <div class="flex shrink mr-3">
                            <status-icon
                                value="error"
                                size="25" />
                        </div>
                        <div class="flex mr-5">
                            <h2>You Need Business Manager Access</h2>
                            <p>
                                We couldn't find
                                <manage-user-email
                                    :user="user"
                                    @update="checkBmAccess()" />
                                in {{ currentDealer.agency.name }}'s
                                business manager. You will need access to complete this step or will need
                                to coordinate with someone who does.
                                Would you like us to send you an invite to gain
                                {{ $userHasRole(['super_admin', 'admin']) ? 'admin' : 'employee' }} access?
                            </p>
                        </div>
                    </div>
                </styled-callout>
                <div class="text-center">
                    <styled-button
                        class=""
                        outline
                        :loading="loadingBmInvite"
                        @click="sendBmInvite()">
                        SEND INVITE
                    </styled-button>
                </div>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import { mapState } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledCallout from '@/components/globals/StyledCallout';
import StyledButton from '@/components/globals/StyledButton';
import AnimatedDots from '@/components/globals/AnimatedDots';
import ActionButton from '@/components/globals/ActionButton';
import StatusIcon from '@/components/globals/StatusIcon';
import ManageUserEmail from '@/components/globals/ManageUserEmail';
export default {
    components: {
        StyledSlideout,
        StyledCallout,
        StyledButton,
        AnimatedDots,
        ActionButton,
        StatusIcon,
        ManageUserEmail,
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            bmAccessStatus: null,
            bmAccessDetectionTimeout: false,
            bmAccessDetectionActive: false,
            bmAccessDetectionTries: 0,
            loadingBmInvite: false,
            loadingBmAccessStatus: false,
            bmAccessInterval: null,
            bmInvite: null
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
        })
    },
    watch: {
        value(newValue) {
            // Check if the user has opened the slide out 
            // and if they did refresh the bm access check
            if (newValue === true) {
                this.checkBmAccess();
            }
        }
    },
    created() {
        this.checkBmAccess();
    },
    methods: {
        toggle(value) {
            this.$emit('input', value);
            // Reset the status when closed
            if (value === false) {
                this.agreementAccepted = false;
            }
        },
        async sendBmInvite() {
            try {
                this.loadingBmInvite = true;
                this.bmAccessStatus = null;
                const response = await this.$http.post(`/agencies/${this.currentDealer.agency.id}/send-bm-invite`, {
                    'role': this.$userHasRole(['super_admin', 'admin']) ? 'ADMIN' : 'EMPLOYEE'
                });

                this.bmInvite = response.data.invitation_url;
                this.bmAccessStatus = response.data.status;
            } catch (error) {
                console.log(error);
            } finally {
                this.loadingBmInvite = false;
            }
        },
        async checkBmAccess() {
            try {
                this.loadingBmAccessStatus = true;
                const response = await this.$http.post(`/agencies/${this.currentDealer.agency.id}/check-bm-access`);

                if (response.data.status === 'user_pending_bm_invite' && !this.bmInvite) {
                    this.bmInvite = response.data.invitation_url;
                }

                this.bmAccessStatus = response.data.status;
            } catch (error) {
                console.log(error);
            } finally {
                this.loadingBmAccessStatus = false;
            }
        },
        initiateBmAccessDetection() {
            // Reset initial data
            this.bmAccessDetectionTimeout = false;
            this.bmAccessDetectionTries = 0;
            this.bmAccessDetectionActive = true;

            // Start the interval
            this.bmAccessInterval = setInterval(() => {
                this.bmAccessDetectionTries++;
                // When the tries reach their threshold stop
                if (this.bmAccessDetectionTries > 5) {
                    this.bmAccessDetectionTimeout = true;
                    this.bmAccessDetectionActive = false;
                    return clearInterval(this.bmAccessInterval);
                }
                this.checkBmAccess();
            }, 5000);
        }
    }
};
</script>
