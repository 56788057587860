<template>
    <div>
        <div
            v-if="!loading"
            class="">
            <input
                :id="assetConfig.aspect_ratio"
                class="file-input upload"
                type="file"
                @change="handleFiles($event)">
            <a>
                <label
                    :for="assetConfig.aspect_ratio"
                    class="pointer">
                    Upload custom {{ assetConfig.aspect_ratio }}
                </label>
            </a>
        </div>
        <div>
            <div
                v-if="errorMessage && !loading"
                class="warning-text">
                <span v-html="errorMessage" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {
    IMAGE_ASSET_TYPE,
    VIDEO_ASSET_TYPE
} from '@/components/ad-studio/store/constants.js';
import EventBus from '@/event-bus';
export default {
    props: {
        assetConfig: {
            type: Object,
            required: false,
            default: () => {}
        },
        selectedPlatforms: {
            type: Array,
            required: false,
            default: () => []
        },
        advancedMediaControls: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            errorMessage: '',
            fileName: '',
            progress: 0,
            imageExtensions: ['jpg', 'jpeg', 'png'],
            videoExtensions: ['mov', 'mp4'],
            IMAGE_ASSET_TYPE,
            VIDEO_ASSET_TYPE,
            duration: 0,
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            user: state => state.user.user,
            agency: state => state.agency.currentAgency,
            adIndex: state => state.adStudio.adIndex,
            ads: state => state.adStudio.ads,
        }),
        extension() {
            const parts = this.fileName.split('.');
            return parts[parts.length - 1];
        },
        assetType() {
            return this.imageExtensions.includes(this.extension) ? IMAGE_ASSET_TYPE : VIDEO_ASSET_TYPE;
        },
        allowedExtensionsForMediaType() {
            return this.assetConfig.asset_type === IMAGE_ASSET_TYPE ? this.imageExtensions : this.videoExtensions;
        },
        allowedExtension() {
            return this.imageExtensions.includes(this.extension.toLowerCase()) || this.videoExtensions.includes(this.extension.toLowerCase());
        },
        isImageExtension() {
            return this.imageExtensions.includes(this.extension.toLowerCase());
        },
        isVideoExtension() {
            return this.videoExtensions.includes(this.extension.toLowerCase());
        },
        customizedPerPlatform() {
            return this.ads[this.adIndex]?.configuration?.customized_per_platform;
        },
        loading: {
            get() {
                return this.assetConfig.loading;
            },
            set(value) {
                this.assetConfig.loading = value;
            }
        },
    },
    methods: {
        async handleFiles(e) {
            this.resetFileData();
            let fileList = e.target.files || e.dataTransfer.files;

            if (fileList.length > 1) {
                this.errorMessage = 'Please choose only one file';
                return;
            }

            let file = fileList[0];
            this.fileName = file.name;

            if (file.size > 524288000) {
                this.errorMessage = 'The file exceeds the max upload size of 500mb.';
                return;
            }

            if (this.assetConfig.asset_type === IMAGE_ASSET_TYPE && !this.isImageExtension) {
                this.errorMessage = `Please upload an image file: ${this.allowedExtensionsForMediaType.join(', ')}`;
                return;
            }

            if (this.assetConfig.asset_type === VIDEO_ASSET_TYPE && !this.isVideoExtension) {
                this.errorMessage = `Please upload a video file: ${this.allowedExtensionsForMediaType.join(', ')}`;
                return;
            }

            if (this.assetConfig.asset_type === IMAGE_ASSET_TYPE) {
                const imageDimensions = await this.imageData(file);
                if (imageDimensions.width < 500 || imageDimensions.height < 500) {
                    this.errorMessage = 'Media must be at least 500x500';
                    return;
                }
            }

            if (this.assetConfig.asset_type === VIDEO_ASSET_TYPE) {
                const videoDimensions = await this.videoMetaData(file, 'dimensions');
                const duration = await this.videoMetaData(file, 'duration');
                if (videoDimensions.width < 500 || videoDimensions.height < 500) {
                    this.errorMessage = 'Media must be at least 500x500';
                    return;
                }
                if (duration > 31) {
                    this.errorMessage = 'The video duration is over 30 seconds';
                    return;
                }
                this.duration = duration;
            }

            this.uploadFile(file, this.adIndex);
        },
        resetFileData() {
            this.errorMessage = '';
            this.loading = false;
            this.fileName = '';
            this.progress = 0;
            this.duration = 0;
        },
        async videoMetaData(file, option) {
            const getVideoData = () => new Promise(resolve => {
                let video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = () => {
                    window.URL.revokeObjectURL(video.src);
                    if (option === 'dimensions') {
                        const width = video.videoWidth;
                        const height = video.videoHeight;
                        resolve({ width, height });
                    }
                    if (option === 'duration') {
                        resolve(video.duration);
                    }
                };
                video.src = URL.createObjectURL(file);
            });
            return await getVideoData();
        },
        async imageData(file) {
            const getImageData = () => new Promise(resolve => {
                let img = new Image();
                img.onload = function() {
                    const height = img.height;
                    const width = img.width;
                    resolve({ width, height });
                };
                img.src = URL.createObjectURL(file);
            });
            return await getImageData();
        },
        async uploadFile(file, adIndex) {
            this.loading = true;
            // Upload the file to cloudinary
            let formData = new FormData();
            formData.append('file', file);
            formData.append('display_name', this.fileName);
            formData.append('agency_id', this.agency.id);
            this.assetType === VIDEO_ASSET_TYPE ? formData.append('duration', this.duration) : null;
            // Upload asset to cloudnary and get the asset object back
            const asset = await this.$http.axios.post('/assets/upload', formData).catch(error => {
                this.loading = false;
                this.errorMessage = error;
            });

            let updatedAsset = asset.data;
            // Update the width and height of the asset to match the asset config
            updatedAsset.width = this.assetConfig.width;
            updatedAsset.height = this.assetConfig.height;
            // Update the aspect ratio of the asset to match the asset config
            updatedAsset.aspect_ratio = this.assetConfig.aspect_ratio;

            // Insert the asset into the platforms ad config
            this.$store.commit('adStudio/insertAssetIntoAd', {
                adIndex,
                asset: updatedAsset,
                platforms: this.customizedPerPlatform ? this.selectedPlatforms : this.assetConfig.platforms,
            });

            // Fire off a transformation to get it to actually be the correct size
            EventBus.$emit('runTransformation', {
                assetConfig: this.assetConfig,
            });

            this.loading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.upload {
    display: none;
}
.pointer {
    cursor: pointer;
}
.warning-text {
    color: #ff0000; // todo use variable red if we have one
    font-size: 12px;
    margin-top: 10px;
    font-weight: 600;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>