/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microsoft': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<g _fill="#000" fill-rule="evenodd"><path pid="0" d="M0 0h10v10H0zM0 11h10v10H0zM11 0h10v10H11zM11 11h10v10H11z"/></g>'
  }
})
