<template>
    <div class="ad-studio__footer">
        <!-- Step 1 of ad editing opened from creating a new ad -->
        <template v-if="stepOneActive">
            <styled-button
                :disabled="!validateAssetsAndSelectedPlatforms"
                small
                @click="finishFirstStep">
                <span
                    class="font-weight-bold">Next</span>
            </styled-button>
        </template>
        <!-- Step 2 of ad editing opened from creating a new ad -->
        <template v-if="(stepTwoActive && !openFromDeployment) || editingExistingAd">
            <styled-button
                class="mr-3"
                :loading="loading"
                small
                @click="saveAndExit">
                <span class="font-weight-bold">Save &amp; Exit</span>
            </styled-button>
            <styled-button
                :loading="loading"
                small
                @click="saveAndDeploy">
                <span class="font-weight-bold">Save &amp; Deploy</span>
            </styled-button>
        </template>
        <!-- Step 2 of ad editing opened from layered designer-->
        <template v-if="stepTwoActive && isOpenedFromLayeredDesigner && openFromDeployment">
            <styled-button
                class="mr-3"
                :loading="loading"
                small
                @click="saveAndExit">
                <span class="font-weight-bold">Save &amp; Exit</span>
            </styled-button>
            <styled-button
                :loading="loading"
                small
                @click="saveAndDeploy">
                <span class="font-weight-bold">Save &amp; Deploy</span>
            </styled-button>
        </template>
        <!-- Step 2 of ad editing opened from ad deployment -->
        <template v-if="stepTwoActive && openFromDeployment && !isOpenedFromLayeredDesigner && !editingExistingAd">
            <styled-button
                :loading="loading"
                small
                @click="backToDeployment">
                <span class="font-weight-bold">Back to Deployment</span>
            </styled-button>
        </template>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import { mapGetters, mapState } from 'vuex';
import { PLATFORM_ASSET_CONFIGURATIONS } from '@/components/ad-studio/store/constants.js';
import EventBus from '@/event-bus.js';
import { STATIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';

export default {
    components: {
        StyledButton
    },
    props: {
        secondStepActive: {
            type: Boolean,
            default: false,
        },
        openFromDeployment: {
            type: Boolean,
            default: false,
        },
        adIndex: {
            type: Number,
            required: true
        },
        formIsValid: {
            type: Boolean,
            required: true
        },
        isOpenedFromLayeredDesigner: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            stepOneActive: true,
            stepTwoActive: false,
            loading: false,
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user,
        }),
        ...mapState('adStudio', {
            ads: (state) => state.ads,
            showValidationErrors: (state) => state.showValidationErrors,
            editingExistingAd: (state) => state.editingExistingAd,
        }),
        ...mapGetters({
            selectedAdPlatforms: 'adStudio/selectedAdPlatforms',
        }),
        disabled() {
            if (!this.showValidationErrors) {
                return false;
            }
            return !this.formIsValid;
        },
        selectedPlatformsLength() {
            return this.selectedAdPlatforms.length;
        },
        validateAssetsAndSelectedPlatforms() {
            const originalAssetTypeName = this.ads?.[this.adIndex]?.configuration?.all_platforms?.creatives?.[0]?.assets?.[0]?.asset_type?.name;
            return this.selectedAdPlatforms?.length > 0 && this.selectedAdPlatforms.every((platform) => {
                const filteredAssetConfigs = PLATFORM_ASSET_CONFIGURATIONS.filter((assetConfig) => {
                    return assetConfig.platforms.includes(platform) && assetConfig.asset_type === originalAssetTypeName;
                });
                return filteredAssetConfigs.every((assetConfig) => {
                    return this.ads?.[this.adIndex]?.configuration?.platform_specific?.[platform]?.creatives?.[0]?.assets.some((asset) => {
                        return asset.asset_type.name === assetConfig.asset_type;
                    });
                });
            });
        }
    },
    watch: {
        secondStepActive: {
            immediate: true,
            handler() {
                if (this.secondStepActive) {
                    this.stepOneActive = false;
                    this.stepTwoActive = true;
                } else {
                    this.stepOneActive = true;
                    this.stepTwoActive = false;
                }
            }
        },
        formIsValid() {
            if (this.formIsValid) {
                this.$store.commit('adStudio/setShowValidationErrors', { value: false });
            }
        }
    },
    methods: {
        runValidationLogic() {
            if (!this.formIsValid) {
                this.$store.commit('adStudio/setShowValidationErrors', { value: true });
                this.$flash('All Ads need to be valid before saving!', 'red');
                // jump the user to the first ad with an error
                const invalidAdIndex = this.ads.findIndex(ad => {
                    if (ad.deleted) {
                        return;
                    }
                    // If the ad is customized we look to see if the selected platform has a valid form
                    // if (this.customizedPerPlatform) {
                    //     return !ad?.configuration?.platform_specific[this.activePlatform]?.form_valid;
                    // }
                    // If the ad is not customized we look to see if all the ads have a valid form
                    return !ad?.configuration?.selected_platforms?.every((platform) => {
                        return ad?.configuration?.platform_specific?.[platform]?.form_valid;
                    });
                });

                if (invalidAdIndex !== -1) {
                    this.$store.commit('adStudio/setCurrentAdIndex', invalidAdIndex);
                    // Trigger the validation on the form
                    this.$nextTick(() => {
                        EventBus.$emit('validate-fields');
                    });
                    // Scroll to the first error but wait for the first ad to load first
                    setTimeout(() => {
                        const el = document.querySelector('.v-input.error--text');
                        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }, '250');
                    return true;
                }
                return false;
            }
        },
        finishFirstStep() {
            this.$emit('finish-first-step', this.adIndex);
            // If we only have one platform selected, turns on customizePerPlatform
            if (this.selectedPlatformsLength === 1) {
                this.$store.commit('adStudio/customizePerPlatform', { adIndex: this.adIndex });
                this.$store.commit('adStudio/changeActivePlatform', {
                    adIndex: this.adIndex,
                    platform: this.selectedAdPlatforms[0]
                });
            }
        },
        async deploy(ads) {
            EventBus.$emit('open-deployment-modal', {
                configuration: { ads, platforms: this.selectedAdPlatforms },
                userId: this.user.id,
                dealerId: this.dealer.id,
                deployment_type: STATIC_DEPLOYMENT_TYPE
            });
        },
        async save() {
            const validAds = this.ads.filter(ad => ad.deleted !== true);
            const adPromises = validAds.map(ad => (
                this.$apiRepository.createAd({
                    ...ad
                })
            ));

            const responses = await Promise.all(adPromises);
            return responses.map(response => response.data.data);
        },
        async edit() {
            const validAds = this.ads.filter(ad => ad.deleted !== true);
            const adPromises = validAds.map(ad => (
                this.$apiRepository.editAd({
                    ...ad
                })
            ));

            const responses = await Promise.all(adPromises);
            return responses.map(response => response.data.data);
        },
        async saveAndExit() {
            if (this.runValidationLogic()) {
                return;
            }

            try {
                this.loading = true;

                if(!this.formIsValid) {
                    this.$flash('All Ads need to be valid before saving!', 'red');
                    return;
                }

                if(this.ads.length) {
                    if (this.editingExistingAd) {
                        await this.edit();
                    } else {
                        await this.save();
                    }

                    this.$emit('close');
                }
            } catch(err) {
                // need better error handling
                console.log(err);
            } finally {
                this.loading = false;
            }
        },
        async saveAndDeploy() {
            if (this.runValidationLogic()) {
                return;
            }

            try {
                this.loading = true;

                if ((!this.formIsValid) && this.validateAssetsAndSelectedPlatforms) {
                    this.$flash('All Ads need to be valid before deploying!', 'red');
                    return;
                }

                if (this.ads.length) {
                    const ads = await (this.editingExistingAd ? this.edit() : this.save());

                    await this.deploy(ads);
                }
            } catch(err) {
                console.log(err);
            } finally {
                this.loading = false;
            }
        },
        backToDeployment() {
            if (this.runValidationLogic()) {
                return;
            }

            this.$emit('close');
        }
    }
};
</script>

<style lang="scss" scoped>
.ad-studio__footer{
    position: absolute;
    bottom: 0;
    height: 50px;
    background: #EBF0F3;
    left:0;
    right: 0;
    border-top: 1px solid #B4B9BB;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 60px;
    z-index: 2;
}
</style>
