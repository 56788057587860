<template>
    <styled-dialog
        custom-content-class="layered-designer-dialog"
        :fullscreen="true"
        :value="isLayeredDesignerActive">
        <layered-designer />
        <styled-dialog
            custom-content-class="asset-library-dialog"
            :fullscreen="true"
            :value="isAssetLibraryActive">
            <asset-library
                v-if="isAssetLibraryActive"
                :mode="assetLibraryMode"
                :asset-type="assetLibraryAssetType"
                @selection-finished="onSelectionFinished" />
        </styled-dialog>
    </styled-dialog>
</template>

<script>
    import StyledDialog from '@/components/globals/StyledDialog';

    import LayeredDesigner from './LayeredDesigner';
    import AssetLibrary from '@/components/creative-studio/assets/AssetLibrary';

    import { mapGetters, mapActions } from 'vuex';
import { EVENT_DESIGNER_CLOSE, EVENT_DESIGNER_OPEN } from '@/helpers/globals';

    export default {
        components: {
            StyledDialog,
            LayeredDesigner,
            AssetLibrary
        },
        props: {
            value: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            ...mapGetters([
                'isLayeredDesignerActive',
                'isAssetLibraryActive',
                'assetLibraryAssetType',
                'assetLibraryMode'
            ])
        },
        watch: {
            value(newActiveStatus) {
                if (this.isLayeredDesignerActive !== newActiveStatus) {
                    this.setLayeredDesignerActiveStatus({ isActive: newActiveStatus, mode: 'DEFAULT' });
                }
            },
            isLayeredDesignerActive(newActiveStatus) {
                this.$emit('input', newActiveStatus);
            }
        },
        created() {
            this.$dl.newEvent(EVENT_DESIGNER_OPEN);
        },
        destroyed() {
            this.$dl.newEvent(EVENT_DESIGNER_CLOSE);
        },
        methods: {
            ...mapActions([
                'setLayeredDesignerActiveStatus',
                'setAssetLibraryActiveStatus'
            ]),
            onSelectionFinished() {
                this.setAssetLibraryActiveStatus(false);
                document.querySelector('.card-canvas-container')?.classList?.remove('overflow-hidden');
            }
        }
    };
</script>

<style lang="scss" >

.v-dialog.layered-designer-dialog {
    overflow: hidden;
    margin: $layered-designer-modal-margin;
    height: calc(100% - (#{$layered-designer-modal-margin} * 2));
    width: calc(100% - (#{$layered-designer-modal-margin} * 2));
    border-radius: 10px;
    .body {
        padding: 0px !important;
    }
}

.v-dialog.asset-library-dialog {
    height: 100%;
    margin: $asset-library-modal-margin;
    height: calc(100% - (#{$asset-library-modal-margin} * 2));
    width: calc(100% - (#{$asset-library-modal-margin} * 2));
    border-radius: 5px;
}

.overflow-hidden {
    overflow: hidden !important;
}

.asset-library-dialog {
    .dialog, .body {
        height: 100%;
        background-color: $greyLight;
    }
}

</style>