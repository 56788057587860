<template>
    <div>
        <loader v-if="loading" />
        <div v-else-if="accepted">
            <h3>Data Sharing Agreement Accepted</h3>
            <p>
                You have accepted the data sharing agreement
                and are sharing data with Facebook
            </p>
        </div>
        <div v-else>
            <h2>Facebook Data Sharing Agreement</h2>
            <p>
                Review &amp; accept the agreement below
                if you would like to share data with Facebook.
                This effectively disables Limited Data Use
                which protects consumers under privacy legislation
                like the California Consumer Privacy Act.  Please
                ensure you understand these policies before
                sharing data.
            </p>
            <div class="agreement-content mb-4">
                <styled-scrolling-area
                    :style="{ height: '300px', padding: '20px' }"
                    class="">
                    <div v-html="lduAgreement.body" />
                </styled-scrolling-area>
            </div>
            <v-checkbox
                v-model="agreementConfirmed"
                color="primary"
                class="styled-checkbox mb-5">
                <template #label>
                    Check here to confirm that you agree to the terms
                    above and want to share data with Facebook
                </template>
            </v-checkbox>
            <div class="text-xs-center">
                <styled-button
                    :disabled="!agreementConfirmed"
                    :loading="acceptAgreementLoading"
                    @click="acceptAgreement()">
                    ACCEPT &amp; SHARE DATA
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from './Loader';
import StyledScrollingArea from './StyledScrollingArea';
import StyledButton from './StyledButton';
import { mapState } from 'vuex';

export default {
    components: {
        Loader,
        StyledScrollingArea,
        StyledButton
    },
    props: {
        accepted: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            lduAgreement: null,
            error: null,
            agreementConfirmed: false,
            acceptAgreementLoading: false,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.error = null;
            this.loading = true;
            const status = await this.getDealerLduAgreementStatus();
            if (status === false) {
                await this.getLduAgreement();
            }
            this.loading = false;
        },
        async getLduAgreement() {
            try {
                const agreement = await this.$apiRepository.getLegalAgreement('limited_data_use_opt_out');
                this.lduAgreement = agreement;
            } catch (error) {
                console.error('Error retrieving LDU agreement', error);
                this.error = 'Error retrieving LDU agreement';
            }
        },
        async getDealerLduAgreementStatus() {
            try {
                const response = await this.$apiRepository.getDealerAgreement(this.currentDealer.id, 'limited_data_use_opt_out');
                const status = !!response.data?.id;
                this.$emit('update:accepted', status);
                return status;
            } catch (error) {
                console.error('Error retrieving LDU agreement status', error);
                this.error = 'Error retrieving LDU agreement status';
            }
        },
        async acceptAgreement() {
            try {
                this.acceptAgreementLoading = true;
                const data = {
                    dealerId: this.currentDealer.id,
                    legalAgreementName: this.lduAgreement.name,
                    document: this.lduAgreement.body,
                };
                const response = await this.$apiRepository.acceptDealerAgreement(data);
                if (response.data?.success) {
                    this.$emit('update:accepted', true);
                    this.$emit('complete');
                }
            } catch (error) {
                console.error('Error retrieving LDU agreement status', error);
                this.error = 'Error retrieving LDU agreement status';
            } finally {
                this.acceptAgreementLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">

.agreement-content {
    color: $gray-dark;
    border: 1px solid rgba($gray, 0.4);
    font-size: 12px;
    background-color: rgba($gray, 0.05);
    font-family:'Courier New', Courier, monospace;
    h1, h2, h3, h4 {
        font-weight: bold;
        margin-bottom: 1rem;
        color: $gray-dark;
    }
}
</style>
