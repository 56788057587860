<template>
    <div>
        <svg
            :height="radius * 2"
            :width="radius * 2"
            :view-box="`0 0 ${radius * 2} ${radius * 2}`">
            <circle
                stroke="#D3D9DB"
                :stroke-dasharray="circumference + ' ' + circumference"
                style="stroke-dashoffset: 0;"
                :stroke-width="stroke"
                fill="transparent"
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius" />
            <circle
                stroke="#03A2EA"
                :stroke-dasharray="circumference + ' ' + circumference"
                :style="{ strokeDashoffset: strokeDashoffset }"
                :stroke-width="stroke"
                fill="transparent"
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius" />
        </svg>
    </div>
</template>

<script>

export default {
    props: {
        radius: {
            type: [Number, String],
            required: true
        },
        progress: {
            type: Number,
            required: true
        },
        stroke: {
            type: [Number, String],
            required: true
        },
    },
    data() {
        const normalizedRadius = this.radius - this.stroke * 2;
        const circumference = normalizedRadius * 2 * Math.PI;
        return {
            normalizedRadius,
            circumference,
        };
    },
    computed: {
        strokeDashoffset() {
            return this.circumference - this.progress / 100 * this.circumference;
        },
    },
};
</script>

<style lang="scss" scoped>
circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}
</style>
