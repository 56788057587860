<template>
    <styled-tooltip
        open-delay="0"
        close-delay="0"
        nudge-top="10"
        :position="position">
        <template
            v-if="hasTooltip"
            #content>
            <div
                v-if="!!fieldProps"
                class="custom--tooltip--container">
                <div
                    class="flex">
                    <icon
                        color="red"
                        :name="channel" />
                </div>
                <div class="ml-3">
                    <span class="red--text">{{ capitalizedChannel }} copy limit</span>
                    <p class="text--tooltip">
                        {{ capitalizedChannel }}'s copy limit for the
                        "{{ parsedFieldName }}" field is {{ maxLengthField }} characters.
                        We will automatically crop any that exceeds this character limit or you can
                        customize per platform.
                    </p>
                </div>
            </div>
            <div v-else>
                {{ tooltip }}
            </div>
        </template>
        <icon
            :name="icon"
            :color="color"
            :size="size" />
    </styled-tooltip>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import Icon from '@/components/globals/Icon';
import { capitalizeWord } from '@/helpers/capitalizeWord';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';

export default {
    name: 'IconWithTooltip',
    components: {
        Icon,
        StyledTooltip,
    },
    props: {
        tooltip: {
            type: String,
            default: ''
        },
        icon: {
          type: String,
          required: true,
        },
        color: {
            type: String,
            default: '#909FA8'
        },
        size: {
            type: String,
            default: '20'
        },
        hasTooltip: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            required: false,
            default: 'top'
        },
        fieldProps: {
            type: Object,
            required: false,
            default: () => {}
        },
        channel: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            capitalizeWord,
            linkAdStudioSupport: 'https://support.buyerbridge.com/knowledge/how-to-ad-builder'
        };
    },
    computed: {
        capitalizedChannel() {
            return this.capitalizeWord(this.channel);
        },
        parsedFieldName() {
            return this.fieldProps?.ui_name;
        },
        maxLengthField() {
            return lengthValidatorConstants[this.fieldProps?.key]?.[this.channel]?.maxLength;
        }
    },
};
</script>

<style lang="scss" scoped>
.single-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-left: 10px;
  &.active{
    &:before{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid #03A2EA;
      transform: rotate(45deg);
      top: calc(100% + 5px);
      left: 0;
      z-index: 1;
    }
    &:after{
      content: '';
      position: absolute;
      width: 40px;
      height: 20px;
      background: white;
      top: calc(100% + 16px);
      left:-10px;
      z-index: 2;
    }
  }
  &.meta-icon{
    display: inline-block;
    transform: translateY(2px);
    &:after{
      top: calc(100% + 14px);
    }
  }
}
.text--tooltip {
  text-align: justify;
  justify-content: center;
}
.custom--tooltip--container {
  display: flex;
  flex-direction: row;
}
</style>
