/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'performance': {
    width: 20,
    height: 17,
    viewBox: '0 0 20 17',
    data: '<path pid="0" d="M10 0c5.523 0 10 4.477 10 10 0 2.27-.757 4.364-2.031 6.043a1 1 0 01-1.59.534l-2-1.587-.094-.084a1 1 0 011.336-1.483l.944.75A7.741 7.741 0 0017.778 10c0-2.097-.83-4-2.179-5.398l-.818 1.023a1 1 0 01-1.634-1.147l.072-.103.82-1.023A7.728 7.728 0 0011 2.286L11 3l-.007.117A1 1 0 019 3v-.714a7.728 7.728 0 00-3.039 1.066l.82 1.023.072.103A1 1 0 015.22 5.625l-.818-1.023A7.767 7.767 0 002.48 8H3a1 1 0 01.117 1.993L3 10h-.778c0 1.505.428 2.91 1.168 4.1l.942-.845a1 1 0 011.424 1.4l-.088.09-2 1.793a1 1 0 01-1.638-.5l.076.102A9.957 9.957 0 010 10C0 4.477 4.477 0 10 0zm0 8a2 2 0 011.732 1H15a1 1 0 01.117 1.993L15 11l-3.268.001A2 2 0 1110 8z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
