<template>
    <v-autocomplete
        ref="selector"
        :items="agencies"
        :value="currentAgency"
        item-text="name"
        item-value="id"
        return-object
        :loading="agenciesLoading ? 'primary' : false"
        class="agency-select styled-select"
        append-icon="arrow_drop_down"
        color="grey lighten-2"
        label="Organization"
        placeholder="Select..."
        required
        @input="onInput">
        <template #no-data>
            <v-list-tile>
                <v-list-tile-title>
                    {{ (agenciesLoading) ? 'Loading...' : 'No agencies found' }}
                </v-list-tile-title>
            </v-list-tile>
        </template>
    </v-autocomplete>
</template>

<script>
import { get } from 'lodash';
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        noStyle: {
            type: Boolean,
            default: false
        },
        styled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            agencies: (state) => state.agency.agencies,
            currentAgency: (state) => state.agency.currentAgency,
            agenciesLoading: (state) => state.agency.agenciesLoading,
        })
    },
    methods: {
        ...mapActions([
            'setBrandFromAgency'
        ]),
        onInput(agency) {
            this.updateAgency(agency);
        },
        updateAgency(agency) {

            // If we're on a agency route we can change the agency
            // by changing the URL which will automatically register the
            // target dealer
            const routeAgencyId = get(this.$route, 'params.agency_id', null);
            if (routeAgencyId !== null &&
                parseInt(routeAgencyId) !== agency.id) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        agency_id: agency.id
                    }
                });
            }
            // Otherwise just dispatch an action to update the agency by id
            else {
                this.$store.dispatch('updateAgencyById', agency.id);
            }
        }
    }
};
</script>

<style lang="scss">
.dealer-menu {
    .v-list > div {
        padding: 10px 0;
    }
}
.agency-select.styled-select {
    .v-select__slot {
        input::placeholder {
            color: #8f9ea6 !important;
        }
    }
    [data-lastpass-icon-root] {
        display: none;
    }
}
</style>

