/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'data-remap': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M8.598 10.998c.224 0 .401.177.405.401v8.2c0 .22-.18.401-.401.401H.403a.4.4 0 01-.401-.401v-8.2a.4.4 0 01.401-.401zm8.179.09a.311.311 0 01.44 0l2.689 2.374a.313.313 0 01-.22.535h-1.69V18c0 .551-.448 1-1 1H11.4A.4.4 0 0111 18.6v-1.2c0-.221.18-.402.401-.402h4.597v-3H14.31a.313.313 0 01-.22-.535zM19.595 0c.224 0 .401.181.405.401v8.2a.4.4 0 01-.401.401H11.4A.4.4 0 0111 8.601V.4c0-.22.18-.401.401-.401zM8.598 1a.4.4 0 01.401.401v1.197a.4.4 0 01-.401.401H4v3h1.688c.28 0 .417.338.22.535L3.222 8.908a.311.311 0 01-.44 0L.092 6.534A.313.313 0 01.312 6h1.69v-4c0-.55.448-1 1-1z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
