/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outbound-link': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M7.643.118c.25 0 .455.19.455.42V1.97c0 .232-.204.422-.455.422H2.317V17.69h15.428v-5.326c0-.25.19-.455.422-.455h1.412c.231 0 .421.205.4.455v7.306c0 .25-.19.455-.422.455H.402a.392.392 0 01-.172-.04.432.432 0 01-.348-.41V.54c0-.232.205-.421.455-.421zM18.66.84c.24-.006.43.184.423.423l-.178 6.98a.462.462 0 01-.445.445l-1.408.036a.409.409 0 01-.423-.423l.09-3.535-9.959 10.176a.425.425 0 01-.599.016l-.977-.978a.425.425 0 01.015-.6l9.958-10.176-3.52.105a.409.409 0 01-.423-.423l.021-1.422a.462.462 0 01.446-.446z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
