import Vue from 'vue';
import Vuetify, { VBtn, VFlex } from 'vuetify/lib';
import Layout from './components/layout.vue';
import moment from 'moment';
import store from './store';
import router from './routes';
import VueClipboard from 'vue-clipboard2';
import VueGtm from '@gtm-support/vue2-gtm';
import VueFbSDK from './plugins/fb-sdk';
import PageTitlePlugin from './plugins/PageTitle';
import BrandPlugin from './plugins/brand';
import ApiClientPlugin from './plugins/api-client';
import VueKeepAliveDev from './plugins/KeepAliveDev';
import EchoPlugin from './plugins/echo';
import DataLayerPlugin from './plugins/data-layer';
import NumbersPlugin from './plugins/numbers';
import SvgIcon from 'vue-svgicon';
import * as VueGoogleMaps from 'vue2-google-maps';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import EventBus from './event-bus';
import downloadFile from './helpers/downloadFile';
import VueScrollTo from 'vue-scrollto';
import  { isNumber } from 'lodash';
import directives from './directives';
import './assets/scss/app.scss';
import './globals';
import VueI18n from 'vue-i18n';
import en from '../src/translations/en.js';
import * as Sentry from '@sentry/vue';

window.Vue = Vue;

moment.suppressDeprecationWarnings = true;
Vue.config.productionTip = false;

Vue.use(Vuetify, {
    components: {
        VBtn,
        VFlex,
    },
    theme: {
        primary: '#51c1f7',
        secondary: '#ed2434',
        accent: '#1b313c',
        error: '#F44336',
        warning: '#ffeb3b',
        info: '#2196F3',
        success: '#4CAF50'
    }
});

Vue.use(VueI18n);
Vue.use(VueKeepAliveDev);
Vue.use(VueScrollTo);
Vue.use(BrandPlugin);
Vue.use(ApiClientPlugin);
Vue.use(EchoPlugin);
Vue.use(VueClipboard);
Vue.use(DataLayerPlugin);
Vue.use(NumbersPlugin);
Vue.use(moment);
Vue.use(require('vue-moment'));
Vue.use(directives);

Vue.use(PageTitlePlugin);

Vue.use(VueFbSDK, {
    appId: '1401796643196931',
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v18.0'
});

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_API_KEY
    },
    installComponents: false
});

Vue.use(SvgIcon, {
    tagName: 'svgicon'
});


Vue.use(VueGtm, {
    id: 'GTM-5WH6NZP', // BuyerBridge GTM
    queryParams: {
        bb_cache: moment().format('YYYY-MM-DD')
    },
    enabled: (process.env.VUE_APP_APP_ENV == 'production'),
    debug: false,
    vueRouter: router,
    ignoredViews: []
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_APP_ENV == 'production' ? process.env.VUE_APP_SENTRY_DSN : '',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api\.buyerbridge\.io/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.01, // If you're not already sampling the entire session, change the sample rate to 1% when sampling sessions where errors occur.
});

Vue.filter('numFormat', numFormat(numeral));

Vue.component('Layout', require('./components/layout.vue'));


Vue.prototype.$userHasRole = function(roles) {
    return window.userHasRole(roles);
};

Vue.prototype.$userHasProduct = function(productIds) {
    return window.userHasProduct(productIds);
};

Vue.prototype.$userIsInternal = function() {
    return window.userIsInternal();
};

Vue.prototype.$dealerHasAdProduct = function(dealer) {
    return window.dealerHasAdProduct(dealer);
};

Vue.prototype.$dealerHasProducts = function(dealer, productIds) {
    return window.dealerHasProducts(dealer, productIds);
};

//Global Flash Message
Vue.prototype.$flash = function(message, color = 'blue') {
    EventBus.$emit('flash', { message, color });
};

Vue.prototype.$userHasAgency = function() {
    return window.userHasAgency();
};

Vue.prototype.$getUserAgencyId = function() {
    return window.getUserAgencyId();
};

Vue.prototype.$isAdProduct = function(product) {
    return window.isAdProduct(product);
};

Vue.prototype.$downloadFile = downloadFile;

Vue.filter('capitalize', function(val) {
    return val.charAt(0).toUpperCase() + val.slice(1);
});

Vue.filter('formatInteger', function(val) {
    if (val !== null && val !== undefined) {
        return Math.round(val).toLocaleString();
    }
});

Vue.filter('formatPhoneNumber', function(val, country) {
    if (val && country) {
        return parsePhoneNumberFromString(val, country).formatNational();
    }
});

Vue.filter('formatThousands', function(val) {
    if (val !== null && val !== undefined) {
        return val.toLocaleString();
    }
});


Vue.filter('formatNumbertToKAndM', function(val) {
    if (isNumber(val)) {
        if(String(val).length < 4) {
            return val;
        } else if (String(val).length < 7) {
            return Math.floor(val/1000) + 'K';
        } else {
            return Math.floor(val/1000000) + 'M';
        }
    }
});

Vue.filter('secondsToTime', function(val) {
    if(val) {
       return moment.utc(val*1000).format('HH:mm:ss');
    }
    if(val === 0) {
        return '00:00:00';
    }
});

Vue.filter('lowercase', function(v) {
    return v.toLowerCase();
});

Vue.filter('uppercase', function(v) {
    return v.toUpperCase();
});

Vue.filter('toFixed', function(v) {
    return Array.isArray(v) ? parseFloat(v[0].value).toFixed(2) : (v ? parseFloat(v).toFixed(2) : 0);
});

Vue.filter('toNumber', function(v) {
    return Array.isArray(v) ? parseFloat(v[0].value) : (v ? parseFloat(v) : 0);
});

Vue.filter('machineToFriendlyName', function(v) {
    if (typeof v !== 'string') return '';
    let splitStr = v.toLowerCase().split('_');
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
});

Vue.filter('machineToFriendlyName', function(v) {
    let splitStr = v.toLowerCase().split('_');
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
});

Vue.filter('camelCaseToWords', function(v) {
    const parts = v.split(/(?=[A-Z])/);
    // Ensure the first word is capitalized to support camel (proper) and pascal simultaneously
    if (parts[0]) {
        parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].substring(1);
    }
    return parts.join(' ');
});


Vue.filter('formatPhoneNumber', function(val) {
    if (val !== null && val !== undefined) {
        var stripPlus = val.replace(/^\+[0-9]/, '');
        var s2 = ('' + stripPlus).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
    }
});

Vue.filter('truncate', function(val, length = 35) {
    if (val !== null && val !== undefined && val.length >= length) {
        return val.substring(0, length) + '...';
    } else {
        return val;
    }
});

Vue.filter('localizeTimezone', function(val) {
    return window.convertFromUtcToLocalTime(val);
});

Vue.filter('fullDate', function(dateToFormat) {
    if (dateToFormat) {
        return moment(dateToFormat).format('MMMM DD, Y');
    }
    return dateToFormat;
});

Vue.filter('formatBytes', function(numberToFormat) {

    if (!numberToFormat) {
        return '0 Bytes';
    }
    const decimals = 1;
    const kb = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(numberToFormat) / Math.log(kb));
    return parseFloat((numberToFormat / Math.pow(kb, i)).toFixed(decimals)) + ' ' + sizes[i];
});
Vue.filter('urlFormatter', function(val) {
    if(val) {
     const stringWithoutProtocol = val.replace(/^https?:\/\//, '');
    return  stringWithoutProtocol.substring(0, stringWithoutProtocol.indexOf('/'));
    }
});

Vue.filter('formatCurrency', function(val) {
    if (val !== null && val !== undefined) {
        return val.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    }
});

const i18n = new VueI18n({
    locale: 'en',
    messages: { en },
  });

new Vue({
    i18n,
    Vuetify,
    router,
    store,
    render: h => h(Layout)
}).$mount('#app');
