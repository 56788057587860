/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fb_reels_menu_icon': {
    width: 54,
    height: 54,
    viewBox: '0 0 54 54',
    data: '<defs><filter id="svgicon_fb_reels_menu_icon_b" width="163.2%" height="163.2%" x="-31.6%" y="-31.6%" filterUnits="objectBoundingBox"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.300453453 0"/></filter><circle pid="0" id="svgicon_fb_reels_menu_icon_a" cx="19" cy="19" r="19"/></defs><g _fill="none" fill-rule="evenodd" transform="translate(8 8)"><use xlink:href="#svgicon_fb_reels_menu_icon_a" _fill="#000" filter="url(#svgicon_fb_reels_menu_icon_b)"/><use xlink:href="#svgicon_fb_reels_menu_icon_a" _fill="#D8D8D8" fill-opacity=".198"/><g _fill="#FFF" transform="translate(10.5 17)"><circle pid="1" cx="2.05" cy="2.05" r="2.05"/><circle pid="2" cx="7.972" cy="2.05" r="2.05"/><circle pid="3" cx="13.894" cy="2.05" r="2.05"/></g></g>'
  }
})
