import { Card } from '@/creative-studio/domain/layered-designer/models/Card';
import { LayerMapper } from './LayerMapper';
export class CardMapper {
    static toDomain(cardResource) {
        const { id, name, dimensions, thumbnail, layers , originalBaseDimensions } = cardResource;

        const card = new Card(id);

        card.thumbnail = thumbnail;

        card.name = name;

        card.originalBaseDimensions = originalBaseDimensions;

        if (dimensions?.height !== undefined && dimensions?.width !== undefined ) {
            card.dimensions = dimensions;
        }

        if (Array.isArray(layers)) {
            card.layers = layers.map(LayerMapper.toDomain);
        }

        return card;
    }
}