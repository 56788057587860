<template>
    <div class="popup-wrapper">
        <div class="popup">
            <div
                class="popup__close"
                @click="closePopup">
                <icon
                    name="close"
                    size="20px" />
            </div>
            <div class="popup__files">
                <ad-studio-uploading
                    @file-uploaded="emitsNewAdEvent($event)" />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import AdStudioUploading from '@/components/ad-studio/uploading/AdStudioUploading';


export default {
    components: {
        Icon,
        AdStudioUploading
    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
        emitsNewAdEvent(event) {
            const { totalFiles, index } = event;
            this.$emit('new-ad', event);

            if((index + 1) === totalFiles) {
                this.closePopup();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.popup-wrapper{
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(5px);
}
.popup{
    position: absolute;
    top:5%;
    bottom:5%;
    left: 5%;
    right:5%;
    background: white;
    border-radius: 10px;
    display: flex;
    &__original{
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &__files{
        width: 75%;
        padding: 30px;
        overflow: auto;
    }
    &__note{
        font-size: 20px;
        color: #03A2EA;
        margin-bottom: 15px;
    }
    &__image{
        width: 150px;
        img, video{
            max-width: 100%;
            height: auto;
        }
    }
    &__close{
        z-index: 10000;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px;
        cursor: pointer;
    }
}
</style>
