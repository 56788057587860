/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M12.424 3.333l4.243 4.243L5.455 18.788 0 20l1.212-5.455L12.424 3.333zm.084 3.114l-8.485 8.485 1.045 1.045 8.484-8.485-1.044-1.045zM17.083 0a2.917 2.917 0 012.334 4.667L18.25 5.833 14.167 1.75 15.333.583A2.904 2.904 0 0117.083 0z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
