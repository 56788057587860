import { render, staticRenderFns } from "./LayeredDesignerDialog.vue?vue&type=template&id=639d0352&"
import script from "./LayeredDesignerDialog.vue?vue&type=script&lang=js&"
export * from "./LayeredDesignerDialog.vue?vue&type=script&lang=js&"
import style0 from "./LayeredDesignerDialog.vue?vue&type=style&index=0&id=639d0352&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports