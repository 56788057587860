


const metricsCampaigns = (state) => {
    if(!state.metricsCampaigns || !state.campaigns) {
        return [];
    }
    const campaigns = state.campaigns;
    return state.metricsCampaigns.map(campaign => {
        const match = campaigns.find(c => c.external_id == campaign.id);
        campaign.stats.campaign_id = {
            formatted: match.external_id,
        };
        campaign.stats.campaign_name = {
            formatted: match.name,
            value: match.name
        };
        return campaign;
    });
};
/**
 * Determines if the current dealer is eligible to retrieve metrics
 *
 * @returns Boolean
 */
const eligibleForMetrics = (state, getters, rootState, rootGetters) => {
    return rootGetters.dealerHasFeatures(['tiktok_ad_account']);
};


/**
 * Determines if metrics are available
 *
 * @returns Boolean
 */
const hasMetrics = (state) => {
    return state.metricsTotals !== null;
};

const hasPreviousPeriodMetrics = (state) => {
    return state.metricsTotals !== null && state.metricsErrorPreviousPeriods == null;
};


/**
 * Determines if any tracked metrics are loading
 */
 const metricsLoading = (state) => {
    return (
        state.campaignsLoading ||
        state.metricsTotalsLoading ||
        state.metricsIntervalsLoading ||
        state.metricsCampaignsLoading ||
        state.metricsTotalsComparisonLoading ||
        state.metricsTotalsPreviousMonthLoading ||
        state.metricsTotalsPrevious90DaysLoading
    );
};

export default {
    metricsCampaigns,
    eligibleForMetrics,
    hasMetrics,
    hasPreviousPeriodMetrics,
    metricsLoading
};