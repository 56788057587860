<template>
    <vue-slideout-panel
        :value="value"
        :widths="widths"
        class="styled-slideout-panel"
        :class="position"
        :go-to-bottom="goToBottom"
        :disable-background-click="disableBackgroundClick"
        @input="$emit('input', value)"
        @close="$emit('input', false)">
        <div class="layout">
            <div class="flex">
                <h1 class="slideout-title">
                    <v-btn
                        icon
                        @click="$emit('input', false)">
                        <icon
                            color="#ffff"
                            name="close" />
                    </v-btn>
                    {{ label }}
                </h1>
            </div>
        </div>
        <div class="actions">
            <ul
                v-if="hasTabs"
                class="tabs">
                <li
                    v-for="(tab,i) in tabs"
                    :key="i">
                    <button
                        class="tab-toggle"
                        :class="{active: activeTab === tab.slot}"
                        @click="$emit('setActiveTab',tab.slot)">
                        <icon
                            class="icon"
                            :name="tab.icon"
                            size="20" />
                        <span class="label">{{ tab.label }}</span>
                    </button>
                </li>
            </ul>
            <div
                v-if="!!$slots['action-buttons']"
                class="action-buttons">
                <slot name="action-buttons" />
            </div>
        </div>

        <slot
            v-if="hasTabs"
            :name="activeTab" />
        <slot v-if="createOnOpen ? value : true" />
        <vue-slideout-panel
            v-if="!!$slots['nested']"
            :value="nested"
            :widths="nestedWidths"
            class="styled-slideout-panel"
            @input="$emit('update:nested', nested)"
            @close="$emit('update:nested', false)">
            <div class="layout">
                <div class="flex">
                    <h1 class="slideout-title">
                        <v-btn
                            icon
                            @click="$emit('update:nested', false)">
                            <icon
                                color="#ffff"
                                name="close" />
                        </v-btn>
                        {{ nestedLabel }}
                    </h1>
                </div>
            </div>
            <slot
                v-if="createOnOpen ? value : true"
                name="nested" />
        </vue-slideout-panel>
    </vue-slideout-panel>
</template>
<script>
import VueSlideoutPanel from '@/components/globals/VueSlideoutPanel';
import Icon from './Icon';

export default {
    components: {
        VueSlideoutPanel,
        Icon,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disableBackgroundClick: {
            type: Boolean,
            default: false
        },
        goToBottom: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'right'
        },
        label: {
            type: String,
            default: ''
        },
        nested: {
            type: Boolean,
            default: false
        },
        nestedLabel: {
            type: String,
            default: ''
        },
        tabs: {
            type: Array,
            default: () => []
        },
        activeTab: {
            type: String,
            default: ''
        },
        width: {
            type: Number,
            default: 600
        },
        createOnOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            nestedSlideoutOpen: false,
            widths: [],
            nestedWidths: []
        };
    },
    computed: {
        hasTabs() {
            return this.tabs.length > 0;
        }
    },
    watch: {
        value() {
            this.setHtmlClass();
        }
    },
    created() {
        this.setWidths();
        this.setHtmlClass();
    },
    mounted() {
        this.setInitialState();
    },
    activated() {
        this.setHtmlClass();
        this.setInitialState();
    },
    deactivated() {
        document.documentElement.classList.remove('styled-slideout-panel-open');
    },
    destroyed() {
        document.documentElement.classList.remove('styled-slideout-panel-open');
    },
    methods: {
        setInitialState() {
            const id = this.$el?.id;
            const hashValue = this.$route.hash ? this.$route.hash.substring(1) : null;
            if (id && hashValue && (id == hashValue)) {
                this.$emit('input', true);
            }
        },
        setWidths() {
            if (window.innerWidth <= this.width) {
                this.widths = ['100%', '100%'];
                this.nestedWidths = ['90%'];
            } else {
                const width = this.width + 'px';
                this.widths = [width, width];
                this.nestedWidths = [(this.width - 100) + 'px'];
            }
        },
        setHtmlClass() {
            if (this.value === true) {
                document.documentElement.classList.add('styled-slideout-panel-open');
            } else {
                document.documentElement.classList.remove('styled-slideout-panel-open');
            }
        }
    }
};
</script>

<style lang="scss">
.styled-slideout-panel-open {
    overflow: hidden; /* Freeze UI when open */
}

.styled-slideout-panel.left {
    .dock {
        z-index: 3060 !important;
        left: 0 !important;
    }
    .slide-out-enter, .slide-out-leave-to {
        transform: translateX(-100%) !important;
    }
    .dock {
        h1 {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.actions {
    position: relative;
    .action-buttons {
        position: absolute;
        top: 8px;
        right: 15px;
        display: flex;
        justify-content: space-between;
        .action-button {
            margin: 0 5px;
        }
    }
}
.tabs {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0 30px;
    height: 60px;
    background-color: #f3f5f6;
}
.tab-toggle {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 30px;
    overflow: hidden;
    &::after {
        display: block;
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        opacity: 0;
        background: $blue-primary;
        transition: opacity 0.3s ease-out;
    }
    .label {
        margin-top: 5px;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 600;
        white-space: nowrap;
        color: $gray;
    }
    &.active {
        path {
            fill: $blue-primary;
        }
        .label {
            color: $blue-primary;
        }
        &::after {
            opacity: 1;
        }
    }
}

.styled-slideout-panel {
    .panel.default {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0 !important;
    }
    .dock {
        .action-close {
            display: none;
        }
        .slideout-title {
            color: #fff;
            background: $gray-text;
            font-weight: 400;
            text-align: left;
            padding: 12px;
            span {
                font-size: 14px;
                float: right;
                padding: 5px;
            }
        }
    }
}
</style>
