import store from '@/components/ad-studio/store/index.js';
import {
    IMAGE_ASSET_TYPE,
    PLATFORM_ASSET_CONFIGURATIONS
} from '@/components/ad-studio/store/constants.js';

import { DEPLOYMENT_PLATFORMS } from '@/components/ad-deployment/store/constants.js';

const CHILD_ASSET_ASPECT_RATIO_THUMBNAIL = '1:1';


export const setParentAssetAsAd = (agencyId, asset) => {
    store.mutations.initAdStudioData(store.state, {
        agencyId,
        asset,
        fileName: asset.display_name
    });
};


export const setSelectedPlatformsForAd = (adIndex) => {
    const platforms = PLATFORM_ASSET_CONFIGURATIONS.filter((platformConfig) => {
            return platformConfig.asset_type === IMAGE_ASSET_TYPE;
        }).map((platformConfig) => { // Get all the platform arrays
            return platformConfig.platforms;
        }).reduce((acc, val) => { // Combine all the platform arrays into one
            return acc.concat(val);
        }, []).filter((platform, index, self) => { // Remove duplicates
            return self.indexOf(platform) === index;
        }).filter((platform) => { // Remove platforms that are coming soon
            return DEPLOYMENT_PLATFORMS.find((deploymentPlatform) => {
                return deploymentPlatform.name === platform;
            }).comingSoon === false;
        });

    platforms.forEach((platform) => {
        store.mutations.addPlatform(store.state, {
            adIndex,
            platform
        });
    });
};


export const setChildAssetsAsAd = (adIndex, assets) => {
    store.mutations.setAllowSupportedChannelsToControlMediaStyles(store.state, {
        adIndex,
        value: false, // Set to false for design ads
    });

    assets.map((asset) => {
        // Set child asset as thumbnail based on aspect ratio
        if(asset.aspect_ratio === CHILD_ASSET_ASPECT_RATIO_THUMBNAIL) {
            store.mutations.setAdThumbnail(store.state, {
                adIndex,
                thumbnailUrl: asset.url
            });
        }
        Object.assign(asset, { asset_type: {
            name: 'image',
            display_name: 'Image'
        } });
        const assetsConfiguration = PLATFORM_ASSET_CONFIGURATIONS.find(platform => {
            return platform.aspect_ratio === asset.aspect_ratio && platform.asset_type === asset.asset_type.name;
        });
        if(!assetsConfiguration) {
            return;
        }
        const platforms = assetsConfiguration.placements.map((placement) => {
            return placement.platform;
        });
        store.mutations.insertAssetIntoAd(store.state, {
            adIndex,
            asset,
            platforms
        });
    });
};


export const setEditedAssetsForAd = (asset) => {
    store.mutations.setEditedDesignAds(store.state, { asset });
};
