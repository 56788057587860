/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-pause': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 0C13.458 0 0 13.458 0 30s13.458 30 30 30 30-13.458 30-30S46.542 0 30 0zm-3 46h-8V14h8v32zm14 0h-8V14h8v32z"/>'
  }
})
