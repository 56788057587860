/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'disable-call-tracking': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" d="M15.41 11l-1.402-1.363c.549-.696 1.03-1.43 1.442-2.212l-2.158-2.097a.938.938 0 01-.245-.972c.363-1.067.559-2.211.559-3.403 0-.524.441-.953.98-.953h3.433c.54 0 .981.429.981.953C19 4.747 17.656 8.236 15.41 11zm-2.81 2.953a16.202 16.202 0 01-10.095 3.502.962.962 0 01-.96-.96v-3.349c0-.527.432-.96.96-.96 1.19 0 2.35-.191 3.425-.546a.954.954 0 01.98.23l2.11 2.111a14.294 14.294 0 002.207-1.4L0 1.352 1.363 0 19 17.647 17.647 19l-5.048-5.047z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
