/**
 * Executes the function passed as param, if it fails, it will retry it using exponential backoff. If the call succeeds,
 * it will stop the function and return its results.
 *
 * @param fn
 * @param maxAttempts
 * @param baseDelayMs
 * @returns {Promise<*|undefined>}
 */
export const retryWithExponentialBackoff = (fn, maxAttempts = 5, baseDelayMs = 1000) => {
    let attempt = 1

    const execute = async () => {
        try {
            return await fn()
        } catch (error) {
            if (attempt >= maxAttempts) {
                throw error;
            }

            const delayMs = baseDelayMs * 2 * attempt;
            await new Promise((resolve) => setTimeout(resolve, delayMs));

            attempt++;
            return execute();
        }
    }

    return execute();
}

/**
 * Executes the function passed as a param for how many numberOfExecutions it was passed, it always executes up to that number,
 * there's no conditional that would make it stop. It will use exponential backoff to increase the delay between each execution.
 *
 * @param fn
 * @param numberOfExecutions
 * @param baseDelayMs
 * @returns {Promise<void>}
 */
export const executeWithExponentialBackoff = async (fn, numberOfExecutions = 5, baseDelayMs = 1000) => {
    let attempt = 1

    do {
        try {
            const delayMs = baseDelayMs * 2 * attempt;
            await new Promise((resolve) => setTimeout(resolve, delayMs));

            await fn();

            attempt++;
        } catch (error) {
            console.log(error);
        }
    } while (numberOfExecutions >= attempt);
}
