/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M1.03 0h18.34l.03 5.968h-.878c-.287-2.129-1.11-3.57-2.469-4.32-.763-.415-1.904-.641-3.421-.68v15.578c0 1.089.196 1.811.588 2.168.391.356 1.207.535 2.446.535V20H4.808v-.751c1.19 0 1.98-.181 2.372-.542.392-.362.588-1.082.588-2.16V.967c-1.487.039-2.628.265-3.421.68-1.458.77-2.281 2.21-2.47 4.32H1L1.03 0z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
