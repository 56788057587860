var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:{
        'outline': _vm.outline,
        'gray': _vm.gray,
        'green': _vm.green,
        'red': _vm.red,
        'small': _vm.small,
        'full-width': _vm.fullWidth,
        'light': _vm.light,
        'text': _vm.text
    },attrs:{"disabled":_vm.disabled},on:{"click":_vm.onClick}},[(_vm.loading)?_c('div',{staticClass:"button-loader"},[_c('styled-loader',{attrs:{"size":_vm.currentLoaderSize,"light":!(_vm.outline || _vm.text)},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1):_vm._e(),_c('div',[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }