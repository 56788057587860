/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audio-play': {
    width: 15,
    height: 18,
    viewBox: '0 0 15 18',
    data: '<path pid="0" d="M14.21 7.189L2.424.22C1.467-.345 0 .204 0 1.604v13.932c0 1.255 1.363 2.012 2.424 1.383L14.21 9.954c1.051-.62 1.055-2.146 0-2.765z" _fill="#B3E5FC" fill-rule="nonzero"/>'
  }
})
