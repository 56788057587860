const removeNamespace = (namespace, object) => {
    const newObject = {};
    
    for (let key in object) {
        newObject[key] = object[key].replace(namespace, '');
    }

    return newObject;
};

export default removeNamespace;