<template>
    <div class="catalog-link">
        <div class="catalog">
            <a
                :href="`https://business.facebook.com/products/catalogs/${annotation.facebook_product_catalog_id}/products?business_id=${dealer.agency.facebook_business_id}`"
                target="_blank"
                class="mr-2">
                <span>{{ dealer.name }} {{ getCatalogType(annotation) }} Catalog ({{ annotation.facebook_product_catalog_id }})</span>
            </a>
            <div class="button-group">
                <action-button
                    :loading="loading"
                    :color="userAssigned ? 'blue' : 'red'"
                    :icon="userAssigned ? 'indicator-success' : 'indicator-error' ">
                    {{ userAssigned ? 'Permission' : 'No Permission' }}
                </action-button>
                <action-button
                    :href="`https://business.facebook.com/settings/product-catalogs/${annotation.facebook_product_catalog_id}?business_id=${dealer.agency.facebook_business_id}`"
                    target="_blank"
                    icon="cog">
                    BM Settings
                </action-button>
            </div>
        </div>
        <ul class="links">
            <li>
                <a
                    :href="`https://business.facebook.com/products/catalogs/${annotation.facebook_product_catalog_id}/data_sources?business_id=${dealer.agency.facebook_business_id}`"
                    target="_blank">
                    Data Sources
                </a>
            </li>
            <li>
                <a
                    :href="`https://business.facebook.com/products/catalogs/${annotation.facebook_product_catalog_id}/events/sources?business_id=${dealer.agency.facebook_business_id}`"
                    target="_blank">
                    Events
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ActionButton from '@/components/globals/ActionButton';
    export default {
        components: {
            ActionButton
        },
        props: {
            dealer: {
                type: Object,
                required: true
            },
            annotation: {
                type: Object,
                required: true
            },
            refresh: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                userAssigned: false,
            };
        },
        computed: {
            ...mapState({
                recheckFacebookAssets: (state) => state.dealer.recheckFacebookAssets,
            })
        },
        watch: {
            recheckFacebookAssets() {
                this.checkAccess(this.annotation.id);
            }
        },
        created() {
            this.checkAccess(this.annotation.id);
        },
        methods: {
            async checkAccess(annotationId) {
                try {
                    this.loading = true;
                    const response = await this.$http.post(`/facebook_product_catalog_annotations/${annotationId}/check-user-access`);

                    if (['user_already_has_access', 'user_added_to_ad_account'].includes(response.data.status)) {
                        this.userAssigned = true;
                    } else {
                        this.userAssigned = false;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
            },
            getCatalogType(catalog) {
                if (catalog.catalog_type_id === 1) {
                    return 'Vehicle';
                }
                if (catalog.catalog_type_id === 2) {
                    return 'Product';
                }
                return null;
            }
        }
    };
</script>

<style lang="scss" scoped>
.catalog {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.button-group {
    display: flex;
    justify-content: space-between;
    width: 150px;
}
.links {
    list-style: none;
}
</style>
