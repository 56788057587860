/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fit': {
    width: 30,
    height: 19,
    viewBox: '0 0 30 19',
    data: '<g _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#8F9EA6" x="6" width="19" height="19" rx="1"/><path pid="1" _fill="#FFF" d="M22.841 5.495l.031 1.124-4.816-.134-.133-4.816 1.123.032.103 3.692zM9.159 12.588l-.031-1.124 4.816.134.133 4.816-1.123-.031-.103-3.692z"/></g>'
  }
})
