/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
