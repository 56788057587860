/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'support': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M7.867 19.94a1.483 1.483 0 01-1.84-1.02l-.387-1.383H3.54a2.046 2.046 0 01-2.045-2.04v-2.072l-.542-.18a1.39 1.39 0 01-.832-1.88l1.15-2.576c.148-.331.224-.69.224-1.053A7.755 7.755 0 016.742.408c1.642-.55 3.82-.55 5.666.265a7.733 7.733 0 014.4 5.35 7.719 7.719 0 01-1.657 6.723l.947 3.314a1.477 1.477 0 01-1.022 1.836l-7.21 2.045zm-1.241-7.623a1.15 1.15 0 00-1.376.143 1.145 1.145 0 001.003 1.965c.503-.098.88-.516.925-1.026a5.95 5.95 0 002.194-2.357 2.602 2.602 0 002.875-1.562 2.593 2.593 0 00-.933-3.132A47.384 47.384 0 0010.905.18 7.654 7.654 0 009.249 0c-.37 0-.741.026-1.108.078.296 2.032.449 4.083.456 6.138a2.593 2.593 0 00-.342 4.324 4.599 4.599 0 01-1.63 1.777z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
