/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<path pid="0" d="M8.687 8.302v10.352H0V8.302h8.687zM20 12.868v5.786h-9.187v-5.786H20zM20 .5v10.352h-9.187V.5H20zM8.687.5v5.286H0V.5h8.687z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
