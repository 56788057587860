<template>
    <div>
        <div class="pixel-link">
            <div class="pixel">
                <a
                    :href="`https://business.facebook.com/events_manager2/list/pixel/${annotation.facebook_pixel_id}/overview?business_id=${dealer.agency.facebook_business_id}`"
                    target="_blank"
                    class="mr-2">
                    <span>{{ dealer.name }} Pixel ({{ annotation.facebook_pixel_id }})</span>
                </a>
                <div class="button-group">
                    <action-button
                        :loading="loading"
                        :color="userAssigned ? 'blue' : 'red'"
                        :icon="userAssigned ? 'indicator-success' : 'indicator-error' ">
                        {{ userAssigned ? 'Permission' : 'No Permission' }}
                    </action-button>
                    <action-button
                        :href="`https://business.facebook.com/settings/pixels/${annotation.facebook_pixel_id}?business_id=${dealer.agency.facebook_business_id}`"
                        target="_blank"
                        icon="cog">
                        BM Setttings
                    </action-button>
                </div>
            </div>
            <asset-alerts :monitor-id="3" />
            <ul class="links">
                <li>
                    <a
                        :href="`https://business.facebook.com/events_manager2/custom_conversions/?act=${facebookAdAccountId}&business_id=${dealer.agency.facebook_business_id}`"
                        target="_blank">
                        Custom Conversions
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import AssetAlerts from './AssetAlerts';
export default {
    components: {
        ActionButton,
        AssetAlerts
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        annotation: {
            type: Object,
            required: true
        },
        refresh: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            userAssigned: false,
        };
    },
    computed: {
        ...mapState({
            recheckFacebookAssets: (state) => state.dealer.recheckFacebookAssets,
        }),
        facebookAdAccountId() {
            return this?.dealer?.facebook_ad_account_annotations?.data[0]?.facebook_ad_account_id.replace('act_', '');
        }
    },
    watch: {
        recheckFacebookAssets() {
            this.checkAccess(this.annotation.id);
        }
    },
    created() {
        this.checkAccess(this.annotation.id);
    },
    methods: {
        async checkAccess(annotationId) {
            try {
                this.loading = true;
                const response = await this.$http.post(`/facebook_pixel_annotations/${annotationId}/check-user-access`);

                if (['user_already_has_access', 'user_added_to_ad_account'].includes(response.data.status)) {
                    this.userAssigned = true;
                } else {
                    this.userAssigned = false;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.pixel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.button-group {
    display: flex;
    justify-content: space-between;
    width: 150px;
}
.links {
    list-style: none;
}
</style>
