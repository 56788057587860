/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'page-align-right': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M20 1v18a1 1 0 01-2 0V1a1 1 0 012 0zM2 7h14a1 1 0 011 1v4a1 1 0 01-1 1H2a1 1 0 01-1-1V8a1 1 0 011-1z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
