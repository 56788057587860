import { METRICS_STORAGE_KEY } from '@/helpers/storageKeys';
import { getDefaultDateRangeComparison } from './helpers';
import moment from 'moment';
import { INTERVAL_DAY } from '@/helpers/globals';

const getLocalData = () => {
    const currentSettingsJson = localStorage.getItem(METRICS_STORAGE_KEY);
    return currentSettingsJson ? JSON.parse(currentSettingsJson) : null;
};

const dateRange = {
    startDate: moment().subtract(31, 'day').toDate(),
    endDate: moment().subtract(1, 'day').toDate(),
};

const getDefaultData = () => {
    return {
        dateRange,
        dateRangeComparison: getDefaultDateRangeComparison(dateRange),
        spendOverrideDisable: false,
        interval: INTERVAL_DAY,
    };
};

const getInitialData = () => ({
    ...getDefaultData(),
    ...getLocalData()
});

export default getInitialData;