<template>
    <div class="previews-wrapper">
        <div class="header">
            <div class="header__title">
                <div class="previews__title">
                    Configure Media Styles
                </div>
                <div>
                    <p>
                        We have processed your media automatically and chosen
                        the optimal treatment for it across each media style
                        required by your selected platforms.  Use the tools
                        below to further tweak your media to suit your needs!
                    </p>
                </div>
            </div>
            <div>
                <best-practice-icon class="mt-3" />
            </div>
        </div>

        <div
            class="body">
            <div class="body__row">
                <div class="flex justify-center items-center">
                    <div
                        :class="[
                            popupActive && filteredAssetConfigs.length === 1 ? 'w-1/4' : '',
                            popupActive && filteredAssetConfigs.length === 2 ? 'w-1/2' : '',
                            popupActive && filteredAssetConfigs.length === 3 ? 'w-3/4' : '',
                            popupActive && filteredAssetConfigs.length === 4 ? 'w-full' : '',
                            !popupActive ? 'w-full' : '',
                        ]">
                        <div class="flex justify-between items-center">
                            <div v-if="platforms.length">
                                <div
                                    v-if="showAllowSupportedChannelsToControlMediaStyles && !popupActive"
                                    class="body__toggle">
                                    <div>
                                        <v-switch
                                            v-model="allowSupportedChannelsToControlMediaStyles"
                                            color="primary"
                                            @click.prevent="showConfirmationModal = true" />
                                    </div>
                                    <div class="flex">
                                        <div>
                                            Allow supported channels to control media styles
                                        </div>
                                        <span class="gray-text">(Recommended)</span>
                                        <div
                                            class="ml-2 mb-2"
                                            @click="openInfoPanel">
                                            <a>Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <styled-dialog v-model="showConfirmationModal">
                                    <template #heading>
                                        <h2>Are you sure?</h2>
                                    </template>
                                    <p>
                                        Are you sure you want to turn {{ allowSupportedChannelsToControlMediaStyles ? 'off' : 'on' }}
                                        allowing supported channels to controll media styles?
                                        This will remove any custom media styles you have set.
                                    </p>
                                    <template #actions>
                                        <styled-button
                                            outline
                                            class="ml-auto"
                                            @click="showConfirmationModal = false">
                                            Cancel
                                        </styled-button>
                                        <styled-button
                                            class="ml-3"
                                            @click="toggleAllowSupportedChannelsToControlMediaStyles">
                                            Switch {{ allowSupportedChannelsToControlMediaStyles ? 'Off' : 'On' }}
                                        </styled-button>
                                    </template>
                                </styled-dialog>
                                <div
                                    v-if="showAdvantagePlusToggle"
                                    class="body__toggle">
                                    <!-- Meta Advantage+ -->
                                    <div class="ml-1">
                                        <v-switch
                                            v-model="advantagePlus"
                                            color="primary" />
                                    </div>
                                    <div class="pb-2">
                                        Enable Meta Advantage+ Creative Standard Enhancement
                                    </div>
                                    <div
                                        class="ml-2 mb-2"
                                        @click="showAdvantagePlusSildeOut = true">
                                        <a>Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div v-if="filteredAssetConfigs.length > 1 && !allowSupportedChannelsToControlMediaStyles">
                                <a
                                    v-if="!advancedMediaControls"
                                    class="mb-3 mr-4"
                                    @click="advancedMediaControls = true">
                                    Advanced
                                </a>
                                <a
                                    v-if="advancedMediaControls"
                                    class="mb-3 mr-4"
                                    @click="advancedMediaControls = false">
                                    Simple
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="assets flex"
                :class="[allowSupportedChannelsToControlMediaStyles ? 'p-20' : '', popupActive ? 'justify-center' : '']">
                <div
                    v-for="(asset_config, index) in filteredAssetConfigs"
                    :key="index+asset_config.aspect_ratio"
                    class="w-1/4 px-4"
                    :class="[
                        (advancedMediaControls && (index+1 < filteredAssetConfigs.length)) ? 'border-right' : '',
                        (allowSupportedChannelsToControlMediaStyles && index === 0 && filteredAssetConfigs.length > 1) ? 'border-right' : ''
                    ]">
                    <file-preview
                        :asset-config-index="index"
                        :asset-config="asset_config"
                        :filtered-asset-configs="filteredAssetConfigs"
                        :selected-platforms="platforms"
                        :allow-supported-channels-to-control-media-styles="allowSupportedChannelsToControlMediaStyles"
                        :advanced-media-controls="advancedMediaControls" />
                </div>
            </div>
        </div>
        <div
            v-if="platforms.length == 0 && !popupActive"
            class="select-channels">
            <span>
                Select the channels you'd like to<br>
                deploy this ad to.
            </span>
        </div>

        <advantage-plus-slideout
            v-model="showAdvantagePlusSildeOut" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import FilePreview from '@/components/ad-studio/editing/common/FilePreview';
import BestPracticeIcon from '@/components/ad-studio/BestPracticeIcon';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import {
    PLATFORM_ASSET_CONFIGURATIONS,
    IMAGE_ASSET_TYPE,
    VIDEO_ASSET_TYPE,
    DESIGN_ASSET_TYPE,
    ORIGINAL_ASSET_TYPE
} from '@/components/ad-studio/store/constants.js';
import EventBus from '@/event-bus';
import { DEPLOYMENT_PLATFORMS } from '@/components/ad-deployment/store/constants';
import AdvantagePlusSlideout from '@/components/ad-studio/editing/common/AdvantagePlusSlideout.vue';
import { PLATFORM_FACEBOOK } from '@/helpers/globals';

export default {
    components: {
        FilePreview,
        BestPracticeIcon,
        AdvantagePlusSlideout,
        StyledDialog,
        StyledButton
    },
    props: {
        popupActive: {
            type: Boolean,
            default: false
        },
        selectedPlatforms: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            assetConfigs: PLATFORM_ASSET_CONFIGURATIONS,
            IMAGE_ASSET_TYPE,
            VIDEO_ASSET_TYPE,
            ORIGINAL_ASSET_TYPE,
            DESIGN_ASSET_TYPE,
            showAdvantagePlusSildeOut: false,
            showConfirmationModal: false,
            configmrationModalAccepted: false
        };
    },
    computed: {
        ...mapState({
            adIndex: state => state.adStudio.adIndex,
            ads: state => state.adStudio.ads,
            dealer: (state) => state.dealer.currentDealer,
            agency: (state) => state.agency.currentAgency,
            user: (state) => state.user.user,
        }),
        platforms() {
            return this.selectedPlatforms.length ? this.selectedPlatforms : this.$store.state.adStudio.ads[this.adIndex].configuration.selected_platforms;
        },
        selectedPlatformsJsonString() {
            return JSON.stringify(this.platforms);
        },
        platformsThatAllowSupportedChannelsToControlMediaStyles() {
            return DEPLOYMENT_PLATFORMS.filter(platform => {
                return platform.channelControlsMediaStyles;
            }).map(platform => platform.platform);
        },
        showAdvantagePlusToggle() {
            return this.platforms.includes(PLATFORM_FACEBOOK);
        },
        filteredAssetConfigs() {
            // Check if all the platforms allowSupportedChannelsToControlMediaStyles
            return PLATFORM_ASSET_CONFIGURATIONS.filter(asset_config => {
                return asset_config.platforms.some(assetConfigPlatform => {
                    return (
                        this.platforms.includes(assetConfigPlatform)
                        && (asset_config?.asset_type === this.originalAsset?.asset_type?.name || (asset_config?.asset_type === ORIGINAL_ASSET_TYPE && this.allowSupportedChannelsToControlMediaStyles))
                    );
                });
            }).filter(asset_config => {
                // Keep the asset_config if the asset type is the original asset
                if (asset_config.asset_type === ORIGINAL_ASSET_TYPE) {
                    return true;
                }

                if (this.allowSupportedChannelsToControlMediaStyles) {
                    let shouldShowAssetConfig = asset_config.platforms.filter(assetConfigPlatform => {
                        return this.platforms.includes(assetConfigPlatform);
                    }).every(platform => this.platformsThatAllowSupportedChannelsToControlMediaStyles.includes(platform));

                    if (shouldShowAssetConfig) {
                        return false;
                    }
                }

                return true;
            });
        },
        allowSupportedChannelsToControlMediaStyles: {
            get() {
                return this.$store.state.adStudio?.ads?.[this.adIndex]?.configuration?.supported_platforms_control_media_styles;
            },
            set(value) {
                // If its going from true to false and in advanced controlls we need
                // to make the transformations on the assets
                if (!value && this.advancedMediaControls) {
                    EventBus.$emit('runTransformationOnAllAssetConfigs');
                }

                this.$store.commit('adStudio/setAllowSupportedChannelsToControlMediaStyles', {
                    adIndex: this.adIndex,
                    value
                });
            }
        },
        advancedMediaControls: {
            get() {
                return this.$store.state.adStudio?.ads?.[this.adIndex]?.configuration?.advanced_media_controls;
            },
            set(value) {
                this.$store.commit('adStudio/setAdvancedMediaControls', {
                    adIndex: this.adIndex,
                    value
                });
            }
        },
        advantagePlus: {
            get() {
                return this.ads?.[this.adIndex]?.configuration?.platform_specific?.facebook?.creative_enhancement;
            },
            set(value) {
                this.$store.commit('adStudio/setCreativeEnhancement', {
                    value,
                    adIndex: this.adIndex
                });
            }
        },
        showAllowSupportedChannelsToControlMediaStyles() {
            // Get only the platforms that support allowing channels to controll media styles
            let platformsThatAllowSupportedChannelsToControlMediaStyles = DEPLOYMENT_PLATFORMS.filter(platform => {
                return platform.channelControlsMediaStyles;
            }).map(platform => platform.platform);

            // Check if any of the selected platforms are in the platformsThatAllowSupportedChannelsToControlMediaStyles
            return this.platforms.some(platform => platformsThatAllowSupportedChannelsToControlMediaStyles.includes(platform));
        },
        originalAsset() {
            return this.ads?.[this.adIndex]?.configuration?.all_platforms?.creatives?.[0]?.assets?.[0];
        },
        customizedPerPlatform() {
            return this.ads?.[this.adIndex]?.configuration?.customized_per_platform;
        },
    },
    created() {
        EventBus.$on('makeTransformationOnAsset', (data) => {
            this.updateAspecRatioAssetWithTransformation(data);
        });
    },
    beforeDestroy() {
        EventBus.$off('makeTransformationOnAsset');
    },
    methods: {
        openInfoPanel() {
            this.$store.commit('adStudio/setInfoPanelStatus', true);
        },
        toggleAllowSupportedChannelsToControlMediaStyles() {
            this.showConfirmationModal = false;
            this.allowSupportedChannelsToControlMediaStyles = !this.allowSupportedChannelsToControlMediaStyles;
        },
        async updateAspecRatioAssetWithTransformation({ transformations, assetConfig }) {
            assetConfig.loading = true;

            // Figure out which asset to use to make the transformation from
            let filteredPlatform = this.platforms.find(platform => {
                if (this.allowSupportedChannelsToControlMediaStyles) {
                    return !DEPLOYMENT_PLATFORMS.find(deployment_platform => deployment_platform.name === platform).channelControlsMediaStyles && assetConfig.platforms.includes(platform);
                } else {
                    return assetConfig.platforms.includes(platform);
                }
            });

            let platformAsset = this.ads?.[this.adIndex]?.configuration?.platform_specific?.[filteredPlatform]?.creatives?.[0]?.assets.find(asset => {
                return asset?.aspect_ratio === assetConfig?.aspect_ratio
                    && asset?.asset_type?.name === assetConfig?.asset_type;
            });

            // Make the api call to update the asset transformation
            const newAsset = await this.$apiRepository.transformAsset({
                // Use the aspect ratio asset if we have if if not just fall back to the original asset
                asset: platformAsset ?? this.originalAsset,
                agency_id: this.agency.id,
                transformations
            });

            // Insert into the correct location in the store using the insertAssetIntoAd action
            this.$store.commit('adStudio/insertAssetIntoAd', {
                adIndex: this.adIndex,
                asset: newAsset.data,
                platforms: this.customizedPerPlatform ? this.platforms : assetConfig.platforms,
            });

            assetConfig.loading = false;
        }
    },
};
</script>

<style lang="scss" scoped>
 .select-channels{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 26px;
    color: #03A2EA;
    height: 100%;
    padding-bottom: 30px;
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    &__title{
        width: 65%;
    }
}

.body {
    padding: 30px 20px;
    border-top: 1px solid #BABFC1;
    &__toggle {
        display: flex;
        align-items: center;
        padding-left: 40px;
    }
    &__toggle-label {
        padding-bottom: 6px;
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}
.gray-text{
    color: #BABFC1;
    margin-left: 10px;
}
// .grid {
//     display: grid;
// }
// .gap {
//     gap: 40px;
// }
.flex {
    display: flex;
}
.assets {
    display: flex;
}
.edit-boxes {
    padding: 0px 40px;
    width: 25%;
}
.p-20 {
    padding: 0px 20px;
}
.border-right {
    border-right: 1px solid #babfc184;
}

.w-1\/4 {
    width: 25%;
}

.w-1\/2 {
    width: 50%;
}

.w-3\/4 {
    width: 75%;
}

.w-full {
    width: 100%;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.previews-wrapper{
    height: 100%;
}
.previews{
    padding: 95px 30px 30px 60px;
    &__title{
        font-size: 20px;
        color: #03A2EA;
        margin-bottom: 15px;
    }
    &__desc{
        font-size: 12px;
        margin-bottom: 30px;
        // max-width: 750px;
    }
    &__wrapper{
        padding-left: 45px;
        max-width: 95%;
    }
    &.popuped{
        padding: 15px;
        .previews__wrapper{
            padding-left: 0;
        }
        .previews__desc{
            padding-bottom: 30px;
            border-bottom: 1px solid #BABFC1;
        }
    }
}
.open-panel {
    cursor: pointer;
}
</style>
