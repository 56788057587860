<template>
    <v-dialog
        v-model="value"
        persistent
        width="500">
        <v-card
            light>
            <v-card-title 
                class="d-block text-xs-center primary--text headline mx-3"
                color="white"
                primary-title>
                <div class="headline">
                    Attention: This is a Beta Feature!
                </div>
            </v-card-title>

            <v-card-text>
                <v-container 
                    fluid 
                    grid-list-md
                    py-0>
                    You are seeing this feature within an early release state.
                    As a result there may be issues with certain functionality and
                    the displayed data may differ slightly from actual results.
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn 
                    color="error"
                    flat
                    @click="handleCloseClick">
                    Okay, I Understand
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        handleCloseClick() {
            this.$emit('input', false);
            this.$emit('close');
        }
    }
};
</script>