<template>
    <div
        v-show="open"
        class="layer-item-menu-window">
        <div class="layer-item-menu-container">
            <div>
                <button @click="$emit('center')">
                    Center
                </button>
            </div>
            <div>
                <button @click="$emit('rename')">
                    Rename
                </button>
            </div>
            <div>
                <button @click="$emit('delete')">
                    Delete
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            open: {
                required: true,
                type: Boolean
            }
        }
    };
</script>

<style lang="scss">

.layer-item-menu-window {
    position: absolute;
    z-index: 100;
    right: -100px;
    margin-top: 80px;
    width: 110px;
    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    color: $blue-bg;
    padding: 15px 20px;
    &:before {
        content: '';
        position: absolute;
        left: -15px;
        top: 15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $white transparent;
        transform: rotate(-90deg);
    }


}

.layer-item-menu-container {
    display: inline-block;
    flex-wrap: wrap;
    button {
        margin-bottom: 10px;
    }
}

</style>
