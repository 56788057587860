import {
    PLATFORM_FACEBOOK,
    PLATFORM_TIKTOK,
    PLATFORM_SNAPCHAT,
    FACEBOOK_BB_PLATFORM_ID,
    TIKTOK_BB_PLATFORM_ID,
    SNAPCHAT_BB_PLATFORM_ID,
    PINTEREST_BB_PLATFORM_ID,
    PLATFORM_PINTEREST,
} from '@/helpers/globals.js';

import {
    supportedPlatforms,
    fields
} from '@/components/ad-studio/store/constants';

import { DEPLOYMENT_PLATFORMS } from '@/components/ad-deployment/store/constants';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';

const initAd = (agencyId, asset, filename) => {
    return {
        display_name: filename,
        dealer_id: null,
        agency_id: agencyId,
        user_id: null,
        thumbnail: null,
        description: null,
        public: true,
        active_platform: null,
        configuration: {
            selected_platforms: [],
            customized_per_platform: false,
            format: 'single', // update this in the future to one of the following single, carousel, dynamic
            supported_platforms_control_media_styles: true,
            advanced_media_controls: false,
            creative_enhancement: true, // default to true which enables facebook advantage+ creative
            all_platforms: {
                primary_text: null,
                headline: null,
                description: null,
                cta_link: null,
                cta_text: null,
                url_params: null,
                platform_id: null,
                creatives: [
                    {
                        // headline: null, // use this when carousel ads are ready
                        // description: null,
                        // cta_link: null,
                        // cta_text: null,
                        // url_params: null,
                        assets: [asset]
                    }
                ]
            },
            platform_specific: {
                [PLATFORM_FACEBOOK]: {
                    primary_text: null,
                    headline: null,
                    description: null,
                    cta_link: null,
                    cta_text: null,
                    url_params: null,
                    platform_id: FACEBOOK_BB_PLATFORM_ID,
                    form_valid: false,
                    platform_controls_media_styles: true,
                    creative_enhancement: true, // advantage+ toggle
                    creatives: [
                        {
                            // headline: null, // use this when carousel ads are ready
                            // description: null,
                            // cta_link: null,
                            // url_params: null,
                            assets: [asset]
                        }
                    ],
                },
                [PLATFORM_SNAPCHAT]: {
                    primary_text: null,
                    headline: null,
                    description: null,
                    cta_link: null,
                    cta_text: null,
                    url_params: null,
                    platform_id: SNAPCHAT_BB_PLATFORM_ID,
                    form_valid: false,
                    platform_controls_media_styles: false, // not currently supported by Snapchat
                    creative_enhancement: false,
                    creatives: [
                        {
                            // headline: null, // use this when carousel ads are ready
                            // description: null,
                            // cta_link: null,
                            // url_params: null,
                            assets: []
                        }
                    ],
                },
                [PLATFORM_TIKTOK]: {
                    primary_text: null,
                    headline: null,
                    description: null,
                    cta_link: null,
                    cta_text: null,
                    url_params: null,
                    platform_id: TIKTOK_BB_PLATFORM_ID,
                    form_valid: false,
                    platform_controls_media_styles: false, // not currently supported by TikTok
                    creative_enhancement: false,
                    creatives: [
                        {
                            // headline: null, // use this when carousel ads are ready
                            // description: null,
                            // cta_link: null,
                            // url_params: null,
                            assets: []
                        }
                    ],
                },
                [PLATFORM_PINTEREST]: {
                    primary_text: null,
                    headline: null,
                    description: null,
                    cta_link: null,
                    cta_text: null,
                    url_params: null,
                    platform_id: PINTEREST_BB_PLATFORM_ID,
                    form_valid: false,
                    platform_controls_media_styles: true,
                    creative_enhancement: false,
                    creatives: [
                        {
                            // headline: null, // use this when carousel ads are ready
                            // description: null,
                            // cta_link: null,
                            // url_params: null,
                            assets: [asset]
                        }
                    ],
                }
            }
        }
    };
};

export default {
    namespaced: true,
    state: {
        adIndex: 0,
        showValidationErrors: false,
        ads: [],
        fields,
        editedDesignAds: [],
        editingExistingAd: false,
        newAdFlow: false,
        infoPanelOpened: false,
    },
    getters: {
        selectedAdPlatforms(state) {
            return state?.ads?.[state.adIndex]?.configuration?.selected_platforms ?? [];
        },
        selectedAdData(state) {
            return state?.ads?.[state.adIndex] ?? {};
        },
        selectedAdCustomized(state) {
            return state?.ads?.[state.adIndex]?.configuration?.customized_per_platform ?? false;
        },
        selectedAdAssetsForPlatform: (state) => (platform) => { // todo in the future handel multiple creatives
            return state?.ads?.[state?.adIndex]?.configuration?.platform_specific?.[platform]?.creatives?.[0]?.assets;
        },
        selectedAdOriginalAsset(state) {
            return state?.ads?.[state.adIndex]?.configuration?.all_platforms?.creatives?.[0]?.assets?.[0];
        },
        editedDesignAds(state) {
            return state.editedDesignAds;
        }
    },
    actions: {},
    mutations: {
        setInfoPanelStatus(state, status) {
            state.infoPanelOpened = status;
        },
        setCurrentAdIndex(state, adIndex) {
            state.adIndex = adIndex;
        },
        deleteAd(state, adIndex) {
            if (state.adIndex === adIndex) {
                state.adIndex = 0;
            }
            const currentAd = state.ads[adIndex];
            currentAd.deleted = true;
        },
        setEditingExistingAd(state, status) {
            state.editingExistingAd = status;
        },
        clearAdStudioData(state) {
            state.editingExistingAd = false;
            state.adIndex = 0;
            state.ads = [];
            state.showValidationErrors = false;
        },
        initAdStudioData(state, { agencyId, asset, fileName }) {
            const updatedAds = [ ...state.ads ];
            const newAd = initAd(agencyId, asset,  fileName);
            updatedAds.push(newAd);
            state.ads = updatedAds;
            if (state.newAdFlow) {
                state.adIndex = state.ads.length - 1; // select the new ad
            } else {
                state.adIndex = 0; // select first ad
            }
        },
        setNewAdFlow(state, status) {
            state.newAdFlow = status;
        },
        customizePerPlatform(state, { adIndex }) {
            const updatedAds = [ ...state.ads ];
            updatedAds[adIndex].configuration.customized_per_platform = true;
            state.ads = updatedAds;
        },
        setAdName(state, { adIndex, name }) {
            const updatedAds = [ ...state.ads ];
            updatedAds[adIndex].display_name = name;
            state.ads = updatedAds;
        },
        addPlatform(state, { adIndex, platform }) {
            const updatedAds = [ ...state.ads ];
            const platformsOrderArray = DEPLOYMENT_PLATFORMS.map(platform => platform.name);
            updatedAds[adIndex].configuration.selected_platforms.push(platform);
            updatedAds[adIndex].configuration.selected_platforms.sort((a, b) => {
                return platformsOrderArray.indexOf(a) - platformsOrderArray.indexOf(b);
            });
            state.ads = updatedAds;
        },
        removePlatform(state, { adIndex, platform }) {
            const updatedAds = [ ...state.ads ];
            updatedAds[adIndex].configuration.selected_platforms = updatedAds[adIndex].configuration.selected_platforms.filter((p) => p !== platform);
            state.ads = updatedAds;
        },
        setAdThumbnail(state, { adIndex, thumbnailUrl }) {
            const updatedAds = [ ...state.ads ];
            updatedAds[adIndex].thumbnail = thumbnailUrl;
            state.ads = updatedAds;
        },
        insertAssetIntoAd(state, { adIndex, asset, platforms }) {
            const updatedAds = [ ...state.ads ];
            // Add the asset to each platforms ad
            platforms.forEach((platform) => {
                // Check if the platform is using allow supported channels to control media styles
                // If so dont insert the asset into the ad as we want to keep the original asset
                if (updatedAds[adIndex].configuration.platform_specific[platform].platform_controls_media_styles) {
                    return;
                }

                // Check if the platform exists in the configuration
                if(!updatedAds[adIndex].configuration.platform_specific[platform]) {
                    return;
                }

                const existingAssetIndex = updatedAds[adIndex].configuration.platform_specific[platform].creatives[0].assets
                    .findIndex((existingAsset) => existingAsset.aspect_ratio === asset.aspect_ratio);
                // Overwrite existing asset
                if (existingAssetIndex >= 0) {
                    updatedAds[adIndex].configuration.platform_specific[platform].creatives[0].assets[existingAssetIndex] = asset;
                }
                // Add new asset to the creative, -1 is returned if the asset is not found
                if (existingAssetIndex < 0) {
                    updatedAds[adIndex].configuration.platform_specific[platform].creatives[0].assets.push(asset);
                }

            });
            state.ads = updatedAds;
        },
        updateAdField(state, { adIndex, key, value, platform }) {
            // check if the ad is customized per platform
            let customizedPer = state.ads[adIndex].configuration.customized_per_platform;
            state.ads[adIndex].configuration.all_platforms[key] = value;

            if(customizedPer) {
                const sPlatform = supportedPlatforms.find(sPlatform => sPlatform.name === platform);
                let maxLengthSinglePlatform = lengthValidatorConstants?.[key]?.[platform]?.maxLength;

                if(sPlatform?.[key]) {
                    value = sPlatform[key].callback(value);
                }

                state.ads[adIndex].configuration.platform_specific[platform][key] = value.slice(0, (maxLengthSinglePlatform || value.length));
            } else {
                supportedPlatforms.forEach((sPlatform) => {
                    const platformName = sPlatform.name;
                    let tempValue = JSON.parse(JSON.stringify(value));
                    let maxLengthForPlatform = lengthValidatorConstants?.[key]?.[platformName]?.maxLength;

                    if(sPlatform[key]) {
                        tempValue = sPlatform[key].callback(tempValue);
                    }

                    state.ads[adIndex].configuration.platform_specific[platformName][key] = tempValue.slice(0, (maxLengthForPlatform || tempValue.length));
                });
            }
        },
        updateAdFormValidation(state, { adIndex, value, platform }) {
            const updatedAds = [ ...state.ads ];
            // If its customized per platform update only form validation the current platform
            if (updatedAds[adIndex].configuration.customized_per_platform) {
                updatedAds[adIndex].configuration.platform_specific[platform].form_valid = value;
            } else {
                // If its not customized per platform update the form validation for all platforms
                updatedAds[adIndex].configuration.selected_platforms.forEach((selected_platform) => {
                    updatedAds[adIndex].configuration.platform_specific[selected_platform].form_valid = value;
                });
            }
            state.ads = updatedAds;
        },
        changeActivePlatform(state, { adIndex, platform }) {
            const updatedAds = [ ...state.ads ];
            updatedAds[adIndex].active_platform = platform;
            state.ads = updatedAds;
        },
        setAllAds(state, { ads }) {
            state.ads = ads;
            state.adIndex = 0;
        },
        setShowValidationErrors(state, { value }) {
            state.showValidationErrors = value;
        },
        setEditedDesignAds(state, { asset }) {
            const currentEditedDesignAds = state.editedDesignAds;
            const exists = currentEditedDesignAds.find((ad) => ad.id === asset.id);
            if(exists) {
               //remove the asset from the array
               currentEditedDesignAds.splice(currentEditedDesignAds.indexOf(exists), 1);
            }
            currentEditedDesignAds.push(asset);
            state.editedDesignAds = currentEditedDesignAds;
        },
        setAllowSupportedChannelsToControlMediaStyles(state, { adIndex, value }) {
            const updatedAds = [ ...state.ads ];
            // Update the overall ad configuration which changes the toggle in the ui for all platforms
            updatedAds[adIndex].configuration.supported_platforms_control_media_styles = value;
            // Get the original asset to use later
            const originalAsset = updatedAds[adIndex].configuration.all_platforms.creatives[0].assets[0];
            // Get all the platforms that support channelControlsMediaStyles
            DEPLOYMENT_PLATFORMS.filter((platform) => platform.channelControlsMediaStyles).forEach((platform) => {
                // Update the configuration for each platform that supports platform_controls_media_styles
                updatedAds[adIndex].configuration.platform_specific[platform.name].platform_controls_media_styles = value;
                if (value) {
                    // Set the original asset as the asset for each platform that supports platform_controls_media_styles
                    updatedAds[adIndex].configuration.platform_specific[platform.name].creatives[0].assets = [originalAsset];
                } else {
                    // Remove all assets for each platform that does not support platform_controls_media_styles. These assets will be generated in
                    // src/components/ad-studio/editing/common/FilePreviewsList.vue @updateAspecRatioAssetWithTransformation
                    updatedAds[adIndex].configuration.platform_specific[platform.name].creatives[0].assets = [];
                }
            });
            state.ads = updatedAds;
        },
        setAdvancedMediaControls(state, { adIndex, value }) {
            const updatedAds = [ ...state.ads ];
            updatedAds[adIndex].configuration.advanced_media_controls = value;
            state.ads = updatedAds;
        },
        setCreativeEnhancement(state, { adIndex, value }) {
            const updatedAds = [ ...state.ads ];
            // Update the overall ad configuration which changes the toggle in the ui for all platforms
            updatedAds[adIndex].configuration.creative_enhancement = value;
            // Update the facebook creative_enhancement (aka advantage+) configuration as this is the only
            // platform using this feature at the moment. Add more platforms here if they support it in the future.
            updatedAds[adIndex].configuration.platform_specific[PLATFORM_FACEBOOK].creative_enhancement = value;
            state.ads = updatedAds;
        }
    }
};
