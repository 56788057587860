<template>
    <div
        v-show="open"
        class="text-align-window">
        <div class="text-content-picker-container">
            <div class="icons-row">
                <div
                    class="text-align-option text-align-left"
                    @click="handleTextAlign(aligmentOptions.left)">
                    <icon
                        name="text-align-right"
                        color="gray"
                        width="24"
                        height="24" />
                </div>
                <div
                    class="text-align-option"
                    @click="handleTextAlign(aligmentOptions.center)">
                    <icon
                        name="text-align-center"
                        width="24"
                        color="gray"

                        height="24" />
                </div>
                <div
                    class="text-align-option"
                    @click="handleTextAlign(aligmentOptions.right)">
                    <icon
                        name="text-align-right"
                        width="24"
                        height="24"
                        color="gray" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Icon from '@/components/globals/Icon';
    import { TEXT_ALIGNMENT } from '@/components/creative-studio/common/constants';
    import { mapActions } from 'vuex';

export default {
    components: {
        Icon
    },
    props: {

        open: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            aligmentOptions: {
                left: TEXT_ALIGNMENT.left,
                right: TEXT_ALIGNMENT.right,
                center: TEXT_ALIGNMENT.center
            }
        };
    },
    methods: {
        ...mapActions([
                'updateTextLayer',
            ]),
        handleTextAlign(alignOption) {
            this.updateTextLayer({
                textAlign: alignOption
            });
        }
    }
};

</script>

<style lang="scss">

.text-align-window {
    position: absolute;
    left: calc(50% - 60px);
    width: 120px;
    top: 40px;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: $white;
}

.text-content-picker-container {
    padding: 4px;
}


.icons-row {
    display: flex;
    justify-content: space-between;

    margin: 10px;
}

.text-align-option {
    cursor: pointer;
}

.text-align-left {
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>