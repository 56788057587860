<template>
    <sidebar-menu
        :menu="items"
        :collapsed="!value"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick">
        <div
            slot="header"
            class="menu-opener">
            <v-btn
                v-if="!$userHasRole(['pre_onboarding', 'onboarding'])"
                icon
                @click.stop="onToggle">
                <v-icon color="white">
                    menu
                </v-icon>
            </v-btn>
        </div>
    </sidebar-menu>
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu';
import getItems from './items';
import { mapState, mapGetters } from 'vuex';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import { get } from 'lodash';
import {
    FEATURE_FACEBOOK_AD_ACCOUNT,
    FEATURE_FACEBOOK_MARKETPLACE,
    FEATURE_MICROSOFT_AD_ACCOUNT,
    FEATURE_PINTEREST_AD_ACCOUNT,
    FEATURE_SNAPCHAT_AD_ACCOUNT,
    FEATURE_TIKTOK_AD_ACCOUNT,
} from '@/helpers/globals';

export default {
    name: 'Nav',
    components: {
        SidebarMenu,
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        overlayDrawer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            drawerActive: false,
            activeSubItem: '',
            items: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'userHasRoles',
            'userAgency',
            'userIsClient',
            'dealerHasFeatures',
            'dealerHasFeature',
            'dealerHasVertical',
        ]),
        platforms() {
            return {
                facebook: {
                    ads: this.dealerHasFeature(FEATURE_FACEBOOK_AD_ACCOUNT),
                    marketplace: this.dealerHasFeature(FEATURE_FACEBOOK_MARKETPLACE),
                },
                microsoft: this.dealerHasFeature(FEATURE_MICROSOFT_AD_ACCOUNT),
                snapchat: this.dealerHasFeature(FEATURE_SNAPCHAT_AD_ACCOUNT),
                tiktok: this.dealerHasFeature(FEATURE_TIKTOK_AD_ACCOUNT),
                pinterest: this.dealerHasFeature(FEATURE_PINTEREST_AD_ACCOUNT),
            };
        },
    },
    watch: {
        user() {
            this.setItems();
        },
        currentDealer() {
            this.setItems();
        },
        currentAgency() {
            this.setItems();
        },
    },
    created() {
        this.drawerActive = this.drawer;
        this.setItems();
    },
    methods: {
        onToggle() {
            this.$emit('input', !this.value);
            // Emit an additional event to differentiate when the user toggles
            this.$emit('toggle', !this.value);
        },
        onToggleCollapse(collapsed) {
            this.$emit('input', !collapsed);
        },
        onItemClick() {
        },
        setItems() {
            const userIsClient = this.userHasRoles(['client']);
            const items = getItems(this.currentDealer, this.currentAgency, this.platforms, userIsClient);
            this.items = items.filter(this.filterItems).map(this.setActiveItem);
        },
        setActiveItem(item) {

            if (item.child) {
                // Recursively call this function on all children
                item.child = item.child.map(this.setActiveItem);
                // If any of the children were marked active set this item (the parent) to active
                item.active = item.child.some(child => child.active);
            }

            // Retrieve the name safely or return null if none is found
            const routeName = get(item, 'href.name', null);
            const route = this.getRoute(routeName);

            // If there's no route there's nothing to do
            if (!route) {
                return item;
            }

            // If the current route path includes this path consider it active
            // This works for nested paths like /dealers/:id where /dealers would be active
            item.active = (this.$route.path.startsWith(route.path));

            return item;
        },
        filterItems(item) {
            // If the items has roles specified override all other logic
            if (item.roles) {
                return !!this.userHasRoles(item.roles);
            }

            // If there's no link but the item has children filter them
            if (!item.href && item.child) {
                // Run this function recursively to set the children while telling
                // Detecting whether the result has children which will filter out entire branches
                return (item.child = item.child.filter(this.filterItems)).length;
            }

            // Ensure the to property has the proper structure to check roles & features
            if (typeof item.href === 'object' && item.href.name) {
                const route = this.getRoute(item.href.name);
                const hasAgencyOverride = this.hasAgencyOverride(route);
                const verticals = this.getRouteVerticals(route);
                // Check if the route is locked to verticals for use downstream
                let dealerHasVerticals = null;
                if (verticals.length) {
                    dealerHasVerticals = verticals.some(vertical => this.dealerHasVertical(vertical));
                }
                const features = this.getRouteFeatures(route);
                // Check if the route is locked to features for use downstream
                let dealerHasFeatures = null;
                if (features.length) {
                    dealerHasFeatures = this.dealerHasFeatures(features);
                }
                // Get the roles from the route and determine if the route is locked to
                // specified roles then determine if the active user has this role (or
                // their agency has an active override) and their current dealer satisfies
                // the feature and/or vertical requirements (if set)
                const roles = this.getRouteRoles(route);
                if (roles.length) {
                    return (
                        (this.userHasRoles(roles) || hasAgencyOverride) &&
                        (dealerHasFeatures === null || dealerHasFeatures === true) &&
                        (dealerHasVerticals === null || dealerHasVerticals === true)
                    );
                }
                // If the vertical requirements have been specified use them
                if (dealerHasVerticals !== null) {
                    return dealerHasVerticals;
                }
                // If feature requirements have been specified use them
                if (dealerHasFeatures !== null) {
                    return dealerHasFeatures;
                }
                // One last check before finishing to see if the agency
                // can see the route (if specified)
                if (hasAgencyOverride) {
                    return true;
                }
            }
            // If we make it here continue recursively filtering out children
            if (item.child) {
                return (item.child = item.child.filter(this.filterItems)).length;
            }
        },
        hasAgencyOverride(route) {
            // Ensure there's a route and user agency to proceed
            if (!route || !this.userAgency) {
                return false;
            }

            const agencies = get(route, 'meta.agencies', null) || [];

            if (!this.userIsClient &&
                agencies.includes(this.userAgency.id)) {
                return true;
            }

            return false;
        },
        getRoute(name) {
            return this.$router.options.routes.find(route => route.name === name);
        },
        getRouteRoles(route) {

            if (!route) {
                return [];
            }
            // If there's route roles return them
            if (route.meta && route.meta.roles && route.meta.roles.length) {
                return route.meta.roles;
            }
            // Otherwise return an empty array
            return [];
        },
        getRouteFeatures(route) {
            return route?.meta?.features || [];
        },
        getRouteVerticals(route) {
            return route?.meta?.verticals || [];
        },
    },
};
</script>

<style lang="scss">

.menu-opener {
    margin: 20px 0;
}

.v-sidebar-menu {
    z-index: 160;
    background-color: $gray-dark;

    & > .vsm--list {
        width: auto !important; /* Resolve bug with built in calculation */
    }

    .vsm--link:focus {
        outline: none;
    }

    .vsm--item_open .vsm--link_level-1 {
        .vsm--title {
            color: white;
        }

        .vsm--arrow:after {
            color: white;
        }
    }

    .vsm--mobile-item {
        .vsm--title {
            color: $gray;
        }

        .vsm--link_mobile-item {
            .vsm--title {
                color: white;
            }

            .vsm--arrow:after {
                color: white;
            }
        }
    }

    &.vsm_expanded {
        .vsm--item_open {
            .vsm--link_level-1 {
                background-color: var(--color-primary);

                .vsm--icon {
                    background-color: var(--color-primary);
                }
            }
        }
    }

    &.vsm_collapsed {
        .vsm--link_level-1 {
            padding-left: 10px;
        }

        .vsm--header {
            max-height: 1px;
            margin: 20px;

            &:first-child {
                opacity: 0
            }
        }

        .vsm--link_level-1.vsm--link_hover,
        .vsm--link_level-1:hover {
            .vsm--icon {
                background-color: var(--color-primary);
            }
        }
    }

    .vsm--toggle-btn {
        display: none;
    }

    .vsm--header {
        overflow: hidden;
        color: $gray;
        border-bottom: 1px solid $gray;
        margin: 50px 20px 20px;
        padding: 0 0 5px;
        max-height: 100px;
        transition: all 0.2s ease-in-out;

        &:first-child {
            margin-top: 0;
        }
    }

    .vsm--title {
        color: $gray;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .vsm--link_active,
    .router-link-active {
        .vsm--title {
            color: white;
        }

        .vsm--arrow:after {
            color: white;
        }
    }

    .vsm--link_level-1.vsm--link_active,
    .router-link-active.vsm--link {
        .vsm--icon {
            color: white;
            background-color: transparent;
        }
    }

    .vsm--link_level-1.vsm--link_exact-active,
    .vsm--link_level-1.vsm--link_active {
        box-shadow: 3px 0px 0px 0px var(--color-primary) inset;
    }

    .vsm--mobile-bg {
        background-color: var(--color-primary);
    }

    .vsm--link_level-1 {
        padding-left: 20px;

        .vsm--icon {
            color: $gray;
            background-color: transparent;
            font-size: 20px;
        }
    }

    .vsm--arrow:after {
        content: $bb-icon-chevron-right;
        font-family: $icomoon-font-family;
        font-size: 10px;
        color: $gray;
    }

    .vsm--dropdown .vsm--list {
        background-color: $gray-darker;
    }

    hr {
        border-color: $gray;
        border-top: none;
        margin: 20px;
    }
}
</style>
