export const MILES_PER_METER = 0.00062137;

export const GENERIC_SITE_PROVIDER = 232;

export const WEBSITE_INVENTORY_SOURCE_TYPE = 3; // marketcheck_id_string
export const WEBSITE_INVENTORY_API_SOURCE = 65;
export const WEBSITE_INVENTORY_CSV_SOURCE = 60;
export const WEBSITE_INVENTORY_SOURCES = [
    WEBSITE_INVENTORY_API_SOURCE,
    WEBSITE_INVENTORY_CSV_SOURCE
];

export const AGENCY_BB = 1;
export const AGENCY_DU = 2;

export const AD_DEPLOYMENT_AGENCIES = [
    2,
    91, // iMOS
    146, // Drive
    100, // Social Hub
];

export const PRODUCT_MARKETPLACE = 19;
export const PRODUCT_MARKETPLACE_PLUS = 20;
export const PRODUCT_ADVERTISING_LITE = 30;
export const PRODUCT_ADVERTISING = 21;
export const PRODUCT_ADVERTISING_PLUS = 22;
export const PRODUCT_REPORTING = 23;
export const PRODUCT_BING_MARKETPLACE = 25;
export const PRODUCT_TIKTOK_ADS_LITE = 31;
export const PRODUCT_TIKTOK_ADS = 34;
export const PRODUCT_TIKTOK_ADS_PLUS = 27;
export const PRODUCT_SNAPCHAT_ADS_LITE = 32;
export const PRODUCT_SNAPCHAT_ADS = 35;
export const PRODUCT_SNAPCHAT_ADS_PLUS = 28;
export const PRODUCT_PINTEREST_ADS_LITE = 33;
export const PRODUCT_PINTEREST_ADS = 36;
export const PRODUCT_PINTEREST_ADS_PLUS = 29;
export const PRODUCT_GOOGLE_MARKETPLACE = 40;
export const PRODUCT_GOOGLE_ADS_PLUS = 39;
export const PRODUCT_MICROSOFT_ADS_PLUS = 26;
export const PRODUCT_MICROSOFT_MARKETPLACE_CPC = 41;
export const PRODUCT_EVENT_FLOW_PLUS = 44;

export const PRODUCT_GROUP_MARKETPLACE = 'marketplace';
export const PRODUCT_GROUP_ADVERTISING = 'advertising';

export const PRODUCT_CATEGORY_MARKETPLACE = 'marketplace';
export const PRODUCT_CATEGORY_MARKETPLACE_PLUS = 'marketplace_plus'
export const PRODUCT_CATEGORY_MARKETPLACE_CPC = 'marketplace_cpc'
export const PRODUCT_CATEGORY_ADVERTISING = 'advertising';
export const PRODUCT_CATEGORY_ADVERTISING_PLUS = 'advertising_plus';
export const PRODUCT_CATEGORY_ADVERTISING_LITE = 'advertising_lite';
export const PRODUCT_CATEGORY_REPORTING = 'reporting';
export const PRODUCT_CATEGORY_EVENT_FLOW_PLUS = 'event_flow_plus_addon';

export const FEATURE_MICROSITE = 'microsite';
export const FEATURE_FACEBOOK_PAGE = 'facebook_page';
export const FEATURE_FACEBOOK_AUDIENCE_LOCATION = 'facebook_audience_location';
export const FEATURE_GOOGLE_ANALYTICS = 'google_analytics';
export const FEATURE_TWILIO = 'twilio';
export const FEATURE_FACEBOOK_AD_ACCOUNT = 'facebook_ad_account';
export const FEATURE_FACEBOOK_LEAD_TOS = 'facebook_lead_tos';
export const FEATURE_FACEBOOK_CUSTOM_AUDIENCE_TOS = 'facebook_custom_audience_tos';
export const FEATURE_SITE_PROVIDER = 'site_provider';
export const FEATURE_FACEBOOK_PIXEL = 'facebook_pixel';
export const FEATURE_GOOGLE_TAG_MANAGER = 'google_tag_manager';
export const FEATURE_FACEBOOK_PRODUCT_CATALOG = 'facebook_product_catalog';
export const FEATURE_FACEBOOK_AUTOMOTIVE_CATALOG = 'facebook_automotive_catalog';
export const FEATURE_FACEBOOK_CUSTOM_CONVERSION = 'facebook_custom_conversion';
export const FEATURE_FACEBOOK_OFFLINE_EVENT_SET = 'facebook_offline_event_set';
export const FEATURE_FACEBOOK_MARKETPLACE = 'facebook_marketplace';
export const FEATURE_LEAD_DESTINATION = 'lead_destination';
export const FEATURE_CHATBOT = 'chatbot';
export const FEATURE_FACEBOOK_LEAD_FORM = 'facebook_lead_form';
export const FEATURE_WEBSITE_INVENTORY = 'website_inventory';
export const FEATURE_FEED_INVENTORY = 'feed_inventory';
export const FEATURE_INVENTORY = 'inventory';
export const FEATURE_FACEBOOK_MARKETPLACE_PAGE = 'facebook_marketplace_page';
export const FEATURE_EVENT_FLOW = 'event_flow';
export const FEATURE_EVENT_FLOW_PLUS = 'event_flow_plus';
export const FEATURE_EVENT_FLOW_PLUS_TAG = 'event_flow_plus_tag';
export const FEATURE_EVENT_FLOW_PLUS_DATA_SHARING = 'event_flow_plus_data_sharing_agreement';
export const FEATURE_EVENT_FLOW_PLUS_LEGAL = 'event_flow_plus_legal_agreement';
export const FEATURE_TIKTOK_AD_ACCOUNT = 'tiktok_ad_account';
export const FEATURE_TIKTOK_IDENTITY = 'tiktok_identity';
export const FEATURE_SNAPCHAT_AD_ACCOUNT = 'snapchat_ad_account';
export const FEATURE_SNAPCHAT_PUBLIC_PROFILE = 'snapchat_public_profile';
export const FEATURE_PINTEREST_AD_ACCOUNT = 'pinterest_ad_account';
export const FEATURE_MICROSOFT_AD_ACCOUNT = 'microsoft_ad_account';
export const FEATURE_MICROSOFT_PIXEL = 'microsoft_pixel';
export const FEATURE_MICROSOFT_CATALOG = 'microsoft_catalog';
export const FEATURE_MICROSOFT_VEHICLE_LISTING_ADS_SETUP = 'microsoft_vehicle_listing_ads_setup';
export const FEATURE_SNAPCHAT_HUB_SPOT_FORM = 'snapchat_hub_spot_form';
export const FEATURE_TIK_TOK_HUB_SPOT_FORM = 'tik_tok_hub_spot_form';
export const FEATURE_PINTEREST_HUB_SPOT_FORM = 'pinterest_hub_spot_form';
export const FEATURE_SNAPCHAT_CATALOG = 'snapchat_catalog';
export const FEATURE_SNAPCHAT_PIXEL = 'snapchat_pixel';
export const FEATURE_TIKTOK_PIXEL = 'tiktok_pixel';
export const FEATURE_TIKTOK_CATALOG = 'tiktok_catalog';
export const FEATURE_PINTEREST_PIXEL = 'pinterest_pixel';
export const FEATURE_PINTEREST_CATALOG = 'pinterest_catalog';
export const FEATURE_PINTEREST_BOARD = 'pinterest_board';
export const FEATURE_PLAYBOOK = 'playbook';
export const FEATURE_GOOGLE_AD_ACCOUNT = 'google_ad_account';
export const FEATURE_GOOGLE_PIXEL = 'google_pixel';
export const FEATURE_GOOGLE_CATALOG = 'google_catalog';
export const FEATURE_GOOGLE_MARKETPLACE = 'google_marketplace';
export const FEATURE_GOOGLE_IDENTITY = 'google_identity';
export const FEATURE_GOOGLE_STORE_CODE = 'google_store_code';
export const FEATURE_GOOGLE_VEHICLE_LISTING_ADS_SETUP = 'google_vehicle_listing_ads_setup';
export const FEATURE_MICROSOFT_MARKETPLACE_CPC_BILLING_AGREEMENT = 'microsoft_marketplace_cpc_billing_agreement';

export const MARKETPLACE_PRODUCTS = [
    PRODUCT_MARKETPLACE,
    PRODUCT_MARKETPLACE_PLUS,
    PRODUCT_BING_MARKETPLACE,
    PRODUCT_GOOGLE_MARKETPLACE
];

export const ADVERTISING_PRODUCTS = [
    PRODUCT_ADVERTISING_LITE,
    PRODUCT_ADVERTISING,
    PRODUCT_ADVERTISING_PLUS,
    PRODUCT_TIKTOK_ADS_LITE,
    PRODUCT_TIKTOK_ADS,
    PRODUCT_TIKTOK_ADS_PLUS,
    PRODUCT_SNAPCHAT_ADS_LITE,
    PRODUCT_SNAPCHAT_ADS,
    PRODUCT_SNAPCHAT_ADS_PLUS,
    PRODUCT_PINTEREST_ADS_LITE,
    PRODUCT_PINTEREST_ADS,
    PRODUCT_PINTEREST_ADS_PLUS,
    PRODUCT_GOOGLE_ADS_PLUS,
    PRODUCT_MICROSOFT_ADS_PLUS
];

export const EVENT_FLOW_REQUIRED_PRODUCTS = [
    PRODUCT_TIKTOK_ADS_LITE,
    PRODUCT_TIKTOK_ADS,
    PRODUCT_TIKTOK_ADS_PLUS,
    PRODUCT_SNAPCHAT_ADS_LITE,
    PRODUCT_SNAPCHAT_ADS,
    PRODUCT_SNAPCHAT_ADS_PLUS,
    PRODUCT_PINTEREST_ADS_LITE,
    PRODUCT_PINTEREST_ADS,
    PRODUCT_PINTEREST_ADS_PLUS
];

export const PLAYBOOK_PRODUCTS = [
    PRODUCT_ADVERTISING,
    PRODUCT_ADVERTISING_PLUS
];

export const FACEBOOK_FEATURES = [
    FEATURE_FACEBOOK_PAGE,
    FEATURE_FACEBOOK_AUDIENCE_LOCATION,
    FEATURE_FACEBOOK_AD_ACCOUNT,
    FEATURE_FACEBOOK_LEAD_TOS,
    FEATURE_FACEBOOK_CUSTOM_AUDIENCE_TOS,
    FEATURE_FACEBOOK_PIXEL,
    FEATURE_FACEBOOK_OFFLINE_EVENT_SET,
    // //'facebook_custom_conversion',
    FEATURE_FACEBOOK_LEAD_FORM,
];

export const SNAPCHAT_FEATURES = [
    FEATURE_SNAPCHAT_AD_ACCOUNT,
    FEATURE_SNAPCHAT_PIXEL,
    FEATURE_SNAPCHAT_PUBLIC_PROFILE,
];

export const TIKTOK_FEATURES = [
    FEATURE_TIKTOK_AD_ACCOUNT,
    FEATURE_TIKTOK_PIXEL,
    FEATURE_TIKTOK_IDENTITY,
];

export const PINTEREST_FEATURES = [
    FEATURE_PINTEREST_AD_ACCOUNT,
    FEATURE_PINTEREST_PIXEL,
    FEATURE_PINTEREST_BOARD,
];

const EVENT_FLOW_PLUS_PRODUCT = {
    id: PRODUCT_EVENT_FLOW_PLUS,
    display_name: 'EventFlowPlus',
    description: '',
    requirement: 'Requires Any Advertising',
    category: PRODUCT_CATEGORY_EVENT_FLOW_PLUS,
    upgradeFrom: [],
    addOns: []
}

export const PRODUCTS = [
    {
        id: PRODUCT_MARKETPLACE,
        display_name: 'Marketplace',
        description: 'Sync a dealership\'s pre-owned inventory to Facebook\'s organic Marketplace platform',
        category: PRODUCT_CATEGORY_MARKETPLACE,
        upgradeFrom: [],
        addOns: []
    },
    {
        id: PRODUCT_MARKETPLACE_PLUS,
        display_name: 'MarketplacePLUS',
        description: 'All the benefits of Marketplace with the ability to automate responses with our Chatbot',
        category: PRODUCT_CATEGORY_MARKETPLACE_PLUS,
        upgradeFrom: [
            PRODUCT_MARKETPLACE
        ],
        addOns: []
    },
    {
        id: PRODUCT_MICROSOFT_MARKETPLACE_CPC,
        display_name: 'MarketplaceCPC',
        description: 'Cast your dealer\'s inventory across Microsoft\'s growing organic vehicle marketplace. Want to take full advantage of the opportunity? Upgrade to their paid "Start Autos" CPC program for premium exposure.',
        category: PRODUCT_CATEGORY_MARKETPLACE_CPC,
        upgradeFrom: [
            PRODUCT_BING_MARKETPLACE
        ],
        addOns: []
    },
    {
        id: PRODUCT_ADVERTISING_LITE,
        display_name: 'AdvertisingLITE',
        description: 'Quickly onboard to Facebook and take advantage of multiple reporting options',
        category: PRODUCT_CATEGORY_ADVERTISING_LITE,
        upgradeFrom: [
            PRODUCT_REPORTING
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_ADVERTISING,
        display_name: 'Advertising',
        description: 'Create fully optimized Facebook Ad campaigns with Playbooks and Ads that are ready-to-go',
        category: PRODUCT_CATEGORY_ADVERTISING,
        upgradeFrom: [
            PRODUCT_ADVERTISING_LITE,
            PRODUCT_REPORTING
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_ADVERTISING_PLUS,
        display_name: 'AdvertisingPLUS',
        description: 'All the benefits of Advertising with added access to premium inventory & lead generation playbooks',
        category: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        upgradeFrom: [
            PRODUCT_ADVERTISING_LITE,
            PRODUCT_ADVERTISING,
            PRODUCT_REPORTING
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_BING_MARKETPLACE,
        display_name: 'Marketplace',
        description: 'Sync a dealership\'s new and pre-owned inventory to Microsoft\'s organic Marketplace platform',
        category: PRODUCT_CATEGORY_MARKETPLACE,
        upgradeFrom: [],
        addOns: []
    },
    {
        id: PRODUCT_MICROSOFT_ADS_PLUS,
        display_name: 'AdvertisingPLUS',
        description: 'All the benefits of Advertising with added access to premium inventory & lead generation playbooks',
        category: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        upgradeFrom: [],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_TIKTOK_ADS_LITE,
        display_name: 'AdvertisingLITE',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING_LITE,
        upgradeFrom: [],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_TIKTOK_ADS,
        display_name: 'Advertising',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING,
        upgradeFrom: [
            PRODUCT_TIKTOK_ADS_LITE
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_TIKTOK_ADS_PLUS,
        display_name: 'AdvertisingPLUS',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        upgradeFrom: [
            PRODUCT_TIKTOK_ADS_LITE,
            PRODUCT_TIKTOK_ADS
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_SNAPCHAT_ADS_LITE,
        display_name: 'AdvertisingLITE',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING_LITE,
        upgradeFrom: [],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_SNAPCHAT_ADS,
        display_name: 'Advertising',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING,
        upgradeFrom: [
            PRODUCT_SNAPCHAT_ADS_LITE
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_SNAPCHAT_ADS_PLUS,
        display_name: 'AdvertisingPLUS',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        upgradeFrom: [
            PRODUCT_SNAPCHAT_ADS_LITE,
            PRODUCT_SNAPCHAT_ADS
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_PINTEREST_ADS_LITE,
        display_name: 'AdvertisingLITE',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING_LITE,
        upgradeFrom: [],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_PINTEREST_ADS,
        display_name: 'Advertising',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING,
        upgradeFrom: [
            PRODUCT_PINTEREST_ADS_LITE
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_PINTEREST_ADS_PLUS,
        display_name: 'AdvertisingPLUS',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        upgradeFrom: [
            PRODUCT_PINTEREST_ADS_LITE,
            PRODUCT_PINTEREST_ADS
        ],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    {
        id: PRODUCT_GOOGLE_MARKETPLACE,
        display_name: 'Marketplace',
        description: '',
        category: PRODUCT_CATEGORY_MARKETPLACE,
        upgradeFrom: [],
        addOns: []
    },
    {
        id: PRODUCT_GOOGLE_ADS_PLUS,
        display_name: 'AdvertisingPLUS',
        description: '',
        category: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        upgradeFrom: [],
        addOns: [
            EVENT_FLOW_PLUS_PRODUCT
        ]
    },
    EVENT_FLOW_PLUS_PRODUCT
];


export const VERTICAL_AUTOMOTIVE = 1;
export const VERTICAL_OTHER = 7;

export const COOKIE_KEY_USER = '_bb_user';
export const COOKIE_KEY_TOKEN = '_bb_token';

export const LDU_STATES = ['CA'];

export const INTERFACE_FULL = 'full';
export const INTERFACE_MINIMAL = 'minimal';
export const INTERFACE_BLANK = 'blank';

export const FACEBOOK_OFFLINE_EVENT_TYPES = [
    {
        "name": "Purchase",
        "key": "purchase"
    },
    {
        "name": "Lead",
        "key": "lead"
    },
    {
        "name": "ViewContent",
        "key": "view_content"
    },
    {
        "name": "Search",
        "key": "search"
    },
    {
        "name": "AddToCart",
        "key": "add_to_cart"
    },
    {
        "name": "AddToWishlist",
        "key": "add_to_wish_list"
    },
    {
        "name": "InitiateCheckout",
        "key": "initiate_checkout"
    },
    {
        "name": "AddPaymentInfo",
        "key": "add_payment_info"
    },
    {
        "name": "Other",
        "key": "other"
    }
];

export const INTERVAL_DAY = 'day';
export const INTERVAL_WEEK = 'week';
export const INTERVAL_MONTH = 'month';
export const INTERVAL_QUARTER = 'quarter';

export const INTERVALS = [
    {
        text: 'Daily',
        value: INTERVAL_DAY,
        increment: 1
    },
    {
        text: 'Weekly',
        value: INTERVAL_WEEK,
        increment: 7
    },
    {
        text: 'Monthly',
        value: INTERVAL_MONTH,
        increment: 'monthly'
    },
    {
        text: 'Quarterly',
        value: INTERVAL_QUARTER,
        increment: 90
    }
];

export const ATTRIBUTION_CLICK = 'click';
export const ATTRIBUTION_VIEW = 'view';

export const ATTRIBUTION_1D = '1d';
export const ATTRIBUTION_7D = '7d';
export const ATTRIBUTION_28D = '28d';

export const ATTRIBUTION_1D_CLICK = '1d_click';
export const ATTRIBUTION_7D_CLICK = '7d_click';
export const ATTRIBUTION_28D_CLICK = '28d_click';

export const ATTRIBUTION_1D_VIEW = '1d_view';
export const ATTRIBUTION_7D_VIEW = '7d_view';
export const ATTRIBUTION_28D_VIEW = '28d_view';

export const FACEBOOK_ATTRIBUTION_WINDOWS = [
    {
        name: '1-Day Click',
        key: ATTRIBUTION_1D_CLICK
    },
    {
        name: '1-Day View',
        key: ATTRIBUTION_1D_VIEW
    },
    {
        name: '7-Day Click',
        key: ATTRIBUTION_7D_CLICK
    },
    {
        name: '7-Day View',
        key: ATTRIBUTION_7D_VIEW
    },
    {
        name: '28-Day Click',
        key: ATTRIBUTION_28D_CLICK
    },
    {
        name: '28-Day View',
        key: ATTRIBUTION_28D_VIEW
    }
];

export const FACEBOOK_ATTRIBUTION_WINDOW_KEYS = FACEBOOK_ATTRIBUTION_WINDOWS.map(window => window.key);

export const FACEBOOK_AD_PLACEMENT_DESKTOP_FEED_STANDARD = 'DESKTOP_FEED_STANDARD';
export const FACEBOOK_AD_PLACEMENT_MOBILE_FEED_STANDARD = 'MOBILE_FEED_STANDARD';
export const FACEBOOK_AD_PLACEMENT_FACEBOOK_STORY_MOBILE = 'FACEBOOK_STORY_MOBILE';
export const FACEBOOK_AD_PLACEMENT_MARKETPLACE_MOBILE = 'MARKETPLACE_MOBILE';
export const FACEBOOK_AD_PLACEMENT_INSTAGRAM_STORY = 'INSTAGRAM_STORY';

export const FACEBOOK_AD_PLACEMENTS = [
    {
        name: 'Mobile News Feed',
        queryName: 'mobile_news_feed',
        apiName: FACEBOOK_AD_PLACEMENT_MOBILE_FEED_STANDARD,
        iframeWidth: 500,
        iframeHeight: 690
    },
    {
        name: 'Desktop News Feed',
        queryName: 'desktop_news_feed',
        apiName: FACEBOOK_AD_PLACEMENT_DESKTOP_FEED_STANDARD,
        iframeWidth: 500,
        iframeHeight: 950
    },
    {
        name: 'Facebook Story Mobile',
        queryName: 'facebook_story_mobile',
        apiName: FACEBOOK_AD_PLACEMENT_FACEBOOK_STORY_MOBILE,
        iframeWidth: 500,
        iframeHeight: 570
    },
    {
        name: 'Marketplace Mobile',
        queryName: 'marketplace_mobile',
        apiName: FACEBOOK_AD_PLACEMENT_MARKETPLACE_MOBILE,
        iframeWidth: 180,
        iframeHeight: 270
    },
    {
        name: 'Instagram Story',
        queryName: 'instagram_story',
        apiName: FACEBOOK_AD_PLACEMENT_INSTAGRAM_STORY,
        iframeWidth: 500,
        iframeHeight: 570
    }
]

export const XML_EMAIL_LEAD_SENDER = 'XmlEmailLeadSender';
export const TEXT_EMAIL_LEAD_SENDER = 'TextEmailLeadSender';
export const WEBHOOK_LEAD_SENDER = 'WebhookLeadSender';

// @todo consolidate these with the variations below
export const PLATFORM_ID_FACEBOOK = 1;
export const PLATFORM_ID_TIKTOK = 8;
export const PLATFORM_ID_SNAPCHAT = 9;
export const PLATFORM_ID_PINTEREST = 10;
export const PLATFORM_ID_MICROSOFT = 7;
export const PLATFORM_ID_GOOGLE = 11;

export const PLATFORM_FACEBOOK = 'facebook';
export const PLATFORM_META = 'meta';
export const FACEBOOK_BB_PLATFORM_ID = 1;
export const FACEBOOK_REPORT_ID = 1;

// this is a deleted id for conversion_logix and should be changed in the future
// this is only being used in ad previews at the moment
export const PLATFORM_INSTAGRAM = 'instagram';
export const INSTAGRAM_BB_PLATFORM_ID = 5;

export const PLATFORM_MICROSOFT = 'microsoft';
export const MICROSOFT_BB_PLATFORM_ID = 7;

export const PLATFORM_TIKTOK = 'tiktok';
export const TIKTOK_BB_PLATFORM_ID = 8;
export const TIKTOK_REPORT_ID = 80;

export const PLATFORM_SNAPCHAT = 'snapchat';
export const SNAPCHAT_BB_PLATFORM_ID = 9;
export const SNAPCHAT_REPORT_ID = 58;

export const PLATFORM_PINTEREST = 'pinterest';
export const PINTEREST_BB_PLATFORM_ID = 10;
export const PINTEREST_REPORT_ID = 85;

export const PLATFORM_GOOGLE = 'google';
export const GOOGLE_BB_PLATFORM_ID = 11;

export const OBJECT_TYPE_DEPLOYED_ADS = 'deployed-ads';
export const OBJECT_TYPE_CAMPAIGN = 'campaign';
export const OBJECT_TYPE_ADSET = 'adset';
export const OBJECT_TYPE_AD = 'ad';

export const BUDGET_LEVEL_ADSET = 'AD_SET';
export const BUDGET_LEVEL_CAMPAIGN = 'CAMPAIGN';

export const PLATFORM_STATUS_ACTIVE = 'ACTIVE';
export const PLATFORM_STATUS_PAUSED = 'PAUSED';

export const PLATFORM_STATUS_OPTIONS = [{
    value: PLATFORM_STATUS_ACTIVE,
    label: 'Active'
}, {
    value: PLATFORM_STATUS_PAUSED,
    label: 'Paused'
}, {
    value: '',
    label: 'Any'
}];

export const PLATFORM_MAPPING = {
    [PLATFORM_ID_FACEBOOK]: PLATFORM_META,
    [PLATFORM_ID_TIKTOK]: PLATFORM_TIKTOK,
    [PLATFORM_ID_GOOGLE]: PLATFORM_GOOGLE,
    [PLATFORM_ID_SNAPCHAT]: PLATFORM_SNAPCHAT,
    [PLATFORM_ID_PINTEREST]: PLATFORM_PINTEREST,
    [PLATFORM_ID_MICROSOFT]: PLATFORM_MICROSOFT,
};

export const EVENT_USER_LOGIN = 'BB-UserLogin';
export const EVENT_USER_LOGOUT = 'BB-UserLogout';
export const EVENT_USER_SET = 'BB-UserSet';
export const EVENT_DEALER_SET = 'BB-DealerSet';
export const EVENT_AGENCY_SET = 'BB-AgencySet';
export const EVENT_ROUTE_SET = 'BB-RouteSet';
export const EVENT_DESIGNER_OPEN = 'BB-Designer-Open';
export const EVENT_DESIGNER_CLOSE = 'BB-Designer-Close';

export const ALL_PLACEMENTS = 'All Placements';
export const FACEBOOK_DESKTOP_FEED = 'Facebook Desktop Feed';
export const FACEBOOK_MOBILE_FEED = 'Facebook Mobile Feed';
export const FACEBOOK_STORY = 'Facebook Stories';
export const FACEBOOK_REEL = 'Facebook Reels';
export const INSTAGRAM_DESKTOP_FEED = 'Instagram Desktop Feed';
export const INSTAGRAM_MOBILE_FEED = 'Instagram Feed';
export const INSTAGRAM_STORY = 'Instagram Stories';
export const INSTAGRAM_REEL = 'Instagram Reels';
export const SNAPCHAT_FEED = 'Snapchat Feed';
export const TIKTOK_FEED = 'Tiktok Feed';
export const PINTEREST_FEED = 'Pinterest Feed';

export const META_IN_AD_STUDIO = true;
export const SNAPCHAT_IN_AD_STUDIO = true;
export const TIKTOK_IN_AD_STUDIO = true;
export const PINTEREST_IN_AD_STUDIO = true;

export const BB_STATUS_ACTIVE = 'ACTIVE';
export const BB_STATUS_PAUSED = 'PAUSED';
export const BB_DEFAULT_DAILY_BUDGET = 20;

export const BB_USER_ID = 3099;

export const PLATFORM_TAG_GROUP_ID = 1;
export const MEDIA_FORMAT_TAG_GROUP_ID = 4;
export const BRANDS_TAG_GROUP_ID = 7;

export const FEEDS_BASE_URL = 'https://ad:tech@feeds.buyerbridge.io/feeds';

// elastic search keys
export const FACEBOOK_KEY = 'facebook_auto';
export const GOOGLE_MARKETPLACE_KEY = 'google_marketplace';
export const GOOGLE_ADVERTISING_KEY = 'google_auto';
export const MICROSOFT_MARKETPLACE_KEY = 'microsoft_marketplace';
export const MICROSOFT_ADVERTISING_KEY = 'microsoft_auto';
export const PINTEREST_ADVERTISING_KEY = 'pinterest_auto';
export const TIKTOK_ADVERTISING_KEY = 'tiktok_auto';
export const SNAPCHAT_ADVERTISING_KEY = 'snapchat_auto';

// event flow plus docs
export const EVENT_FLOW_PLUS_PRIVACY_BANNER_OPT_IN = 'event_flow_plus_privacy_banner_opt_in';
export const EVENT_FLOW_PLUS_NO_PRIVACY_BANNER = 'event_flow_plus_no_privacy_banner';
export const EVENT_FLOW_PLUS_PRIVACY_BANNER_OPT_OUT = 'event_flow_plus_privacy_banner_opt_out';

// BUYERBRIDGE ASSET TYPES
export const IMAGE = 'IMAGE';
export const VIDEO = 'VIDEO';
export const COLLECTION = 'COLLECTION';

// BUYERBRIDGE RENDER TYPES
export const STATIC = 'STATIC';
export const DYNAMIC = 'DYNAMIC';

export const TEN_MEGABYTES = 10485760;

// GROUPS ROUTES
export const GROUPS_ROUTES = [
    'account-management',
    'communication-central',
    'resellers-billing',
    'resellers-reports-channel-budgeting',
    'resellers-reports-channel-advertising',
    'resellers-reports-facebook-advertising',
    'resellers-reports-facebook-offline-events',
]

// PERMISSIONS -------------------------------------------
// Account Management Permissions
export const PERMISSION_CREATE_USER = 'create_user';
export const PERMISSION_READ_ACCOUNT_GROUP = 'read_account_group';
export const PERMISSION_CREATE_ACCOUNT_GROUP = 'create_account_group';
export const PERMISSION_UPDATE_ACCOUNT_GROUP = 'update_account_group';
export const PERMISSION_DELETE_ACCOUNT_GROUP = 'delete_account_group';
export const PERMISSION_SHARE_ACCOUNT_GROUP = 'share_account_group';
export const PERMISSION_UNSHARE_ACCOUNT_GROUP = 'unshare_account_group';
export const PERMISSION_READ_ACCOUNT_GROUP_DEALER = 'read_account_group_dealer';
export const PERMISSION_CREATE_ACCOUNT_GROUP_DEALER = 'create_account_group_dealer';
export const PERMISSION_UPDATE_ACCOUNT_GROUP_DEALER = 'update_account_group_dealer';
export const PERMISSION_DELETE_ACCOUNT_GROUP_DEALER = 'delete_account_group_dealer';
export const PERMISSION_UPDATE_AGENCY_USER = 'update_agency_user';

/// Configuration keys
// Agency
export const OVERRIDE_UTM_PARAMS = 'override_utm_params';
