/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'code': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" _fill="#8F9EA6" d="M11.51.703l.144.015c.45.075.794.364.862.796.02.126.015.25-.015.41L9.62 14.474c-.089.438-.384.734-.81.801-.155.025-.307.02-.453-.014-.469-.093-.804-.335-.873-.775a1.006 1.006 0 01.017-.398l2.881-12.573c.096-.427.422-.73.851-.798.136-.021.274-.021.421 0zM5.982 3.5c.42 0 .741.281.883.62.09.171.135.364.135.558 0 .354-.162.668-.464.846L2.67 7.988l3.89 2.505c.247.179.389.432.42.718l.007.124c0 .162-.037.335-.11.52-.173.376-.487.645-.895.645a.91.91 0 01-.522-.168L.58 9.112C.19 8.835 0 8.495 0 8c0-.556.223-.906.72-1.24l4.832-3.142a.899.899 0 01.43-.118zm8.036 0a.91.91 0 01.522.167l4.88 3.22c.391.279.58.618.58 1.113 0 .555-.223.905-.72 1.238l-4.832 3.144a.899.899 0 01-.43.118c-.42 0-.741-.282-.883-.622a1.203 1.203 0 01-.135-.557c0-.354.162-.668.464-.845l3.866-2.464-3.89-2.506a1.01 1.01 0 01-.42-.718l-.007-.124c0-.162.037-.335.11-.52.173-.376.487-.644.895-.644z"/>'
  }
})
