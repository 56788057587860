/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'slideshow': {
    width: 54,
    height: 32,
    viewBox: '0 0 54 32',
    data: '<g _stroke="#8F9EA6" stroke-width="1.25" _fill="none" fill-rule="evenodd" stroke-linejoin="round"><path pid="0" _fill="#FFF" d="M37 24h16V8H37z"/><path pid="1" d="M37 21l3.9-3.858 3.42 3.296L49.54 15 53 18.531M44 13a2 2 0 11-4 0 2 2 0 014 0z"/><g><path pid="2" _fill="#FFF" d="M.8 24h16V8H.8z"/><path pid="3" d="M.8 21l3.9-3.858 3.42 3.296L13.34 15l3.46 3.531M8.8 13a2 2 0 11-4 0 2 2 0 014 0z"/></g><g><path pid="4" _fill="#FFF" d="M13 31h30V1H13z"/><path pid="5" d="M13 26.151l7.312-7.233 6.414 6.179L36.51 14.9 43 21.523M27.063 9.53a3.99 3.99 0 11-7.979 0 3.99 3.99 0 017.979 0z"/></g></g>'
  }
})
