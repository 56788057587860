<template>
    <div
        :class="{
            'data-field-wrapper': true,
            'no-indent': noIndent,
            'no-wrap': noWrap
        }">
        <div
            v-if="$slots.label || label"
            class="data-field-label">
            <slot name="label">
                {{ label }}
            </slot>
        </div>
        <div
            class="data-field-data"
            :style="{
                marginLeft: activeMarginLeft
            }">
            <div
                v-if="$slots.indicator"
                class="data-field-indicator">
                <slot name="indicator" />
            </div>
            <div class="data-field-value">
                <slot />
            </div>
            <div
                v-if="$slots.actions"
                class="data-field-actions">
                <slot name="actions" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataField',
    props: {
        noIndent: {
            type: Boolean,
            default: false
        },
        noWrap: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        marginLeft: {
            type: String,
            default: undefined
        }
    },
    computed: {
        activeMarginLeft() {
            if (this.marginLeft) {
                return this.marginLeft;
            }

            if (this.noIndent) {
                return '0px';
            }

            return '15px';
        }
    }
};
</script>

<style lang="scss" scoped>

.data-field-label {
    color: $loblolly;
    padding-bottom: 5px;
}

.data-field-data {
    padding-bottom: 5px;
    display: flex;
    position: relative;
}

.data-field-value {
    overflow: hidden;
    .no-wrap & {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.data-field-indicator {
    position: absolute;
    right: calc(100% + 5px);
    top: calc(50% - 3px);
    transform: translateY(-50%);
}

.data-field-actions {
    margin-left: 5px;
}
</style>
