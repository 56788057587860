/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'data': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.978 13.866v3.978c0 2.037-6.546 2.15-7.406 2.156h-.166C9.546 19.994 3 19.88 3 17.844V13.89c1.284.444 4.202 1.244 7.75 1.244 2.243 0 4.725-.333 7.228-1.267zM18 8.244v4.6c-.022.022-.044.022-.065.022-5.61 2.216-11.422 1.053-13.904.357l-.287-.083c-.315-.093-.56-.173-.722-.23V8.245c1.045.355 4.05 1.156 7.75 1.156 2.243 0 4.746-.312 7.228-1.156zM10.643 0c1.097.012 7.335.167 7.335 2.155v5.089c-.022 0-.065.022-.087.022-7.206 2.6-14.477.111-14.804 0-.022-.022-.043-.044-.087-.044V2.155C3 .167 9.238.012 10.335 0h.308zm-.154.955c-3.418 0-5.225.555-5.834.777-.11.045-.196.067-.262.112-.087.044-.152.066-.217.11a.76.76 0 00-.196.134c-.044.045-.066.067-.022.089l.022.023.021.021a.384.384 0 00.11.09l.021.022c.044.044.109.066.152.088l.022.023a.647.647 0 00.174.089l.066.022.196.066.087.023c.065.044.152.066.24.089l.108.044c.087.022.174.044.283.067l.13.022.327.067c.066.022.153.022.24.044l.217.044.196.023.131-.045c.087.022.152.022.24.045.087.022.195.022.283.044.087.022.174.022.283.045.087 0 .195.022.283.022.108 0 .217.022.326.022.087 0 .196.022.283.022l.392.022.283.023c.152.022.305.022.457.022h1.981c.153 0 .305-.022.458-.022l.283-.023.391-.022c.087 0 .196-.022.283-.022.11 0 .218 0 .327-.022l.283-.022c.087-.023.196-.023.283-.045.109-.022.196-.022.283-.044.087-.023.152-.023.24-.045h.043l.109-.022.326-.067c.088-.022.175-.022.24-.044l.327-.067.13-.022a5.63 5.63 0 00.283-.067l.109-.022c.087-.044.152-.067.24-.089l.065-.022c.087-.044.152-.067.218-.089l.065-.022c.065-.022.13-.045.174-.067l.044-.022c.065-.044.108-.067.152-.089l.022-.022c.021-.022.065-.045.087-.067l.065-.066c0-.045-.196-.245-.849-.467-.631-.2-2.417-.711-5.704-.711z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
