<template>
    <div class="panel-container">
        <img
            :src="icon"
            width="34px"
            alt="Icon">
        <h2>{{ title }}</h2>
        <p>{{ text }}</p>
        <styled-button
            @click="redirectToPage()">
            SELECT
        </styled-button>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';

export default {
  name: 'Panel',
  components: {
    StyledButton,
  },
  props: {
    isNewCampaignComponent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      if (this.isNewCampaignComponent) {
        return require('@/assets/icons/light-bulb-icon.svg');
      }

      return require('@/assets/icons/target-people-icon.svg');
    },
    title() {
      if (this.isNewCampaignComponent) {
        return this.$t('adDeployment.deploymentModal.panel.newCampaign.title');
      }

      return this.$t('adDeployment.deploymentModal.panel.existingCampaign.title');
    },
    text() {
      if (this.isNewCampaignComponent) {
        return this.$t('adDeployment.deploymentModal.panel.newCampaign.text');
      }

      return this.$t('adDeployment.deploymentModal.panel.existingCampaign.text');
    },
  },
  methods: {
    redirectToPage() {
        const deploymentLocation = this.isNewCampaignComponent ? 'all_platforms' : 'platform_specific';
        this.$store.commit('adDeployment/setDeploymentLocation', deploymentLocation);

        this.$emit('close-deployment-modal', deploymentLocation);
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid lightgrey;
    width: 250px;
    padding: 20px;

    button {
        height: 25px;
        width: 65px;
    }
}
</style>
