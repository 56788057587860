/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'not-linked': {
    width: 16,
    height: 16,
    viewBox: '0 0 200 200',
    data: '<path pid="0" d="M4.752 7.416a1 1 0 01-.063 1.991l-.18-.006-2.704-.85a1 1 0 01.063-1.992l.18.006 2.704.851zm1.702-2.377a1 1 0 01-1.803.848l-.077-.164-.469-2.795a1 1 0 011.803-.848l.076.164.47 2.795zm9.824 7.373a1 1 0 01.063-1.99l.18.005 2.704.85a1 1 0 01-.063 1.992l-.18-.006-2.704-.851zm-1.702 2.378a1 1 0 011.802-.848l.078.163.469 2.795a1 1 0 01-1.803.848l-.076-.164-.47-2.795zM2.38 18.69c-1.84-1.742-1.84-4.579 0-6.322l1.7-1.608a1.046 1.046 0 011.423 0 .917.917 0 010 1.348l-1.698 1.608a2.473 2.473 0 000 3.627 2.77 2.77 0 001.915.752 2.77 2.77 0 001.916-.752l3.715-3.517c1.056-1 2.588-.11 1.423 1.347L9.058 18.69A4.828 4.828 0 015.72 20a4.83 4.83 0 01-3.338-1.31zM8.227 4.827l3.715-3.517A4.828 4.828 0 0115.281 0c1.261 0 2.446.465 3.338 1.31 1.841 1.742 1.841 4.578 0 6.321L16.921 9.24a1.046 1.046 0 01-1.423 0 .917.917 0 010-1.347l1.698-1.608a2.472 2.472 0 000-3.627 2.77 2.77 0 00-1.915-.752 2.77 2.77 0 00-1.916.752L9.65 6.174c-1.056 1-2.379.11-1.423-1.347z" _fill="#00A2EA" fill-rule="evenodd"/>'
  }
})
