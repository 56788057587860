export const DEFAULT_CARD_DIMENSIONS = {
    height: 1080,
    width: 1080
};

import { LinkedStatuses } from '@/creative-studio/domain/layered-designer/models/types';

export class Card {
    name;
    layers= [];

    dimensions= {
        id: 1,
        height: DEFAULT_CARD_DIMENSIONS.height,
        width: DEFAULT_CARD_DIMENSIONS.width,
        aspectRatio: '1:1',
        linkedStatus: LinkedStatuses.Main
    }

    thumbnail;

    originalBaseDimensions= {
        mainCardSizeDimensions: {
            id: 1,
            height: DEFAULT_CARD_DIMENSIONS.height,
            width: DEFAULT_CARD_DIMENSIONS.width,
            aspectRatio: '1:1',
            linkedStatus: LinkedStatuses.Main
        }
    }

    constructor(id) {
        this.id = id;
    }
}