import cache from '@/apis/cache';
import HTTP from '@/http';
import moment from 'moment';

async function getDealerMetrics(config) {

    const cacheKey = `tiktok_${JSON.stringify(config)}`;

    const cacheEntry = cache.get(cacheKey);

    if (cacheEntry) {
        return cacheEntry;
    }

    // Set defaults
    config = {
        ...{
            dealerId: config.dealerId,
            start_date: moment(config.startDate).format('YYYY-MM-DD'),
            end_date: moment(config.endDate).format('YYYY-MM-DD'),
            level: config.level || 'account',
            interval: config.interval || null
        },
    };

    const dealerId = config.dealerId;
    delete config.dealerId;

    const response = await (new HTTP()).get(`/dealers/${dealerId}/platforms/tiktok/metrics`, config);

    const data = response.data || null;

    cache.set(cacheKey, data);

    return data;
}

export default getDealerMetrics;