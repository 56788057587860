/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ig_reels_arrow_back_icon': {
    width: 12,
    height: 20,
    viewBox: '0 0 12 20',
    data: '<path pid="0" _fill="#FFF" d="M12 17.667L9.73 20 0 10 9.73 0 12 2.333 4.541 10z"/>'
  }
})
