<template>
    <div
        v-show="open"
        class="anchor-picker-window">
        <div class="align-content-picker-title">
            Pin to edge
        </div>
        <div class="anchor-picker-container">
            <SVGAnchor
                :width="'100'"
                :height="'100'"
                :positions="currentLayerAnchoredPositions"
                @position-clicked="onPositionClick" />
        </div>
    </div>
</template>

<script>
    import SVGAnchor from './anchors/SVGAnchor.vue';
    import { mapGetters, mapActions } from 'vuex';
    import { MAX_ANCHORS_ON_IMAGE_ASSETS } from '@/components/creative-studio/common/constants';
    export default {
        components: {
            SVGAnchor
        },
        props: {
            open: {
                required: true,
                type: Boolean
            },
        },
        computed: {
            ...mapGetters([
                'focusedLayer'
            ]),
            currentLayerAnchoredPositions() {
                return this.focusedLayer.anchoredPositions;
            },
        },
        methods:{
            ...mapActions([
                'updateFocusedLayerAnchoredPositions'
            ]),
            onPositionClick(position) {
                if (this.focusedLayer === null) {
                    return;
                }
                const isMaxOfAchorsForImage = this.currentLayerAnchoredPositions.length  === MAX_ANCHORS_ON_IMAGE_ASSETS && this.focusedLayer.asset.isImageAsset();
                const isRemovingAnchor = this.currentLayerAnchoredPositions.includes(position);
                if(isMaxOfAchorsForImage && !isRemovingAnchor ) {
                    return;
                }
                this.updateFocusedLayerAnchoredPositions(position);
            },
        }
    };
</script>

<style lang="scss">

.anchor-picker-window {
    position: absolute;
    left: calc(50% - 70px);
    width: 150px;
    top: 40px;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: $white;
}

.anchor-picker-container {
    text-align: center;
    margin: 15px;
}


.align-content-picker-title {
    text-align: center;
    color: $gray;
    font-size: 12px;
    margin-top: 5px;
}

</style>
