import { isPlainObject } from 'lodash';
import translateToRequestFormat from '../translate.js';
import ApiRepository from '@/api-repository';
import router from '@/routes';
import getInitialState, { getExtendedState } from './getInitialState.js';
import { PLATFORM_FACEBOOK } from '@/helpers/globals.js';
import { STATIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';

const apiRepository = new ApiRepository();

export default {

    /**
     * Creates a new deployment and loads it into context.
     * This function can be used in multiple contexts
     */
    async start({
        dispatch,
        commit
    },{
        fromCreated,
        configuration,
        dealerId,
        userId,
    }) {
        if (configuration.deployment_type === STATIC_DEPLOYMENT_TYPE && !configuration?.ads?.length) {
            throw new Error('The deployment must, at least, have ads to begin');
        }

        const response = await apiRepository.createAdDeployment({
            dealer_id: dealerId,
            user_id: userId,
            deployment_type: configuration.deployment_type,
            configuration
        });

        // pre-populating 1 step if we've selected some platforms in Ad Studio
        if (configuration.platforms) {
            commit('setPlatforms', configuration.platforms);
        }

        await dispatch('initialize', response.data.data);

        router.push({
            name: 'ad-deployment',
            params: {
                ad_deployment_id: response.data.data.id,
                dealer_id: dealerId
            },
            ...(STATIC_DEPLOYMENT_TYPE && { query: {
                from_created: fromCreated ?? false
            } })
        });
    },

    /**
     * Initializes the deployment from the server
     * based on a given ID.  This will prime the entire
     * state with remote data so the user can begin
     * customizing after this point.
     */
    async load({ dispatch }, id) {
        const response = await apiRepository.getAdDeployment(id);
        await dispatch('initialize', response.data.data);
    },

    /**
     * Initializes the deployment with response data from the server.
     * This will prime the entire state with remote data so the user
     * can begin customizing after this point.
     */
    async initialize({
        dispatch,
        commit,
        rootState
    },{
        configuration,
        dealer_id,
        id
    }) {

        const deploymentType = configuration?.deployment_type;
        // Reset the state to override any existing deployment
        const fullConfiguration = {
            ...getInitialState(deploymentType),
            ...configuration
        };

        // Initialize the remote configuration into the local store
        await dispatch('initializeConfiguration', fullConfiguration);

        // Keep the deployment ID in state
        commit('setDeploymentId', id);

        // Set the dealer ID in the state
        commit('setDealerId', dealer_id);

        // If the dealer in the ad deployment is not the same
        // as the current dealer then update the current dealer
        if (rootState.dealer.currentDealerId != dealer_id) {
            await dispatch('updateDealerById', dealer_id, { root: true });
        }
    },

    /**
     * Saves the current ad deployment to the server
     */
    save({ state }) {
        return apiRepository.updateAdDeployment({
            id: state.ad_deployment_id,
            data: state
        });
    },

    /**
     * Deploys a configured ad deployment which sends each
     * platform's translated data to the builders and
     * redirects to campaign management.
     */
    async deploy({ state, rootState, dispatch }) {

        // Kick off all deployments at once
        const deployments = state.platforms.map(platform => (
            apiRepository.deployAdToPlatform(
                rootState.dealer.currentDealerId,
                platform,
                translateToRequestFormat(state, platform)
            )
        ));

        // Wait for them to resolve
        await Promise.all(deployments);

        // Send the user to campaign management
        router.push({
            name: 'campaign-management',
            params: {
                dealer_id: rootState.dealer.currentDealerId
            },
            query: {
                ad_deployment_id: state.ad_deployment_id
            }
        });

        await dispatch('clear');
    },

    goToNextStep({ state, dispatch }) {
        let nextStep = state.current_step + 1;

        // If the user has made it further than the
        // next incremental step move them to the
        // latest step.  This enables editing.
        if (state.latest_step > nextStep) {
            nextStep = state.latest_step;
        }
        dispatch('updateCurrentStep', nextStep);

        // Save to the server
        dispatch('save');
    },

    goToPreviousStep({ state, dispatch }) {
        dispatch('updateCurrentStep', state.current_step - 1);
    },

    async goToStepByName({ dispatch }, name) {
        const stepIndex = await dispatch('getStepByName', name);

        if (stepIndex >= 0) {
            dispatch('updateCurrentStep', stepIndex);
        }
    },

    async alterProgressToStepByName({ commit, dispatch }, name) {
        const stepIndex = await dispatch('getStepByName', name);
        commit('setLatestStep', stepIndex);
        dispatch('updateCurrentStep', stepIndex);
    },

    getStepByName({ getters }, name) {
        const stepIndex = getters.currentSteps.findIndex(step => (
            step.name === name
        ));

        return (stepIndex > -1) ? stepIndex : null;
    },

    updateCurrentStep({ getters, commit }, index) {
        // Guard from over-navigation
        if (getters.currentSteps.length < index) {
            commit('setCurrentStep', getters.currentSteps.length - 1);
        }
        // Guard from under-navigation
        else if (index < 0) {
            commit('setCurrentStep', 0);
        } else {
            commit('setCurrentStep', index);
        }
    },

    /**
     * Initializes deployment configuration into the store
     * for use in the initial load and resetting components
     * after changes.
     *
     * This works by looping through all properties in `configuration`
     * and setting them directly into the state via `setProperty`
     */
    initializeConfiguration({ commit, state }, configuration) {

        // Merge the remote deployment configuration with
        // the state defaults
        for (const property in configuration) {
            let value = null;

            // Only merge in objects
            if (isPlainObject(configuration[property])) {
                value = {
                    ...state[property] ?? {},
                    ...configuration[property]
                };
            } else {
                value = configuration[property];
            }

            // Use the open-ended mutation to properly commit to state
            commit('setProperty', {
                property,
                value
            });
        }
    },

    /**
     * Resets the deployment configuration for all steps
     * outside of ads and channels.  This is primarily used when
     * the deployment location changes after the user
     * progresses past the step.
     */
    async resetExtendedState({ dispatch }) {
        await dispatch(
            'initializeConfiguration',
            getExtendedState()
        );
    },

    /**
     * This will loop over all the ads in the ad deployment
     * and toggle the advantage plus flag to false.
     */
    turnOffAdvantagePlus({ state }) {
        const oldAds = [ ...state.ads ];
        let newAds = oldAds.map(ad => {
            ad.configuration.creative_enhancement = false;
            ad.configuration.platform_specific[PLATFORM_FACEBOOK].creative_enhancement = false;
            return ad;
        });
        state.ads = newAds;
    },

    /**
     * Reset the entire ad deployment to the initial state
     * and clear out the ad deployment ID.
     *
     * @param dispatch
    */
    async clear({ dispatch }) {
        await dispatch('initializeConfiguration', getInitialState());
    }
};
