

export const getImgSizeInfo = (img) => {
    var position = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
    const renderedSize = getRenderedSize(true,
                           img.width,
                           img.height,
                           img.naturalWidth,
                           img.naturalHeight,
                           parseInt(position[0] || 0));

        return renderedSize;
        };


const getRenderedSize = (contains, width, height, naturalWidth, naturalHeight, position) => {
    var oRatio = naturalWidth / naturalHeight,
        cRatio = width / height;
    return function() {
      if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
        this.width = width;
        this.height = height / oRatio;
      } else {
        this.width = height * oRatio;
        this.height = width;
      }
      this.left = (width - this.width)*(position/100);
      this.right = this.width + this.left;

      this.top = (height - this.height)*(position/100);
      this.bottom = this.top + this.height;

      return this;
    }.call({});
  };