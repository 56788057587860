/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-align-left': {
    width: 26,
    height: 23,
    viewBox: '0 0 26 23',
    data: '<path pid="0" d="M1.5 2h23m-22 9h12m-12 10h6" _stroke="#8E9DA5" stroke-width="3" _fill="none" stroke-linecap="round"/>'
  }
})
