/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<path pid="0" d="M9 .5a9 9 0 110 18 9 9 0 010-18zm1 4H8v4H4v2h4v4h2v-4h4v-2h-4v-4z" _fill="#00A2EA" fill-rule="evenodd"/>'
  }
})
