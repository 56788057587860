<template>
    <styled-slideout
        v-model="channelConnectionDebugPanelOpen"
        class="open-support-ticket-dialog">
        <loader
            v-if="loadingTokenDetails"
            class="pa-5" />
        <div
            v-else
            class="ma-5">
            <h2 class="primary--text font-weight-regular mb-3">
                Resolve {{ capitalizeFirstLetter(currentChannel) }} Connection Issues
            </h2>
            <div v-if="!isTokenDetailsValid">
                <p class="mb-5">
                    We are currently unable to connect to {{ capitalizeFirstLetter(currentChannel) }}.
                    This can occur if you changed your {{ capitalizeFirstLetter(currentChannel) }} password
                    or removed BuyerBridge's access to your account.
                </p>
                <div
                    class="text-xs-center mt-5">
                    <styled-button
                        @click="goToSettings">
                        VIEW & UPDATE CONNECTION
                    </styled-button>
                </div>
            </div>
            <div v-else>
                <p class="mb-5">
                    We are able to connect to {{ capitalizeFirstLetter(currentChannel) }} but cannot
                    retrieve data for {{ currentDealerName }}.
                    This can happen if the account we have on file is wrong or if your {{ capitalizeFirstLetter(currentChannel) }} user is
                    not properly assigned to the account. Try the following steps to resolve this issue:
                </p>
                <styled-list>
                    <div>
                        <p class="mb-3">
                            <b>
                                Check that the {{ capitalizeFirstLetter(currentChannel) }} account ID in BuyerBridge matches what's in {{ capitalizeFirstLetter(currentChannel) }}
                            </b>
                        </p>
                        <p>
                            The {{ capitalizeFirstLetter(currentChannel) }} account ID in BuyerBridge is {{ channelAdAccountId }}.
                            Please check <a
                                :href="adsManagerLink(currentChannel)"
                                target="_blank">{{ capitalizeFirstLetter(currentChannel) }} Ads Manager</a> to confirm that ID is correct.
                            If it's not <a href="mailto:support@buyerbridge.io">contact support</a>.
                        </p>
                    </div>
                    <div>
                        <p class="mb-3">
                            <b>
                                Confirm that your user is assigned to the {{ capitalizeFirstLetter(currentChannel) }} ad account
                            </b>
                        </p>
                        <p>
                            Check the <a
                                :href="adsManagerLink(currentChannel)"
                                target="_blank">assigned user for ad account {{ channelAdAccountId }}</a> and ensure your {{ capitalizeFirstLetter(currentChannel) }} user is listed.
                            If not assign it.
                        </p>
                    </div>
                </styled-list>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledList from '@/components/globals/StyledList';
import Loader from '@/components/globals/Loader';
import getPinterestTokenDetails from '@/apis/pinterest/getTokenDetails';
import { mapState, mapGetters } from 'vuex';
import { SET_CHANNEL_CONNECTION_DEBUG_PANEL } from '@/store/mutation-types';
import { PLATFORM_PINTEREST, PLATFORM_FACEBOOK, PLATFORM_TIKTOK, PLATFORM_SNAPCHAT } from '@/helpers/globals';

export default {
    components: {
        StyledButton,
        StyledSlideout,
        StyledList,
        Loader,
    },
    data() {
        return {
            loadingTokenDetails: false,
            tokenDetails: null,
            currentDealer: null
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
            dealer: (state) => state.dealer.currentDealer,
            currentChannel: (state) => state.settings.channelForConnection
        }),
        ...mapGetters([
           'dealerPlatforms',
           'dealerPinterestAdAccountUrl',
           'dealerFacebookAdAccountUrl',
           'dealerTiktokAdAccountUrl',
           'dealerSnapchatAdAccountUrl'
        ]),
        currentDealerName() {
            return this.currentDealer?.name ?? '';
        },
        dealerId() {
            if (this.$store.state.settings.dealerForCheckingConnection) {
                return this.$store.state.settings.dealerForCheckingConnection;
            }
            return null;
        },
        isTokenDetailsValid() {
            return (this.tokenDetails?.status !== 'success' || !Object.keys(this.tokenDetails).length) ? false : true;
        },
        channelConnectionDebugPanelOpen: {
            get() {
                return this.$store.state.settings.channelConnectionDebugPanelOpen;
            },
            set(isOpen) {
                if (!isOpen) {
                    this.close();
                }
            }
        },
        channelAdAccountId() {
            let channel = this.dealerPlatforms.find(dp => dp.name === this.currentChannel);
            let adAccountId = '';
            if(this.currentChannel === PLATFORM_FACEBOOK) {
                adAccountId = this.currentDealer?.facebook_ad_account_annotations.data[0].facebook_ad_account_id.replace('act_', '');
            } else {
                let adAccount = this.currentDealer?.ad_accounts.data.find(cd => cd.platform_id === channel?.id);
                if(adAccount) {
                    adAccountId = adAccount.external_ad_account_id;
                }
            }
            return adAccountId;
        },
    },
    watch: {
        channelConnectionDebugPanelOpen: {
            handler(isOpen) {
                if (isOpen) {
                    this.checkTokenStatus();
                }
            },
            immediate: true
        },
        dealerId() {
            this.init();
        },
        dealer() {
            this.init();
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.currentDealer = null;
            if (!this.dealerId) {
                this.currentDealer = this.dealer;
            } else {
                this.getDealerData();
            }
        },
        async getDealerData() {
            try {
                const response = await this.$apiRepository.getFullDealer(this.dealerId, true);
                this.currentDealer = response.data.data;
            } catch(error) {
                console.log(error);
            }
        },
        async checkTokenStatus() {
            try {
                this.loadingTokenDetails = true;
                this.tokenDetails = null;
                if (this.currentChannel === PLATFORM_PINTEREST) {
                    this.tokenDetails = await getPinterestTokenDetails(this.currentAgency.id);
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loadingTokenDetails = false;
            }
        },
        capitalizeFirstLetter(string) {
            return string?.charAt(0).toUpperCase() + string?.slice(1);
        },
        close() {
            this.$store.commit(SET_CHANNEL_CONNECTION_DEBUG_PANEL, { status: false, channel: null, dealer_id: null });
        },
        goToSettings() {
            this.close();
            this.$router.push({ name: 'reseller-settings' });
        },
        adsManagerLink(channel) {
            switch (channel) {
                case PLATFORM_PINTEREST:
                    return this.dealerPinterestAdAccountUrl;
                case PLATFORM_FACEBOOK:
                    return this.dealerFacebookAdAccountUrl;
                case PLATFORM_TIKTOK:
                    return this.dealerTiktokAdAccountUrl;
                case PLATFORM_SNAPCHAT:
                    return this.dealerSnapchatAdAccountUrl;
            }
        }
    }
};
</script>
