/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'italic': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M8 1h9v2H8V1zm3 2h3L8 17H5l6-14zM2 17h9v2H2v-2z"/>'
  }
})
