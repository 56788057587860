/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cog': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14.03 10A4.034 4.034 0 0110 14.03 4.034 4.034 0 015.97 10 4.034 4.034 0 0110 5.97 4.034 4.034 0 0114.03 10m5.432-1.876L17.4 7.59a7.733 7.733 0 00-.546-1.298l1.013-1.72a.716.716 0 00-.11-.87l-1.69-1.688a.716.716 0 00-.87-.111l-1.79 1.055a7.673 7.673 0 00-.998-.405L11.887.537A.716.716 0 0011.194 0H8.806a.716.716 0 00-.694.537l-.52 2.016a7.722 7.722 0 00-1.158.487l-1.79-1.055a.717.717 0 00-.871.11l-1.688 1.69a.716.716 0 00-.11.87L3.05 6.48a7.716 7.716 0 00-.45 1.11l-2.063.532A.716.716 0 000 8.817v2.388c0 .327.22.612.537.693l2.124.55c.097.283.209.56.338.829l-1.184 2.01a.716.716 0 00.11.87l1.69 1.688a.716.716 0 00.87.11l1.938-1.141c.36.188.738.348 1.129.479l.56 2.17a.716.716 0 00.694.537h2.388c.327 0 .612-.22.694-.537l.56-2.17c.334-.112.658-.244.97-.398l1.939 1.142a.715.715 0 00.87-.11l1.688-1.69a.717.717 0 00.111-.87l-1.12-1.902c.167-.327.312-.666.433-1.018l2.124-.549a.716.716 0 00.537-.693V8.817a.717.717 0 00-.538-.693" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
