<template>
    <v-dialog
        v-model="active"
        width="500"
        content-class="branded-loader-dialog"
        full-width
        persistent>
        <v-layout
            align-center
            justify-center
            column
            fill-height>
            <gears-loader />
            <p class="branded-loader-message">
                {{ message }}
                <animated-dots />
            </p>
        </v-layout>
    </v-dialog>
</template>

<script>
import AnimatedDots from './AnimatedDots';
import GearsLoader from './GearsLoader';

export default {
    components: {
        AnimatedDots,
        GearsLoader
    },
    props: {
        active: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            default: 'Initializing BuyerBridge'
        }
    },
    data() {
        return {};
    },
    watch: {
        active(isActive) {
            const addRemove = (isActive) ? 'add' : 'remove';
            document.body.classList[addRemove]('loader-dialog-active');
        }
    },
    created() {
        if (this.active) {
            document.body.classList.add('loader-dialog-active');
        }
    }
};
</script>

<style lang="scss">
.loader-dialog-active {
    .v-overlay--active:before {
        opacity: 0.8;
    }
}
.v-dialog.branded-loader-dialog {
    color: white;
    box-shadow: none;
    overflow: visible;
}
</style>
