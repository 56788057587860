/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buyerbridge': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.23 12.148a1.454 1.454 0 01-.73 1.239l-3.763 2.14v-.001a1.482 1.482 0 01-.737.194c-.267 0-.518-.07-.734-.193l-3.76-2.137a1.454 1.454 0 01-.735-1.222v-1.415c.987-.12 2.07-.224 3.188-.285v.284a.713.713 0 00.358.611l1.339.761a.728.728 0 00.685.002l1.342-.763a.715.715 0 00.358-.61v-.285c1.118.061 2.2.165 3.189.286v1.394zM10 7.79a.73.73 0 01.352.09l1.327.754.077.05a.714.714 0 01.285.562v.216c4.234.232 7.959 1.08 7.959 1.08v1.006s-.628-.143-1.66-.327v1.725c0 .528-.137 1.063-.424 1.554-.287.49-.689.873-1.152 1.137l-5.194 2.952c-.463.262-1 .411-1.571.411-.55 0-1.066-.138-1.516-.38L3.24 15.64l-.171-.105a3.122 3.122 0 01-.986-1.035 3.063 3.063 0 01-.425-1.551V11.22C.627 11.405 0 11.548 0 11.548v-1.006s3.725-.847 7.96-1.08v-.214a.714.714 0 01.362-.614H8.32l1.324-.753A.728.728 0 0110 7.79zM9.999 1c.572 0 1.108.15 1.571.411l5.194 2.952.194.12c.382.256.712.598.958 1.017.287.49.424 1.026.424 1.554v2.344c-.82-.151-1.893-.33-3.11-.484V7.861a1.454 1.454 0 00-.73-1.248l-3.79-2.154a1.483 1.483 0 00-1.423.001l-1.364.775V1.7l.56-.318C8.933 1.138 9.449 1 9.999 1zM4.77 3.49v5.423a54.807 54.807 0 00-3.11.485V7.052l.008-.227A3.124 3.124 0 013.241 4.36l1.529-.87z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
