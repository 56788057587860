/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'billing': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#8F9EA6" fill-rule="nonzero"><path pid="0" d="M9.32 10.62c-.136-.144-.233-.289-.252-.475l-1.047.165c.058.456.271.87.62 1.18.233.186.485.33.776.434V13h1.028v-1.055c.33-.083.659-.248.91-.476l.04-.041c.407-.435.64-1.035.6-1.656a2.122 2.122 0 00-.775-1.53c-.426-.332-.834-.435-1.183-.518-.33-.083-.523-.145-.678-.33-.175-.187-.272-.394-.272-.58 0-.207.117-.352.214-.455.349-.31.93-.29 1.28.02.057.062.116.145.154.207l1.067-.165a1.764 1.764 0 00-.543-.87 2.035 2.035 0 00-.873-.475V4H9.36v1.097a2.11 2.11 0 00-.757.413l-.019.021-.02.02a1.827 1.827 0 00-.562 1.242c-.02.497.175 1.014.563 1.428.387.413.833.538 1.202.62.271.062.523.145.776.331.213.166.33.414.349.683.019.29-.097.58-.291.786-.291.31-.912.31-1.28-.02z"/><path pid="1" d="M0 2v1.257h2.487v13.868L4.34 19l1.897-1.917L8.135 19l1.897-1.917L11.928 19l1.897-1.917L15.722 19l.611-.618 1.243-1.257V3.257H20V2H0zm16.354 14.614l-.611.618-1.897-1.917-1.897 1.917-1.896-1.917-1.897 1.917-1.897-1.917-1.897 1.917-.61-.618V3.257h12.644v13.357h-.042z"/></g>'
  }
})
