/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'full-screen': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M7.748 13.426L3.83 17.34h2.145a.83.83 0 110 1.659H1.83A.853.853 0 011 18.17v-4.146a.83.83 0 011.659 0v2.144l3.915-3.915a.83.83 0 111.173 1.173zm.009-5.663a.845.845 0 01-1.174 0L2.66 3.839v2.148a.83.83 0 11-1.661 0V1.835A.854.854 0 011.83 1h4.153a.83.83 0 010 1.662H3.835l3.922 3.925a.832.832 0 010 1.176zM18.93 18.487a.857.857 0 01-.76.513h-4.146a.83.83 0 010-1.659h2.145l-3.916-3.915a.83.83 0 111.173-1.173l3.915 3.915v-2.144a.83.83 0 011.659 0v4.147a.78.78 0 01-.07.316zM19 5.986a.83.83 0 11-1.661 0V3.838L13.418 7.76a.84.84 0 01-1.175 0 .831.831 0 010-1.175l3.921-3.924h-2.148a.83.83 0 010-1.662h4.153A.857.857 0 0119 1.83v4.155z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
