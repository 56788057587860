/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'page-blank': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#8F9EA6" fill-rule="nonzero"><path pid="0" d="M17 2.5H3A2.5 2.5 0 00.5 5v10A2.5 2.5 0 003 17.5h14a2.5 2.5 0 002.5-2.5V5A2.5 2.5 0 0017 2.5zm-14 1h14A1.5 1.5 0 0118.5 5v10a1.5 1.5 0 01-1.5 1.5H3A1.5 1.5 0 011.5 15V5A1.5 1.5 0 013 3.5z"/><path pid="1" d="M8 11a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1v-2a1 1 0 011-1h4zm0 1H4v2h4v-2zM16 11a1 1 0 011 1v2a1 1 0 01-1 1h-4a1 1 0 01-1-1v-2a1 1 0 011-1h4zm0 1h-4v2h4v-2zM16 5a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V6a1 1 0 011-1h12zm0 1H4v2h12V6z"/></g>'
  }
})
