/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'meta': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" _fill="#8F9EA6" fill-rule="evenodd" d="M14.352 3C17.45 3 20 6.943 20 11.637c0 2.864-1.233 4.658-3.448 4.658-1.91 0-2.973-1.062-4.848-4.186l-.057-.096-.981-1.65a68.334 68.334 0 00-.664-1.098l-.336.593-.065.116-.11.194-.044.077-.043.077-.874 1.544c-2.093 3.658-3.27 4.43-4.906 4.43-2.18 0-3.518-1.628-3.624-4.235v-.75C.146 6.982 2.586 3 5.742 3c1.597 0 2.93.774 4.526 2.805C11.428 4.235 12.711 3 14.352 3zm-.138 1.715c-1.037 0-1.909.813-2.966 2.444.27.402.55.837.842 1.308l.073.118 1.173 1.956c1.835 2.952 2.296 3.59 3.262 3.59 1.008 0 1.495-.862 1.495-2.347 0-3.897-1.724-7.07-3.879-7.07zm-8.5.458c-1.934 0-3.557 3.328-3.557 6.583 0 1.522.578 2.375 1.55 2.375.934 0 1.52-.584 3.177-3.176l.05-.079.001-.001c.03-.048.796-1.263 2.044-3.177-1.341-1.92-2.217-2.525-3.264-2.525z"/>'
  }
})
