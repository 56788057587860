<template>
    <div class="inputs-wrapper">
        <div
            class="back-button"
            @click="handleGoBack">
            <icon
                name="chevron-left"
                color="#00a2ea"
                width="11"
                height="11" />
            back
        </div>
        <div
            v-for="(item, index) in editables"
            :key="index">
            <div class="input-label">
                {{ item.label }}
            </div>
            <input
                v-model="item.textContent"
                class="text-asset-input"
                placeholder="Text content">
        </div>
    </div>
</template>

<script>
    import Icon from '@/components/globals/Icon';
    import { mapActions } from 'vuex';

export default {
    components: {
        Icon,
    },
    props: {
        editableFields: {
                type: Array,
                required: true,
            }
        },
    computed: {
        editables() {
            return this.editableFields;
        }
    },
    methods: {
        ...mapActions([
            'setTextEditorVisible'
        ]),
        handleGoBack() {
            this.$emit('editor-closed');
            this.setTextEditorVisible(false);
        }
    }
};

</script>

<style lang="scss">

.inputs-wrapper {
    margin-top: 30px;
    width: 100%;
    padding-left: 10px;
}

.input-label {
    margin-bottom: 5px;
    margin-top: 20px;
}

.text-asset-input {
    outline: none;
    background-color: #DDE3E6;
    color: #000;
    padding: 4px 10px;
    border-radius: 5px;
    width: 100%;

}

.back-button {
    color: $blue-bg;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: underline;
}
</style>