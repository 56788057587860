import { BaseAsset } from './BaseAsset';
export class TextAsset extends BaseAsset {
    text;
    fontFamily;
    fontSize;
    weight;
    fontStyle;
    fillColor;
    content;
    width;
    height;
    viewBox;
    editableFields;
    contentType;
    textAlign;


    hasSimpleContentType() {
        return this.contentType === 'simple_text';
    }
}