/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alignment-selected': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<path pid="0" d="M2 2.405h16M5.429 10.5h8.808M2 18.595h16" _stroke="#00a2ea" stroke-width="3" _fill="none" stroke-linecap="round"/>'
  }
})
