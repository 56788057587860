/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'details': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.232 14c.424 0 .768.448.768 1s-.344 1-.768 1H5.768C5.344 16 5 15.552 5 15s.344-1 .768-1zm-15.95 0c.397 0 .718.448.718 1s-.321 1-.717 1H.717C.321 16 0 15.552 0 15s.321-1 .717-1zm0-5C3.68 9 4 9.448 4 10s-.321 1-.717 1H.717C.321 11 0 10.552 0 10s.321-1 .717-1zm15.95 0c.424 0 .768.448.768 1s-.344 1-.768 1H5.768C5.344 11 5 10.552 5 10s.344-1 .768-1zm0-5c.424 0 .768.448.768 1s-.344 1-.768 1H5.768C5.344 6 5 5.552 5 5s.344-1 .768-1zM3.282 4C3.68 4 4 4.448 4 5s-.321 1-.717 1H.717C.321 6 0 5.552 0 5s.321-1 .717-1z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
