import axios from 'axios';
import { CREATIVE_STUDIO_API_URL } from '@/creative-studio/domain/common/constants';

export class HttpFactory {

    static createClient() {
        const client = axios.create();
        client.defaults.baseURL = CREATIVE_STUDIO_API_URL;
        client.defaults.headers.common['Content-Type'] = 'application/json';
        client.defaults.headers.common['Accept'] = 'application/json';

        return client;
    }
}