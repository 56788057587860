<template>
    <component
        :is="layeredDesigner"
        v-if="isOpen"
        v-model="isOpen" />
</template>
<script>
import LayeredDesignerDialog from './LayeredDesignerDialog';
export default {
    components: {
        LayeredDesignerDialog
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            layeredDesigner: null
        };
    },
    watch: {
        async isOpen(newActiveStatus) {
            if (newActiveStatus) {
                await this.handleOpenSetLayeredDesigner();
            }
        }
    },
    methods: {
       async handleOpenSetLayeredDesigner() {
            const component = await import('@/components/creative-studio/layered-designer/LayeredDesignerDialog');
            this.layeredDesigner = component.default;
        }
    }
};
</script>