/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'handshake': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" d="M5.214 3.436L8.671.722A3.355 3.355 0 0111.98.233l5.511 2.17c.36.14.74.21 1.123.209h.907a.47.47 0 01.343.147c.09.095.14.223.137.356v6.953a.485.485 0 01-.478.492h-1.198a.737.737 0 00-.472.174l-.273.23a.16.16 0 01-.229-.026l-6.422-8.05a.515.515 0 00-.664-.13L7.321 4.459a2.11 2.11 0 01-1.925.099.69.69 0 01-.182-1.121zm-4.719 7.37h1.07l.747-.897a1.93 1.93 0 011.87-.642 1.898 1.898 0 011.444 1.33 1.89 1.89 0 011.328 1.294c.469.135.868.44 1.114.855.405.062.78.25 1.068.536.612.63.724 1.583.274 2.334l-.1.175a.012.012 0 000 .016c.365.25.847.258 1.22.02.372-.24.56-.676.475-1.105a.022.022 0 01.01-.03.023.023 0 01.03.008l.082.078c.379.372.973.43 1.42.14.354-.244.53-.67.447-1.087a.022.022 0 01.009-.03.023.023 0 01.03.007l.082.078c.379.372.971.43 1.418.14.36-.242.538-.67.455-1.09a.022.022 0 01.01-.03.023.023 0 01.03.007l.092.086c.358.349.91.419 1.346.17a1.064 1.064 0 00.253-1.644l-.669-.792-5.406-6.465a.18.18 0 00-.226-.042L7.834 5.648a3.292 3.292 0 01-2.718.206 1.723 1.723 0 01-1.058-1.028 1.893 1.893 0 01.18-1.469.239.239 0 00-.006-.24A.248.248 0 004.018 3H.495a.498.498 0 00-.35.141.48.48 0 00-.145.344v6.841a.49.49 0 00.495.48zm3.464-.678c-.375-.242-.846-.133-1.11.256l-.66.935a1.07 1.07 0 00-.184.716c.025.257.138.492.315.652l.1.09a.747.747 0 00.935.077.036.036 0 01.046 0 .05.05 0 01.015.05c-.061.357.057.723.305.948l.081.074a.752.752 0 00.905.101.039.039 0 01.044.004c.013.01.02.028.018.046-.033.337.087.669.318.878l.057.05a.759.759 0 00.914.099.042.042 0 01.046-.003.055.055 0 01.026.045c.034.242.146.46.314.611l.036.033a.773.773 0 00.693.192.873.873 0 00.579-.479l.123-.25c.211-.408.15-.929-.148-1.257a.767.767 0 00-.925-.166.022.022 0 01-.026-.002.03.03 0 01-.01-.027 1.032 1.032 0 00-.346-.836.752.752 0 00-.796-.122c-.126.026-.1-.101-.1-.101.137-.38.05-.82-.217-1.093a.756.756 0 00-.968-.124c-.093.015-.082-.086-.082-.086.23-.457.097-1.043-.298-1.311z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
