import HttpClient from './http';

class ApiQuery {
    constructor(objectType, objectId) {
        this.objectType = objectType;
        this.objectId = objectId || null;

        this.fields = [];
        this.workflowTransitionHistories = [];
        this.relationships = [];
        this.relationshipCounts = [];
        this.scopes = {};
        this.pageNumber = null;
        this.pageSize = null;
        this.pageLimit = null;
        this.sortBy = '';
        this.sortDirection = 'asc';
        this.searchQuery = '';
        this.httpConfig = {};
        this.requireRelationships = null;
        this.with_archived = null;
        this.only_archived = null;
        this.filters = [];
    }

    // Convert JS arguments object to array
    static getArgumentsAsArray(args) {
        return Array.prototype.slice.call(args);
    }

    withFields(fields) {
        if ((typeof fields) === 'string') {
            fields = this.constructor.getArgumentsAsArray(arguments);
        }

        this.fields = this.fields.concat(fields);

        return this;
    }

    withFilters(filters) {
        this.filters = filters;

        return this;
    }

    withWorkflowTransitionHistories(workflowTransitionHistories) {
        if ((typeof workflowTransitionHistories) === 'string') {
            workflowTransitionHistories = this.constructor.getArgumentsAsArray(arguments);
        }

        this.workflowTransitionHistories = this.workflowTransitionHistories.concat(workflowTransitionHistories);

        return this;
    }

    withRelationships(relationships) {
        if ((typeof relationships) === 'string') {
            relationships = this.constructor.getArgumentsAsArray(arguments);
        }

        this.relationships = this.relationships.concat(relationships);

        return this;
    }

    withRelationshipCounts(relationshipCounts) {
        if ((typeof relationshipCounts) === 'string') {
            relationshipCounts = this.constructor.getArgumentsAsArray(arguments);
        }

        this.relationshipCounts = this.relationshipCounts.concat(relationshipCounts);

        return this;
    }

    withScope(name, params) {
        this.scopes[name] = (params? params : {});

        return this;
    }

    withScopes(scopes) {
        Object.assign(this.scopes, scopes);

        return this;
    }

    setPageNumber(pageNumber) {
        this.pageNumber = pageNumber;

        return this;
    }

    setPageSize(pageSize) {
        this.pageSize = pageSize;

        return this;
    }

    setPageLimit(pageLimit) {
        this.pageLimit = pageLimit;

        return this;
    }

    setSortField(field) {
        this.sortBy = field;

        return this;
    }

    setSortDirection(direction) {
        this.sortDirection = (
            (direction === 'desc')
                ? 'desc'
                : 'asc'
        );

        return this;
    }

    setPaginationOptionsFromVuetifyDataTable(pagination) {
        return (
            this.setPageSize(pagination.rowsPerPage || pagination.totalItems)
                .setPageNumber(pagination.page)
                .setSortField(pagination.sortBy ? pagination.sortBy : '')
                .setSortDirection(pagination.descending? 'desc' : 'asc')
        );
    }

    setSearchQuery(query) {
        this.searchQuery = query;

        return this;
    }

    setRelationshipsRequired() {
        this.requireRelationships = true;

        return this;
    }

    setArchivedOptions(archivedOptions) {
        if (archivedOptions.includes('Inactive')) {
            if (archivedOptions.includes('Active')) {
                this.with_archived = 1;
                this.only_archived = null;
            } else {
                this.with_archived = null;
                this.only_archived = 1;
            }
        }

        return this;
    }

    get() {
        let params = {};

        if (this.fields.length) {
            params.with_fields = this.fields.join(',');
        }

        if (this.workflowTransitionHistories.length) {
            params.with_workflow_transition_histories = this.workflowTransitionHistories.join(',');
        }

        if (this.relationships.length) {
            params.with_relationships = this.relationships.join(',');
        }

        if (this.relationshipCounts.length) {
            params.with_relationship_counts = this.relationshipCounts.join(',');
        }

        if (Object.keys(this.scopes).length) {
            params.with_scopes = JSON.stringify(this.scopes);
        }

        if (this.pageNumber !== null) {
            params.page = this.pageNumber;
        }

        if (this.pageSize !== null) {
            params.page_size = this.pageSize;
        }

        if (this.pageLimit !== null) {
            params.limit= this.pageLimit;
        }

        if (this.sortBy.trim() !== '') {
            params.sort_by = this.sortBy;
            params.sort_direction = this.sortDirection;
        }

        if (this.searchQuery.trim() !== '') {
            params.search = this.searchQuery; //
        }

        if (this.filters.length) {
            params.with_filters = JSON.stringify(this.filters);
        }

        if (this.requireRelationships !== null) {
            params.relations_not_null = 1;
        }

        if (this.with_archived !== null) {
            params.with_archived = 1;
        }

        if (this.only_archived !== null) {
            params.only_archived = 1;
        }

        let url = (
            (this.objectId === null)
                ? this.objectType
                : this.objectType+'/'+this.objectId
        );

        return (new HttpClient(this.httpConfig)).get(url, params);
    }

    delete() {
        let url = (
            (this.objectId === null)
                ? this.objectType
                : this.objectType+'/'+this.objectId
        );

        return (new HttpClient()).delete(url);
    }
}

export default ApiQuery;