<template>
    <div class="image-container">
        <img
            :src="imageUrl"
            class="image"
            @error="imageUrl">
    </div>
</template>
<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
      default: 'https://via.placeholder.com/200',
    },
  },
};
</script>
<style lang="scss" scoped>
.image-container {
  border: solid $border-list 1px;
  width: 120px;
  height: 120px;
  max-width: 120px;
  max-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>