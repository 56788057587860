import {
    FACEBOOK_BB_PLATFORM_ID,
    FACEBOOK_DESKTOP_FEED,
    FACEBOOK_MOBILE_FEED,
    FACEBOOK_REEL,
    FACEBOOK_STORY,
    INSTAGRAM_BB_PLATFORM_ID,
    INSTAGRAM_DESKTOP_FEED,
    INSTAGRAM_MOBILE_FEED,
    INSTAGRAM_REEL,
    INSTAGRAM_STORY,
    PINTEREST_BB_PLATFORM_ID,
    PINTEREST_FEED,
    SNAPCHAT_BB_PLATFORM_ID, SNAPCHAT_FEED,
    TIKTOK_BB_PLATFORM_ID,
    TIKTOK_FEED,
    PLATFORM_FACEBOOK,
    PLATFORM_SNAPCHAT,
    PLATFORM_PINTEREST,
    PLATFORM_TIKTOK,
    ALL_PLACEMENTS
} from '@/helpers/globals';

import { removeEmojiFromString } from '@/helpers/removeEmojiFromString';
import { requiredRules, urlRules, httpsRules } from '@/helpers/validationRules';

export const primaryText = 'primaryText';
export const description = 'description';
export const headline = 'headline';
export const destinationUrl = 'destinationUrl';
export const urlParameters = 'urlParameters';
export const callToAction = 'callToAction';

export const headlineKey = 'headline';
export const descriptionKey = 'description';
export const primaryTextKey = 'primary_text';
export const ctaLinkKey = 'cta_link';
export const ctaTextKey = 'cta_text';
export const urlParamsKey = 'url_params';

export const IMAGE_ASSET_TYPE = 'image';
export const VIDEO_ASSET_TYPE = 'video';
export const SHAPE_ASSET_TYPE = 'shape';
export const TEXT_TEMPLATE_ASSET_TYPE = 'text_template';
export const DESIGN_ASSET_TYPE = 'design';
export const ORIGINAL_ASSET_TYPE = 'original';

export const LAYOUT_CAROUSEL = 'carousel';
export const LAYOUT_SINGLE = 'single';
export const LAYOUT_COLLECTION = 'collection';
export const CREATIVE_VIDEO = 'VIDEO';
export const CREATIVE_IMAGE = 'IMAGE';
export const CREATIVE_COLLECTION = 'COLLECTION';

export const FACEBOOK = 'facebook';
export const META = 'meta';
export const PINTEREST = 'pinterest';
export const SNAPCHAT = 'snapchat';
export const TIKTOK = 'tiktok';

export const defaultCtas = ['Shop Now', 'Learn More'];

export const fields = [
    {
        ui_name: 'Primary Headline',
        key: headlineKey,
        component: 'textField',
        rules: [...requiredRules],
        platforms: [FACEBOOK, SNAPCHAT, TIKTOK, PINTEREST],
        hasMaxLength: true,
        hasTooltip: true,
    },
    {
        ui_name: 'Secondary Headline',
        key: descriptionKey,
        component: 'textField',
        rules: [...requiredRules],
        platforms: [FACEBOOK],
        hasMaxLength: true,
        hasTooltip: true,
    },
    {
        ui_name: 'Body Text',
        key: primaryTextKey,
        component: 'textArea',
        rules: [...requiredRules],
        platforms: [FACEBOOK, PINTEREST],
        hasMaxLength: true,
        hasTooltip: true,
        tooltipAdditions: {
            [PINTEREST]: 'This is used by Pinterest to populate description field on the Pins detail page.'
        }
    },
    {
        ui_name: 'Destination URL',
        key: ctaLinkKey,
        component: 'textField',
        platforms: [FACEBOOK, SNAPCHAT, TIKTOK, PINTEREST],
        rules: [...urlRules, ...httpsRules, ...requiredRules]
    },
    {
        ui_name: 'Call To Action',
        key: ctaTextKey,
        component: 'select',
        label: 'Call To Action',
        rules: [...requiredRules],
        platforms: [FACEBOOK, SNAPCHAT, TIKTOK],
        items: defaultCtas,
        specificItems: {
            [FACEBOOK]: defaultCtas,
            [SNAPCHAT]: [
                'Shop Now',
                'More',
                'Apply Now',
                'Get Now',
                'Order Now',
                'Sign Up',
                'View',
            ],
            [TIKTOK]: defaultCtas,
        },
    },
    {
        ui_name: 'URL Parameters',
        key: urlParamsKey,
        component: 'utmContent',
        disableTooltip: true,
        platforms: [FACEBOOK, SNAPCHAT, TIKTOK, PINTEREST],
    },
];

export const channelPerField = (field) => {
    return fields.find((f) => f.key === field).platforms;
};

// Changing the order here will affect which Icon is shown first on IconList component
export const supportedPlatforms = [
    {
        name: FACEBOOK,
        icon: META,
        iconSize: '22'
    },
    {
        name: SNAPCHAT,
        icon: SNAPCHAT,
        iconSize: '20',
        primaryText: {
            callback: (value) => removeEmojiFromString(value),
        }
    },
    {
        name: TIKTOK,
        icon: TIKTOK,
        iconSize: '20'
    },
    {
        name: PINTEREST,
        icon: PINTEREST,
        iconSize: '20'
    },
];

export const PREVIEW_TYPES = [
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_IMAGE, CREATIVE_VIDEO],
        layout: LAYOUT_CAROUSEL,
        name: 'Facebook Carousel Desktop',
        component: () => import('@/components/globals/AdPreview/facebook/FacebookCarouselDesktop.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_IMAGE, CREATIVE_VIDEO],
        layout: LAYOUT_CAROUSEL,
        name: 'Facebook Carousel Mobile',
        component: () => import('@/components/globals/AdPreview/facebook/FacebookCarouselMobile.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_IMAGE, CREATIVE_VIDEO],
        layout: LAYOUT_SINGLE,
        name: FACEBOOK_DESKTOP_FEED,
        component: () => import('@/components/globals/AdPreview/facebook/FacebookFeedDesktop.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_IMAGE, CREATIVE_VIDEO],
        layout: LAYOUT_SINGLE,
        name: FACEBOOK_MOBILE_FEED,
        component: () => import('@/components/globals/AdPreview/facebook/FacebookFeedMobile.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_VIDEO],
        layout: LAYOUT_SINGLE,
        name: FACEBOOK_STORY,
        component: () => import('@/components/globals/AdPreview/facebook/FacebookStory.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_VIDEO],
        layout: LAYOUT_SINGLE,
        name: FACEBOOK_REEL,
        component: () => import('@/components/globals/AdPreview/facebook/FacebookReel.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_VIDEO, CREATIVE_IMAGE],
        layout: LAYOUT_SINGLE,
        name: INSTAGRAM_DESKTOP_FEED,
        component: () => import('@/components/globals/AdPreview/instagram/InstagramFeedDesktop.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_VIDEO, CREATIVE_IMAGE],
        layout: LAYOUT_SINGLE,
        name: INSTAGRAM_MOBILE_FEED,
        component: () => import('@/components/globals/AdPreview/instagram/InstagramFeedMobile.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_VIDEO, CREATIVE_IMAGE],
        layout: LAYOUT_CAROUSEL,
        name: 'Instagram Carousel Desktop',
        component: () => import('@/components/globals/AdPreview/instagram/InstagramCarouselDesktop.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_VIDEO, CREATIVE_IMAGE],
        layout: LAYOUT_CAROUSEL,
        name: 'Instagram Carousel Mobile',
        component: () => import('@/components/globals/AdPreview/instagram/InstagramCarouselMobile.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_VIDEO],
        layout: LAYOUT_SINGLE,
        name: INSTAGRAM_STORY,
        component: () => import('@/components/globals/AdPreview/instagram/InstagramStory.vue')
    },
    {
        platform_ids: [FACEBOOK_BB_PLATFORM_ID, INSTAGRAM_BB_PLATFORM_ID],
        asset_types: [CREATIVE_VIDEO],
        layout: LAYOUT_SINGLE,
        name: INSTAGRAM_REEL,
        component: () => import('@/components/globals/AdPreview/instagram/InstagramReel.vue')
    },
    {
        platform_ids: [TIKTOK_BB_PLATFORM_ID],
        asset_types: [CREATIVE_IMAGE, CREATIVE_VIDEO, CREATIVE_COLLECTION],
        layout: LAYOUT_SINGLE,
        name: TIKTOK_FEED,
        component: () => import('@/components/globals/AdPreview/tiktok/TikTokPreview.vue')
    },
    {
        platform_ids: [PINTEREST_BB_PLATFORM_ID],
        asset_types: [CREATIVE_IMAGE, CREATIVE_VIDEO, CREATIVE_COLLECTION],
        layout: LAYOUT_SINGLE,
        name: PINTEREST_FEED,
        component: () => import('@/components/globals/AdPreview/pinterest/PinterestPreview.vue')
    },
    {
        platform_ids: [SNAPCHAT_BB_PLATFORM_ID],
        asset_types: [CREATIVE_IMAGE, CREATIVE_VIDEO, CREATIVE_COLLECTION],
        layout: LAYOUT_SINGLE,
        name: SNAPCHAT_FEED,
        component: () => import('@/components/globals/AdPreview/snapchat/SnapchatSinglePreview.vue')
    },
    {
        platform_ids: [SNAPCHAT_BB_PLATFORM_ID],
        asset_types: [CREATIVE_IMAGE, CREATIVE_VIDEO, CREATIVE_COLLECTION],
        layout: LAYOUT_COLLECTION,
        name: 'Collection',
        component: () => import('@/components/globals/AdPreview/snapchat/SnapchatCollectionPreview.vue')
    }
];

export const PLATFORM_ASSET_CONFIGURATIONS = [
    {
        aspect_ratio: '999:999',
        asset_type: ORIGINAL_ASSET_TYPE,
        platforms: [PLATFORM_FACEBOOK, PLATFORM_PINTEREST],
        height: null,
        width: null,
        loading: false,
        placements: [
            {
                platform: PLATFORM_FACEBOOK,
                placements: ['All Placements *']
            },
            {
                platform: PLATFORM_PINTEREST,
                placements: ['All Placements']
            }
        ]
    },
    {
        aspect_ratio: '9:16',
        asset_type: IMAGE_ASSET_TYPE,
        platforms: [PLATFORM_FACEBOOK, PLATFORM_SNAPCHAT],
        height: 1920,
        width: 1080,
        loading: false,
        placements: [
            {
                platform: PLATFORM_FACEBOOK,
                placements: [FACEBOOK_STORY, INSTAGRAM_STORY],
            },
            {
                platform: PLATFORM_SNAPCHAT,
                placements: [ALL_PLACEMENTS],
            }
        ]
    },
    {
        aspect_ratio: '2:3',
        asset_type: IMAGE_ASSET_TYPE,
        platforms: [PLATFORM_PINTEREST],
        height: 1500,
        width: 1000,
        loading: false,
        placements: [
            {
                platform: PLATFORM_PINTEREST,
                placements: [ALL_PLACEMENTS],
            }
        ]
    },
    {
        aspect_ratio: '1:1',
        asset_type: IMAGE_ASSET_TYPE,
        platforms: [PLATFORM_FACEBOOK],
        height: 1080,
        width: 1080,
        loading: false,
        placements: [
            {
                platform: PLATFORM_FACEBOOK,
                placements: ['Facebook Feed', 'Instagram Feed'],
            }
        ]
    },
    {
        aspect_ratio: '9:16',
        asset_type: VIDEO_ASSET_TYPE,
        platforms: [PLATFORM_FACEBOOK, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK],
        height: 1920,
        width: 1080,
        loading: false,
        placements: [
            {
                platform: PLATFORM_FACEBOOK,
                placements: [FACEBOOK_STORY, FACEBOOK_REEL, INSTAGRAM_STORY, INSTAGRAM_REEL],
            },
            {
                platform: PLATFORM_SNAPCHAT,
                placements: [ALL_PLACEMENTS],
            },
            {
                platform: PLATFORM_TIKTOK,
                placements: [ALL_PLACEMENTS],
            }
        ]
    },
    {
        aspect_ratio: '2:3',
        asset_type: VIDEO_ASSET_TYPE,
        platforms: [PLATFORM_PINTEREST],
        height: 1500,
        width: 1000,
        loading: false,
        placements: [
            {
                platform: PLATFORM_PINTEREST,
                placements: [ALL_PLACEMENTS],
            }
        ]
    },
    {
        aspect_ratio: '4:5',
        asset_type: VIDEO_ASSET_TYPE,
        platforms: [PLATFORM_FACEBOOK],
        height: 1350,
        width: 1080,
        loading: false,
        placements: [
            {
                platform: PLATFORM_FACEBOOK,
                placements: ['Facebook Feed', 'Instagram Feed'],
            },
        ]
    }
];

export const FIELDS_NAMING_CONFIGURATION = {
    headline: {
        platforms: {
            [PLATFORM_FACEBOOK]: 'Headline',
            [PLATFORM_SNAPCHAT]: 'Headline',
            [PLATFORM_TIKTOK]: 'Text',
            [PLATFORM_PINTEREST]: 'Title',
        }
    },
    description: {
        platforms: {
            [PLATFORM_FACEBOOK]: 'Description',
        }
    },
    primary_text: {
        platforms: {
            [PLATFORM_FACEBOOK]: 'Primary Text',
            [PLATFORM_PINTEREST]: 'Description',
        }
    },
    cta_link: {
        platforms: {
            [PLATFORM_FACEBOOK]: 'Destination URL',
            [PLATFORM_SNAPCHAT]: 'Destination URL',
            [PLATFORM_TIKTOK]: 'Destination URL',
            [PLATFORM_PINTEREST]: 'Destination URL',
        }
    },
    cta_text: {
        platforms: {
            [PLATFORM_FACEBOOK]: 'Call To Action',
            [PLATFORM_SNAPCHAT]: 'Call To Action',
            [PLATFORM_TIKTOK]: 'Call To Action',
            [PLATFORM_PINTEREST]: 'Call To Action',
        }
    }
};

// Clear out any assets that were used in previous ad studio sessions
export const resetAssetConfigurations = () => {
    PLATFORM_ASSET_CONFIGURATIONS.forEach((config) => {
        config.loading = false;
    });
};

export const getPlatformAssetConfigsForAnAd = (ad, platform) => {
    const aspectRatios = ad?.configuration?.platform_specific?.[platform]?.creatives?.[0]?.assets.map(asset => asset.aspect_ratio);
    const assetType = ad?.configuration?.platform_specific?.[platform]?.creatives?.[0]?.assets[0]?.asset_type?.name;
    return PLATFORM_ASSET_CONFIGURATIONS.filter(assetConfiguration => {
        return assetConfiguration.platforms.includes(platform)
            && aspectRatios.includes(assetConfiguration.aspect_ratio)
            && assetConfiguration.asset_type === assetType;
    });
};

export const getAssetFromAdForAssetConfig = (ad, platform, assetConfig) => {
    return ad?.configuration?.platform_specific?.[platform]?.creatives?.[0]?.assets.find(asset => {
        return (asset?.aspect_ratio === assetConfig?.aspect_ratio
                && asset?.asset_type?.name === assetConfig?.asset_type)
            || assetConfig?.asset_type === ORIGINAL_ASSET_TYPE;
    });
};

export const findExistingAssetForAssetConfig = (ad, assetConfig) => {
    // Get all the assets for each of the platforms relevant to the asset config
    let allAssets = assetConfig?.platforms.map(platform => {
        return ad?.configuration?.platform_specific?.[platform]?.creatives?.[0]?.assets;
    });

    // Flatten the array of arrays
    allAssets = [].concat.apply([], allAssets);

    // Filter out duplicate assets where the aspect ratio is the same and the asset type is the same
    let uniqueAssets = allAssets.filter((asset, index, self) => {
        return self.findIndex(t => t.aspect_ratio === asset.aspect_ratio && t.asset_type.name === asset.asset_type.name) === index;
    });

    // Find the asset that matches the asset config
    return uniqueAssets.find(asset => asset.aspect_ratio === assetConfig.aspect_ratio);
};

export const getAllAssetsForAd = (ad) => {
    // Get all the assets for each of the platforms
    let allAssets = ad?.configuration?.selected_platforms.map(platform => {
        return ad?.configuration?.platform_specific?.[platform]?.creatives?.[0]?.assets;
    });

    // Flatten the array of arrays
    allAssets = [].concat.apply([], allAssets);

    // Filter out duplicate assets where the aspect ratio is the same and the asset type is the same
    return allAssets.filter((asset, index, self) => {
        return self.findIndex(t => t.aspect_ratio === asset.aspect_ratio && t.asset_type.name === asset.asset_type?.name) === index;
    });
};

export const getAllAssetsForAPlatform = (ad, platform) => {
    return ad?.configuration?.platform_specific?.[platform]?.creatives?.[0]?.assets;
};

// media type styles
export const ASSET_STYLE_SQUARE = 'Asset Style Square';
export const ASSET_STYLE_LANDSCAPE = 'Asset Style Landscape';
export const ASSET_STYLE_PORTRAIT = 'Asset Style Portrait';
export const ASSET_STYLE_2_BY_3 = 'Asset Style 2 by 3';
export const ASSET_STYLE_IMAGE = 'Asset Style Image';
export const ASSET_STYLE_VIDEO = 'Asset Style Video';
