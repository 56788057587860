<template>
    <div class="pixel-link-group">
        <p class="section-title">
            Pixels
        </p>
        <div
            v-for="annotation in currentDealer.facebook_pixel_annotations.data"
            :key="annotation.id">
            <pixel-link
                :refresh="refresh"
                :dealer="currentDealer"
                :annotation="annotation" />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import PixelLink from '@/components/dealers/DealerID/QuickLinks/PixelLink';
    export default {
        components: {
            PixelLink
        },
        data() {
            return {
                refresh: 0
            };
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer,
            })
        }
    };
</script>

<style lang="scss" scoped>
.section-title {
    margin-top: 30px;
    font-size: 16px;
    background: #f3f5f6;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
</style>