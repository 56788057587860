/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indicator-warning': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M9 0a9 9 0 110 18A9 9 0 019 0zm.215 13.167a.917.917 0 100 1.833.917.917 0 000-1.833zM9.902 4H8.527a1.024 1.024 0 00-1.02 1.146l.69 6.187a1.024 1.024 0 002.035 0l.69-6.187A1.024 1.024 0 009.902 4z" _fill="#F8E71C" fill-rule="evenodd"/>'
  }
})
