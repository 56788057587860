/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'megaphone': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" _fill="#444" fill-rule="evenodd" d="M11.562 2.193l.12.013a.82.82 0 01.57.411l.045.095 3.513 9.055a.795.795 0 01-.116.778.828.828 0 01-.614.316l-.12-.003-5.298-.52-1.058.395 2.571 3.086a.798.798 0 01-.241 1.22l-.102.046-2.306.862a.84.84 0 01-.844-.155l-.086-.088-2.918-3.502-.215.08c-.377.14-.77.212-1.17.212-1.357 0-2.593-.836-3.076-2.08A3.154 3.154 0 01.29 9.942a3.235 3.235 0 011.629-1.614l.202-.082L7.32 6.302l3.602-3.849a.825.825 0 01.52-.257l.12-.003zm4.742 7.914a.83.83 0 011.087-.406l2.123.934c.415.182.6.66.414 1.066a.824.824 0 01-1.087.407l-2.123-.934a.802.802 0 01-.414-1.067zM5.413 9.615l-.107.032-1.538.575a.803.803 0 00-.476 1.042.824.824 0 00.965.497l.097-.03 1.537-.575a.802.802 0 00.476-1.042.825.825 0 00-.954-.5zm13.253-4.1a.802.802 0 01-.375.996l-.101.046-2.306.862a.822.822 0 01-1.062-.468.801.801 0 01.375-.995l.102-.046 2.305-.863c.423-.157.9.05 1.062.467zM15.415.07c.38.168.567.583.453.965l-.04.103-.95 2.084a.824.824 0 01-1.088.407.805.805 0 01-.453-.965l.038-.103.952-2.084C14.514.07 15-.11 15.415.07z"/>'
  }
})
