/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud': {
    width: 20,
    height: 15,
    viewBox: '0 0 20 15',
    data: '<path pid="0" _fill="#FFF" d="M10 0c1.556 0 3.115.603 4.3 1.806 1.197 1.215 1.756 2.815 1.746 4.412 2.21.205 3.954 2.074 3.954 4.37C20 13.021 18.05 15 15.652 15H5c-2.754 0-5-2.28-5-5.074A5.042 5.042 0 014.049 4.97c.227-1.158.762-2.269 1.644-3.164A6.035 6.035 0 0110 0zm0 5.294a.58.58 0 00-.442.172l-2.39 2.206a.675.675 0 00-.042.938c.23.255.671.274.924.041l1.298-1.2v4.24c0 .366.292.662.652.662.36 0 .652-.296.652-.662v-4.24l1.298 1.2c.253.233.68.201.924-.041.257-.256.21-.703-.041-.938l-2.391-2.206A.546.546 0 0010 5.294z"/>'
  }
})
