import ApiQuery from '@/api-query';
import ApiRepository from '@/api-repository';
import InsightsApiRepository from '@/insights-api-repository';
import Http from '@/http';
import axios from 'axios';

const ApiClientPlugin = {
    install(Vue, options) {
        Vue.prototype.$apiQuery = new ApiQuery();
        Vue.prototype.$insightsApiRepository = new InsightsApiRepository();
        Vue.prototype.$apiRepository = new ApiRepository();
        Vue.prototype.$http = new Http();

        // Allow components to use the public API directly
        const publicApi = axios.create();
        publicApi.defaults.baseURL = process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI;
        Vue.prototype.$httpPublic = publicApi;
    }
};

export default ApiClientPlugin;
