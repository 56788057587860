const VueFbSDK = {
    // The only place this could be useful is in AdAccountDependencies
    // for the payment methods.
    // src/components/onboarding/workflow/steps/AdAccountDependencies.vue
    install(Vue, options) {

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return}
            js = d.createElement(s);
            js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        window.fbAsyncInit = function onSDKInit() {
            FB.init(options);
            FB.AppEvents.logPageView();
            Vue.prototype.$fb = FB;
            window.dispatchEvent(new Event('fb-sdk-ready'));
        };
    }
  };

  export default VueFbSDK;