/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comments': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" d="M25.375 0c.963 0 1.813.396 2.549 1.076.68.736 1.076 1.586 1.076 2.549v16.313c0 1.02-.396 1.869-1.076 2.548-.736.737-1.586 1.076-2.549 1.076h-8.156l-7.08 5.325c-.114.113-.284.113-.453.113a.643.643 0 01-.454-.17c-.113-.113-.17-.283-.17-.51v-4.758H3.626c-1.02 0-1.87-.34-2.549-1.076C.34 21.806 0 20.957 0 19.937V3.625c0-.963.34-1.813 1.076-2.549C1.756.396 2.606 0 3.625 0h21.75zm1.813 19.938V3.625c0-.453-.227-.906-.567-1.246-.34-.34-.793-.567-1.246-.567H3.625c-.51 0-.963.227-1.303.567-.34.34-.51.793-.51 1.246v16.313c0 .51.17.962.51 1.302.34.34.793.51 1.303.51h7.25v4.305l5.72-4.305h8.78c.453 0 .906-.17 1.246-.51.34-.34.567-.793.567-1.302z" _fill="#606770" fill-rule="nonzero"/>'
  }
})
