<template>
    <styled-slideout
        id="open-ticket"
        v-model="newTicketDialogOpen"
        class="open-support-ticket-dialog">
        <div class="ma-5">
            <h2 class="primary--text font-weight-regular">
                Create Support Ticket
            </h2>
            <p class="mb-5">
                Use the form below to open a support ticket with our team. To ensure a swift response please be as descriptive as possible. Where relevant, screenshots and pictures can explain more than any written explanation!
            </p>

            <template v-if="showFields">
                <v-combobox
                    v-if="associateDealers"
                    v-model="selectedDealers"
                    :items="dealers"
                    item-text="name"
                    item-value="id"
                    :loading="loadingDealers"
                    label="Account(s)"
                    chips
                    multiple
                    class="styled-field mb-4"
                    @keyup="search">
                    <template #selection="{ attrs, item, parent, selected }">
                        <v-chip
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            :selected="selected"
                            small>
                            <span class="pr-2">
                                {{ item.name }}
                            </span>
                            <v-icon
                                small
                                @click="parent.selectItem(item)">
                                close
                            </v-icon>
                        </v-chip>
                    </template>
                </v-combobox>
            </template>

            <hub-spot-form
                ref="hsForm"
                :form-id="hubSpotFormId"
                :field-values="fieldValues"
                :form-submit-callback="handlePreSubmit"
                @load="showFields = true"
                @complete="handleFormSubmitted" />

            <div
                v-if="formSubmitted"
                class="text-xs-center mt-5">
                <styled-button
                    @click="handleClickSubmitAnotherTicket">
                    Submit Another Ticket
                </styled-button>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import debounce from 'debounce';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
    TOGGLE_NEW_TICKET_DIALOG,
    SET_TICKET_TYPE
} from '@/store/mutation-types';
import HubSpotForm from './HubSpotForm.vue';

export default {
    components: {
        StyledButton,
        StyledSlideout,
        HubSpotForm,
    },
    data() {
        return {
            associateDealers: true,
            loading: false,
            dealers: [],
            loadingDealers: false,
            selectedDealers: [],
            showFields: false,
            formSubmitted: false,
            additionalFieldValues: {},
            selectedAgencyId: null,
            selectedAgencyName: null
        };
    },
    computed: {
        ...mapGetters(['userIsAdmin']),
        ...mapState({
            user: (state) => state.user.user,
            stateDealers: (state) => state.dealer.dealers,
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
            newTicketData: (state) => state.support.newTicketData
        }),
        dealerFieldValue() {
            if (!this.associateDealers) {
                return '';
            }
            if (this.selectedDealers.length) {
                return this.selectedDealers.map(dealer => dealer.id).join(', ');
            }
            return this.selectedDealers.id;
        },
        firstDealerFieldValue() {
            if (this.selectedDealers.length) {
                return this.selectedDealers[0].id;
            }
            return '';
        },
        dealerNameFieldValue() {
            if (!this.associateDealers) {
                return '';
            }
            if (this.selectedDealers.length) {
                return this.selectedDealers.map(dealer => dealer.name).join(', ');
            }
            return this.selectedDealers.name;
        },
        hubSpotFormId() {
            return 'a6d1665d-208f-4a4a-9745-fc5d413e7e9e';
        },
        newTicketDialogOpen: {
            get() {
                return this.$store.state.support.newTicketDialogOpen;
            },
            set(isOpen) {
                if (!isOpen) {
                    this.closeAndReset();
                }
            }
        },
        fieldValues() {
            return {
                ...{
                    'TICKET.dealer_id': this.dealerFieldValue,
                    'TICKET.ticket___buyerbridge_id': this.firstDealerFieldValue,
                    'TICKET.agency_id': this.selectedAgencyId ?? this.currentAgency.id,
                    'TICKET.agency_name': this.selectedAgencyName ?? this.currentAgency.name,
                    'email': this.user.email,
                    'TICKET.form___dealer_name': this.dealerNameFieldValue,
                    'TICKET.ticket_contact_email': this.user.email,
                },
                ...this.additionalFieldValues
            };
        }
    },
    watch: {
        currentDealer() {
            this.selectedDealers = [ this.currentDealer ];
        },
        stateDealers() {
            this.dealers = this.stateDealers;
        },
        newTicketDialogOpen: {
            handler(isOpen) {
                if (isOpen) {
                    this.mapNewTicketData();
                } else {
                    this.resetTicketData();
                    this.additionalFieldValues = {};
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.dealers = this.stateDealers;

        // If the page loaded with the `open_ticket` query open it
        if (this.$route.query.open_ticket) {
            this.newTicketDialogOpen = true;
        }
    },
    methods: {
        ...mapActions(['resetTicketData']),
        mapNewTicketData() {
            if (this.newTicketData.dealers?.length) {
                this.selectedDealers = this.newTicketData.dealers;
            } else {
                this.selectedDealers = [ this.currentDealer ];
            }

            if (this.newTicketData.type) {
                this.additionalFieldValues['TICKET.type_of_issue'] = this.newTicketData.type;
            }

            if (this.newTicketData.subject) {
                this.additionalFieldValues['TICKET.subject'] = this.newTicketData.subject;
            }

            if (this.newTicketData.content) {
                this.additionalFieldValues['TICKET.content'] = this.newTicketData.content;
            }
        },
        handleClickSubmitAnotherTicket() {
            this.showFields = true;
            this.formSubmitted = false;
            this.$refs.hsForm.reset();
        },
        handleFormSubmitted() {
            this.showFields = false;
            this.formSubmitted = true;
            this.additionalFieldValues = {};
            this.resetTicketData();
        },
        search: debounce(async function(e) {
            this.loadingDealers = true;
            this.dealers = [];
            const response = await this.$apiRepository.getDealerPickerSearchDealers(e.target.value);
            this.dealers = response.data.data;
            this.loadingDealers = false;
        }, 300),
        handlePreSubmit($form) {
            const $subjectField = $form.find('[name="TICKET.subject"]');
            const $contentField = $form.find('[name="TICKET.content"]');
            $contentField.val(`
            • User: ${this.user.name}
            • Organization: ${this.currentAgency.name}
            • Account(s): ${this.dealerNameFieldValue}
            • Account ID(s): ${this.dealerFieldValue}
            • Issue description:
            ${$contentField.val()}`);
            const agencyName = this.selectedAgencyName ?? this.currentAgency.name;
            $subjectField.val(`${$subjectField.val()} - ${agencyName} - ${this.dealerNameFieldValue} - Support`);
        },
        onAgencySelection(agency) {
            this.selectedAgencyId = agency.id;
            this.selectedAgencyName = agency.name;
        },
        closeAndReset() {
            this.$store.commit(TOGGLE_NEW_TICKET_DIALOG, false);
            this.$store.commit(SET_TICKET_TYPE, false);
            this.selectedAgencyId = null;
            this.selectedAgencyName = null;
        }
    }
};
</script>

<style lang="scss">
.open-support-ticket-dialog .dock {
    z-index: 3100 !important;
}

#open-ticket {
    .styled-select.v-text-field .v-input__slot .v-label {
        font-size: 14px;
        top: 0;
    }
    .listitem {
        padding: 30px 0;
    }
}
</style>
