/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shape': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M12.819 6.637a6.681 6.681 0 110 13.363 6.681 6.681 0 010-13.363zM11.357 1v4.176a6.681 6.681 0 00-6.681 6.681H.5V1h10.857z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
