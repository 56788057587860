/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 11,
    height: 8,
    viewBox: '0 0 11 8',
    data: '<path pid="0" _fill="#00A2EA" fill-rule="evenodd" d="M9.551 0L4.333 5.135 1.637 2.608 0 4.033 4.145 8 11 1.44z"/>'
  }
})
