/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M8.059 12.037H0V8.084h8.059V0h3.882v8.084H20v3.953h-8.059V20H8.06z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
