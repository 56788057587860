import FacebookMapping from './mapping/facebook';
import SnapchatMapping from './mapping/snapchat';
import TiktokMapping from './mapping/tiktok';
import PinterestMapping from './mapping/pinterest';

const getGlobalMetricTotals = (name, data, map) => {
    if (data.hasOwnProperty('stats')) {
        return {
            name,
            impressions: data.stats[map.impressions].value,
            clicks: data.stats[map.clicks].value,
            leads: data.stats[map.leads].value,
            spend: data.stats[map.spend].value,
            cpm: data.stats[map.cpm].value
        };
    } else {
        return {
            name,
            impressions: data[map.impressions].value,
            clicks: data[map.clicks].value,
            leads: data[map.leads].value,
            spend: data[map.spend].value,
            cpm: data[map.cpm].value
        };
    }
};

const getGlobalMetricIntervals = (data, map) => {
    if(data[0].hasOwnProperty('stats')) {
        return data.map(day => {
            return {
                date: day.start_date,
                impressions: day.stats[map.impressions].value,
                clicks: day.stats[map.clicks].value,
                leads: day.stats[map.leads].value,
                spend: day.stats[map.spend].value,
                cpm: day.stats[map.cpm].value,
            };
        });
    } else {
        return data.map(day => {
            return {
                date: day.date_start.value,
                impressions: day[map.impressions].value,
                clicks: day[map.clicks].value,
                leads: day[map.leads].value,
                spend: day[map.spend].value,
                cpm: day[map.cpm].value
            };
        });
    }
};

const formattedTotalsData = (state, platforms) => {
    let aggregateTotals = {
        impressions: 0,
        clicks: 0,
        leads: 0,
        spend: 0,
        cpm: 0,
        ctr: null,
        cpc: null,
        conversion_rate: null,
        cpl: null,
    };
    // Sum all platforms on the main 4 metrics
    platforms
        .filter(platform => state.platformsSelected.includes(platform.name))
        .forEach(platform => {
        aggregateTotals.impressions += platform.impressions;
        aggregateTotals.clicks += platform.clicks;
        aggregateTotals.leads += platform.leads;
        aggregateTotals.spend += platform.spend;
    });

    // Add calculated metric values
    aggregateTotals.ctr = isFinite(aggregateTotals.clicks / aggregateTotals.impressions) ? aggregateTotals.clicks / aggregateTotals.impressions : 0;
    aggregateTotals.cpc = isFinite(aggregateTotals.spend / aggregateTotals.clicks) ? aggregateTotals.spend / aggregateTotals.clicks : 0;
    aggregateTotals.conversion_rate = isFinite(aggregateTotals.leads / aggregateTotals.clicks) ? aggregateTotals.leads / aggregateTotals.clicks : 0;
    aggregateTotals.cpl = isFinite(aggregateTotals.spend / aggregateTotals.leads) ? aggregateTotals.spend / aggregateTotals.leads : 0;
    aggregateTotals.cpm = (isFinite(aggregateTotals.spend / aggregateTotals.impressions)) * 1000 ? (aggregateTotals.spend / aggregateTotals.impressions) * 1000 : 0;

    // Format values into object with value and formatted keys
    aggregateTotals = {
        impressions: {
            value: aggregateTotals.impressions,
            formatted: aggregateTotals.impressions.toLocaleString()
        },
        clicks: {
            value: aggregateTotals.clicks,
            formatted: aggregateTotals.clicks.toLocaleString()
        },
        leads: {
            value: aggregateTotals.leads,
            formatted: aggregateTotals.leads.toLocaleString()
        },
        spend: {
            value: aggregateTotals.spend,
            formatted: '$' + aggregateTotals.spend.toLocaleString(undefined, { maximumFractionDigits: 2 })
        },
        cpm: {
            value: aggregateTotals.cpm,
            formatted: '$' + aggregateTotals.cpm.toLocaleString(undefined, { maximumFractionDigits: 2 })
        },
        ctr: {
            value: aggregateTotals.ctr,
            formatted: (aggregateTotals.ctr * 100).toFixed(2) + '%'
        },
        cpc: {
            value: aggregateTotals.cpc,
            formatted: '$' + aggregateTotals.cpc.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
        conversion_rate: {
            value: aggregateTotals.conversion_rate,
            formatted: (aggregateTotals.conversion_rate * 100).toFixed(2) + '%'
        },
        cpl: {
            value: aggregateTotals.cpl,
            formatted: '$' + aggregateTotals.cpl.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
    };

    return aggregateTotals;
};

const formattedSeriesData = (state, platforms) => {
    // Merge platform array values where dates match
    const combined = [];
    platforms
        .filter(platform => state.platformsSelected.includes(platform.name))
        .forEach(platform => {
            platform.metrics.forEach(day => {
            let index = combined.findIndex(c => c.start_date == day.date);
            if( index > -1) {
                combined[index].stats.impressions += day.impressions;
                combined[index].stats.clicks += day.clicks;
                combined[index].stats.leads += day.leads;
                combined[index].stats.spend += day.spend;
                combined[index].stats.cpm += day.cpm;
            } else {
                combined.push({
                    start_date: day.date,
                    end_date: day.date,
                    stats: {
                        impressions: day.impressions,
                        clicks: day.clicks,
                        leads: day.leads,
                        spend: day.spend,
                        cpm: day.cpm
                    }
                });
            }
        });
    });

    // Add calculated values
    const calculated = combined.map(day => {
        return {
            ...day,
            stats: {
                ...day.stats,
                ctr: day.stats.clicks / day.stats.impressions,
                cpc: day.stats.spend / day.stats.clicks,
                conversion_rate: day.stats.leads / day.stats.clicks,
                cpl: day.stats.spend / day.stats.leads
            }
        };
    });
    // Format values into object with value and formatted keys
    const formatted = calculated.map((day, i) => {
        return {
            ...day,
            stats: {
                impressions: {
                    value: calculated[i].stats.impressions,
                    formatted: calculated[i].stats.impressions?.toLocaleString() ?? 0
                },
                clicks: {
                    value: calculated[i].stats.clicks,
                    formatted: calculated[i].stats.clicks?.toLocaleString() ?? 0
                },
                leads: {
                    value: calculated[i].stats.leads,
                    formatted: calculated[i].stats.leads?.toLocaleString() ?? 0
                },
                spend: {
                    value: calculated[i].stats.spend,
                    formatted: '$' + (calculated[i].stats.spend?.toLocaleString(undefined, { maximumFractionDigits: 2 }) ?? 0)
                },
                cpm: {
                    value: calculated[i].stats.cpm,
                    formatted: '$' + (calculated[i].stats.cpm?.toLocaleString(undefined, { maximumFractionDigits: 2 }) ?? 0)
                },
                ctr: {
                    value: calculated[i].stats.ctr,
                    formatted: (calculated[i].stats.ctr * 100).toFixed(2) + '%'
                },
                cpc: {
                    value: calculated[i].stats.cpc,
                    formatted: '$' + (calculated[i].stats.cpc?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0)
                },
                conversion_rate: {
                    value: calculated[i].stats.conversion_rate,
                    formatted: (calculated[i].stats.conversion_rate * 100).toFixed(2) + '%'
                },
                cpl: {
                    value: calculated[i].stats.cpl,
                    formatted: '$' + (calculated[i].stats.cpl.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0)
                },

            }
        };
    });
    return formatted;
};

const loading = (state, getters, rootState) => {
    // TODO: Refactor this to use the specific loading getters for each channel instead of accessing rootState
    return (
        rootState.metrics.facebook.ads.metricsTotalsLoading ||
        rootState.metrics.facebook.ads.metricsIntervalsLoading ||
        rootState.metrics.facebook.ads.metricsTotalsComparisonLoading ||
        rootState.metrics.facebook.ads.metricsTotalsPrevious90DaysLoading ||
        rootState.metrics.facebook.ads.metricsTotalsPreviousMonthLoading ||
        rootState.metrics.snapchat.ads.metricsTotalsLoading ||
        rootState.metrics.snapchat.ads.metricsIntervalsLoading ||
        rootState.metrics.snapchat.ads.metricsTotalsComparisonLoading ||
        rootState.metrics.snapchat.ads.metricsTotalsPrevious90DaysLoading ||
        rootState.metrics.snapchat.ads.metricsTotalsPreviousMonthLoading ||
        rootState.metrics.tiktok.ads.metricsTotalsLoading ||
        rootState.metrics.tiktok.ads.metricsIntervalsLoading ||
        rootState.metrics.tiktok.ads.metricsTotalsComparisonLoading ||
        rootState.metrics.tiktok.ads.metricsTotalsPrevious90DaysLoading ||
        rootState.metrics.tiktok.ads.metricsTotalsPreviousMonthLoading ||
        rootState.metrics.pinterest.ads.metricsTotalsLoading ||
        rootState.metrics.pinterest.ads.metricsIntervalsLoading ||
        rootState.metrics.pinterest.ads.metricsTotalsComparisonLoading ||
        rootState.metrics.pinterest.ads.metricsTotalsPrevious90DaysLoading ||
        rootState.metrics.pinterest.ads.metricsTotalsPreviousMonthLoading
    );
};

const metricsTotals = (state, getters, rootState, rootGetters) => {
    if(getters.loading || !getters.hasMetrics) {
        return null;
    }
    try {
        const platforms = [];

        if(rootGetters.dealerHasFeatures(['facebook_ad_account'])) {
            const facebook = rootGetters['metrics/facebook/ads/metricsTotalsFormatted'];
            if(facebook) {
                platforms.push(getGlobalMetricTotals('facebook', facebook, FacebookMapping));
            }
        }

        if(rootGetters.dealerHasFeatures(['snapchat_ad_account'])) {
            const snapchat = rootState.metrics.snapchat.ads.metricsTotals;
            if(snapchat) {
                platforms.push(getGlobalMetricTotals('snapchat', snapchat, SnapchatMapping));
            }
        }

        if(rootGetters.dealerHasFeatures(['tiktok_ad_account'])) {
            const tiktok = rootState.metrics.tiktok.ads.metricsTotals;
            if(tiktok) {
                platforms.push(getGlobalMetricTotals('tiktok', tiktok, TiktokMapping));
            }
        }

        if(rootGetters.dealerHasFeatures(['pinterest_ad_account'])) {
            const pinterest = rootState.metrics.pinterest.ads.metricsTotals;
            if(pinterest) {
                platforms.push(getGlobalMetricTotals('pinterest', pinterest, PinterestMapping));
            }
        }
        return formattedTotalsData(state, platforms);
    } catch(error) {
        console.error('Error retrieving aggregate metric totals:', error);
    }
};
const metricsTotalsPreviousPeriod = (state, getters, rootState, rootGetters) => {
    if(getters.loading || !getters.hasMetrics) {
        return null;
    }
    const platforms = [];

    if(rootGetters.dealerHasFeatures(['facebook_ad_account'])) {
        const facebook = rootGetters['metrics/facebook/ads/metricsTotalsComparisonFormatted'];
        if(facebook) {
            platforms.push(getGlobalMetricTotals('facebook', facebook, FacebookMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['snapchat_ad_account'])) {
        const snapchat = rootState.metrics.snapchat.ads.metricsTotalsComparison;
        if(snapchat) {
            platforms.push(getGlobalMetricTotals('snapchat', snapchat, SnapchatMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['tiktok_ad_account'])) {
        const tiktok = rootState.metrics.tiktok.ads.metricsTotalsComparison;
        if(tiktok) {
            platforms.push(getGlobalMetricTotals('tiktok', tiktok, TiktokMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['pinterest_ad_account'])) {
        const pinterest = rootState.metrics.pinterest.ads.metricsTotalsComparison;
        if(pinterest) {
            platforms.push(getGlobalMetricTotals('pinterest', pinterest, PinterestMapping));
        }
    }

    return formattedTotalsData(state, platforms);
};

const metricsTotalsPreviousMonth = (state, getters, rootState, rootGetters) => {
    if(getters.loading || !getters.hasMetrics) {
        return null;
    }
    const platforms = [];

    if(rootGetters.dealerHasFeatures(['facebook_ad_account'])) {
        const facebook = rootGetters['metrics/facebook/ads/metricsTotalsPreviousMonthFormatted'];
        if(facebook) {
            platforms.push(getGlobalMetricTotals('facebook', facebook, FacebookMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['snapchat_ad_account'])) {
        const snapchat = rootState.metrics.snapchat.ads.metricsTotalsPreviousMonth;
        if(snapchat) {
            platforms.push(getGlobalMetricTotals('snapchat', snapchat, SnapchatMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['tiktok_ad_account'])) {
        const tiktok = rootState.metrics.tiktok.ads.metricsTotalsPreviousMonth;
        if(tiktok) {
            platforms.push(getGlobalMetricTotals('tiktok', tiktok, TiktokMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['pinterest_ad_account'])) {
        const pinterest = rootState.metrics.pinterest.ads.metricsTotalsPreviousMonth;
        if(pinterest) {
            platforms.push(getGlobalMetricTotals('pinterest', pinterest, PinterestMapping));
        }
    }

    return formattedTotalsData(state, platforms);
};

const metricsTotalsPrevious90Days = (state, getters, rootState, rootGetters) => {
    if(getters.loading || !getters.hasMetrics) {
        return null;
    }
    const platforms = [];

    if(rootGetters.dealerHasFeatures(['facebook_ad_account'])) {
        const facebook = rootGetters['metrics/facebook/ads/metricsTotalsPrevious90DaysFormatted'];
        if(facebook) {
            platforms.push(getGlobalMetricTotals('facebook', facebook, FacebookMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['snapchat_ad_account'])) {
        const snapchat = rootState.metrics.snapchat.ads.metricsTotalsPrevious90Days;
        if(snapchat) {
            platforms.push(getGlobalMetricTotals('snapchat', snapchat, SnapchatMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['tiktok_ad_account'])) {
        const tiktok = rootState.metrics.tiktok.ads.metricsTotalsPrevious90Days;
        if(tiktok) {
            platforms.push(getGlobalMetricTotals('tiktok', tiktok, TiktokMapping));
        }
    }

    if(rootGetters.dealerHasFeatures(['pinterest_ad_account'])) {
        const pinterest = rootState.metrics.pinterest.ads.metricsTotalsPrevious90Days;
        if(pinterest) {
            platforms.push(getGlobalMetricTotals('pinterest', pinterest, PinterestMapping));
        }
    }

    return formattedTotalsData(state, platforms);
};

const metricsIntervals = (state, getters, rootState, rootGetters) => {
    if(getters.loading || !getters.hasMetrics) {
        return null;
    }
    const platforms = [];

    if(rootGetters.dealerHasFeatures(['facebook_ad_account'])) {
        const facebook = rootGetters['metrics/facebook/ads/metricsIntervalsFormatted'];
        if(facebook && facebook.length) {
            platforms.push({ name: 'facebook', metrics: getGlobalMetricIntervals(facebook, FacebookMapping) });
        }
    }

    if(rootGetters.dealerHasFeatures(['snapchat_ad_account'])) {
        const snapchat = rootState.metrics.snapchat.ads.metricsIntervals;
        if(snapchat && snapchat.length) {
            platforms.push({ name: 'snapchat', metrics: getGlobalMetricIntervals(snapchat, SnapchatMapping) });
        }
    }

    if(rootGetters.dealerHasFeatures(['tiktok_ad_account'])) {
        const tiktok = rootState.metrics.tiktok.ads.metricsIntervals;
        if(tiktok && tiktok.length) {
            platforms.push({ name: 'tiktok', metrics: getGlobalMetricIntervals(tiktok, TiktokMapping) });
        }
    }

    if(rootGetters.dealerHasFeatures(['pinterest_ad_account'])) {
        const pinterest = rootState.metrics.pinterest.ads.metricsIntervals;
        if(pinterest && pinterest.length) {
            platforms.push({ name: 'pinterest', metrics: getGlobalMetricIntervals(pinterest, PinterestMapping) });
        }
    }

    return formattedSeriesData(state, platforms);
};

const hasMetrics = (state, getters, rootState, rootGetters) => {
    return rootGetters['metrics/facebook/ads/hasMetrics']
        || rootGetters['metrics/snapchat/ads/hasMetrics']
        || rootGetters['metrics/tiktok/ads/hasMetrics']
        || rootGetters['metrics/pinterest/ads/hasMetrics'];
};

export default {
    loading,
    hasMetrics,
    metricsTotals,
    metricsTotalsPreviousPeriod,
    metricsTotalsPreviousMonth,
    metricsTotalsPrevious90Days,
    metricsIntervals
};