<template>
    <perfect-scrollbar>
        <slot />
    </perfect-scrollbar>
</template>
<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
export default {
    components: {
        PerfectScrollbar
    }
};
</script>
<style>
@import "~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
.ps > .ps__rail-x,
.ps > .ps__rail-y {   opacity: 0.6; }
</style>
