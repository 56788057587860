/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tooltip': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" _fill="#8F9EA6" d="M17.2 0H2.797a2.787 2.787 0 00-2.79 2.778L0 10.159l.014.227.03.212c.232 1.179 1.095 2.024 2.61 2.024H7.48l.672 2.007a1.949 1.949 0 003.696 0l.67-2.007H17.2c1.54 0 2.8-1.2 2.8-2.733V2.778C20 1.245 18.74 0 17.2 0zM2.797 2H17.2c.44 0 .8.355.8.778v7.11c0 .407-.343.734-.8.734h-4.681l-.16.007a2 2 0 00-1.737 1.359L10 13.848l-.623-1.86a2 2 0 00-1.897-1.366H2.654c-.478 0-.587-.106-.64-.374L2 10.153l-.002-.07.008-7.305c0-.426.354-.778.791-.778z"/>'
  }
})
