import { differenceInDays, format, subDays } from 'date-fns';

/**
 * Retrieves the default date range comparison date range
 *
 * @todo Let the user control increments and move/remove this
 *
 */
export const getDefaultDateRangeComparison = (dateRange) => {

    const daysDifference = differenceInDays(dateRange.endDate, dateRange.startDate);

    const previousPeriodEnd = subDays(dateRange.startDate, 1);
    const previousPeriodStart = subDays(previousPeriodEnd, daysDifference);

    return {
        startDate: format(previousPeriodStart, 'M/D/YY '),
        endDate: format(previousPeriodEnd, 'M/D/YY '),
    };
};
