/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<defs><path pid="0" d="M11.135 0l2.576 4.64-1.437.013c.239 6.95-1.937 11.413-6.585 13.15l-.271.096-.635-1.845c3.908-1.344 5.774-5.056 5.54-11.385l-1.77.014L11.136 0zm6.076 9.498v2.81H20v2.2h-2.789v2.795h-2.234v-2.795h-2.782v-2.2h2.782v-2.81h2.234zM3.902 5.108a3.902 3.902 0 110 7.805 3.902 3.902 0 010-7.805zm0 1.951a1.951 1.951 0 100 3.903 1.951 1.951 0 000-3.903z" id="svgicon_play_a"/></defs><use _fill="#8F9EA6" xlink:href="#svgicon_play_a" fill-rule="evenodd"/>'
  }
})
