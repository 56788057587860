<template>
    <div>
        <action-button
            class="mx-2"
            :icon="icon"
            :loading="loading"
            :color="color">
            {{ status }}
        </action-button>
        <b-m-access-slide-out v-model="bmAccessSlideOut" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import BMAccessSlideOut from '@/components/dealers/DealerID/QuickLinks/BMAccessSlideOut';
export default {
    components: {
        ActionButton,
        BMAccessSlideOut
    },
    data() {
        return {
            status: null,
            color: 'red',
            icon: 'indicator-error',
            response: null,
            bmAccessSlideOut: false,
            loading: false
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        })
    },
    watch: {
        response() {
            switch (this.response) {
                case 'loading':
                    this.status = 'Loading';
                    break;
                case 'user_pending_bm_invite':
                    this.color = 'orange';
                    this.icon = 'indicator-warning';
                    this.status = 'Pending BM Invite';
                    break;
                case 'user_not_in_bm':
                    this.color = 'red';
                    this.icon = 'indicator-error';
                    this.status = 'User Not In BM';
                    break;
                case 'user_already_in_bm':
                    this.color = 'blue';
                    this.icon = 'indicator-success';
                    this.status = 'User Is In BM';
                    break;
                default:
                    this.color= 'gray';
                    this.icon = 'indicator-question';
                    this.status = 'Unknown';
                    break;
            }
        }
    },
    created() {
        this.checkBMAccess();
    },
    methods: {
        async checkBMAccess() {
            try {
                this.response = 'loading';
                this.loading = true;
                const response = await this.$http.post(`/agencies/${this.currentDealer.agency.id}/check-bm-access`);

                this.response = response.data.status;

                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        }
    }
};
</script>
