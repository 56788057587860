/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outbound-user': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 12c.323 0 .676.017 1.052.05A6.973 6.973 0 009 17c0 1.074.242 2.091.674 3H0v-2.667C0 13.787 6.662 12 10 12zm9.738-1.128a.302.302 0 01.312.313l-.131 5.156a.342.342 0 01-.33.329l-1.04.026a.302.302 0 01-.312-.313l.067-2.61-4.857 4.517a.314.314 0 01-.443.011l-.722-.722a.314.314 0 01.011-.443l4.857-4.517-2.6.077a.302.302 0 01-.313-.313l.016-1.05a.342.342 0 01.329-.33zM10 0c2.762 0 5 2.237 5 5s-2.238 5-5 5c-2.763 0-5-2.237-5-5s2.237-5 5-5z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
