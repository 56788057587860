/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'marketplace': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<defs><path pid="0" d="M18.001 11.732L18 16.4c0 1.436-1.023 2.6-2.286 2.6H4.286C3.023 19 2 17.836 2 16.4v-4.668c.294.17.636.268 1 .268a1.99 1.99 0 001.001-.268L4 16h12v-4.268c.294.17.636.268 1 .268a1.99 1.99 0 001.001-.268zM16.256 0a2 2 0 011.911 1.412l1.54 5a2 2 0 01-1.083 2.408 2.5 2.5 0 01-4.123.181 2.5 2.5 0 01-4 0 2.5 2.5 0 01-4 0 2.5 2.5 0 01-4 0L2.2 9A2 2 0 01.289 6.418l1.52-5A2 2 0 013.722 0h12.534z" id="svgicon_marketplace_a"/></defs><use _fill="#8F9EA6" xlink:href="#svgicon_marketplace_a" fill-rule="evenodd"/>'
  }
})
