/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy-checked': {
    width: 27,
    height: 20,
    viewBox: '0 0 27 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.551 8l-5.218 5.135-2.696-2.527L0 12.033 4.145 16 11 9.44 9.551 8z" _fill="#00A2EA"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M22.828 2.778h-9.017a.869.869 0 00-.638.264.869.869 0 00-.264.638v9.018c0 .249.088.461.264.637a.869.869 0 00.638.264h9.017a.869.869 0 00.638-.264.869.869 0 00.264-.637V3.68a.869.869 0 00-.264-.638.869.869 0 00-.638-.264M9.302 4.737v11.568c0 .25.088.462.264.638a.869.869 0 00.637.264h11.568c-.187.53-.514.964-.979 1.3a2.618 2.618 0 01-1.571.504h-9.018a2.607 2.607 0 01-1.913-.793 2.607 2.607 0 01-.792-1.913V7.287c0-.582.168-1.106.504-1.57.336-.466.769-.792 1.3-.98M13.81.975h9.017c.747 0 1.385.264 1.913.793.529.528.793 1.166.793 1.912v9.018c0 .747-.264 1.385-.793 1.913a2.607 2.607 0 01-1.913.792h-9.017a2.607 2.607 0 01-1.913-.792 2.606 2.606 0 01-.793-1.913V3.68c0-.746.264-1.384.793-1.912A2.607 2.607 0 0113.81.975" _fill="#8F9EA6"/>'
  }
})
