var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('div',{staticClass:"loader"},[_c('div',{class:[
            'loader-icon',
            _vm.light ? 'light' : ''
        ],style:({
            height: `${_vm.size}px`,
            width: `${_vm.size}px`
        })},_vm._l(([1,2,3,4]),function(div){return _c('div',{key:div,style:({
                borderWidth: `${_vm.borderWidth}px`,
                height: `${_vm.size}px`,
                width: `${_vm.size}px`
            })})}),0),(_vm.label)?_c('div',{staticClass:"loader-label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }