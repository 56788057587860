/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'anchors': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g class="anchors-bar-control" _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M8 20a1 1 0 010-2h1v-2a1 1 0 012 0v2h1a1 1 0 010 2H8zM12 0a1 1 0 010 2h-1v2a1 1 0 01-2 0V2H8a1 1 0 110-2h4zM19 7a1 1 0 011 1v4a1 1 0 01-2 0v-1h-2a1 1 0 010-2h2V8a1 1 0 011-1zM1 7a1 1 0 011 1v1h2a1 1 0 110 2H2v1a1 1 0 01-2 0V8a1 1 0 011-1zM13 6a1 1 0 011 1v6a1 1 0 01-1 1H7a1 1 0 01-1-1V7a1 1 0 011-1h6zm-1 2H8v4h4V8z"/></g>'
  }
})
