import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import getInitialState from './getInitialState';

export default {
    namespaced: true,
    state: getInitialState(),
    mutations,
    getters,
    actions,
};
