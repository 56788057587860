/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list': {
    width: 31,
    height: 19,
    viewBox: '0 0 31 19',
    data: '<path pid="0" d="M8 16v3H0v-3h8zm23 0v3H11v-3h20zM8 8v3H0V8h8zm23 0v3H11V8h20zM8 0v3H0V0h8zm23 0v3H11V0h20z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
