/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rotate': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M7.37 6.451l.026.12.528 3.315a1.047 1.047 0 01-2.042.448l-.026-.12-.118-.734a5.35 5.35 0 00-.238 2.762 5.7 5.7 0 002.283 3.63 5.7 5.7 0 004.158 1.05 1.047 1.047 0 11.295 2.072 7.792 7.792 0 01-5.683-1.428 7.792 7.792 0 01-3.115-4.964 7.418 7.418 0 01.583-4.439l-.811.129a1.047 1.047 0 01-1.171-.752l-.026-.12c-.084-.53.247-1.03.751-1.17l.12-.026L6.2 5.7c.53-.083 1.029.246 1.17.75zm8.077-1.546a7.792 7.792 0 013.115 4.964 7.418 7.418 0 01-.583 4.439l.811-.129c.53-.083 1.03.247 1.171.752l.026.12c.084.53-.247 1.03-.751 1.17l-.12.026-3.316.523a1.047 1.047 0 01-1.17-.75l-.026-.12-.528-3.314a1.047 1.047 0 012.042-.449l.026.12.118.734a5.35 5.35 0 00.238-2.762 5.7 5.7 0 00-2.283-3.63 5.7 5.7 0 00-4.158-1.05 1.047 1.047 0 11-.295-2.072 7.792 7.792 0 015.683 1.428z" _fill="#000" fill-rule="evenodd"/>'
  }
})
