/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grab': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h3v3H0zm0 6h3v3H0zm0 6h3v3H0zM6 0h3v3H6zm0 6h3v3H6zm0 6h3v3H6z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
