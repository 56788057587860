/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ig_comment_icon': {
    width: 26,
    height: 25,
    viewBox: '0 0 26 25',
    data: '<path pid="0" d="M12.4 0c6.847 0 12.399 5.596 12.399 12.5 0 1.603-.3 3.135-.845 4.544L25.926 25l-7.122-1.795A12.267 12.267 0 0112.4 25C5.551 25 0 19.404 0 12.5S5.551 0 12.4 0zm0 2C6.66 2 2 6.697 2 12.5S6.66 23 12.4 23c1.791 0 3.512-.457 5.04-1.313l.324-.19.716-.436 4.702 1.184-1.321-5.334.228-.59c.467-1.205.71-2.495.71-3.821 0-5.803-4.66-10.5-10.4-10.5z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
