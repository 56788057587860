var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"styled-checkbox",class:{
        'disallow': _vm.disabled,
        'square': _vm.square,
        'inline': _vm.inline,
        'dash': _vm.dash
    },style:({
        '--checkmark-scale': _vm.checkmarkScale,
        '--checkmark-color': _vm.derivedColor
    })},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('input',{attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.checked},on:{"click":_vm.handleClick}}),_c('span',{staticClass:"styled-checkbox-checkmark"})])
}
var staticRenderFns = []

export { render, staticRenderFns }