<template>
    <div>
        <div
            class="preview"
            :class="{desktop: desktopPreview}">
            <div class="preview__bar">
                <meta-media-types-dropdown
                    v-if="platformConfig.platform === 'facebook'"
                    :selected-meta-preview="selectedMetaPreview"
                    :show-reels="platformAd.creatives[0].assets[0].asset_type.name == VIDEO_ASSET_TYPE"
                    @show-preview="showPreview" />
                <div v-else>
                    <div
                        class="preview__title">
                        <div class="preview__inside">
                            <div>
                                <span class="preview__icon meta-icon">
                                    <icon
                                        :name="platformConfig.icon"
                                        color="#03A2EA"
                                        size="25" />
                                </span>
                                <span>
                                    <span>{{ platformConfig.name }}</span> -
                                    <span v-if="assetType == VIDEO_ASSET_TYPE">Video Ad</span>
                                    <span v-else>Single Image Ad</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ad-preview
                :ad-data="platformAd"
                :platform-id="platformConfig.platform_id"
                :selected-meta-preview="selectedMetaPreview" />
        </div>
    </div>
</template>

<script>
import AdPreview from '@/components/globals/AdPreview';
import Icon from '@/components/globals/Icon';
import MetaMediaTypesDropdown from '@/components/ad-studio/editing/preview-step/MetaMediaTypesDropdown';
import { FACEBOOK_MOBILE_FEED } from '@/helpers/globals';
import { VIDEO_ASSET_TYPE } from '@/components/ad-studio/store/constants.js';
import { mapGetters } from 'vuex';

export default {
    components: {
        MetaMediaTypesDropdown,
        AdPreview,
        Icon
    },
    props: {
        adData: {
            type: [Object, Array],
            default: () => []
        },
        platformConfig: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            selectedMetaPreview: FACEBOOK_MOBILE_FEED,
            desktopPreview: false,
            VIDEO_ASSET_TYPE,
        };
    },
    computed: {
        assetType() {
            return this.platformAd?.creatives?.[0]?.assets?.[0]?.asset_type.name || this.platformAd?.creatives?.[0]?.media_type;
        },
        ...mapGetters({
            selectedAdDataFromAdStudio: 'adStudio/selectedAdData'
        }),
        selectedAdData() {
            if (this.adData.length != 0) {
                return this.adData;
            } else {
                return this.selectedAdDataFromAdStudio;
            }
        },
        platformAd() {
            return this.selectedAdData?.configuration?.platform_specific?.[this.platformConfig.platform];
        }
    },
    methods: {
        showPreview(type, desktopPreview) {
            this.selectedMetaPreview = type;
            this.desktopPreview = !!desktopPreview;
        },
    }
};
</script>

<style lang="scss" scoped>
.preview{
    &.desktop{
        max-width: 500px;
    }
    &__icon{
        margin-right: 10px;
        display: inline-block;
        &.meta-icon{
            transform: translateY(4px);
        }
    }
    &__title{
        color: #03A2EA;
        padding: 5px;
        padding-bottom: 15px;
    }
    &__inside{
        position: relative;
        display: inline-block;
        cursor: pointer;
        &.withTooltip{
            &:after{
                content: '';
                position: absolute;
                width:20px;
                height: 20px;
                background: white;
                box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
                top: calc(100% + 10px);
                right: 0;
                transform: rotate(45deg);
                z-index: 99;
            }
            &:before{
                content: '';
                position: absolute;
                width:60px;
                height: 25px;
                background: white;
                top: calc(100% + 20px);
                right: -20px;
                z-index: 100;
            }
        }
    }
    &__arrow{
        margin-left: 10px;
        display: inline-block;
        transform: rotate(90deg) translateX(1px);
    }
}
</style>
