<template>
    <div class="dropdown-menu">
        <a
            href="https://support.buyerbridge.io/"
            target="_blank">
            Help Center
        </a>
        <a @click.prevent="onClickOpenTicket">
            Open Ticket
        </a>
        <a
            v-if="currentAgency.id === AGENCY_DU"
            :href="duAdOpsTicketLink"
            target="_blank">
            Open DU Ticket
        </a>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { AGENCY_DU } from '@/helpers/globals';
import { TOGGLE_NEW_TICKET_DIALOG } from '@/store/mutation-types';

export default {
    data() {
        return {
            AGENCY_DU,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        duAdOpsTicketLink() {
            const params = {
                dealer_id: this.currentDealerId,
                dealer_name: this.currentDealer?.name,
                user_name: this.user?.name,
                user_email: this.user?.email,
                agency_name: this.currentAgency?.name,
                agency_id: this.currentAgency?.id,
                dealer_website: this.currentDealer?.site_base_url,
            };

            return `https://form.jotform.com/buyerbridge/dealers-united-tickets?${(new URLSearchParams(params)).toString()}`;
        }
    },
    methods: {
        onClickOpenTicket() {
            this.$store.commit(TOGGLE_NEW_TICKET_DIALOG, true);
            this.$emit('slideout-opened');
        },
    }
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
    background-color: $white;
    box-shadow: 0 0 6px 4px rgba(0,0,0,0.1);
    margin: 0 10px 10px 10px;
    border-radius: 5px;
    a {
        display: block;
        padding: 15px 40px 15px 40px;
        color: $blue-bg;
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        transition: background-color 0.2s ease-in-out;
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}
</style>
