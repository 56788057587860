<script>
import RemoteContentLink from './RemoteContentLink';

export default {
    name: 'RemoteContent',
    props: {
        value: {
            type: String,
            required: true
        },
        dealer: {
            type: Object,
            default: () => {}
        },
        ticketData: {
            type: Object,
            default: () => {
            }
        }
    },
    methods: {
        getVNodes(h, nodes) {
            const children = Array.from(nodes);

            return children.map(child => {

                // Anchor nodes get converted to remote content links
                // finding & converting these is the primary objective
                // of this component
                if (child.nodeName == 'A') {
                    return h(RemoteContentLink, {
                        props: {
                            href: child.href,
                            target: child.target,
                            dealer: this.dealer,
                            ticketData: this.ticketData
                        }
                    }, this.getVNodes(h, child.childNodes));
                }

                // Text nodes.  As of writing it's unclear how
                // to make text vnodes alone.
                if (child.nodeType == Node.TEXT_NODE) {
                    return h('span', {}, child.textContent);
                }

                // All other nodes
                if (child.nodeType == Node.ELEMENT_NODE) {
                    return h(
                        child.nodeName,
                        { class: child.className },
                        this.getVNodes(h, child.childNodes)
                    );
                }

                // We're going to ignore everything else
                return '';
            });
        }
    },
    render(h) {

        const tempElem = document.createElement('div');
        tempElem.innerHTML = this.value;

        const vNodes = this.getVNodes(h, tempElem.childNodes);

        return h('div', { class: 'remote-content' }, vNodes);
    }
};
</script>

<style lang="scss" scoped>
.remote-content-link {
    display: inline-block
}
</style>
