/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'funnel': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<defs><path pid="0" id="svgicon_funnel_a" d="M18 2.75V.5H0v2.25l7.5 8.125V18.5l3-2.25v-5.375z"/></defs><use _fill="#8F9EA6" xlink:href="#svgicon_funnel_a" fill-rule="evenodd"/>'
  }
})
