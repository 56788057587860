/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M3.507 9.49c.529-3.86 4.148-6.554 8.03-6.011a7.116 7.116 0 013.518 1.507L12.249 7.38l7.706 1.849-.366-8.041-2.603 2.191a9.553 9.553 0 00-5.103-2.332C6.638.323 1.78 3.961 1.047 9.148c-.732 5.186 2.948 10.01 8.214 10.714a9.662 9.662 0 009.108-3.84l-1.972-1.507a7.108 7.108 0 01-6.79 2.915c-3.904-.523-6.628-4.081-6.1-7.94z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
