import { getRad } from '@daybrush/utils';

export const customRotateAble = (rotationIcon) => {
    return {
        name: 'custom',
        props: {},
        events: {},
        render(moveable, { createElement }) {
            const {
                rotationPosition,
            } = moveable.props;
            const {
                renderPoses,
                direction,
            } = moveable.state;

            const positions = getRotationPositions(rotationPosition, renderPoses, direction);
            const [pos, rad] = positions;

            const child = rotationIcon.children[0];

            const pathAttributes = {};
            child.getAttributeNames().forEach(attribute => {
                pathAttributes[attribute] = child.getAttribute(attribute);
            });

            const div = createElement('div', {
                class: 'moveable-custom-rotation-wrapper',
                style: {
                    transform: `translate(-50%) translate(${pos[0]}px, ${pos[1]}px) rotate(${rad}rad)`,
                }
            },
            [
                createElement('svg', {
                    class: 'moveable-custom-rotation rotate-icon moveable-custom-rotation svg-icon svg-fill',
                    key: 'moveable-custom-rotation',
                    version:'1.1',
                    viewBox:'0 0 22 22',
                    style: {
                        width: '20px',
                        height: '20px',
                    }
                }, [createElement('path', pathAttributes)] )
            ]
            );
            return div;
        },
    };
};

/**
 * @link https://github.com/daybrush/moveable/blob/0.27.2/packages/react-moveable/src/react-moveable/ables/Rotatable.tsx#L157
 */
const getRotationPositions = ( rotationPosition, [pos1, pos2, pos3, pos4], direction) => {
    if (rotationPosition === 'none') {
        return;
    }
    const [dir1, dir2] = (rotationPosition || 'top').split('-');
    let radPoses = [pos1, pos2];

    if (dir1 === 'left') {
        radPoses = [pos3, pos1];
    } else if (dir1 === 'right') {
        radPoses = [pos2, pos4];
    } else if (dir1 === 'bottom') {
        radPoses = [pos4, pos3];
    }
    let pos = [
        (radPoses[0][0] + radPoses[1][0]) / 2,
        (radPoses[0][1] + radPoses[1][1]) / 2,
    ];
    const rad = getRotationRad(radPoses, direction);
    if (dir2) {
        const isStart = dir2 === 'top' || dir2 === 'left';
        const isReverse = dir1 === 'bottom' || dir1 === 'left';

        pos = radPoses[(isStart && !isReverse) || (!isStart && isReverse) ? 0 : 1];
    }
    return [pos, rad];
};

/**
 * @link https://github.com/daybrush/moveable/blob/0.27.2/packages/react-moveable/src/react-moveable/utils.tsx#L884
 */
const getRotationRad = ( poses, direction ) => {
    return getRad(direction > 0 ? poses[0] : poses[1], direction > 0 ? poses[1] : poses[0]);
};