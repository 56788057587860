import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';
import getDealerCampaigns from '@/apis/pinterest/getDealerCampaigns';
import getDealerMetricsRequest from '@/apis/pinterest/getDealerMetrics';

const _mutationTypes = removeNamespace('metrics/pinterest/ads/', mutationTypes);


const updateCampaigns = async({ rootState, commit, getters }) => {

    try {

        commit(_mutationTypes.SET_PIN_DEALER_CAMPAIGNS, []);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_PIN_DEALER_CAMPAIGNS_LOADING, true);


        const data = await getDealerCampaigns(rootState.dealer.currentDealerId);

        const frozenData = data.data.map(Object.freeze);

        commit(_mutationTypes.SET_PIN_DEALER_CAMPAIGNS, frozenData);

    } finally {
        commit(_mutationTypes.SET_PIN_DEALER_CAMPAIGNS_LOADING, false);
    }
};

const getMetrics = async({ rootState }, config = {}) => {

    const defaults = {
        ...rootState.metrics.dateRange,
        dealerId: rootState.dealer.currentDealerId,
        level: 'account',
        interval: null,
        ...config
    };

    return await getDealerMetricsRequest(defaults);
};

const updateMetricsTotals = async({ dispatch, commit, getters }) => {

    try {

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS, null);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_LOADING, true);

        const data = await dispatch('getMetrics');

        const accountData = data ? data.series[0] : null;

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS, frozenAccountData);
    } finally {
        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_LOADING, false);
    }
};

const updateMetricsTotalsPreviousMonth = async({ dispatch, commit, getters, rootGetters }) => {

    try {

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH, null);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING, true);

        const data = await dispatch('getMetrics', {
            ...rootGetters['metrics/dateRangePreviousMonth']

        });

        const accountData = data ? data.series[0] : null;

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH, frozenAccountData);

    } finally {
        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING, false);
    }
};

const updateMetricsTotalsPrevious90Days = async({ dispatch, commit, getters, rootGetters }) => {

    try {

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS, null);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING, true);

        const data = await dispatch('getMetrics', {
            ...rootGetters['metrics/dateRangePrevious90Days']

        });

        const accountData = data ? data.series[0] : null;

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS, frozenAccountData);

    } finally {
        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING, false);
    }
};

const updateMetricsIntervals = async({ dispatch, commit, getters }) => {

    try {

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_INTERVALS, []);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_INTERVALS_LOADING, true);

        const data = await dispatch('getMetrics', {
            interval: 'daily'
        });
        const accountData = data ? data.series : [];

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_INTERVALS, frozenAccountData);
    } finally {
        commit(_mutationTypes.SET_PIN_DEALER_METRICS_INTERVALS_LOADING, false);
    }
};

const updateMetricsCampaigns = async({ dispatch, commit, getters }) => {

    try {

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_CAMPAIGNS, []);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_CAMPAIGNS_LOADING, true);

        const data = await dispatch('getMetrics', {
            level: 'campaign'
        });

        const accountData = data ? data.series : [];

        const frozenData = Object.freeze(accountData);

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_CAMPAIGNS, frozenData);
    } finally {
        commit(_mutationTypes.SET_PIN_DEALER_METRICS_CAMPAIGNS_LOADING, false);
    }
};

const updateMetricsTotalsComparison = async({ dispatch, commit, getters, rootState }) => {

    try {

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_COMPARISON, null);

        if (!getters.eligibleForMetrics) {
            return;
        }

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_COMPARISON_LOADING, true);

        const data = await dispatch('getMetrics', {
            ...rootState.metrics.dateRangeComparison
        });

        const accountData = data.series ? data.series[0] : null;

        const frozenAccountData = Object.freeze(accountData);

        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_COMPARISON, frozenAccountData);
    } finally {
        commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_COMPARISON_LOADING, false);
    }
};

const updateAllMetrics = async({ dispatch, commit }) => {
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_RUNNING, { value: false, status: '' });
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_ERROR, null);
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_PREVIOUS_PERIOD_ERROR, null);

    const responses = await Promise.allSettled([
        dispatch('updateMetricsTotals'),
        dispatch('updateMetricsIntervals'),
        dispatch('updateMetricsCampaigns'),
        dispatch('updateMetricsTotalsComparison'),
        dispatch('updateMetricsTotalsPreviousMonth'),
        dispatch('updateMetricsTotalsPrevious90Days')
    ]);


    // Get the metric total responses from the promise all settled array
    const metricsResponses = responses.slice(0, 2);

    if (metricsResponses.some(response => response.status === 'rejected')) {
        const fullSyncRunning = metricsResponses.some(response => response.reason.response.data.full_sync === 'running');
        const incrementalSyncRunning = metricsResponses.some(response => response.reason.response.data.incremental_sync === 'running');
        const fullSyncQueued = metricsResponses.some(response => response.reason.response.data.full_sync === 'queued');
        const incrementalSyncQueued = metricsResponses.some(response => response.reason.response.data.incremental_sync === 'queued');
        if (fullSyncRunning || incrementalSyncRunning || fullSyncQueued || incrementalSyncQueued) {
            commit(_mutationTypes.SET_PIN_DEALER_METRICS_RUNNING, { value: true, status: 'running' });
        }
        const fullSyncFailed = metricsResponses.some(response => response.reason.response.data.full_sync === 'failed');
        const incrementalSyncFailed = metricsResponses.some(response => response.reason.response.data.incremental_sync === 'failed');
        if (fullSyncFailed || incrementalSyncFailed) {
            commit(_mutationTypes.SET_PIN_DEALER_METRICS_RUNNING, { value: true, status: 'failed' });
        }
        commit(_mutationTypes.SET_PIN_DEALER_METRICS_ERROR, 'Error retrieving Pinterest Metrics');
        console.error('Error retrieving Pinterest Metrics');
    }


    // Get the previous metric total responses from the promise all settled array
    const metricsPreviousResponses = responses.slice(2, 5);

    if (metricsPreviousResponses.some(response => response.status === 'rejected')) {
        commit(_mutationTypes.SET_PIN_DEALER_METRICS_PREVIOUS_PERIOD_ERROR, 'Error retrieving previous Pinterest Metrics');
        console.error('Error retrieving previous Pinterest Metrics');
    }
};

const startMetricsLoaders = ({ commit }) => {
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_LOADING, true);
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_INTERVALS_LOADING, true);
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_CAMPAIGNS_LOADING, true);
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_COMPARISON_LOADING, true);
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING, true);
    commit(_mutationTypes.SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING, true);
};

export default {
    getMetrics,
    updateCampaigns,
    updateAllMetrics,
    startMetricsLoaders,
    updateMetricsTotals,
    updateMetricsCampaigns,
    updateMetricsIntervals,
    updateMetricsTotalsComparison,
    updateMetricsTotalsPreviousMonth,
    updateMetricsTotalsPrevious90Days,
};