<template>
    <editable-data-field
        :label="label"
        :value="value"
        :open.sync="open"
        :loading="loading"
        :message="message"
        :error="error"
        margin-left="0px"
        hide-edit
        replace-content>
        <template #default>
            <div
                class="current-color"
                :style="{
                    backgroundColor: value
                }"
                @click="open = true" />
        </template>
        <template #content>
            <v-form
                class="color-form"
                @submit.prevent="handleSubmit">
                <color-picker
                    v-model="color" />
                <div class="button-wrapper">
                    <styled-button
                        :loading="loading"
                        small>
                        Save
                    </styled-button>
                </div>
            </v-form>
        </template>
        <!-- Pass down actions -->
        <template
            v-if=" $slots.actions"
            #actions>
            <slot name="actions" />
        </template>
    </editable-data-field>
</template>

<script>
import sleep from '@/helpers/sleep';
import EditableDataField from '@/components/globals/fields/EditableDataField/index.vue';
import StyledButton from '@/components/globals/StyledButton';
import { Sketch } from 'vue-color';

export default {
    components: {
        ColorPicker: Sketch,
        EditableDataField,
        StyledButton
    },
    props: {
        value: {
            type: String,
            required: true
        },
        handler: {
            type: Function,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            color: null,
            open: false,
            loading: false,
            message: '',
            error: '',
        };
    },
    computed: {
        hexString() {
            if (this.color.a < 1) {
                return this.color.hex8;
            }

            return this.color.hex;
        }
    },
    watch: {
        value: {
            handler(value) {
                this.color = value;
            },
            immediate: true
        }
    },
    methods: {
        async handleSubmit() {
            try {
                this.loading = true;
                // Pass down the color to the provided handler
                await this.handler(this.hexString);

                this.loading = false;
                this.message = 'Color saved successfully!';

                await sleep(1000);

                this.open = false;

                await sleep(300);

                this.message = '';

            } catch (error) {
                this.error = error;
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.current-color {
    width: 25px;
    height: 25px;
    border-radius: 3px;
    border: 1px solid rgb(185, 188, 190);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
// .data-field-data {
//     padding-left: 20px;
// }
.button-wrapper {
    text-align: center;
    margin-top: 20px;
    button {
        min-width: 100px;
    }
}
</style>

<style lang="scss">
.color-form {
    .vc-sketch {
        box-shadow: none;
        border-radius: none;
        padding: 0;
    }
    .vc-sketch-presets {
        display: none;
    }
}
</style>
