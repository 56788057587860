/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash': {
    width: 18,
    height: 22,
    viewBox: '0 0 18 22',
    data: '<path pid="0" d="M16.573 5.242l-.735 15.129s-.277 1.164-1.326 1.164H3.92c-1.046 0-1.324-1.164-1.324-1.164L1.822 5.242h14.751zm-3.666 2.037h-.021a.54.54 0 00-.548.509l-.484 11.2a.497.497 0 00.485.51h.02a.542.542 0 00.549-.51l.483-11.2a.496.496 0 00-.484-.51zm-3.71 0a.518.518 0 00-.526.509v11.2c0 .282.236.51.527.51a.518.518 0 00.527-.51v-11.2a.518.518 0 00-.527-.51zm-3.687 0a.497.497 0 00-.505.487v.022l.483 11.2c.013.285.255.51.549.51.28 0 .506-.219.506-.488l-.001-.021-.483-11.201a.54.54 0 00-.549-.51zM12.172.955c.594 0 1.081.375 1.14.857h2.983c.634 0 1.146.427 1.146.953v.762H.955v-.762c0-.526.512-.953 1.144-.953h2.984c.044-.362.336-.686.777-.809.118-.032.24-.048.362-.048z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
