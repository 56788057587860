<template>
    <div class="ad-account-link">
        <div class="ad-account">
            <a
                :href="`https://business.facebook.com/adsmanager/manage/campaigns?act=${facebookAdAccountId}&business_id=${dealer.agency.facebook_business_id}`"
                target="_blank"
                class="mr-2">
                <span>{{ dealer.name }} Ad Account ({{ facebookAdAccountId }})</span>
            </a>
            <div class="button-group">
                <action-button
                    :loading="loading"
                    :color="userAssigned ? 'blue' : 'red'"
                    :icon="userAssigned ? 'indicator-success' : 'indicator-error' ">
                    {{ userAssigned ? 'Permission' : 'No Permission' }}
                </action-button>
                <action-button
                    target="_blank"
                    :href="`https://business.facebook.com/settings/ad-accounts/${facebookAdAccountId}?business_id=${dealer.agency.facebook_business_id}`"
                    icon="cog">
                    BM Settings
                </action-button>
            </div>
        </div>
        <asset-alerts :monitor-id="2" />
        <ul class="links">
            <li>
                <a
                    :href="`https://business.facebook.com/ads/manager/billing/transactions/?act=${facebookAdAccountId}&business_id=${dealer.agency.facebook_business_id}`"
                    target="_blank">
                    Billing
                </a>
            </li>
            <li>
                <a
                    :href="`https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${facebookAdAccountId}&business_id=${dealer.agency.facebook_business_id}`"
                    target="_blank">
                    Payment Settings
                </a>
            </li>
            <li>
                <a
                    :href="`https://business.facebook.com/adsmanager/audiences?act=${facebookAdAccountId}&business_id=${dealer.agency.facebook_business_id}`"
                    target="_blank">
                    Audiences
                </a>
            </li>
            <li>
                <router-link
                    :to="{ name: 'reports-facebook-advertising', params: { dealer_id: dealer.id } }">
                    Reporting
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ActionButton from '@/components/globals/ActionButton';
    import AssetAlerts from './AssetAlerts';


    export default {
        components: {
            ActionButton,
            AssetAlerts
        },
        props: {
            annotation: {
                type: Object,
                required: true
            },
            dealer: {
                type: Object,
                required: true
            },
            refresh: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                userAssigned: false
            };
        },
        computed: {
            ...mapState({
                recheckFacebookAssets: (state) => state.dealer.recheckFacebookAssets,
            }),
            facebookAdAccountId() {
                return this?.annotation?.facebook_ad_account_id.replace('act_', '');
            }
        },
        watch: {
            recheckFacebookAssets() {
                this.checkAccess(this.annotation.id);
            }
        },
        created() {
            this.checkAccess(this.annotation.id);
        },
        methods: {
            async checkAccess(annotationId) {
                try {
                    this.loading = true;
                    const response = await this.$http.post(`/facebook_ad_account_annotations/${annotationId}/check-user-access`);

                    if (['user_already_has_access', 'user_added_to_ad_account'].includes(response.data.status)) {
                        this.userAssigned = true;
                    } else {
                        this.userAssigned = false;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
.ad-account {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.button-group {
    display: flex;
    justify-content: space-between;
    width: 150px;
}
.links {
    list-style: none;
}
</style>
