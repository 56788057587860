<template>
    <div class="fields">
        <div class="fields__customizing">
            <div class="fields__switch">
                <v-switch
                    v-model="customizedPerPlatform"
                    :disabled="customizedPerPlatform"
                    color="primary" />
                <div class="fields__switch-text">
                    <span>Customize content per platform</span>
                    <icon-with-tooltip
                        class="single-icon"
                        has-tooltip
                        icon="question-circle"
                        size="18"
                        tooltip="By selecting this toggle, you will be able to customize the content on the channels that you are deploying to." />
                    <a
                        class="cheat-sheet"
                        href="https://support.buyerbridge.com/knowledge/how-to-ad-builder"
                        target="_blank">
                        <span>Ad Builder</span> <span>Cheat Sheet</span>
                    </a>
                </div>
            </div>
            <div
                v-if="customizedPerPlatform"
                class="fields__channels">
                <div>
                    <span
                        v-for="(platform, index) in platformsToShow"
                        :key="index"
                        class="single-icon mr-3"
                        :class="{ active: activePlatform === platform.name }"
                        @click="setActivePlatform(platform.name)">
                        <icon
                            :name="platform.icon"
                            :color="activeColor(platform.name)"
                            :size="platform.iconSize" />
                    </span>
                </div>
            </div>
            <best-practice-icon hide-link />
            <div class="fields__section">
                <span class="ml-2">Ad Creatives</span>
                <div class="fields__box">
                    <div class="fields__types">
                        <div class="fields__image-raw">
                            <div
                                v-for="(asset, index) in assetsForShowing"
                                :key="index">
                                <img
                                    v-if="asset.asset_type.name === IMAGE_ASSET_TYPE"
                                    :key="asset.url"
                                    :src="asset.url"
                                    alt="Ads images"
                                    class="mx-4">
                                <video
                                    v-if="asset.asset_type.name === VIDEO_ASSET_TYPE"
                                    :key="asset.url"
                                    class="mx-4"
                                    muted
                                    loop>
                                    <source
                                        :src="asset.url"
                                        type="video/mp4">
                                </video>
                                <div
                                    v-if="asset.width && asset.height"
                                    class="height-width mt-1">
                                    {{ asset.width }} x {{ asset.height }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="fields__edit"
                        @click="handleEdit">
                        edit
                    </div>
                </div>
            </div>
            <div>
                <file-editing-popup
                    v-if="popupOpen"
                    :active-platform="activePlatform"
                    @close-popup="closePopup" />
            </div>
        </div>
        <div
            class="fields__container">
            <v-form
                ref="form"
                v-model="formValidation">
                <field
                    v-for="(field, id) in filteredFields"
                    :key="id"
                    :field-props="field"
                    :ad-index="adIndex" />
            </v-form>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import {
    META,
    PINTEREST,
    SNAPCHAT,
    TIKTOK,
    FACEBOOK,
    supportedPlatforms,
    getAllAssetsForAd,
    getAllAssetsForAPlatform,
    IMAGE_ASSET_TYPE,
    VIDEO_ASSET_TYPE,
    fields
} from '@/components/ad-studio/store/constants';
import Field from '@/components/ad-studio/editing/preview-step/Field';
import BestPracticeIcon from '@/components/ad-studio/BestPracticeIcon';
import { urlRules } from '@/helpers/validationRules';
import { mapGetters, mapState, mapActions } from 'vuex';
import IconWithTooltip from '@/components/ad-studio/editing/preview-step/IconWithTooltip';
import EventBus from '@/event-bus';
import FileEditingPopup from '@/components/ad-studio/editing/FileEditingPopup';
import {  LAYERED_DESIGNER_MODE } from '@/creative-studio/domain/layered-designer/models/Layer';

export default {
    components: {
        BestPracticeIcon,
        Icon,
        Field,
        IconWithTooltip,
        FileEditingPopup
    },
    data() {
        return {
            defaultIconColor: '#909FA8',
            globalFieldsLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-builder',
            urlRules,
            META,
            SNAPCHAT,
            PINTEREST,
            TIKTOK,
            FACEBOOK,
            supportedPlatforms,
            popupOpen: false,
            IMAGE_ASSET_TYPE,
            VIDEO_ASSET_TYPE,
        };
    },

    computed: {
        ...mapState('adStudio', {
            ads: state => state.ads,
            adIndex: state => state.adIndex,
            showValidationErrors: state => state.showValidationErrors,
        }),
        ...mapGetters({
            selectedAdPlatforms: 'adStudio/selectedAdPlatforms',
        }),
        platformsToShow() {
            return this.supportedPlatforms.filter(platform => this.selectedAdPlatforms.includes(platform.name));
        },
        assetsForShowing() {
            return this.customizedPerPlatform ? getAllAssetsForAPlatform(this.ads[this.adIndex], this.activePlatform) : getAllAssetsForAd(this.ads[this.adIndex]);
        },
        customizedPerPlatform: {
            get() {
                return this.ads[this.adIndex]?.configuration.customized_per_platform;
            },
            set(value) {
                // Check to see if the value trying to be set to true
                // It should never be going from true to false
                if (value) {
                    this.$store.commit('adStudio/customizePerPlatform', { adIndex: this.adIndex });
                    // select the first channel as the one being customized
                    this.setActivePlatform(this.selectedAdPlatforms[0]);
                }
            }
        },
        formValidation: {
            get() {
                // If the ad is customized we look to see if the selected platform has a valid form
                if (this.customizedPerPlatform) {
                    return this.ads[this.adIndex]?.configuration?.platform_specific[this.activePlatform]?.form_valid;
                }
                // If the ad is not customized we look to see if all the ads have a valid form
                return this.ads[this.adIndex]?.configuration?.selected_platforms?.every((platform) => {
                    return this.ads[this.adIndex]?.configuration?.platform_specific?.[platform]?.form_valid;
                });
            },
            set(value) {
                this.$store.commit('adStudio/updateAdFormValidation', {
                    value,
                    adIndex: this.adIndex,
                    platform: this.customizedPerPlatform ? this.activePlatform : null
                });
            }
        },
        activePlatform: {
            get() {
                return this.ads[this.adIndex]?.active_platform;
            },
            set(platform) {
                this.$store.commit('adStudio/changeActivePlatform', {
                    adIndex: this.adIndex,
                    platform
                });
            }
        },
        filteredFields() {
            if(!this.customizedPerPlatform) {
                return fields.filter(field => field.platforms.some(platform => this.selectedAdPlatforms.includes(platform)));
            }

            return fields.filter(field => field.platforms.includes(this.activePlatform));
        },
    },
     watch: {
        showValidationErrors() {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        }
    },
    created() {
        // Check if customized per channel is set for the ad and
        // if so select the first platform as the default
        if (this.customizedPerPlatform) {
            this.setActivePlatform(this.selectedAdPlatforms[0]);
        }
        EventBus.$on('validate-fields', () => this.$refs.form.validate());
    },
    beforeDestroy() {
        EventBus.$off('validate-fields');
    },
    methods: {
        ...mapActions([
            'setLayeredDesignerActiveStatus',
            'loadLayeredDesigner'
        ]),
        activeColor(channel) {
            return channel === this.activePlatform ? '#03A2EA' : '#909FA8';
        },
        setActivePlatform(platform) {
            this.activePlatform = platform;
            EventBus.$emit('toggle-single-platform', platform);
        },
        closePopup() {
            this.popupOpen = false;
        },
        handleEdit() {
            const originalAdType = this.ads[this.adIndex].configuration?.all_platforms?.creatives?.[0]?.assets?.[0]?.asset_type?.name;
            if(originalAdType === IMAGE_ASSET_TYPE || originalAdType === VIDEO_ASSET_TYPE) {
                this.popupOpen = true;
                return;
            }

            // generate random id for layered designer
            const layeredDesignerId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

            this.loadLayeredDesigner(Object.freeze({
                layeredDesignerId,
                cards: [this.assetsForShowing[0].configuration.design]
             }));


            this.setLayeredDesignerActiveStatus({ isActive: true, mode: LAYERED_DESIGNER_MODE.EDIT_DESIGN });
        },
        }

};
</script>

<style lang="scss" scoped>
.fields {
    &__customizing{
        padding: 15px 30px;
        border-bottom: 1px solid #C7CED3;
    }
    &__switch{
        display: flex;
        align-items: center;
        margin-left: -15px;
    }
    &__channels{
        border-bottom: 1px solid #03A2EA;
        margin-bottom: 30px;
        width: calc(100% + 60px);
        margin-left: -30px;
        text-align: center;
        padding-bottom: 15px;
    }
    &__switch-text{
        width:calc(100% - 50px);
        display: flex;
        margin-top: -4px;
        position: relative;
        span {
            @media only screen and (max-width: 1500px) {
                max-width: 125px;
            }
        }
    }
    &__container{
        padding: 30px;
    }
    &__box{
        border-radius: 5px;
        margin-top:5px;
        border: 1px solid #C7CED3;
        padding: 10px 45px 10px 10px;
        display: flex;
        position: relative;
    }
    &__image{
        width: 100px;
        height: 100px;
        margin-right: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__image-raw{
        width: 100px;
        text-align: center;
        display: flex;
        img, video{
            // max-width: 100%;
            max-height: 100px;
            height: auto;
        }
    }
    &__types{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        span{
            color: #03A2EA;
            cursor: pointer;
            text-decoration: underline;
            margin-bottom: 5px;
            display: inline-block;
        }
    }
    &__edit{
        position: absolute;
        top: 10px;
        right:20px;
        color: #03A2EA;
        cursor: pointer;
        text-decoration: underline;
    }
}
.single-icon {
  margin-left: 10px;
  cursor: pointer;
  position: relative;
}
.single-icon.active:after {
    content: "";
    position: absolute;
    width: 26px;
    height: 20px;
    background: #fff;
    top: calc(100% + 16px);
    left: -3px;
    z-index: 2;
}

.single-icon.active:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #03a2ea;
    transform: rotate(45deg);
    top: calc(100% + 6px);
    left: 0;
    z-index: 1;
}

.height-width {
    font-weight: 600;
    color: #03A2EA;
    font-size: 12px;
}

.global--fields-container {
  display: flex;
  flex-direction: row;
}
.cheat-sheet {
    display: inline-block;
    position: absolute;
    right: -15px;
    top: 0;
    @media only screen and (max-width: 1785px) {
       display: flex;
       flex-direction: column;
       text-align: center;
       top: -10px;
    }
}
</style>
