export class File {
    path;
    name;
    type;
    size;
    height;
    width;

    constructor(id) {
        this.id = id;
    }
}