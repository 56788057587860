<template>
    <div>
        <h3 class="sidebar-item-title">
            Card Size
        </h3>
        <v-select
            v-model="localCardSizeId"
            class="styled-field image-format-select"
            :items="parsedCardSizes"
            item-text="displayLabel"
            item-value="id"
            :readonly="!isCurrentCardSelected">
            <template #selection="{ item }">
                <div
                    :style="item.style"
                    class="size-preview" />
                <div class="card-select-label">
                    {{ item.displayLabel }}
                </div>
                <icon
                    class="card-select-icon"
                    :name="item.icon"
                    width="20"
                    height="20"
                    color="#00A2EA" />
            </template>
            <template #item="{ item }">
                <div
                    :style="item.style"
                    class="size-preview" />
                <div class="card-select-label">
                    {{ item.displayLabel }}
                </div>
                <icon
                    class="card-select-icon"
                    :name="item.icon"
                    width="20"
                    height="20"
                    color="#00A2EA" />
            </template>
        </v-select>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import EventBus from '@/event-bus';

    import { LayeredDesignerServiceFactory } from '@/creative-studio/domain/layered-designer/services/LayeredDesignerServiceFactory';
    import { LinkedStatuses } from '@/creative-studio/domain/layered-designer/models/types';
    import Icon from '@/components/globals/Icon';

    const DEFAULT_CARD_SIZE_ID = 1;

    export default {
        components: {
            Icon
        },
        data() {
            return {
                cardSizes: [],
                localCardSizeId: DEFAULT_CARD_SIZE_ID
            };
        },
        computed: {
            ...mapGetters([
                'currentCard'
            ]),
            isCurrentCardSelected() {
                return this.currentCard !== null;
            },
            parsedCardSizes() {
                return this.cardSizes.map((size) => {
                    const parsedSize = { displayLabel: `${size.aspectRatio} - ${size.width} x ${size.height}`, ...size };
                    const selectStyle = {
                        width: (size.width / 70) + 'px' ,
                        height: (size.height / 70) + 'px',
                    };
                    parsedSize.style = selectStyle;

                    let icon = '';

                    switch(size.linkedStatus) {
                        case LinkedStatuses.Main:
                        icon = 'main-link';
                        break;
                        case LinkedStatuses.Linked:
                        icon = 'linked';
                        break;
                        case LinkedStatuses.Unlinked:
                        icon = 'not-linked';
                        break;
                    }
                    parsedSize.icon = icon;
                    return parsedSize;
                });
            }
        },
        watch: {
            currentCard(newCard) {
                if (newCard === null) {
                    this.localCardSizeId = DEFAULT_CARD_SIZE_ID;
                    return;
                }

                const cardSizeIndex = this.cardSizes.findIndex((size) => size.id === newCard.dimensions.id);
                if (cardSizeIndex === -1) {
                    console.error('New card size not found');
                    return;
                }
                this.localCardSizeId = this.cardSizes[cardSizeIndex].id;
            },
            localCardSizeId(newCardSizeId) {
                const cardSizeIndex = this.cardSizes.findIndex((size) => size.id === newCardSizeId);
                if (cardSizeIndex === -1) {
                    return;
                }
                const { id, height, width, aspectRatio, linkedStatus } = this.cardSizes[cardSizeIndex];
                this.updateCurrentCardDimensions({ id, height, width, aspectRatio, linkedStatus });
                this.setFocusedLayer(null);
                EventBus.$emit('on-current-card-dimensions-change');
            }
        },
        async mounted() {
            const layeredDesignerApiService = LayeredDesignerServiceFactory.createApiLayeredDesignerService();
            this.cardSizes = await layeredDesignerApiService.getCardSizes();
        },
        methods: {
            ...mapActions([
                'updateCurrentCardDimensions',
                'setFocusedLayer'
            ])
        }
    };
</script>

<style lang="scss">
.v-menu__content{
    z-index: 300 !important;
}

.size-preview {
    padding: 5px;
    margin-left: 10px;
    background-color: $gray;
}

.card-select-label {
    margin: auto;
    color: $light-grey-title;
}

.card-select-icon {
    margin-left: auto;
    margin-right: 10%;
}
</style>