<template>
    <div>
        <styled-tooltip>
            <v-btn
                href="https://support.buyerbridge.com/knowledge/facebook-page-access"
                target="_blank"
                flat
                icon>
                <icon
                    name="question-circle"
                    :size="iconSize" />
            </v-btn>
            <template
                v-if="newPageSettings"
                #content>
                <template v-if="newPage">
                    There is a page change request in progress. You need to complete that process
                    to use the new page.  If you started that process in error click "Change Page"
                    and enter your current page URL to change it back.
                </template>
                <template v-else>
                    <span v-if="overallStatus == 'active'">
                        {{ pageName }} is currently being used as your primary page
                        for all marketplace listings and advertisements.  If you would
                        like to change the primary page click "Change Page".
                    </span>
                    <span v-else-if="overallStatus == 'user_not_assigned'">
                        BuyerBridge is not yet assigned to {{ pageName }}.  This can happen
                        when our system is lagging or has errored.  Please wait up to 30 minutes
                        and check again. If you are still seeing the issue after that point please
                        contact support. We apologize for this issue.
                    </span>
                    <span v-else-if="overallStatus == 'pending'">
                        BuyerBridge does not yet have full access to the {{ pageName }} page.
                        The page owner needs to accept the appropriate invitations before the system can work with Facebook.
                        Check the status indicator(s) to determine the appropriate action.
                        If you would like to resend the invitation click "Resend Request".
                    </span>
                    <span v-else-if="overallStatus == 'revoked'">
                        BuyerBridge's access to the page ID {{ pageId }} has been revoked.
                        You'll need to reset access to the page before BuyerBridge can operate.
                        To start that process click "Resend Request".
                    </span>
                    For more information <strong>click the help icon</strong>.
                </template>
            </template>
            <template #content>
                <span v-if="overallStatus == 'active'">
                    {{ pageName }} is now being used as your primary page
                    for all marketplace listings and advertisements.  It will
                    replace your old page in this screen shortly.
                </span>
                <span v-else-if="overallStatus == 'user_not_assigned'">
                    BuyerBridge is not yet assigned to {{ pageName }}.  This can happen
                    when our system is lagging or has errored.  Please wait up to 30 minutes
                    and check again. If you are still seeing the issue after that point please
                    contact support. We apologize for this issue.
                </span>
                <span v-else-if="overallStatus == 'pending'">
                    BuyerBridge does not yet have full access to the {{ pageName }} page.
                    The page owner needs to accept the appropriate invitations before the system can work with Facebook.
                    Check the status indicator(s) to determine the appropriate action.
                    If you would like to resend the invitation click "Resend Request".
                </span>
                <span v-else-if="overallStatus == 'revoked'">
                    BuyerBridge's request to access {{ pageId }} has been rejected.
                    If you think this has been done in error click "Resend Request" to
                    restart the process. Otherwise you can continue using the current
                    active page.
                </span>
                For more information <strong>click the help icon</strong>.
            </template>
        </styled-tooltip>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import Icon from '@/components/globals/Icon';

export default {
    components: {
        StyledTooltip,
        Icon
    },
    props: {
        overallStatus: {
            type: String,
            required: true
        },
        pageName: {
            type: String,
            default: ''
        },
        pageId: {
            type: [String, Number],
            default: ''
        },
        newPageSettings: {
            type: Boolean,
            default: false
        },
        newPage: {
            type: Object,
            default: null
        },
        iconSize: {
            type: String,
            default: ''
        }
    },
};
</script>