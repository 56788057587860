/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exclamation': {
    width: 7,
    height: 20,
    viewBox: '0 0 7 20',
    data: '<path pid="0" d="M6.04 2.022L4.822 12.944a1.808 1.808 0 01-3.592 0L.013 2.022A1.808 1.808 0 011.813 0H4.24a1.808 1.808 0 011.8 2.022M3.026 19.416a1.618 1.618 0 110-3.236 1.618 1.618 0 010 3.236" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
