import {
    SET_NEW_TICKET_DATA,
    TOGGLE_NEW_TICKET_DIALOG,
    SET_TICKET_TYPE
} from '../mutation-types';

export default {
    state: {
        newTicketDialogOpen: false,
        adminTypeTicket: false,
        newTicketData: {}
    },
    getters: {},
    actions: {
        createTicket({ commit }, params = {}) {
            const {
                dealers = [],
                subject = '',
                type = '',
                content = ''
            } = params;

            commit(SET_NEW_TICKET_DATA, {
                dealers,
                subject,
                type,
                content
            });
            commit(TOGGLE_NEW_TICKET_DIALOG, true);
        },
        resetTicketData({ commit }) {
            commit(SET_NEW_TICKET_DATA, {});
        }
    },
    mutations: {
        [TOGGLE_NEW_TICKET_DIALOG](state, newTicketDialogOpen) {
            state.newTicketDialogOpen = newTicketDialogOpen;
        },
        [SET_NEW_TICKET_DATA](state, data) {
            state.newTicketData = data;
        },
        [SET_TICKET_TYPE](state, data) {
            state.adminTypeTicket = data;
        },
    }
};
