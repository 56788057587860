import ApiRepository from '../../api-repository';
import {
    SET_AGENCY,
    SET_DEALER,
    SET_DEALERS,
    SET_USER,
    SET_TOKEN,
    SET_INITIALIZING,
    SET_IS_LOGGED_IN,
    SET_USER_LOCATION,
    SET_TEMP_LOGIN_DETAILS
} from '../mutation-types';
import { get } from 'lodash';
import {
    USER_LOCATION_STORAGE_KEY,
    USER_STORAGE_KEY,
    ACCESS_TOKEN_STORAGE_KEY, TRUSTED_DEVICE_TOKEN
} from '../../helpers/storageKeys';
import router from '@/routes';
import store from '@/store';

const apiRepository = new ApiRepository();

const userLocationJson = sessionStorage.getItem(USER_LOCATION_STORAGE_KEY);

const userJson = localStorage.getItem(USER_STORAGE_KEY);

const localToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

export default {
    state: {
        token: localToken || null,
        isLoggedIn: !!localToken,
        tempLoginDetails: {},
        user: userJson ? JSON.parse(userJson) : null,
        location: userLocationJson ? JSON.parse(userLocationJson) : null,
        initializing: false
    },
    getters: {
        currentUser: (state) => state.user,
        isInitializing: (state) => state.initializing,
        userDealer(state) {
            return get(state, 'user.dealers.data[0]', null);
        },
        userAgency(state) {
            return get(state, 'user.agency', null);
        },
        userHasRoles: (state, getters) => (roles) => {

            if (!state.user) {
                return false;
            }

            return roles.some(getters.userHasRole);
        },
        userHasRole: (state) => (userRole) => {

            if (!state.user) {
                return false;
            }

            return state.user.roles.data.some(role => userRole === role.name);
        },
        userIsReseller: (state, getters) => {

            if (!state.user) {
                return false;
            }

            const resellerRoles = ['agency_dashboard','performance_manager'];

            return resellerRoles.some(getters.userHasRole);
        },
        userIsClient: (state, getters) => {

            if (!state.user) {
                return false;
            }

            const clientRoles = ['client'];

            return clientRoles.some(getters.userHasRole);
        },
        userIsAdmin: (state, getters) => {

            if (!state.user) {
                return false;
            }

            const adminRoles = ['super_admin', 'admin'];

            return adminRoles.some(getters.userHasRole);
        },
        userIsSuperAdmin: (state, getters) => {
            if (!state.user) {
                return false;
            }
            const adminRoles = ['super_admin'];
            return adminRoles.some(getters.userHasRole);
        },
        userHasPermission: (state, getters) => (permission) => {
            if (getters.userIsAdmin) { return true }
            return state.user.roles.data.some(role => {
                return role.permissions.data.some(p => p.name === permission);
            });
        },
        userHomepage: (state, getters, rootState) => {

            const userHasOnlyFBMarketplace = getters.dealerHasFeatures(['facebook_marketplace'])
                && !getters.dealerHasFeatures(['facebook_ad_account'])
                && !getters.dealerHasFeatures(['microsoft_ad_account'])
                && !getters.dealerHasFeatures(['pinterest_ad_account'])
                && !getters.dealerHasFeatures(['snapchat_ad_account'])
                && !getters.dealerHasFeatures(['tiktok_ad_account'])
                && getters.userHasRoles(['client']);

            if (!state.isLoggedIn) {
                return {
                    name: 'login',
                };
            }

            // "Pre-onboarding" users go to the "complete onboarding" form
            if (getters.userHasRoles(['pre_onboarding'])) {
                return {
                    name: 'complete-onboarding',
                };
            }

            // "Onboarding" users go to the dealer page
            if (getters.userHasRoles(['onboarding'])) {
                return {
                    name: 'thank-you',
                };
            }

            if (rootState.dealer.currentDealer && !userHasOnlyFBMarketplace) {
                return {
                    name: 'global-dashboard',
                    params: {
                        dealer_id: rootState.dealer.currentDealer.id
                    }
                };
            }

            if (userHasOnlyFBMarketplace) {
                return {
                    name: 'facebook-marketplace',
                    params: {
                        dealer_id: rootState.dealer.currentDealer.id
                    }
                };
            }

            return null;
        },
        userHasProducts: (state, getters) => (productIds) => {

            if (!state.user) {
                return false;
            }

            return productIds.some(getters.userHasProduct);
        },
        userHasProduct: (state) => (productId) => {

            if (!state.user) {
                return false;
            }

            const dealers = get(state, 'user.dealers.data');

            return dealers.some((dealer) => {
                const products = get(dealer, 'products.data');
                return products.some((product) => productId === product.id);
            });
        },
        userOrganization(state, getters) {

            if (getters.userHasRoles(['client'])) {
                return getters.userDealer;
            }

            if (getters.userHasRoles(['agency_dashboard', 'performance_manager'])) {
                return getters.userAgency;
            }

            return null;
        },
        userOrganizationName(state, getters) {
            return (getters.userOrganization) ? getters.userOrganization.name : null;
        },
        intercomCompany(state, getters) {
            return (
                (!getters.userOrganization) ? null :
                    {
                        company_id: getters.userOrganization.id,
                        name: getters.userOrganization.name
                    }
            );
        },
        intercomCompanies(state, getters) {
            const companies = [];

            if (getters.userHasRoles(['client']) && getters.userDealer) {
                companies.push(getters.userDealer);
            }

            if (getters.userHasRoles(['agency_dashboard', 'performance_manager']) && getters.userAgency) {
                companies.push(getters.userAgency);
            }

            return companies;
        },
        /**
         * @todo This  and its references can be removed once ad
         * deployment is publicly available
         */
        userHasAdDeploymentAccess(state, getters) {
            if (getters.userIsAdmin) {
                return true;
            }

            if (!(getters.userIsClient)) {
                return true;
            }

            return false;
        }
    },
    actions: {
        startInit({ commit }) {
            commit(SET_INITIALIZING, true);
        },
        stopInit({ commit }) {
            commit(SET_INITIALIZING, false);
        },

        async login({ dispatch, commit }, { email, password, init, trustedDeviceToken = null }) {
            try {
                const twoFaFeatureEnabled = /*process.env.VUE_APP_2FA_FEATURE_ENABLED =*/ true;

                const { data } = await apiRepository.loginUser({ email, password, trustedDeviceToken });

                if (data?.error) {
                    throw data;
                }

                if (data.reset_password_required) {
                    return { status: 'reset_password_required' };
                }

                commit(SET_TEMP_LOGIN_DETAILS, { email, password });

                if (twoFaFeatureEnabled && data.twofa_required) {
                    return { status: 'twofa_required' };
                }

                if (twoFaFeatureEnabled && data.twofa_forced) {
                    return { status: 'twofa_forced', data };
                }

                await dispatch('completeLogin', { data, init });
            } catch (error) {
                console.error('Authentication error:', error);

                // Ensure the system doesn't think we're logged in
                commit(SET_IS_LOGGED_IN, false);

                // Reject with the token for external consumers
                throw error;
            }
        },
        async completeLogin({ commit }, { data, init }) {
            // Setup an object for external consumers
            const tokens = {
                token: data.access_token
            };

            if (!data || !data.access_token) {
                router.push({
                    name: 'login',
                    query: {
                        user_message: 'session_timeout'
                    }
                }, () => {
                    store.dispatch('logout');
                });
            }

            // Start by storing the token so we can keep the user logged in
            commit(SET_TOKEN, tokens.token);


            // Allow the caller to determine if the UI should initialize
            // which is tied to state.isLoggedIn
            if (init) {
                commit(SET_IS_LOGGED_IN, true);
            }

            // Resolve with the token for external consumers
            return tokens;
        },
        async handleTwoFaChallenge({ commit }, { email, password, code, rememberDevice }) {
            try {
                const { data } = await apiRepository.twoFaChallenge({ email, password, code, rememberDevice });

                if (data?.error) {
                    return { error: data.error };
                }

                commit(SET_TEMP_LOGIN_DETAILS, { email, password });

                if (rememberDevice && data.data.trustedDeviceToken) {
                    localStorage.setItem(TRUSTED_DEVICE_TOKEN + email, data.data.trustedDeviceToken);
                }

                return data;
            } catch (error) {
                return { error: '2FA challenge failed. Please try again.' };
            }

        },
        async getUser({ commit }) {
            try {
                const response = await apiRepository.getCurrentUser();

                const user = response.data.data;

                // Commit mutations
                commit(SET_USER, user);

                // Resolve with the token for external consumers
                return user;
            } catch (error) {
                console.error('Error retrieving user:', error.response);
                // Reject with the token for external consumers
                throw error.response;
            }
        },
        async getUserLocation({ commit }) {
            try {
                const response = await apiRepository.getUserLocation();
                const location = response.data;

                commit(SET_USER_LOCATION, location);
                return location;
            } catch (error) {
                console.error('Error retrieving user location', error);
                throw error;
            }
        },
        logout({ commit, state }) {
            const email = state.user?.email;

            // Commit mutations to reset the state
            commit(SET_USER, null);
            commit(SET_TOKEN, null);
            commit(SET_IS_LOGGED_IN, false);

            // Unset the dealer and agency
            commit(SET_DEALER, null, { root: true });
            commit(SET_DEALERS, [], { root: true });
            commit(SET_AGENCY, null, { root: true });

            if (email) {
                apiRepository.logout(email);
            }
        },
    },
    mutations: {
        [SET_USER](state, user) {
            // Manipulate local storage for continuous use
            if (user === null) {
                localStorage.removeItem(USER_STORAGE_KEY);
            } else if (typeof user === 'object') {
                user.loaded = true;
                localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
            }

            state.user = user;
        },
        [SET_TOKEN](state, token) {
            // Manipulate local storage for continuous use
            if (token === null || token === undefined) {
                localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
            } else {
                localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
            }

            state.token = token;
        },
        [SET_IS_LOGGED_IN](state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        },
        [SET_TEMP_LOGIN_DETAILS](state, tempLoginDetails) {
            state.tempLoginDetails = tempLoginDetails;
        },
        [SET_INITIALIZING](state, initializing) {
            state.initializing = initializing;
        },
        [SET_USER_LOCATION](state, location) {
            // Manipulate local storage for continuous use
            if (location === null) {
                sessionStorage.removeItem(USER_LOCATION_STORAGE_KEY);
            } else if (typeof location === 'object') {
                sessionStorage.setItem(USER_LOCATION_STORAGE_KEY, JSON.stringify(location));
            }

            state.location = location;
        }
    }
};
