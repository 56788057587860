import { toPng, toBlob ,getFontEmbedCSS } from 'html-to-image';
import { LAYERED_DESIGNER_MAIN_CANVAS_ID } from '@/components/creative-studio/common/constants';

// Temporary placeholder to prevent errors
const imagePlaceholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIBAMAAABfdrOtAAAAG1BMVEXMzMyWlpacnJyqqqrFxcWxsbGjo6O3t7e+vr6He3KoAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACmElEQVR4nO3av2/aQBQH8O8ZbDPahJCOtpO2jE6aSB0PiDIbhswgIZHRphJdSfqP994ZEpwAigClrfr9SLyLeY7f/bIXAxAREREREREREREREREREREREf0/6ldfNDBr5svwrJ9MsDGxB6cZt+CG8VkZXsRJqDcm9jBI3RN0LxrNMjwz3w7STYl9jDWaKHJcLmwAihRFBngtqKiSOEBiJkYqPWY2mPn7JN/BieAElcQBfsKMJAa6qQ1Ao+3KCvi5jGQ9cRD/RLreHdlgjsdFVCbMxFUShxi0zFigIhvM8WNYblg31NXEAWrNRbXDtXaZuOzgWCOpxxNUp94p92uvrXGsNfFOYPdxkdlgDorYbqVx+jqxv0KuVbkdkl4Ldju8TuzPdBOVG9s9deX6nlQ61h0fJ0lSeUT1zN2hZWWSJDrWsys0Kg/bQWanUJnvg2M9hYmIiIiIiIiIiIiIiIjoD1HLz79SJGxev69IYI7ktaa8mHcCOTT68RS4CydQkX3PvUVQ//b+Ija2F5gvi9Q/618m3A61eoA/3l4EPVydw9G++cNNUqhhIE0tGUiR4Tm83GTWipyNMF0WqWVlcFM1h1fsKuJO7rJa3sUP3N/eQE1sc38rHVMm1Yhws14k6DSiZRFHl6EeKWfxtH0BzXQ5uh410mH+HRemz0rb5gJdKWJSmJrP6vWzFJl7+bKIWoVA+aPp9iJm4W0Ho84okgvJ/0gTrNYkwFMtr4zEmeHtSPA12jESOUu6O3oYme7bK0mzNhJniEoR9xRv1wSDbGcRWRMM81mGue7LlaR5WRP4Z9Ui9sDGtd2FXTeVnGx2Fx51V8OPO3KqNC+7C360oUg5pf1wdZ/sLPIO3kf8mGD2ATVU5wOKEP09fgOLdXyF2B0MogAAAABJRU5ErkJggg==';

export const generateAndDownloadCardPNG = async(elementId, elementName, { height, width, fontEmbedCSS }) => {
    try {
        const dataUrl = await generateDataURLFromElement(elementId, { height, width, fontEmbedCSS });
        downloadDataURL(dataUrl, elementName);
        return true;
    } catch (error) {
        console.error('Error saving layered designer card', error);
        return false;
    }
};

export const generateDataURLFromElement = async(elementId, { height, width, fontEmbedCSS }) => {
    const container = document.getElementById(elementId);
    try {
        const options = { height, width, filter: filterElementNodes, imagePlaceholder };
        if (fontEmbedCSS) {
            options.fontEmbedCSS = fontEmbedCSS;
        }
        const dataUrl = await toPng(container, options);
        return dataUrl;
    } catch (error) {
        console.error('Error generating asset', error);
        throw error;
    }
};

export const generateDataURLFromElementAsBlob = async(elementId, { height, width, fontEmbedCSS }) => {
    const container = document.getElementById(elementId);
    const moveableBox = container.querySelector('.moveable-control-box');
    if (moveableBox) {
        moveableBox.style.visibility = 'hidden';
    }
      try {
        const options = { height, width, filter: filterElementNodes, imagePlaceholder };
        if (fontEmbedCSS) {
            options.fontEmbedCSS = fontEmbedCSS;
        }
        const blob = await toBlob(container, options);
        return blob;
    } catch (error) {
        console.error('Error generating asset', error);
        throw error;
    } finally {
        if (moveableBox) {
            moveableBox.style.visibility = 'visible';
        }
    }
};

const filterElementNodes = (element) => {
    return !(element?.classList?.contains('rCSwtyrwf') || element?.id?.includes('clone'));
};

const downloadDataURL = (dataUrl, fileName, fileExtension = 'png') => {
    const link = document.createElement('a');

    document.body.appendChild(link);
    link.download = `${fileName}.${fileExtension}`;
    link.href = dataUrl;
    link.target = '_blank';
    link.click();
    link.remove();
};

export const downloadJsonAsFile = (data) => {
    const jsonStr = JSON.stringify(data);
    const dataUrl = 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr);
    const fileName = 'creative-studio';

    downloadDataURL(dataUrl, fileName, 'json');
};

export const downloadFontsCss = async() => {
    const container = document.getElementById(LAYERED_DESIGNER_MAIN_CANVAS_ID);
    return await getFontEmbedCSS(container);
};
