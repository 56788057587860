<template>
    <div>
        <styled-slideout
            label="Connection User"
            :value="value"
            @input="$emit('input', $event)">
            <div
                class="pa-4">
                <h3 class="pb-3">
                    What is a {{ channelDisplayName }} Connection User?
                </h3>
                <p>
                    Our platform uses a {{ channelDisplayName }} user token (“Connection User”) to make {{ channelDisplayName }}
                    API calls, communicate with {{ channelDisplayName }}, and receive data on your behalf that is crucial to many functions within the dashboard.
                    (<a
                        href="https://support.buyerbridge.com/knowledge/omnichannel-authentication"
                        target="_blank">Learn more about Connection Users here.</a>)
                </p>

                <div class="py-2">
                    <div class="py-2">
                        <v-alert
                            :value="true"
                            color="error"
                            outline
                            icon="warning">
                            Please proceed with caution. The need to make changes to your {{ channelDisplayName }} token is rare,
                            and can remove the ability to remove core {{ channelDisplayName }} functions in your account.
                            Typically, a {{ channelDisplayName }} token change is only required due to password/security changes, or it has expired.
                            We advise you contacting Support when making changes to any platform's Connection User.
                        </v-alert>
                    </div>
                </div>

                <h3 class="pb-3">
                    To create or change your {{ channelDisplayName }} Connection User:
                </h3>

                <p>
                    <ul class="connection-list">
                        <li>Select the blue button below to verify you want to make this change</li>
                        <li>Select “Authenticate with {{ channelDisplayName }},” which will direct you to the {{ channelDisplayName }} login screen</li>
                        <li>If you have an existing {{ channelDisplayName }} Business Manager, log in with the user that is in your {{ channelDisplayName }} Business Manager</li>
                        <li>
                            If you do not have an existing {{ channelDisplayName }} Business Manager,
                            you will first need to sign up for a {{ channelDisplayName }} account.
                            <a
                                v-if="channel.name == PLATFORM_TIKTOK"
                                href="https://support.buyerbridge.com/knowledge/create-assets-for-tiktok-onboarding#business-manager-creation"
                                target="_blank">Visit this Help Center article to begin.</a>
                            <a
                                v-if="channel.name == PLATFORM_SNAPCHAT"
                                href="https://support.buyerbridge.com/knowledge/create-assets-for-snapchat-onboarding#business-manager-creation"
                                target="_blank">Visit this Help Center article to begin.</a>
                        </li>
                    </ul>
                </p>

                <div
                    v-if="!showAreYouSure"
                    class="py-2">
                    <styled-button @click="showAreYouSure = true">
                        Continue Changing {{ channelDisplayName }} Connection User
                    </styled-button>
                </div>

                <div v-if="showAreYouSure">
                    <div v-if="loadingAuthUrl">
                        <styled-loader size="30" />
                    </div>
                    <div v-if="!loadingAuthUrl">
                        <a
                            :href="authUrl"
                            :class="channelDisplayName.toLowerCase() + '-button'"
                            class="
                            py-1
                            layout
                            row
                            align-center
                            justify-space-around
                            flex
                            xs8
                            ">
                            <icon
                                class="mr-2"
                                :name="channelDisplayName.toLowerCase()"
                                color="white"
                                size="40" />
                            <div>Authenticate with {{ channelDisplayName }}</div>
                        </a>
                    </div>
                </div>
            </div>
        </styled-slideout>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledButton from '@/components/globals/StyledButton';
import StyledLoader from '@/components/globals/StyledLoader';
import Icon from '@/components/globals/Icon';
import {
    PLATFORM_FACEBOOK,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK,
} from '@/helpers/globals.js';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    components: {
        StyledSlideout,
        StyledButton,
        StyledLoader,
        Icon,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        channel: {
            type: Object,
            required: true,
        },
		slideOutTitle: {
			type: String,
			default() {
				return 'Change Connection User';
			}
		},
        redirectPath: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            showAreYouSure: false,
            authUrl: '',
            loadingAuthUrl: false,
            PLATFORM_TIKTOK,
            PLATFORM_SNAPCHAT,
            PLATFORM_FACEBOOK
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency
        }),
        channelDisplayName() {
            return fbToMeta(this.channel.display_name);
        }
    },
    watch: {
        // To make sure that showAreYouSure is reset when the slideout is closed
        value() {
            this.showAreYouSure = false;
        },
        showAreYouSure(val) {
            if(val === true) {
                this.getAuthUrl(this.channel.name);
            }
        }
    },
    methods: {
        async getAuthUrl(platformName) {
            this.loadingAuthUrl = true;
            try {
                let response = await this.$apiRepository.getPlatformAuthUrl(this.currentAgency.id, platformName.toLowerCase(), this.getUiRedirectUrl());
                this.authUrl = response.data.authUrl;
            } catch (error) {
                console.log(error);
            } finally {
                this.loadingAuthUrl = false;
            }
        },
        // this ui redirect url is encoded by the backend API which is then
        // used as a state parameter in the oauth flow and once returned to
        // the call, used as the redirect url in the frontend
        getUiRedirectUrl() {
            if (this.channel.key === PLATFORM_FACEBOOK) {
                // Redirect to the /organizations/settings page so the user can
                // select the buisness manager they want to connect to
                return window.location.origin + '/organizations/settings';
            }

            let url = window.location.origin;
            let path = '';

            if(this.redirectPath) {
                path = this.redirectPath;
            } else {
                path = this.$route.path;
            }

            return url + path;
        },
        closeSlideoutPanel() {
            this.$emit('input', false);
        }
    }
};
</script>

<style lang="scss" scoped>
p {
    color: black;
}
.snapchat-button {
	border-radius: 7px;
	text-align: center;
	background-color: #FFFB53;
	padding: 4px;
	color: black;
	font-weight: 700;
	font-size: 17px;
	text-decoration: none;
}
.snapchat-button .svg-icon path {
    fill: white;
    stroke: #000000;
    stroke-width: .5px;
}

.tiktok-button {
	border-radius: 7px;
	text-align: center;
	background-color: black;
	padding: 4px;
	color: white;
	font-weight: 700;
	font-size: 17px;
	text-decoration: none;
}

.pinterest-button {
	border-radius: 7px;
	text-align: center;
	background-color: black;
	padding: 4px;
	color: white;
	font-weight: 700;
	font-size: 17px;
	text-decoration: none;
}
.connection-list{
    list-style-type: decimal;
    li{
        margin-bottom:8px;
        font-size: 13px;
    }
}

.meta-button {
  border-radius: 7px;
  text-align: center;
  background-color: #1A77F2;
  padding: 4px;
  color: white;
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
}
</style>