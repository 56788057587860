import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state: {
        campaigns: [],
        campaignsSelected: [],
        campaignsLoading: false,
        metricsRunning: {
            value: false,
            status: ''
        },
        metricsError: null,
        metricsTotals: null,
        metricsTotalsLoading: false,
        metricsIntervals: [],
        metricsIntervalsLoading: false,
        metricsCampaigns: [],
        metricsCampaignsLoading: false,
        metricsErrorPreviousPeriods: null,
        metricsTotalsComparison: null,
        metricsTotalsComparisonLoading: false,
        metricsTotalsPrevious90Days: null,
        metricsTotalsPrevious90DaysLoading: false,
        metricsTotalsPreviousMonth: null,
        metricsTotalsPreviousMonthLoading: false,
    },
    getters,
    actions,
    mutations
};
