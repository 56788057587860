/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V2l8 5 8-5v2z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
