import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
import monthDifference from '@/helpers/monthDifference';
import formatMap from './formatMap';
import {
    FACEBOOK_OFFLINE_EVENT_TYPES,
    FACEBOOK_ATTRIBUTION_WINDOWS,
    FACEBOOK_ATTRIBUTION_WINDOW_KEYS
} from '@/helpers/globals';


const getDate = (string) => {
    const parts = string.split('-').map(value => parseInt(value));
    return new Date(parts[0], parts[1] - 1, parts[2]);
};

const appendMetricAttributionWindows = ({ sourceData, sourceKeys, targetData, targetKey }) => {
    FACEBOOK_ATTRIBUTION_WINDOW_KEYS.forEach(attributionWindowKey => {

        // Sum the values of every key
        const total = sourceKeys.reduce((totals, currentKey) => {
            const sourceField = `${currentKey}_${attributionWindowKey}`;
            const currentValue = sourceData[sourceField];

            return totals + (currentValue);
        }, 0);

        const targetField = `${targetKey}_${attributionWindowKey}`;

        targetData[targetField] = total;
    });
};

function formatInsightsData(insights, config) {
    const {
        attribution: {
            website: websiteAttribution,
            offline: offlineAttribution
        },
        attributionWindows = [],
        offlineEventTypes = FACEBOOK_OFFLINE_EVENT_TYPES
    } = config;

    const data = {};
    const stringFields = [
        'product_id',
        'account_id',
        'campaign_id',
        'campaign_name',
        'account_name',
        'date_start',
        'date_stop'
    ];

    // Loop through all stats and reformat them into a flat list
    let statData;
    for (let statKey in insights) {
        statData = insights[statKey];

        // If the data is an array push each action type in as it's own key
        // prefixed with the parent stat
        if (statData instanceof Array) {
            statData.forEach(actionType => {

                // Guarding added 3/24/2024 since Facebook was sending back
                // `{ value: 0 }` in one node with no action_type
                if (!actionType.action_type) {
                    return;
                }

                // Swap dots with underscores for usability
                const fieldKey = `${statKey}_${actionType.action_type.replace('.', '_')}`;

                const metricAttribution = fieldKey.includes('offline') ? offlineAttribution : websiteAttribution;

                // Select the active click metric from the store setting (if available)
                let clicks = 0;
                if (actionType[`${metricAttribution.click}_click`]) {
                    clicks = parseFloat(actionType[`${metricAttribution.click}_click`]);
                }

                // Select the active view metric from the store setting (if available)
                let views = 0;
                if (actionType[`${metricAttribution.view}_view`]) {
                    views = parseFloat(actionType[`${metricAttribution.view}_view`]);
                }

                // Total the two
                data[fieldKey] = clicks + views;

                if (statKey.includes('action')) {
                    attributionWindows.forEach(attributionWindow => {
                        data[`${fieldKey}_${attributionWindow}`] = actionType[attributionWindow] ? parseFloat(actionType[attributionWindow]) : 0;
                    });
                } else {
                    data[fieldKey] = parseFloat(actionType.value);
                }
            });
            // All other data will be stored as the key's value so remap it directly
        } else {
            data[statKey] = stringFields.includes(statKey) ? statData : parseFloat(statData);
        }
    }

    // Remap the compiled data above into more a more intuitive and consistent format
    // most of this code probably wouldn't be necessary if Facebook formatted their data
    // differently so bring this one up with them ;)
    const finalData = {};
    // data.outbound_clicks_outbound_click = insights.hasOwnProperty('outbound_clicks') ? insights.outbound_clicks[0].value : 0;

    // Meta data
    finalData.campaign_name = data.campaign_name || '';
    finalData.campaign_id = data.campaign_id || '';
    finalData.product_id = data.product_id ? data.product_id.split(',')?.[0] : '';
    finalData.date_start = data.date_start || '';
    finalData.date_stop = data.date_stop || '';


    // Audience
    finalData.spend = calculateSpend(data, config);
    finalData.reach = data.reach || 0;
    finalData.cpp = isFinite(finalData.spend / finalData.reach) ? finalData.spend / finalData.reach : null;
    finalData.frequency = data.frequency || null;

    // Impressions
    finalData.impressions = data.impressions || 0;
    finalData.cpm = isFinite(finalData.spend / (finalData.impressions / 1000)) ? finalData.spend / (finalData.impressions / 1000) : null;

    // Clicks
    finalData.clicks = data.clicks || 0;
    finalData.cost_per_click = isFinite(finalData.spend / finalData.clicks) ? finalData.spend / finalData.clicks : null;
    finalData.ctr = data.ctr / 100 || null;

    // Unique clicks
    finalData.unique_clicks = data.unique_clicks || 0;
    finalData.cost_per_unique_click = isFinite(finalData.spend / finalData.unique_clicks) ? finalData.spend / finalData.unique_clicks : null;
    finalData.unique_click_rate = isFinite(finalData.unique_clicks / finalData.reach) ? finalData.unique_clicks / finalData.reach : null;

    // Outbound clicks
    finalData.outbound_clicks = data.outbound_clicks_outbound_click || 0;
    finalData.cost_per_outbound_click = isFinite(finalData.spend / finalData.outbound_clicks) ? finalData.spend / finalData.outbound_clicks : null;
    finalData.outbound_ctr = data.outbound_clicks_ctr_outbound_click / 100 || null;

    // Unique outbound clicks
    finalData.unique_outbound_clicks = data.unique_outbound_clicks_outbound_click || 0;
    finalData.cost_per_unique_outbound_click = isFinite(finalData.spend / finalData.unique_outbound_clicks) ? finalData.spend / finalData.unique_outbound_clicks : null;
    finalData.unique_outbound_ctr = data.unique_outbound_clicks_ctr_outbound_click / 100 || null;

    // Landing page views
    finalData.landing_page_views = data.actions_landing_page_view || 0;
    finalData.cost_per_landing_page_view = isFinite(finalData.spend / finalData.landing_page_views) ? finalData.spend / finalData.landing_page_views : null;
    finalData.landing_page_view_rate = isFinite(finalData.landing_page_views / finalData.reach) ? finalData.landing_page_views / finalData.reach : null;

    // On Facebook content views
    finalData.on_site_content_views = data.actions_onsite_conversion_view_content || 0;
    finalData.cost_per_on_site_content_view = isFinite(finalData.spend / finalData.actions_onsite_conversion_view_content) ? finalData.spend / finalData.actions_onsite_conversion_view_content : null;
    finalData.on_site_content_view_rate = isFinite(finalData.actions_onsite_conversion_view_content / finalData.impressions) ? finalData.actions_onsite_conversion_view_content / finalData.impressions : null;

    // Unique on Facebook content views
    finalData.unique_on_site_content_views = data.unique_actions_onsite_conversion_view_content || 0;
    finalData.cost_per_unique_on_site_content_view = isFinite(finalData.spend / finalData.unique_on_site_content_views) ? finalData.spend / finalData.unique_on_site_content_views : null;
    finalData.unique_on_site_content_view_rate = isFinite(finalData.unique_on_site_content_views / finalData.reach) ? finalData.unique_on_site_content_views / finalData.reach : null;

    // Off Facebook content views
    finalData.content_views = data.actions_offsite_conversion_fb_pixel_view_content || 0;
    finalData.cost_per_content_view = isFinite(finalData.spend / finalData.content_views) ? finalData.spend / finalData.content_views : null;
    finalData.content_view_rate = isFinite(finalData.content_views / finalData.landing_page_views) ? finalData.content_views / finalData.landing_page_views : null;

    // Unique off Facebook content views
    finalData.unique_content_views = data.unique_actions_offsite_conversion_fb_pixel_view_content || 0;
    finalData.cost_per_unique_content_view = isFinite(finalData.spend / finalData.unique_content_views) ? finalData.spend / finalData.unique_content_views : null;
    finalData.unique_content_view_rate = isFinite(finalData.unique_content_views / finalData.landing_page_views) ? finalData.unique_content_views / finalData.landing_page_views : null;

    // Aggregate content views
    finalData.omni_content_views = data.actions_omni_view_content || 0;
    finalData.cost_per_omni_content_view = isFinite(finalData.spend / finalData.omni_content_views) ? finalData.spend / finalData.omni_content_views : null;
    finalData.omni_content_view_rate = isFinite(finalData.omni_content_views / finalData.impressions) ? finalData.omni_content_views / finalData.impressions : null;

    // Aggregate unique content views
    finalData.unique_omni_content_views = data.unique_actions_omni_view_content || 0;
    finalData.cost_per_unique_omni_content_view = isFinite(finalData.spend / finalData.unique_omni_content_views) ? finalData.spend / finalData.unique_omni_content_views : null;
    finalData.unique_omni_content_view_rate = isFinite(finalData.unique_omni_content_views / finalData.reach) ? finalData.unique_omni_content_views / finalData.reach : null;

    // Call confirmation
    finalData.actions_click_to_call_native_call_placed = data.actions_click_to_call_native_call_placed || 0;

    // On-Facebook Leads (Form leads, messenger, etc)
    finalData.messaging_conversation_started_7d = data.actions_onsite_conversion_messaging_conversation_started_7d || 0;
    finalData.cost_per_messaging_conversation_started_7d = isFinite(finalData.spend / finalData.messaging_conversation_started_7d) ? finalData.spend / finalData.messaging_conversation_started_7d : null;
    finalData.messaging_conversation_started_7d_rate = isFinite(finalData.messaging_conversation_started_7d / finalData.impressions) ? (finalData.messaging_conversation_started_7d / finalData.impressions) : null;

    // Offline Leads
    finalData.actions_onsite_conversion_lead_grouped = data.actions_onsite_conversion_lead_grouped || 0;

    finalData.on_site_leads = (finalData.actions_onsite_conversion_lead_grouped + finalData.messaging_conversation_started_7d + finalData.actions_click_to_call_native_call_placed) || 0;
    finalData.cost_per_on_site_lead = isFinite(finalData.spend / finalData.on_site_leads) ? finalData.spend / finalData.on_site_leads : null;
    finalData.on_site_lead_rate = isFinite(finalData.on_site_leads / finalData.unique_on_site_content_views) ? finalData.on_site_leads / finalData.unique_on_site_content_views : null;

    finalData.onsite_conversion_messaging_block = data.actions_onsite_conversion_messaging_block || 0;
    finalData.cost_per_onsite_conversion_messaging_block = isFinite(finalData.spend / finalData.onsite_conversion_messaging_block) ? finalData.spend / finalData.onsite_conversion_messaging_block : null;
    finalData.onsite_conversion_messaging_block_rate = isFinite(finalData.onsite_conversion_messaging_block / finalData.messaging_conversation_started_7d) ? (finalData.onsite_conversion_messaging_block / finalData.messaging_conversation_started_7d) : null;

    finalData.messaging_first_reply = data.actions_onsite_conversion_messaging_first_reply || 0;
    finalData.cost_per_messaging_first_reply = isFinite(finalData.spend / finalData.messaging_first_reply) ? finalData.spend / finalData.messaging_first_reply : null;
    finalData.messaging_first_reply_rate = isFinite(finalData.messaging_first_reply / finalData.messaging_conversation_started_7d) ? (finalData.messaging_first_reply / finalData.messaging_conversation_started_7d) : null;

    // Form leads
    finalData.form_leads = data.actions_leadgen_grouped || 0;
    finalData.cost_per_form_lead = isFinite(finalData.spend / finalData.form_leads) ? finalData.spend / finalData.form_leads : null;
    finalData.form_lead_rate = isFinite(finalData.form_leads / finalData.reach) ? finalData.form_leads / finalData.reach : null;

    // Unique form leads
    finalData.unique_form_leads = data.unique_actions_leadgen_grouped || 0;
    finalData.cost_per_unique_form_lead = isFinite(finalData.spend / finalData.unique_form_leads) ? finalData.spend / finalData.unique_form_leads : null;
    finalData.unique_form_lead_rate = isFinite(finalData.unique_form_leads / finalData.reach) ? finalData.unique_form_leads / finalData.reach : null;

    // Off Facebook leads
    finalData.website_leads = data.actions_offsite_conversion_fb_pixel_lead || 0;
    finalData.cost_per_website_lead = isFinite(finalData.spend / finalData.website_leads) ? finalData.spend / finalData.website_leads : null;
    finalData.website_lead_rate = isFinite(finalData.website_leads / finalData.landing_page_views) ? finalData.website_leads / finalData.landing_page_views : null;

    appendMetricAttributionWindows({
        sourceData: data,
        sourceKeys: ['actions_offsite_conversion_fb_pixel_lead'],
        targetData: finalData,
        targetKey: 'website_leads'
    });


    // Unique off facebook leads
    finalData.unique_website_leads = data.unique_actions_offsite_conversion_fb_pixel_lead || 0;
    finalData.cost_per_unique_website_leads = isFinite(finalData.spend / finalData.unique_website_leads) ? finalData.spend / finalData.unique_website_leads : null;
    finalData.unique_website_lead_rate = isFinite(finalData.unique_website_leads / finalData.landing_page_views) ? finalData.unique_website_leads / finalData.landing_page_views : null;

    // Total leads
    finalData.leads = (finalData.on_site_leads + finalData.website_leads) || 0;
    finalData.cost_per_lead = isFinite(finalData.spend / finalData.leads) ? finalData.spend / finalData.leads : null;
    finalData.lead_rate = isFinite(finalData.leads / finalData.clicks) ? finalData.leads / finalData.clicks : null;

    // Lead rates
    finalData.lead_to_reach_rate = isFinite(finalData.leads / finalData.reach) ? finalData.leads / finalData.reach : null;
    finalData.lead_to_landing_page_view_rate = isFinite(finalData.leads / finalData.landing_page_views) ? finalData.leads / finalData.landing_page_views : null;
    finalData.lead_to_unique_click_rate = isFinite(finalData.leads / finalData.unique_clicks) ? finalData.leads / finalData.unique_clicks : null;
    finalData.lead_to_click_rate = isFinite(finalData.leads / finalData.clicks) ? finalData.leads / finalData.clicks : null;
    finalData.lead_to_unique_on_site_view_content_rate = isFinite(finalData.leads / finalData.unique_on_site_content_views) ? finalData.leads / finalData.unique_on_site_content_views : null;

    // Misc actions
    finalData.find_location = data.actions_onsite_conversion_find_location || 0;
    finalData.click_to_call = data.actions_onsite_conversion_click_to_call || 0;

    // Aggregate on Facebook leads
    finalData.omni_on_site_leads = (finalData.on_site_leads + finalData.find_location + finalData.click_to_call) || 0;
    finalData.cost_per_omni_on_site_lead = isFinite(finalData.spend / finalData.omni_on_site_leads) ? finalData.spend / finalData.omni_on_site_leads : null; // Calculated since only omni is available
    finalData.omni_on_site_lead_rate = isFinite(finalData.omni_on_site_leads / finalData.unique_on_site_content_views) ? finalData.omni_on_site_leads / finalData.unique_on_site_content_views : null;

    // Website + form leads
    finalData.website_and_form_leads = (finalData.website_leads + finalData.form_leads) || 0;
    finalData.cost_per_website_and_form_lead = isFinite(finalData.spend / finalData.website_and_form_leads) ? finalData.spend / finalData.website_and_form_leads : null; // Calculated since only omni is available
    finalData.website_and_form_lead_rate = isFinite(finalData.website_and_form_leads / finalData.landing_page_views) ? finalData.website_and_form_leads / finalData.landing_page_views : null;

    // Offline events
    offlineEventTypes.forEach(eventType => {
        finalData[`offline_${eventType.key}`] = data[`actions_offline_conversion_${eventType.key}`] || 0;

        FACEBOOK_ATTRIBUTION_WINDOWS.forEach(attributionWindow => {
            finalData[`offline_${eventType.key}_${attributionWindow.key}`] = data[`actions_offline_conversion_${eventType.key}_${attributionWindow.key}`] || 0;
        });
    });
    finalData.offline_purchase_28d_total = (finalData?.offline_purchase_28d_click || 0) + (finalData?.offline_purchase_28d_view || 0);
    finalData.offline_purchase_28d_rate = isFinite((finalData?.offline_purchase_28d_total || 0) / finalData.leads) ? (finalData?.offline_purchase_28d_total || 0) / finalData.leads : null;

    // Cost per offline event
    offlineEventTypes.forEach(eventType => {

        const total = finalData[`offline_${eventType.key}`];

        finalData[`cost_per_offline_${eventType.key}`] = isFinite(finalData.spend / total) ? finalData.spend / total : null;
        finalData[`offline_${eventType.key}_rate`] = isFinite(total / finalData.leads) ? total / finalData.leads : null;
    });
    finalData.cost_per_offline_purchase_28d = isFinite(finalData.spend / finalData.offline_purchase_28d_total) ? (finalData.spend / finalData.offline_purchase_28d_total) : null;

    // Return formatted metrics
    return formatMetrics(finalData);
}

function calculateSpend(data, { spendOverride }) {

    const { spend } = data;

    if (!spend) {
        return 0;
    }

    if (!spendOverride) {
        return spend;
    }

    // Allows customers to hide spend from reports
    if (spendOverride.type === 'no_spend') {
        return 0;
    }

    if (spendOverride.type === 'percentage') {

        // Apply the actual spend to the desired percentage
        // This will typically increase the spend (e.g. spend * 1.2 for 20%)
        return spend * (1 + (spendOverride.value / 100));
    }

    if (spendOverride.type === 'monthly_fixed') {

        const stateDate = getDate(data.date_start);
        const endDate = getDate(data.date_stop);

        // Get the fraction of months between the start and end date
        // This allows us to easily pro-rate any date range which is important
        // since the requests can come in representing different ranges
        const months = monthDifference(stateDate, endDate);

        // Apply the fixed spend to the fraction of months to derive the displayed spend
        return spendOverride.value * months;
    }

    // Fall back to spend
    return spend;
}

/**
 * Provides formatted values for each field for consistency in displaying data
 *
 * @param {Object} data
 */
function formatMetrics(data) {

    const formats = getNumberFormats();

    const newData = {};
    let metricValue;
    for (let metric in data) {
        metricValue = data[metric];

        // If there's a number format specified for the field apply it
        let numberFormat;
        let formattedValue;
        if (formatMap[metric]) {
            numberFormat = formatMap[metric];

            if (metricValue === null || metricValue === '') {
                formattedValue = '-';
            } else {
                formattedValue = numeral(metricValue).format(formats[numberFormat]);
            }

            newData[metric] = {
                value: metricValue,
                formatted: formattedValue,
                format: numberFormat
            };
            // Otherwise just use the raw value as the formatted value
        } else {
            newData[metric] = {
                value: metricValue,
                formatted: metricValue,
                format: 'raw'
            };
        }
    }

    return newData;
}

export default formatInsightsData;
