/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fill-icon': {
    width: 42,
    height: 40,
    viewBox: '0 0 42 40',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" _stroke="#8F9EA6" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round" d="M20.51 31.5v5.844M18.484 35.84l2.1 2.848 2.1-2.848M20.659 8.188V2.345M22.685 3.848L20.585 1l-2.101 2.848"/><path pid="1" d="M15.04 29.994h11.9a4.05 4.05 0 004.05-4.05v-11.9a4.05 4.05 0 00-4.05-4.05h-11.9a4.05 4.05 0 00-4.05 4.05v11.9a4.05 4.05 0 004.05 4.05z" _fill="#8F9EA6"/><path pid="2" d="M11.99 25.761l4.387-4.822 3.848 4.119 5.871-6.797 3.894 4.414v2.269a4.05 4.05 0 01-4.05 4.05H15.223a3.232 3.232 0 01-3.233-3.233z" _fill="#FFF"/><path pid="3" d="M20.365 15.68a2.66 2.66 0 11-5.319 0 2.66 2.66 0 015.319 0z" _stroke="#FFF" stroke-width="1.688" _fill="#FFF" stroke-linejoin="round"/><path pid="4" _stroke="#8F9EA6" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round" d="M33.314 19.978h5.833M37.646 22.008l2.844-2.104-2.844-2.104M8.666 19.83H2.833M4.334 17.8L1.49 19.904l2.844 2.104"/></g>'
  }
})
