<template>
    <div
        class="card-canvas-title-wrapper">
        <span
            class="card-canvas-title"
            :style="unescaledElementStyle">
            <icon
                :name="cardLinkedIcon"
                width="20"
                height="20"
                color="#00A2EA" />
            <span
                :contenteditable="nameEditable"
                class="card-canvas-editable-title"
                @blur="handleTitleBlur"
                @keypress.enter="onEnterKeyPress"
                v-text="cardName" />
            - {{ cardAspectRatio }}
        </span>
        <!-- <div
            class="ml-auto card-control-icons"
            :style="unescaledElementStyle">
            <button
                title="navigate-down-cards-button"
                @click="handleCardNavigation(navigateDirections.down)">
                <icon
                    name="chevron-up"
                    width="20"
                    height="20" />
            </button>
            <button
                title="navigate-up-cards-button"
                @click="handleCardNavigation(navigateDirections.up)">
                <icon
                    name="chevron-down"
                    width="20"
                    height="20" />
            </button>
            <button
                title="copy-cards-button"
                @click="duplicateCurrentCard">
                <icon
                    name="copy"
                    width="20"
                    height="20" />
            </button>
            <button
                title="add-card-button"
                @click="handleCreateNewCard">
                <icon
                    name="plus"
                    width="20"
                    height="20" />
            </button>
            <button
                v-if="!isLastCard"
                title="delete-current-card-button"
                @click="deleteCurrentCard">
                <icon
                    name="trash"
                    color="#8F9EA6"
                    width="17"
                    height="20" />
            </button>
        </div> -->
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Icon from '@/components/globals/Icon';
    import { CARD_NAVIGATE_DIRECTIONS } from '@/components/creative-studio/common/constants';
    import { generateCardPreview } from '../utils/card-preview-generation';
    import { manageCardPreviewDebounced } from '../LayeredDesigner.vue';

    export default {
        components: {
            Icon
        },
        props: {
            cardName: {
                required: true,
                type: String
            },
            nameEditable: {
                required: false,
                type: Boolean,
                default: false
            },
            cardLinkedIcon: {
                required: true,
                type: String
            },
            cardAspectRatio: {
                required: true,
                type: String
            }
        },
        data() {
            return {
                navigateDirections: {
                    up: CARD_NAVIGATE_DIRECTIONS.UP,
                    down: CARD_NAVIGATE_DIRECTIONS.DOWN
                },
                unescaledElementStyle: {
                    transform: null,
                }
            };
        },
        computed: {
            ...mapGetters([
                'canvasZoom',
                'layeredDesignerCards'
            ]),
                isLastCard() {
                return this.layeredDesignerCards?.length === 1;
            },
            lastCard() {
                return this.layeredDesignerCards.at(-1);
            }
        },
        watch: {
            canvasZoom(newZoom) {
                this.unescaledElementStyle.transform =`scale(${0.7 /(newZoom)})`;
            }
        },
        mounted() {
            this.unescaledElementStyle.transform =`scale(${0.7 /(this.canvasZoom)})`;
        },
        methods: {
            ...mapActions([
                'setCurrentCardName',
                'duplicateCurrentCard',
                'navigateBetweenCards',
                'setCurrentCardThumbnail',
                'deleteCurrentCard',
                'createNewCard',
                'setCurrentCard'
            ]),
            handleTitleBlur(event) {
                const updatedText = event.target.innerText;
                if (updatedText === this.cardName) {
                    return;
                }
                this.setCurrentCardName(updatedText);
            },
            onEnterKeyPress(e) {
                e.preventDefault();
                e.target.blur();
            },
            async handleCreateNewCard() {
                this.createNewCard();
                await generateCardPreview({
                    state: this.$store.state,
                    setThumbnailAction: this.setCurrentCardThumbnail,
                });
                this.setCurrentCard(this.lastCard);

            },
            async handleCardNavigation(direction) {
                //TODO: Logic repeated in Cards Manager, maybe we could make this global
                // we don't want to generate the card preview twice
                manageCardPreviewDebounced.cancel();

                // because the preview is generated from the current DOM
                // we have to generate the current card preview before switching to another card (because this impacts the dom)
                await generateCardPreview({
                    state: this.$store.state,
                    setThumbnailAction: this.setCurrentCardThumbnail,
                });
                this.navigateBetweenCards(direction);
            }
        }
    };
</script>

<style lang="scss">
.card-canvas-title-wrapper {
    display: flex;
    align-items: flex-start;
    margin-left:60px;
    margin-right: auto;
    width: inherit;
    padding: 10px;
    margin-bottom: 40px;
}

.card-canvas-editable-title {
    min-width: 100px;
    max-width: 300px;
    margin: 0 10px;
    outline: none;
}

.card-canvas-title {
    font-weight: 700;
    font-size: 15px;
}

.card-control-icons {
    padding-right: 20px;

    button {
        padding: 0 5px;
    }
}
</style>
