/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ig_reels_share_icon': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" d="M23.214.498a.954.954 0 01.405.05.966.966 0 01.534.426c.131.21.189.467.13.732a.943.943 0 01-.148.352l-11.51 20.653c-.457.82-1.689.616-1.857-.308L8.623 10.64.289 2.203C-.308 1.598.075.593.887.506L1 .5zm-2.717 3.975l-9.823 6.266 1.561 8.562 8.262-14.828zm-.782-1.974L3.392 2.5l6.105 6.179 10.218-6.18z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
