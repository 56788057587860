<template>
    <div
        :class="[{ 'selected-asset': isCurrentAsset }, asset.type + '-asset-container']">
        <img
            draggable="false"
            :src="asset.thumbnail"
            :alt="asset.name"
            :class="[ asset.type + '-asset']"
            @error="asset.thumbnail = 'https://via.placeholder.com/200'"
            @click="handleClickedAssed">
        <label
            v-if="isSelected && !hideCheckedAsset"
            class="styled-checkbox">
            <input
                type="checkbox"
                checked
                @change="handleUnselectedAsset">
            <span class="styled-checkbox-checkmark" />
        </label>
    </div>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isCurrentAsset: {
      type: Boolean,
      default: false,
    },
    hideCheckedAsset: {
        type: Boolean,
        default: false,
    }
  },
  methods: {
      handleClickedAssed() {
          this.$emit('click');
      },
      handleUnselectedAsset() {
          this.$emit('unselect-asset', this.asset);
      }
  }
};
</script>

<style lang="scss" scoped>
.selected-asset {
  outline: solid 5px $blue-bg;
  outline-offset: -4px;
}

.image-asset {
    max-height: 100%;
    min-width: 100%;
    width: 100px;
    object-fit: contain;
}

.text-asset {
  width: 100px;
  object-fit: cover;
}

.image-asset-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-asset-container img {
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 100%;
}
.shape-asset-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-asset-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.asset-container {
    position: relative;
    max-width: 100% !important;
    width: 50px;
    height: 50px;
}

.styled-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  &.disallow {
    cursor: not-allowed;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .styled-checkbox-checkmark {
        background-color: $blue-btn;
        border-color: $blue-btn;
        &::after {
          display: block;
        }
        &:hover {
          &::after {
            content: "\d7";
            font-size: 20px;
            color: #fff;
            border: 0px;
            top: -2.5px;
            left: 14.5px;
            transform: rotate(90deg);
            transition: transform 0.1s;
          }
          border-color: $gray;
           background-color: $gray;
        }
      }
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  .styled-checkbox-checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    border-radius: 2px;
    height: 15px;
    width: 15px;
    border: 2px solid;
    border-color: $gray;
    background-color: $white;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 3.5px;
      top: 0.5px;
      width: 4px;
      height: 9px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

}

.shape-asset-container  {
    background-color: $white;
    border: 1px solid $border-list;
    .shape-asset {
        object-fit: contain;
        width: 100px;
        height: 100px;
    }
}
.text-asset-container  {
    background-color: $white;
    border: 1px solid $border-list;
    padding: 10px;
    .text-asset {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
</style>
