<template>
    <div
        :class="{ hidden }">
        <div v-if="!formReady">
            <loader />
        </div>
        <div
            v-show="formReady"
            :id="formContainerId"
            ref="formContainer" />
    </div>
</template>

<script>
import Loader from '@/components/globals/Loader';
import { v4 as uuidv4 } from 'uuid';
import EventBus from '@/event-bus';

export default {
    components: {
        Loader
    },
    props: {
        formId: {
            type: String,
            required: true
        },
        portalId: {
            type: String,
            default: '3753365'
        },
        formSubmitCallback: {
            type: Function,
            default() {
                return () => null;
            }
        },
        fieldValues: {
            type: Object,
            default() {
                return {};
            }
        },
        checkBoxFieldValues: {
            type: Object,
            default() {
                return {};
            }
        },
        hideButton: {
            type: Boolean,
            required: false,
            default: false
        },
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formReady: false,
            formContainerId: null,
            hsScriptLoaded: false,
            jQueryScriptLoaded: false,
            formElem: null,

        };
    },
    computed: {
        scriptsLoaded() {
            return (this.jQueryScriptLoaded && this.hsScriptLoaded);
        },
    },
    watch: {
        formReady(isReady) {
            if (isReady) {
                this.$emit('load');
            }
        },
        scriptsLoaded(isLoaded) {
            if (isLoaded) {
                this.initForm();
            }
        },
        fieldValues() {
            this.syncFieldValues();
        },
        checkBoxFieldValues() {
            this.syncCheckBoxFieldValues();
        }
    },
    created() {
        EventBus.$on([
            'submit-dynamic-deployment-forms',
            'submit-new-platform-onboarding-form'
        ], () => {
            this.submit();
        });
    },
    mounted() {
        if (window.hbspt?.forms) {
            this.hsScriptLoaded = true;
        } else {
            this.loadHubSpotLibrary();
        }

        if (window.jQuery) {
            this.jQueryScriptLoaded = true;
        } else {
            this.loadJQueryLibrary();
        }

    },
    methods: {
        submit() {
            const submitButton = this.$el.querySelector('input[type=\'submit\']');

            if (submitButton) {
                submitButton.click();
            } else {
                console.error(`HubSpot form ${this.formId} cannot be initialized`);
            }
        },
        hideSubmitButton() {
            const submitButton = this.$el.querySelector('input[type=\'submit\']');
            submitButton.style.display = 'none';
        },
        initForm() {

            const formDomId = 'hs-form-' + uuidv4();

            this.formElem = document.createElement('div');
            this.formElem.id = formDomId;

            this.$refs.formContainer.appendChild(this.formElem);

            window.hbspt.forms.create({
                region: 'na1',
                css: '',
                portalId: this.portalId,
                formId: this.formId,
                target: '#' + formDomId,
                onFormReady: this.handleFormReady.bind(this),
                onFormSubmit: this.formSubmitCallback,
                onFormSubmitted: this.handleFormSubmitted.bind(this)
            });
        },
        reset() {
            // Remove the last form elem
            this.formElem.remove();

            this.formReady = false;

            this.initForm();
        },
        handleFormReady() {
            this.formReady = true;
            this.syncFieldValues();
            this.syncCheckBoxFieldValues();
            if (this.hideButton) {
                this.hideSubmitButton();
            }
        },
        handleFormSubmitted() {
            this.$emit('complete');
        },
        loadHubSpotLibrary() {
            const script = document.createElement('script');
            script.onload = () => {
                this.hsScriptLoaded = true;
            };
            script.src = '//js.hsforms.net/forms/v2.js';

            document.head.appendChild(script);
        },
        // HS requires JQuery to funtion
        loadJQueryLibrary() {
            const script = document.createElement('script');
            script.onload = () => {
                this.jQueryScriptLoaded = true;
            };
            script.src = '//code.jquery.com/jquery-3.6.0.min.js';

            document.head.appendChild(script);
        },
        syncFieldValues() {
            if (!this.formReady) {
                return;
            }

            // Populate fields from props
            Object.entries(this.fieldValues).forEach(([key, value]) => {
                const field = this.$refs.formContainer.querySelector(`[name="${key}"]`);
                if (field) {
                    /* eslint-disable-next-line no-undef */
                    jQuery(field).val(value).change(); // jQuery required from HS
                }
            });
        },
        syncCheckBoxFieldValues() {
            if (!this.formReady) {
                return;
            }

            // Populate fields from props
            Object.entries(this.checkBoxFieldValues).forEach(([key, value]) => {
                const fields = this.$refs.formContainer.querySelectorAll(`[name="${key}"]`);

                if (fields) {
                    fields.forEach((el) => {
                        if (value.includes(el.value)) {
                            el.checked = true;
                        } else {
                            el.checked = false;
                        }
                    });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.hidden {
    display: none;
}
</style>

<style lang="scss">
.hs-form {
    .hs-main-font-element {
        margin-bottom: 18px;
    }

    .hs-richtext {
        font-size: 12px;
        color: $gray-dark;
    }

    .hs-field-desc {
        color: #8f9ea6;
        font-size: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .hs-form-field {
        margin-bottom: 22px;
        position: relative;
        display: flex; /* Fix to move the field description around */
        flex-direction: column;

        & > label {
            font-size: 11px;
            line-height: 11px;
            color: rgba($black, 0.54);
            margin-bottom: 4px;
            display: block;

            .hs-form-required {
                color: $carnation;
            }
        }

        & > .hs-field-desc {
            order: 1
        }

        &.hs-fieldtype-number,
        &.hs-fieldtype-text,
        &.hs-fieldtype-select,
        &.hs-fieldtype-textarea {
            padding-top: 8px;

            & > label {
                position: absolute;
                left: 12px;
                top: 0px;
                background-color: $white;
                padding: 2px 4px;
                margin-bottom: 0;
            }
        }

        [name="TICKET.content"] {
            min-height: 180px;
        }

        &.hs-fieldtype-textarea {
            margin-bottom: 0px;
        }

        &.hs-fieldtype-file {
            .hs-field-desc:nth-child(1) {
                margin-bottom: 22px;
            }
        }

        select.hs-input {
            background: url("~@/assets/icons/expand-all.svg") no-repeat calc(100% - 14px) 50%;
        }

        .hs-input {
            &:not([type="file"]) {
                border: 1px solid $gray-light;
                padding: 8px 10px !important;

                &:focus {
                    outline: none;
                }
            }

            &.error {
                border-color: $carnation !important;
                background-color: $white !important;
            }
        }
    }


    .hs-error-msgs {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .hs-error-msg {
        color: $carnation;
        font-size: 12px;
    }

    .actions {
        margin-top: 50px;
        text-align: center;

        .hs-button {
            border-radius: 5px;
            padding: 15px 25px;
            color: $white;
            font-weight: bold;
            text-transform: uppercase;
            background-color: $blue-btn !important;
        }
    }
}
</style>
