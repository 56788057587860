/**
 * @file
 *
 * Note that this file is temporary and should be replaced with
 * a server side solution to protect resellers and streamline the process
 */

import {
    SET_BRAND
} from '../mutation-types';
import user from '../modules/user';
import ApiRepository from '../../api-repository';
import {
    BRAND_STORAGE_KEY,
    AGENCY_STORAGE_KEY
} from '@/helpers/storageKeys';

/**
 * Retrieves brand data from local storage (if applicable)
 */
const getLocalBrandData = () => {
    const currentBrandJson = localStorage.getItem(BRAND_STORAGE_KEY);
    if (currentBrandJson) {
        return JSON.parse(currentBrandJson); //TODO make sure the structure of the agency branding is good coming from localstorage
    } else {
        return applyDefaultBuyerBridgeBranding(getEmptyBrand());
    }
};

/**
 * Apply an updated brand ensuring that BB fullbacks are present
 *
 * @param {Object} data
 */
const applyDefaultBuyerBridgeBranding = (data) => {
    return {
        name: data.name || 'BuyerBridge',
        primary_domain: data.primary_domain || 'https://app.buyerbridge.io',
        asset_urls: {
            favicon: data.asset_urls.favicon || 'https://res.cloudinary.com/du/image/upload/v1586269057/branding/buyer-bridge-icon-dark.png',
            navbar_logo: data.asset_urls.navbar_logo || 'https://res.cloudinary.com/du/image/upload/v1555619282/branding/buyerbridge-logo.svg',
            small_logo: data.asset_urls.navbar_logo || 'https://res.cloudinary.com/du/image/upload/v1555619282/branding/buyerbridge-logo.svg'
        },
        theme: {
            font: data.theme.font || null,
            primary_color: data.theme.primary_color || '#42a5f5',
            secondary_color: data.theme.secondary_color ||  null
        }
    };
};

//Structure the data and set nulls if nothing comes back from the api
const structureBrandData = (agency) => {
    return {
        name: agency.name || null,
        primary_domain: agency.primary_domain || null,
        asset_urls : {
            favicon: agency.white_label_theme_config.asset_urls.favicon || null,
            navbar_logo: agency.white_label_theme_config.asset_urls.navbar_logo || null,
            small_logo: agency.white_label_theme_config.asset_urls.small_logo || null
        },
        theme: {
            font: agency.white_label_theme_config.theme.font || null,
            primary_color: agency.white_label_theme_config.theme.primary_color || null,
            secondary_color: agency.white_label_theme_config.theme.secondary_color || null
        }
    };
};

//Structure the data and set nulls if nothing comes back from the api
const getEmptyBrand = () => {
    return {
        'name': null,
        'primary_domain': null,
        'asset_urls': {
            'favicon': null,
            'navbar_logo': null,
            'small_logo': null
        },
        'theme': {
            'font': null,
            'primary_color': null,
            'secondary_color': null
        }
    };
};

// Retrieve local brand data (if set) and apply it to the defaults
const initialBrandData = getLocalBrandData();

export default {
    state: {
        name: initialBrandData.name,
        primary_domain: initialBrandData.primary_domain,
        asset_urls : initialBrandData.asset_urls,
        theme: initialBrandData.theme
    },
    actions: {
        setBrandFromAgency({ commit }, agency) {
            const agency_branding = structureBrandData(agency);

            commit(SET_BRAND, agency_branding);
        },
        async setBrandFromPublicAgencyEndpoint({ commit }, domain) {

            const apiRepository = new ApiRepository();

            //Get the agency from the brand endpoint based off the current domain
            try {
                const response = await apiRepository.getAgencyBranding(domain);

                const agency = response.data.data;

                const agency_branding = structureBrandData(agency);

                commit(SET_BRAND, agency_branding);
            } catch (error) {
                //Set up default brand structure cuz api call failed
                const agency_branding = applyDefaultBuyerBridgeBranding(getEmptyBrand());

                commit(SET_BRAND, agency_branding);
            }
        },
        async updateBrand({ dispatch }) {
            //Check the user vuex to see if you are logged in
            const loggedIn = user.state.isLoggedIn;

            //If you are authenticated use the localStorage to get the agency theme
            if (loggedIn) {

                //Get the agency from local storage
                const agency = JSON.parse(localStorage.getItem(AGENCY_STORAGE_KEY));
                dispatch('setBrandFromAgency', agency);

            //If you are not authenticated send the url back to the backend to find the correct theme to display
            } else {
                const domain = window.location.hostname;
                dispatch('setBrandFromPublicAgencyEndpoint', domain);
            }
        }
    },
    mutations: {
        [SET_BRAND](state, brand) {
            let brandWithDefaults = applyDefaultBuyerBridgeBranding(brand);

            // Manipulate local storage for continuous use
            localStorage.setItem(BRAND_STORAGE_KEY, JSON.stringify(brandWithDefaults));

            state.name = brandWithDefaults.name;
            state.primary_domain = brandWithDefaults.primary_domain;
            state.asset_urls = brandWithDefaults.asset_urls;
            state.theme = brandWithDefaults.theme;
        }
    }
};
