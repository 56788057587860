/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-sort': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" _fill="#fff" fill-opacity=".01" d="M0 0h48v48H0z"/><path pid="1" d="M24 6v36M12 18L24 6l12 12" _stroke="#6D797F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
