import cache from '@/apis/cache';
import HTTP from '@/http';
import moment from 'moment';
import { groupBy } from 'lodash';

const ignoreKeys = [
    'action_type',
    'date_start',
    'date_stop',
    'product_id'
];

/**
 * Sums an array of objects with equivalent values together
 * and supports properties with child arrays that need to be summed
 * this is specifically designed for Facebook metrics
 *
 * @param {Array} arr
 * @returns {Object}
 */
const sumArray = (arr) => {
    const res = {};

    const arrayMetrics = {};

    for (let i = 0; i < arr.length; i++) {
        Object.keys(arr[i])
            .filter(key => !ignoreKeys.includes(key))
            .forEach(key => {

                // Track arrays so we can handle these differently
                if (arr[i][key] instanceof Array) {
                    arrayMetrics[key] = [
                        ...(arrayMetrics[key] || []),
                        ...arr[i][key]
                    ];
                } else {
                    res[key] = (res[key] || 0) + (arr[i][key] ? parseFloat(arr[i][key]) : 0);
                }
            });
    }

    Object.keys(arrayMetrics).forEach(metric => {
        const grouped = groupBy(arrayMetrics[metric], 'action_type');

        res[metric] = Object.keys(grouped).map(key => {
            return sumArray(grouped[key]);
        });
    });

    // Use the first item to replace the keys we dropped
    const firstItem = arr[0];
    if (firstItem) {
        Object.keys(firstItem)
            .filter(key => ignoreKeys.includes(key))
            .forEach(key => {
                res[key] = firstItem[key];
            });
    }

    return res;
};

/**
 * Retrieves inventory level insights from the Facebook API
 *
 * @param {Object} config
 * @returns {Array}
 */
async function getDealerInventoryInsights(config) {

    const cacheKey = `dealer_inventory_${JSON.stringify(config)}`;

    const cacheEntry = cache.get(cacheKey);

    if (cacheEntry) {
        return cacheEntry;
    }

    // Set defaults
    config = {
        ...{
            dealerId: null,
            startDate: null,
            endDate: null,
            attributionWindows: []
        },
        ...config
    };

    // List out Facebook fields to retrieve for dev usability
    const fields = [
        'actions',
        'clicks',
        'spend',
        'reach',
        'impressions',
        'outbound_clicks'
    ];
    const params = {
        fields: fields.join(','),
        breakdowns: 'product_id',
        time_range: {
            since: moment(config.startDate).format('YYYY-MM-DD'), // start date as yyyy-mm-dd
            until: moment(config.endDate).format('YYYY-MM-DD') // end date as yyyy-mm-dd
        },
        action_attribution_windows: config.attributionWindows,
        limit: 500
    };

    if (config.campaigns.length) {
        params.filtering = [{
            field: 'campaign.id',
            operator: 'IN',
            value: config.campaigns // selected campaign ids
        }];
    }

    const response = await (new HTTP()).get(`/dealers/${config.dealerId}/graph-api/ad-account/insights`, params);

    const data = response.data || [];

    // Facebook will send us multiple nodes for the same vehicle
    // we think this is a result of running multiple catalogs but
    // aren't 100% sure.  In any case, we fixed it 😅
    const groupedVehicles = groupBy(data, 'product_id');

    const summedStats = Object.values(groupedVehicles).map(sumArray);

    cache.set(cacheKey, summedStats);

    return summedStats;
}

export default getDealerInventoryInsights;
