<template>
    <div
        ref="wholeArea"
        class="whole-area">
        <div class="drop-visual" />
        <div
            ref="dropField"
            class="drop__field">
            <div
                ref="dropArea"
                class="drop__land">
                <div
                    ref="dropIcon"
                    class="drop__icon-land">
                    <icon
                        name="cloud"
                        color="white"
                        width="45"
                        height="45" />
                </div>
                <div class="drop__title-land">
                    Drop here
                </div>
                <div
                    ref="dropInfo"
                    class="drop__info-land">
                    Supported BuyerBridge file formats:<br>
                    Images: {{ imageExtensions.join(', ') }}.
                    <span class="drop__slash">|</span> Max. size: 500MB
                </div>
            </div>
        </div>
        <asset-library-header-layout
            :is-wrapped="selectedAssetToShowDetails !== null"
            :asset-type="assetType"
            @search-asset="handleAssetSearch" />
        <div
            class="interface">
            <div
                :class="[
                    selectedAssetToShowDetails !== null
                        ? 'page-content-details-opened'
                        : 'page-content-details-no-opened',
                ]">
                <div
                    :class="[
                        selectedAssetToShowDetails !== null ? 'results-opened' : '',
                        'results',
                    ]">
                    <div>
                        <asset-library-tabs
                            :current-media-type="currentMediaType"
                            @set-media-type="setMediaType" />
                    </div>
                    <div class="d-flex">
                        <div class="result-amount">
                            Results: {{ total }}
                        </div>
                        <div class="sort">
                            Sort:
                            <styled-menu
                                :custom-content-class="'styled-menu-content layered-designer-quick-insert-menu'">
                                <template #activator="{ on }">
                                    <span
                                        v-if="sort"
                                        class="link"
                                        v-on="on"> {{ sort }} </span>
                                    <v-icon
                                        small
                                        v-on="on">
                                        arrow_drop_down
                                    </v-icon>
                                </template>
                                <div
                                    v-for="(option, index) in sortItems"
                                    :key="`option_${index}`"
                                    class="option quick-insert-inner-option"
                                    @click="handleSort(option)">
                                    {{ option }}
                                </div>
                            </styled-menu>
                        </div>
                    </div>
                </div>

                <div
                    class="assets-results-wrapper">
                    <assets-results
                        :loading="loading"
                        :insert-mode="isInsertMode"
                        :assets="assets"
                        :selected-assets="selectedAssets"
                        @asset-clicked="handleAssetClicked"
                        @unselect-asset="removeAssetFromSelected"
                        @page-bottom-reached="findAssets" />
                </div>
            </div>
            <asset-tree-view-sidebar
                ref="asset-tree-view-sidebar"
                :is-upload-active="isUploadActive"
                @new-asset-uploaded="handleNewAssetUploaded"
                @tags-selected="handleFilterBytags"
                @error-on-upload="handleErrorOnUpload" />
            <assets-detail-side-bar
                :opened="selectedAssetToShowDetails !== null"
                :selected-item="selectedAssetToShowDetails"
                :asset-type="assetType"
                @cancel-preview="handleCancelPreview"
                @insert-selected-assets="handleInsertSelectedAssets" />

            <assets-insert-control
                v-if="isInsertMode"
                :selected-assets="selectedAssets"
                @close-asset-library="handleCloseAssetLibrary"
                @remove-selected-asset="handleRemoveSelectedAsset"
                @insert-selected-assets="handleInsertSelectedAssets" />
        </div>
    </div>
</template>

<script>
import AssetsResults from './AssetsResults';
import AssetsDetailSideBar from './assets-details/AssetsDetailSideBar';
import AssetLibraryHeaderLayout from './assets-library-header/AssetLibraryHeaderLayout';
import AssetsInsertControl from './AssetsInsertControl';
import AssetTreeViewSidebar from './assets-tree-view/AssetTreeViewSidebar';
import { AssetServiceFactory } from '@/creative-studio/domain/assets/services/AssetServiceFactory';
import { AssetLibraryMode } from '@/components/creative-studio/common/types';
import { AssetType } from '@/creative-studio/domain/assets/models/types';
import { AssetMapper } from '@/creative-studio/domain/assets/services/api/mappers/AssetMapper';
import StyledMenu from '@/components/globals/StyledMenu';
import { mapActions, mapState, mapGetters } from 'vuex';
import { ASSETS_SORTABLE_FIELDS } from '@/components/creative-studio/common/constants';
import { debounce } from 'lodash';
import AssetLibraryTabs from './AssetLibraryTabs.vue';
import EventBus from '@/event-bus';
import Icon from '@/components/globals/Icon';

export default {
  components: {
    AssetsResults,
    AssetsDetailSideBar,
    AssetLibraryHeaderLayout,
    AssetsInsertControl,
    StyledMenu,
    AssetLibraryTabs,
    AssetTreeViewSidebar,
    Icon
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: AssetLibraryMode.Default,
    },
    assetType: {
      type: String,
      required: false,
      default: AssetType.Image,
    },
  },
  data() {
    return {
      total: 0,
      assets: [],
      sortOptions: ASSETS_SORTABLE_FIELDS,
      selectedSort: null,
      tabs: [],
      tabsKeys: {
        all: 0,
        upload: 1,
      },
      selectedAssets: [],
      selectedAssetToShowDetails: null,
      loading: false,
      currentMediaType: 'all',
      searchTerm: '',
      sortItems: ['Newest', 'Oldest', 'Last Updated'],
      sort:'Newest',
      page: 1,
      currentAssetType: null,
      tags: [],
      activeTags: [],
      imageExtensions: ['jpg', 'jpeg', 'png'],
      isUploadingDroppedFiles: false,
    };
  },
  computed: {
    ...mapGetters([
        'assetLibraryAssetType',
    ]),
    ...mapState({
            currentAgencyId: (state) => state.agency.currentAgency.id,
    }),
    isInsertMode() {
      return this.mode === AssetLibraryMode.Insert;
    },
    isUploadActive() {
      return this.currentMediaType === AssetType.Image;
    },
  },
  watch: {
    sort() {
      this.changeSorting();
    },
    searchTerm() {
      this.onSearch();
    },

  },
  created() {
    this.tabs = [
      {
        title: 'ALL PHOTOS',
        key: this.tabsKeys.all,
      },
      {
        title: 'UPLOAD PHOTO',
        key: this.tabsKeys.upload,
      },
    ];
  },
  mounted() {
    this.setMediaType(this.assetLibraryAssetType);
    this.findAssets();
    this.handleDropping();
  },
  methods: {
    ...mapActions(['insertSelectedAssetsAsLayers']),
    setMediaType(mediaType) {
        this.currentMediaType = mediaType;
        this.reset();
        this.findAssets();
    },
    onSearch: debounce(function() {
        this.reset();
        // adding relevance when we have something in search field
        if (this.searchTerm != '') {
            this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Relevance'];
            this.sort = 'Relevance';
        } else {
            this.sortItems = ['Newest', 'Oldest', 'Last Updated'];
            this.sort = 'Newest';
        }
        this.findAssets();
    }, 500),
    reset() {
        this.page = 1;
        this.assets = [];
    },
    handleSort(selectedSort) {
       this.sort = selectedSort;
    },
    changeSorting() {
            this.reset();
            this.findAssets();
        },
    clearAllFilters() {
            this.searchTerm = '';
            this.sortItems = ['Newest', 'Oldest', 'Last Updated'];
            this.sort = 'Newest';
            this.page = 1;
            this.assets = [];
    },
     findAssets: debounce(async function() {
        if (!this.page) {
            return;
        }
      this.loading = true;
      const assetService = AssetServiceFactory.createApiAssetService();

      // taken from ads library assets tab
      let assetsToShow = [1,3,4];

        if (this.currentMediaType == AssetType.Image) {
            assetsToShow = [1];
        }
        if (this.currentMediaType == AssetType.Shape) {
            assetsToShow = [3];
        }
        if (this.currentMediaType == AssetType.Text) {
            assetsToShow = [4];
        }

        // sorting by tags
        const tagData = {};
        this.tags.forEach(tagGroup => {
            const tagGroupName = {
                [tagGroup.name]: []
            };
            Object.assign(tagData, tagGroupName);
        });
        this.activeTags.forEach(tag => {
            const tagGroupName = this.tags.find(t => t.id == tag.tag_group_id);
            tagData[tagGroupName.name].push(tag.id);
        });
        // removing empty tags... because back-end is not working with empty request...
        for (let key in tagData) {
            if (tagData[key].length == 0) {
                delete tagData[key];
            }
        }

        let direction = null;
        let dateField = 'created_at.date.keyword';
        if (this.sort == 'Newest') {
                direction = 'DESC';
            } else if (this.sort == 'Oldest') {
                direction = 'ASC';
            } else if (this.sort == 'Last Updated') {
                direction = 'DESC';
                dateField = 'updated_at.date.keyword';
            } else if (this.sort == 'Relevance') {
                direction = 'DESC';
                dateField = '_score';
            }

        try {

        const filter = {
                agency_id: this.currentAgencyId,
                q: this.searchTerm,
                page_size: 100,
                page: this.page,
                asset_types: assetsToShow,
                order: {
                    field: dateField,
                    direction
                },
                tags: tagData
        };

        const response = await assetService.findAssets(filter);
        const responseAssets = response.data;
        this.assets.push(...responseAssets);

        this.total = response.meta.total;
        this.page = response.meta?.next_page;
        } catch (error) {
            console.error('Error finding assets', error);
        } finally {
            this.loading = false;

        }
    }, 100),
    handleAssetClicked(selectedAsset) {
      if (!this.isInsertMode) {
        return;
      }
      this.selectedAssetToShowDetails = selectedAsset;
      this.addAssetToSelected(selectedAsset);
    },
    handleFilterBytags({ activeTags, tags }) {
      this.activeTags = activeTags;
      this.tags = tags;
      this.reset();
      this.findAssets();
    },
    removeAssetFromSelected(selectedAssetIndex) {
      if (selectedAssetIndex !== -1) {
        this.selectedAssets.splice(selectedAssetIndex, 1);
      }
    },
    addAssetToSelected(selectedAsset) {
      if (this.assetIsInserted(selectedAsset)) {
        return;
      }
      this.selectedAssets.push(selectedAsset);
    },
    handleInsertSelectedAssets() {
      this.insertSelectedAssetsAsLayers(this.selectedAssets);
      this.clearAssetsSelection();
      this.$emit('selection-finished');
    },
    clearAssetsSelection() {
      this.selectedAssets = [];
    },
    handleCancelPreview() {
      this.selectedAssetToShowDetails = null;
    },
    handleAssetSearch(searchText) {
        this.searchTerm = searchText;
    },
    handleRemoveSelectedAsset(selectedAsset) {
      const selectedAssetIndex = this.selectedAssets.findIndex(
        (asset) => asset.id === selectedAsset.id
      );
      if (selectedAssetIndex === -1) {
        return;
      }
      if (this.selectedAssetToShowDetails?.id === selectedAsset.id) {
        this.handleCancelPreview();
      }
      this.removeAssetFromSelected(selectedAssetIndex);
    },
    assetIsInserted(assetToFind) {
      return (
        this.selectedAssets.findIndex(
          (selectedAsset) => selectedAsset.id === assetToFind.id
        ) !== -1
      );
    },
    async handleNewAssetUploaded(uploadedAssets) {
        uploadedAssets.map(uploadedAsset => {
            const mappedAssed = AssetMapper.toDomain(uploadedAsset, { loadFromRemote: true });
            this.assets.unshift(mappedAssed);
            this.total++;
            this.selectedAssetToShowDetails = mappedAssed;
            this.handleAssetClicked(mappedAssed);
            EventBus.$emit('new-asset-upload', mappedAssed);
        });
        this.isUploadingDroppedFiles = false;

    },
    handleCloseAssetLibrary() {
      this.clearAssetsSelection();
      this.$emit('selection-finished');
    },
    handleDropping() {
        const wholeArea = this.$refs.wholeArea;
        const dropField = this.$refs.dropField;
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            wholeArea.addEventListener(eventName, this.preventDefaults, false);
            dropField.addEventListener(eventName, this.preventDefaults, false);
        });
        ['dragenter', 'dragover'].forEach(eventName => {
            wholeArea.addEventListener(eventName, this.highlight, false);
        });
        ['dragleave', 'drop'].forEach(eventName => {
            dropField.addEventListener(eventName, this.unhighlight, false);
        });
    },
    preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        },
    highlight() {
        if(!this.isUploadingDroppedFiles) {
            this.$refs.wholeArea.classList.add('highlight');
        }

    },
    unhighlight(e) {
            const icon = this.$refs.dropIcon;
            const info = this.$refs.dropInfo;

            // if not dropping or leaving
            if (e.type == 'dragleave' && e.x == 0 && e.y == 0) {
                this.$refs.wholeArea.classList.remove('highlight');
            }
            else if (e.type == 'dragleave' && e.target.parentElement == this.$refs.dropArea) return;
            else if (e.type == 'dragleave' && e.target.parentElement == this.$refs.dropField) return;
            else if (e.type == 'dragleave' && e.target.parentElement == icon) return;
            else if (e.type == 'dragleave' && e.target.parentElement == info) return;
            else if (e.type == 'dragleave' && e.target.parentElement.tagName == 'svg') return;
            else {
                this.$refs.wholeArea.classList.remove('highlight');
            }
            // if dropping
            if (e.type == 'drop' && !this.isUploadingDroppedFiles) {
                this.handleFiles(e);
            }
    },
    handleFiles(e) {
        this.isUploadingDroppedFiles = true;
       this.$refs['asset-tree-view-sidebar'].handleUpload(e);
    },
    handleErrorOnUpload() {
        this.isUploadingDroppedFiles = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-box-wrapper {
  background-color: $white;
  height: 500px;
}
.interface {
  background-color: #f7f8fc;
}
.page-content {
  width: 100%;
  height: 100%;
  padding: 120px 0px 0px 30px;
  text-align: center;
  background-color: $greyLight;
}
.page-content-details-opened {
  padding-right: 270px;
  transition: padding-right 0.5s;
}
.page-content-details-no-opened {
  padding-right: 0px;
  transition: padding-right 0.5s;
}
.results {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-size: 11px;
    padding-left: 330px;
    margin-top: 100px;
    @media only screen and (max-height: 768px) {
        margin-top: 30px;
    }
}
.assets-results-wrapper {
    z-index: 6 !important;
    padding-left: 315px;
}
.results-opened {
    padding-left: calc(#{$asset-library-modal-margin} + 310px);
}
.result-amount {
  color: #777777;
  margin-right: 10px;
}
i.v-icon.v-icon {
  color: $blue-btn !important;
  cursor: pointer;
}
.option {
  cursor: pointer;
}

.tabs-navigation {
    background-color: transparent !important;
}


.as-main{
    display: flex;
}
.whole-area{

    &.highlight{
        .drop__field{
           display: block;
        }
    }
    @media only screen and (max-height: 768px) {
       align-items: flex-start;
       overflow: auto;
       padding-top: 70px;
    }
}
.drop{
    @media only screen and (max-height: 768px) {
       min-height: 700px;
    }
    &__title{
        font-size: 30px;
        margin-bottom: 30px;
        color: #03A2EA;
    }
    &__desc{
      max-width: 700px;
      text-align: left;
      margin: auto auto 30px;
    }
    &__box{
        border: 3px dashed $gray-light;
        border-radius: 10px;
        padding: 50px;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 30px;
        #upload{
           display: none;
        }
        label{
            display: inline-block;
            padding: 8px 15px;
            background: #03A2EA;
            font-weight: bold;
            color: white;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    &__oops{
        color: #FF3C00;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    &__error{
        margin-bottom: 45px;
        color: #FF3C00;
        font-size: 12px;
    }
    &__uploading{
        font-weight: bold;
        margin-bottom: 15px;
    }
    &__circle{
        position: relative;
    }
    &__percentage{
        font-size: 36px;
        font-weight: bold;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__icondesc{
        font-size: 16px;
    }
    &__or{
        margin-bottom: 30px;
        margin-top: 30px;
        font-size: 16px;
    }
    &__slash{
        margin-left: 15px;
        margin-right: 15px;
        display: inline-block;
        margin-top: 5px;
    }
    &__field{
        display: none;
        position: fixed;
        z-index: 9999;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: rgba(0,0,0,0.75);
        backdrop-filter: blur(6px);
    }
    &__land{
        position: absolute;
        top:10%;
        left:10%;
        bottom: 10%;
        right: 10%;
        border: 3px dashed #A8A8A8;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &__title-land{
        color: white;
        font-size: 16px;
        margin-bottom: 45px;
    }
    &__info-land{
        color: white;
    }
}
</style>