import { cloneDeep } from 'lodash';
class UndoRedoHistory {
    layeredDesignerState;
    store;
    history = [];
    currentIndex = -1;


    get changeHistory() {
        return this.history;
    }

    init(state) {
      this.layeredDesignerState = state;
    }

    setStore(store) {
        this.store= store;
    }

    addState(state, mutation) {
      // Remove redo steps if change is detected in an internal position
            if (this.currentIndex + 1 < this.history.length && mutation) {
                this.history.splice(this.currentIndex + 1);
            }
            this.history.push({
                state,
                mutation
            });
            this.currentIndex++;
    }

    navigateInHistory(index) {
        const state = this.history[index === 0 ? index : index -1]?.state;
        if(!state) {
            return;
        }
        const layeredDesignerPrevState = cloneDeep(state);
        this.store.replaceState({
            ...this.store.state,
            layeredDesigner: layeredDesignerPrevState
          });

          this.currentIndex = index -1;
    }


    undo() {
      const prevState = this.history[this.currentIndex - 1]?.state;
        if(!prevState) {
        return;
      }
      if(this.currentIndex === 1) {
        return;
      }
      // take a copy of the history state
      // because it would be changed during store mutations
      // what would corrupt the undo-redo-history
      // (same on redo);
      const layeredDesignerPrevState = cloneDeep(prevState);
      this.store.replaceState({
        ...this.store.state,
        layeredDesigner: layeredDesignerPrevState
      });
      this.currentIndex--;
    }

    redo() {
      const nextState = this.history[this.currentIndex + 1]?.state;
      if(!nextState) {
        return;
      }
      const layeredDesignerNextState = cloneDeep(nextState);
      this.store.replaceState(cloneDeep({
        ...this.store.state,
        layeredDesigner: layeredDesignerNextState
      }));
      this.currentIndex++;
    }
  }

  const undoRedoHistory = new UndoRedoHistory();

  export default undoRedoHistory;
