import {
    SET_BLUR,
    SET_COLOR,
    SET_ID,
    SET_LAYERED_DESIGNER_ACTIVE_STATUS,
    SET_LAYERED_DESIGNER_CANVAS_ZOOM,
    SET_LAYERED_DESIGNER_RECOMMENDED_SCALE,
    SET_LAYERED_DESIGNER_CARDS,
    SET_LAYERED_DESIGNER_CURRENT_CARD,
    SET_LAYERED_DESIGNER_CARD_LAYERS,
    SET_LAYERED_DESIGNER_CURRENT_CARD_DIMENSIONS,
    SET_LAYERED_DESIGNER_CURRENT_CARD_NAME,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_DIMENSIONS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ROTATE_ANGLE,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHORED_POSITIONS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHOR_POSITION,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS,
    SET_LAYERED_DESIGNER_CURRENT_CARD_THUMBNAIL,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_SHADOW,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_IS_CLIPPING,
    SET_LAYERED_DESIGNER_ID,
    SET_LAYERED_DESIGNER_LOADING_STATUS,
    SET_LAYERED_DESIGNER_FONT_CSS,
    SET_LAYERED_DESIGNER_FONT_MANAGER,
    SET_LAYERED_DESIGNER_DOWNLOADED_FONTS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ORIGINAL_DIMENSIONS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_SCALE,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_NAME,
    SET_LAYERED_DESIGNER_TEXT_EDITOR_VISIBLE,
    SET_LAYERED_DESIGNER_MODE,
    SET_LAYERED_DESIGNER_WORKSPACE_OVERFLOW
} from '@/store/mutation-types';

import { uniqueId, clone, cloneDeep } from 'lodash';

import { Card } from '@/creative-studio/domain/layered-designer/models/Card';
import { Layer, FLIP_DIRECTIONS, LAYER_COLOR_MODES, LAYERED_DESIGNER_MODE, LAYERED_DESIGNER_OVERFLOW } from '@/creative-studio/domain/layered-designer/models/Layer';

import { CARD_REARRANGE_DIRECTIONS, CARD_NAVIGATE_DIRECTIONS } from '@/components/creative-studio/common/constants';
import { CardMapper } from '@/creative-studio/domain/layered-designer/services/api/mappers/CardMapper';

const createDefaultEmptyCard = ({ cardName = '' } = {}) => {
    const uniqueNumberId = parseInt(uniqueId());
    const defaultCard = new Card(uniqueNumberId);

    defaultCard.name = cardName ? cardName : 'test card ' + defaultCard.id;
    return defaultCard;
};

const createDefaultEmptyLayer = () => {
    const uniqueNumberId = parseInt(uniqueId());
    const defaultLayer = new Layer(uniqueNumberId);
    return defaultLayer;
};

export default {
    state: {
        isLayeredDesignerActive: false,
        layeredDesignerId: null,
        cards: [],
        currentCard: null,
        focusedLayer: null,
        canvasZoom: 0.5,
        recommendedScale: 0.50,
        isLoading: false,
        fontEmbedCSS: '',
        fontManager: {},
        downloadedFonts: [],
        isTextEditorVisible: false,
        layeredDesignerMode: LAYERED_DESIGNER_MODE.DEFAULT,
        layeredDesignerOverflow: LAYERED_DESIGNER_OVERFLOW.HIDDEN
    },
    getters: {
        blur: (state) => state.blur,
        color: (state) => state.color,
        isLayeredDesignerActive: (state) => state.isLayeredDesignerActive,
        layeredDesignerId: (state) => state.layeredDesignerId,
        layeredDesignerCards: (state) => state.cards,
        currentCard: (state) => state.currentCard,
        currentCardLayers: (state) => state.currentCard?.layers || [],
        focusedLayer: (state) => state.focusedLayer,
        canvasZoom: (state) => state.canvasZoom,
        recommendedScale: (state) => state.recommendedScale,
        isLoading: (state) => state.isLoading,
        fontEmbedCSS: (state) => state.fontEmbedCSS,
        fontManager: (state) => state.fontManager,
        downloadedFonts: (state) => state.downloadedFonts,
        isTextEditorVisible: (state) => state.isTextEditorVisible,
        layeredDesignerMode: (state) => state.layeredDesignerMode,
        layeredDesignerOverflow: (state) => state.layeredDesignerOverflow
    },
    actions: {
        updateBlur({ commit }, newValue) {
            commit(SET_BLUR, newValue);
          },
          updateColor({ commit }, newValue) {
            commit(SET_COLOR, newValue);
          },
          updateId({ commit }, newValue) {
            commit(SET_ID, newValue);
          },
        resetLayeredDesigner({ commit, dispatch }) {
           dispatch('setTextEditorVisible', false);
           dispatch('setCanvasZoom', 0.5);
           dispatch('setLayeredDesignerWorkspaceOverflow', LAYERED_DESIGNER_OVERFLOW.HIDDEN);
           commit(SET_LAYERED_DESIGNER_CARDS, []);
        },
        //Layered designer actions
        setLayeredDesignerActiveStatus({ commit }, { isActive, mode }) {
            // If layered designer is active, set the mode
            if(isActive) {
                commit(SET_LAYERED_DESIGNER_MODE, mode);
            } else {
                // If layered designer is not active, reset the mode
                commit(SET_LAYERED_DESIGNER_MODE, LAYERED_DESIGNER_MODE.DEFAULT);
            }

            commit(SET_LAYERED_DESIGNER_ACTIVE_STATUS, isActive);
        },
        setTextEditorVisible({ commit }, isVisible) {
            commit(SET_LAYERED_DESIGNER_TEXT_EDITOR_VISIBLE, isVisible);

        },
        setCanvasZoom({ commit }, canvasZoom) {
            commit(SET_LAYERED_DESIGNER_CANVAS_ZOOM, canvasZoom);
        },
        setRecommendedScale({ commit }, recommendedScale) {
            // When recommended scale is set, update the zoom
            commit(SET_LAYERED_DESIGNER_CANVAS_ZOOM, recommendedScale * 100);
            // Then update the scale
            commit(SET_LAYERED_DESIGNER_RECOMMENDED_SCALE, recommendedScale);
        },
        getExportableLayeredDesignerData({ state }) {
            return {
                layeredDesignerId: state.layeredDesignerId,
                cards: state.cards
            };
        },
        loadLayeredDesigner({ state, dispatch, commit }, jsonData ) {

            const { layeredDesignerId, cards: cardsResources } = jsonData;

            const cards = cardsResources.map(CardMapper.toDomain);

            if ( layeredDesignerId !== state.layeredDesignerId ) {
                dispatch('setLayeredDesignerId', layeredDesignerId);
            }

            commit(SET_LAYERED_DESIGNER_CARDS, cards);
            dispatch('setCurrentCard', cards[0]);
        },
        setLayeredDesignerId({ commit }, layeredDesignerId) {
            commit(SET_LAYERED_DESIGNER_ID, layeredDesignerId);
        },
        setLayeredDesignerLoadingStatus({ commit }, status) {
            commit(SET_LAYERED_DESIGNER_LOADING_STATUS, status);
        },
        setLayeredDesignerEmbededFonts({ commit }, fontEmbedCSS) {
            commit(SET_LAYERED_DESIGNER_FONT_CSS, fontEmbedCSS);
        },
        setLayeredDesignerFontManager({ commit }, fontManager) {
            commit(SET_LAYERED_DESIGNER_FONT_MANAGER, fontManager);
        },
        addDownloadedFont({ state, commit }, fontFamily) {
            const currentDownloadedFonts = state.downloadedFonts;
            if (currentDownloadedFonts.includes(fontFamily)) {
                return;
            }
            currentDownloadedFonts.push(fontFamily);
            commit(SET_LAYERED_DESIGNER_DOWNLOADED_FONTS, currentDownloadedFonts);
        },
        // Cards actions
        createNewCard({ state, commit }, cardName) {
            const currentCards = state.cards;
            const newCard = createDefaultEmptyCard({ cardName });

            currentCards.push(newCard);

            commit(SET_LAYERED_DESIGNER_CARDS, currentCards);
        },
        insertSelectedAssetsAsLayers({ state, dispatch }, selectedAssets) {
            let currentCard = state.currentCard;
            if (currentCard === null) {
                dispatch('setDefaultCurrentCard');
                currentCard = state.currentCard;
            }
            selectedAssets.forEach(selectedAsset => {
                dispatch('addAssetAsLayer', selectedAsset);
            });
        },
        setDefaultCurrentCard({ state, dispatch }, cardName = '') {
            if (state.cards.length === 0) {
                dispatch('createNewCard', cardName);
            }
            dispatch('setCurrentCard', state.cards[state.cards.length-1]);
        },
        setCurrentCard({ commit, dispatch }, selectedCard) {
            commit(SET_LAYERED_DESIGNER_CURRENT_CARD, selectedCard);
            dispatch('syncFocusedLayerWithCurrentCard');
        },
        deleteCurrentCard({ state, commit, dispatch }) {
            if (state.currentCard === null) {
                return;
            }

            const currentCards = state.cards;
            const currentCardIndex = currentCards.findIndex((card) => card.id === state.currentCard.id);

            if (currentCardIndex === -1 || currentCards.length === 1 ) {
                return;
            }

            currentCards.splice(currentCardIndex, 1);

            commit(SET_LAYERED_DESIGNER_CARDS, currentCards);
            dispatch('setCurrentCard', null);
            dispatch('setDefaultCurrentCard');
        },
        updateCurrentCardDimensions({ state, commit, dispatch },{ id, height, width, aspectRatio, linkedStatus }) {
            if (state.currentCard === null) {
                return;
            }
            const cardOldDimensions = clone(state.currentCard.dimensions);
            const cardNewDimensions = clone(state.currentCard.dimensions);
            cardNewDimensions.id = id;
            cardNewDimensions.height = height;
            cardNewDimensions.width = width;
            cardNewDimensions.aspectRatio = aspectRatio;
            cardNewDimensions.linkedStatus = linkedStatus;

            commit(SET_LAYERED_DESIGNER_CURRENT_CARD_DIMENSIONS, cardNewDimensions);
            dispatch('syncLayersWithCardResize', { cardOldDimensions, cardNewDimensions });
        },
        setCurrentCardName({ state, commit }, name) {
            if (state.currentCard === null) {
                return;
            }

            commit(SET_LAYERED_DESIGNER_CURRENT_CARD_NAME, name);
        },
        setCurrentCardThumbnail({ state, commit }, thumbnail) {
            if (state.currentCard === null) {
                return;
            }

            commit(SET_LAYERED_DESIGNER_CURRENT_CARD_THUMBNAIL, thumbnail);
        },
        duplicateCurrentCard({ state, commit }) {
            if (state.currentCard === null) {
                return;
            }

            const currentCards = state.cards;

            const newCard = cloneDeep(state.currentCard);
            newCard.id = parseInt(uniqueId());

            let newName = newCard.name;
            currentCards.forEach(card => {
                if (card.name === newName) {
                    newName+= ' (copy)';
                }
            });
            newCard.name = newName;

            const newLayers = newCard.layers.map(layer => {
                layer.id = parseInt(uniqueId());
                return layer;
            });

            newCard.layers = newLayers;

            currentCards.push(newCard);

            commit(SET_LAYERED_DESIGNER_CARDS, currentCards);
        },
        rearrangeCurrentCard({ state, commit }, rearrangeDirection) {
            if (state.currentCard === null) {
                return;
            }

            const currentCard = state.currentCard;
            const currentCards = state.cards;

            const currentCardIndex = currentCards.findIndex((card) => card.id === currentCard.id);
            if (currentCardIndex === -1 ) {
                return;
            }

            let changePosition = 0;

            if (rearrangeDirection === CARD_REARRANGE_DIRECTIONS.UP) {
                changePosition = -1;
            } else if (rearrangeDirection === CARD_REARRANGE_DIRECTIONS.DOWN) {
                changePosition = 1;
            }

            const newCardIndex = currentCardIndex + changePosition;

            if (newCardIndex < 0 || newCardIndex > currentCard.length - 1) {
                return;
            }

            currentCards.splice(currentCardIndex, 1);
            currentCards.splice(newCardIndex, 0, currentCard);

            commit(SET_LAYERED_DESIGNER_CARDS, currentCards);
        },
        navigateBetweenCards({ state, commit, dispatch }, navigateDirections) {
            if (state.currentCard === null) {
                return;
            }
            const currentCards = state.cards;
            const selectedCard = state.currentCard;
            const currentCardIndex = currentCards.findIndex(card => {
                return card.id === selectedCard.id;
              });

            const newSelectedCard = navigateDirections === CARD_NAVIGATE_DIRECTIONS.UP
            ? currentCards[currentCardIndex + 1]
            : currentCards[currentCardIndex - 1];

            if(!newSelectedCard) {
                return;
            }

            commit(SET_LAYERED_DESIGNER_CURRENT_CARD, newSelectedCard);
            dispatch('syncFocusedLayerWithCurrentCard');

        },
        // Layers actions
        addAssetAsLayer({ state, dispatch }, asset) {
            const currentLayers = state.currentCard.layers;
            const newLayer = createDefaultEmptyLayer();

            newLayer.asset = asset;
            newLayer.index = currentLayers.length + 1;
            newLayer.setInitialPositionAndDimensions(state.currentCard);
            currentLayers.push(newLayer);

            dispatch('setCurrentCardLayers', currentLayers);

        },
        setCurrentCardLayers({ state, commit }, layers) {
            if (state.currentCard === null) {
                return;
            }
            commit(SET_LAYERED_DESIGNER_CARD_LAYERS, layers);
        },
        setFocusedLayerById({ getters, dispatch }, layerId) {
            const currentLayers = getters.currentCardLayers;
            const layerIndex = currentLayers.findIndex((layer) => layer.id === layerId);
            if (layerIndex === -1) {
                return;
            }
            dispatch('setFocusedLayer', currentLayers[layerIndex]);
        },
        setFocusedLayer({ getters, commit }, layer) {
            if (getters.focusedLayer?.id === layer?.id) {
                return;
            }
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER, layer);
        },
        updateTextLayer({ state, commit }, { text, fontFamily, fontSize, fillColor, weight, fontStyle, editableFields, textAlign, content }) {

            if(!state.focusedLayer?.asset) {
                return;
            }

            const textAsset = clone(state.focusedLayer?.asset);

            if (text !== undefined) {
                textAsset.text = text;
            }

            if (fontFamily) {
                textAsset.fontFamily = fontFamily;
            }

            if (fontSize) {
                textAsset.fontSize = fontSize;
            }

            if (fillColor) {
                textAsset.fillColor = fillColor;
                commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE, LAYER_COLOR_MODES.SOLID);
            }

            if(weight) {
                textAsset.weight = weight;
            }

            if(fontStyle) {
                textAsset.fontStyle = fontStyle;
            }

            if(editableFields) {
                textAsset.editableFields = editableFields;
            }

            if(content) {
                textAsset.content = content;
            }

            if(textAlign) {
                textAsset.textAlign = textAlign;
            }

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET, textAsset);
        },
        syncFocusedLayerWithCurrentCard({ state, commit }) {
            if (state.focusedLayer === null) {
                return;
            }
            if (state.currentCard === null || state.currentCard.layers.length === 0 ) {
                commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER, null);
                return;
            }

            const focusedLayerIndex = state.currentCard.layers.findIndex((layer) => layer.id === state.focusedLayer.id);
            if (focusedLayerIndex === -1) {
                commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER, null);
            }
        },
        updateFocusedLayerPosition({ state, commit }, { top, left }) {
            if (state.focusedLayer === null) {
                return;
            }
            const layerPosition = clone(state.focusedLayer.position);
            layerPosition.top = top;
            layerPosition.left = left;

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION, layerPosition);
        },
        updateFocusedLayerDimensions({ state, commit }, { height, width }) {
            if (state.focusedLayer === null) {
                return;
            }
            const layerDimensions = clone(state.focusedLayer.dimensions);
            layerDimensions.height = height;
            layerDimensions.width = width;

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_DIMENSIONS, layerDimensions);

            if (state.currentCard?.dimensions?.id === state.currentCard?.originalBaseDimensions?.mainCardSizeDimensions?.id) {
                commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ORIGINAL_DIMENSIONS, layerDimensions);
            }
        },
        updateFocusedLayerRotateAngle({ state, commit }, rotateAngle) {
            if (state.focusedLayer === null) {
                return;
            }
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ROTATE_ANGLE, rotateAngle);
        },
        updateFocusedLayerScale({ state, commit }, scale) {
            if (state.focusedLayer === null) {
                return;
            }

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_SCALE, scale);
        },
        updateFocusedLayerAnchorPosition({ state, commit }, anchorPosition) {
            if (state.focusedLayer === null) {
                return;
            }
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHOR_POSITION, anchorPosition);
        },
        updateFocusedLayerAnchoredPositions({ state, commit, dispatch }, position) {
            if (state.focusedLayer === null) {
                return;
            }

            let currentPositions = clone(state.focusedLayer.anchoredPositions);
            const existInPositions = currentPositions.includes(position);

            if(existInPositions) {
                currentPositions = currentPositions.filter(item => item !== position);
            } else {
                currentPositions.push(position);
            }
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHORED_POSITIONS, currentPositions);

            if(state.focusedLayer?.hasOppositesHorizontalAnchors() || state.focusedLayer?.hasOppositesVerticalAnchors()) {
                if(state.focusedLayer.alterations.hasToKeepSize) {
                    dispatch('toggleFocusedLayerFixedSize');
                }
            }
        },
        updateFocusedLayerCropOffset({ state, commit }, { top, left, right, bottom }) {
            if (state.focusedLayer === null) {
                return;
            }
            const layerCropOffset = clone(state.focusedLayer.cropOffset);
            layerCropOffset.top = top;
            layerCropOffset.left = left;
            layerCropOffset.right = right;
            layerCropOffset.bottom = bottom;

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET, layerCropOffset);
        },
        syncLayersWithCardResize({ state, commit }, { cardOldDimensions, cardNewDimensions }) {
            const currentCard = state.currentCard;
            const updatedLayers = currentCard.layers.map((layer) => {
                const updatedLayer = clone(layer);
                updatedLayer.updateLayerPositionAndDimensions(cardOldDimensions, cardNewDimensions, currentCard.originalBaseDimensions.mainCardSizeDimensions);
                return updatedLayer;
            });
            commit(SET_LAYERED_DESIGNER_CARD_LAYERS, updatedLayers);
        },
        deleteLayer({ getters, commit, dispatch }, layer) {
            const currentLayers = [...getters.currentCardLayers];
            const layerIndex = currentLayers.findIndex((actualLayer) => actualLayer.id === layer.id);
            if (layerIndex === -1) {
                return;
            }

            currentLayers.splice(layerIndex, 1);

            if (getters.focusedLayer?.id === layer.id) {
                dispatch('setFocusedLayer', null);
            }
            commit(SET_LAYERED_DESIGNER_CARD_LAYERS, currentLayers);
        },
        toggleFocusedLayerFlip({ state, commit }, direction) {
            if (state.focusedLayer === null) {
                return;
            }
            const layerAlterations = clone(state.focusedLayer.alterations);

            if (direction === FLIP_DIRECTIONS.HORIZONTAL) {
                layerAlterations.isHorizontalFlipped = !layerAlterations.isHorizontalFlipped;
            } else if (direction === FLIP_DIRECTIONS.VERTICAL) {
                layerAlterations.isVerticalFlipped = !layerAlterations.isVerticalFlipped;
            }
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS, layerAlterations);
        },
        toggleFocusedLayerFixedSize({ state, commit }) {
            if (state.focusedLayer === null) {
                return;
            }
            const layerAlterations = clone(state.focusedLayer.alterations);

            layerAlterations.hasToKeepSize = !layerAlterations.hasToKeepSize;

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS, layerAlterations);
        },
        duplicateFocusedLayer({ state, dispatch }) {
            if (state.focusedLayer === null) {
                return;
            }

            const currentLayers = state.currentCard.layers;

            const newLayer = cloneDeep(state.focusedLayer);
            newLayer.id = parseInt(uniqueId());
            newLayer.index = currentLayers.length + 1;

            currentLayers.push(newLayer);

            dispatch('setCurrentCardLayers', currentLayers);
        },
        updateShapeLayer({ state, commit }, { fillColor, strokeColor, strokeWidth }) {
            const shapeAsset = clone(state.focusedLayer.asset);

            if (fillColor) {
                shapeAsset.fillColor = fillColor;
                commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE, LAYER_COLOR_MODES.SOLID);
            }

            if (strokeColor) {
                shapeAsset.strokeColor = strokeColor;
            }

            if (strokeWidth) {
                shapeAsset.strokeWidth = strokeWidth;
            }

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET, shapeAsset);
        },
        updateLayerShadow({ state, commit }, { xOffset, yOffset, blur, color }) {
            if (state.focusedLayer === null) {
                return;
            }

            const layerShadow = clone(state.focusedLayer.shadow);

            if (xOffset != null) {
                layerShadow.xOffset = xOffset;
            }

            if (yOffset != null) {
                layerShadow.yOffset = yOffset;
            }

            if (blur != null) {
                layerShadow.blur = blur;
            }

            if (color != null) {
                layerShadow.color = color;
            }

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_SHADOW, layerShadow);
        },
        setFocusedLayerIsClipping({ state, commit }) {
            if (state.focusedLayer === null) {
                return;
            }
            const layerAlterations = clone(state.focusedLayer.alterations);
            layerAlterations.isClipping = !layerAlterations.isClipping;
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS, layerAlterations);

        },
        setFocusedLayerIsBeingFit({ state, commit }) {
            if (state.focusedLayer === null) {
                return;
            }
            const layerAlterations = clone(state.focusedLayer.alterations);
            layerAlterations.isBeingFit = !layerAlterations.isBeingFit;
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS, layerAlterations);
        },
        setFocusedLayerObjectFit({ state, commit }, objectFit) {
            const layerAlterations = clone(state.focusedLayer.alterations);
            layerAlterations.objectFit = objectFit;
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS, layerAlterations);
        },
        updateLayerGradientColor({ state, commit }, { type, degree, points }) {
            if (state.focusedLayer === null) {
                return;
            }

            const layerGradientColor = clone(state.focusedLayer.colorGradient);

            if (type !== undefined) {
                layerGradientColor.type = type;
            }

            if (degree !== undefined) {
                layerGradientColor.degree = degree;
            }

            if (points !== undefined) {
                layerGradientColor.points = points;
            }

            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR, layerGradientColor);
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE, LAYER_COLOR_MODES.GRADIENT);
        },
        centerLayer({ getters, commit }, layer) {
            const currentLayers = [...getters.currentCardLayers];
            const layerIndex = currentLayers.findIndex((actualLayer) => actualLayer.id === layer.id);
            if (layerIndex === -1) {
                return;
            }

            const currentCard = getters.currentCard;

            const selectedLayer = currentLayers[layerIndex];

            selectedLayer.centerLayerInCard(currentCard);

            currentLayers[layerIndex] = selectedLayer;

            commit(SET_LAYERED_DESIGNER_CARD_LAYERS, currentLayers);
        },
        renameLayer({ state, commit }, layerNewName) {
            if (state.focusedLayer === null) {
                return;
            }
            commit(SET_LAYERED_DESIGNER_FOCUSED_LAYER_NAME, layerNewName);
        },
        replaceFocusedLayerAsset({ dispatch, getters,state }, asset) {
            if(!getters.focusedLayer) {
                return;
            }
            const currentLayers = getters.currentCardLayers;

            // Get current focused layer id
            const focusedLayerId = getters.focusedLayer.id;

            // Get focused layer index between the layers
            const layerIndex = currentLayers.findIndex((layer) => layer.id === focusedLayerId);
            if (layerIndex === -1) {
                return;
            }

            // Replace current focused layer asset
            currentLayers[layerIndex].asset = asset;
            currentLayers[layerIndex].setInitialPositionAndDimensions(state.currentCard);

            dispatch('setCurrentCardLayers', currentLayers);
        },
        setLayeredDesignerWorkspaceOverflow({ commit }, overflow) {
            commit(SET_LAYERED_DESIGNER_WORKSPACE_OVERFLOW, overflow);
        }
    },
    mutations: {
        // eslint-disable-next-line no-unused-vars
        [SET_BLUR](state, status) {
            const focusedLayerIndex = state.currentCard.layers.findIndex((layer) => layer.id === state.focusedLayer.id);
            state.mappedBlur = state.cards[0].layers[focusedLayerIndex].shadow.blur;
        },
         // eslint-disable-next-line no-unused-vars
        [SET_ID](state, status) {
            state.currentId = state.currentCard.layers.findIndex((layer) => layer.id === state.focusedLayer.id);
        },
        // eslint-disable-next-line no-unused-vars
        async [SET_COLOR](state, status) {
            const focusedLayerIndex = state.currentCard.layers.findIndex((layer) => layer.id === state.focusedLayer.id);
            state.mappedColor = state.cards[0].layers[focusedLayerIndex].shadow.color;
           },
        [SET_LAYERED_DESIGNER_ACTIVE_STATUS](state, status) {
            state.isLayeredDesignerActive = status;
        },
        [SET_LAYERED_DESIGNER_MODE](state, mode) {
            state.layeredDesignerMode = mode;
        },
        [SET_LAYERED_DESIGNER_CANVAS_ZOOM](state, canvasZoom) {
            state.canvasZoom = canvasZoom;
        },
        [SET_LAYERED_DESIGNER_TEXT_EDITOR_VISIBLE](state, isTextEditorVisible) {
            state.isTextEditorVisible = isTextEditorVisible;
        },
        [SET_LAYERED_DESIGNER_RECOMMENDED_SCALE](state, recommendedScale) {
            state.recommendedScale = recommendedScale;
        },
        [SET_LAYERED_DESIGNER_CARDS](state, cards) {
            state.cards = cards;
        },
        [SET_LAYERED_DESIGNER_CURRENT_CARD](state, selectedCard) {
            state.currentCard = selectedCard;
        },
        [SET_LAYERED_DESIGNER_CARD_LAYERS](state, layers) {
            state.currentCard.layers = layers;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER](state, layer) {
            state.focusedLayer = layer;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET](state, asset) {
            state.focusedLayer.asset = asset;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION](state, position) {
            state.focusedLayer.position = position;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_NAME](state, name) {
            state.focusedLayer.name = name;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_DIMENSIONS](state, dimensions) {
            state.focusedLayer.dimensions = dimensions;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_ORIGINAL_DIMENSIONS](state, dimensions) {
            state.focusedLayer.originalBaseDimensions.layerOriginalDimensions = { ...dimensions };
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_ROTATE_ANGLE](state, rotateAngle) {
            state.focusedLayer.rotateAngle = rotateAngle;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_SCALE](state, scale) {
            state.focusedLayer.scale = scale;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHOR_POSITION](state, anchorPosition) {
            state.focusedLayer.anchorPosition = anchorPosition;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHORED_POSITIONS](state, anchoredPositions) {
            state.focusedLayer.anchoredPositions = anchoredPositions;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET](state, cropOffset) {
            state.focusedLayer.cropOffset = cropOffset;
        },
        [SET_LAYERED_DESIGNER_CURRENT_CARD_DIMENSIONS](state, dimensions) {
            state.currentCard.dimensions = dimensions;
        },
        [SET_LAYERED_DESIGNER_CURRENT_CARD_NAME](state, name) {
            state.currentCard.name = name;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS](state, alterations) {
            state.focusedLayer.alterations = alterations;
        },
        [SET_LAYERED_DESIGNER_CURRENT_CARD_THUMBNAIL](state, thumbnail) {
            state.currentCard.thumbnail = thumbnail;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_SHADOW](state, shadow) {
            state.focusedLayer.shadow = shadow;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR](state, gradient) {
            state.focusedLayer.colorGradient = gradient;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE](state, colorMode) {
            state.focusedLayer.colorMode = colorMode;
        },
        [SET_LAYERED_DESIGNER_FOCUSED_LAYER_IS_CLIPPING](state, isClipping) {
            state.focusedLayer.isClipping = isClipping;
        },
        [SET_LAYERED_DESIGNER_ID](state, layeredDesignerId) {
            state.layeredDesignerId = layeredDesignerId;
        },
        [SET_LAYERED_DESIGNER_LOADING_STATUS](state, status) {
            state.isLoading = status;
        },
        [SET_LAYERED_DESIGNER_FONT_CSS](state, fontEmbedCSS) {
            state.fontEmbedCSS = fontEmbedCSS;
        },
        [SET_LAYERED_DESIGNER_FONT_MANAGER](state, fontManager) {
            state.fontManager = fontManager;
        },
        [SET_LAYERED_DESIGNER_DOWNLOADED_FONTS](state, downloadedFonts) {
            state.downloadedFonts = downloadedFonts;
        },
        [SET_LAYERED_DESIGNER_WORKSPACE_OVERFLOW](state, overflow) {
            state.layeredDesignerOverflow = overflow;
        },
    }
};
