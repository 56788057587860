/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lead-destination': {
    width: 21,
    height: 19,
    viewBox: '0 0 21 19',
    data: '<g _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M19.947 2.972L17.144.248a.904.904 0 00-1.243 0c-.358.33-.337.867 0 1.218l1.307 1.28H9.2c-2.17 0-3.94 1.733-3.94 3.86 0 2.126 1.77 3.86 3.94 3.86h1.475c1.223 0 2.213.97 2.213 2.167-.021 1.177-1.012 2.147-2.234 2.147H4.86a2.43 2.43 0 00-2.255-1.528c-1.349 0-2.423 1.053-2.423 2.374C.182 16.947 1.256 18 2.605 18a2.43 2.43 0 002.255-1.528h5.794c2.17 0 3.94-1.733 3.94-3.86 0-2.126-1.77-3.86-3.94-3.86H9.18c-1.222 0-2.212-.97-2.212-2.167 0-1.197.99-2.168 2.212-2.168h8.029L15.9 5.697a.86.86 0 000 1.218.89.89 0 00.611.248.853.853 0 00.611-.248l2.803-2.745a.818.818 0 00.253-.599.727.727 0 00-.232-.599z" fill-rule="nonzero"/><path pid="1" d="M15.682 10a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm.551 2H15.13v1.962h-1.948v1.101h1.948V17h1.103v-1.937h1.949v-1.101h-1.949V12z"/></g>'
  }
})
