<template>
    <div>
        <div class="assets-tree-view-sidebar">
            <div class="assets-tree-view-sidebar-header">
                <asset-uploader
                    ref="asset-uploader"
                    view-type="full"
                    @asset-added="handleAssetNewAssetAdded"
                    @error-on-upload="handleErrorOnUpload" />
            </div>

            <div class="sidebar-content">
                <div class="filters">
                    <div class="filters-top">
                        <div class="filters-title">
                            Attributes
                        </div>
                    </div>
                    <div
                        v-for="(tag, index) in tags"
                        :key="index"
                        class="filters-block">
                        <div class="filters-name mb-2">
                            {{ tag.display_name }}
                        </div>
                        <div
                            v-for="item in tag.tags.data"
                            :key="item.id"
                            class="mb-2">
                            <styled-checkbox
                                v-if="item.display_name !== 'Transparent'"
                                :key="checkboxKey"
                                :value="item.id"
                                :label="item.display_name"
                                :checked="setChecked(item)"
                                :inline="true"
                                :square="true"
                                :disabled="loading"
                                @change="selectTag($event)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AssetUploader from  '../AssetUploader.vue';
import StyledCheckbox from '@/components/globals/StyledCheckbox';

export default {
  components: {
    AssetUploader,
    StyledCheckbox
  },
  data() {
      return {
          loading: false,
          tags: [],
          activeTags: [],
          checkboxKey: 0,
          tagsList: [],
      };
  },
  watch: {
    activeTags() {
      this.$emit('tags-selected', {
        activeTags: this.activeTags,
        tags: this.tags
      });
    }
  },
  mounted() {
    this.getTags(2);
    this.getTags(3);
  },
  methods: {
    handleAssetNewAssetAdded(asset) {
      this.$emit('new-asset-uploaded', asset);
    },
    removeTag(tag) {
        const tagIndex = this.activeTags.indexOf(tag);
        if (tagIndex > -1) {
            this.activeTags.splice(tagIndex, 1);
        }
        this.checkboxKey += 1;
    },
    async getTags(tagGroupID) {
            try {
                const response = await this.$http.get(`tag_groups/${tagGroupID}`, { with_relationships: 'tag_groups.tags' });

                const tagsData = [];
                this.tags.push(response.data.data);
                tagsData.push(response.data.data);
                tagsData.forEach(tag => {
                    tag.tags.data.forEach(t => {
                        this.tagsList.push(t);
                    });
                });
            } catch(error) {
                console.log(error);
            }
    },
    setChecked(tag) {
            if (!this.activeTags.includes(tag)) {
                return false;
            } else {
                return true;
            }
    },
    selectTag(e) {
            let currentTag = this.tagsList.find(tag => tag.id == e.target.value);
            if (e.target.checked) {
                this.activeTags.push(currentTag);
            } else {
                this.removeTag(currentTag);
            }
    },
    handleUpload(e) {
      this.$refs['asset-uploader'].handleFiles(e);
    },
    handleErrorOnUpload() {
      this.$emit('error-on-upload');
    }
  },
};
</script>
<style lang="scss">

.assets-tree-view-sidebar-header {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-right: 10px;
    padding-left: 20px;
    margin-right: 5px;
}

.assets-tree-view-sidebar {
  height: calc(100% - 100px - (#{$asset-library-modal-margin} * 2));
  width: $asset-library-filters-width;
  position: fixed;

  max-height: 100%;
  left: $asset-library-modal-margin;
  top: $asset-library-modal-margin;
  padding-top: 30px;
  background-color: #fff;
  border-right: solid 1px $border-list;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sidebar-content {
  margin: 15px 0px 30px 0px;
}

// SIDE FILTERS
.filters {
    width: 100%;
    padding: 15px 20px;
}
.filters-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.filters-title {
    color: #00A4EB;
}
.filters-clear {
    color: #00A4EB;
    text-decoration: underline;
    cursor: pointer;
}
.filters-block {
    margin-bottom: 30px;
    padding-left: 20px;
}
.filters-name {
    color: #00A4EB;
}
.filters-checkbox {
    margin-top: 0;
    margin-bottom: 0;
}


</style>
