<template>
    <div>
        <h3 class="font-weight-regular">
            Send email {{ hasMultipleBms ? 'requests' : 'request' }} to:
        </h3>
        <p class="mb-4">
            Select or enter the email addresses you want the
            {{ hasMultipleBms ? 'requests' : 'request' }} sent to.
            We have pre-filled recommended contacts to ensure an
            efficient turnaround.
        </p>

        <v-combobox
            :value="toEmailsValue"
            :items="availableEmails"
            :search-input.sync="searchToEmail"
            :rules="[...requiredComboboxRules, ...emailComboboxRules]"
            hide-selected
            :hint="`Contact(s) with administrative access to ${dealer.name}'s page`"
            label="To"
            multiple
            persistent-hint
            small-chips
            return-object
            item-text="label"
            item-value="value.email"
            class="mb-4"
            @input="handleToInput">
            <template #selection="{ attrs, item, parent, selected }">
                <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="emailIsValid(item.value.email) ? '' : 'red lighten-4'"
                    :input-value="selected"
                    small>
                    <span class="pr-2">
                        {{ item.label }}
                    </span>
                    <v-icon
                        small
                        @click="parent.selectItem(item)">
                        close
                    </v-icon>
                </v-chip>
            </template>
        </v-combobox>

        <transition name="fade">
            <p
                v-if="dealerUserEmails.length && !hasDealerUserEmail"
                class="red--text">
                <strong>Important Note!</strong>
                This message will be requesting that the primary user associated with
                {{ dealer.name }}, {{ dealerUserEmails[0].value.name }}
                ({{ dealerUserEmails[0].value.email }}), approve the page access
                {{ hasMultipleBms ? 'requests' : 'request' }} within Facebook. If you
                choose to send the email to another contact please ensure that someone
                at {{ dealer.name }} with administrative access to this Facebook page
                receives {{ hasMultipleBms ? 'these communications' : 'this communication' }}.
            </p>
        </transition>

        <v-combobox
            :value="ccEmailsValue"
            :items="availableEmails"
            :search-input.sync="searchCcEmail"
            :rules="[...emailComboboxRules]"
            hide-selected
            hint="Any other contacts who should be aware of the request."
            label="Cc"
            multiple
            persistent-hint
            small-chips
            return-object
            item-text="label"
            item-value="value.email"
            class="mb-4"
            @input="handleCcInput">
            <template #selection="{ attrs, item, parent, selected }">
                <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="emailIsValid(item.value.email) ? '' : 'red lighten-4'"
                    :input-value="selected"
                    small>
                    <span class="pr-2">
                        {{ item.label }}
                    </span>
                    <v-icon
                        small
                        @click="parent.selectItem(item)">
                        close
                    </v-icon>
                </v-chip>
            </template>
        </v-combobox>

        <transition name="fade">
            <p
                v-if="!hasBuyerBridgeEmail"
                class="red--text">
                <strong>Important Note!</strong>
                We cannot guarantee timely turnaround on issues and questions
                that arise during this process unless support@buyerbridge.io is copied
                on {{ hasMultipleBms ? 'these communications' : 'this communication' }}.
            </p>
        </transition>


        <div class="mb-5">
            <p>
                <a @click.prevent="viewingMoreInfo = !viewingMoreInfo">
                    Questions about what gets sent?
                </a>
            </p>
            <transition name="fade">
                <div v-if="viewingMoreInfo">
                    <p>
                        Upon submission the following communications will be triggered within several minutes:
                    </p>
                    <ol class="mb-4">
                        <li
                            v-for="communication in communicationsList"
                            :key="communication">
                            {{ communication }}
                        </li>
                    </ol>
                    <p v-if="communicationsList.length > 2">
                        <em>
                            Note that we send access requests for both business managers because
                            we need page access in {{ dealer.agency.name }} for advertising services
                            and BuyerBridge to post marketplace listings.
                        </em>
                    </p>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { getDealerUserEmails, getBuyerBridgeSupportEmails, getBuyerBridgeNoReplyEmails, getUserEmails } from '../../helpers/emailAddresses';
import { requiredRules, requiredComboboxRules, emailComboboxRules } from '../../helpers/validationRules';
import regexRules from '../../helpers/regexRules';
import uniqBy from 'lodash/uniqBy';

export default {
    props: {
        dealer: {
            type: Object,
            required: true
        },
        page: {
            type: Object,
            required: true
        },
        toEmails: {
            type: Array,
            required: true
        },
        ccEmails: {
            type: Array,
            required: true
        },
        communicationsList: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            emailComboboxRules,
            requiredRules,
            requiredComboboxRules,
            complete: false,
            searchToEmail: null,
            toEmailsValue: [],
            searchCcEmail: null,
            ccEmailsValue: [],
            noReplyEmails: [],
            availableEmails: [],
            viewingMoreInfo: false,
        };
    },
    computed: {
        allEmails() {
            return [ ...this.toEmailsValue, ...this.ccEmailsValue];
        },
        hasDealerUserEmail() {
            return this.allEmails.some(item => {
                return (item.value.email === this.dealerUserEmails[0].value.email);
            });
        },
        hasBuyerBridgeEmail() {
            return this.allEmails.some(item => {
                return (item.value.email === this.supportEmails[0].value.email);
            });
        },
        hasMultipleBms() {
            return !!(this.page.agency_bm_page_access && this.page.buyerbridge_bm_page_access);
        }
    },
    watch: {
        dealer() {
            this.setDefaultEmails();
        },
        toEmailsValue(newValue, oldValue) {
            if (newValue.length !== oldValue.length) {
                this.toEmailsValue = newValue.map(this.reformatInput);
                this.$emit('update:to-emails', this.toEmailsValue);
            }
        },
        ccEmailsValue(newValue, oldValue) {
            if (newValue.length !== oldValue.length) {
                this.ccEmailsValue = newValue.map(this.reformatInput);
                this.$emit('update:cc-emails', this.ccEmailsValue);
            }
        }
    },
    created() {
        this.setDefaultEmails();
    },
    methods: {
        emailIsValid(email) {
            return regexRules.email.test(email);
        },
        reformatInput(value) {
            if (typeof value === 'string') {
                value = {
                    label: value,
                    value: {
                        email: value
                    }
                };

                if (!this.availableEmails.some(item => item.email === value)
                    && regexRules.email.test(value.value.email)) {
                    this.availableEmails.push(value);
                }
            }

            return value;
        },
        handleToInput(value) {
            this.toEmailsValue = value;
        },
        handleCcInput(value) {
            this.ccEmailsValue = value;
        },
        formatEmailEntry(item) {
            // Objects were selected from the list with formatting preset
            // where strings need to be prepped to send to the backend
            if (typeof item === 'object') {
                return item.value;
            } else {
                return {
                    email: item
                };
            }
        },
        setDefaultEmails() {
            this.dealerUserEmails = getDealerUserEmails(this.dealer);
            this.supportEmails = getBuyerBridgeSupportEmails();
            this.userEmails = getUserEmails();
            this.noReplyEmails = getBuyerBridgeNoReplyEmails();

            this.availableEmails = uniqBy([
                ...this.dealerUserEmails,
                ...this.supportEmails,
                ...this.userEmails
            ], v => v.value.email);

            this.toEmailsValue = this.dealerUserEmails.length ? [ this.dealerUserEmails[0] ] : [];
            this.$emit('update:to-emails', this.toEmailsValue);

            this.ccEmailsValue = uniqBy([
                ...this.userEmails,
                ...this.noReplyEmails
            ]);
            this.$emit('update:cc-emails', this.ccEmailsValue);
        }
    }
};
</script>
