/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fixed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 13H0V7h2v2h7V2H7V0h6v2h-2v7h7V7h2v6h-2v-2h-7v7h2v2H7v-2h2v-7H2v2z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
