<template>
    <div
        class="type">
        <span
            class="typename"
            :class="{active: media == assetType.All}"
            @click="setMediaType(assetType.All)">All</span>
        <span
            class="typename"
            :class="{active: media == assetType.Image}"
            @click="setMediaType(assetType.Image)">Images</span>

        <span
            class="typename"
            :class="{active: media == assetType.Shape}"
            @click="setMediaType(assetType.Shape)">Shapes</span>
        <span
            class="typename"
            :class="{active: media == assetType.Text}"
            @click="setMediaType(assetType.Text)">Text Templates</span>
    </div>
</template>
<script>
import { AssetType } from '@/creative-studio/domain/assets/models/types';
import { mapActions } from 'vuex';
export default {
    props: {
        currentMediaType: {
            type: String,
            required:  false,
            default: ''
        }
    },
    data() {
        return {
            localMediaType: AssetType.All,
            assetType: AssetType
        };
    },
    computed: {
        media() {
            return this.currentMediaType ? this.currentMediaType : this.localMediaType;
        }
    },
    methods: {
        ...mapActions([
                'setAssetLibraryAssetType',
        ]),
        setMediaType(type) {
            this.localMediaType = type;
            this.setAssetLibraryAssetType(type);
            this.$emit('set-media-type', type);
        }
    }
};
</script>
<style lang="scss" scoped>

.type {
    margin: 20px 30px 10px 10px;
    font-size: 12px;
}
.typename {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    &.active {
        font-weight: bold;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            top: 100%;
            left: 0;
            background: #00A4EB;
        }
    }
}
</style>