import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
    state: {
        platformsSelected: []
    },
    namespaced: true,
    getters,
    actions,
    mutations
};