/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external-link': {
    width: 8,
    height: 8,
    viewBox: '0 0 8 8',
    data: '<path pid="0" d="M0 0v8h8V6H7v1H1V1h1V0H0zm4 0l1.5 1.5L3 4l1 1 2.5-2.5L8 4V0H4z"/>'
  }
})
