<template>
    <div
        v-show="open"
        class="color-picker-window">
        <color-picker
            v-model="color"
            @change="$emit('change', $emit)" />
    </div>
</template>

<script>
    import { Sketch } from 'vue-color';

    export default {
        components: {
            ColorPicker: Sketch
        },
        props: {
            open: {
                required: true,
                type: Boolean
            },
            value: {
                required: true,
                type: [Object, String]
            },
        },
        data() {
            return {
                color: '#000000'
            };
        },
        watch: {
            value(newValue) {
                this.color = newValue;
            },
            color(newColor) {
                this.$emit('input', newColor);
            }
        }
    };
</script>

<style lang="scss">

.color-picker-window {
    position: absolute;
    left: calc(50% - 110px);
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 220px;
}

.color-picker-window .vc-sketch{
    position: relative;
}

</style>
