<template>
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g
            id="Icons"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd">
            <g
                id="image-editor/anchor"
                class="anchors-bar-control"
                fill="#8F9EA6">
                <path
                    v-for="path in paths"
                    :id="path.id"
                    :key="path.id"
                    :d="path.path" />
            </g>
        </g>
    </svg>
</template>

<script>

export default {
    data() {
        return {
            paths: [
                {
                    id: 'anchor-bottom',
                    path: 'M8,20 C7.44771525,20 7,19.5522847 7,19 C7,18.4477153 7.44771525,18 8,18 L9,18 L9,16 C9,15.4477153 9.44771525,15 10,15 C10.5522847,15 11,15.4477153 11,16 L11,18 L12,18 C12.5522847,18 13,18.4477153 13,19 C13,19.5522847 12.5522847,20 12,20 L8,20 Z'
                },
                {
                    id: 'anchor-top',
                    path: 'M12,0 C12.5522847,-1.01453063e-16 13,0.44771525 13,1 C13,1.55228475 12.5522847,2 12,2 L11,2 L11,4 C11,4.55228475 10.5522847,5 10,5 C9.44771525,5 9,4.55228475 9,4 L9,2 L8,2 C7.44771525,2 7,1.55228475 7,1 C7,0.44771525 7.44771525,1.01453063e-16 8,0 L12,0 Z'
                },
                {
                    id: 'anchor-right',
                    path: 'M19,7 C19.5522847,7 20,7.44771525 20,8 L20,12 C20,12.5522847 19.5522847,13 19,13 C18.4477153,13 18,12.5522847 18,12 L18,11 L16,11 C15.4477153,11 15,10.5522847 15,10 C15,9.44771525 15.4477153,9 16,9 L18,9 L18,8 C18,7.44771525 18.4477153,7 19,7 Z'
                },
                {
                    id: 'anchor-left',
                    path: 'M1,7 C1.55228475,7 2,7.44771525 2,8 L2,9 L4,9 C4.55228475,9 5,9.44771525 5,10 C5,10.5522847 4.55228475,11 4,11 L2,11 L2,12 C2,12.5522847 1.55228475,13 1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 L0,8 C-6.76353751e-17,7.44771525 0.44771525,7 1,7 Z'
                },
                {
                    id: 'center',
                    path: 'M13,6 C13.5522847,6 14,6.44771525 14,7 L14,13 C14,13.5522847 13.5522847,14 13,14 L7,14 C6.44771525,14 6,13.5522847 6,13 L6,7 C6,6.44771525 6.44771525,6 7,6 L13,6 Z M12,8 L8,8 L8,12 L12,12 L12,8 Z'
                }
            ]
        };
    }
};

</script>

