import { v4 as uuidv4 } from 'uuid';

const observers = {};

const bind = (el, binding) => {
    const id = uuidv4();

    const { value: params } = binding;

    let callback;
    let options = {};

    // Allow consumer to pass functions directly
    if (typeof params === 'function') {
        callback = params;
    }
    // Also support an object structure
    else if (typeof params?.handler === 'function') {
        callback = params.handler;
        options = params.options || {};
    }

    // Enforce callbacks passed to directive
    if (typeof callback !== 'function') {
        return;
    }

    el.dataset.intersectId = id;

    const fn = (entries) => entries.forEach(entry => {
        if (entry.isIntersecting) {
            callback(entry);
        }
    });

    const observer = new IntersectionObserver(fn, options);

    observer.observe(el);

    observers[id] = observer;
};



const unbind = (el) => {
    const { intersectId } = el.dataset;

    if (intersectId) {
        observers[intersectId].unobserve(el);
        delete observers[intersectId];
    }
};

export default { bind, unbind };