export const DEFAULT_LAYER_POSITION = {
    top: 20,
    left: 200
};
export const DEFAULT_LAYER_DIMENSIONS = {
    height: 200,
    width: 450
};
export const DEFAULT_LAYER_ROTATE_ANGLE = 0;

export const ANCHOR_POSITIONS = Object.freeze({
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom'
});

export const verticalAnchoredPositions = [ ANCHOR_POSITIONS.TOP, ANCHOR_POSITIONS.BOTTOM];
export const rightAnchoredPositions = [ ANCHOR_POSITIONS.RIGHT, ANCHOR_POSITIONS.TOP_RIGHT, ANCHOR_POSITIONS.BOTTOM_RIGHT];
export const horizontalAnchoredPositions = [ ANCHOR_POSITIONS.LEFT, ANCHOR_POSITIONS.RIGHT];
export const bottomAnchoredPositions = [ ANCHOR_POSITIONS.BOTTOM, ANCHOR_POSITIONS.BOTTOM_LEFT, ANCHOR_POSITIONS.BOTTOM_RIGHT];

export const LAYERED_DESIGNER_MODE = Object.freeze({
    DEFAULT: 'DEFAULT',
    NEW_DESIGN: 'NEW_DESIGN',
    EDIT_DESIGN: 'EDIT_DESIGN'
});

export const LAYERED_DESIGNER_OVERFLOW = Object.freeze({
    HIDDEN: 'hidden',
    VISIBLE: 'visible'
});

export const FLIP_DIRECTIONS = Object.freeze({
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
});

export const FONT_WEIGHT = Object.freeze({
    BOLD: 'bold',
    NORMAL: 'normal'
});

export const FONT_STYLE = Object.freeze({
    ITALIC: 'italic',
    NORMAL: 'normal'
});

export const GRADIENT_TYPES= {
    LINEAR: 'linear',
    RADIAL: 'radial',
    SOLID: 'solid'
};

export const CLIP_TYPES = {
    INSET: 'inset',
    RECT: 'rect',
    CIRCLE: 'circle',
    ELLIPSE: 'ellipse',
    POLYGON: 'polygon'
};

export const FIT_TYPES = {
    FILL: 'fill',
    COVER: 'cover',
};


export const TEXT_ASSET_CONTENT_MODE = Object.freeze({
    simpleText: 'simple_text',
    html: 'html',
});

export const DEFAULT_GRADIENT_POINTS= Object.freeze([
    {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1
    },
    {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1
    }
]);

export const LAYER_COLOR_MODES = Object.freeze({
    SOLID: 'solid',
    GRADIENT: 'gradient'
});

export class Layer {
    name;
    asset;
    position= {
        top: DEFAULT_LAYER_POSITION.top,
        left: DEFAULT_LAYER_POSITION.left
    }
    dimensions= {
        height: DEFAULT_LAYER_DIMENSIONS.height,
        width: DEFAULT_LAYER_DIMENSIONS.width
    }

    // Original base dimensions to keep ratio when resizing the canvas
    originalBaseDimensions= {
        layerOriginalDimensions: {
            height: 0,
            width: 0
        }
    }

    rotateAngle= DEFAULT_LAYER_ROTATE_ANGLE;

    anchorPosition= null;
    anchoredPositions = [];
    index;
    cropOffset= {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    }

    alterations= {
        isHorizontalFlipped: false,
        isVerticalFlipped: false,
        hasToKeepSize: false,
        isClipping: false,
        isBeingFit: false,
        objectFit: FIT_TYPES.FILL
    }

    shadow= {
        xOffset: 0,
        yOffset: 0,
        blur: 0,
        color: ''
    }

    colorMode= LAYER_COLOR_MODES.SOLID;

    scale = 1;

    colorGradient= {
        type: GRADIENT_TYPES.SOLID,
        degree: 1,
        points: DEFAULT_GRADIENT_POINTS
    }

    constructor(id) {
        this.id = id;
    }

    setInitialPositionAndDimensions(parentCard) {
        if (!this.asset) {
            return;
        }
        this.setInitialDimensions(parentCard);
        this.setInitialPosition(parentCard);

        this.originalBaseDimensions.layerOriginalDimensions = { ...this.dimensions };
    }

    setInitialDimensions(parentCard) {
        const file = this.asset?.file;
        if (!this.isResizeable() || !file || (!file?.height || !file?.width)) {
            return;
        }
        const { height: parentHeight, width: parentWidth } = parentCard.dimensions;
        const layerHeight = file?.height;
        const layerWidth = file?.width;

        let ratio = 1;
        if ( layerHeight > parentHeight || layerWidth > parentWidth ) {
            ratio = Math.min(parentWidth / layerWidth, parentHeight / layerHeight);
        }

        this.dimensions = { width: layerWidth*ratio, height: layerHeight*ratio };

    }

    setInitialPosition(parentCard) {
        this.centerLayerInCard(parentCard);
    }

    centerLayerInCard(parentCard) {
        const { height: parentHeight, width: parentWidth } = parentCard.dimensions;
        const { height: layerHeight, width: layerWidth } = this.dimensions;

        let top= null, left= null;
        if (layerHeight === parentHeight) {
            top = 0;
        }

        if (layerWidth === parentWidth) {
            left = 0;
        }

        if (top === null) {
            top = Math.abs((parentHeight / 2) - (layerHeight / 2)) ;
        }

        if (left === null) {
            left = Math.abs((parentWidth / 2) - (layerWidth / 2));
        }

        this.position = { top, left };
    }

    isAnchored() {
        return this.anchoredPositions.length > 0;
    }

    updateLayerPositionAndDimensions({ height: oldCardHeight , width: oldCardWidth }, { height: newCardHeight, width: newCardWidth }, mainCardSizeDimensions) {
        const horizontalResizeRatio = newCardWidth / oldCardWidth;
        const verticalResizeRatio = newCardHeight / oldCardHeight;

        const cardWidthDelta = newCardWidth - oldCardWidth;
        const cardHeightDelta = newCardHeight - oldCardHeight;

        const { newLayerHeight, newLayerWidth } = this.calculateNewDimensions({
            horizontalResizeRatio,
            verticalResizeRatio,
            newCardHeight,
            newCardWidth
        },
        mainCardSizeDimensions,
        cardWidthDelta,
        cardHeightDelta);

        const newLayerLeft = this.calculateNewLeftPosition({ horizontalResizeRatio, cardWidthDelta, newLayerWidth });
        const newLayerTop = this.calculateNewTopPosition({
            verticalResizeRatio,
            cardHeightDelta,
            newLayerHeight,
            oldCardHeight,
         });


        this.dimensions.width = newLayerWidth;
        this.dimensions.height = newLayerHeight;
        this.position.left = newLayerLeft;
        this.position.top = newLayerTop;
    }

    calculateNewDimensions({ horizontalResizeRatio, verticalResizeRatio, newCardHeight, newCardWidth }, mainCardSizeDimensions, cardWidthDelta, cardHeightDelta) {
        let newLayerWidth = this.dimensions.width * horizontalResizeRatio;
        let newLayerHeight = this.dimensions.height * verticalResizeRatio;
        let currentWidth = this.dimensions.width;
        let currentHeight = this.dimensions.height;

        if (this.asset.isTextAsset() || !this.isResizeable()) {
            newLayerWidth = this.dimensions.width;
            newLayerHeight = this.dimensions.height;
        } else if (this.hasToKeepAspectRatio()) {
            return this.calculateDimensionsWithRatio(newLayerHeight, newLayerWidth, { newCardHeight, newCardWidth }, mainCardSizeDimensions,  cardWidthDelta, cardHeightDelta);
        }

        if (this.hasOppositesHorizontalAnchors() && this.isResizeable()) {
            newLayerWidth = currentWidth + cardWidthDelta;
        }

        if (this.hasOppositesVerticalAnchors() && this.isResizeable()) {
            newLayerHeight = currentHeight + cardHeightDelta;
        }


        return { newLayerHeight, newLayerWidth };
    }

    hasToKeepAspectRatio() {
        return this.asset.isImageAsset() || (this.asset.isTextAsset() &&  !this.asset.hasSimpleContentType());
    }

    isScalable() {
        return !this.alterations.hasToKeepSize && (this.asset.isTextAsset() && !this.asset.hasSimpleContentType());
    }

    calculateDimensionsWithRatio(newLayerHeight, newLayerWidth, { newCardHeight, newCardWidth }, mainCardSizeDimensions, cardWidthDelta, cardHeightDelta) {
        // Use the base dimensions when resizing to keep the ratio and correctly resize the layers
        // According to the new card dimensions
        const originalBaseHeight = this.originalBaseDimensions.layerOriginalDimensions.height;
        const originalBaseWidth = this.originalBaseDimensions.layerOriginalDimensions.width;

        const realCurrentHeight = this.dimensions.height;
        const realCurrentWidth = this.dimensions.width;


        // Use the current main card size dimensions for the calculations
        const { height: originalBaseCardHeight , width: originalBaseCardWidth } = mainCardSizeDimensions;
        const horizontalBaseResizeRatio = newCardWidth / originalBaseCardWidth;
        const verticalBaseResizeRatio = newCardHeight / originalBaseCardHeight;
        const minResizeRatio = Math.min(horizontalBaseResizeRatio, verticalBaseResizeRatio);

        let ratioHeight = realCurrentHeight;
        let ratioWidth = realCurrentWidth;

        const isATwoDimensionChange = realCurrentHeight !== newLayerHeight && realCurrentWidth !== newLayerWidth;

        if (isATwoDimensionChange && !this.hasOppositesHorizontalAnchors() &&  !this.hasOppositesVerticalAnchors()) {
            if(originalBaseHeight !== 0 && originalBaseWidth !== 0) {
                ratioHeight = originalBaseHeight * minResizeRatio;
               ratioWidth = originalBaseWidth * minResizeRatio;
            }
        }

        if (this.hasOppositesHorizontalAnchors() && this.isResizeable()) {
            ratioWidth =  cardWidthDelta + ratioWidth;
            ratioHeight = cardWidthDelta + ratioHeight;
        }

        if (this.hasOppositesVerticalAnchors() && this.isResizeable()) {
            ratioWidth = cardHeightDelta + ratioWidth;
            ratioHeight = cardHeightDelta + ratioHeight;
        }

        return { newLayerHeight: ratioHeight, newLayerWidth: ratioWidth };
    }

    calculateNewLeftPosition({ horizontalResizeRatio, cardWidthDelta, newLayerWidth }) {

        if (!this.isHorizontalAnchored()) {
            return this.position.left * horizontalResizeRatio;
        }


        if (this.anchoredPositions.includes(ANCHOR_POSITIONS.RIGHT) && !this.hasOppositesHorizontalAnchors()) {
            return this.position.left + cardWidthDelta + (this.dimensions.width - newLayerWidth);
        }

        // The anchor is on the left side or on the vertical edge, either way the left is the same
        return this.position.left;
    }

    isHorizontalAnchored() {
        return this.isAnchored() && (this.anchoredPositions.includes(ANCHOR_POSITIONS.LEFT) || this.anchoredPositions.includes(ANCHOR_POSITIONS.RIGHT));
    }

    hasOppositesHorizontalAnchors() {
        return this.anchoredPositions.includes(ANCHOR_POSITIONS.RIGHT) && this.anchoredPositions.includes(ANCHOR_POSITIONS.LEFT);
    }

    hasOppositesVerticalAnchors() {
        return this.anchoredPositions.includes(ANCHOR_POSITIONS.TOP) && this.anchoredPositions.includes(ANCHOR_POSITIONS.BOTTOM);
    }

    calculateNewTopPosition({ verticalResizeRatio, cardHeightDelta, newLayerHeight, oldCardHeight }) {
        if (!this.isVerticalAnchored()) {
            const cardCenter = oldCardHeight / 2;

            if(this.position.top < cardCenter) {
                return this.position.top * verticalResizeRatio;
            }

        return this.position.top + cardHeightDelta + (this.dimensions.height - newLayerHeight);
        }

        if (this.anchoredPositions.includes(ANCHOR_POSITIONS.BOTTOM)) {
            return this.position.top + cardHeightDelta + (this.dimensions.height - newLayerHeight);
        }


        // The anchor is on the top side or on the horizontal edge, either way the top is the same
        return this.position.top;
    }

    isVerticalAnchored() {
        return this.isAnchored() && (this.anchoredPositions.includes(ANCHOR_POSITIONS.TOP) || this.anchoredPositions.includes(ANCHOR_POSITIONS.BOTTOM));
    }

    isResizeable() {
        return !this.alterations.hasToKeepSize && (this.asset.isImageAsset() || this.asset.isShapeAsset() || (this.asset.isTextAsset() && this.asset.hasSimpleContentType()));
    }

    isClippable() {
        return this.asset.isImageAsset();
    }

    usesColorGradient() {
        return this.colorMode === LAYER_COLOR_MODES.GRADIENT;
    }

}