/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indicator-info': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M9 0a9 9 0 110 18A9 9 0 019 0zm1.094 6.861H7.803V13.5h2.29V6.861zM8.957 4.19c-.639 0-.639 0-.926.252-.287.252-.287.252-.287.768 0 1.008 0 1.008 1.213 1.008 1.225 0 1.225 0 1.225-1.008 0-.504 0-.504-.293-.762s-.293-.258-.932-.258z" _fill="#8F9EA6" fill-rule="evenodd" fill-opacity=".598"/>'
  }
})
