/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 22,
    height: 20,
    viewBox: '0 0 22 20',
    data: '<path pid="0" d="M13 13a3.502 3.502 0 013.355 2.5H21v2h-4.645a3.502 3.502 0 01-6.71 0H.5v-2h9.145A3.502 3.502 0 0113 13zm0 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM8.5 6a3.502 3.502 0 013.355 2.5h9.233v2h-9.233a3.502 3.502 0 01-6.71 0H.588v-2h4.557A3.502 3.502 0 018.5 6zm0 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm8-8a3.502 3.502 0 013.355 2.5H21v2h-1.145a3.502 3.502 0 01-6.71 0H.5v-2h12.645A3.502 3.502 0 0116.5 0zm0 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
