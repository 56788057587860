/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'horizontal': {
    width: 30,
    height: 19,
    viewBox: '0 0 30 19',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M3 18V1a1 1 0 011-1h5.564a1 1 0 01.657.246l3.436 2.997a1 1 0 01.343.754V18a1 1 0 01-1 1H4a1 1 0 01-1-1zM17 18V1a1 1 0 011-1h5.564a1 1 0 01.657.246l3.436 2.997a1 1 0 01.343.754V18a1 1 0 01-1 1h-9a1 1 0 01-1-1z" _fill="#8F9EA6"/><path pid="1" _fill="#FFF" d="M21.458 6.358l.773-.816 3.31 3.5-3.31 3.5-.773-.817 2.539-2.683zM7.458 6.358l.773-.816 3.31 3.5-3.31 3.5-.773-.817 2.539-2.683z"/></g>'
  }
})
