/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert-muted': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M1 1.586L18.414 19 17 20.414 13.585 17h-.83L7.246 17H1v-1.687c0-1.043.466-2.01 1.248-2.587L3 12.17v-1.04c0-1.333.367-2.607 1.017-3.698L-.414 3 1 1.586zM13 18c-.162 1.123-1.446 2-3 2s-2.837-.877-3-2h6zM10.126.018c.956.131 1.677.999 1.677 2.019v1.706c0 .228.14.432.35.507C15.053 5.292 17 8.126 17 11.3v.87l.752.556c.73.538 1.184 1.417 1.242 2.38l.006.206V17l-.587-.001L6.42 5.005c.29-.175.594-.329.911-.46l.247-.097a.537.537 0 00.345-.506V1.998c0-.573.243-1.12.667-1.502a1.88 1.88 0 011.536-.478z" _fill="#8F9EA6" fill-rule="evenodd" fill-opacity=".602"/>'
  }
})
