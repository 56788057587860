/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'opportunity': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<path pid="0" d="M5.092 6.256l-.611 1.57C8.78 9.75 10.996 13.474 11.129 19l-1.867-.004H8.14c-.25 0-.375.002-.376.004-.258-3.802-1.799-6.524-4.623-8.164l-.671 1.499L0 7.825l5.092-1.569zm9.816 0L20 7.826l-2.47 4.509-.67-1.5c-2.359 1.37-3.822 3.493-4.391 6.37-.237-2.064-.808-3.849-1.71-5.35 1.104-1.76 2.69-3.103 4.76-4.03l-.611-1.569zM9.544 0l3.132 4.27H11.11l-.037 3.903-1.529 1.9-1.618-1.9.052-3.903H6.412L9.544 0z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
