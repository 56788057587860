<template>
    <svgicon
        :name="name"
        :height="size || height"
        :width="size || width"
        :color="derivedColor" />
</template>

<script>
import '@/components/icons';
import colors from '@/helpers/colors';

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        height: {
            type: String,
            default: '25'
        },
        width: {
            type: String,
            default: '25'
        },
        size: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: colors.gray
        }
    },
    data() {
        return {
            iconLoaded: false
        };
    },
    computed: {
        derivedColor() {
            if (colors[this.color]) {
                return colors[this.color];
            }

            return this.color;
        }
    },
    async created() {
        // @todo Once webpack supports this activate it!
        //await import(`../icons/${this.name}`);
        //this.iconLoaded = true;
    }
};
</script>
