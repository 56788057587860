import store from '../store';
import { get } from 'lodash';

const currentUser = store.state.user.user;

export const getDealerUserEmails = (dealer) => {

    dealer = dealer || store.state.dealer.currentDealer;

    const users = get(dealer, 'users.data', null) || [];

    return users
        .filter(user => user.email)
        .map(user => {
            let label = user.email;

            if (user.name) {
                label = `${user.name} <${user.email}>`;
            }
            return {
                label,
                value: {
                    name: user.name,
                    email: user.email
                }
            };
        });
};

export const getDealerFeedProviderEmails = (dealer) => {
    dealer = dealer || store.state.dealer.currentDealer;

    // Assume feed provider is the first one
    const dataSources = get(dealer, 'vehicle_data_sources.data', null) || [];
    // Filter out Marketcheck (website inventory)
    const feedProviders = dataSources.filter(dataSource => {
        return (dataSource.id !== 65);
    });

    if (!feedProviders.length) {
        return [];
    }

    const primaryProvider = feedProviders[0];

    return [{
        label: `${primaryProvider.name} <${primaryProvider.support_email}>`,
        value: {
            name: primaryProvider.name,
            email: primaryProvider.support_email
        }
    }];
};

export const getDealerSiteProviderEmails = (dealer) => {
    dealer = dealer || store.state.dealer.currentDealer;

    if (!dealer.site_provider) {
        return [];
    }

    return [{
        label: `${dealer.site_provider.name} <${dealer.site_provider.support_email}>`,
        value: {
            name: dealer.site_provider.name,
            email: dealer.site_provider.support_email
        }
    }];
};

export const getBuyerBridgeSupportEmails = () => {
    return [{
        label: 'BuyerBridge <support@buyerbridge.io>',
        value: {
            name: 'BuyerBridge Support',
            email: 'support@buyerbridge.io'
        }
    }];
};

export const getBuyerBridgeNoReplyEmails = () => {
    return [{
        label: 'BuyerBridge <noreply@buyerbridge.io>',
        value: {
            name: 'No Reply',
            email: 'noreply@buyerbridge.io'
        }
    }];
};

export const getUserEmails = () => {
    if (!currentUser) {
        return [];
    }

    return [{
        label: `${currentUser.name} <${currentUser.email}>`,
        value: {
            name: currentUser.name,
            email: currentUser.email
        }
    }];
};

export const getAllEmails = (dealer) => {
    const dealerUserEmails = getDealerUserEmails(dealer);
    const supportEmails = getBuyerBridgeSupportEmails();
    const userEmails = getUserEmails();

    this.availableEmails = [ ...new Set([
        ...dealerUserEmails,
        ...supportEmails,
        ...userEmails
    ]) ];
};