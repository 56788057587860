import {
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK,
    BB_STATUS_ACTIVE,
    BB_DEFAULT_DAILY_BUDGET
} from '@/helpers/globals';
import { cloneDeep } from 'lodash';
import getPlatformOptimizationDefaults from '../helpers/getPlatformOptimizationDefaults';
import {
    OBJECTIVE_OUTCOME_AWARENESS,
    OBJECTIVE_BRAND_AWARENESS,
    OBJECTIVE_REACH,
    OBJECTIVE_AWARENESS
} from './constants';

const coreState = {
    ad_deployment_id: null,
    dealer_id: null,
    current_step: 0,
    latest_step: 0,
    platforms: [],
    ads: [],
    loading: false,
    selectedPreset: null,
    dealer_name: null,
    is_simple_geo: true,
    trigger_map_refresh: false,
    deployment_type: '',
    send_notifications: true,
    notification_emails: [],
    ad_copy_refresh_counter: 10,
    custom_mile_pinterest: false,
    custom_mile_pinterest_value: 15,
};

export const getCoreState = () => {
    return cloneDeep(coreState);
};

// Isolate extended state for resetting
// deployment when the ad destination is toggled
const extendedState = {
    // Step 2
    url_config: {
        platform_specific: {
            facebook: true,
            tiktok: true,
            snapchat: true,
            pinterest: true
        },
        applied_to_all_ads: true,
        customize_per_channel: false
    },
    // Step 3
    campaign_selection: {
        selection: 'all_platforms',
        all_platforms: {
            campaign_name: null,
            ad_category: 'credit'
        },
        platform_specific: {
            facebook: {
                campaign: {
                    id: null,
                    name: null
                },
                adSet: {
                    id: null,
                    name: null
                }
            },
            tiktok: {
                campaign: {
                    id: null,
                    name: null
                },
                adSet: {
                    id: null,
                    name: null
                }
            },
            snapchat: {
                campaign: {
                    id: null,
                    name: null
                },
                adSet: {
                    id: null,
                    name: null
                }
            },
            pinterest: {
                campaign: {
                    id: null,
                    name: null
                },
                adSet: {
                    id: null,
                    name: null
                }
            }
        }
    },
    // Step 4
    optimization: {
        platform_specific: {
            // @todo Use the mutations or some side effect to default these
            facebook: {
                ...getPlatformOptimizationDefaults(PLATFORM_FACEBOOK, OBJECTIVE_OUTCOME_AWARENESS),
                cta: null,
            },
            tiktok: {
                ...getPlatformOptimizationDefaults(PLATFORM_TIKTOK, OBJECTIVE_REACH),
                cta: null,
            },
            snapchat: {
                ...getPlatformOptimizationDefaults(PLATFORM_SNAPCHAT, OBJECTIVE_BRAND_AWARENESS),
                cta: null,
            },
            pinterest: {
                ...getPlatformOptimizationDefaults(PLATFORM_PINTEREST, OBJECTIVE_AWARENESS),
                cta: null,
            }
        }
    },
    // Step 5
    geo_targeting: {
        platform_specific: {
            facebook: {
                locations: []
            },
            tiktok: {
                locations: []
            },
            snapchat: {
                locations: []
            },
            pinterest: {
                locations: []
            }
        }
    },
    // Step 6
    audience: {
        platform_specific: {
            facebook: {
                ages: {
                    min: {
                        name: '18',
                        value: 18
                    },
                    max: {
                        name: '65+',
                        value: 65,
                    }
                },
                gender: 'ALL',
                existing_audiences: [],
            },
            tiktok: {
                ages: {
                    min: {
                        name: '18',
                        value: 18
                    },
                    max: {
                        name: '55+',
                        value: 55
                    }
                },
                gender: 'ALL',
                existing_audiences: [],
            },
            snapchat: {
                ages: {
                    min: {
                        name: '18',
                        value: 18
                    },
                    max: {
                        name: '50+',
                        value: 50,
                    }
                },
                gender: 'ALL',
                existing_audiences: []
            },
            pinterest: {
                ages: {
                    min: {
                        name: '18',
                        value: 18
                    },
                    max: {
                        name: '65+',
                        value: 65,
                    }
                },
                gender: 'ALL',
                existing_audiences: []
            }
        }
    },
    budget: {
        schedule: 'daily',
        currency: 'USD',
        start_date_time: null,
        end_date_time: null,
        platform_specific: {
            facebook: {
                level: 'campaign',
                campaign: {
                    daily_budget: BB_DEFAULT_DAILY_BUDGET,
                    daily_spend_cap: null,
                    lifetime_budget: null,
                    lifetime_spend_cap: null,
                    bid_cap: null,
                },
                adSet: {
                    daily_budget: BB_DEFAULT_DAILY_BUDGET,
                    daily_spend_cap: null,
                    lifetime_budget: null,
                    lifetime_spend_cap: null,
                    bid_cap: null,
                }
            },
            tiktok: {
                level: 'adSet',
                campaign: {
                    daily_budget: BB_DEFAULT_DAILY_BUDGET,
                    daily_spend_cap: null,
                    lifetime_budget: null,
                    lifetime_spend_cap: null,
                    bid_cap: null,
                },
                adSet: {
                    daily_budget: BB_DEFAULT_DAILY_BUDGET,
                    daily_spend_cap: null,
                    lifetime_budget: null,
                    lifetime_spend_cap: null,
                    bid_cap: null,
                }
            },
            snapchat: {
                level: 'adSet',
                campaign: {
                    daily_budget: BB_DEFAULT_DAILY_BUDGET,
                    daily_spend_cap: null,
                    lifetime_budget: null,
                    lifetime_spend_cap: null,
                    bid_cap: null,
                },
                adSet: {
                    daily_budget: BB_DEFAULT_DAILY_BUDGET,
                    daily_spend_cap: null,
                    lifetime_budget: null,
                    lifetime_spend_cap: null,
                    bid_cap: null,
                }
            },
            pinterest: {
                level: 'adSet',
                campaign: {
                    daily_budget: BB_DEFAULT_DAILY_BUDGET,
                    daily_spend_cap: null,
                    lifetime_budget: null,
                    lifetime_spend_cap: null,
                    bid_cap: null,
                },
                adSet: {
                    daily_budget: BB_DEFAULT_DAILY_BUDGET,
                    daily_spend_cap: null,
                    lifetime_budget: null,
                    lifetime_spend_cap: null,
                    bid_cap: 0.02,
                }
            }
        }
    },
    statuses: {
        platform_specific: {
            facebook: {
                campaign: {
                    status: BB_STATUS_ACTIVE
                },
                adSet: {
                    status: BB_STATUS_ACTIVE
                },
                ad: {
                    status: BB_STATUS_ACTIVE
                }
            },
            tiktok: {
                campaign: {
                    status: BB_STATUS_ACTIVE
                },
                adSet: {
                    status: BB_STATUS_ACTIVE
                },
                ad: {
                    status: BB_STATUS_ACTIVE
                }
            },
            snapchat: {
                campaign: {
                    status: BB_STATUS_ACTIVE
                },
                adSet: {
                    status: BB_STATUS_ACTIVE
                },
                ad: {
                    status: BB_STATUS_ACTIVE
                }
            },
            pinterest: {
                campaign: {
                    status: BB_STATUS_ACTIVE
                },
                adSet: {
                    status: BB_STATUS_ACTIVE
                },
                ad: {
                    status: BB_STATUS_ACTIVE
                }
            }
        }
    },

    // Dynamic Steps
    product_group_selection: {
        radio_group_option: 'default',
        platform_specific: {
            [PLATFORM_FACEBOOK]: {
                id: null,
                name: 'All Inventory',
                count: null,
            },
            [PLATFORM_TIKTOK]: {
                id: null,
                name: 'All Inventory',
                count: null,
            },
            [PLATFORM_SNAPCHAT]: {
                id: null,
                name: 'All Inventory',
                count: null,
            },
            [PLATFORM_PINTEREST]: {
                id: null,
                name: 'New Only',
                count: null,
            },
        }
    },
    dynamic_audience_configuration: {
        radio_group_option: 'default',
        platform_specific: {
            [PLATFORM_FACEBOOK]: {
                radio_group_option: 'automatedByChannel',
                custom_audiences: []
            },
            [PLATFORM_TIKTOK]: {
                radio_group_option: 'automatedByChannel',
                custom_audiences: []
            },
            [PLATFORM_SNAPCHAT]: {
                radio_group_option: 'automatedByChannel',
                custom_audiences: []
            },
            [PLATFORM_PINTEREST]: {
                radio_group_option: 'automatedByChannel',
                custom_audiences: []
            },
        }
    },
    ad_destination: {
        radio_group_option: 'default',
        platform_specific: {
            [PLATFORM_FACEBOOK]: {
                radio_group_option: 'websiteVDPs',
            },
            [PLATFORM_TIKTOK]: {
                radio_group_option: 'websiteVDPs',
            },
            [PLATFORM_SNAPCHAT]: {
                radio_group_option: 'websiteVDPs',
            },
            [PLATFORM_PINTEREST]: {
                radio_group_option: 'websiteVDPs',
            },
        }
    },
    ad_copy: {
        platform_specific: {
            [PLATFORM_FACEBOOK]: '',
            [PLATFORM_TIKTOK]: '',
            [PLATFORM_SNAPCHAT]: '',
            [PLATFORM_PINTEREST]: ''
        }
    },
};

export const getExtendedState = () => {
    return cloneDeep(extendedState);
};

const getInitialState = () => {
    return cloneDeep({
        ...coreState,
        ...extendedState
    });
};

export default getInitialState;
