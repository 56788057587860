/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'agreement': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M12.948 13.19a2.26 2.26 0 012.256 2.256c0 .767-.38 1.43-.95 1.836V19.9l-1.303-.288-1.3.288v-2.612a2.244 2.244 0 01-.957-1.842 2.26 2.26 0 012.254-2.257zM16.48.123c.658 0 1.193.535 1.193 1.19v16.139c0 .658-.535 1.193-1.193 1.193h-1.579v-1.05c.6-.552.95-1.33.95-2.15a2.908 2.908 0 00-2.904-2.905 2.906 2.906 0 00-2.902 2.905c0 .84.344 1.608.956 2.152v1.048H4.233a1.194 1.194 0 01-1.193-1.193V1.314c0-.655.535-1.19 1.193-1.19zm-.798 8.632H4.95v1.5h10.732v-1.5zm0-3H4.95v1.5h10.732v-1.5zm0-3H4.95v1.5h10.732v-1.5z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
