<template>
    <div
        ref="wrapper"
        class="pws-wrapper"
        :class="{'fit-view': canvasView}">
        <div class="pws-header">
            <div
                v-if="!canvasView"
                class="pws-header__placeholder" />
            <div class="pws-header__channels">
                <div class="pws-header-previews">
                    Channel Preview
                </div>

                <div class="pws-platforms">
                    <div
                        v-for="(platformConfig, index) in platforms"
                        :key="index">
                        <span
                            v-if="selectedAdPlatforms.includes(platformConfig.platform)"
                            @click="togglePlatform(platformConfig); scrollTo(platformConfig);">
                            <icon
                                :name="platformConfig.icon"
                                :color="platformConfig.active ? '#03A2EA' : '#909FA8'"
                                size="25" />
                        </span>
                    </div>
                </div>
            </div>
            <div
                class="pws-header__switcher"
                :class="{'larger-switcher': !canvasView}">
                <div
                    v-if="canvasView"
                    class="canvas-title">
                    Canvas View
                </div>
                <div>
                    <span @click="selectGrid">
                        <icon
                            name="grid-four"
                            :color="isGrid ? '#03A2EA' : '#909FA8'"
                            size="32" />
                    </span>
                    <span
                        class="ml-1"
                        @click="selectVertical">
                        <icon
                            class="vertical-view-icon"
                            name="vertical"
                            :color="isVertical ? '#03A2EA' : '#909FA8'"
                            size="32" />
                    </span>
                    <span
                        v-if="canvasView"
                        class="ml-1"
                        @click="selectHorizontal">
                        <icon
                            class="horizontal-view-icon"
                            name="horizontal"
                            :color="isHorizontal ? '#03A2EA' : '#909FA8'"
                            size="32" />
                    </span>
                    <span
                        v-if="canvasView"
                        class="view-divider" />
                    <span
                        v-if="canvasView"
                        @click="toggleFitToWindow">
                        <icon
                            class="fit-icon"
                            name="fit"
                            :color="fitToWindow ? '#03A2EA' : '#909FA8'"
                            size="32" />
                    </span>
                </div>
            </div>
        </div>
        <div
            ref="content"
            class="ad-studio-previews"
            :class="{
                'vertical-view': isVertical,
                'horizontal-view': isHorizontal,
                'fit-to-window': fitToWindow
            }">
            <div
                v-for="(platformConfig, index) in platforms"
                :key="index">
                <div
                    v-if="platformConfig.active && selectedAdPlatforms.includes(platformConfig.platform)"
                    :ref="`${platformConfig.platform}Wrapper`"
                    class="preview-wrapper">
                    <single-preview
                        :ad-data="adData"
                        :platform-config="platformConfig" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SinglePreview from '@/components/ad-studio/editing/preview-step/SinglePreview';
import Icon from '@/components/globals/Icon';
import { mapGetters } from 'vuex';
import {
    PLATFORM_FACEBOOK,
    FACEBOOK_BB_PLATFORM_ID,
    PLATFORM_SNAPCHAT,
    SNAPCHAT_BB_PLATFORM_ID,
    PLATFORM_PINTEREST,
    PINTEREST_BB_PLATFORM_ID,
    PLATFORM_TIKTOK,
    TIKTOK_BB_PLATFORM_ID,
} from '@/helpers/globals';
import EventBus from '@/event-bus';

export default {
    components: {
        SinglePreview,
        Icon
    },
    props: {
        adData: {
            type: [Object, Array],
            default: () => []
        },
        selectedChannels: {
            type: [Object, Array],
            default: () => []
        },
        canvasView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isGrid: false,
            isHorizontal: false,
            isVertical: true,
            fitToWindow: false,
            platforms: [
                {
                    name: 'Meta',
                    platform: PLATFORM_FACEBOOK,
                    platform_id: FACEBOOK_BB_PLATFORM_ID,
                    icon: 'meta',
                    active: true
                },
                {
                    name: 'Snapchat',
                    platform: PLATFORM_SNAPCHAT,
                    platform_id: SNAPCHAT_BB_PLATFORM_ID,
                    icon: PLATFORM_SNAPCHAT,
                    active: true
                },
                {
                    name: 'TikTok',
                    platform: PLATFORM_TIKTOK,
                    platform_id: TIKTOK_BB_PLATFORM_ID,
                    icon: PLATFORM_TIKTOK,
                    active: true
                },
                {
                    name: 'Pinterest',
                    platform: PLATFORM_PINTEREST,
                    platform_id: PINTEREST_BB_PLATFORM_ID,
                    icon: PLATFORM_PINTEREST,
                    active: true
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            selectedAdPlatformsFromStudio: 'adStudio/selectedAdPlatforms',
            selectedAdData: 'adStudio/selectedAdData'
        }),
        selectedAdPlatforms() {
            if (this.selectedChannels.length != 0) {
                return this.selectedChannels;
            } else {
                return this.selectedAdPlatformsFromStudio;
            }
        }
    },
    created() {
        EventBus.$on('toggle-single-platform', (activePlatform) => {
            this.platforms.map(platform => platform.active = activePlatform === platform.platform);
        });
        /* When the close popup event happens, this means the user is done editing
        the assets relevant for the ad. For some reason editing the ad data in the
        store is not updating in the ad previews. We need to toggle the platforms
        to get the ad previews to refresh with the new asset data from the store. */
        EventBus.$on('close-popup', async() => {
            this.platforms.forEach(async(platform) => {
                this.togglePlatform(platform);
                await this.$nextTick();
                this.togglePlatform(platform);
            });
            await this.$nextTick();
            this.clearFit();
            this.resize();
        });
    },
    methods: {
        toggleFitToWindow() {
            this.fitToWindow = !this.fitToWindow;
            this.resize();
        },
        async resize() {
            await this.$nextTick();
            if (this.fitToWindow) {
                this.activateFit();
            } else {
                this.clearFit();
            }
        },
        activateFit() {
            const content = this.$refs.content;
            const wrapper = this.$refs.wrapper;
            const contentHeight = content.getBoundingClientRect().height;
            const contentWidth = content.getBoundingClientRect().width;
            const wrapperHeight = wrapper.getBoundingClientRect().height;
            const wrapperWidth = wrapper.getBoundingClientRect().width;
            const scale = Math.min(
                wrapperWidth / contentWidth,
                wrapperHeight / contentHeight
            );
            content.style.transform = `translate(-50%, -50%) scale(${scale})`;
            this.$emit('fit-activated');
        },
        clearFit() {
            const content = this.$refs.content;
            content.style.transform = 'none';
        },
        async togglePlatform(platform) {
            platform.active = !platform.active;
            this.clearFit();
            this.resize();
        },
        selectPlatform(platform) {
            platform.active = true;
            this.scrollTo(platform);
            this.setSingleClass();
        },
        selectGrid() {
            this.isGrid = true;
            this.isHorizontal = false;
            this.isVertical = false;
            this.clearFit();
            this.resize();
        },
        selectVertical() {
            this.isGrid = false;
            this.isHorizontal = false;
            this.isVertical = true;
            this.clearFit();
            this.resize();
        },
        selectHorizontal() {
            this.isGrid = false;
            this.isHorizontal = true;
            this.isVertical = false;
            this.clearFit();
            this.resize();
        },
        scrollTo({ platform }) {
            this.$nextTick(function() {
                const wrapper = this.$refs[platform+'Wrapper'];
                if (wrapper) {
                    this.$emit('scroll-to', wrapper[0]?.offsetTop);
                }
            });
        }
    }
};
</script>

<style lang="scss">
.pws-wrapper{
    position: relative;
}
.pws-header{
    height: 60px;
    background: #EDF2F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: fixed;
    top:85px;
    left:calc(35% + 5px);
    right: 15px;
    z-index: 51;
    box-shadow: 0 9px 7px -7px rgba(0,0,0,0.25);
    &__channels{
        text-align: center;
        color: #03A2EA;
        span{
            display: inline-block;
            margin: 0 20px;
            cursor: pointer;
        }
        .meta-icon{
            transform: translateY(3px);
        }
    }
    &__switcher{
        display: flex;
        flex-direction: column;
        span{
            cursor: pointer;
        }
    }
}
.canvas-title {
    color: $light-blue-text;
    padding-left: 5px;
    line-height: 18px;
    padding-top: 3px;
}
.view-divider {
    margin-left: 15px;
    margin-right: 15px;
    padding-right: 0;
    width: 1px;
    height: 28px;
    background: $gray-light;
    display: inline-block;
    transform: translateY(2px);
}
.pws-wrapper.fit-view {
    top: 60px;
    height: calc(100% - 60px);
    .pws-header__channels span {
        margin: 0 10px;
    }
    .pws-header-previews {
        text-align: left;
        padding-left: 10px;
    }
}
.larger-switcher {
    transform: scale(1.5) translateX(-15px);
}
.pws-platforms {
    display: flex;
}
.ad-studio-previews{
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 30px;
    margin-bottom: 50px;
    @media only screen and (min-width: 2560px) {
        grid-template-columns: 33.3% 33.3% 33.3%;
    }
    &.vertical-view {
        grid-template-columns: 100%;
        grid-gap: 0;
    }
    &.horizontal-view {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 0;
    }
    &.horizontal-view .preview-wrapper {
        display: inline-block;
        vertical-align: top;
        min-width: 530px;
    }
    &.fit-to-window {
        position: relative;
        left: 50%;
        top: 50%;
        bottom: auto;
        transform: translate(-50%, -50%);
        transform-origin: center center;
    }
    &.fit-to-window.horizontal-view {
        position: absolute;
    }
    & > div:empty {
        display: none;
    }

    //some specific styles
    .preview{
        max-width: 375px;
        margin:0 auto;
        margin-bottom: 60px;
        width: 100%;

        .top__holder{
            display: none;
        }
        .preview-container{
            width: 100%;
        }
        .instagram-feed-carousel .image-holder{
            min-height: 375px;
        }
    }
}
.fit-icon,
.vertical-view-icon,
.horizontal-view-icon {
    g path:last-of-type {
        fill: $white;
    }
}
</style>
