import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';
import {
    OBJECT_TYPE_AD,
    OBJECT_TYPE_ADSET,
    OBJECT_TYPE_CAMPAIGN,
    PLATFORM_FACEBOOK, PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK,
} from '@/helpers/globals';
import channels from '@/components/onboarding/channel-setup/channels';

const {
    SET_PM_CAMPAIGNS,
    SET_PM_ADSETS,
    SET_PM_ADS,
    SET_PM_SELECTED_CAMPAIGNS,
    SET_PM_SELECTED_ADSETS,
    SET_PM_SELECTED_ADS,
} = removeNamespace('playbookManagement/', mutationTypes);

/**
 * Creates the preliminary structure for all state where
 * each contains the data for every active platform
 *
 * @returns object
 */
export const getInitialStructure = () => {
    return channels.reduce((structure, channel) => {
        structure[channel.key] = [];
        return structure;
    }, {});
};

/**
 * Retrieves normalized map of the related state
 * for each object to try and simplify actions
 *
 * @param {Object} state
 * @returns {Object}
 */
export const getStateTypeMap = (state) => {
    return {
        [OBJECT_TYPE_CAMPAIGN]: {
            selected: state.selectedCampaigns,
            objects: state.campaigns,
        },
        [OBJECT_TYPE_ADSET]: {
            selected: state.selectedAdSets,
            objects: state.adSets
        },
        [OBJECT_TYPE_AD]: {
            selected: state.selectedAds,
            objects: state.ads
        }
    };
};

export const sumArrayLengths = (total, platform) => {
    return total += platform.length;
};

/**
 * Assists in normalizing metrics for use in campaign management
 *
 * @param {Object} stats
 * @returns
 */
export const getFormattedMetrics = (stats) => {
    if (!stats) {
        return;
    }

    const formattedMetrics = {};

    Object.keys(stats).forEach((item) => {
        formattedMetrics[item] = stats[item].value;
    });

    return formattedMetrics;
};

/**
 * A map of the related mutations for each object to try
 * and simplify actions
 *
 * @var mutationTypeMap
 */
export const mutationTypeMap = {
    [OBJECT_TYPE_CAMPAIGN]: {
        selected: SET_PM_SELECTED_CAMPAIGNS,
        objects: SET_PM_CAMPAIGNS
    },
    [OBJECT_TYPE_ADSET]: {
        selected: SET_PM_SELECTED_ADSETS,
        objects: SET_PM_ADSETS
    },
    [OBJECT_TYPE_AD]: {
        selected: SET_PM_SELECTED_ADS,
        objects: SET_PM_ADS
    }
};

export const HIERARCHY = [
    OBJECT_TYPE_CAMPAIGN,
    OBJECT_TYPE_ADSET,
    OBJECT_TYPE_AD
];

export const SUPPORTED_PLATFORMS = [
    PLATFORM_FACEBOOK,
    PLATFORM_TIKTOK,
    PLATFORM_SNAPCHAT,
    PLATFORM_PINTEREST
];
