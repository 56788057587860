/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question-circle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zM8.546 12.64h2.218v-.503c0-.335.071-.605.213-.812.143-.206.482-.507 1.017-.904.782-.558 1.312-1.06 1.59-1.506.28-.447.42-.977.42-1.59 0-.922-.34-1.658-1.018-2.21-.678-.553-1.591-.83-2.741-.83a7.975 7.975 0 00-3.96 1.047l.913 1.833c1.072-.547 2.026-.82 2.863-.82.48 0 .854.095 1.121.284.268.19.402.466.402.83 0 .323-.093.616-.28.878-.187.262-.574.603-1.16 1.021-.608.447-1.026.868-1.255 1.264-.229.396-.343.862-.343 1.398v.62zm-.268 2.862c0 .452.128.802.385 1.05.257.25.62.373 1.088.373.458 0 .815-.127 1.072-.38.256-.255.385-.602.385-1.043 0-.457-.127-.809-.381-1.054-.254-.246-.613-.369-1.076-.369-.48 0-.845.12-1.096.36-.252.24-.377.594-.377 1.063z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
