<template>
    <div
        class="canvas-toolbar"
        :class="{ 'open-toolbar' : toolbarOpen}">
        <div class="toolbar-header">
            <div class="toolbar-header-title-wrapper">
                <h3
                    v-show="toolbarOpen"
                    class="toolbar-header-title">
                    Asset library
                </h3>
                <div class="toolbar-toggle-wrapper">
                    <div
                        class="toolbar-toggle-content"
                        @click="toolbarOpen = !toolbarOpen">
                        <button
                            class="toolbar-toggle">
                            <icon
                                :name="toolbarOpen? 'chevron-left' : 'chevron-right'"
                                color="#00a2ea"
                                width="15"
                                height="15" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-show="toolbarOpen"
            class="toolbar-content">
            <div class="v-divider v-divider--horizontal" />

            <div class="quick-selector-tool-bar-wrapper">
                <quick-selector-tool-bar
                    @on-expand-library="handleQuickInsertAsset"
                    @search-asset="handleSearch" />
            </div>
            <div
                v-if="isTextEditorVisible &&
                    focusedLayer?.asset.type === 'text' &&
                    focusedLayer?.asset.contentType === 'html' &&
                    focusedLayer?.asset.editableFields?.length"

                class="toolbar-assets-results">
                <text-asset-editor
                    :editable-fields="focusedLayer?.asset.editableFields" />
            </div>
            <div
                v-else
                class="toolbar-assets-results">
                <asset-library-tabs
                    :current-media-type="currentMediaType"
                    @set-media-type="setMediaType" />
                <div
                    class="assets-results-wrapper">
                    <assets-results
                        :loading="loadingAssets"
                        :hide-checked-asset="true"
                        :insert-mode="true"
                        :assets="assets"
                        :selected-assets="selectedAssets"
                        @asset-clicked="handleAssetClicked"
                        @page-bottom-reached="findAssets" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/components/globals/Icon';
    import { AssetServiceFactory } from '@/creative-studio/domain/assets/services/AssetServiceFactory';
    import AssetsResults from '../../../assets/AssetsResults.vue';
    import AssetLibraryTabs from '../../../assets/AssetLibraryTabs.vue';

    import { mapActions, mapGetters, mapState } from 'vuex';
    import TextAssetEditor from './text-assets-quick-library/TextAssetEditor.vue';
    import QuickSelectorToolBar from './QuickSelectorToolBar.vue';
    import EventBus from '@/event-bus';
    import { debounce } from 'lodash';

    import { AssetLibraryMode } from '@/components/creative-studio/common/types';
import { AssetType } from '@/creative-studio/domain/assets/models/types';

    export default {
        components: {
            Icon,
            AssetsResults,
            TextAssetEditor,
            QuickSelectorToolBar,
            AssetLibraryTabs
        },
        props: {
            focusedLayer: {
                required: false,
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                currentTabIndex: 0,
                toolbarOpen: true,
                loadingAssets: false,
                assetType: '',
                isInitialLoad: false,
                assetFilters: {
                    filter: {
                    type: 'shape',
                    categoryIds: null,
                    color: null,
                    isTransparent: null,
                    year: null,
                    ownership: null,
                    query: null,
                    },
                     order: {
                        sortBy: 'id',
                        orderBy: 'desc',
                    },
                   type: null
                },
                assets: [],
                selectedAssets: [],
                selectedSort: null,
                currentMediaType: 'all',
                page: 1,
                searchTerm: '',
                currentFocusedAssetType: null,
            };
        },
        computed: {
            ...mapGetters([
                'isTextEditorVisible'
            ]),
            ...mapState({
                currentAgencyId: (state) => state.agency.currentAgency.id,
             }),
        },
        watch: {
        isTextEditorVisible(isVisible) {
            this.toolbarOpen = true;
            if(!isVisible) {
                    this.currentMediaType = '';
                    this.setMediaType(this.focusedLayer?.asset.type, { forceReload: true });
            }
        },
        focusedLayer(newFocusedAsset, oldFocusedAsset) {
            if(!newFocusedAsset) {
                this.selectedAssets = [];
                return;
            }
            if(oldFocusedAsset?.asset.type === newFocusedAsset?.asset.type) {
                return;
            }
            if(this.currentMediaType === AssetType.All) {
                return;
            }

            this.setMediaType(newFocusedAsset?.asset.type, { forceReload: false });
        },
        toolbarOpen(value) {
            this.$emit('on-toolbar-visible', value);
        },

        },
        mounted() {
            this.findAssets();
            EventBus.$on('new-asset-upload', this.insertUploadedAsset);
        },
        beforeDestroy() {
            EventBus.$off('new-asset-upload');
        },
        methods: {
            ...mapActions([
                'setFocusedLayer',
                'setTextEditorVisible',
                'insertSelectedAssetsAsLayers',
                'setAssetLibraryAssetType',
                'setAssetLibraryMode',
                'setAssetLibraryActiveStatus',
                'setFocusedLayerIsClipping'
            ]),
            setMediaType(mediaType, { forceReload = false } = {}) {
                if(mediaType === this.currentMediaType && !forceReload) {
                    return;
                }
                this.currentMediaType = mediaType;
                this.assets= [];
                this.page = 1;
                this.findAssets();
            },
            insertUploadedAsset(asset) {
                this.assets.unshift(asset);

            },
            handleSearch(searchText) {
                this.searchTerm = searchText;
                this.onSearch();
            },
            onSearch: debounce(function() {
                this.assets= [];
                this.page = 1;
                this.findAssets();
            }, 500),
            findAssets: debounce(async function() {
                if(!this.page) {
                   return;
                }
                this.loadingAssets = true;
                const assetService = AssetServiceFactory.createApiAssetService();
                 // taken from ads library assets tab
                let assetsToShow = [1,3,4];

                if (this.currentMediaType == AssetType.Image) {
                    assetsToShow = [1];
                }
                if (this.currentMediaType == AssetType.Shape) {
                    assetsToShow = [3];
                }
                if (this.currentMediaType == AssetType.Text) {
                    assetsToShow = [4];
                }

                const filter = {
                        agency_id: this.currentAgencyId,
                        q: this.searchTerm,
                        page_size: 20,
                        page: this.page,
                        asset_types: assetsToShow,
                        order: {
                            field: 'created_at.date.keyword',
                            direction: 'DESC'
                        },
                        tags: {}
                };

                const response = await assetService.findAssets(filter);
                const responseAssets = response.data;
                this.assets.push(...responseAssets);
                this.page = response.meta.next_page;
                this.loadingAssets = false;

            }, 100),
            handleAssetClicked(asset) {
                this.insertSelectedAssetsAsLayers([asset]);
                if(this.focusedLayer?.alterations.isClipping) {
                    this.setFocusedLayerIsClipping();
                }
                this.selectedAssets = [asset];
            },
            handleQuickInsertAsset() {
               this.triggerOpenLibrary();
            },
            triggerOpenLibrary() {
                this.setAssetLibraryMode(AssetLibraryMode.Insert);
                this.setAssetLibraryAssetType(AssetType.All);
                this.setAssetLibraryActiveStatus(true);
            },

        }
    };
</script>

<style lang="scss">

.canvas-toolbar {
    margin-top: -5px;
    height: 100%;
    min-height: 100vh;
    width: 25px;
    position: absolute;
    z-index: 10;
    box-shadow: 10px 0 14px -10px rgba(0, 0, 0, 0.15);
}

.canvas-toolbar.open-toolbar {
    width: 390px;
    background-color: $white;
    transition: all 0.1s;
}


.toolbar-header-title-wrapper {
    width: 100%;
    padding: 10px 0;
    background-color: $white;
    display: flex;
    margin-top: 5px;
}

.toolbar-header-title {
    margin-top: 5px;
    padding-left: 20px;
    color:  #4e555b;
    font-size: 14px;
}


.toolbar-toggle {
    margin-left: auto;
    padding-right: 30px;
    width: 20px;
    height: 20px;
}

.toolbar-content {
    width: 100%;
    height: 100%;
    padding: 5px 0px;

}

.toolbar-content .v-divider {
    color: #B1BCC1;
}

/* custom scrollbar */
.toolbar-assets-results ::-webkit-scrollbar {
  width: 18px;
}

.custom-text-assets-class .asset-container {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 10%;
    border: none;
}

.custom-text-assets-class  {
    border-top: solid 1px rgba(143, 158, 166, 0.5);
}

.assets-results-wrapper .custom-text-assets-class{
    width: 80% !important;
}

.assets-results-wrapper {
    width: calc(100% + 25px);
    max-height: calc(100vh - 330px);
}

.toolbar-assets-results ::-webkit-scrollbar-track {
  background-color: transparent;
}

.toolbar-assets-results ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.toolbar-assets-results::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.toolbar-assets-results {
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    max-width:  500px;
}
.toolbar-assets-results .assets-results-wrapper {
    width: calc(100% - 5px);
}

.toolbar-assets-results .assets-flex-wrapper {
    padding: 5px;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, 100px);
}

.toolbar-assets-results .asset-item {
    width: 100px;
    height: 100px;
}

.quick-selector-tool-bar-wrapper {
    padding-right: 30px;
    margin-left: 10px;
}

.tabs-navigation {
    max-width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
}

.assets-results-wrapper {
    margin-left: 5px;
    height: 100vh;
    overflow: auto;
    padding-bottom: 50px !important;
}

.toolbar-toggle-wrapper {
    filter: drop-shadow(15px 3px 14px rgba(0,0,0,0.08));
    position: absolute;
    right: 0px;
    top: 35%;
    margin-right: -28px;
}

.toolbar-toggle-content {
    padding: 50px 10px 50px 25px;
    overflow:hidden;
    background-color: #fff;
    clip-path: polygon(39% 0, 74% 23%, 74% 76%, 39% 100%);
    cursor: pointer;
}
</style>