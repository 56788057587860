/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert': {
    width: 18,
    height: 20,
    viewBox: '0 0 18 20',
    data: '<path pid="0" d="M12 18c-.162 1.123-1.446 2-3 2s-2.837-.877-3-2zM7.59.496A1.88 1.88 0 019.126.018c.956.131 1.677.999 1.677 2.019v1.706c0 .228.14.432.35.507C14.053 5.292 16 8.126 16 11.3v.87l.752.556c.73.538 1.184 1.417 1.242 2.38l.006.206V17H0v-1.687c0-1.043.466-2.01 1.248-2.587L2 12.17v-1.04c0-2.895 1.73-5.508 4.331-6.585l.247-.097a.537.537 0 00.345-.506V1.998c0-.573.243-1.12.667-1.502z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
