<template>
    <div class="">
        <div class="preview__image_wrapper">
            <div class="preview__image">
                <div>
                    <!-- This only edits one aspect ratio asset at a time -->
                    <div v-if="!(assetConfig.asset_type === ORIGINAL_ASSET_TYPE) && advancedMediaControls">
                        <asset-edit-boxes
                            :asset-configs="[assetConfig]"
                            :advanced-media-controls-toggle="advancedMediaControls"
                            :asset-url="fileUrl" />
                    </div>

                    <!-- This edits all aspect ratio assets at the same time -->
                    <div v-if="!(assetConfig.asset_type === ORIGINAL_ASSET_TYPE) && !advancedMediaControls">
                        <div v-if="allowSupportedChannelsToControlMediaStyles">
                            <div>
                                <asset-edit-boxes
                                    :asset-configs="filteredAssetConfigs"
                                    :advanced-media-controls-toggle="advancedMediaControls"
                                    :asset-url="fileUrl" />
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="assetConfigIndex === 0">
                                <asset-edit-boxes
                                    :asset-configs="filteredAssetConfigs"
                                    :advanced-media-controls-toggle="advancedMediaControls"
                                    :asset-url="fileUrl" />
                            </div>
                            <div v-else>
                                <div
                                    class="asset-edit-boxes-height" />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div
                            v-if="assetConfig.asset_type === IMAGE_ASSET_TYPE || assetConfig.asset_type === VIDEO_ASSET_TYPE"
                            class="mb-3">
                            <div class="mb-3">
                                <span>{{ assetConfig.aspect_ratio }} Media Style</span>
                            </div>
                            <div v-if="loading">
                                <loader />
                            </div>
                            <div
                                v-if="!loading"
                                class="ma-1">
                                <img
                                    v-if="assetConfig.asset_type === IMAGE_ASSET_TYPE && fileUrl"
                                    :src="fileUrl"
                                    alt=""
                                    class="shadow">
                                <video
                                    v-if="assetConfig.asset_type === VIDEO_ASSET_TYPE && fileUrl"
                                    muted
                                    loop
                                    controls
                                    class="shadow">
                                    <source
                                        :src="fileUrl"
                                        type="video/mp4">
                                </video>
                            </div>
                            <div class="mb-2 mt-3">
                                <upload-asset
                                    :selected-platforms="platforms"
                                    :asset-config="assetConfig"
                                    :advanced-media-controls="advancedMediaControls" />
                            </div>


                            <div class="preview__desc col-span-2">
                                <div
                                    v-for="(placement, index) in assetConfig.placements"
                                    :key="index">
                                    <div class="flex">
                                        <div v-if="shouldShowPlacment(placement)">
                                            <div class="flex mb-3">
                                                <div class="center mb-2">
                                                    <icon
                                                        :name="placement.platform === 'facebook' ? 'meta' : placement.platform"
                                                        size="25" />
                                                </div>
                                                <div class="ml-3">
                                                    <div
                                                        v-for="(configPlacement, configIndex) in placement.placements"
                                                        :key="configIndex"
                                                        class="smaller-text">
                                                        {{ configPlacement }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="assetConfig.asset_type === ORIGINAL_ASSET_TYPE && allowSupportedChannelsToControlMediaStyles">
                            <!-- original asset type -->
                            <div class="asset-column mb-3">
                                <div class="blue-box">
                                    This media style is controlled by {{ platformsThatAllowSupportedChannelsToControlMediaStylesAndAreSelected.join(', ') }}
                                </div>
                                <div class="media-column">
                                    <div class="mt-3 mb-3">
                                        Original Media
                                    </div>
                                    <div class="ma-1">
                                        <img
                                            v-if="originalAssetTypeName == IMAGE_ASSET_TYPE || originalAssetTypeName == DESIGN_ASSET_TYPE"
                                            :src="originalAssetUrl"
                                            class="original-media-image shadow"
                                            alt="">
                                        <video
                                            v-else-if="originalAssetTypeName === VIDEO_ASSET_TYPE"
                                            muted
                                            paused
                                            controls
                                            class="shadow">
                                            <source
                                                :src="originalAssetUrl"
                                                type="video/mp4">
                                        </video>
                                    </div>
                                </div>
                            </div>

                            <div class="preview__desc col-span-2 mt-4">
                                <div
                                    v-for="(placement, index) in assetConfig.placements"
                                    :key="index">
                                    <div class="flex">
                                        <div v-if="shouldShowOriginalPlacment(placement)">
                                            <div class="flex mb-3">
                                                <div class="center mb-2">
                                                    <icon
                                                        :name="placement.platform === 'facebook' ? 'meta' : placement.platform"
                                                        size="25" />
                                                </div>
                                                <div class="ml-3">
                                                    <div
                                                        v-for="(configPlacement, configIndex) in placement.placements"
                                                        :key="configIndex"
                                                        class="smaller-text">
                                                        {{ configPlacement }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/Loader.vue';
import UploadAsset from '@/components/ad-studio/editing/common/UploadAsset.vue';
import {
    IMAGE_ASSET_TYPE,
    VIDEO_ASSET_TYPE,
    ORIGINAL_ASSET_TYPE,
    DESIGN_ASSET_TYPE
} from '@/components/ad-studio/store/constants.js';
import AssetEditBoxes from '@/components/ad-studio/editing/common/AssetEditBoxes.vue';
import { DEPLOYMENT_PLATFORMS } from '@/components/ad-deployment/store/constants';

export default {
    components: {
        Icon,
        Loader,
        UploadAsset,
        AssetEditBoxes
    },
    props: {
        assetConfigIndex: {
            type: Number,
            required: true
        },
        assetConfig: {
            type: Object,
            required: true
        },
        filteredAssetConfigs: {
            type: Array,
            required: true
        },
        allowSupportedChannelsToControlMediaStyles: {
            type: Boolean,
            required: true,
        },
        advancedMediaControls: {
            type: Boolean,
            required: true,
        },
        selectedPlatforms: {
            type: Array,
            required: false,
            default: null
        }
    },
    data() {
        return {
            IMAGE_ASSET_TYPE,
            VIDEO_ASSET_TYPE,
            ORIGINAL_ASSET_TYPE,
            DESIGN_ASSET_TYPE,
            color: '#194d33',
            showColorPicker: false,
        };
    },
    computed: {
        ...mapState({
            ads: state => state.adStudio.ads,
            adIndex: state => state.adStudio.adIndex,
        }),
        filteredPlatform() {
            return this.platforms.find(platform => {
                if (this.allowSupportedChannelsToControlMediaStyles) {
                    return !DEPLOYMENT_PLATFORMS.find(deployment_platform => deployment_platform.name === platform).channelControlsMediaStyles && this.assetConfig.platforms.includes(platform);
                } else {
                    return this.assetConfig.platforms.includes(platform);
                }
            });
        },
        fileUrl() {
            return this.ads?.[this.adIndex]?.configuration?.platform_specific?.[this.filteredPlatform]?.creatives?.[0]?.assets.find(asset => {
                return asset?.aspect_ratio === this.assetConfig?.aspect_ratio
                    && asset?.asset_type?.name === this.assetConfig?.asset_type;
            })?.url;
        },
        loading() {
            return this.assetConfig?.loading;
        },
        platforms() {
            return this.selectedPlatforms ?? this.$store.state.adStudio.ads[this.adIndex].configuration.selected_platforms;
        },
        originalAsset() {
            return this.ads?.[this.adIndex]?.configuration?.all_platforms?.creatives?.[0]?.assets?.[0];
        },
        originalAssetUrl() {
            return this.originalAsset?.url;
        },
        originalAssetTypeName() {
            return this.originalAsset?.asset_type?.name;
        },
        platformsThatAllowSupportedChannelsToControlMediaStylesAndAreSelected() {
            return DEPLOYMENT_PLATFORMS.filter(deploymentPlatform => deploymentPlatform.channelControlsMediaStyles === true)
                .filter(deploymentPlatform => this.platforms.includes(deploymentPlatform.name))
                .map(deploymentPlatform => deploymentPlatform.displayName);
        }
    },
    methods: {
        shouldShowOriginalPlacment(placement) {
            return DEPLOYMENT_PLATFORMS.filter(deploymentPlatform => deploymentPlatform.channelControlsMediaStyles === true)
                .filter(deploymentPlatform => this.platforms.includes(deploymentPlatform.platform))
                .map(deploymentPlatform => deploymentPlatform.platform)
                .includes(placement.platform);
        },
        shouldShowPlacment(placement) {
            return this.platforms.includes(placement.platform) && this.showControlMediaStyles(placement);
        },
        showControlMediaStyles(placement) {
            if (this.allowSupportedChannelsToControlMediaStyles) {
                return DEPLOYMENT_PLATFORMS.find(deploymentPlatform => deploymentPlatform.platform === placement.platform)?.channelControlsMediaStyles === false;
            } else {
                return true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:45px;
    .file-input{
        display: none;
    }
    &__image-wrapper{
        text-align: center;
        position: relative;
    }
    &__image{
        // background: $gray-light;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        overflow: hidden;
        &.active{
            background: transparent;
        }
        img, video{
            max-width: 100%;
            position: relative;
            display: inline-block;
            z-index: 2;
            vertical-align: top;
        }
    }
    &__bg{
        position: absolute;
        z-index: 1;
        top: -5px;
        left:-5px;
        right:-5px;
        bottom: -5px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        filter: blur(5px);
        display: flex;
        align-items: center;
        justify-content: center;
        video{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__tooltip{
        position: fixed;
        z-index: 99;
        top: 150px;
        transform: translateX(200px);
        background: white;
        padding:15px;
        padding-top: 70px;
        box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.25);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__tooltip-bg{
        position: absolute;
        top: 60px;
        left:15px;
        right: 15px;
        bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        video{
            object-fit: cover;
            width: 100%;
            height: 100%;
            filter: blur(5px);
        }
    }
    &__tooltip-img{
        position: relative;
        z-index: 8;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img, video{
            max-width: 353px;
            height: auto;
            vertical-align: top;
        }
    }
    &__ratio{
        font-weight: 700;
        color:#03A2EA;
    }
    &__used{
        font-weight: 700;
        color:#03A2EA;
    }
    &__link{
        text-decoration: underline;
        color:#03A2EA;
        margin-top:5px;
        display: inline-block;
        cursor: pointer;
    }
    &__upload-wrapper{
        width:200px;
        label{
            cursor: pointer;
        }
    }
    &__upload{
        text-decoration: underline;
        color:#03A2EA;
        display: inline-block;
    }
    &__error{
        color: #FF3E02;
        margin-top: 5px;
    }
    &__success{
        color: #51C450;
        margin-top: 5px;
    }
    &__triangle{
        position: absolute;
        z-index: 100;
        width:15px;
        height: 15px;
        right: 0px;
        top: 30%;
        background: white;
        transform: rotate(45deg);
        box-shadow: 0 2px 10px 1px rgba(0,0,0,0.25);
    }
    &__triangle-hide{
        position: absolute;
        z-index: 101;
        width:15px;
        height: 45px;
        right:-7px;
        top: calc(30% - 15px);
        background: white;
    }
    &__tooltip-title{
        position: absolute;
        top: 15px;
        left:15px;
    }
}
.flex {
    display: flex;
}
.capital {
    font-weight: 700;
    text-transform: capitalize;
}
.grid {
    display: grid;
}

.asset-edit-boxes-height {
    height: 124px;
    width: 100%;
}

.asset-edit-boxes-margin {
    margin-top: 124px;
}

.learn-more{
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #03A2EA;
    cursor: pointer;
}
.original-media-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.line-right{
    border-right: 1px solid #BABFC1;
    padding-right: 20px;
}
.blue-box{
    background-color: RGBA(0, 162, 234, 0.15);
    padding-top: 20px;
    padding-bottom: 64px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    position: relative;
    align-items: center;
}
.toggle-row {
    display: flex;
    align-items: center;
}

.grid-cols-4 {
    grid-template-columns: repeat(4,minmax(0,1fr));
}
.center {
    justify-content: center;
    align-items: center;
}

.gap-4 {
    gap: 1rem;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.smaller-text {
    font-size: 13px;
    font-weight: 400;
}
.border-right {
    border-right: 1px solid #E0E0E0;;
}

.shadow {
    // border: 1px solid rgb(185, 188, 190);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
</style>
<style lang="scss">
.preview__image{
    .loader-text{
        margin-bottom: 10px !important;
    }
}
</style>
