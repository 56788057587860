var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'data-field-wrapper': true,
        'no-indent': _vm.noIndent,
        'no-wrap': _vm.noWrap
    }},[(_vm.$slots.label || _vm.label)?_c('div',{staticClass:"data-field-label"},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2):_vm._e(),_c('div',{staticClass:"data-field-data",style:({
            marginLeft: _vm.activeMarginLeft
        })},[(_vm.$slots.indicator)?_c('div',{staticClass:"data-field-indicator"},[_vm._t("indicator")],2):_vm._e(),_c('div',{staticClass:"data-field-value"},[_vm._t("default")],2),(_vm.$slots.actions)?_c('div',{staticClass:"data-field-actions"},[_vm._t("actions")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }