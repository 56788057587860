/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.465.021c.264 0 .48.19.48.421V19.58c0 .232-.216.421-.48.421h-7.69c-.263 0-.479-.19-.479-.421v-1.432c0-.231.216-.42.48-.42h5.605V2.252h-5.606c-.263 0-.479-.19-.479-.421V.422c0-.233.216-.422.48-.4zM8.094 4.908a.506.506 0 01.663 0l5.327 4.809a.395.395 0 010 .598l-5.327 4.81a.506.506 0 01-.663 0l-1.075-.97a.395.395 0 010-.6l2.698-2.435H.594c-.251 0-.457-.186-.457-.413V9.325c0-.227.206-.413.457-.413h9.123L7.02 6.497a.395.395 0 010-.599z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
