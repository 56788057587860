import { HttpFactory } from '@/creative-studio/domain/common/services/http/HttpFactory';
import { ImageAssetMapper } from './mappers/ImageAssetMapper';
import { FindAssetsMapper } from './mappers/FindAssetsMapper';
import { FileServiceFactory } from '@/creative-studio/domain/assets/services/FileServiceFactory';
import { AssetType } from '@/creative-studio/domain/assets/models/types';
import { TEXT_ASSET_CONTENT_MODE } from '@/creative-studio/domain/layered-designer/models/Layer';
import HttpClient from '@/http';

import { FIND_ASSETS_PATH, CREATE_ASSETS_PATH, ASSET_RESOURCE_PATH } from '@/creative-studio/domain/assets/services/routes';
import { AssetMapper } from './mappers/AssetMapper';
export class AssetApiService {

    constructor() {
        this.httpClient = HttpFactory.createClient();
        this.externalHttpClient = new HttpClient();

    }

    async findAssets(filter) {
        const requestParams = FindAssetsMapper.toRequest(filter);
        const response = await this.externalHttpClient.post(FIND_ASSETS_PATH, requestParams);
        const assetResources = response.data.data;
        const mappedAssets = assetResources.map((asset) => AssetMapper.toDomain(asset, { loadFromRemote: true }));

        return Object.freeze({
            data: mappedAssets,
            meta: response.data.meta
        });
    }

    async showAsset(id) {
        const response = await this.externalHttpClient.get(`${ASSET_RESOURCE_PATH}/${id}`);
        const assetResource = response.data.data;
        return ImageAssetMapper.toDomain(assetResource, { loadFromRemote: true });
    }

    async createAssetFromRawFile(rawFile) {
        const fileService = FileServiceFactory.createApiFileService();
        const fileInstance = await fileService.uploadFile(rawFile);
        return await this.createAsset(fileInstance);
    }

    async createAsset(file) {
        const requestParams = ImageAssetMapper.toRequest(file);

        const { data: assetResource } = await this.httpClient.post(CREATE_ASSETS_PATH, requestParams);

        return AssetMapper.toDomain(assetResource, file);
    }

    createTextAsset() {
        const assetResource = {
            id: 1,
            type: AssetType.Text,
            name: 'test_text',
            thumbnail: '',
            text: 'Text placeholder',
            fontFamily: 'Open Sans',
            contentType: TEXT_ASSET_CONTENT_MODE.simpleText,
            fontSize: '50px',
            color: '#000000',
            content: '<div id="editable-text" contenteditable="true">Text placeholder</div>'
        };

        return AssetMapper.toDomain(assetResource);
    }
}
