<template>
    <div class="a-studio">
        <div class="a-studio__bg" />
        <div class="a-studio__content">
            <ad-studio-header
                :open-from-deployment="openFromEditing"
                :is-opened-from-layered-designer="isOpenedFromLayeredDesigner"
                :uploading-completed="uploadingCompleted"
                @save-and-exit="saveAndExit"
                @close="close" />
            <ad-studio-uploading
                v-if="!uploadingCompleted && !openFromEditing"
                key="normal-uploader"
                @file-uploaded="setUploadedFile($event, false)"
                @close="close" />
            <ad-studio-editing
                v-if="uploadingCompleted || openFromEditing"
                :assets="assets"
                :second-step-active="secondStepCompleted || openFromEditing"
                :complete="complete"
                :open-from-deployment="openFromEditing"
                @close="close" />
            <ad-studio-footer
                ref="adStudioFooter"
                :ad-index="adIndex"
                :second-step-active="secondStepCompleted || openFromEditing"
                :open-from-deployment="openFromEditing"
                :is-opened-from-layered-designer="isOpenedFromLayeredDesigner"
                :form-is-valid="formIsValid"
                @finish-first-step="openSecondStep"
                @close="close" />
            <safezone-slideout />
            <new-ad-popup
                v-if="showNewAdModal"
                @close="showNewAdModal = false"
                @new-ad="setUploadedFile($event, true)" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdStudioHeader from '@/components/ad-studio/AdStudioHeader';
import AdStudioFooter from '@/components/ad-studio/AdStudioFooter';
import AdStudioEditing from '@/components/ad-studio/editing/AdStudioEditing';
import AdStudioUploading from '@/components/ad-studio/uploading/AdStudioUploading';
import NewAdPopup from '@/components/ad-studio/NewAdPopup';
import EventBus from '@/event-bus';
import SafezoneSlideout from '@/components/ad-studio/SafezoneSlideout';
import { resetAssetConfigurations } from '@/components/ad-studio/store/constants.js';

export default {
    name: 'AdStudio',
    components: {
        AdStudioHeader,
        AdStudioFooter,
        AdStudioEditing,
        AdStudioUploading,
        SafezoneSlideout,
        NewAdPopup
    },
    props: {
        openFromEditing: {
            type: Boolean,
            required: false,
            default: false
        },
        isOpenedFromLayeredDesigner: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedAssets: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            uploadingCompleted: false,
            secondStepActive: false,
            complete: false,
            assets: [],
            shouldShowSafezone: false,
            showNewAdModal: false,
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
            ads: (state) => state.adStudio.ads,
            adIndex: (state) => state.adStudio.adIndex,
        }),
        secondStepCompleted() {
            return this.assets[this.adIndex]?.first_step_completed?.status;
        },
        formIsValid() {
            return this.ads.every((ad) => {
                const selectedPlatforms = ad?.configuration?.selected_platforms;

                // don't validate on deleted ads
                if (ad.deleted == true) {
                    return true;
                }

                // The user has not selected a platform yet
                if (selectedPlatforms?.length == 0) {
                    return false;
                }

                return selectedPlatforms?.every((platform) => {
                    return ad?.configuration?.platform_specific?.[platform]?.form_valid;
                });
            });
        }
    },
    mounted() {
        if (this.openFromEditing) {
            this.secondStepActive = true;
        }
        if (this.selectedAssets.length) {
            this.selectedAssets.forEach((asset, index) => {
                this.setUploadedFile({
                    asset,
                    fileName: asset.display_name,
                    index,
                    totalFiles: this.selectedAssets.length
                }, false);
            });
        }
    },
    created() {
        EventBus.$on('close-ad-studio', () => this.close());
        EventBus.$on('open-new-ad-modal', () => this.showNewAdModal = true);
    },
    methods: {
        close() {
            this.$emit('close');
            this.$store.commit('adStudio/clearAdStudioData');
        },
        saveAndExit() {
            this.$refs.adStudioFooter.saveAndExit();
        },
        setUploadedFile({ asset, fileName, index, totalFiles }, isAddNewAdFlow) {
            if (isAddNewAdFlow) {
                this.$store.commit('adStudio/setNewAdFlow', true);
            } else {
                this.$store.commit('adStudio/setNewAdFlow', false);
            }
            // Create new ad structure and set the asset in it
            this.$store.commit('adStudio/initAdStudioData', {
                agencyId: this.currentAgency.id,
                asset,
                fileName
            });

            this.saveThumbnail(asset, this.adIndex);

            this.fileName = fileName;
            this.originalAsset = asset;
            resetAssetConfigurations();
            this.assets.push(asset);

            const lastIndex = index + 1;
            if (lastIndex === totalFiles) {
                isAddNewAdFlow
                    ? this.showNewAdModal = false
                    : this.uploadingCompleted = true;

                EventBus.$emit('show-new-ad-button');
            }
        },
        openSecondStep(index) {
            if (this.secondStepActive) {
                this.complete = true;
            } else {
                const data = {
                    first_step_completed: {
                        status: true
                    }
                };
                Object.assign(this.assets[index], data);
                const currentAssets = this.assets;
                //refresh assets with new data to trigger 'computed' property
                this.assets = [];
                this.assets = currentAssets;
            }
        },
        async saveThumbnail(asset, adIndex) {
            // todo catch errors here
            const imageAssetResponse = await this.$apiRepository.transformAsset({
                'asset': asset.url.replace(/\.[^/.]+$/, '.jpg'),
            });

            const cloudinaryPublicId = imageAssetResponse.data.public_id.replace('/', ':');

            // todo catch errors here
            const thumbnailResponse = await this.$apiRepository.transformAsset({
                'asset': imageAssetResponse.data.url, // replace the extension with .jpg
                'proxy': `c_pad,h_512,w_512/c_fill,e_blur:2000,g_center,h_512,u_${cloudinaryPublicId},w_512`
            });

            // transform the original asset into a 512 x 512 asset and set the thumbnail on the ad
            this.$store.commit('adStudio/setAdThumbnail', {
                adIndex,
                thumbnailUrl: thumbnailResponse.data.url,
            });
        }
    }
};
</script>

<style lang="scss">
.a-studio{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 200;
    &__bg {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        z-index: 3001;
        background: rgba(0,0,0,0.6);
    }
    &__content {
        position: relative;
        z-index: 3002;
        background: white;
        border-radius: 10px;
        overflow: hidden;
        margin: 15px;
        min-height: calc(100vh - 30px);
    }
}
</style>

<style scoped lang="scss">
    .v-dialog__content {
        z-index: 10000 !important;
    }
</style>
