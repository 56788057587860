<template>
    <div class="layered-designer-header-card-controls">
        <div
            v-if="focusedLayer !== null"
            class="layered-designer-header-card-controls-container">
            <div
                v-if="isTextLayer"
                class="row-wrapper">
                <div
                    class="canvas-toolbar-option toolbar-font-select">
                    <v-select
                        v-model="localFontFamily"
                        class="styled-field custom-font-select"
                        :items="availableFonts" />
                </div>
                <div
                    class="canvas-toolbar-option">
                    <div class="font-size-input-wrapper">
                        <div
                            class="font-size-input-action"
                            @click="handleFontSizeAction(fontSizeActions.minus)">
                            -
                        </div>
                        <input
                            v-model="localFontSize"
                            type="number"
                            min="1"
                            step="1"
                            class="font-size-input"
                            name=""
                            @keydown="handleFontSizeInput">
                        <div
                            class="font-size-input-action"
                            @click="handleFontSizeAction(fontSizeActions.plus)">
                            +
                        </div>
                    </div>
                </div>
                <div

                    v-if="isTextLayer && !isCustomTextAsset"
                    class="canvas-toolbar-option">
                    <button
                        class="popup-control-button"
                        title="Text fill color">
                        <div
                            class="color-square"
                            :style="{backgroundColor: focusedLayer.asset.fillColor}"
                            @click="setCurrentPickerPopup(pickerPopups.REGULAR_COLOR_PICKER)" />
                    </button>
                    <color-picker-popup
                        v-model="localFillColor"
                        v-custom-click-outside="{
                            handler: handleOutsidePickerClick,
                            identifier: pickerPopups.REGULAR_COLOR_PICKER
                        }"
                        :open="isPopupOpen(pickerPopups.REGULAR_COLOR_PICKER)" />
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        class="popup-control-button"
                        title="Text bold"
                        @click="updateFontWeight()">
                        <icon
                            :color="focusedLayer.asset.weight === fontWeights.bold ? activeColor : 'gray'"
                            name="bold"
                            width="17"
                            height="17" />
                    </button>
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        class="popup-control-button"
                        title="Text italic"
                        @click="updateFontStyle()">
                        <icon
                            :color="focusedLayer.asset.fontStyle === fontStyles.italic ? activeColor : 'gray'"
                            name="italic"
                            width="18"
                            height="18" />
                    </button>
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Align text"
                        class="popup-control-button"
                        @click="setCurrentPickerPopup(pickerPopups.TEXT_ALIGN_PICKER)">
                        <icon
                            :color="!isPopupOpen(pickerPopups.TEXT_ALIGN_PICKER) ? 'gray' : activeColor"
                            width="22"
                            height="22"
                            name="text-align-center" />
                        <!-- :name="!isPopupOpen(pickerPopups.TEXT_ALIGN_PICKER) ? 'alignment' : 'alignment-selected'" -->
                    </button>
                    <text-align-picker-popup
                        v-custom-click-outside="{
                            handler: handleOutsidePickerClick,
                            identifier: pickerPopups.TEXT_ALIGN_PICKER
                        }"
                        :open="isPopupOpen(pickerPopups.TEXT_ALIGN_PICKER)" />
                </div>
            </div>
            <div class="v-divider v-divider--vertical" />
            <div
                class="row-wrapper">
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Flip horizontal"
                        class="popup-control-button"
                        @click="toggleFocusedLayerFlip(flipDirections.horizontal)">
                        <icon
                            :color="focusedLayer.alterations.isHorizontalFlipped ? activeColor : 'grey'"
                            name="flip-horizontal"
                            width="25"
                            height="25" />
                    </button>
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Flip vertical"
                        class="popup-control-button"
                        @click="toggleFocusedLayerFlip(flipDirections.vertical)">
                        <icon
                            :color="focusedLayer.alterations.isVerticalFlipped ? activeColor : 'gray'"
                            name="flip-vertical"
                            width="25"
                            height="25" />
                    </button>
                </div>
                <div
                    v-if="isClippable"
                    class="canvas-toolbar-option">
                    <button
                        title="Crop image"
                        class="popup-control-button"
                        @click="handleIsClippingUpdate()">
                        <icon
                            :color="focusedLayer.alterations.isClipping ? activeColor : 'gray'"
                            name="crop"
                            width="20"
                            height="20" />
                    </button>
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Fixed asset"
                        class="popup-control-button"
                        @click="handleToggleFixedAssetSize">
                        <icon
                            :color="focusedLayer.alterations.hasToKeepSize ? activeColor: 'gray'"
                            name="fixed"
                            width="22"
                            height="22" />
                    </button>
                </div>
                <div
                    v-if="!isTextLayer"
                    class="canvas-toolbar-option">
                    <button
                        title="Object fit"
                        class="popup-control-button"
                        @click="setFocusedLayerIsBeingFit">
                        <icon
                            :color="focusedLayer.alterations.isBeingFit ? activeColor: 'gray'"
                            name="resize"
                            width="19"
                            height="19" />
                    </button>
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Anchors"
                        class="popup-control-button anchors-icon-container"
                        @click="setCurrentPickerPopup(pickerPopups.ANCHOR_PICKER)">
                        <SVGAnchorSmall />
                    </button>
                    <anchor-picker-popup
                        v-custom-click-outside="{
                            handler: handleOutsidePickerClick,
                            identifier: pickerPopups.ANCHOR_PICKER
                        }"
                        :open="isPopupOpen(pickerPopups.ANCHOR_PICKER)" />
                </div>
            </div>
            <div class="v-divider v-divider--vertical" />

            <div
                class="row-wrapper">
                <div
                    v-if="isShapeLayer || isCustomTextAsset"
                    class="canvas-toolbar-option">
                    <button
                        title="Gradient selector"
                        class="popup-control-button"
                        @click="setCurrentPickerPopup(pickerPopups.GRADIENT_PICKER)">
                        <div
                            class="color-square"
                            :style="gradientPreviewStyle"
                            @click="setCurrentPickerPopup(pickerPopups.REGULAR_COLOR_PICKER)" />
                    </button>
                    <gradient-picker-popup
                        v-model="localGradientInfo"
                        v-custom-click-outside="{
                            handler: handleOutsidePickerClick,
                            identifier: pickerPopups.GRADIENT_PICKER
                        }"
                        :open="isPopupOpen(pickerPopups.GRADIENT_PICKER)" />
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Shadow selector"
                        class="popup-control-button"
                        @click="setCurrentPickerPopup(pickerPopups.SHADOW_PICKER)">
                        <ShadowIcon />
                    </button>
                    <shadow-picker
                        v-model="localShadowInfo"
                        v-custom-click-outside="{
                            handler: handleOutsidePickerClick,
                            identifier: pickerPopups.SHADOW_PICKER
                        }"
                        :open="isPopupOpen(pickerPopups.SHADOW_PICKER)" />
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Text align"
                        class="popup-control-button"
                        @click="setCurrentPickerPopup(pickerPopups.CONTENT_ALIGN_PICKER)">
                        <icon
                            name="align"
                            :color="isPopupOpen(pickerPopups.CONTENT_ALIGN_PICKER) ? activeColor: 'gray'"
                            width="18"
                            height="18" />
                    </button>
                    <content-align-picker-popup
                        v-custom-click-outside="{
                            handler: handleOutsidePickerClick,
                            identifier: pickerPopups.CONTENT_ALIGN_PICKER
                        }"
                        :open="isPopupOpen(pickerPopups.CONTENT_ALIGN_PICKER)" />
                </div>
            </div>
            <div class="v-divider v-divider--vertical" />
            <div
                class="row-wrapper">
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Copy layer"
                        class="popup-control-button"
                        @click="duplicateFocusedLayer">
                        <icon
                            name="copy"
                            width="17"
                            height="17" />
                    </button>
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Undo"
                        class="popup-control-button"
                        @click="handleUndo">
                        <icon
                            name="undo"
                            width="20"
                            height="20" />
                    </button>
                </div>
                <div
                    class="canvas-toolbar-option">
                    <button
                        title="Redo"
                        class="popup-control-button"
                        @click="handleRedo">
                        <icon
                            name="redo"
                            width="20"
                            height="20" />
                    </button>
                </div>
            </div>
        </div>

        <div class="v-divider v-divider--vertical" />
        <div
            class="ml-4 overflow-toggle-icon"
            @click="handleWorkspaceOverflow">
            <icon
                name="eye"
                :color="layeredDesignerOverflow === 'visible' ? '#00a2ea' : 'gray'" />
        </div>
        <div class="zoom-range-wrapper">
            <span
                class="zoom-range-button"
                @click="decreaseZoom">-</span>
            <v-slider
                v-model="currentZoom"
                class="tick-text-gray zoom-range-slider"
                inverse-label
                thumb-color="white"
                step="0.002"
                min="0.04"
                max="2" />
            <span
                class="zoom-range-button"
                @click="increaseZoom">+</span>
            <div class="zoom-percentage">
                <input
                    v-model="percentualZoom"
                    type="number"
                    class="zoom-input"
                    @blur="handlePercentualZoomBlur">
                <span>%</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/components/globals/Icon';
    import undoRedoHistory from '@/components/creative-studio/layered-designer/utils/undo-redo-history';
    import GradientPickerPopup from '../layered-designer-workspace/layer-quick-selector-panel/GradientPickerPopup.vue';
    import ShadowPicker from '../layered-designer-workspace/layer-quick-selector-panel/ShadowPicker.vue';
    import ContentAlignPickerPopup from '../layered-designer-workspace/layer-quick-selector-panel/ContentAlignPickerPopup.vue';
    import TextAlignPickerPopup from '../layered-designer-workspace/layer-quick-selector-panel/TextAlignPickerPopup.vue';
    import ColorPickerPopup from '../layered-designer-workspace/layer-quick-selector-panel/ColorPickerPopup.vue';
    import AnchorPickerPopup from '../layered-designer-workspace/layer-quick-selector-panel/AnchorPickerPopup.vue';
    import SVGAnchorSmall from '../layered-designer-workspace/layer-quick-selector-panel/anchors/SVGAnchorSmall.vue';
    import { customClickOutsideDirective } from '@/components/creative-studio/common/directives/custom-click-outside.js';
    import { TEXT_ASSET_CONTENT_MODE } from '@/creative-studio/domain/layered-designer/models/Layer';
    import ShadowIcon from '@/components/creative-studio/svgs/ShadowIcon';
    import { mapActions, mapGetters } from 'vuex';
    import { FLIP_DIRECTIONS, FONT_WEIGHT, FONT_STYLE,ANCHOR_POSITIONS } from '@/creative-studio/domain/layered-designer/models/Layer';
    import { debounce } from 'lodash';
    import { CREATIVE_STUDIO_FONTS } from '@/components/creative-studio/common/fonts';
    import { generateGradientStyle } from '@/components/globals/GradientPicker/helpers';

    export default {
        components: {
            Icon,
            GradientPickerPopup,
            ShadowPicker,
            ContentAlignPickerPopup,
            TextAlignPickerPopup,
            ColorPickerPopup,
            AnchorPickerPopup,
            SVGAnchorSmall,
            ShadowIcon
        },
        directives: {
            CustomClickOutside : customClickOutsideDirective
        },
        data() {
            return {
                activeColor:'#00a2ea',
                currentZoom: 0.5,
                toolbarOpen: false,
                positionsArray: [ANCHOR_POSITIONS.TOP, ANCHOR_POSITIONS.LEFT, ANCHOR_POSITIONS.BOTTOM, ANCHOR_POSITIONS.RIGHT],
                currentPickerPopupOpen: null,
                pickerPopups: {
                    REGULAR_COLOR_PICKER: 'regularColorPicker',
                    FILL_COLOR_PICKER: 'fillColorPickerOpen',
                    STROKE_COLOR_PICKER: 'strokeColorPickerOpen',
                    SHADOW_PICKER: 'shadowPickerOpen',
                    GRADIENT_PICKER: 'gradientPickerOpen',
                    CROP_TYPE_PICKER: 'cropTypePickerOpen',
                    CONTENT_ALIGN_PICKER: 'contentAlignPickerOpen',
                    TEXT_ALIGN_PICKER: 'textAlignPickerOpen'

                },
                localFontSize: 50,
                localTextColor: '#000000',
                localFontFamily: '',
                localFillColor: '#000000',
                localStrokeColor: '#000000',
                localFontWeight: FONT_WEIGHT.NORMAL,
                localFontStyle: FONT_STYLE.NORMAL,
                localStrokeWidth: 0,
                localShadowInfo: {},
                localIsClipping: false,
                localIsBeingFit: false,
                flipDirections: {
                    horizontal: FLIP_DIRECTIONS.HORIZONTAL,
                    vertical: FLIP_DIRECTIONS.VERTICAL,
                },
                fontSizeActions: {
                    plus: 'PLUS',
                    minus: 'MINUS'
                },
                fontWeights: {
                    bold: FONT_WEIGHT.BOLD,
                    normal: FONT_WEIGHT.NORMAL
                },
                fontStyles: {
                    italic: FONT_STYLE.ITALIC,
                    normal: FONT_STYLE.NORMAL
                },
                contentType: {
                    simpleText: TEXT_ASSET_CONTENT_MODE.simpleText,
                    html: TEXT_ASSET_CONTENT_MODE.html
                },
                localGradientInfo: {},
                localCropTypeInfo: null,
                isInitialLoad: false,
                percentualZoom: 0
            };
        },
        computed: {
            ...mapGetters([
                'canvasZoom',
                'layeredDesignerCards',
                'focusedLayer',
                'fontManager',
                'layeredDesignerOverflow'
            ]),
            isLastCard() {
                return this.layeredDesignerCards.length === 1;
            },
            isTextLayer() {
                return  this.focusedLayer?.asset?.isTextAsset();
            },
            isCustomTextAsset() {
               return this.focusedLayer?.asset.contentType === this.contentType.html;
            },
            isShapeLayer() {
                return  this.focusedLayer?.asset?.isShapeAsset();
            },
            layerTitle() {
                return `${this.focusedLayer?.id} - ${this.focusedLayer?.name}`;
            },
            layerScale() {
                return this.focusedLayer?.scale;
            },
            availableFonts() {
                return Array.from(this.fontManager?.getFontList().values())
                    .map(font => {
                        return font.family;
                    }).sort();
            },
            isClippable() {
                return  this.focusedLayer?.isClippable();
            },
            canBeFit() {
                return  this.focusedLayer?.asset?.isImageAsset();
            },
            hasOppositesAnchors() {
                return this.focusedLayer?.hasOppositesHorizontalAnchors() || this.focusedLayer?.hasOppositesVerticalAnchors();
            },
            gradientPreviewStyle() {
                const style = generateGradientStyle(this.focusedLayer?.colorGradient.points || [], this.focusedLayer?.colorGradient.type, this.focusedLayer?.colorGradient.degree);

                return { background: this.focusedLayer?.colorGradient.type === 'solid' ? this.focusedLayer?.asset.fillColor : style };
            }
        },
        watch: {
            focusedLayer: {
                handler() {
                    this.initFontFamily();
                    this.initAnchorsColor();
                },
                deep: true
            },
            'focusedLayer.anchoredPositions'() {
                this.$nextTick(() => {
                    this.initAnchorsColor();
                });
            },
            layerScale(newVal) {
               this.localFontSize = (50 * newVal).toFixed(0);
            },
            localFontSize(newValue) {
                const newFontSize = newValue + 'px';
                if (newFontSize === this.focusedLayer?.asset?.fontSize) {
                    return;
                }

                // Debounce the fontSize update for the layer due to can be
                // expensive because the scale updates very fast
                this.handleDebounceFontSizeUpdate(newFontSize);

                this.updateFocusedLayerScale(newValue / 50);
            },
            localFontWeight(newVal) {
                const updateFields = {
                    weight: newVal
                };
                this.updateTextLayer(updateFields);

            },
            localFontStyle(newVal) {
                const updateFields = {
                    fontStyle: newVal
                };
                this.updateTextLayer(updateFields);

            },
            localTextColor(newValue) {
                const newColor = newValue?.hex;

                if (newColor === this.focusedLayer?.asset?.color) {
                    return;
                }

                const updateFields = {
                    color: newColor
                };
                this.updateTextLayer(updateFields);
            },
            localFontFamily(newValue) {
                if (newValue === this.focusedLayer?.asset?.fontFamily) {
                    return;
                }
                const updateFields = {
                    fontFamily: newValue
                };
                const currentFont = CREATIVE_STUDIO_FONTS.filter(font => newValue == font.family);
                this.fontManager.addFont(newValue, true, currentFont[0].url);
                this.updateTextLayer(updateFields);
            },
            localFillColor(newValue) {
                const newColor = newValue?.rgba ? `rgba(${newValue?.rgba?.r}, ${newValue?.rgba?.g}, ${newValue?.rgba?.b}, ${newValue?.rgba?.a})` : newValue;
                if (newColor === this.focusedLayer?.asset?.fillColor) {
                    return;
                }
                const updateFields = {
                    fillColor: newColor
                };
                this.updateShapeLayer(updateFields);
            },
            localStrokeColor(newValue) {
                const newColor = newValue?.rgba ? `rgba(${newValue?.rgba?.r}, ${newValue?.rgba?.g}, ${newValue?.rgba?.b}, ${newValue?.rgba?.a})` : newValue;

                if (newColor === this.focusedLayer?.asset?.strokeColor) {
                    return;
                }

                const updateFields = {
                    strokeColor: newColor
                };
                this.updateShapeLayer(updateFields);
            },
            localStrokeWidth(newWidth) {
                const newStrokeWidth = newWidth + 'px';

                if (newStrokeWidth === this.focusedLayer?.asset?.strokeWidth) {
                    return;
                }

                const updateFields = {
                    strokeWidth: newStrokeWidth
                };
                this.updateShapeLayer(updateFields);
            },
            localShadowInfo(newShadowInfo) {

                if(newShadowInfo)
                this.updateLayerShadow(newShadowInfo);
            },
            localGradientInfo(newGradientInfo) {
                if (this.isInitialLoad) {
                    return;
                }
                this.updateLayerGradientColor(newGradientInfo);
            },
            localIsClipping() {
                this.setFocusedLayerIsClipping();
            },
            currentZoom(newZoom) {
                this.setCanvasZoom(newZoom);
            },
            canvasZoom(newCanvasZoom) {
                this.currentZoom = newCanvasZoom;
                this.setPercentualZoom();
            },
            percentualZoom(newVal) {
                if(newVal < 10) {
                    this.currentZoom = (10 * 2) / 500;
                    this.percentualZoom = newVal;
                    return;
                }
                if(newVal > 500) {
                    this.currentZoom = (500 * 2) / 500;
                    this.percentualZoom = newVal;
                    return;
                }
                this.currentZoom = (newVal * 2) / 500;
            }

        },
        mounted() {
            this.currentZoom = this.canvasZoom;
            this.initAnchorsColor();
            this.setPercentualZoom();
        },
        methods: {
            ...mapActions([
                'deleteCurrentCard',
                'setFocusedLayerObjectFit',
                'setCanvasZoom',
                'updateTextLayer',
                'toggleFocusedLayerFlip',
                'duplicateFocusedLayer',
                'toggleFocusedLayerFixedSize',
                'updateShapeLayer',
                'updateLayerShadow',
                'updateLayerGradientColor',
                'setFocusedLayerIsClipping',
                'setFocusedLayerIsBeingFit',
                'updateFocusedLayerPosition',
                'updateFocusedLayerScale',
                'updateFocusedLayerDimensions',
                'setLayeredDesignerWorkspaceOverflow'
            ]),
            initFontFamily() {
                this.localFontFamily = this.focusedLayer?.asset?.fontFamily;
            },
            initLocalValues() {
                this.isInitialLoad= true;
                this.currentPickerPopupOpen= null;

                this.localFontSize = parseFloat(this.focusedLayer?.asset.fontSize);
                this.localTextColor = this.focusedLayer?.asset?.color;
                this.localFontWeight = this.focusedLayer?.asset?.weight;

                this.localFontFamily = this.focusedLayer?.asset?.fontFamily;

                this.localStrokeWidth = parseInt(this.focusedLayer?.asset?.strokeWidth);
                this.localFillColor = this.focusedLayer?.asset?.fillColor;
                this.localStrokeColor = this.focusedLayer?.asset?.strokeColor;

                this.localShadowInfo = this.focusedLayer?.shadow;

                this.localGradientInfo = this.focusedLayer?.colorGradient;

                // Wait for all the watchers to finish executing before updating the load flag
                this.$nextTick(() => {
                    this.isInitialLoad= false;
                });
            },
            initAnchorsColor() {
                if(!this.focusedLayer) {
                    return;
                }
                this.positionsArray.map(item => {
                const icon =  this.$el.querySelector(`.anchors-icon-container #anchor-${item}`);
                if(icon === null) {
                        return;
                    }
                if(!this.focusedLayer.anchoredPositions.includes(item)) {

                    icon.style.fill = '#8F9EA6';
                } else {
                    icon.style.fill = '#00a2ea';
                }
            });
            const icon =   this.$el.querySelector('.anchors-icon-container #center');
                if(icon === null) {
                        return;
                    }
                if(this.focusedLayer.anchoredPositions.length === this.positionsArray.length) {
                    icon.style.fill = '#00a2ea';
                }   else {
                        icon.style.fill = '#8F9EA6';
                }
            },
            handleDebounceFontSizeUpdate: debounce(function(newFontSize) {
                const updateFields = {
                    fontSize: newFontSize
                };
                this.updateTextLayer(updateFields);
        }, 1000),
        handleFontSizeAction(action) {
            if(action === this.fontSizeActions.plus) {
                this.localFontSize++;
            } else {
                if(this.localFontSize - 1 < 0 )
                return;
                this.localFontSize--;
            }
        },
        handleIsClippingUpdate() {
                this.localIsClipping = !this.localIsClipping;
            },
            handleUndo() {
               undoRedoHistory.undo();
            },
            handleRedo() {
               undoRedoHistory.redo();
            },
            isPopupOpen(popupName) {
                return this.currentPickerPopupOpen === popupName;
            },
            setCurrentPickerPopup(popupName) {
                if (this.currentPickerPopupOpen === popupName) {
                    this.currentPickerPopupOpen = null;
                    return;
                }
                this.currentPickerPopupOpen = popupName;
            },
            handleOutsidePickerClick(popupName, event) {
                if (this.isPopupOpen(popupName) && !event?.target?.closest('.popup-control-button')) {
                    this.currentPickerPopupOpen = null;
                }
            },
            handleToggleFixedAssetSize() {
                if(this.hasOppositesAnchors) {
                    return;
                }
                this.toggleFocusedLayerFixedSize();
            },
            updateFontWeight() {
                this.localFontWeight = this.localFontWeight === FONT_WEIGHT.BOLD
                ? FONT_WEIGHT.NORMAL
                : FONT_WEIGHT.BOLD;
            },
            updateFontStyle() {
                this.localFontStyle = this.localFontStyle === FONT_STYLE.ITALIC
                ? FONT_STYLE.NORMAL
                : FONT_STYLE.ITALIC;
            },
            setPercentualZoom() {
                if(this.percentualZoom > 500) {
                    return;
                }
                if(this.percentualZoom < 10 && this.percentualZoom !== 0) {
                    return;
                }

               this.percentualZoom = Math.floor(((this.currentZoom * 500) / 2));
            },
            increaseZoom() {
                if (this.currentZoom > 1.998) {
                    return;
                }
                this.currentZoom += 0.004;
            },
            decreaseZoom() {
                if (this.currentZoom === 0.044) {
                    this.currentZoom -= 0.002;
                }
                if (this.currentZoom < 0.044) {
                    return;
                }
                this.currentZoom -= 0.004;
            },
            handlePercentualZoomBlur() {
                if(this.percentualZoom > 500) {
                    this.percentualZoom = 500;
                }
                if(this.percentualZoom < 10 && this.percentualZoom !== 0) {
                    this.percentualZoom = 10;
                }
            },
            handleFontSizeInput(event) {
                if(event.key==='.') {
                    event.preventDefault();
                }
            },
            handleWorkspaceOverflow() {
                if(this.layeredDesignerOverflow === 'hidden') {
                    this.setLayeredDesignerWorkspaceOverflow('visible');
                } else {
                    this.setLayeredDesignerWorkspaceOverflow('hidden');
                }
            }
        }
    };
</script>

<style lang="scss">
.layered-designer-header-card-controls {
    background-color: #EDF2F5;
    height: 35px;
    display: flex;
    padding: 10px 0px;
    align-items: center;
    justify-content: flex-end !important;
    width: 100%;
    border-bottom: 1px solid #B4B9BB;
}

 .layered-designer-header-card-controls-container {
    display: flex;

}


.layered-designer-header-card-controls .v-divider {
   border-right-width: 2px !important;
}
.delete-card-button {
    padding: 0 20px;
}

.zoom-range-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 20px;
    max-width: 360px;
    color: #000;
}

.zoom-range-wrapper .v-input__slot {
    margin-bottom: 0;
}

.zoom-range-button {
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color: $gray;
    font-family: Courier;
    cursor: pointer;
    padding: 0 3px;
}

.zoom-percentage {
    white-space: nowrap;
}

.zoom-range-slider .v-slider__track__container,.zoom-range-slider .v-slider__track-fill {
    height: 4px;
    border-radius: 15%;
}

.current-zoom {
    font-size: 12px;
    color: $black;
    padding: 0 10px;
}

.zoom-input {
    max-width: 90px;
    margin: 0 10px;
}

.row-wrapper {
    display: flex;
    align-items: center;
}

.canvas-toolbar-option {
    padding: 0px;
    margin: 0px 9px;
    width: 100%;
    position: relative;
}

.hidden {
    display: none;
}


.color-square {
    margin-top: 6px;
    padding: 8px;
    border-radius: 5px;
    border: solid $blue-bg 2px;
}

.custom-font-select .v-select__slot {
    height: 20px !important;
}

.font-size-input-wrapper {
    border: solid 1px $gray;
    padding: 0px 8px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.3);
}


.font-size-input-wrapper input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.overflow-toggle-icon {
    cursor: pointer;
}


.font-size-input-action {
    color: $gray;
    transform: scale(1.5);
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.font-size-input {
    width: 40px;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
    border-right: solid 1px $gray;
    border-left: solid 1px $gray;
    text-align: center;
    font-size: 14px;

}

.toolbar-font-select .styled-field.v-input.v-text-field.v-input.v-text-field{
    & > .v-input__control {
        & > .v-input__slot {
            border: solid 1px $gray;
            font-size: 14px !important;

            border-radius: 5px !important;
            margin-bottom: 0;
            background-color: rgba(255, 255, 255, 0.3);
        }
    }
}

.active {
    color: $blue-bg !important;
}

.percentual-zoom-container {
    max-width: 25px;
}


.zoom-input {
    max-width:30px !important;
    margin-right: 1px;
    outline-color: $blue-bg;
}
</style>
