/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm4.084 5l-5.537 5.537L5.98 7.972 4 9.953 8.547 14.5l7.519-7.519L14.084 5z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
