<template>
    <div class="container">
        <div class="image-holder">
            <img
                class="image"
                :src="defaultMedialUrl">
            <div class="message">
                <div class="mb-3">
                    <icon
                        size="40"
                        :color="colors.yellow"
                        name="indicator-warning" />
                </div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import colors from '@/helpers/colors';

export default {
    name: 'AdPlaceholder',
    components: {
        Icon
    },
    data() {
        return {
            defaultMedialUrl: '/img/ad-preview-placeholders/placeholder.jpg',
            colors
        };
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    width: 375px;
    height: 751px;
    display: flex;
    flex-direction: column;
    background-color: $black;
    border-radius: 20px;
    overflow: hidden;
    color: $white;
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
    @media only screen and (max-width: 400px) {
        width: 320px;
    }
}
.image-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}
.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.message {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 45px;
}
.message a {
    color: $yellow-dark;
}
</style>
