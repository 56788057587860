<template>
    <div
        class="list-wrapper"
        :class="{ relative: relativePosition }">
        <div
            class="list-item"
            @click="openAdStudio">
            {{ $t('nav.create.ad') }}
        </div>
        <div
            class="list-item"
            @click="openInventoryDeployment">
            <div class="design-text">
                {{ $t('nav.create.inventoryDeployment') }}
            </div>
        </div>
        <div
            class="list-item"
            @click="openLayeredDesigner">
            <div class="design-text">
                {{ $t('nav.create.design') }} <span class="beta-label">{{ $t('nav.create.beta') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '@/event-bus';
import { LAYERED_DESIGNER_MODE } from '@/creative-studio/domain/layered-designer/models/Layer';
import { DYNAMIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';
import { mapGetters } from 'vuex';

export default {
    props: {
        relativePosition: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters(['userIsAdmin']),
    },
    methods: {
        openAdStudio() {
            EventBus.$emit('open-ad-studio');
        },
        openLayeredDesigner() {
            EventBus.$emit('open-layered-designer', LAYERED_DESIGNER_MODE.NEW_DESIGN);
        },
        openInventoryDeployment() {
            EventBus.$emit('open-deployment-modal', {
                deployment_type: DYNAMIC_DEPLOYMENT_TYPE,
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.list-wrapper {
    position: absolute;
    z-index: 99;
    right: -20px;
    min-width: 100%;
    top: 100%;
    background: white;
    color: #00A4EB;
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &:after {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        background: white;
        box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
        top: -7px;
        right: 37px;
        transform: rotate(45deg);
        z-index: 99;
    }

    &:before {
        content: '';
        position: absolute;
        width: 40px;
        height: 15px;
        background: white;
        top: 0;
        right: 25px;
        z-index: 100;
    }
}

.list-wrapper.relative {
    position: relative;
    left: 0;
    margin: 20px 15px;
    min-width: auto;
}

.list-item {
    position: relative;
    z-index: 999;
    min-width: 220px;
    max-width: 230px;
    box-sizing: border-box;
    display: block;
    padding: 15px 30px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
}

.design-text {
    white-space: nowrap;
}

.beta-label {
    font-size: 10px;
    font-weight: 600;
    padding: 3px 8px;
    background: $alabaster-dark;
    border-radius: 5px;
    color: $gray-dark;
    display: inline-block;
    margin-left: 5px;
}
</style>
