/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'snapchat-arrow': {
    width: 54,
    height: 52,
    viewBox: '0 0 54 52',
    data: '<path pid="0" _fill="#D8D8D8" fill-rule="evenodd" d="M8.966 1.567c6.893 3.422 36.73 17.626 40.857 19.877 4.127 2.252 4.77 6.824 0 8.978-20.88 9.43-39.025 19.31-42.764 20.892-3.74 1.583-9.006-.883-5.858-7.267 3.147-6.384 7.366-9.777 9.427-13.625 2.06-3.848.8-7.441 0-8.978-.8-1.536-4.453-6.511-8.556-12.374-4.103-5.862 0-10.924 6.894-7.503z"/>'
  }
})
