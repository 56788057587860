import { cloneDeep } from "lodash";

const DataLayerPlugin = {
    install(Vue, options) {

        // Ensure definition
        window.dataLayer = window.dataLayer || [];

        Vue.prototype.$dl = {
            push(data) {
                window.dataLayer.push(
                    // Clone to ensure we don't expose bugs in GTM
                    cloneDeep(data)
                );
            },
            newEvent(eventType, eventData = {}) {

                const clonedData = cloneDeep(eventData);

                // Dealers in the user object can grow very large
                if (clonedData.user?.dealers?.data?.length) {
                    clonedData.user.dealers.data = clonedData.user.dealers.data.map(({ id, name, agency_id }) => {
                        return { id, name, agency_id}
                    })
                }

                // The matched object contains functions and unnecessary data
                if (clonedData.route) {
                    delete clonedData.route.matched;
                }

                window.dataLayer.push({
                    event: 'newEvent',
                    eventType,
                    // Clone to ensure we don't expose bugs in GTM
                    eventData: clonedData
                })
            }
        }
    }
};

export default DataLayerPlugin;
