<template>
    <v-dialog
        :content-class="customContentClass"
        :value="value"
        persistent
        :width="width"
        :fullscreen="fullscreen"
        @input="$emit('input', $event)">
        <div
            :class="{
                'dialog': true,
                'no-heading': !$slots['heading']
            }">
            <div
                v-if="!!$slots['heading']"
                class="heading">
                <slot name="heading" />
            </div>

            <div class="body">
                <slot />
            </div>

            <div
                v-if="!!$slots['actions']"
                class="actions">
                <slot name="actions" />
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        width: {
            type: Number,
            default: 500
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        customContentClass: {
            required: false,
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>

.dialog {
    position: relative;
    background-color: $white;
    padding-bottom: 40px;

    &.no-heading {
        padding-top: 20px;
    }

    .heading {
        display: flex;
        align-items: center;
        padding: 0 40px;
        border-bottom: 1px solid $alabaster-dark;
        height: 70px;
    }

    .body {
        padding: 20px 40px 0;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        padding: 20px 40px 0;
    }
}
</style>

<style lang="scss">
.dialog {
    .heading {
        /* Eventually we can get rid of these when the global styles are fixed */
        h1, h2, h3, h4, h5 {
            color: $blue-primary !important;
            font-weight: normal !important;
            margin: 0;
            line-height: 1rem;
        }
    }

    .body {
        color: $gray-dark;
    }
}

</style>
