/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'page-align-left': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M2 1v18a1 1 0 01-2 0V1a1 1 0 112 0zm2 6h14a1 1 0 011 1v4a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
