<template>
    <div class="as-main">
        <styled-dialog v-model="dialogOpen">
            <div class="text-xs-center">
                <h3 class="mb-4">
                    Are You Sure You Want to Remove This Ad?
                </h3>
                <p>
                    This action cannot be undone.
                    It is important to note that the ad will only be removed from this set of ads.
                    It will not be removed from the library itself.
                    If you would like to delete the ad entirely you can do that from the ad library.
                </p>
                <styled-button
                    class="mt-4"
                    @click="deleteAd">
                    Yes, Remove Ad
                </styled-button>
            </div>
            <template #actions>
                <a
                    href="#"
                    class="red--text"
                    @click.prevent="dialogOpen = false">
                    Cancel
                </a>
            </template>
        </styled-dialog>
        <!-- left part -->
        <div
            class="as-main__left"
            :class="[!secondStepActive ? 'w-20' : 'w-35']">
            <div class="left__content">
                <!-- small ad prev -->
                <div
                    class="left__lt"
                    :class="[!secondStepActive ? 'w-30' : 'w-20']">
                    <div class="lt__block">
                        <div
                            v-for="(ad,index) in ads"
                            :key="index"
                            class="lt__current"
                            :class="{selected: index === adIndex}"
                            @click="selectAd(index)"
                            @mouseover="showTrash = index"
                            @mouseleave="showTrash = null">
                            <div v-if="!ad.deleted">
                                <div class="delete-wrapper">
                                    <styled-tooltip
                                        nudge-right="35"
                                        :max-width="100"
                                        position="right-far">
                                        <template #content>
                                            <div class="ad-studio-tooltip">
                                                <span class="ad-studio-blue">Remove Ad</span>
                                            </div>
                                        </template>
                                        <div
                                            v-if="showTrash === index && !openFromDeployment"
                                            class="delete"
                                            @click.stop="openDialog(index)">
                                            <icon
                                                name="trash"
                                                size="12px" />
                                        </div>
                                    </styled-tooltip>
                                </div>
                                <left-side-ad
                                    :ad="ad"
                                    :current-ad="index === adIndex" />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="left__rt"
                    :class="[!secondStepActive ? 'w-70' : 'w-80']">
                    <div class="left__name">
                        <div class="left__title">
                            Ad Name:
                        </div>
                        <div class="flex">
                            <v-edit-dialog
                                :return-value.sync="localAdName"
                                large
                                @save="saveAdName">
                                <div class="left__edit">
                                    <span>{{ localAdName }}</span>
                                    <icon
                                        name="edit"
                                        size="18px" />
                                </div>
                                <template #input>
                                    <v-text-field
                                        v-model="localAdName"
                                        label="Edit"
                                        single-line />
                                </template>
                            </v-edit-dialog>
                            <div
                                v-if="!openFromDeployment"
                                class="trash"
                                @click="openDialog(adIndex)">
                                <icon
                                    name="trash"
                                    size="18px" />
                            </div>
                        </div>
                    </div>
                    <channels-list
                        v-if="!secondStepActive"
                        :ad-index="adIndex" />
                    <editing-fields v-if="secondStepActive" />
                </div>
            </div>
        </div>
        <!-- end left part -->

        <!-- right part -->
        <div
            ref="previewsWrap"
            class="as-main__right"
            :class="[!secondStepActive ? 'w-80' : 'w-65', {'overflow-auto': overflowAuto}]">
            <file-previews-list v-if="!secondStepActive" />
            <ad-previews
                v-else
                canvas-view
                @scroll-to="scrollTo"
                @fit-activated="resetOverflow" />
        </div>
        <!-- end right part -->
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import EditingFields from '@/components/ad-studio/editing/preview-step/EditingFields';
import AdPreviews from '@/components/ad-studio/editing/preview-step/AdPreviews';
import ChannelsList from '@/components/ad-studio/editing/selection-step/ChannelsList';
import FilePreviewsList from '@/components/ad-studio/editing/common/FilePreviewsList';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import LeftSideAd from '@/components/ad-studio/editing/LeftSideAd';
import {
    getAllAssetsForAPlatform,
    getAllAssetsForAd
    } from '@/components/ad-studio/store/constants';
import { mapState } from 'vuex';
import { sleep } from '@/helpers';

export default {
    components: {
        EditingFields,
        AdPreviews,
        Icon,
        ChannelsList,
        FilePreviewsList,
        StyledTooltip,
        StyledDialog,
        StyledButton,
        LeftSideAd
    },
    props: {
        secondStepActive: {
            type: Boolean,
            default: false,
        },
        complete: {
            type: Boolean,
            default: false,
        },
        openFromDeployment: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localAdName: '',
            showTrash: null,
            dialogOpen: false,
            overflowAuto: true
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            selectedChannels: (state) => state.adStudio.selectedChannels,
            adIndex: state => state.adStudio.adIndex,
            ads: state => state.adStudio.ads,
        }),
        adName: {
            get() {
                return this.ads[this.adIndex]?.display_name;
            },
            set(value) {
                this.$store.commit('adStudio/setAdName', {
                    name: value,
                    adIndex: this.adIndex
                });
            }
        },
        currentAdAssets() {
            return  this.customizedPerPlatform ? getAllAssetsForAPlatform(this.ads[this.adIndex], this.activePlatform) : getAllAssetsForAd(this.ads[this.adIndex]);
        },
        activePlatform: {
            get() {
                return this.ads[this.adIndex]?.active_platform;
            },
            set(platform) {
                this.$store.commit('adStudio/changeActivePlatform', {
                    adIndex: this.adIndex,
                    platform
                });
            }
        },
        customizedPerPlatform: {
            get() {
                return this.ads[this.adIndex]?.configuration.customized_per_platform;
            },
            set(value) {
                // Check to see if the value trying to be set to true
                // It should never be going from true to false
                if (value) {
                    this.$store.commit('adStudio/customizePerPlatform', { adIndex: this.adIndex });
                    // select the first channel as the one being customized
                    this.setActivePlatform(this.selectedAdPlatforms[0]);
                }
            }
        },
    },
    watch: {
        complete() {
            if (this.complete) {
                this.completeStep();
            }
        },
        adName() {
            this.localAdName = this.adName;
        },
    },
    created() {
        // Check if ad.active_platform is not and if so, set it to
        // the first platform from the ad.configuration?.selected_platforms.
        // This is kinda hacky and is required because the ad.active_platform
        // is not persisted in the database and is only used in the UI.
        this.ads.forEach((ad, index) => {
            if (!ad?.active_platform) {
                this.$store.commit('adStudio/changeActivePlatform', {
                    adIndex: index,
                    platform: ad.configuration?.selected_platforms[0]
                });
            }
        });
        this.localAdName = this.adName;
    },
    methods:{
        async resetOverflow() {
            // Fixing weird Safari browser bug (CSS 'overflow: auto' not working properly with dynamically scaled content)
            this.overflowAuto = null;
            await sleep(100);
            this.overflowAuto = true;
        },
        saveAdName() {
            // set AdName to store only when 'save' is clicked
            // to prevent glitches when editing/typing it directly with every keypress
            this.$store.commit('adStudio/setAdName', {
                name: this.localAdName,
                adIndex: this.adIndex
            });
        },
        selectAd(index) {
            this.$store.commit('adStudio/setCurrentAdIndex', index);
        },
        openDialog(index) {
            this.dialogOpen = true;
            this.adToDelete = index;
        },
        deleteAd() {
            this.$store.commit('adStudio/deleteAd', this.adToDelete);
            const availableAds = this.ads.filter(ad => ad.deleted !== true);
            if (availableAds.length == 0) {
                this.$emit('close');
            }
            this.dialogOpen = false;
        },
        scrollTo(position) {
            this.$refs.previewsWrap.scrollTop = Number(position) - 85;
        },
        completeStep() {
            // todo check if saving the ad and starting the ad deployment should happen here
            // currently its happening in the footer which is probably not the best place but it
            // does have the continue button in the footer
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss" scoped>
.as-main{
    display: flex;
    .left{
        &__content{
            display: flex;
        }
        &__lt{
            // width: 40%; // first 40% second xx%
            height: calc(100vh - 100px);
            overflow-y: auto;
            z-index: 99;
            position: relative;
            background: #E5EAED;
            overflow-x: hidden;
            direction: rtl;
            .lt{
                &__text{
                    padding: 5px 15px;
                    margin-bottom: 15px;
                }
                &__tooltip{
                    position: absolute;
                    background: white;
                    z-index: 9999;
                    left:50%;
                }
                &__exclamation{
                    position: absolute;
                    background: #EF5643;
                    border-radius: 50%;
                    right:5px;
                    top:-15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;
                }
                &__images{
                    margin-bottom: 15px;
                    position: relative;
                    width: 100%;
                    div{
                        display: inline-block;
                    }
                }
                &__image{
                    img, video{
                        width: 50px;
                        height: auto;
                        vertical-align: top;
                    }
                }
                &__block{
                    position: relative;
                    box-sizing: border-box;
                    text-align: center;
                    border-right: 1px solid #CBCBCB;
                    height: 100%;
                    .tri{
                        position: absolute;
                        z-index: 3;
                        right:-1px;
                        top: 50%;
                        margin-top: 0px;
                        div{
                            &:nth-of-type(1){
                                width: 25px;
                                height: 25px;
                                background: white;
                                transform: rotate(45deg) translate(12px, -6px);
                            }
                            &:nth-of-type(2){
                                position: absolute;
                                top:0;
                                right:0;
                                width: 25px;
                                height: 1px;
                                transform: rotate(-45deg) translate(-4px, 8px);
                                background: #CBCBCB;
                            }
                            &:nth-of-type(3){
                                position: absolute;
                                bottom:0;
                                right:0;
                                width: 25px;
                                height: 1px;
                                transform: rotate(45deg) translate(4px, -1px);
                                background: #CBCBCB;
                            }
                        }
                    }
                }
                &__current{
                    position: relative;
                    cursor: pointer;
                    border-bottom: 1px solid #D3D5D6;
                    &.selected{
                        background-color: #dfdfdf;
                    }
                    &:hover{
                        .lt__image{
                            outline: 5px solid #03A2EA;
                        }
                    }
                }
            }
        }
        &__rt{
            // width: 80%; // first step 40% second step 80%
            height: calc(100vh - 150px);
            box-sizing:border-box;
            background: white;
            overflow: auto;
            border-right: 1px solid #CBCBCB;
        }
        &__name{
            padding: 5px 15px 10px 10px;
            border-bottom: 1px solid #CBCBCB;
        }
        &__title{
            color: #03A2EA;
            font-size: 12px;
        }
        &__edit{
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 5px 3px;
        }
    }
    &__right{
        // width: 65%; // here
        height: calc(100vh - 150px);
        overflow: hidden;
        scroll-behavior: smooth;
        &.overflow-auto {
            overflow: auto;
        }
    }
}
.flex {
    display: flex;
    align-items: center;
}
.trash {
    margin-right: -10px;
    margin-left: 5px;
    margin-top: -3px;
    cursor: pointer;
}
.delete-wrapper {
    min-height: 31px;
    text-align: right;
}
.delete {
    display: inline-block;
    margin: 0px 5px 10px 5px;
    cursor: pointer;
    text-align: right;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-35{
    width: 35%;
}
.w-65{
    width: 65%;
}
.w-70{
    width: 70%;
}
.w-80{
    width: 80%;
}
</style>
<style lang="scss">
.v-menu__content{
    z-index: 9999 !important;
}
.left__name .v-menu a {
    width: 100%;
}
.ad-studio-tooltip{
    font-size: 14px;
}
.ad-studio-blue{
    color: #03A2EA;
}
</style>
