<template>
    <div>
        <div
            class="box-row mb-4">
            <div
                class="box"
                :class="[fillBlurToggle ? 'blue-border ' : 'gray-border']"
                @click="toggleFillBlur">
                <div class="blur-box" />
                <div class="box-text">
                    Fill Blur
                </div>
            </div>
            <div
                class="box"
                :class="[fillToggle ? 'blue-border ' : 'gray-border']"
                @click="toggleFill">
                <div class="mt-2">
                    <!-- This SVG does not work well with the normal way we do icons  -->
                    <svg
                        height="40"
                        viewBox="0 0 40 40"
                        width="40"
                        xmlns="http://www.w3.org/2000/svg">
                        <g
                            fill="none"
                            fill-rule="evenodd"
                            transform="translate(1.49 1)">
                            <g
                                stroke="#8f9ea6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.35">
                                <path
                                    d="m16.098473 33.42169h5.843381"
                                    transform="matrix(0 1 -1 0 52.441854 14.401526)" />
                                <path d="m16.9935586 34.8400803 2.1006392 2.8483154 2.1006393-2.8483154" />
                                <path
                                    d="m16.246542 4.266705h5.843381"
                                    transform="matrix(0 -1 1 0 14.901527 23.434937)" />
                                <path
                                    d="m16.9935586 0 2.1006392 2.84831543 2.1006393-2.84831543"
                                    transform="matrix(-1 0 0 -1 38.188396 2.848316)" />
                            </g>
                            <g transform="translate(9.5 8.993559)">
                                <path
                                    d="m4.05 20h11.9c2.2367532 0 4.05-1.8132468 4.05-4.05v-11.9c0-2.23675324-1.8132468-4.05-4.05-4.05h-11.9c-2.23675324 0-4.05 1.81324676-4.05 4.05v11.9c0 2.2367532 1.81324676 4.05 4.05 4.05z"
                                    fill="#8f9ea6" />
                                <g fill="#fff">
                                    <path
                                        d="m1 15.7675 4.3869375-4.8225 3.8484 4.119375 5.8710938-6.796875 3.8935687 4.414375v2.268125c0 2.2367532-1.8132468 4.05-4.05 4.05h-10.7175c-1.78526045 0-3.2325-1.4472395-3.2325-3.2325z"
                                        stroke="#fff" />
                                    <path
                                        d="m9.375 5.686875c0 1.46875-1.1906875 2.659375-2.6595 2.659375s-2.6595-1.190625-2.6595-2.659375c0-1.4688125 1.1906875-2.6595 2.6595-2.6595s2.6595 1.1906875 2.6595 2.6595z"
                                        stroke="#fff"
                                        stroke-linejoin="round"
                                        stroke-width="1.6875" />
                                </g>
                            </g>
                            <path
                                d="m31.823574 18.978301h5.833651"
                                stroke="#8f9ea6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.35" />
                            <path
                                d="m35.4740708 17.4823564 2.1041428 2.8435728 2.1041428-2.8435728"
                                stroke="#8f9ea6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.35"
                                transform="matrix(0 -1 1 0 18.674071 56.482357)" />
                            <path
                                d="m1.342775 18.829985h5.833652"
                                stroke="#8f9ea6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.35"
                                transform="matrix(-1 0 0 -1 8.519202 37.65997)" />
                            <path
                                d="m-.68235636 17.4823564 2.10414276 2.8435728 2.10414276-2.8435728"
                                stroke="#8f9ea6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.35"
                                transform="matrix(0 1 -1 0 20.325929 17.482357)" />
                        </g>
                    </svg>
                </div>
                <div class="box-text">
                    Fill
                </div>
            </div>
            <div
                class="box"
                :class="[backgroundColorToggle ? 'blue-border ' : 'gray-border']"
                @click="toggleBackgroundColor">
                <div class="color-box">
                    <color-picker-box
                        v-if="backgroundColor"
                        :value="backgroundColor"
                        :handler="setBackgroundColor" />
                </div>
                <div class="box-text">
                    Fill Color
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ColorPickerBox from '@/components/ad-studio/editing/common/ColorPickerBox.vue';
import EventBus from '@/event-bus';
import { ORIGINAL_ASSET_TYPE, VIDEO_ASSET_TYPE } from '@/components/ad-studio/store/constants.js';

export default {
    components: {
        ColorPickerBox
    },
    props: {
        assetConfigs: {
            type: Array,
            required: false,
            default: () => ([])
        },
        advancedMediaControlsToggle: {
            type: Boolean,
            required: true,
        },
        assetUrl: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            fillToggle: false,
            fillBlurToggle: false,
            showColorPicker: false,
            backgroundColorToggle: false,
            backgroundColor: '#000000',
        };
    },
    computed: {
        ...mapState({
            ads: state => state.adStudio.ads,
            adIndex: state => state.adStudio.adIndex,
            agency: state => state.agency.currentAgency,
        }),
        selectedAd() {
            return this.ads?.[this.adIndex];
        },
        originalAsset() {
            return this.selectedAd?.configuration?.all_platforms?.creatives?.[0]?.assets?.[0];
        },
        advancedMediaControls() {
            return this.assetConfigs.length > 1;
        },
    },
    created() {
        this.initTheToggleStates();

        // This is the use case where someone is uploading a custom aspect
        // ratio asset and we need to apply the same transformations to it
        // after its been uploaded
        EventBus.$on('runTransformation', ({ assetConfig }) => {
            // Check if the asset config is the same as the one we are working on
            if (! this.assetConfigs.includes(assetConfig)) {
                return;
            }

            // Apply the transformations
            this.applyTransformation();
        });

        // This is triggered when the user turns off platform controlls media styles
        // so we need to generate the assets
        EventBus.$on('runTransformationOnAllAssetConfigs', () => {
            this.applyTransformation();
        });
    },
    beforeDestroy() {
        EventBus.$off('runTransformation');
        EventBus.$off('runTransformationOnAllAssetConfigs');
    },
    methods: {
        initTheToggleStates() {
            this.getColorFromOriginalAsset();

            // Compute the state of the buttons
            if (this.assetUrl) {
                this.computeToggleFillState(this.assetUrl);
                this.computeToggleFillBlurState(this.assetUrl);
                this.computeToggleFillBackgroundState(this.assetUrl);
                this.computeBackgroundColorValue(this.assetUrl);
            } else {
                this.toggleFillBlur();
            }
        },
        toggleFill() {
            // Check if toggle fill is already on
            if (this.fillToggle) {
                return;
            }

            // Toggle state to on
            this.fillToggle = true;

            // Turn off other toggles
            if (this.fillToggle) {
                this.fillBlurToggle = false;
                this.backgroundColorToggle = false;
            }

            // Apply the transformation
            this.applyTransformation();
        },
        computeToggleFillState(assetUrl) {
            if (assetUrl.includes('c_fill') && (!assetUrl.includes('e_blur:2000') || assetUrl.includes('b_blurred:2000:15'))) {
                this.fillToggle = true;
            }
        },
        toggleFillBlur() {
            if (this.fillBlurToggle) {
                return;
            }

            this.fillBlurToggle = true;
            if (this.fillBlurToggle) {
                this.fillToggle = false;
                this.backgroundColorToggle = false;
            }
            this.applyTransformation();
        },
        computeToggleFillBlurState(assetUrl) {
            // Image blur value in url
            if (assetUrl.includes('e_blur:2000')) {
                this.fillBlurToggle = true;
            }

            // Video blur value in url
            if (assetUrl.includes('b_blurred:2000:15')) {
                this.fillBlurToggle = true;
            }
        },
        toggleBackgroundColor() {
            if (this.backgroundColorToggle) {
                return;
            }

            this.backgroundColorToggle = true;
            if (this.backgroundColorToggle) {
                this.fillToggle = false;
                this.fillBlurToggle = false;
            }
            this.applyTransformation();
        },
        setBackgroundColor(color) {
            this.backgroundColor = color;
            this.applyTransformation();
        },
        computeToggleFillBackgroundState(assetUrl) {
            if (assetUrl.includes('b_rgb:')) {
                this.backgroundColorToggle = true;
            }
        },
        computeBackgroundColorValue(assetUrl) {
            const color = assetUrl.match(/b_rgb:(\w+)/);
            if (color) {
                this.backgroundColor = '#' + color[1];
            }
        },
        getColorFromOriginalAsset() {
            this.backgroundColor = this.originalAsset?.configuration?.dominant_color ?? '#000000'; // default to black
        },
        async applyTransformation() {
            // Loop over all the asset configs and apply the transformation.
            // This handels the use case where the user wants to apply the same
            // transformation to all aspect ratio platforms.
            // Only one assetConfig is passed in if they are doing advanced edit
            // transformations.

            // Filter out any asset configs that have the ORIGINAL_ASSET_TYPE
            // This is because we don't want to apply transformations to the
            // original asset.
            this.assetConfigs.filter((assetConfig) => {
                return assetConfig.asset_type !== ORIGINAL_ASSET_TYPE;
            }).forEach((assetConfig) => {

                // Video does not have any background transformations
                const videoTransformations = {
                    foreground: {
                        width: assetConfig?.width,
                        height: assetConfig?.height,
                        crop: this.fillToggle ? 'fill': 'pad',
                        gravity: 'center',
                        background: [
                            this.fillBlurToggle ? 'blurred:2000:15' : null,
                            this.backgroundColorToggle ? 'rgb:' + this.backgroundColor.replace('#', '') : null
                        ].filter(Boolean)[0],
                    }
                };

                const imageTransformations = {
                    foreground: {
                        width: assetConfig?.width,
                        height: assetConfig?.height,
                        crop: this.fillToggle ? 'fill': 'pad',
                        gravity: 'center',
                    },
                    background: {
                        width: assetConfig?.width,
                        height: assetConfig?.height,
                        crop: this.fillToggle ? 'fill' : null,
                        effect: this.fillBlurToggle ? 'blur:2000' : null,
                        background: this.backgroundColorToggle ? 'rgb:' + this.backgroundColor.replace('#', '') : null,
                        underlay: !this.backgroundColorToggle ? '{public_id}' : null,
                        gravity: 'center',
                    }
                };

                const transformations = assetConfig?.asset_type === VIDEO_ASSET_TYPE ? videoTransformations : imageTransformations;

                // Emit transformation event
                EventBus.$emit('makeTransformationOnAsset', {
                    transformations,
                    assetConfig,
                });

            });
        }
    }
};
</script>

<style lang="scss" scoped>
.box {
    width: 80px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: visible;
    margin: 5px;

}
.box-text {
    margin-bottom: 10px;
}
.box-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow: visible;
    position: relative;
}
.arrow-left {
    position: absolute;
    top: 8px;
    right: 28px;
    rotate: 180deg;
}
.arrow-right {
    position: absolute;
    top: 8px;
    left: 28px;
}
.arrow-top {
    position: absolute;
    bottom: 28px;
    right: 7px;
    rotate: 270deg;
}
.arrow-bottom {
    position: absolute;
    top: 28px;
    right: 7px;
    rotate: 90deg;
}
.blue-border {
    border-radius: 10px;
    border: 4px solid rgba(0,162,234, 1);
}
.gray-border {
    border-radius: 10px;
    border: 2px solid rgba(143,158,166, 1);
}
.blur-box {
    border-radius: 4px;
    box-shadow: 0px 0px 8px 1px rgba(143,158,166, 1);
    opacity: 1;
    background-color: rgba(143,158,166, 1);
    height: 25px;
    width: 25px;
    position: relative;
    margin-top: 15px;
}
.image-box {
    border-radius: 4px;
    opacity: 1;
    background-color: rgba(143,158,166, 1);
    height: 25px;
    width: 25px;
    position: relative;
    margin-top: 15px;
}
.color-box {
    margin-top: 15px;
}

.color-picker {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    overflow: visible;
}
.button-wrapper {
    text-align: center;
    margin-top: 20px;
    button {
        min-width: 100px;
    }
}
.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
