/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'designs': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14.672 13.213l2.907 1.537c.478.253.484.67 0 .926l-6.733 3.56c-.478.252-1.268.255-1.752 0l-6.733-3.56c-.478-.253-.484-.67 0-.926l2.906-1.537 3.827 2.022c.484.256 1.274.253 1.752 0l3.826-2.022zm0-5l2.907 1.537c.478.253.484.67 0 .926l-6.733 3.56c-.478.252-1.268.255-1.752 0l-6.733-3.56c-.478-.253-.484-.67 0-.926l2.906-1.537 3.827 2.022c.484.256 1.274.253 1.752 0l3.826-2.022zM9.094 1.191c.478-.253 1.268-.256 1.752 0L17.58 4.75c.478.253.484.67 0 .926l-6.733 3.56c-.478.252-1.268.255-1.752 0L2.36 5.675c-.478-.253-.484-.67 0-.926z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
