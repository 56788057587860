<template>
    <div>
        <div
            v-if="!uploading && viewType === 'full'"
            class="upload-asset-container">
            <div

                class="upload-asset-wrapper">
                <input
                    id="upload-asset-input"
                    type="file"
                    multiple
                    accept="image/*"
                    @change="handleFiles($event)">
                <label
                    class="upload-asset-label"
                    for="upload-asset-input">Upload image</label>
                <icon
                    class="upload-icon"
                    name="upload"
                    width="40"
                    height="40" />
            </div>
            <div>
                or drop files here to upload
            </div>
        </div>
        <div v-else-if="!uploading && viewType === 'button'">
            <div class="upload-asset-button-mode">
                <input
                    id="upload-asset-input"
                    type="file"
                    @change="handleFiles($event)">
                <label
                    class="upload-asset-button-label"
                    for="upload-asset-input">
                    <icon
                        class="upload-icon"
                        name="upload"
                        color="#fff"
                        width="20"
                        height="20" />
                    Upload</label>
            </div>
        </div>
        <div
            v-else
            class="upload-asset-container no-border">
            <div class="loading-circle">
                <div class="loading-bar">
                    <progress-ring
                        radius="70"
                        :progress="uploadProgress"
                        stroke="10" />
                </div>
                <div class="loading-percentage">
                    {{ uploadProgress }}%
                </div>
            </div>
        </div>
        <div class="mt-2 upload-asset-error">
            {{ errorMessage }}
        </div>
    </div>
</template>
<script>
import Icon from '@/components/globals/Icon';
import { mapState } from 'vuex';
import ProgressRing from '@/components/ad-studio/uploading/ProgressRing';

export default {
    components: {
        Icon,
        ProgressRing
    },
    props:{
        viewType: {
            type: String,
            default: 'full'
        }
    },
    data() {
        return {
            uploading: false,
            response: null,
            uploadProgress: 0,
            extension: null,
            imageExtensions: ['jpg', 'jpeg', 'png'],
            errorMessage:'',
            totalFiles: 0,
            uploadedAssets: []
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            user: state => state.user.user,
            agency: state => state.agency.currentAgency
        })
    },
    methods: {
        async handleFiles(e) {
            this.uploading = true;
            const fileList = e.target.files || e.dataTransfer.files;
            this.errorMessage = '';
            this.uploadedAssets = [];
            for (const file of fileList) {
                this.setExtension(file.name);

                if (!this.allowedFormat(file.name)) {
                        this.errorMessage = 'It looks like this format of file is not allowed';
                        this.uploading = false;
                        this.$emit('error-on-upload');
                        return;
                }

                if (file.size > 524288000) {
                    this.errorMessage = 'It looks like your file esceeds our max upload size';
                    this.uploading = false;
                    this.$emit('error-on-upload');
                    return;
                }

        }
            if (!this.errorMessage) {
                    this.totalFiles = fileList.length;
                    this.uploadProgress = 0;
                    for (const [index, file] of Object.entries(fileList)) {
                        await this.uploadFile(file, index);
                    }
                }

        },
        allowedFormat() {
            return this.imageExtensions.includes(this.extension.toLowerCase());
        },
        async uploadFile(file, index) {
            // TODO: Use asset api service - createAsset
            let formData = new FormData();
            formData.append('file', file);
            formData.append('display_name', file.name);
            formData.append('agency_id', this.agency.id);
            try {
                 let uploadedAsset = await this.$http.axios.post('/assets/upload', formData, {
                    onUploadProgress: () => {
                        let uploadProgress = ((Number(index) + 1) * 100) / this.totalFiles;
                        if(uploadProgress > 100) {
                            uploadProgress = 100;
                        }
                        this.uploadProgress = Math.round(uploadProgress);
                }
                });
                this.uploadedAssets.push(uploadedAsset.data);
            } catch (error) {
                this.$flash('Error uploading files', 'red');
                this.uploading = false;
            } finally {
                if(index == this.totalFiles - 1) {
                    this.$flash('Assets uploaded', 'green');
                    this.$emit('asset-added',  this.uploadedAssets);
                    this.uploading = false;
                    this.errorMessage = '';
                }
            }
        },
        setExtension(filename) {
            const parts = filename.split('.');
            this.extension = parts[parts.length - 1];
        },
    }
};
</script>

<style lang="scss">
#upload-asset-input {
    display: none;
}
.upload-asset-label {
    display: inline-block;
    padding: 6px 12px;
    background: #03A2EA;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    width: 60%;
    text-align: center;
}

.upload-asset-container {
    text-align: center;
    width: 300px;
    height: 70%;
    padding: 10px 25px;
    border: dashed 2px rgba($color: $gray, $alpha: 0.6);
    border-radius: 5px;
}

.no-border {
    border: none !important;
}

.upload-asset-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.upload-asset-button-mode {
    width: 100% !important;
    cursor: pointer;
}

.upload-asset-button-label {
    cursor: pointer;
    width: 100% !important;
}

.loading-circle{
        position: relative;
    }
.loading-percentage{
        font-size: 30px;
        font-weight: bold;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
.upload-asset-error {
    color: red;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}


</style>