/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contract-legal': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M4.667 0C3.748 0 3 .748 3 1.667v16.666C3 19.253 3.748 20 4.667 20h11.666c.919 0 1.667-.748 1.667-1.667V4.583a.417.417 0 00-.122-.294L13.711.122A.417.417 0 0013.417 0h-8.75zm9.166 1.423l2.744 2.744h-1.91a.835.835 0 01-.834-.834v-1.91zM5.917 4.167h5a.416.416 0 110 .833h-5a.417.417 0 110-.833zm0 2.5h9.166a.416.416 0 110 .833H5.917a.417.417 0 110-.833zm0 2.5h9.166a.416.416 0 110 .833H5.917a.417.417 0 110-.833zm3.333 2.5h5.833a.416.416 0 110 .833H9.25a.417.417 0 110-.833zm-2.5 1.666c.483 0 1.25.34 1.25 1.25 0 .53-.369 1.218-.828 1.81.17.16.325.264.416.274.091-.065.344-.58.466-.828.25-.505.414-.839.78-.839.426 0 .56.443.728 1.004.05.167.132.441.205.605.834.028 1.423.034 2.326.056.113.065.322.107.318.47-.002.301-.251.315-.356.37-1.99-.006-1.109.006-2.388-.005-.518 0-.703-.594-.896-1.23-.29.59-.625 1.23-1.188 1.23-.29 0-.636-.19-.962-.481-.156.154-.31.29-.455.398a.414.414 0 01-.583-.084.417.417 0 01.084-.583c.132-.1.266-.219.398-.35-.331-.458-.565-.978-.565-1.4 0-.95.758-1.667 1.25-1.667zm0 .834a1.072 1.072 0 00-.417.833c0 .213.12.481.29.74.319-.442.544-.891.544-1.157 0-.397-.348-.416-.417-.416z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
