/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder': {
    width: 21,
    height: 18,
    viewBox: '0 0 21 18',
    data: '<path pid="0" d="M19.5 3H9L6 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h18a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0019.5 3z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
