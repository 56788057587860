/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<path pid="0" d="M1.853 11.91c.231 0 .42.204.42.454v5.326h15.474v-5.326c0-.25.19-.455.421-.455h1.41c.233 0 .422.205.4.455v7.306c0 .25-.189.455-.42.455H.42C.19 20.125 0 19.92 0 19.67v-7.306c0-.25.19-.455.421-.455zm9.07-11c.228 0 .413.195.413.434v8.666l2.415-2.563a.409.409 0 01.599 0l.99 1.021a.462.462 0 010 .63l-4.809 5.06a.409.409 0 01-.598 0l-4.81-5.06a.462.462 0 010-.63l.97-1.02a.409.409 0 01.6 0l2.435 2.562V1.344c0-.24.186-.435.413-.435z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
