/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indicator-error': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M9 0a9 9 0 110 18A9 9 0 019 0zM6.238 4.686L4.68 6.243l2.775 2.775-2.753 2.753 1.558 1.558 2.753-2.753 2.739 2.738 1.557-1.557-2.738-2.74 2.753-2.752-1.558-1.558L9.013 7.46 6.238 4.686z" _fill="#EE5643" fill-rule="evenodd"/>'
  }
})
