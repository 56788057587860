/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dropdown': {
    width: 15,
    height: 8,
    viewBox: '0 0 15 8',
    data: '<path pid="0" d="M14.985 0l-7.25 7.192L.485 0z" _fill="#03A2EA" fill-rule="evenodd"/>'
  }
})
