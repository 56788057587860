/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fb_reels_comment_icon': {
    width: 54,
    height: 54,
    viewBox: '0 0 54 54',
    data: '<defs><filter id="svgicon_fb_reels_comment_icon_b" width="163.2%" height="163.2%" x="-31.6%" y="-31.6%" filterUnits="objectBoundingBox"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.300453453 0"/></filter><circle pid="0" id="svgicon_fb_reels_comment_icon_a" cx="19" cy="19" r="19"/></defs><g _fill="none" fill-rule="evenodd" transform="translate(8 8)"><use xlink:href="#svgicon_fb_reels_comment_icon_a" _fill="#000" filter="url(#svgicon_fb_reels_comment_icon_b)"/><use xlink:href="#svgicon_fb_reels_comment_icon_a" _fill="#D8D8D8" fill-opacity=".198"/><path pid="1" _fill="#FFF" fill-rule="nonzero" d="M25.75 10c.598 0 1.125.246 1.582.668.422.457.668.984.668 1.582v10.125c0 .633-.246 1.16-.668 1.582-.457.457-.984.668-1.582.668h-5.062l-4.395 3.305c-.07.07-.176.07-.281.07a.399.399 0 01-.282-.105c-.07-.07-.105-.176-.105-.317v-2.953H12.25c-.633 0-1.16-.21-1.582-.668-.457-.422-.668-.95-.668-1.582V12.25c0-.598.21-1.125.668-1.582.422-.422.95-.668 1.582-.668h13.5z"/></g>'
  }
})
