import GradientPoint from './GradientPoint';
import { generateGradientStyle, updateGradientActivePercent } from '@/components/globals/GradientPicker/helpers';
import { useMouseEvents } from '@/components/globals/GradientPicker/hooks';
import { calculateDegree } from '@/components/globals/GradientPicker/helpers';

export default {
    name: 'index',

    props: {
        points: Array,
        degree: Number,
        reference: String,
        type: String,
        activePointIndex: Number,
        changeActivePointIndex: Function,
        updateGradientLeft: Function,
        addPoint: Function,
        removePoint: Function,
        changeGradientControl: Function
    },

    data() {
        return {
            width: 0,
            positions: { x: 0, y: 0 },
            mouseEvents: () => {},
            disableClick: false,
        };
    },

    components: {
        GradientPoint,
    },

    mounted() {
        const pointer = this.$refs[this.reference];
        console.log(pointer);
        if (pointer) {
            this.width = pointer.clientWidth;

            const pointerPos = pointer.getBoundingClientRect();

            this.positions = { x: pointerPos.x, y: pointerPos.y };
        }
        this.mouseEvents = useMouseEvents(this.mouseDownHandler, this.mouseMoveHandler, this.mouseUpHandler);
    },
    computed: {
        pointsStyle() {
            const style = generateGradientStyle(this.points, 'linear', 90);

            return { background: style };
        },
        degreesStyle() {
            return { transform: `rotate(${this.degree}deg)` };
        }
    },

    watch: {
        type() {
            this.$nextTick(() => {
                const pointer = this.$refs[this.reference];
                if (pointer) {
                this.width = pointer.clientWidth;
                const pointerPos = pointer.getBoundingClientRect();
                this.positions = { x: pointerPos.x, y: pointerPos.y };
            }
            });

        },
    },

    methods: {
        pointsContainerClick(event) {
            const left = updateGradientActivePercent(event.pageX - this.positions.x, this.width);

            this.addPoint(left);
        },
        mouseDownHandler(event) {
            const pointer = event.target;
            const pointerBox = pointer.getBoundingClientRect();
            const centerY = pointerBox.top + parseInt(8 - window.pageYOffset, 10);
            const centerX = pointerBox.left + parseInt(8 - window.pageXOffset, 10);

            return {
                centerY,
                centerX,

            };
        },
        debug() {
            alert(JSON.stringify(this.degreesStyle));
        },
        mouseMoveHandler(event, { centerX, centerY }) {
            this.disableClick = true;

            const newDegree = calculateDegree(event.clientX, event.clientY, centerX, centerY);

            this.changeGradientControl({ degree: parseInt(newDegree, 10) });
        },

        mouseUpHandler(event) {
            const targetClasses = event.target.classList;

            if (targetClasses.contains('gradient-degrees') || targetClasses.contains('icon-rotate')) {
                return;
            }

            this.disableClick = false;
        },

        onClickGradientDegree() {
            if (this.disableClick) {
                this.disableClick = false;
                return;
            }

            let gradientDegree = this.degree + 45;

            if (gradientDegree >= 360) {
                gradientDegree = 0;
            }

            this.changeGradientControl({ degree: parseInt(gradientDegree, 10) });
        }
    }
};
