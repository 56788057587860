<template>
    <div
        ref="text-asset-div"
        :draggable="false"
        :style="textAssetStyles"
        class="text-asset-inner-layer"
        contenteditable="true"
        @mousedown="handleClick"
        @dblclick="handleDblClick"
        @blur="updateDomLayer"
        @input="updateTextLayerDebounced" />
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { gradientToString } from '@/components/creative-studio/layered-designer/utils/gradient-to-string';
    import { debounce } from 'lodash';
    export default {
        props: {
            layer: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                ignoreFocus: true,
                editing: false
            };
        },
        computed: {
            textAssetStyles() {
                return {
                    fontFamily: this.layer.asset.fontFamily,
                    ...this.textColorStyles,
                    width: this.layer.dimensions.width + 'px',
                    height: this.layer.dimensions.height + 'px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: this.layer.asset?.textAlign || 'center',
                };
            },
            textColorStyles() {
                const layerGradientDefinition = `url(#${this.layer?.id}_layer_gradient)`;

                const styles= {
                    color:  this.layer.colorGradient.type !== 'solid' ? layerGradientDefinition :  this.layer.asset.fillColor,
                    fontWeight: this.layer.asset.weight,
                    fontStyle: this.layer.asset.fontStyle,
                };

                if (this.layer.usesColorGradient()) {
                    styles.color= 'transparent';
                    styles.backgroundColor= 'transparent';

                    styles.backgroundImage= gradientToString(this.layer.colorGradient);
                    styles.backgroundClip= 'text';
                    styles['-webkit-background-clip']= 'text';
                    styles['-webkit-text-fill-color']= 'transparent';
                }

                return styles;
            },
            ...mapGetters(['focusedLayer'])
        },
        watch: {
            focusedLayer(focusedLayer) {
                if(!focusedLayer || focusedLayer.id !== this.layer.id) {
                    this.$refs['text-asset-div'].contenteditable = false;
                    this.editing = false;
                    // remove focus from text layer
                    this.$refs['text-asset-div'].blur();
                }
            },
        },
           mounted() {
            this.$el.innerText = this.layer.asset.text;
         },
        methods: {
            ...mapActions([
                'setFocusedLayer',
                'updateTextLayer'
            ]),
            updateDomLayer() {
                this.$el.innerText = this.layer.asset.text;
            },
            updateLayerText(event) {

                const updatedText = event.target.innerText;
                if (updatedText === this.layer.asset.text) {
                    return;
                }
                if(this.focusedLayer.id !== this.layer.id) {
                    return;
                }
                const updateFields = {
                    text: updatedText
                };
                this.updateTextLayer(updateFields);

            },
            // Debounc function on keyup to dont trigger
            // history add state on every key pressed
            updateTextLayerDebounced: debounce(function(event) {
                this.updateLayerText(event);
            }, 200),
            handleDblClick() {
                this.$refs['text-asset-div'].contenteditable = true;
                this.$refs['text-asset-div'].focus();
                // set cursor at the end of the text
                const range = document.createRange();
                const sel = window.getSelection();
                range.setStart(this.$refs['text-asset-div'], 1);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
                this.editing = true;

            },
            handleClick(e) {
                if(!this.editing) {
                    this.$refs['text-asset-div'].contenteditable = false;
                    this.setFocusedLayer(this.layer);
                    e.preventDefault();
                    return;
                }
            },
    }
    };
</script>

<style lang="scss" scoped>
.text-asset-inner-layer {
     outline: none;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

</style>