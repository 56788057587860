<template>
    <data-field
        :label="label"
        :no-indent="noIndent"
        :margin-left="marginLeft">
        <styled-tooltip
            v-model="localOpen"
            position="bottom"
            :max-width="maxWidth"
            open-on-click>
            <slot v-if="$slots.default" />
            <a
                v-else
                href="#"
                @click.prevent="localOpen = !localOpen">
                <span>{{ value }}</span>
            </a>
            <template #content>
                <div ref="content">
                    <transition name="fade">
                        <div
                            v-if="currentMessageState"
                            class="message-success">
                            {{ currentMessageState }}
                        </div>
                    </transition>
                    <transition name="fade">
                        <div
                            v-if="currentErrorState"
                            class="message-error">
                            {{ currentErrorState }}
                        </div>
                    </transition>
                    <!-- If no content has been supplied create the form -->
                    <template v-if="!$slots.content">
                        <v-form
                            ref="form"
                            v-model="valid"
                            @submit.prevent="handleSubmit">
                            <v-text-field
                                v-model="localFieldValue"
                                :rules="rules"
                                name="input"
                                class="styled-field mb-3"
                                :label="label" />
                            <div class="button-wrapper">
                                <styled-button
                                    :loading="currentLoadingState"
                                    small>
                                    {{ buttonLabel }}
                                </styled-button>
                            </div>
                        </v-form>
                    </template>
                    <!-- Allow consumers to override tooltip content -->
                    <slot
                        v-else-if="replaceContent"
                        name="content" />
                    <v-form
                        v-else
                        ref="form"
                        v-model="valid"
                        @submit.prevent="handleSubmit">
                        <slot name="content" />
                        <div
                            v-if="!hideSaveButton"
                            class="button-wrapper">
                            <styled-button
                                :loading="currentLoadingState"
                                small>
                                {{ buttonLabel }}
                            </styled-button>
                        </div>
                    </v-form>
                </div>
            </template>
        </styled-tooltip>
        <template
            v-if="$slots.indicator || currentLoadingState"
            #indicator>
            <styled-loader
                v-if="currentLoadingState"
                size="10" />
            <slot
                v-else
                name="indicator" />
        </template>
        <!-- Pass actions down to data field -->
        <template
            v-if="$slots.actions || !hideEdit"
            #actions>
            <action-button
                v-if="!hideEdit"
                class=""
                size="15"
                icon="edit"
                @click.stop="localOpen = !localOpen" />
            <slot name="actions" />
        </template>
    </data-field>
</template>

<script>
import sleep from '@/helpers/sleep';
import ActionButton from '@/components/globals/ActionButton';
import DataField from '@/components/globals/DataField';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledButton from '@/components/globals/StyledButton';
import StyledLoader from '../../StyledLoader.vue';

export default {
    components: {
        ActionButton,
        DataField,
        StyledTooltip,
        StyledButton,
        StyledLoader
    },
    props: {
        label: {
            type: String,
            default: undefined,
        },
        buttonLabel: {
            type: String,
            default: 'Save',
        },
        hideEdit: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: undefined
        },
        message: {
            type: String,
            default: undefined
        },
        error: {
            type: String,
            default: undefined
        },
        handler: {
            type: Function,
            default: null
        },
        open: {
            type: Boolean,
            default: undefined,
        },
        maxWidth: {
            type: Number,
            default: 320
        },
        replaceContent: {
            type: Boolean,
            default: false
        },
        marginLeft: {
            type: String,
            default: undefined
        },
        noIndent: {
            type: Boolean,
            default: false
        },
        hideSaveButton: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            valid: true,
            localOpen: false,
            localMessage: '',
            localLoading: false,
            localError: '',
            localFieldValue: '',
        };
    },
    computed: {
        currentLoadingState() {

            if (typeof this.loading !== 'undefined') {
                return this.loading;
            }

            return this.localLoading;
        },
        currentErrorState() {
            if (typeof this.error !== 'undefined') {
                return this.error;
            }

            return this.localError;
        },
        currentMessageState() {
            if (typeof this.message !== 'undefined') {
                return this.message;
            }

            return this.localMessage;
        }
    },
    watch: {
        open: {
            handler(value) {
                if (typeof value !== 'undefined') {
                    this.localOpen = value;
                }
            },
            immediate: true
        },
        localOpen(value) {
            this.$emit('update:open', value);

            if (value) {
                this.focusField();
            }
        },
        value: {
            handler(value) {
                this.localFieldValue = value;
            },
            immediate: true
        }
    },
    methods: {
        /**
         * Primary form submission handler
         */
        async handleSubmit() {
            this.$refs.form.validate();

            if (!this.valid) {
                return;
            }

            const formProps = this.getFieldValues();
            this.$emit('submit', formProps);

            // If the consumer has passed an async handler run it
            if (typeof this.handler === 'function') {
                this.runHandler();
            }
        },
        /**
         * Execute the handler passed by the parent, if present
         * This may look like an anti-pattern but is the only way
         * to allow to simplicity in the parent code for this
         * repeat use-case
         */
        async runHandler() {
            try {
                this.$emit('update:loading', true);

                this.localLoading = true;
                this.localError = '';

                const formProps = this.getFieldValues();

                const result = await this.handler(formProps);
                this.$emit('complete', result);

                this.localLoading = false;
                this.$emit('update:loading', false);

                this.localMessage = 'Data saved successfully!';

                await sleep(1000);

                this.localOpen = false;

                await sleep(300);

                this.localMessage = '';

            } catch (error) {
                this.localError = error;
                this.$emit('error', error);

                this.localLoading = false;
                this.$emit('update:loading', false);
            }

        },
        async focusField() {
             await this.$nextTick();

            const field = this.$refs.content?.querySelector?.('input');

            if (field) {
                // This is the only way I've found to ensure
                // this focuses consistently
                await this.$nextTick();
                await sleep(50);
                field.focus();
            }
        },
        getFieldValues() {
            const formData = new FormData(this.$refs.form?.$el);
            return Object.fromEntries(formData);
        }
    }
};
</script>

<style lang="scss" scoped>
.message-success {
    border: 1px solid $success-500;
    background-color: rgba($success-500, 0.1);
    color: $success-500;
    padding: 5px 10px;
    margin-bottom: 15px;
}
.message-error {
    border: 1px solid $error-500;
    background-color: rgba($error-500, 0.1);
    color: $error-500;
    padding: 5px 10px;
    margin-bottom: 15px;
}
.value {
    display: flex;
}
.actions {
    margin-left: 5px;
}
.button-wrapper {
    text-align: center;
    margin-top: 20px;
    button {
        min-width: 100px;
    }
}
</style>
