import {
    PRODUCT_MARKETPLACE,
    PRODUCT_ADVERTISING,
    PRODUCT_ADVERTISING_PLUS,
    PRODUCT_TIKTOK_ADS,
    PRODUCT_SNAPCHAT_ADS,
    PRODUCT_PINTEREST_ADS,
    PRODUCT_TIKTOK_ADS_PLUS,
    PRODUCT_SNAPCHAT_ADS_PLUS,
    PRODUCT_PINTEREST_ADS_PLUS,
    PRODUCT_GROUP_MARKETPLACE,
    PRODUCT_GROUP_ADVERTISING,
    PRODUCT_ADVERTISING_LITE,
    PRODUCT_PINTEREST_ADS_LITE,
    PRODUCT_TIKTOK_ADS_LITE,
    PRODUCT_SNAPCHAT_ADS_LITE,
    PRODUCTS,
    VERTICAL_AUTOMOTIVE,
    PLATFORM_FACEBOOK,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK,
    PLATFORM_PINTEREST,
    PLATFORM_GOOGLE,
    PRODUCT_GOOGLE_MARKETPLACE,
    PRODUCT_GOOGLE_ADS_PLUS,
} from '@/helpers/globals.js';
import { cloneDeep, groupBy } from 'lodash';

const DEFAULT_ADS_DESCRIPTION = 'Simplify onboarding, reporting, and managing clients\' social advertising across Meta, Snapchat, TikTok, and Pinterest! Our 3 Advertising product packages offer a unique feature set to fit your business\' needs.';

const channels = [
    {
        name: 'Facebook',
        display_name: 'Facebook',
        key: PLATFORM_FACEBOOK,
        description: 'Simplify setting up your client\'s Meta and Instagram (Meta) accounts with BuyerBridge! Our Meta connection automatically integrates and optimizes your Business Manager, Ad Accounts, and Assets for a smarter, faster experience.',
        vertical_descriptions: {
            [VERTICAL_AUTOMOTIVE]: 'List a dealership\'s inventory on Meta Marketplace and/or deploy ready-to-go Meta Ad campaigns with fully optimized campaign setup, audience targeting, and engaging ad creative. Automatically follow up with website visitors who have shown intent, convert shoppers directly on Meta & Instagram with Dynamic Lead Ads, and push leads straight into your dealer\'s CRM. Plus, get all the tools you need to track your client\'s success and report on key metrics.',
        },
        verticals: [],
        learn_more_link: 'https://buyerbridge.com/social-platforms/facebook/',
        set_up_route_name: '/channel-setup/facebook',
        icon_name: 'meta',
        platform_id: 1,
        visibility: 'live',
        products: [
            /* {
                product_group: PRODUCT_GROUP_MARKETPLACE,
                product_category: PRODUCT_MARKETPLACE,
                bb_product_id: PRODUCT_MARKETPLACE
            },
            {
                product_group: PRODUCT_GROUP_MARKETPLACE,
                product_category: PRODUCT_MARKETPLACE_PLUS,
                bb_product_id: PRODUCT_MARKETPLACE_PLUS
            }, */
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_LITE,
                bb_product_id: PRODUCT_ADVERTISING_LITE
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING,
                bb_product_id: PRODUCT_ADVERTISING
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_PLUS,
                bb_product_id: PRODUCT_ADVERTISING_PLUS
            }
        ],
        details_page: {
            show_products_grid: true,
            marketplace_description: 'Sync a dealership\'s pre-owned inventory to Meta\'s organic Marketplace platform where active auto shoppers can search and browse a dealership\'s inventory in real-time. Get dynamic reporting on leads and Messenger inquiries, plus use our Chatbot and Call Tracking for even more control over your listings.',
            ads_description: DEFAULT_ADS_DESCRIPTION,
            vertical_ads_descriptions: {
                [VERTICAL_AUTOMOTIVE]: 'Create fully optimized Meta Ad campaigns with Playbooks and Ads that are ready-to-go with audience targeting, campaign setup, creative templates, and Automotive Inventory Ads (AIA). Gain full access to BuyerBridge Monitoring, Reporting, EventFlow and LeadFlow tools.',
            },
        }
    },
    {
        name: 'Snapchat',
        display_name: 'Snapchat',
        key: PLATFORM_SNAPCHAT,
        description: 'Easily add your client\'s Snapchat Business Manager, Ad Account, and Assets to BuyerBridge to begin driving powerful insights from Snapchat Ads, ensuring you have the full picture of your client\'s ad strategy.',
        vertical_descriptions: {
            [VERTICAL_AUTOMOTIVE]: 'Deploy fully optimized Snapchat Inventory Ads that dynamically target users based on their engagement with the app + your dealership client\'s website. Run single image ads or collection ads across Prospecting and Retargeting campaigns. You\'ll send shoppers to dealers VDP pages on their website using campaigns optimized to low funnel intent for seamless engagement. Our EventFlow tracking system will utilize critical lead events and user activity captured by the Snapchat Pixel for future retargeting on Snapchat + cross-channel.',
        },
        verticals: [],
        learn_more_link: 'https://buyerbridge.io/social-platforms/snapchat/',
        set_up_route_name: null,
        icon_name: 'snapchat',
        platform_id: 9,
        visibility: 'beta',
        products: [
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_LITE,
                bb_product_id: PRODUCT_SNAPCHAT_ADS_LITE
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING,
                bb_product_id: PRODUCT_SNAPCHAT_ADS
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_PLUS,
                bb_product_id: PRODUCT_SNAPCHAT_ADS_PLUS
            }
        ],
        details_page: {
            show_grid: true,
            marketplace_description: '',
            ads_description: DEFAULT_ADS_DESCRIPTION,
            vertical_ads_descriptions: {
                [VERTICAL_AUTOMOTIVE]: 'Create fully optimized Snapchat Ad campaigns with Dynamic Inventory Ads that are ready-to-go. Gain full access to Snapchat Reporting capabilities built within our platform to provide insights in Ad performance.',
            }
        }
    },
    {
        name: 'TikTok',
        display_name: 'TikTok',
        key: PLATFORM_TIKTOK,
        description: 'Begin visualizing and reporting on your clients\' TikTok content by connecting your client\'s TikTok Business Center, Ad accounts, and Assets with BuyerBridge — all managed alongside your other social platforms.',
        vertical_descriptions: {
            [VERTICAL_AUTOMOTIVE]: 'Turn your dealership client\'s new and used inventory into dynamic video inventory ads on TikTok. Run ready-to-deploy Prospecting ad campaigns that are fully optimized for audience targeting, ad creative, and campaign setup. Our EventFlow tracking system will utilize critical lead events and user activity captured by the TikTok Pixel for future cross-channel retargeting.',
        },
        verticals: [],
        learn_more_link: 'https://buyerbridge.io/social-platforms/tiktok/',
        set_up_route_name: null,
        icon_name: 'tiktok',
        platform_id: 8,
        visibility: 'beta',
        products: [
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_LITE,
                bb_product_id: PRODUCT_TIKTOK_ADS_LITE
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING,
                bb_product_id: PRODUCT_TIKTOK_ADS
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_PLUS,
                bb_product_id: PRODUCT_TIKTOK_ADS_PLUS
            }
        ],
        details_page: {
            show_grid: true,
            marketplace_description: '',
            ads_description: DEFAULT_ADS_DESCRIPTION,
            vertical_ads_descriptions: {
                [VERTICAL_AUTOMOTIVE]: 'Create dynamic video inventory ads using your dealership client\'s inventory on TikTok. Gain full access to TikTok Reporting capabilities built within our platform to provide insights in Ad performance and our EventFlow tools.'

            },
        }
    },
    {
        name: 'Pinterest',
        display_name: 'Pinterest',
        key: PLATFORM_PINTEREST,
        description: 'Integrate your client\'s Pinterest Business Center and Ad accounts using BuyerBridge to help you measure all of your client\'s social ad campaigns and ROI from a single, scalable platform.',
        vertical_descriptions: {
            [VERTICAL_AUTOMOTIVE]: 'Transform your dealership client\'s new Inventory into Pinterest ad campaigns fully optimized for audience targeting, ad creative, and campaign setup. With access to Pinterest\'s highly engaged audience and our precise targeting, dealers will have the ability to directly reach the right people, at the right time, with content that results in high conversion and click-through rates.',
        },
        verticals: [],
        learn_more_link: 'https://buyerbridge.io/social-platforms/pinterest/',
        set_up_route_name: null,
        icon_name: 'pinterest',
        platform_id: 10,
        visibility: 'beta',
        products: [
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_LITE,
                bb_product_id: PRODUCT_PINTEREST_ADS_LITE
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING,
                bb_product_id: PRODUCT_PINTEREST_ADS
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_PLUS,
                bb_product_id: PRODUCT_PINTEREST_ADS_PLUS
            }
        ],
        details_page: {
            show_grid: true,
            marketplace_description: '',
            ads_description: DEFAULT_ADS_DESCRIPTION,
            vertical_ads_descriptions: {
                [VERTICAL_AUTOMOTIVE]: 'Create fully optimized Pinterest Pins that are ready-to-go using your dealership client\'s new Inventory. Gain full access to our Pinterest Reporting capabilities built within our platform to provide insights in Ad performance and our EventFlow tools.'
            },
        },
    },
    {
        name: 'Google',
        key: PLATFORM_GOOGLE,
        description: '',
        vertical_descriptions: {
            [VERTICAL_AUTOMOTIVE]: 'Activate Vehicle listings on Google to surface inventory on dealerships\' Google Business Profiles and on other Google surfaces where users can search, filter, and easily learn more about each vehicle.',
        },
        verticals: [
            VERTICAL_AUTOMOTIVE
        ],
        learn_more_link: 'https://support.buyerbridge.com/knowledge/google-vehicle-listings',
        set_up_route_name: '/channel-setup/google',
        icon_name: 'google',
        platform_id: 11,
        visibility: 'beta',
        products: [
            {
                product_group: PRODUCT_GROUP_MARKETPLACE,
                product_category: PRODUCT_MARKETPLACE,
                bb_product_id: PRODUCT_GOOGLE_MARKETPLACE
            },
            {
                product_group: PRODUCT_GROUP_ADVERTISING,
                product_category: PRODUCT_ADVERTISING_PLUS,
                bb_product_id: PRODUCT_GOOGLE_ADS_PLUS
            }
        ],
        details_page: {
            show_grid: false,
            marketplace_description: 'Sync a dealership\'s pre-owned and new inventory to Google Vehicle Listings which surface inventory within their Google Business Profile and on relevant searches.',
            ads_description: DEFAULT_ADS_DESCRIPTION,
            vertical_ads_descriptions: {
                [VERTICAL_AUTOMOTIVE]: 'Supercharge your Google search ads with a dynamic, inventory-driven, strategy through their Vehicle Listing Ads program where BuyerBridge syndicates your inventory directly to Google for a low-funnel experience.',
            },
        }
    },
];

export const getDealerChannels = (dealer) => {

    // Clean the channels to alleviate bugs when switching accounts since we're modifying the object
    return cloneDeep(channels)
            .map(channel => {

                // Override the descriptions with the one from the vertical if set
                if (channel.vertical_descriptions?.[dealer.vertical_id]) {
                    channel.description = channel.vertical_descriptions[dealer.vertical_id];
                }

                if (channel.details_page?.vertical_ads_descriptions?.[dealer.vertical_id]) {
                    channel.details_page.ads_description = channel.details_page.vertical_ads_descriptions[dealer.vertical_id];
                }

                return channel;
            })
            .filter(channel => (
                channel.verticals?.length ? channel.verticals.includes(dealer.vertical_id): true
            ));
};

export const getDealerAdAccounts = (dealer) => {

    const accounts = {};

    for (const channel of channels) {
        if (
            channel.key === PLATFORM_FACEBOOK &&
            dealer?.facebook_ad_account_annotations?.data?.[0]?.facebook_ad_account_id) {
            accounts[channel.key] = dealer.facebook_ad_account_annotations.data[0].facebook_ad_account_id.replace('act_', '');
        } else {
            const primaryAccount = dealer.ad_accounts?.data?.find(account => account.platform_id === channel.platform_id);
            if (primaryAccount?.external_ad_account_id) {
                accounts[channel.key] = primaryAccount.external_ad_account_id;
            }
        }
    }
    return accounts;
};

export const getDealerChannelAdProducts = (dealer, channelName) => {
    const channel = getChannelByPlatformName(channelName);
    return channel.products
        ?.filter(channelProduct => (
            channelProduct.product_group === PRODUCT_GROUP_ADVERTISING &&
            dealer.products?.data?.some(dealerProduct => (
                dealerProduct.id === channelProduct.bb_product_id
            ))
        )) ?? [];
};

export const dealerHasChannelAdProduct = (dealer, channelName) => {
    const adProducts = getDealerChannelAdProducts(channelName);
    return Boolean(adProducts?.length);
};

export const getDealerChannelByPlatformName = (dealer, channelName) => {
    return getDealerChannels(dealer)
                .find(channel => channel.name.toLowerCase() === channelName.toLowerCase());
};

export const getChannelByPlatformName = (name) => {
    return channels.find(channel => channel.name.toLowerCase() === name.toLowerCase());
};

export const getChannelByPlatformId = (platform_id) => {
    return channels.find(channel => channel.platform_id === platform_id);
};

export const getChannelByPlatformKey = (platformKey) => {
    return channels.find(channel => channel.key === platformKey);
};

export const getChannelsByPlatformKeys = (platforms) => {
    return platforms.map(platform => (
        getChannelByPlatformKey(platform)
    ));
};

export const channelInUse = (platform_id) => {
    return channels.find(channel => channel.platform_id === platform_id);
};

export const getProductFromChannelAndCategory = (channel, product_category) => {
    return channel.products.find(product => product.product_category === product_category)?.bb_product_id;
};

export const channelHasProduct = (channel, product_category) => {
    return channel.products.some(product => product?.product_category === product_category);
};

export const hasLargestProductForChannel = (channel, product_group, dealerProductIds) => {
    // Get all the products where the product group is the product_group
    const group_specific_products = channel.products.filter(product => product.product_group === product_group);
    // Filter down to the last product in the list
    const largest_product = group_specific_products[group_specific_products.length - 1];
    // See if that is already selected
    return dealerProductIds.includes(largest_product.bb_product_id);
};


export const getChannelProductUpgrades = (platformId, dealerProductIds) => {
    const channel = getChannelByPlatformId(platformId);

    // Just in case new channels are added to the backend before
    // support is added to the frontend
    if (!channel) {
        return [];
    }

    const channelProductIds = channel.products.map(product => product.bb_product_id);

    let upgradeProducts = [];

    // Split the channel's products into their groups and determine if the dealer
    // has any products within the group.  If not, consider every product in the group
    // an upgradeable product.
    const groups = groupBy(channel.products, 'product_group');
    Object.values(groups).forEach(groupProducts => {
        if (!groupProducts.some(product => dealerProductIds.includes(product.bb_product_id))) {
            const groupProductIds = groupProducts.map(product => product.bb_product_id);
            upgradeProducts = upgradeProducts.concat(groupProductIds);
        }
    });

    // Loop through the rest of the products and if any one of them has one of the dealer's
    // current products listed in it's "upgradeFrom" property include it.
    channelProductIds.forEach(currentProductId => {
        const productConfig = PRODUCTS.find(product => product.id === currentProductId);

        if (productConfig.upgradeFrom?.some(productId => dealerProductIds.includes(productId))) {
            upgradeProducts.push(productConfig.id);
        }
    });

    return upgradeProducts;
};


export const canUpgradeChannel = (platformId, dealerProductIds) => {
    const productUpgrades = getChannelProductUpgrades(platformId, dealerProductIds);
    return Boolean(productUpgrades.length);
};

export const getChannelByBBProductId = (bb_product_id) => {
    return channels.find(channel => {
        return channel.products.find(product => product.bb_product_id === bb_product_id);
    });
};

export const channelHasBBProduct = (channel, products) => {
    return products.some(bb_product => {
        return channel.products.some(product => product.bb_product_id === bb_product.id);
    });
};

export const getProductCategoryByBBProductId = (bb_product_id) => {
    return channels.map(channel => {
        return channel.products.find(product => product.bb_product_id === bb_product_id)?.product_category;
    }).filter(x => x)[0];
};

export const getProductsForChannelAndProductGroup = (channel, product_group) => {
    return channel.products.filter(product => product?.product_group === product_group);
};


export const getDealerPlatformBusinessManagerId = (dealer, platform) => {

    if (platform === PLATFORM_FACEBOOK) {
        return  dealer.agency?.facebook_business_id ?? null;
    }

    const { platform_id } = getChannelByPlatformKey(platform);
    return dealer.agency?.business_managers?.data?.find(businessManager => (
        businessManager.platform_id == platform_id
    ))?.external_business_manager_id ?? null;
};

export const getDealerCatalogId = (dealer, platform) => {
    if (platform === PLATFORM_FACEBOOK) {
        return dealer.facebook_product_catalog_annotations?.data?.[0]?.facebook_product_catalog_id;
    }

    const { platform_id } = getChannelByPlatformKey(platform);

    return dealer.catalogs?.data?.find(catalog => (
        catalog.platform_id == platform_id
    ))?.external_catalog_id ?? null;
};

export const getDealerPlatformExternalIds = (dealer, platform) => {
    const adAccounts = getDealerAdAccounts(dealer);
    const businessManagerId = getDealerPlatformBusinessManagerId(dealer, platform);
    const catalogId = getDealerCatalogId(dealer, platform);

    return {
        businessManagerId,
        adAccountId: adAccounts?.[platform] ?? null,
        catalogId,
    };
};


export default channels;
