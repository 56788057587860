/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" d="M13.386 7.39V5.431C13.386 2.442 10.959 0 7.99 0 5.02 0 2.593 2.442 2.593 5.432v1.957C1.13 7.516 0 8.737 0 10.253v6.863C0 18.716 1.276 20 2.865 20h10.27A2.866 2.866 0 0016 17.116v-6.863a2.861 2.861 0 00-2.614-2.864zm-4.142 8.8c.042.21-.104.4-.313.4H7.027c-.209 0-.355-.19-.313-.4l.627-2.485a1.514 1.514 0 01-.9-1.39c0-.841.691-1.536 1.528-1.536a1.54 1.54 0 011.526 1.537c0 .61-.376 1.137-.899 1.39l.648 2.483zm1.402-8.822H5.333V5.453A2.669 2.669 0 017.99 2.779c1.464 0 2.656 1.2 2.656 2.674v1.915z" _fill="#8F9EA6" fill-rule="nonzero"/>'
  }
})
