export function loadRecaptchaScript() {
    if (!window.grecaptcha) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }
}

export function removeRecaptchaScript() {
    const script = document.getElementById('recaptcha-script');
    if (script) {
        document.head.removeChild(script);
    }

    const recaptchaContainer = document.querySelector('.grecaptcha-badge');
    if (recaptchaContainer) {
        recaptchaContainer.parentNode.removeChild(recaptchaContainer);
    }

    delete window.grecaptcha;
    delete window.___grecaptcha_cfg;
}
