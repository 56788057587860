/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 19,
    height: 20,
    viewBox: '0 0 19 20',
    data: '<path pid="0" d="M15.328 2.778H6.311a.869.869 0 00-.638.264.869.869 0 00-.264.638v9.018c0 .249.088.461.264.637a.869.869 0 00.638.264h9.017a.869.869 0 00.638-.264.869.869 0 00.264-.637V3.68a.869.869 0 00-.264-.638.869.869 0 00-.638-.264M1.801 4.737v11.568c0 .25.088.462.265.638a.869.869 0 00.637.264h11.568c-.188.53-.514.964-.979 1.3a2.618 2.618 0 01-1.571.504H2.703a2.607 2.607 0 01-1.913-.793 2.607 2.607 0 01-.792-1.913V7.287c0-.582.168-1.106.504-1.57.335-.466.769-.792 1.3-.98M6.31.975h9.017c.747 0 1.385.264 1.913.793.529.528.793 1.166.793 1.912v9.018c0 .747-.264 1.385-.793 1.913a2.607 2.607 0 01-1.913.793H6.311a2.607 2.607 0 01-1.913-.793 2.606 2.606 0 01-.793-1.913V3.68c0-.746.264-1.384.793-1.912A2.607 2.607 0 016.31.975" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
