/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'collapse-all': {
    width: 12,
    height: 6,
    viewBox: '0 0 12 6',
    data: '<path pid="0" d="M11.657 6H.343L6 .343z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
