import { AssetType } from './types';

export class BaseAsset {

    constructor(id, type, name, thumbnail) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.thumbnail = thumbnail;
    }

    isTextAsset() {
        return this.type === AssetType.Text;
    }

    isImageAsset() {
        return this.type === AssetType.Image;
    }

    isShapeAsset() {
        return this.type === AssetType.Shape;
    }
}