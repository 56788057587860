/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'not-visible': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<path pid="0" d="M2 .586L19.414 18 18 19.414l-3.809-3.808c-1.078.423-2.148.637-3.191.637-1.279 0-2.6-.322-3.926-.957-1.038-.498-2.082-1.188-3.103-2.051-1.725-1.458-2.788-2.903-2.832-2.964a.72.72 0 010-.85l.026-.035a19.228 19.228 0 013.4-3.409L.585 2 2 .586zm.64 9.26c1.055 1.272 4.452 4.957 8.36 4.957a6.96 6.96 0 002.062-.326l-1.116-1.115A3.644 3.644 0 017.484 8.9l-1.89-1.892a18.422 18.422 0 00-2.955 2.838zM11 3.45c1.279 0 2.6.322 3.926.958 1.038.497 2.082 1.187 3.103 2.05 1.725 1.459 2.788 2.904 2.832 2.964a.72.72 0 010 .85c-.044.06-1.108 1.506-2.832 2.964-.254.215-.51.419-.767.612l-1.032-1.03c1.48-1.084 2.6-2.332 3.13-2.97-1.055-1.273-4.452-4.958-8.36-4.958-.79 0-1.56.151-2.295.402L7.591 4.175c1.152-.482 2.296-.726 3.409-.726zm0 2.757a3.644 3.644 0 013.443 4.822L9.818 6.403c.37-.127.768-.197 1.182-.197z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
