import { HttpFactory } from '@/creative-studio/domain/common/services/http/HttpFactory';

import { LinkedStatuses } from '@/creative-studio/domain/layered-designer/models/types';
export class LayeredDesignerApiService {

    constructor() {
        this.httpClient = HttpFactory.createClient();
    }

    async getCardSizes() {
        const cardSizes = [
           {
                id: 1,
                aspectRatio: '1:1',
                width: 1080,
                height: 1080,
                linkedStatus: LinkedStatuses.Main
            },
            {
                id: 2,
                aspectRatio: '9:16',
                width: 1080,
                height: 1920,
                linkedStatus: LinkedStatuses.Linked
            },
            {
                id: 3,
                aspectRatio: '2:3',
                width: 1000,
                height: 1500,
                linkedStatus: LinkedStatuses.Linked
            }
        ];

        return cardSizes;
    }
}
