/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sell': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data: '<path pid="0" d="M7.562 2.209c2.3 2.257 2.936 5.17 1.446 6.63a.572.572 0 01-.402.164.572.572 0 01-.403-.164.552.552 0 010-.79c.773-.759.556-2.307-.403-3.78l-.014.003-2.922.592-2.08 10.264 18.524 18.524 12.344-12.344L15.127 2.784l-5.044 1.022a.559.559 0 01-.222-1.094L15.2 1.63a.559.559 0 01.505.152l19.131 19.131a.558.558 0 010 .79L21.703 34.836a.558.558 0 01-.79 0L1.782 15.706a.56.56 0 01-.152-.506L3.843 4.28a.559.559 0 01.437-.437l2.753-.558A7.88 7.88 0 006.755 3C4.968 1.246 2.612.596 1.61 1.58c-1.002.984-.339 3.296 1.448 5.05a.552.552 0 010 .79.577.577 0 01-.806 0C-.049 5.162-.686 2.249.804.789 2.292-.673 5.26-.05 7.562 2.208zm5.574 10.185l1.378 1.377a.525.525 0 01.103.146c1.367-1.097 3.216-1.174 4.286-.118a.544.544 0 010 .778.562.562 0 01-.788 0c-.723-.713-2.083-.53-3.031.405l-.076.074c-.948.936-1.133 2.277-.41 2.99l.122.12c.333.329.82.478 1.37.42.596-.063 1.185-.356 1.66-.825 1.384-1.365 3.45-1.546 4.608-.405l.548.54c.577.57.841 1.367.745 2.248a3.762 3.762 0 01-.83 1.937c.01.007.018.015.027.024l1.377 1.377a.525.525 0 01-.743.743l-1.377-1.377-.026-.03c-.71.563-1.549.853-2.339.853-.729 0-1.417-.246-1.928-.75a.544.544 0 010-.778.562.562 0 01.788 0c.723.712 2.083.53 3.031-.405l.075-.074c.476-.47.773-1.05.837-1.638.059-.543-.092-1.023-.426-1.352l-.548-.54c-.722-.713-2.081-.531-3.03.405-.655.645-1.482 1.05-2.33 1.14-.892.095-1.701-.167-2.278-.735l-.122-.12c-1.064-1.051-.993-2.863.105-4.209a.516.516 0 01-.144-.101l-1.376-1.377a.525.525 0 01.742-.743z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
