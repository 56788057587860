/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'page-preconfigured': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#8F9EA6" fill-rule="evenodd"><path pid="0" d="M17 2.5H3A2.5 2.5 0 00.5 5v10A2.5 2.5 0 003 17.5h14a2.5 2.5 0 002.5-2.5V5A2.5 2.5 0 0017 2.5zm-14 1h14A1.5 1.5 0 0118.5 5v10a1.5 1.5 0 01-1.5 1.5H3A1.5 1.5 0 011.5 15V5A1.5 1.5 0 013 3.5z" fill-rule="nonzero"/><rect pid="1" x="3" y="11" width="6" height="4" rx="1"/><rect pid="2" x="11" y="11" width="6" height="4" rx="1"/><rect pid="3" x="3" y="5" width="14" height="4" rx="1"/></g>'
  }
})
