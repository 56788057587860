<template>
    <styled-dialog 
        :value="value"
        @input="$emit('input', $event)">
        <template #heading>
            <h2>Assign Permissions</h2>
        </template>
        <p class="">
            Choose which assets you would like to be assigned to:
        </p>
        <template>
            <v-layout align-center>
                <div class="flex xs12 blue-background pa-4">
                    <div
                        v-if="!individualOptions"
                        class="layout align-center justify-center row">
                        <div class="flex text-center">
                            <v-checkbox
                                v-model="selected"
                                label="All Assets"
                                value="all"
                                color="primary"
                                class="styled-checkbox" />
                        </div>
                        <div class="flex text-center">
                            <a
                                href="#"
                                @click.prevent="showIndividualOptions()">Choose individual</a>
                        </div>
                    </div>

                    <div
                        v-if="individualOptions"
                        class="layout align-center justify-center row">
                        <div>
                            <v-checkbox
                                v-model="selected"
                                label="Ad Account"
                                value="ad_account"
                                color="primary"
                                class="styled-checkbox" />

                            <v-checkbox
                                v-model="selected"
                                label="Pixel"
                                value="pixel" 
                                color="primary"
                                class="styled-checkbox" />

                            <v-checkbox
                                v-model="selected"
                                label="Catalogs"
                                value="catalogs"
                                color="primary"
                                class="styled-checkbox" />

                            <v-checkbox
                                v-model="selected"
                                label="Page"
                                value="page"
                                color="primary"
                                class="styled-checkbox" />
                        </div>
                        <div class="flex text-center">
                            <a
                                href="#"
                                @click.prevent="showAllOptions()">Choose All</a>
                        </div>
                    </div>
                </div>
            </v-layout>
            <div class="layout justify-center">
                <styled-button 
                    class="text-uppercase mt-5"
                    :small="true"
                    :loading="loadingAssignPermissions"
                    :disabled="!selected.length"
                    :loader-size="16"
                    @click.prevent="assignPermissions()">
                    Assign Permissions
                </styled-button>
            </div>
        </template>
        <template #actions>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('input', false)">
                Cancel
            </a>
        </template>
    </styled-dialog>
</template>

<script>
    import { mapState } from 'vuex';
    import StyledDialog from '@/components/globals/StyledDialog';
    import StyledButton from '@/components/globals/StyledButton';
    export default {
        components: {
            StyledDialog,
            StyledButton,
        },
        props: {
            value: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                individualOptions: false,
                loadingAssignPermissions: false,
                selected: ['all'],
                refresh: 0
            };
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer,
                recheckFacebookAssets: (state) => state.dealer.recheckFacebookAssets,
            }),
        },
        methods: {
            async assignPermissions() {
                this.loadingAssignPermissions = true;

                // Ad Accounts
                if ((this.selected.includes('ad_account') || this.selected.includes('all')) && this.currentDealer.facebook_ad_account_annotations.data.length) {
                    Promise.all(this.currentDealer.facebook_ad_account_annotations.data.map(async(ad_account) => {
                        return await this.$http.post(`/facebook_ad_account_annotations/${ad_account.id}/grant-user-access`);
                    }));
                }

                // Pixels
                if ((this.selected.includes('pixel') || this.selected.includes('all')) && this.currentDealer.facebook_pixel_annotations.data.length) {
                    await Promise.all(this.currentDealer.facebook_pixel_annotations.data.map(async(pixel) => {
                        return await this.$http.post(`/facebook_pixel_annotations/${pixel.id}/grant-user-access`);
                    }));
                }

                // Catalogs
                if ((this.selected.includes('catalogs') || this.selected.includes('all')) && this.currentDealer.facebook_product_catalog_annotations.data.length) {
                    await Promise.all(this.currentDealer.facebook_product_catalog_annotations.data.map(async(catalog) => {
                        return await this.$http.post(`/facebook_product_catalog_annotations/${catalog.id}/grant-user-access`);
                    }));
                }

                // Pages
                if ((this.selected.includes('page') || this.selected.includes('all')) && this.currentDealer.facebook_page_annotations.data.length) {
                    await Promise.all(this.currentDealer.facebook_page_annotations.data.map(async(page) => {
                        return await this.$http.post(`/facebook_page_annotations/${page.id}/grant-user-access`);
                    }));
                }

                this.loadingAssignPermissions = false;
                this.$store.dispatch('recheckFacebookAssets');
                this.$emit('input', false);
            },
            showIndividualOptions() {
                this.individualOptions = true;
                this.selected = [];
            },
            showAllOptions() {
                this.individualOptions = false;
                this.selected = ['all'];
            }
        },
    };
</script>

<style lang="scss" scoped>
.blue-background {
    background-color: #E3EEFB;
}
</style>