/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.09 4.569v10.627s-3.022-.85-5.269-.418c-2.012.386-3.492 1.478-3.742 1.67v.033l-.021-.016-.02.016v-.032c-.25-.193-1.73-1.284-3.742-1.671-2.247-.433-5.566.418-5.566.418V4.537l-.622.093V16.08s3.618-.931 6.079-.458c2.42.466 3.881 1.866 3.881 1.866s1.462-1.4 3.882-1.866c2.46-.473 5.93.458 5.93.458v-11.4s-.302-.05-.79-.11zM6.114 13.702c1.968.378 3.489 1.517 3.489 1.517V4.764s-1.53-1.14-3.501-1.52c-1.997-.384-4.604.375-4.604.375v10.455s2.616-.757 4.616-.372zm12.21.41V3.657s-2.39-.759-4.386-.374c-1.972.379-3.551 1.519-3.551 1.519v10.455s1.57-1.139 3.538-1.517c2-.384 4.399.372 4.399.372z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
