/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indicator-success': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M9 0a9 9 0 110 18A9 9 0 019 0zm4.446 5.5l-5.16 5.135-2.667-2.527L4 9.533 8.1 13.5l6.779-6.56-1.433-1.44z" _fill="#A2CF75" fill-rule="evenodd"/>'
  }
})
