/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fb_reels_share_icon': {
    width: 54,
    height: 54,
    viewBox: '0 0 54 54',
    data: '<defs><filter id="svgicon_fb_reels_share_icon_b" width="163.2%" height="163.2%" x="-31.6%" y="-31.6%" filterUnits="objectBoundingBox"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.300453453 0"/></filter><circle pid="0" id="svgicon_fb_reels_share_icon_a" cx="19" cy="19" r="19"/></defs><g _fill="none" fill-rule="evenodd" transform="translate(8 8)"><use xlink:href="#svgicon_fb_reels_share_icon_a" _fill="#000" filter="url(#svgicon_fb_reels_share_icon_b)"/><use xlink:href="#svgicon_fb_reels_share_icon_a" _fill="#D8D8D8" fill-opacity=".198"/><path pid="1" _fill="#FFF" fill-rule="nonzero" d="M28.58 17.274c.267.297.42.668.42 1.04 0 .408-.153.742-.42 1.039l-6.722 6.829a1.553 1.553 0 01-1.108.445c-.42 0-.764-.111-1.07-.408a1.408 1.408 0 01-.458-1.076v-3.267c-2.215.038-3.781.149-4.736.372-1.222.297-2.024.853-2.368 1.633-.382.816-.306 2.004.191 3.6.076.37.038.668-.153.965-.19.297-.458.445-.802.52-.344.073-.65.036-.955-.186-.916-.631-1.642-1.374-2.177-2.264C7.382 25.254 7 23.844 7 22.285c0-2.004.573-3.563 1.757-4.75.993-1.003 2.52-1.745 4.507-2.19 1.528-.372 3.514-.557 5.958-.594v-3.266c0-.409.153-.743.459-1.04.305-.297.649-.445 1.069-.445.42 0 .802.148 1.108.445l6.722 6.83z"/></g>'
  }
})
