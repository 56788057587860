/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" _fill="#fff" d="M20 24l-8-8L2 26V2h28v22m-14-4l6-6 8 8v8H2v-6"/><circle pid="1" cx="10" cy="9" r="3"/>'
  }
})
