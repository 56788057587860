<template>
    <styled-slideout
        :value="value"
        @input="toggle($event)">
        <div class="pa-5">
            <manage-dealer-ldu-agreement
                :accepted.sync="agreementAccepted"
                @complete="onAgreementAccepted" />
            <div
                v-if="agreementAccepted"
                class="mt-5 text-xs-center">
                <styled-button @click="toggle(false)">
                    CLOSE
                </styled-button>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledButton from './StyledButton';
import StyledSlideout from './StyledSlideout';
import ManageDealerLduAgreement from './ManageDealerLduAgreement';

export default {
    components: {
        ManageDealerLduAgreement,
        StyledSlideout,
        StyledButton
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            agreementAccepted: false
        };
    },
    methods: {
        onAgreementAccepted() {
            // Update the current dealer to pull in the changed data
            this.$store.dispatch('updateCurrentDealer');
        },
        toggle(value) {
            this.$emit('input', value);
            // Reset the status when closed
            if (value === false) {
                this.agreementAccepted = false;
            }
        }
    }
};
</script>
