import HTTP from '@/http';

/**
 * Gets the details of the agency Pinterest token
 *
 * @param {Number} config
 * @returns {Array}
 */
async function getTokenStatus(agency_id) {
    const response = await (new HTTP).get(`agencies/${agency_id}/platforms/pinterest/get_token_status`);
    return response.data || [];
}

export default getTokenStatus;