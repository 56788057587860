<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div
            class="pa-5">
            <h2 class="mb-4">
                About standard enhancements in Advantage+ creative
            </h2>

            <div>
                <p>
                    Standard enhancements in Meta Advantage+ creative (previously known as dynamic experiences) is for ads using a single image or video.
                    It automatically creates multiple variations of your ad and shows a personalized variation to each person based on what they're most likely to respond to.
                    You can create ads with standard enhancements using the traffic, leads, engagement, sales and app promotion objectives.
                </p>

                <p>
                    When you create an ad with standard enhancements, you can add a single image or video and a variety of text options.
                    We'll automatically create variations of your ad with different text combinations, media enhancements and compositional changes.
                    Your creative inputs, ad setup and placement selections determine which variations we automatically create. We suggest that you
                    include a variety of text options to maximize performance. We'll show each person the variation they're more likely to respond to.
                </p>

                <p>
                    The following are some of the types of media enhancements and compositional changes that can occur in this ad creation flow to
                    automatically optimize your creative, when likely to improve performance:
                </p>
            </div>

            <div class="mt-4">
                <img
                    class="w-100"
                    src="/img/advantage-plus-examples.png"
                    alt="">
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';

export default {
    components: {
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style>
.vehicle-carousel .theme--light.v-icon {
    color: white;
}
</style>

<style scoped>
.w-100 {
    width: 100%;
}
</style>
