/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g transform="rotate(-90 9.6 9.6)" _fill="#8F9EA6" fill-rule="evenodd"><ellipse pid="0" cx="2.036" cy="2" rx="2.036" ry="2"/><ellipse pid="1" cx="9.6" cy="2" rx="2.036" ry="2"/><ellipse pid="2" cx="17.164" cy="2" rx="2.036" ry="2"/></g>'
  }
})
