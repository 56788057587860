import {
    FACEBOOK_ATTRIBUTION_WINDOWS,
    FACEBOOK_OFFLINE_EVENT_TYPES
} from '@/helpers/globals';

const formatMap = {
    // Audience
    spend: 'currency',
    reach: 'number',
    cpp: 'currency',
    frequency: 'decimal',

    // Impressions
    impressions: 'number',
    cpm: 'currency',
    // Clicks
    clicks: 'number',
    cost_per_click: 'currency',
    ctr: 'percent',

    // Unique clicks
    unique_clicks: 'number',
    cost_per_unique_click: 'currency',
    unique_click_rate: 'percent',

    // Outbound clicks
    outbound_clicks: 'number',
    cost_per_outbound_click: 'currency',
    outbound_ctr: 'percent',

    // Unique outbound clicks
    unique_outbound_clicks: 'number',
    cost_per_unique_outbound_click: 'currency',
    unique_outbound_ctr: 'percent',

    // Landing page views
    landing_page_views: 'number',
    cost_per_landing_page_view: 'currency',
    landing_page_view_rate: 'percent',

    // On Facebook content views
    on_site_content_views: 'number',
    cost_per_on_site_content_view: 'currency',
    on_site_content_view_rate: 'percent',

    // Unique on Facebook content views
    unique_on_site_content_views: 'number',
    cost_per_unique_on_site_content_view: 'currency',
    unique_on_site_content_view_rate: 'percent',

    // Content views
    content_views: 'number',
    cost_per_content_view: 'currency',
    content_view_rate: 'percent',

    // Unique content views
    unique_content_views: 'number',
    cost_per_unique_content_view: 'currency',
    unique_content_view_rate: 'percent',

    // Aggregate content views
    omni_content_views: 'number',
    cost_per_omni_content_view: 'currency',
    omni_content_view_rate: 'percent',

    // Unique aggregate content views
    unique_omni_content_views: 'number',
    cost_per_unique_omni_content_view: 'currency',
    unique_omni_content_view_rate: 'percent',

    // On Facebook leads
    on_site_leads: 'number',
    cost_per_on_site_lead: 'currency',
    on_site_lead_rate: 'percent',

    // On Facebook Messaging Block
    onsite_conversion_messaging_block: 'number',
    cost_per_onsite_conversion_messaging_block: 'currency',
    onsite_conversion_messaging_block_rate: 'percent',

    // On Facebook Conversation Started
    messaging_conversation_started_7d: 'number',
    cost_per_messaging_conversation_started_7d: 'currency',
    messaging_conversation_started_7d_rate: 'percent',

    // On Fecebook Messaging first reply
    messaging_first_reply: 'number',
    cost_per_messaging_first_reply: 'currency',
    messaging_first_reply_rate: 'percent',

    // Unique on Facebook leads
    unique_on_site_leads: 'number',
    cost_per_unique_on_site_lead: 'currency',
    unique_on_site_lead_rate: 'percent',

    // Form leads
    form_leads: 'number',
    cost_per_form_lead: 'currency',

    // Unique form leads
    unique_form_leads: 'number',
    cost_per_unique_form_lead: 'currency',

    // Website leads
    website_leads: 'number',
    cost_per_website_lead: 'currency',
    website_lead_rate: 'percent',

    // Unique website leads
    unique_website_leads: 'number',
    cost_per_unique_website_leads: 'currency',
    unique_website_lead_rate: 'percent',

    // Total leads
    leads: 'number',
    cost_per_lead: 'currency',
    lead_rate: 'percent',

    // Leads to reach
    lead_to_reach_rate: 'percent',
    lead_to_landing_page_view_rate: 'percent',
    lead_to_unique_click_rate: 'percent',
    lead_to_click_rate: 'percent',
    lead_to_unique_on_site_view_content_rate: 'percent',

    // Misc actions
    find_location: 'number',
    click_to_call: 'number',
    offline_purchase_28d_total: 'number',
    offline_purchase_28d_rate: 'percent',
    cost_per_offline_purchase_28d: 'currency',

    // Aggregate on site leads
    omni_on_site_leads: 'number',
    cost_per_omni_on_site_lead: 'currency',
    omni_on_site_lead_rate: 'percent',

    // Website + form leads
    website_and_form_leads: 'number',
    cost_per_website_and_form_lead: 'currency',
    website_and_form_lead_rate: 'percent',
};

// Format all offline events as numbers
FACEBOOK_OFFLINE_EVENT_TYPES.forEach(eventType => {
    formatMap[`offline_${eventType.key}`] = 'number';
    FACEBOOK_ATTRIBUTION_WINDOWS.forEach(attributionWindow => {
        formatMap[`offline_${eventType.key}_${attributionWindow.key}`] = 'number';
    });
});

// Format all cost per offline events as currency
FACEBOOK_OFFLINE_EVENT_TYPES.forEach(eventType => {
    formatMap[`cost_per_offline_${eventType.key}`] = 'currency';
});

// Format all cost per offline events as currency
FACEBOOK_OFFLINE_EVENT_TYPES.forEach(eventType => {
    formatMap[`offline_${eventType.key}_rate`] = 'percent';
});

export default formatMap;