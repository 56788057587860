/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dealer': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" d="M9.277 0a3.078 3.078 0 00-.244.972l-.01.252V2.45H6.44L4.894 6.122h4.129v1.225c-.003 1.396.921 2.608 2.232 2.963l.174.041v4.343H6.015v1.224c0 .634-.473 1.156-1.08 1.219l-.123.006H3.609a1.211 1.211 0 01-1.197-1.1l-.006-.125v-1.563a2.454 2.454 0 01-1.196-1.937l-.007-.173V8.57l.515-1.224h-.515c-.363 0-.706-.167-.934-.453a1.24 1.24 0 01-.243-1.025 1.24 1.24 0 011.1-.967l.123-.004h1.5L4.509.743c.172-.41.547-.688.975-.736L5.614 0h3.663zm9.362 0C19.391 0 20 .548 20 1.224v6.123c0 .676-.61 1.224-1.36 1.224h-1.361v1.225l-1.36 1.224 1.36 1.225-1.36 1.224 1.36 1.225v1.224l-2.04 1.225-2.042-1.225V8.571h-1.36c-.752 0-1.36-.548-1.36-1.224V1.224c0-.676.608-1.224 1.36-1.224h6.802zM4.762 10.476a.952.952 0 100 1.905.952.952 0 000-1.905z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
