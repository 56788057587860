<template>
    <nodes-renderer
        ref="content-renderer"
        :style="styles"
        :content="layer.asset.content"
        :container-class="'canvas-text-asset'"
        :editable-nodes="editableNodes"
        @on-mousedown="handleMousedown" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NodesRenderer from './svg-wrappers/NodesRenderer.vue';
import { TEXT_ASSET_CONTENT_MODE } from '@/creative-studio/domain/layered-designer/models/Layer';

export default {
        components: {
            NodesRenderer,
        },
    props: {
            layer: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                editableNodes: [],
                contentType: {
                    simpleText: TEXT_ASSET_CONTENT_MODE.simpleText,
                    html: TEXT_ASSET_CONTENT_MODE.html
                }
            };
        },
        computed: {
            ...mapGetters([
                'focusedLayer',
                'cardLayers'
            ]),
            styles() {
                const { fillColor, strokeColor, strokeWidth, fontStyle, weight, fontFamily } = this.layer.asset;
                const layerGradientDefinition = `url(#${this.layer?.id}_layer_gradient)`;
                const styles = {
                    fill: this.layer.colorGradient.type !== 'solid' ? layerGradientDefinition : fillColor,
                    color:  this.layer.colorGradient.type !== 'solid' ? layerGradientDefinition : fillColor,
                    stroke: strokeColor,
                    strokeWidth,
                    fontStyle,
                    fontWeight: weight,
                    fontFamily: fontFamily || 'Open Sans',
                };

                return styles;
            },

        },
        watch: {
            editableNodes: {
                handler(newValue) {
                    const el = document.querySelector('[data-layer-focused="true"] svg');
                    const updateFields = {
                        editableFields: newValue,
                        content: el?.outerHTML || this.layer.asset.content
                    };
                    this.updateTextLayer(updateFields);
                },
                deep: true
            }
        },
        mounted() {
            if(this.focusedLayer?.id === this.layer?.id)
            this.editableNodes = this.layer?.asset?.editableFields;
        },
        updated() {
            this.updateLayerDimensions();
            this.$nextTick(() => {
                this.editableNodes = this.focusedLayer?.asset?.editableFields;
            });
        },
        methods: {
            ...mapActions([
                'updateTextLayer',
                'setTextEditorVisible',
                'setFocusedLayer'
            ]),
            updateTextLayerEditableNodes() {
                this.$nextTick(() => {

                });
                const elements = document.querySelectorAll('[data-layer-focused="true"] [bb-text-field-id]');
                const elementsArray = Array.from(elements);
                const textNodeContents = elementsArray.map((item) => {
                    return  {
                            textContent: item.textContent.trim(),
                            id: item.getAttribute('bb-text-field-id'),
                            label: item.getAttribute('bb-text-field-label') ?? ''
                        };
                    });
                this.editableNodes = textNodeContents;
            },
            updateLayerDimensions() {
                const svgElement = document.querySelector('[data-layer-focused="true"] svg');
                  if(svgElement) {
                    const bboxWidth = svgElement.getBBox().width + 25;
                          svgElement.setAttribute('width', parseInt(bboxWidth) + 'px');
                  }
            },
            handleMousedown() {
                this.setFocusedLayer(this.layer);
                this.updateLayerDimensions();

                this.$nextTick(() => {
                    this.updateTextLayerEditableNodes();
                    this.setTextEditorVisible(true);
                });

            }

        },
};

</script>

<style lang="scss">
.canvas-text-asset {
    width: 100%;


}

.editable-content-asset {
    white-space: pre-line;
    overflow: auto;
    padding: 2px;
    width: 400px;
}



</style>
