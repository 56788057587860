<template>
    <div>
        <div class="channels-title">
            Available<br> channels
        </div>
        <div class="channels">
            <div
                v-for="(platformConfig, index) in DEPLOYMENT_PLATFORMS"
                :key="index">
                <channel-selector
                    :ad-index="adIndex"
                    :platform-config="platformConfig" />
            </div>
        </div>
    </div>
</template>

<script>
import ChannelSelector from '@/components/ad-studio/editing/selection-step/ChannelSelector';
import { DEPLOYMENT_PLATFORMS } from '@/components/ad-deployment/store/constants.js';

export default {
    components: {
        ChannelSelector,
    },
     props: {
        adIndex: {
            type: [String, Number],
            required: true
        },
    },
    data() {
        return {
            DEPLOYMENT_PLATFORMS
        };
    }
};
</script>

<style lang="scss" scoped>
.channels-title{
    font-size: 20px;
    color:#03A2EA;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
</style>
