/**
 * Determines how many days there are in a given month
 * 
 * @param {Date} d 
 * @returns {Number}
 */
const daysInMonth = (d) => {
    return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
}
  

/**
 * Determines how many fractional months exist between two dates
 * 
 * @param {Date} d1 
 * @param {Date} d2 
 * @returns {Number}
 */
const monthDifference = (d1, d2) => {

    if (d1 > d2) {
        throw new Error('Second date must be greater than first');
    }
    
    const startDateYear = d1.getFullYear();
    const endDateYear = d2.getFullYear();
    
    const startDateMonth = d1.getMonth();
    const endDateMonth = d2.getMonth();
    
    const startDay = d1.getDate();
    const daysInStartMonth = daysInMonth(d1);

    const endDay = d2.getDate();
    const daysInEndMonth = daysInMonth(d2);
    
    // Single month
    if ((startDateYear == endDateYear) && (startDateMonth == endDateMonth)) {
        return (endDay - (startDay - 1)) / daysInStartMonth
    }
    
    // From here we're dealing with multiple months
    const monthsBetweenYears = (endDateYear - startDateYear) * 12;
    const totalMonths = monthsBetweenYears - startDateMonth + endDateMonth;
    
    const monthsBetween = totalMonths <= 0 ? 0 : totalMonths;
    
    const startMonthTotal = (daysInStartMonth - (startDay - 1)) / daysInStartMonth
    const endMonthTotal = endDay / daysInEndMonth
    
    return startMonthTotal + (monthsBetween - 1) + endMonthTotal;
}

export default monthDifference;