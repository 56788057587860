/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M4.879 20a4.851 4.851 0 01-3.452-1.43 4.885 4.885 0 010-6.9l1.756-1.756a1.04 1.04 0 111.47 1.471L2.899 13.14a2.803 2.803 0 000 3.96c.53.529 1.233.82 1.98.82.749 0 1.452-.291 1.981-.82l3.84-3.84a2.803 2.803 0 000-3.96 1.04 1.04 0 111.472-1.47 4.885 4.885 0 010 6.9l-3.84 3.84A4.85 4.85 0 014.878 20zm3.685-7.525c-.266 0-.532-.101-.735-.305a4.885 4.885 0 010-6.9l3.84-3.84A4.85 4.85 0 0115.122 0a4.85 4.85 0 013.452 1.429 4.885 4.885 0 010 6.901l-1.756 1.755a1.04 1.04 0 01-1.47-1.47l1.755-1.756a2.803 2.803 0 000-3.96 2.783 2.783 0 00-1.98-.82c-.749 0-1.452.292-1.981.82L9.3 6.74a2.803 2.803 0 000 3.96 1.04 1.04 0 01-.736 1.776z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
