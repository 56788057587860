import getPlatformOptimizationDefaults from '../helpers/getPlatformOptimizationDefaults';
import { BILLING_EVENTS, DEPLOYMENT_PLATFORMS } from './constants';
import { merge } from 'lodash';
import { setNestedValue } from '@/helpers/setNestedValue';

export default {
    setProperty(state, { property, value }) {
        state[property] = value;
    },
    setCurrentStep(state, index) {
        state.current_step = index;

        if (state.latest_step < state.current_step) {
            state.latest_step = state.current_step;
        }
    },
    setLatestStep(state, index) {
        state.latest_step = index;
    },
    setDeploymentId(state, id) {
        state.ad_deployment_id = id;
    },
    setDealerId(state, id) {
        state.dealer_id = id;
    },
    setDealerName(state, name) {
        state.dealer_name = name;
    },
    setPlatforms(state, platforms) {
        const platformsOrderArray = DEPLOYMENT_PLATFORMS.map(platform => platform.name);
        state.platforms = platforms.sort((a, b) => {
            return platformsOrderArray.indexOf(a) - platformsOrderArray.indexOf(b);
        });
    },
    setExistingPlatformCampaignOrAdSet(state, { value, platform, level }) {
        const newCampaigns = { ...state.campaign_selection };
        newCampaigns.platform_specific[platform][level] = value;
        state.campaign_selection = newCampaigns;
    },
    setCampaignName(state, name) {
        const newCampaigns = { ...state.campaign_selection };
        newCampaigns.all_platforms.campaign_name = name;
        state.campaign_selection = newCampaigns;
    },
    setSpecialAdsCategory(state, selection) {
        const newCampaigns = { ...state.campaign_selection };
        newCampaigns.all_platforms.ad_category = selection;
        state.campaign_selection = newCampaigns;
    },
    setDeploymentLocation(state, value) {
        const newCampaigns = { ...state.campaign_selection };
        newCampaigns.selection = value;
        state.campaign_selection = newCampaigns;
    },
    setPlatformObjective(state, { platform, value }) {

        const newOptimization = { ...state.optimization };

        // Merge the new defaults together with the existing
        // state (e.g. with fields like `cta`)
        newOptimization.platform_specific[platform] = {
            ...newOptimization.platform_specific[platform],
            ...getPlatformOptimizationDefaults(platform, value)
        };

        state.optimization = newOptimization;
    },
    setPlatformOptimizationGoal(state, { platform, value }) {
        const newOptimization = { ...state.optimization };
        newOptimization.platform_specific[platform].optimization_goal = value;

        // Reset to the first billing event available for the goal
        const defaultEvent = BILLING_EVENTS[platform][value.platform_value][0];
        newOptimization.platform_specific[platform].billing_event = defaultEvent;

        state.optimization = newOptimization;
    },
    setPlatformBillingEvent(state, { platform, value }) {
        const newOptimization = { ...state.optimization };
        newOptimization.platform_specific[platform].billing_event = value;
        state.optimization = newOptimization;
    },
    setGeoTargeting(state, platforms) {
        const newTargeting = { ...state.geo_targeting };
        newTargeting.platform_specific = platforms;
        state.geo_targeting = newTargeting;
    },
    setPlatformGender(state, { platform, gender }) {
        const newAudience = { ...state.audience };
        newAudience.platform_specific[platform].gender = gender;
        state.audience = newAudience;
    },
    setBudgetLevel(state, { platform, level }) {
        const newBudget = { ...state.budget };
        newBudget.platform_specific[platform].level = level;
        state.budget = newBudget;
    },
    setBudget(state, { platform, level, schedule, budget }) {
        const type = schedule === 'daily' ? 'daily_budget' : 'lifetime_budget';
        const newBudget = { ...state.budget };
        //todo check on key name for daily_budget and lifetime_budget
        newBudget.platform_specific[platform][level][type] = budget;
        state.budget = newBudget;
    },
    setBidCap(state, { platform, level, bid_cap }) {
        const newBudget = { ...state.budget };
        newBudget.platform_specific[platform][level].bid_cap = bid_cap;
        state.budget = newBudget;
    },
    updateStartDateTime(state, value) {
        const newBudget = { ...state.budget };
        newBudget.start_date_time = value;
        state.budget = newBudget;
    },
    updateEndDateTime(state, value) {
        const newBudget = { ...state.budget };
        newBudget.end_date_time = value;
        state.budget = newBudget;
    },
    setBudgetSchedule(state, value) {
        const newBudget = { ...state.budget };

        if (value === 'lifetime') {
            // loop over all the platforms in the budget object and set the daily_budget to null
            Object.keys(state.budget.platform_specific).forEach(platform => {
                newBudget.platform_specific[platform].campaign.daily_budget = null;
                newBudget.platform_specific[platform].adSet.daily_budget = null;
            });
        }

        if (value === 'daily') {
            // loop over all the platforms in the budget object and set the lifetime_budget to null
            Object.keys(state.budget.platform_specific).forEach(platform => {
                newBudget.platform_specific[platform].campaign.lifetime_budget = null;
                newBudget.platform_specific[platform].adSet.lifetime_budget = null;
            });
        }

        newBudget.schedule = value;

        state.budget = newBudget;
    },
    setExistingAudiences(state, { audiences, channel }) {
        const newAudience = { ...state.audience };
        newAudience.platform_specific[channel].existing_audiences = audiences;
        state.audience = newAudience;
    },
    setPlatformAges(state, { ages, platform }) {
        const newAudience = { ...state.audience };
        newAudience.platform_specific[platform].ages = ages;
        state.audience = newAudience;
    },
    updateLandingPageUrlForPlatform(state, { adIndex, platform, value }) {
        const newAds = [ ...state.ads ];
        newAds[adIndex].configuration.platform_specific[platform].cta_link = value;
        state.ads = newAds;
    },
    updateLandingPageUrlForAllPlatforms(state, { adIndex, value }) {
        const newAds = [ ...state.ads ];
        newAds[adIndex].configuration.all_platforms.cta_link = value;
        state.ads = newAds;
    },
    setApplyToAllUrl(state, { platform, value }) {
        state.url_config.platform_specific[platform] = value;
    },
    setUrlCustomization(state, value) {
        state.url_config.customize_per_channel = value;
    },
    toggleAppliedToAllAds(state) {
        state.url_config.applied_to_all_ads = !state.url_config.applied_to_all_ads;
    },
    updateStatusForSinglePlatformAndObjects(state, { value, objects, platforms }) {
        const newStatuses = { ...state.statuses };
        const platformsToChange = platforms ? platforms : DEPLOYMENT_PLATFORMS.map(platform => platform.name);
        platformsToChange.forEach(platform => {
            objects.forEach(object => {
                newStatuses.platform_specific[platform][object].status = value;
            });
        });
        state.statuses = newStatuses;
    },
    updateAdDeploymentPreset(state, { preset }) {
        const stateAdDeployment = state;
        state = merge(stateAdDeployment, preset);
    },
    updateSelectedPreset(state, { preset }) {
        state.selectedPreset = preset;
    },
    setLoading(state, value) {
        state.loading = value;
    },
    setIsSimpleGeo(state, value) {
        state.is_simple_geo = value;
    },
    setTriggerMapRefresh(state, value) {
        state.trigger_map_refresh = value;
    },
    setAdDeploymentValue(state, { key, value }) {
        state = merge(state, setNestedValue(state, key, value));
    },
};
