import {
    ATTRIBUTION_CLICK,
    ATTRIBUTION_VIEW,
    ATTRIBUTION_1D,
    ATTRIBUTION_7D,
    ATTRIBUTION_28D,
} from '@/helpers/globals';

import { METRICS_FACEBOOK_ADS_STORAGE_KEY } from '@/helpers/storageKeys';

const getLocalData = () => {
    const currentSettingsJson = localStorage.getItem(METRICS_FACEBOOK_ADS_STORAGE_KEY);
    return currentSettingsJson ? JSON.parse(currentSettingsJson) : null;
};

const getDefaultData = () => {
    return {
        attribution: {
            website: {
                [ATTRIBUTION_CLICK]: ATTRIBUTION_7D,
                [ATTRIBUTION_VIEW]: ATTRIBUTION_1D
            },
            offline: {
                [ATTRIBUTION_CLICK]: ATTRIBUTION_28D,
                [ATTRIBUTION_VIEW]: ATTRIBUTION_28D
            }
        },
        campaigns: [],
        campaignsSelected: []
    };
};

const getInitialData = () => ({ 
    ...getDefaultData(), 
    ...getLocalData() 
});

export default getInitialData;