<template>
    <styled-slideout
        class="alert-slideout"
        :value="value"
        :tabs="tabs"
        :width="875"
        label="Channel Settings"
        :active-tab="activeTab"
        @setActiveTab="activeTab = $event"
        @input="$emit('input', $event)">
        <template #action-buttons>
            <action-button
                target="_blank"
                icon="link"
                :href="dealer.site_base_url">
                Website
            </action-button>
            <action-button
                icon="cog"
                @click="gotoDealerSettings">
                Account Settings
            </action-button>
        </template>
        <template #facebook>
            <div class="meta channel">
                <h2>
                    <icon
                        class="channel-icon"
                        color="blue"
                        name="meta" />
                    Meta Settings
                </h2>
                <template v-if="dealerHasChannel('facebook')">
                    <p class="channel-description">
                        Use the tools below to quickly navigate critical connection points across the Meta platform and access related functionality to assist with managing your Dealer's assets.
                    </p>
                    <div class="fb-status-buttons button-group">
                        <b-m-access-status />
                        <assign-permissions-button />
                        <action-button
                            icon="indicator-warning"
                            @click="showAlerts">
                            Alerts
                        </action-button>
                        <action-button
                            v-if="dealerInFacebookDpoJurisdiction"
                            icon="contract-legal"
                            :color="'red'"
                            class="ml-3 "
                            @click="legalAgreementsDialogActive = true">
                            Agreements
                        </action-button>
                    </div>
                    <div class="assets">
                        <page-link-group />
                        <ad-account-link-group />
                        <pixel-link-group />
                        <catalog-link-group />
                        <google-links />
                    </div>
                    <legal-agreements-dialog
                        v-if="dealer"
                        v-model="legalAgreementsDialogActive"
                        :dealer="dealer" />
                </template>
                <template v-else>
                    <p class="channel-description">
                        You are currently not enrolled in Meta Advertising. To do so, review the informational card below and select the setup button.
                    </p>
                    <div class="setup-card meta">
                        <div class="horizontal-badge">
                            <img src="/img/platforms/meta.svg">
                            <p class="name">
                                Meta
                            </p>
                        </div>
                        <p class="description">
                            Deploy ready-to-go Meta Ad campaigns optimized for audience targeting, creative, and campaign setup. Plus, get all the reporting tools you need to track client success.
                        </p>
                        <styled-button
                            @click="openAuthenticateModal({ name: 'facebook', display_name: 'Facebook' })">
                            AUTHENTICATE AND SETUP
                        </styled-button>
                    </div>
                </template>
            </div>
        </template>
        <template #snapchat>
            <div class="snapchat channel">
                <h2>
                    <icon
                        class="channel-icon"
                        color="blue"
                        name="snapchat" />
                    Snapchat Settings
                </h2>
                <template v-if="dealerHasChannel('snapchat')">
                    <p class="channel-description">
                        Use the tools below to quickly navigate critical connection points across the Snapchat platform and access related functionality to assist with managing your Dealer's assets.
                    </p>
                    <div class="assets">
                        <p class="section-title">
                            Ad Account
                        </p>
                        <ul class="asset">
                            <li v-if="snapchat.organizationId && snapchat.adAccountId">
                                <a
                                    target="_blank"
                                    :href="`https://business.snapchat.com/${snapchat.organizationId}/settings/ad-accounts/${snapchat.adAccountId}?ref_aid=${snapchat.adAccountId}`">{{ dealer.name }}  Ad Account ({{ snapchat.adAccountId }})</a>
                            </li>
                            <li v-else>
                                Your Snapchat Ad Account is still in the process of being set up.
                            </li>
                        </ul>
                        <p class="section-title">
                            Pixels
                        </p>
                        <ul class="asset">
                            <li v-if="snapchat.adAccountId">
                                <a
                                    target="_blank"
                                    :href="`https://ads.snapchat.com/${snapchat.adAccountId}/events`">{{ dealer.name }} Pixel ({{ snapchat.pixelId }})</a>
                            </li>
                            <li v-else>
                                Your Snapchat Pixel is still in the process of being set up.
                            </li>
                        </ul>
                        <p class="section-title">
                            Catalogs
                        </p>
                        <ul class="asset">
                            <li v-if="snapchat.organizationId && snapchat.catalogId">
                                <a
                                    target="_blank"
                                    :href="`https://business.snapchat.com/${snapchat.organizationId}/catalogs/${snapchat.catalogId}`">{{ dealer.name }} Catalog ({{ snapchat.catalogId }})</a>
                            </li>
                            <li v-else>
                                Your Snapchat Catalog is still in the process of being set up.
                            </li>
                        </ul>
                    </div>
                </template>
                <template v-else>
                    <p class="channel-description">
                        Use the tools below to quickly navigate critical connection points across the TikTok platform and access related functionality to assist with managing your Dealer's assets.
                    </p>
                    <div class="setup-card snapchat">
                        <div class="horizontal-badge">
                            <img src="/img/platforms/snapchat.svg">
                            <p class="name">
                                Snapchat
                            </p>
                        </div>
                        <p class="description">
                            Deploy fully optimized Snapchat Inventory Ads that dynamically target users based on their engagement with the app + your dealership client's website. Run single image ads or collection ads across Prospecting and Retargeting campaigns. You'll send shoppers to dealers VDP pages on their website using campaigns optimized to low funnel intent for seamless engagement. Our EventFlow tracking system will utilize critical lead events and user activity captured by the Snapchat Pixel for future retargeting on Snapchat + cross-channel.
                        </p>
                        <styled-button
                            v-if="snapchat.authStatus"
                            :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: 'snapchat' }}">
                            SETUP
                        </styled-button>
                        <styled-button
                            v-else
                            @click="openAuthenticateModal({ name: 'snapchat', display_name: 'Snapchat' })">
                            AUTHENTICATE AND SETUP
                        </styled-button>
                    </div>
                </template>
            </div>
        </template>
        <template #tiktok>
            <div class="tiktok channel">
                <h2>
                    <icon
                        class="channel-icon"
                        color="blue"
                        name="tiktok" />
                    TikTok Settings
                </h2>
                <template v-if="dealerHasChannel('tiktok')">
                    <p class="channel-description">
                        Use the tools below to quickly navigate critical connection points across the TikTok platform and access related functionality to assist with managing your Dealer's assets.
                    </p>
                    <div class="assets">
                        <p class="section-title">
                            Ad Account
                        </p>
                        <ul class="asset">
                            <li v-if="tiktok.adAccountId">
                                <a
                                    target="_blank"
                                    :href="`https://ads.tiktok.com/i18n/dashboard?aadvid=${tiktok.adAccountId}`">{{ dealer.name }} Ad Account ({{ tiktok.adAccountId }})</a>
                            </li>
                            <li v-else>
                                Your TikTok Ad Account is still in the process of being set up.
                            </li>
                        </ul>
                        <p class="section-title">
                            Pixels
                        </p>
                        <ul class="asset">
                            <li v-if="tiktok.adAccountId">
                                <a
                                    target="_blank"
                                    :href="`https://ads.tiktok.com/i18n/events_manager/pixel?aadvid=${tiktok.adAccountId}`">{{ dealer.name }} Pixel ({{ tiktok.pixelId }})</a>
                            </li>
                            <li v-else>
                                Your TikTok Pixel is still in the process of being set up.
                            </li>
                        </ul>
                        <p class="section-title">
                            Catalogs
                        </p>
                        <ul class="asset">
                            <li v-if="tiktok.adAccountId">
                                <a
                                    target="_blank"
                                    :href="`https://ads.tiktok.com/dpa_i18n/catalogue/?aadvid=${tiktok.adAccountId}`">{{ dealer.name }} Catalog ({{ tiktok.catalogId }})</a>
                            </li>
                            <li v-else>
                                Your TikTok Catalog is still in the process of being set up.
                            </li>
                        </ul>
                    </div>
                </template>
                <template v-else>
                    <p class="channel-description">
                        You are currently not enrolled in TikTok Advertising. To do so, review the informational card below and select the setup button.
                    </p>
                    <div class="setup-card tiktok">
                        <div class="horizontal-badge">
                            <img src="/img/platforms/tiktok.svg">
                            <p class="name">
                                TikTok
                            </p>
                        </div>
                        <p class="description">
                            Turn your dealership client's new and used inventory into dynamic video inventory ads on TikTok. Run ready-to-deploy Prospecting ad campaigns that are fully optimized for audience targeting, ad creative, and campaign setup. Our EventFlow tracking system will utilize critical lead events and user activity captured by the TikTok Pixel for future cross-channel retargeting.
                        </p>
                        <styled-button
                            v-if="tiktok.authStatus"
                            :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: 'tiktok' }}">
                            SETUP
                        </styled-button>
                        <styled-button
                            v-else
                            @click="openAuthenticateModal({ name: 'tiktok', display_name: 'TikTok' })">
                            AUTHENTICATE AND SETUP
                        </styled-button>
                    </div>
                </template>
            </div>
        </template>
        <template #pinterest>
            <div class="pinterest channel">
                <h2>
                    <icon
                        class="channel-icon"
                        color="blue"
                        name="pinterest" />
                    Pinterest Settings
                </h2>
                <template v-if="dealerHasChannel('pinterest')">
                    <p class="channel-description">
                        Use the tools below to quickly navigate critical connection points across the Pinterest platform and access related functionality to assist with managing your Dealer's assets.
                    </p>
                    <div class="assets">
                        <p class="section-title">
                            Ad Account
                        </p>
                        <ul class="asset">
                            <li v-if="pinterest.adAccountId && pinterest.organizationId">
                                <a
                                    target="_blank"
                                    :href="`https://www.pinterest.com/business/business-access/${pinterest.organizationId}/ad-accounts/${pinterest.adAccountId}/details/ `">
                                    {{ dealer.name }} Ad Account ({{ pinterest.adAccountId }})
                                </a>
                            </li>
                            <li v-else>
                                Your Pinterest Ad Account is still in the process of being set up.
                            </li>
                        </ul>
                        <p class="section-title">
                            Pixels
                        </p>
                        <ul class="asset">
                            <li v-if="pinterest.adAccountId">
                                <a
                                    target="_blank"
                                    :href="`https://ads.pinterest.com/advertiser/${pinterest.adAccountId}/conversions/tag/`">
                                    {{ dealer.name }} Pixel ({{ pinterest.pixelId }})
                                </a>
                            </li>
                            <li v-else>
                                Your Pinterest Pixel is still in the process of being set up.
                            </li>
                        </ul>
                        <p class="section-title">
                            Catalogs
                        </p>
                        <ul class="asset">
                            <li v-if="pinterest.adAccountId">
                                <a
                                    target="_blank"
                                    :href="`https://www.pinterest.com/product-catalogs/data-source/?advertiserId=${pinterest.adAccountId}`">{{ dealer.name }} Catalog ({{ pinterest.catalogId }})
                                </a>
                            </li>
                            <li v-else>
                                Your Pinterest Catalog is still in the process of being set up.
                            </li>
                        </ul>
                    </div>
                </template>
                <template v-else>
                    <p class="channel-description">
                        You are currently not enrolled in Pinterest Advertising. To do so, review the informational card below and select the setup button.
                    </p>
                    <div class="setup-card pinterest">
                        <div class="horizontal-badge">
                            <img src="/img/platforms/pinterest.svg">
                            <p class="name">
                                Pinterest
                            </p>
                        </div>
                        <p class="description">
                            Transform your dealership client's new Inventory into Pinterest ad campaigns fully optimized for audience targeting, ad creative, and campaign setup. With access to Pinterest's highly engaged audience and our precise targeting, dealers will have the ability to directly reach the right people, at the right time, with content that results in high conversion and click-through rates.
                        </p>
                        <styled-button
                            v-if="pinterest.authStatus"
                            :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: 'pinterest' }}">
                            SETUP
                        </styled-button>
                        <styled-button
                            v-else
                            @click="openAuthenticateModal({ name: 'pinterest', display_name: 'Pinterest' })">
                            AUTHENTICATE AND SETUP
                        </styled-button>
                    </div>
                </template>
            </div>
        </template>
        <template #google>
            <div class="google channel">
                <h2>
                    <icon
                        class="channel-icon"
                        color="blue"
                        name="google" />
                    Google Settings
                </h2>
                <template v-if="dealerHasChannel('google')">
                    <p class="channel-description">
                        Included in this panel you will find all your
                        account information related to your Google
                        Channel Settings. For Accounts enrolled in Google
                        Marketplace, you will find instructions on how to
                        access your vehicles under your Google Business Profile.
                    </p>
                    <div class="assets">
                        <div class="marketplace-catalog-links">
                            <p class="section-title">
                                Google Vehicle Listings
                            </p>
                            <div class="catalog">
                                <p>
                                    You are all set up on Google Vehicle Listings!
                                </p>
                                <p>
                                    <em>
                                        It is important to note that listings
                                        can take up to 72 hours to populate under
                                        your Google Business Profile
                                    </em>
                                </p>
                                <ol class="instructions">
                                    <li>
                                        To view your Google listings, search
                                        for this account on Google or
                                        <a
                                            target="_blank"
                                            :href="`https://www.google.com/search?q=${encodeURIComponent(dealer.name)}`">
                                            <span>click here to run the search automatically</span>
                                        </a>
                                    </li>
                                    <li>
                                        <p>
                                            Scroll down the profile until you see a
                                            section titled "Cars for sale" as shown below:
                                        </p>
                                        <img
                                            width="350"
                                            src="/img/google-vehicle-listings.png">
                                    </li>
                                    <li>
                                        Click "View all" and review the inventory
                                        to ensure all of the listings are correct!
                                    </li>
                                    <li>
                                        If you don't see the "Cars for sale"
                                        section and it's been less than 72 hours
                                        since you completed onboarding please
                                        allow more time for the listings to propagate.
                                        Otherwise, please contact support for help.
                                    </li>
                                </ol>
                                <a
                                    target="_blank"
                                    href="https://support.buyerbridge.com/knowledge/google-vehicle-listings">Learn More</a>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p class="channel-description">
                        You are currently not enrolled in Google Marketplace. To do so, review the informational card below and select the setup button.
                    </p>
                    <div class="setup-card google">
                        <div class="horizontal-badge">
                            <img src="/img/platforms/google.svg">
                            <p class="name">
                                Google
                            </p>
                        </div>
                        <p class="description">
                            Activate Vehicle listings on Google to surface
                            inventory on dealerships' Google Business Profiles
                            and on other Google surfaces where users can search,
                            filter, and easily learn more about each vehicle.
                        </p>
                        <styled-button
                            :to="{
                                name: 'channel-setup-product-selection',
                                params: {
                                    dealer_id: dealer.id,
                                    channel: 'google'
                                }
                            }">
                            SETUP
                        </styled-button>
                    </div>
                </template>
            </div>
        </template>

        <platform-authentication-slide-out
            v-model="showAuthenticateSlideOut"
            :channel="authenticateModalData" />
    </styled-slideout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledButton from '@/components/globals/StyledButton';
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import LegalAgreementsDialog from '@/components/globals/DealerLegalAgreementsDialog';
import AssignPermissionsButton from '@/components/dealers/DealerID/QuickLinks/AssignPermissionsButton';
import BMAccessStatus from '@/components/dealers/DealerID/QuickLinks/BMAccessStatus';
import AdAccountLinkGroup from '@/components/dealers/DealerID/QuickLinks/AdAccountLinkGroup';
import PixelLinkGroup from '@/components/dealers/DealerID/QuickLinks/PixelLinkGroup';
import CatalogLinkGroup from '@/components/dealers/DealerID/QuickLinks/CatalogLinkGroup';
import PageLinkGroup from '@/components/dealers/DealerID/QuickLinks/PageLinkGroup';
import GoogleLinks from '@/components/dealers/DealerID/QuickLinks/GoogleLinks';
import { SET_CHANNEL_SETTINGS_TAB } from '@/store/mutation-types';
import PlatformAuthenticationSlideOut from '@/components/resellers/settings/PlatformAuthenticationSlideOut';

export default {
    name: 'ChannelSettingsSlideout',
    components: {
        StyledSlideout,
        StyledButton,
        Icon,
        ActionButton,
        AssignPermissionsButton,
        LegalAgreementsDialog,
        BMAccessStatus,
        AdAccountLinkGroup,
        PixelLinkGroup,
        CatalogLinkGroup,
        PageLinkGroup,
        GoogleLinks,
        PlatformAuthenticationSlideOut
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            showSubscriptionsSlideout: false,
            legalAgreementsDialogActive: false,
            showAuthenticateSlideOut: false,
            authenticateModalData: {},
            channelsAuthStatus: [],
        };
    },
    computed: {
        ...mapState({
            activeChannelSettingsTab: state => state.settings.activeChannelSettingsTab,
            agency: state => state.agency.currentAgency,
            dealer: state => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerPlatforms',
            'dealerInFacebookDpoJurisdiction'
        ]),
        activeTab: {
            set(value) {
                this.$store.commit(SET_CHANNEL_SETTINGS_TAB, value);
            },
            get() {
                return this.activeChannelSettingsTab;
            }
        },
        tabs() {
            return [
                { label: 'Meta', icon: 'meta', slot: 'facebook' },
                { label: 'Snapchat', icon: 'snapchat', slot: 'snapchat' },
                { label: 'TikTok', icon: 'tiktok', slot: 'tiktok' },
                { label: 'Pinterest', icon: 'pinterest', slot: 'pinterest' },
                { label: 'Google', icon: 'google', slot: 'google' },
            ];
        },
        snapchat() {
            let channel = this.channelsAuthStatus.filter(channel => channel.name === 'snapchat')[0];
            return {
                authStatus: channel.authStatus,
                organizationId: this.dealer.agency.business_managers.data.find( b => b.platform_id == 9)?.external_business_manager_id || null,
                adAccountId: this.dealer.ad_accounts.data.find(a => a.platform_id == 9)?.external_ad_account_id || null,
                pixelId: this.dealer.pixels.data.find(p => p.platform_id == 9)?.external_pixel_id || null,
                catalogId: this.dealer.catalogs.data.find(c => c.platform_id == 9)?.external_catalog_id || null
            };
        },
        tiktok() {
            let channel = this.channelsAuthStatus.filter(channel => channel.name === 'tiktok')[0];
            return {
                authStatus: channel.authStatus,
                adAccountId: this.dealer.ad_accounts.data.find(a => a.platform_id == 8)?.external_ad_account_id || null,
                pixelId: this.dealer.pixels.data.find(p => p.platform_id == 8)?.external_pixel_id || null,
                catalogId: this.dealer.catalogs.data.find(c => c.platform_id == 8)?.external_catalog_id || null
            };
        },
        pinterest() {
            let channel = this.channelsAuthStatus.filter(channel => channel.name === 'pinterest')[0];
            return {
                authStatus: channel.authStatus,
                organizationId: this.dealer.agency.business_managers.data.find( b => b.platform_id == 10)?.external_business_manager_id || null,
                adAccountId: this.dealer.ad_accounts.data.find(a => a.platform_id == 10)?.external_ad_account_id || null,
                pixelId: this.dealer.pixels.data.find(p => p.platform_id == 10)?.external_pixel_id || null,
                catalogId: this.dealer.catalogs.data.find(c => c.platform_id == 10)?.external_catalog_id || null
            };
        }

    },
    async created() {
        await this.checkPlatformAuthStatus();
    },
    methods: {
        showAlerts() {
            this.$emit('input', false);
            this.$emit('showAlerts');
        },
        dealerHasChannel(channel) {
            return this.dealerPlatforms.map(p => p.name).includes(channel);
        },
        gotoDealerSettings() {
            this.$emit('input', false);
            this.$router.push({ name: 'dealer', params: { dealer_id: this.dealer.id } });
        },
        getAdAccounts(platformId) {
            return this.dealer.ad_accounts.filter(a => a.platform_id == platformId);
        },
        /**
         * Check if platform is authenticated or not
         */
        async checkPlatformAuthStatus() {
            // List of platforms that currently support omni authentication
            let allowedOmniAuthenticationChannels = [
                {
                    name: 'snapchat'
                },
                {
                    name: 'tiktok'
                },
                {
                    name: 'pinterest'
                }
            ];

            this.channelsAuthStatus = await Promise.all(allowedOmniAuthenticationChannels.map(async(channel) => {
                let status = true;
                try {
                    await this.getPlatformAuthStatus(channel.name).then(response => {
                        if(response.data.token) {
                            status = true;
                        } else {
                            status = false;
                        }
                    });
                } catch (error) {
                    status = false;
                }

                channel.authStatus = status;
                return channel;
            }));
        },
        getPlatformAuthStatus(platformName) {
            return this.$apiRepository.getPlatformAuthStatus(this.agency.id, platformName);
        },
        openAuthenticateModal(data) {
            this.showAuthenticateSlideOut = true;
            this.authenticateModalData = data;
        },
    }
};
</script>
<style lang="scss">
ol.instructions {
    margin: 5px 0;
    li {
        padding: 2px 0;
    }
}

</style>

<style lang="scss" scoped>
.channel {
    padding: 20px 30px 35px;
    color: #3F4649;
}
.channel-icon {
    margin-right: 15px;
}
.channel-description {
    margin: 15px 0;
}
.fb-status-buttons {
    width: 275px;
}
.ms-status-buttons {
    width: 180px;
}
.assets {
    margin-top: 15px;
}
.asset {
    list-style: none;
}
.section-title {
    font-size: 16px;
    background: #f3f5f6;
    margin-top: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
.button-group {
    display: flex;
    justify-content: space-between;
}
.section-title {
    font-size: 16px;
    background: #f3f5f6;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
.setup-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    align-items: center;
    text-align: center;
    box-shadow: 0 0 6px rgba(0,0,0,0.2);
    background-position: top left;
    overflow: hidden;

    &::before {
        position: absolute;
        content: '';
        top: -25px;
        left: -100px;
        height: 250px;
        width: 250px;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.1;
    }
    &.meta::before {
        background-image: url('/img/platforms/meta.svg');
    }
    &.snapchat::before {
        background-image: url('/img/platforms/snapchat.svg');
    }
    &.tiktok::before {
        background-image: url('/img/platforms/tiktok.svg');
    }
    &.pinterest::before {
        background-image: url('/img/platforms/pinterest.svg');
    }

    .horizontal-badge {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .name {
            margin-left: 15px;
            color: $blue-primary;
            font-size: 24px;
            font-weight: 600;
        }
    }
}
</style>
