/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'admin': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 12c.1 0 .202.002.307.005a2.236 2.236 0 00-.298.933l-.009.205v5.714l.005.15c.023.36.131.7.304.994L0 20v-2.667C0 13.787 6.662 12 10 12zm10 6.857V20l-.309.001c.197-.334.309-.728.309-1.144zM15 8c1.38 0 2.5 1.28 2.5 2.857V12h.5c.55 0 1 .514 1 1.143v5.714c0 .629-.45 1.143-1 1.143h-6c-.55 0-1-.514-1-1.143v-5.714c0-.629.45-1.143 1-1.143h.5v-1.143C12.5 9.28 13.62 8 15 8zm0 1.133c-1.389 0-1.502.685-1.502 1.69V12h3.003v-1.306c-.012-.935-.172-1.561-1.501-1.561zM10 0a4.999 4.999 0 014.571 7.03c-1.399.184-2.52 1.27-2.916 2.69A4.999 4.999 0 015 5c0-2.763 2.237-5 5-5z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
