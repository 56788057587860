/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18 0H2C.9 0 .01.9.01 2L0 20l4-4h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM6 12H4v-2h2v2zm0-3H4V7h2v2zm0-3H4V4h2v2zm7 6H8v-2h5v2zm3-3H8V7h8v2zm0-3H8V4h8v2z" _fill="#8F9EA6" fill-rule="evenodd"/>'
  }
})
