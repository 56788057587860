/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ig_heart_icon': {
    width: 27,
    height: 23,
    viewBox: '0 0 27 23',
    data: '<path pid="0" d="M2.732 2.304a6.822 6.822 0 00.029 9.647L13.6 22.728l.664-.666.007.006 1.464-1.478 7.56-7.563-.031-.03 1.013-1.02a6.864 6.864 0 00.149-9.512l-.201-.207-.19-.18a6.833 6.833 0 00-9.474.245l-1.009 1.022-1.076-1.07a6.89 6.89 0 00-9.526-.181l-.219.21zm8.335 1.39l2.5 2.485 2.418-2.45a4.833 4.833 0 016.659-.214l.191.18a4.863 4.863 0 01.023 6.873l-2.423 2.442.024.025-6.864 6.867-9.424-9.37a4.822 4.822 0 01-.186-6.643l.186-.195a4.89 4.89 0 016.896 0z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
