import { mapGetters, mapState, mapActions } from 'vuex';
import { get } from 'lodash';
import { sleep } from '../helpers';
import {
    COOKIE_KEY_TOKEN,
    COOKIE_KEY_USER,
    EVENT_AGENCY_SET,
    EVENT_DEALER_SET,
    EVENT_ROUTE_SET,
    EVENT_USER_LOGIN,
    EVENT_USER_LOGOUT,
    EVENT_USER_SET
} from '@/helpers/globals';
import { deleteCookie, setCookie } from '@/helpers/cookies';
import {
    SETTINGS_STORAGE_KEY,
    METRICS_STORAGE_KEY
} from '@/helpers/storageKeys';

const InitMixin = {
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            isLoggedIn: (state) => state.user.isLoggedIn,
            user: (state) => state.user.user,
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentDealer: (state) => state.dealer.currentDealer,
            agencies: (state) => state.agency.agencies,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'userDealer',
            'userAgency',
            'userHomepage',
            'userIsAdmin',
            'userIsClient',
            'settingsStorage',
        ]),
        ...mapGetters('metrics', {
            metricsStorage: 'storage'
        })
    },
    watch: {
        isLoggedIn() {
            this.init(true);
        },
        user(newUser, oldUser) {

            // Ignore these behaviors for clients
            if (this.userIsClient) {
                return;
            }

            // New login
            if (oldUser === null && typeof newUser === 'object') {
                // Sync up the user cookie for external consumers
                setCookie(COOKIE_KEY_USER, newUser.id);

                // Track in the dataLayer
                this.$dl.newEvent(EVENT_USER_LOGIN, { user: newUser });
            }

            // Logout
            if (typeof oldUser === 'object' && newUser === null) {
                // Drop the user cookie to keep it in sync
                deleteCookie(COOKIE_KEY_USER);

                // Track in the dataLayer
                this.$dl.newEvent(EVENT_USER_LOGOUT, { user: oldUser });
            }
        },
        token(token) {
            // Sync local storage with cookie for external consumers
            if (token) {
                setCookie(COOKIE_KEY_TOKEN, token);
            } else {
                deleteCookie(COOKIE_KEY_TOKEN);
            }
        },
        currentAgency(agency, oldAgency) {
            if (!this.userIsClient &&
                agency !== null &&
                get(agency, 'id') !== get(oldAgency, 'id')) {
                this.initActiveOnboardings();
            }

            // Track in the dataLayer
            if (agency?.id !== oldAgency?.id) {
                this.$dl.newEvent(EVENT_AGENCY_SET, { agency });
            }
        },
        currentDealer(dealer, oldDealer) {
            // Track in the dataLayer
            if (dealer?.id !== oldDealer?.id) {
                this.$dl.newEvent(EVENT_DEALER_SET, { dealer });
            }
        },
        currentDealerId: {
            handler(dealerId, oldDealerId) {
                if (dealerId !== null && dealerId !== oldDealerId) {
                    this.getDealerAlerts(dealerId);
                }
            },
            immediate: true
        },
        settingsStorage(value) {
            localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(value));
        },
        metricsStorage(value) {
            localStorage.setItem(METRICS_STORAGE_KEY, JSON.stringify(value));
        },
        $route(newRoute, oldRoute) {
            if (newRoute && newRoute?.name !== oldRoute?.name) {

                // Curate the specific properties we want to send to GTM
                const { fullPath, hash, meta, name, params, path, query } = newRoute;

                this.$dl.newEvent(EVENT_ROUTE_SET, {
                    route: { fullPath, hash, meta, name, params, path, query }
                });
            }
        }
    },
    async created() {
        let params = new URLSearchParams(window.location.search);
        if(params.get('init') == 'false') {
            console.log('Skipping initialization...');
            return;
        }
        await this.init();

        // Always get the user's location
        this.getUserLocation().catch(() => null);
    },
    methods: {
        ...mapActions([
            'startInit',
            'stopInit',
            'getUser',
            'updateBrand',
            'updateAgencies',
            'updateAgencyById',
            'updateDealers',
            'updateDealerById',
            'getUserLocation',
            'initActiveOnboardings',
            'getDealerAlerts'
        ]),
        async init(authStateChange = false) {

            // Only progress if we're logged in
            if (!this.isLoggedIn) {

                this.startInit();

                //Get brand from the server based off the url
                await this.updateBrand();

                this.stopInit();

                return;
            }

            const startTime = new Date();

            // Sync up the token cookie for external consumers
            setCookie(COOKIE_KEY_TOKEN, this.token);

            // Prep temporary variables since the vuex state gets updated
            let initWithUser = (this.user) ? true : false;
            let initWithCurrentAgency = (this.currentAgency) ? true : false;

            this.$title = 'Initializing...';

            this.startInit();

            // If there's no user in local storage get it from the server
            if (!this.user) {
                await this.getUser();
            }

            // Send the user to the dataLayer
            this.$dl.newEvent(EVENT_USER_SET, { user: this.user });

            // Sync up the user cookie for external consumers
            setCookie(COOKIE_KEY_USER, this.user.id);

            // If the user has a dealer and there's not a currentDealer
            // set from the route use it as the current dealer
            if (this.userDealer && !this.currentDealer) {
                await this.updateDealerById(this.userDealer.id);
            }

            // If there's still no current dealer use the list of
            // dealers and use the first one
            if (!this.currentDealer) {
                const dealers = await this.updateDealers();
                if (dealers.length) {
                    await this.updateDealerById(dealers[0].id);
                }
            }

            // Send the dealer to the dataLayer
            this.$dl.newEvent(EVENT_DEALER_SET, { dealer: this.currentDealer });

            // If the user has an agency use it as the current agency
            if (this.userAgency && !this.currentAgency) {
                await this.updateAgencyById(this.userAgency.id);
            }

            // If the user is an admin and there's no current agency
            // use the dealer's agency
            if (this.userIsAdmin && this.currentDealer && !this.currentAgency) {
                await this.updateAgencyById(this.currentDealer.agency.id);
            }

            // If there's no current agency retrieve all agencies
            // via state and use the first
            if (!this.currentAgency) {
                const agencies = await this.updateAgencies();
                await this.updateAgencyById(agencies[0].id);
            }

            // Send the agency to the dataLayer
            this.$dl.newEvent(EVENT_AGENCY_SET, { agency: this.currentAgency });

            // Get brand from the local storage currentAgency and apply it to the dashboard
            await this.updateBrand();

            // Now if the user isn't an end user let's initialize the onboardings
            // (which depends on an agency)
            if (!this.userIsClient) {
                this.initActiveOnboardings();
            }

            const redirectTo = this.$route.query.redirect;

            // If the authentication state has changed send the newly authenticated user to their homepage
            if (authStateChange && redirectTo) {
                await this.$router.push(this.$route.query.redirect);
            }
            // Otherwise if it's changed OR the user is calling up the root route
            // route which happens when the homepage can't be identified early
            // redirect to the homepage.  Note that the rule below is a bit of a hack!
            else if (authStateChange || window.location.pathname === '/') {
                await this.$router.push(this.userHomepage);
            }

            // Ensure the system shows the loader for at least a second to avoid flashing
            const elapsedTime = (new Date()) - startTime;
            const minTime = 1000;
            if (elapsedTime < minTime) {
                await sleep(minTime - elapsedTime);
            }

            // Apply the brand suffix before finishing intialization
            this.$setTitleSuffix(` - ${this.$brand.name}`);

            this.stopInit();

            /**
             * START REFRESHING DATA
             *
             * If data was initialized from local storage refresh it behind
             * the scenes in case anything has changed since it was set.
             */

            // We do this here so the user doesn't have to wait for the UI to load
            // while this data is processing.
            if (initWithUser) {
                this.getUser();
            }

            // Always update the current dealers
            this.updateDealers();

            // Load in all of the agencies for an admin user that has initialized
            // with an agency because updateAgencies won't trigger during init unless
            // the user doesn't have an agency also guard against ending up here without
            // a list of agencies
            if ((initWithCurrentAgency || this.agencies.length <= 1) && this.userIsAdmin) {
                await this.updateAgencies();
                await this.updateAgencyById(this.currentDealer.agency.id);
            }
        }
    }
};

export default InitMixin;
