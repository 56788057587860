import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';

const _mutationTypes = removeNamespace('metrics/tiktok/ads/', mutationTypes);

export default {
    [_mutationTypes.SET_TT_DEALER_CAMPAIGNS](state, campaigns) {
        state.campaigns = campaigns;
    },
    [_mutationTypes.SET_TT_DEALER_CAMPAIGNS_SELECTED](state, selections) {
        state.campaignsSelected = selections;
    },
    [_mutationTypes.SET_TT_DEALER_CAMPAIGNS_LOADING](state, isLoading) {
        state.campaignsLoading = isLoading;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_ERROR](state, error) {
        state.metricsError = error;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_PREVIOUS_PERIOD_ERROR](state, error) {
        state.metricsErrorPreviousPeriods = error;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_TOTALS](state, metrics) {
        state.metricsTotals = metrics;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_TOTALS_LOADING](state, isLoading) {
        state.metricsTotalsLoading = isLoading;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_INTERVALS](state, metrics) {
        state.metricsIntervals = metrics;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_INTERVALS_LOADING](state, isLoading) {
        state.metricsIntervalsLoading = isLoading;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_CAMPAIGNS](state, metrics) {
        state.metricsCampaigns = metrics;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_CAMPAIGNS_LOADING](state, isLoading) {
        state.metricsCampaignsLoading = isLoading;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_TOTALS_COMPARISON](state, metrics) {
        state.metricsTotalsComparison = metrics;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_TOTALS_COMPARISON_LOADING](state, isLoading) {
        state.metricsTotalsComparisonLoading = isLoading;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS](state, metrics) {
        state.metricsTotalsPrevious90Days = metrics;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING](state, isLoading) {
        state.metricsTotalsPrevious90DaysLoading = isLoading;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_MONTH](state, metrics) {
        state.metricsTotalsPreviousMonth = metrics;
    },
    [_mutationTypes.SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING](state, isLoading) {
        state.metricsTotalsPreviousMonthDayLoading = isLoading;
    },
};