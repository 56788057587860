/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<defs><path pid="0" id="svgicon_expand_a" d="M4 2.333L6.27 0 16 10 6.27 20 4 17.667 11.46 10z"/></defs><use _fill="#8F9EA6" transform="rotate(90 12 8)" xlink:href="#svgicon_expand_a" fill-rule="evenodd"/>'
  }
})
