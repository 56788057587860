/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'indicator-none': {
    width: 11,
    height: 10,
    viewBox: '0 0 11 10',
    data: '<path pid="0" d="M1.24 9.76L10.5.5" _stroke="#8F9EA6" _fill="none" fill-rule="evenodd" stroke-linecap="square"/>'
  }
})
